import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { CaptureConsole } from '@sentry/integrations';

import {
  BrowserRouter as Router,
  withRouter,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes,
  faThumbsUp,
  faComment,
  faUser,
  faCaretDown,
  faPlus,
  faPencilAlt,
  faTrash,
  faSearch,
  faSpinner,
  faEye,
  faEyeSlash,
  faBirthdayCake,
  faMapMarkerAlt,
  faMale,
  faFemale,
  faCalendarDay,
  faComments,
  faCamera,
  faBell,
} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import store from './redux';
import './i18n';
import App from './App';


library.add(
  faTimes,
  faThumbsUp,
  faComment,
  faUser,
  faCaretDown,
  faPencilAlt,
  faPlus,
  faTrash,
  faSearch,
  faSpinner,
  faEye,
  faEyeSlash,
  faBirthdayCake,
  faMapMarkerAlt,
  faMale,
  faFemale,
  faCalendarDay,
  faComments,
  faCamera,
  faBell,
);



const AppWithRouter = withRouter(App);

//const AppCSS = React.lazy(() => import('./common/AppCSS'));
//onst DashboardCSS = React.lazy(() => import('./common/DashboardCSS'));
const { pathname } = window.location;


ReactDOM.render(
  <Provider store={store}>
   
   <Router>
        <AppWithRouter />
    </Router>

  </Provider>,
  document.querySelector('#app'),
);
