import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Form from "../../../common/Form";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';


const SearchFilter = ({ handleChange, value }) => {
  const [query, setQuery] = useState(value);
  const width = window.innerWidth;
  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  return (
    <section className="ic-app-content-left d-md-block">
      <aside>
        <div className="ic-app-content-left--wrapper">
          <StyledSearch style={{ padding: "20px 0 0 0" }}>
            <div className="ic-search">
              <div className="ic-search-sidebar">
                <div className="ic-search-sidebar--wrapper">
                  <header className="header-plain">
                    <Button
                      onClick={() => setOpen(!open)}
                      aria-label="Toggle Search Filters"
                      aria-controls="search-filter-collapse"
                      aria-expanded={open}
                      className="toggle-filter-button"
                    >
                      {t("Search Filters")}
                      {open ? (
                        <i
                          className="material-icons"
                          style={{ fontSize: "28px" }}
                          alt="down arrow"
                        >
                          expand_more
                        </i>
                      ) : (
                        <i
                          className="material-icons"
                          style={{ fontSize: "28px" }}
                          alt="right arrow"
                        >
                          chevron_right
                        </i>
                      )}
                    </Button>
                  </header>
                </div>
              </div>
            </div>
          </StyledSearch>

          <StyledSearch>
            <Collapse in={open}>
              <div id="search-filter-collapse">
                <div className="ic-page-search-sidebar">
                  <div className="ic-page-search-sidebar--wrapper">
                    <div className="containter-fluid">
                      <div className="row ic-default-sidebar-form">
                        <Form noValidate>
                          <div className="ic-form-content clearfix">
                            <Form.Row>
                              <Form.Group
                                className="mb-0"
                                as={Col}
                                xs={12}
                                lg={12}
                              >
                                {/* <Form.Control onChange={(e) => setQ(e.target.value)} className="mr-sm-0" type="search" placeholder="Search..." aria-label="Search keywords" /> */}
                                <Form.Control
                                  value={value}
                                  onChange={(e) => handleChange(e)}
                                  placeholder={t("Search...")}
                                  //onKeyPress={(e) => handleEnterPress(e)}
                                  aria-label="Search keywords"
                                  className="px-4"
                                  style={{ height: "40px" }}
                                />
                              </Form.Group>
                            </Form.Row>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </StyledSearch>
        </div>
      </aside>
    </section>
  );
};

export default SearchFilter;

const StyledSearch = styled.div`
  .toggle-filter-button {
    font-size: 1.2rem;
    color: #000000;
    font-weight: 700;
    border: 0;
    border-radius: 0;
    background-color: #ffffff;
    box-shadow: none;
    text-align: start;
    width: 100%;
    padding: 0 24px 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .toggle-filter-button:active {
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
  }

  @media (max-width: 576px) {
    .ic-app-content-left--wrapper {
      margin-top: 1rem;
    }
  }
`;
