import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
// import { connect } from 'react-redux';
import { Col } from "react-bootstrap";
import Form from "../../../common/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from "styled-components";
import ChevronDown from "../../../assets/images/ChevronDown.png";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { getOrganizationJobCategories, getCities } from "../../../api";
import AccessibilityCheckBoxes from "../../../components/AccessibilityCheckBoxes";


const SearchFilters = ({ onApplyFilters }) => {
  const direction = i18n.dir();
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const [filters, setFilters] = useState({
    q: null,
    cat: null,
    city: null,
    cognitive: "",
    sensoryfriendly: "",
    mobility: "",
    visual: "",
    hearing: "",
  });

  const [accessibility, setAccessibility] = useState({
    cognitive: false,
    sensoryfriendly: false,
    mobility: false,
    visual: false,
    hearing: false,
    allOpen: false,
  });

  const [categories, setCategories] = useState([]);
  const [suggestions, setSuggestions] = useState([
    { city_name: "Abu Dhabi" },
    { city_name: "Dubai" },
    { city_name: "Sharjah" },
  ]);

  const onSearch = (e) => {
    setFilters({ ...filters, q: e.target.value });
  };

  const [catLabel, setCatLabel] = useState(t("All Categories"));
  const [cityLabel, setCityLabel] = useState(t("All Cities"));

  const onFiltersChanged = (val, field, label) => {
    const newFilters = { ...filters };

    if ((field === "cat" || field === "city") && val === "all") {
      newFilters[field] = null;
    } else {
      newFilters[field] = val;
    }

    if (field === "cat") {
      setCatLabel(label);
    } else {
      setCityLabel(label);
    }

    setFilters(newFilters);
    onApplyFilters(newFilters);
  };

  useEffect(() => {
    let unmounted = false;
    getOrganizationJobCategories().then(({ data }) => {
      if (!unmounted) {
        setCategories(
          data.map((item) => ({
            value: item.job_category,
            label: item.job_category,
          }))
        );
      }
    });
    return () => { unmounted = true }
  }, []);

  const onChange = (e) => {
    getSuggestions(e.target.value);
  };

  //Calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    setCityLabel(t("Select City"));
    // let filteredCities = [];
    // filteredCities = cities.filter(city => city.city_name.toLowerCase().slice(0, inputLength) === inputValue);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0 || value === "") {
      setSuggestions([
        { city_name: "Abu Dhabi" },
        { city_name: "Dubai" },
        { city_name: "Sharjah" },
      ]);
    } else if (inputLength >= 1 && inputLength <= 3) {
      setSuggestions([]);
    } else if (inputLength >= 4) {
      getCities({ q: value }).then(({ data }) => {
        setSuggestions(data);
      });
    }
  };

  const onClickApply = (e) => {
    e.preventDefault();

    onApplyFilters(filters);
  };

  const width = window.innerWidth;
  const [open, setOpen] = useState(width < 440 ? false : true);

  return (
    <section className="ic-app-content-left d-md-block">
      <aside>
        <div className="ic-app-content-left--wrapper ">
          <StyledForm>
            <div className="ic-search">
              <div className="ic-search-sidebar">
                <div className="ic-search-sidebar--wrapper">
                  <header className="header-plain">
                    <Button
                      onClick={() => setOpen(!open)}
                      aria-label="Toggle Job Filters"
                      aria-controls="job-filter-collapse"
                      aria-expanded={open}
                      className="toggle-filter-button"
                    >
                      {/* {t("Job Filters")} */}
                      Opportunity Filters
                      {open ? (
                        <i
                          className="material-icons"
                          style={{ fontSize: "28px" }}
                          alt="down arrow"
                        >
                          expand_more
                        </i>
                      ) : (
                        <i
                          className="material-icons"
                          style={{ fontSize: "28px" }}
                          alt="right arrow"
                        >
                          chevron_right
                        </i>
                      )}
                    </Button>
                  </header>
                </div>
              </div>
            </div>

            <Collapse in={open}>
              <div id="job-filter-collapse">
                <div className="ic-page-search-sidebar">
                  <div className="ic-page-search-sidebar--wrapper">
                    <div className="containter-fluid">
                      <div className="row ic-default-sidebar-form">
                        <Form noValidate>
                          <div className="ic-form-content clearfix">
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                xs={12}
                                lg={12}
                                className="mb-3 mt-4"
                              >
                                <Form.Control
                                  className="SearchInput"
                                  onChange={onSearch}
                                  placeholder={t("Search...")}
                                  aria-label="Search jobs"
                                />
                              </Form.Group>
                            </Form.Row>

                            {/* <Dropdown>
                              <Dropdown.Toggle
                                className="job-filter form-control toggle-filter-button"
                                id="dropdown-filter-cat"
                                value={filters.cat}
                                aria-label="Select Job Category"
                              >
                                {catLabel}
                                <i
                                  className="material-icons"
                                  style={{ fontSize: "28px" }}
                                >
                                  expand_more
                                </i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu style={{textAlign: `${direction === "rtl" ? "right" : ""}`}}>
                                <Dropdown.Item
                                  value="all"
                                  onClick={(e) =>
                                    onFiltersChanged(
                                      "all",
                                      "cat",
                                      `${t("All Categories")}`
                                    )
                                  }
                                  tabIndex="0"
                                  onKeyUp={(event) =>
                                    event.key === "Enter"
                                      ? onFiltersChanged(
                                          "all",
                                          "cat",
                                          `${t("All Categories")}`
                                        )
                                      : ""
                                  }
                                >
                                  {t("All Categories")}
                                </Dropdown.Item>
                                {categories.map((category) => (
                                  <Dropdown.Item
                                    key={category.value}
                                    value={category.value}
                                    onClick={(e) =>
                                      onFiltersChanged(
                                        `${category.value}`,
                                        "cat",
                                        `${t(category.label)}`
                                      )
                                    }
                                    tabIndex="0"
                                    onKeyUp={(event) =>
                                      event.key === "Enter"
                                        ? onFiltersChanged(
                                            `${category.value}`,
                                            "cat",
                                            `${t(category.label)}`
                                          )
                                        : ""
                                    }
                                  >
                                    {t(category.label)}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown> */}

                            <Dropdown>
                              <Dropdown.Toggle
                                className="job-filter form-control toggle-filter-button"
                                id="dropdown-filter-city"
                                value={filters.city}
                                aria-label="Select Job City"
                              >
                                {cityLabel}
                                <i
                                  className="material-icons"
                                  style={{ fontSize: "28px" }}
                                >
                                  expand_more
                                </i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu style={{textAlign: `${direction === "rtl" ? "right" : ""}`}}>
                                <Form.Row style={{ width: "100%", marginRight: `${direction === "rtl" ? "0" : ""}` }}>
                                  <Form.Group
                                    className="m-0 p-0"
                                    as={Col}
                                    xs={12}
                                    lg={12}
                                  >
                                    <Form.Control
                                      onChange={(e) => onChange(e)}
                                      placeholder={t("Type a city name...")}
                                      aria-label="Search city name"
                                      className="city-input-search"
                                      tabIndex="0"
                                    />
                                  </Form.Group>
                                </Form.Row>

                                {suggestions.length ? (
                                  suggestions.map((suggestion) => (
                                    <Dropdown.Item
                                      key={suggestion.city_name}
                                      value={suggestion.city_name}
                                      onClick={(e) =>
                                        onFiltersChanged(
                                          `${suggestion.city_name}`,
                                          "city",
                                          `${t(suggestion.city_name)}`
                                        )
                                      }
                                    >
                                      {t(suggestion.city_name)}
                                    </Dropdown.Item>
                                  ))
                                ) : (
                                  <Dropdown.Item style={{ cursor: "default" }}>
                                    {t("City not found.")}
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>

                            <Form.Row>
                              <Form.Group as={Col} xs={12} lg={12}>
                                <Form.Label className="accessibility-label d-flex">
                                  {t("Performance Drivers")}
                                  <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Performance Drivers Tooltip" aria-pressed={showTooltip}>
                                    <i className="material-icons help-icon">help_outline</i>
                                  </Button>
                                </Form.Label>
                                <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                                  {(props) => (
                                    <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                                      With performance drivers, employers can build a truly equitable workplace. Performance drivers offer a range of baked-in, in-built, or additional 30 accessibility options within a range of five key drivers. When an employer uses the super simple process of posting a job with performance drivers, they choose to inclusive hiring of persons with disabilities.
                                    </Tooltip>
                                  )}
                                </Overlay>
                                <AccessibilityCheckBoxes
                                  accessibility={accessibility}
                                  setAccessibility={setAccessibility}
                                  filters={filters}
                                  setFilters={setFilters}
                                  OnApplyFilters={onApplyFilters}
                                  isFilter
                                />
                              </Form.Group>
                            </Form.Row>

                            <Form.Row style={{ display: "none" }}>
                              <Form.Group as={Col} xs={12} lg={12}>
                                <Button
                                  primary="true"
                                  submit="true"
                                  type="submit"
                                  onClick={onClickApply}
                                  className="btn ic-btn-submit"
                                  id="jobBtn"
                                >
                                  Apply
                                </Button>
                              </Form.Group>
                            </Form.Row>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </StyledForm>
        </div>
      </aside>
    </section>
  );
};

export default SearchFilters;

SearchFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
};

SearchFilters.defaultProps = {
  filters: [],
};

const StyledForm = styled.div`
  .toggle-filter-button {
    font-size: 1.2rem;
    color: #000000;
    font-weight: 700;
    border: 0;
    border-radius: 0;
    background-color: #ffffff;
    box-shadow: none;
    text-align: start;
    width: 100%;
    padding: 0 0 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toggle-filter-button:focus,
  .event-filter:focus {
    border: 0.15rem solid #000000 !important;
    border-radius: 0.3rem !important;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .toggle-filter-button:active {
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
  }

  .filter-button-open {
    color: white !important;
    background-color: #000 !important;
    border-radius: 0.25rem !important;
    border-bottom: 0 !important;
  }

  #job-filter-collapse {
    //background-color: #F8F8F8;
  }

  .job-filter {
    font-size: 1.1rem;
    color: #000000 !important;
    font-weight: 600;
    padding: 28px 10px;
    border: 0;
    border-radius: 0;
    background-color: transparent !important;
    border-bottom: 2px solid #ececec !important;
  }

  .job-filter:active {
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
    border-bottom-color: #ececec !important;
    border-bottom: 0 !important;
  }

  .dropdown-menu.show {
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 204px;
    overflow: auto;
  }

  a.dropdown-item {
    padding: 0.8rem;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }

  .dropdown-item:active {
    text-decoration: none;
    color: #000000 !important;
    background-color: #ffffff !important;
  }

  select.ic-form-select {
    font-size: 1.1rem;
    color: #000;
    font-weight: 600;
    padding: 15px 10px;
    border: 0;
    border-radius: 0;
    background-image: url(${ChevronDown});
    background-position: right 0.5em top 50%, 0 0;
    background-color: transparent;
    border-bottom: 2px solid #ececec;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .city-input-search {
    padding: 0.8rem;
    border-bottom: 1.5px solid #cecece !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    margin-left: 5px;
    height: 50px;
    font-weight: 500;
    background: #ffffff !important;
    box-shadow: none !important;
  }

  input.city-input-search.form-control:focus {
    box-shadow: none !important;
    color: #000000 !important;
  }

  .accessibility-label {
    font-size: 1.1rem;
    color: #000000;
    font-weight: 600;
    padding: 15px 10px;
    margin-bottom: 0;
  }

  hr {
    border-top: 2px solid #ececec;
    width: 90%;
    margin-top: 0;
    margin-bottom: 0;
  }

  button#dropdown-button-drop-right {
    color: #000;
    font-weight: 600;
    border: 0;
    border-radius: 0;
    background: white;
    padding: 15px 20px;
  }

  @media (max-width: 440px) {
    margin-top: 1.5rem;
  }
`;
