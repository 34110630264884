import React, {useState} from "react"; 
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";


const OrganizationDescription = ({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {

    const [uploadState, setUploadState] = useState("No file selected");
return (
        <> 
        <Form.Row>
            <Form.Group as={Col} md={6} col={12}>
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                    placeholder="Organization Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_organization"
                    isInvalid={touched.job_organization && errors.job_organization}
                    value={values.job_organization}
                    type="text"
                    as="input"
                    />
                <Form.Control.Feedback type="invalid">
                    {errors.job_organization}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6} col={12}>
                <Form.Label htmlFor="event_photo">Organization Logo</Form.Label>        
                <Button variant="contained" color="default" className={`logo-upload-btn ${touched.event_photo && errors.event_photo? "is-invalid": ""}`} tabIndex="-1">
                <Form.Control
                    placeholder="Job organization Logo"
                    onChange={(event) => {
                        setFieldValue("job_organization_logo", event.currentTarget.files[0]);
                        setUploadState("File selected");
                        }}
                    onBlur={handleBlur}
                    name="job_organization_logo"
                    isInvalid={touched.job_organization_logo && errors.job_organization_logo}
                    className={`fileInputPic ${touched.job_organization_logo && errors.job_organization_logo ? "is-invalid" : ""}`}
                    type="file"
                    as="input"
                    accept="image/png, image/jpeg"
                    aria-label="Organization Logo Upload"
                    />
                    <i className="material-icons mr-2">cloud_upload</i>
                    Upload
                </Button>
                <span> {uploadState}</span>                                  
                <Form.Control.Feedback type="invalid">
                    {errors.job_organization_logo}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label>Organization Description</Form.Label>
                <Form.Control
                    placeholder="Tell us about your Organization."
                    name="job_organization_detail"
                    className=""
                    value={values.job_organization_detail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={5}
                    isInvalid={touched.job_organization_detail && errors.job_organization_detail}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.job_organization_detail}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
    </>
    )
};

export default OrganizationDescription;


