import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";

import JobCard from "./JobCard";
import { LoaderWithText } from "../../../components/Loader";
import { getUserAppliedJobs } from "../../../redux/user";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const JobList = ({
  isSearching,
  jobs,
  appliedJobs,
  getUserAppliedJobs,
  onLoadNext,
  hasMore,
}) => {
  const direction = i18n.dir();
  const { t } = useTranslation();

  let content;

  if (isSearching) {
    return <LoaderWithText key="1" padding="30px 0" text={t("Please wait...")} />;
  }

  const onApply = () => {
    getUserAppliedJobs();
  };

  const onWithdraw = () => {
    getUserAppliedJobs();
  };

  const isAppliedJob = (jobId) =>
    appliedJobs.find((attendingJob) => attendingJob.job.id === jobId);

  if (jobs && jobs.length) {
    const firstJobId = jobs[0].id;
    const jobsJsx = jobs.map((job) => {
      const attendance = isAppliedJob(job.id);
      return (
        <JobCard
          key={job.id}
          job={job}
          onApply={() => onApply(job)}
          onWithdraw={() => onWithdraw(job)}
          isApplied={attendance || false}
          applicationId={attendance ? attendance.id : null}
          firstJobId={firstJobId}
        />
      );
    });
    content = (
      <InfiniteScroll
        pageStart={1}
        loadMore={onLoadNext}
        hasMore={hasMore}
        loader={<LoaderWithText key="2" margin="40px 0 0" text={t("Please wait...")} />}
      >
        {jobsJsx}
      </InfiniteScroll>
    );
  } else {
    content = (
      <div className="ic-app-content-right--wrapper d-flex flex-column">
        <div
          className="ic-page-search-results"
          style={{ borderRadius: "16px" }}
        >
          <div className="ic-pages-search-results-header container-fluid">
            <div className="row">
              <div className="ic-pages-search-results-header--wrapper">
                <div className="results-header-title">
                  <header className="header-plain">
                    <h2
                      className="m-0 pl-2"
                      style={{
                        fontWeight: "700",
                        fontSize: "1.2rem",
                        color: "#000000",
                        fontFamily: `${direction === "rtl" ? "Almarai" : ""}`
                      }}
                    >
                      {t("Search results")}
                    </h2>
                  </header>
                </div>
              </div>
            </div>
          </div>{" "}
          <h3 className="mx-3 py-4 text-red pl-3">
            {t("No jobs were found for the selected criteria.")}
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="ic-pages-search-results-body container-fluid mt-0">
      <div className="row">
        <div
          className="ic-pages-search-results-body--wrapper pt-0"
          style={{ width: "100%" }}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    appliedJobs: state.user.appliedJobs,
  }),
  {
    getUserAppliedJobs,
  }
)(JobList);
