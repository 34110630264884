import { login, register, profile, getAttendedEvents,getAppliedJobs } from '../../api';
import { addToast } from '../toast';
import { setLoading } from '../ui';
import { getNotifications } from '../notifications';
import { Mixpanel } from '../../mixpanel';

const initialState = {
  authenticated: false,
  impactboardlink: null,
  availableProfiles: null,
  userFeed: [],
  attendingEvents: [],
  appliedJobs: [],
  activeProfile: null,
  activeProfileMeta: {
    type: '',
    id: '',
  },
  completeProfile: true,
};

export default (state = initialState, action) => {
  if (action.type === 'SET_AUTHENTICATED') {
    return {
      ...state,
      authenticated: action.authenticated,
    };
  }

  if (action.type === 'SET_AVAILABLE_PROFILES') {
    return {
      ...state,
      availableProfiles: action.availableProfiles,
    };
  }

  if (action.type === 'SET_ACTIVE_PROFILE') {
    
    return {
      ...state,
      activeProfile: action.payload.profile,
      activeProfileMeta: {
        type: action.payload.type,
        id: action.payload.profile.id,
      },
    };
  }

  if (action.type === 'SET_ACTIVE_PROFILE_META') {
    return {
      ...state,
      activeProfileMeta: {
        type: action.payload.type,
        id: action.payload.id,
      },
    };
  }

  if (action.type === 'SET_COMPLETE_PROFILE') {
    return {
      ...state,
      completeProfile: action.completeProfile,
    };
  }

  if (action.type === 'SET_USER_FEED') {
    return {
      ...state,
      userFeed: action.userFeed,
    };
  }

  if (action.type === 'SET_ATTENDING_EVENTS') {
    return {
      ...state,
      attendingEvents: action.attendingEvents,
    };
  }
  if (action.type === 'SET_APPLIED_JOBS') {
    
    return {
      ...state,
      appliedJobs: action.appliedJobs,
    };
  }
  if (action.type === 'SET_IMPACT_LINK') {
    
    return {
      ...state,
      impactboardlink: action.impactboardlink,
    };
  }


  if (action.type === 'LOGOUT') {
    
    return {
      authenticated: false,
      availableProfiles: null,
      userFeed: [],
      attendingEvents: [],
      appliedJobs:[],
      activeProfile: null,
      activeProfileMeta: {
        type: '',
        id: '',
      },
      completeProfile: true,
    };
  }

  return state;
};

export const setAvailableProfiles = (availableProfiles) => ({
  type: 'SET_AVAILABLE_PROFILES',
  availableProfiles,
});

export const setAuthenticated = (authenticated) => ({
  type: 'SET_AUTHENTICATED',
  authenticated,
});

export const setActiveProfile = (payload) => ({
  type: 'SET_ACTIVE_PROFILE',
  payload: {
    profile: payload.profile,
    type: payload.type,
  },
});

export const setActiveProfileMeta = (payload) => ({
  type: 'SET_ACTIVE_PROFILE_META',
  payload: {
    type: payload.type,
    id: payload.id,
  },
});

export const setCompleteProfile = (completeProfile) => ({
  type: 'SET_COMPLETE_PROFILE',
  completeProfile,
});

export const setUserFeed = (feed) => ({
  type: 'SET_USER_FEED',
  userFeed: feed,
});

const setAttendingEvents = (attendingEvents) => ({
  type: 'SET_ATTENDING_EVENTS',
  attendingEvents,
});

const setAppliedJobs = (appliedJobs) => ({
  type: 'SET_APPLIED_JOBS',
  appliedJobs,
});

export const setImpactboardlink = (impactboardlink) => ({
  type: 'SET_IMPACT_LINK',
  impactboardlink,
});

export const logoutUser = () => (dispatch) => {
  
  localStorage.removeItem('Token');
  localStorage.removeItem('activeProfileMeta');
  dispatch({
    type: 'LOGOUT',
  });

  

};

export const registerUser = (authData) => () => (
  new Promise((resolve, reject) => {
    register(authData)
      .then(({ data }) => {
        resolve();
      })
      .catch((err) => {
        reject(err.response);
      });
  })
);

//Error if no Org Profile - TO BE FIXED
export const switchProfile = (switchedProfile) => (dispatch) => {
  const isOrg = "organization_title" in switchedProfile
  localStorage.setItem('activeProfileMeta', JSON.stringify({
    type: switchedProfile.main_profile ? 'individual' :isOrg?'org':'pod',
    id: switchedProfile.id,
  }));
  dispatch(setActiveProfile({
    profile: switchedProfile,
    type: switchedProfile.main_profile ? 'individual' :isOrg?'org':'pod',
  }));
  return Promise.resolve();
};

export const getAttendingEvents = () => (dispatch, getState) => {
  getAttendedEvents(getState().user.activeProfile.id)
    .then(({ data }) => {
      
      dispatch(setAttendingEvents(data.events));
    })
    .catch(() => {
      dispatch(setAttendingEvents([]));
    });
};

export const getUserAppliedJobs = () => (dispatch) => {
  getAppliedJobs()
    .then(({ data }) => {
      
      dispatch(setAppliedJobs(data.applications));
    })
    .catch(() => {
      
      dispatch(setAppliedJobs([]));
    });
};


export const getAvailableProfiles = () => (dispatch, getState) => {
  profile()
    .then(({ data }) => {
      
      dispatch(setAvailableProfiles(data));

      const { activeProfileMeta: { id, type } } = getState().user;
      var USER_ID = data.user_info.id;
      Mixpanel.identify(USER_ID);

      var USER_SIGNUP_DATE = data.user_info.date_joined;
      var email = data.user_info.email;

      Mixpanel.people.set({
        "$email": email,
        "Sign up date": USER_SIGNUP_DATE,
        "USER_ID": USER_ID,
        "usertype":data.individual.identifies_as
      });

      if (!id && !type) {
        dispatch(switchProfile(data.individual));
      } else if (type === 'pod') {
        const podProfile = data.pod.find((currProfile) => currProfile.id === id);

        if (podProfile) {
          dispatch(switchProfile(podProfile));
        } else {
          dispatch(switchProfile(data.individual));
        }
      }
      else if (type==='org'){
        const orgProfile = data.org.find((currProfile) => currProfile.id === id);

        if (orgProfile) {
          dispatch(switchProfile(orgProfile));
        } else {
          dispatch(switchProfile(data.individual));
        }
      }
       else {
        dispatch(switchProfile(data.individual));
      }
      if (data.individual.person_first_name !== '' && data.individual.person_last_name !== '' && data.individual.person_city !== null && data.individual.person_summary !== '' && data.individual.person_work_towards_project !== '' && data.individual.person_skill.length !== 0) {
        dispatch(setCompleteProfile(true));
      }
      else {
        dispatch(setCompleteProfile(false));
      }

      dispatch(getNotifications());
      dispatch(getAttendingEvents());
      dispatch(getUserAppliedJobs());

    })
    .catch(() => {
      dispatch(addToast({
        title: 'Error',
        text: 'An error occurred. Please try again later.',
        type: 'error',
      }));
    });
};

export const loginUser = (authData) => (dispatch) => (
  new Promise((resolve, reject) => {
    login(authData)
      .then(({ data }) => {
        localStorage.setItem('Token', data.key);
        dispatch(setAuthenticated(true));
        

        resolve();
      })
      .catch((err) => {
        reject(err.response);
      });
  })
);
