import React, { useState, useEffect } from "react";
import { NavLink as Link } from "react-router-dom";
import { connect } from "react-redux";
import { processDate } from "../../../common/utils";
import { LoaderWithText } from "../../../components/Loader";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const MyJobs = ({ appliedJobs }) => {
  const [jobs, setJobs] = useState([]);
  const [jobloading, setjobloading] = useState(false);

  // const getjobs=()=>{
  //   setjobloading(true)
  //   getOrganizationJobs(profile.slug)
  //   .then(({ data }) => {

  //     const jobss = data.jobs.sort(function(a,b){
  //       return new Date(b.created_at) - new Date(a.created_at);
  //     });

  //     setJobs(jobss);
  //   setjobloading(false)

  //   });
  // }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (appliedJobs.length) {
      setJobs(appliedJobs);
      setjobloading(false);
    }
  }, [appliedJobs]);

  if (jobloading)
    return <LoaderWithText padding="150px 100px" text="Please wait..." />;
  return (
    <Jobwrapper>
      <section className="organizationCard d-flex flex-column">
        <div
          className="cardHeading pb-3 d-flex align-items-center justify-content-between"
          style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}
        >
          My Opportunities
        </div>
        <div className="outerJobWrapper">
          {jobs && jobs.length ? (
            jobs &&
            jobs.map((item, index) => (
              <JobItems key={item.id} job={item.job} index={index} length={jobs.length} />
            ))
          ) : (
            <h3 className="text-red pt-3">
              {"You have not applied for any opportunity yet."}
            </h3>
          )}
        </div>
      </section>
    </Jobwrapper>
  );
};

const JobItems = ({ job, index, length }) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div
      className="jobs-wrapper justify-content-between col-12 col-md-12"
      style={{
        borderBottom: `${length - 1 === index ? "none" : "1px solid #EFEFEF"}`,
      }}
    >
      <div className="d-flex">
        <img
          src={
            job.job_organization_logo &&
            job.job_organization_logo !==
              "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
              ? job.job_organization_logo
              : job.organization
              ? job.organization.organization_logo
              : job.listed_by.organization_logo
          }
          className="job-img"
          alt="job-img"
        />
        <div className="jobDetailWrapper pl-3 align-self-center">
          <Link
            to={`/opportunity/${job.slug}`}
            className="RedColr"
            style={{ fontWeight: "bold" }}
          >
            {job.job_title}
          </Link>
          <div className="jobcompany">
            {job.organization
              ? job.organization.organization_title
              : job.listed_by.organization_title}
          </div>
        </div>
      </div>
      <Button
        className="modal-cancel-button"
        disabled
        style={{ cursor: "default", color: '#191919', borderColor: '#5F5F5F', backgroundColor: '#fff' }}
      >
        Applied
      </Button>
    </div>
  );
};

export default connect(
  (state) => ({
    appliedJobs: state.user.appliedJobs,
  }),
  {}
)(MyJobs);

const Jobwrapper = styled.div`
  //margin-top: 6.6rem !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 100%;

  .organizationCard {
    margin-top: 1rem;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    padding: 20px;
    font-size: 14px;
    line-height: 141.5%;
    color: #000000;
  }
  .cardHeading {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  .outerJobWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .jobs-wrapper .job-img {
    width: 91px;
    height: 91px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  }
  .RedColr {
    color: #000 !important;
    font-weight: 700;
    font-size: 18px;
  }
  .jobcompany {
    padding-top: 3px;
    font-size: 16px;
    /* line-height: 19px; */
    color: #000000;
  }
  .jobtitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
  .jobpostedDate {
    padding-top: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #a4a4a4;
  }
  .joblocation {
    padding-top: 5px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .jobs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
  }
  .jobs-wrapper {
    padding: 21px 0px;
  }
`;
