import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import styled from "styled-components";

import { Container } from "../common/Layout";

import { LoaderWithText } from "./Loader";
import { addToast } from "../redux/toast";
import { getPostById, getFeedPostById } from "../api";
import { Colors } from "../styles/constants";
import PostCard from "./post/PostCard";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const PostPage = ({ currentProfile, addToast }) => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const direction = i18n.dir();
  const { t } = useTranslation();

  const onPostDeleted = () => <Redirect to="/profile" />;

  useEffect(() => {
    getFeedPostById(postId) //need to change this
      .then(({ data }) => {
        setPost(data);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        addToast({
          title: t("Error"),
          text: t("The post wasn't found."),
          type: "error",
        });
      });

    // if (userId && postId) {
    //   getPostById(userId, postId)
    //     .then(({ data }) => {
    //
    //       setPost(data);

    //       setLoading(false);
    //     })
    //     .catch(() => {
    //       setLoading(false);

    //       addToast({
    //         title: "Error",
    //         text: "The post wasn't found.",
    //         type: "error"
    //       });
    //     });
    // }
  }, [postId]);

  if (loading) return <LoaderWithText minHeight="78vh" text={t("Please wait...")} />;

  if (!post && !loading)
    return (
      <PostNotFound>
        {t("The post wasn't found. It may have been deleted.")}
      </PostNotFound>
    );

  if (currentProfile) {
    return (
      <ContainerWithVerticalPadding className={direction === "rtl" ? "arabic-display" : ""}>
        <PostCard
          posterName={`${
            post.posted_by.organisation
              ? post.posted_by.organisation.organization_title
              : post.posted_by.person.full_name
          }`}
          activityId={post.id}
          postedBy={post.posted_by}
          postedTo={post.posted_on}
          content={post.body}
          postedOn={post.created_at}
          reactions={post.reactions}
          comments={post.comments}
          commentsOpened
          photos={post.photos}
          onDelete={() => onPostDeleted(post.id)}
          fullpost={post}
          isfull
        />
        {/* 
        <PostCard
          posterName={`${post.activity_by.person_first_name} ${post.activity_by.person_last_name}`}
          activityId={post.id}
          postedBy={post.activity_by}
          postedTo={post.content_object.person}
          content={post.content_object}
          postedOn={post.created_at}
          comments={post.comments}
          commentsOpened
          onDelete={() => onPostDeleted(post.id)}
        /> */}
      </ContainerWithVerticalPadding>
    );
  }
  return <LoaderWithText padding="150px 0 0" text={t("Please wait...")} />;
};

export default connect(
  (state) => ({
    currentProfile: state.user.activeProfile,
  }),
  {
    addToast,
  }
)(PostPage);

const PostNotFound = styled.p`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${Colors.font.primary};
  font-size: 2rem;
`;

const ContainerWithVerticalPadding = styled(Container)`
  padding: 5rem 0;
`;
