import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";

import styled from "styled-components";
import { Container } from "../../common/Layout";
import { LoaderWithText } from "./../Loader";
import { addToast } from "../../redux/toast";
import PostCard from "./PostCard";

const PostDetail = ({
  currentProfile,
  addToast,
  show,
  post,
  onClose,
  imgIndex,
  onDeletePic,
}) => {

  if (currentProfile) {
    return (
      <Modal
        show={show}
        centered
        size="xl"
        onHide={onClose}
        style={{ marginTop: "40px" }}
        className="modelTopmargin carousalModal"
      >
        <Modal.Body className="p-0">
          <Modal.Header className="headeer" closeButton></Modal.Header>
          <ContainerWithVerticalPadding>
            <PostCard
              posterName={`${
                post.posted_by.organisation
                  ? post.posted_by.organisation.organization_title
                  : post.posted_by.person.full_name
              }`}
              activityId={post.id}
              postedBy={post.posted_by}
              postedTo={post.posted_on}
              content={post.body}
              postedOn={post.created_at}
              reactions={post.reactions}
              comments={post.comments}
              commentsOpened
              photos={post.photos}
              onDelete={() => onPostDeleted(post.id)}
              postDetail
              imgIndex={imgIndex}
              onDeletePic={onDeletePic}
              fullpost={post}
            />
          </ContainerWithVerticalPadding>
        </Modal.Body>
      </Modal>
    );
  }
  return <LoaderWithText padding="150px 0 0" text="Please wait..." />;
};

export default connect(
  (state) => ({
    currentProfile: state.user.activeProfile,
  }),
  {
    addToast,
  }
)(PostDetail);


const ContainerWithVerticalPadding = styled(Container)`
  //padding: 5rem 0;
  max-width: 100% !important;
  .spinner-border{
    width:5rem;
    height:5rem;
  }
  .imageLayout .pre_line{
    padding: 20px 20px 10px;
  }
  .modal-header{
    padding:0;
    border:0;
  }
  .ic-feed-profile--wrapper .ic-feed-profile{
    padding:0 20px !important
  }
  .carousel-item img{
    max-height:700px
    max-width:100%;
    //margin-top: 1rem;
  }
`;
