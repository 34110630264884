import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import DatePicker from "../../../components/FormikDatepicker";
import Form from "../../../common/Form";
import Onboarding, {
  OnboardingHeader,
  AuthFooter
} from "../../../common/Onboarding";
import Button from "../../../components/Button";
import CountryCity from "../../../components/CountryCity";
import { addToast } from "../../../redux/toast";
import { switchProfile, getAvailableProfiles } from "../../../redux/user";
import { getOrganizationIndustries, addOrganization } from "../../../api";
import { format } from "date-fns";

const AddOrganization = ({ addToast, switchProfile, getAvailableProfiles }) => {
  const history = useHistory();
  const [industries, setIndustries] = useState([]);
  const [userCity, setUserCity] = useState(null);
  const [cityValid, setCityValid] = useState(false);

  const orgSchema = yup.object().shape({
    organization_industry: yup
      .string()
      .required("Please provide organization industry.")
      .max(45),
      organization_size: yup
      .string()
      .required("Please provide organization size.")
      .max(45),
    organization_title: yup
      .string()
      .required("Please provide organization title.")
      .max(45),
    organization_tagline: yup.string().required("Please provide a tagline."),
    organization_overview: yup
      .string()
      .required("Please provide a brief description of your organization."),
    organization_email: yup
      .string()
      .email("Enter a valid email.")
      .required("Please provide organization email."),
    organization_founded: yup
      .date()
      .required("Please provide organization founded date."),
    organization_website: yup
      .string()
      .required("Please provide organization website.")
      .matches(
        /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i,
        "Enter a valid URL."
      ),
    organization_phone_number: yup
      .string()
      .required("Please provide organization contact number.")
      .matches(
        /^\+(?:[0-9]●?){10,13}[0-9]$/,
        "Contact number should start with + and contain 11 - 14 digits. No spaces allowed."
      ),
  });

  const initialValues = {
    organization_industry: "",
    organization_size: "",
    organization_title: "",
    organization_tagline: "",
    organization_overview: "",
    organization_email: "",
    organization_founded: new Date(),
    organization_website: "",
    organization_phone_number: "",
  };

  const onClickNext = (values, { setSubmitting }) => {

    if (userCity !== null) {
      values.organization_city = Number(userCity);
      const data = {
        ...values,
        organization_founded: format(values.organization_founded, "yyyy-MM-dd"),
        organization_industry: Number(values.organization_industry),
        organization_website: values.organization_website.includes("https://") ? values.organization_website : `https://${values.organization_website}`,
      };

      addOrganization(data)
        .then((response) => {
          switchProfile(response.data).then(() => {
            getAvailableProfiles();
            setSubmitting(false);
            addToast({
              title: "Success",
              text: `Organization created successfully...redirecting to profile page.`,
              type: "success",
            });

            setTimeout(() => {
              history.push("/profile/");
            }, 3000);
          });
        })
        .catch((error) => {
          setSubmitting(false);
          addToast({
            type: "error",
            title: "Error",
            text: `${Object.values(error.response.data)[0] ? Object.values(error.response.data)[0] : "An error occurred, please try again."}`,
          });
        });
    } else {
      setSubmitting(false);
      setCityValid(true);
    }
  };

  useEffect(() => {
    let unmounted = false;
    getOrganizationIndustries().then(({ data }) => {
      if (!unmounted) {
        setIndustries(
          data.map((item) => ({ value: item.id, label: item.industry_title }))
        );
      }
    });
    return () => { unmounted = true };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AddOrg>
      <Onboarding classes="ic-about-container">
        <div className="container-fluid">
          <div className="row">

            <div className="col-sm-12 col-md-12 ic-container-right whiteBackground">
              <aside className="add-organization-wrapper">
                <h1 role="heading" className="main-heading mb-0">Create an organization profile</h1>
                <div className="ic-form-wrapper d-flex justify-content-center">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={orgSchema}
                    onSubmit={onClickNext}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      touched,
                      isSubmitting,
                    }) => (
                      <Form onSubmit={handleSubmit} noValidate>
                        <Form.Group>
                          <Form.Label>Organization Title</Form.Label>
                          <Form.Control
                            placeholder="Enter organization title"
                            name="organization_title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.organization_title}
                            isInvalid={
                              touched.organization_title &&
                              errors.organization_title
                            }
                            type="text"
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.organization_title}
                          </Form.Control.Feedback>
                        </Form.Group>
             
                        <Row>
                          <Form.Group as={Col} col={12} md={6}>
                            <Form.Label>Industry</Form.Label>
                            <Form.Control
                              value={values.organization_industry}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="organization_industry"
                              as="select"
                              className="ic-form-select"
                              isInvalid={
                                touched.organization_industry &&
                                errors.organization_industry
                              }
                            >
                              <option value="" disabled>
                                Select Industry
                              </option>
                              {industries.map((industry) => (
                                <option
                                  key={industry.value}
                                  value={industry.value}
                                >
                                  {industry.label}
                                </option>
                              ))}
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                              {errors.organization_industry}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} col={12} md={6}>
                            <Form.Label>Founded Date</Form.Label>
                            <DatePicker
                              nativeInputAriaLabel="Select founded date"
                              placeholderText="When was the organization founded?"
                              name="organization_founded"
                              maxDate={new Date()}
                            />
                          </Form.Group>
                        </Row>
                        <CountryCity
                          citytext="City"
                          countrytext="Country"
                          cityValid={cityValid}
                          setCityValid={setCityValid}
                          setUserCity={setUserCity}
                          //person_city={profile.person_city}
                          row
                        />
                        <Row>
                          <Form.Group as={Col} col={12} md={6}>
                            <Form.Label>Organization Email</Form.Label>
                            <Form.Control
                              placeholder="myorg@example.com"
                              name="organization_email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.organization_email}
                              isInvalid={
                                touched.organization_email &&
                                errors.organization_email
                              }
                              type="text"
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.organization_email}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} col={12} md={6}>
                            <Form.Label>Organization website</Form.Label>
                            <Form.Control
                              placeholder="www.myorg.com"
                              name="organization_website"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.organization_website}
                              isInvalid={
                                touched.organization_website &&
                                errors.organization_website
                              }
                              type="text"
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.organization_website}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col} col={12} md={6}>
                            <Form.Label>Company Size</Form.Label>
                            <Form.Control
                              value={values.organization_size}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="organization_size"
                              as="select"
                              className="ic-form-select"
                              isInvalid={
                                touched.organization_size &&
                                errors.organization_size
                              }
                            >
                              <option value="" disabled>
                                Select Company Size
                              </option>
                              <option value="1">1 - 5 Employees</option>
                              <option value="2">5 - 20 Employees</option>
                              <option value="3">20 - 50 Employees</option>
                              <option value="4">50 - 250 Employees</option>
                              <option value="5">250 - 1000 Employees</option>
                              <option value="6">1000 - 10000 Employees</option>
                              <option value="7">10000 - 100000 Employees</option>
                              <option value="8">100000+ Employees</option>
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                              {errors.organization_size}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} col={12} md={6}>
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                              placeholder="+000112223333"
                              name="organization_phone_number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.organization_phone_number}
                              isInvalid={
                                touched.organization_phone_number &&
                                errors.organization_phone_number
                              }
                              type="text"
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.organization_phone_number}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group as={Col} col={12} md={12}>
                            <Form.Label>Organization Tagline</Form.Label>
                            <Form.Control
                              placeholder="Enter a tagline"
                              name="organization_tagline"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.organization_tagline}
                              isInvalid={
                                touched.organization_tagline &&
                                errors.organization_tagline
                              }
                              type="text"
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.organization_tagline}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Form.Group className="form-group">
                          <Form.Label>Organization Overview</Form.Label>
                          <Form.Control
                            placeholder="Enter a brief description"
                            name="organization_overview"
                            value={values.organization_overview}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="textareaHeight"
                            as="textarea"
                            isInvalid={
                              touched.organization_overview &&
                              errors.organization_overview
                            }
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.organization_overview}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Row>
                          <Form.Group as={Col} col={8}>
                            <Button
                              loading={isSubmitting}
                              type="submit"
                              className="create-button"
                            >
                              Create Organization
                            </Button>
                          </Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </Onboarding>
    </AddOrg>
  );
};

export default connect(
  (state) => ({
    availableProfiles: state.user.availableProfiles,
    activeProfile: state.user.activeProfile,
  }),
  {
    switchProfile,
    addToast,
    getAvailableProfiles,
  }
)(AddOrganization);

const AddOrg = styled.div`
  padding: 57px 0px 0px;
  background-color: #fff;

  .show-on-mobile {
    display: none;
  }

  label.form-label:after {
    content: " *";
    color: #000000;
  }

  aside.d-none.d-md-block {
    padding-top: 55px;
}

  .ic-bottom .ic-bottom-wrapper ul li a {
    color: rgb(166, 167, 171);
    font-size: 1.4rem;
}

  .ic-bottom .ic-bottom-wrapper ul li:nth-child(1) {
    margin-right: 20px;
  }

  ul.d-flex.flex-md-nowrap.flex-lg-wrap.flex-wrap {
    padding-left: 0;
    list-style: none;
}

  @media (max-width: 768px) {
    .ic-container-left {
      display: none !important;
    }
    .ic-container-right {
      padding: 0px 30px !important;
    }
    .show-on-mobile {
      display: block;
      margin-top: 40px !important;
      font-size: 24px !important;
    }

    h2 {
      font-size: 18px !important;
      border-bottom: 1px solid #dadbe2;
      padding-bottom: 25px;
    }
    .ic-form-wrapper {
      margin-top: 20px !important;
    }
    .ic-container-right aside {
      margin: 0 !important;
    }
    .footer {
      display: none !important;
    }
    .ic-bottom .ic-bottom-wrapper ul li a {
      color: #757575 !important;
      font-size: 1rem !important;
      padding-right: 12px;
    }
  }

  @media (max-width: 800px) and (min-width: 600px) {
    .ic-container-left {
      min-height: 40vh;
    }
  }

  @media (max-width: 1024px) and (min-width: 600px) {
    .ic-container-right {
      padding: 60px 30px;
    }
    .ic-container-left {
      padding: 20px 30px;
    }
    li.active {
      padding-left: 45px !important;
    }
  }
`;
