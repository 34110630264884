import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import TagList from "../../../components/TagList";
import Hobbies from "../../PersonProfile/components/Hobbies";
import WorkExperince from "../../PersonProfile/components/WorkExperience";
import EducationHistory from "../../PersonProfile/components/EducationHistory";
import Achievements from "../../PersonProfile/components/Achievements";
import MyPortfolio from '../../PersonProfile/components/Portfolios';
import { getProfileById, getPersonContact, getJobApplication } from "../../../api";
import { processDate } from '../../../common/utils';

const ViewJobApplicant = ({
  show,
  profileId,
  closeDialog,
  status,
  handleChange,
  isSubmittingR,
  isSubmittingS,
  jobId,
  applicationId,
}) => {
  const [profile, setProfile] = useState(null);
  const [profileLoadind, setProfileLoading] = useState(true);
  const [isOwner, setisOwner] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(status);
  const [applicationAnswers, setApplicationAnswers] = useState([]);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [open, setOpen] = useState(false);

  const onProfileUpdated = () => {};

  useEffect(() => {
    let unmounted = false;
    if (show) {
      if (profile === null) setProfileLoading(true);
      getProfileById(profileId)
        .then((response) => {
          if (!unmounted) {
            setProfile(response.data);
            setProfileLoading(false);
          }
        })
        .catch((error) => {});
    }
    else {
      setEmail("");
      setPhoneNumber("");
    }
  setOpen(false);
  return () => { unmounted = true }; 
  }, [show]);

  useEffect(() => {
    let unmounted = false;
    getJobApplication(jobId, applicationId)
    .then(({data}) => {
      if (!unmounted) {
        setApplicationAnswers(data.application_answers);
      }
    })
    .catch((error) => {});
  return () => { unmounted = true }; 
  }, [profileId]);

  const handleContact = () => {
    if (email === "") {
      getPersonContact(profileId)
      .then(({data}) => {
          setEmail(data.person_email);
          setPhoneNumber(data.person_phone_number);
      })
      .catch(() => {
        setEmail("");
        setPhoneNumber("");
      });
    }
  }

  var person_of_determination = false;
  if (profile) {
    if (profile.identifies_as == "Proud to be Person of Determination" || profile.identifies_as == "Person with Disability") {
      person_of_determination = true;
    }
  }

  return (
    <Modal
      show={show}
      centered
      size="xl"
      onHide={closeDialog}
      style={{ marginTop: "20px", marginBottom: '20px' }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>Application Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {profileLoadind ? (
          <div className="d-flex justify-content-center p-5">
            <i
              className="fas fa-spinner fa-spin"
              style={{ fontSize: "58px" }}
            />
          </div>
        ) : (
          profile && (
            <ProfileViewer>
              <div className="container d-flex flex-column flex-md-row justify-content-md-around">
                <div className="col-md-5">
                  <div className="d-flex flex-column justify-content-center align-items-center user-data-wrapper p-3">
                    <div className="imgWrapper">
                      <img src={profile.person_display_picture} alt="Profile Picture" title="Profile Picture"/>
                    </div>
                    <div className="row user-details-container d-flex flex-column justify-content-center text-center pb-0 mb-0">
                      <div className="user-level">
                        <div className="level-wrapper">
                          <div className="outerRing"></div>
                        </div>
                      </div>
                      <div className="user-name">
                        <div className="user-name--wrapper">
                          <span className="user-name-btn">
                            {profile.person_first_name}
                            &nbsp;
                            {profile.person_last_name}
                          </span>
                        </div>
                      </div>
                      <div className="user-app-type">
                        <div className={"user-app-type--wrapper"}>
                          <span>{profile.person_headline}</span>
                        </div>
                      </div>
                      <div className="user-app-type">
                        <div className={"user-app-type--wrapper"}>
                          <span>{profile.identifies_as}</span>
                        </div>
                      </div>
                      {profile.person_city && (
                        <div className="user-app-loc">
                          <div className="user-app-loc--wrapper d-flex align-items-center justify-content-center">
                            <i className="material-icons ml-2 mr-1">location_on</i>
                            <span className="locText">
                              {profile.person_city &&
                                profile.person_city.city_name}
                              ,{" "}
                              {profile.person_city &&
                                profile.person_city.city_country.country_name}
                            </span>
                          </div>
                        </div>
                      )}
                        <div className="user-app-loc">
                          <div className="user-app-loc--wrapper d-flex align-items-center justify-content-center">
                            <i className="material-icons ml-2 mr-1">date_range</i>
                            <span className="locText">
                              {processDate(profile.person_dob, "do MMMM yyyy")}
                            </span>
                          </div>
                        </div>
                      <Collapse in={open}>
                        <div id="person-contact-collapse"> 
                          <div className="person-email d-flex align-items-center justify-content-center pt-1">
                              <i className="material-icons mr-1">email</i>
                              <span className="person-email-link" onClick={() => { window.open(`mailto:${email}`); }}>{email === "" ? "Email Unavailable" : email}</span>
                          </div>
                          {phoneNumber !== "" && (
                          <div className="person-phone-number d-flex align-items-center justify-content-center pt-1">
                              <i className="material-icons mr-1">call</i>
                              <span className="person-phone-number-link" onClick={() => { window.open(`tel:${phoneNumber}`); }}>{phoneNumber}</span>
                          </div>
                          )}
                        </div>
                      </Collapse>
                    </div>
                      <Button
                        onClick={() => {handleContact(); setOpen(!open);}}
                        aria-label="Toggle Person Contact"
                        aria-controls="person-contact-collapse"
                        aria-expanded={open}
                        variant="danger"
                        size="md"
                        block
                        className="mt-3 person-contact-button"
                      >
                        Contact
                      </Button>
                  </div>

                  <div className="user-ability user-data-wrapper">
                    <div className="user-story-header">
                      <div>
                        <h2>Story</h2>
                      </div>
                    </div>
                    <hr />
                    <div className="user-story-text">
                      {profile && profile.person_work_towards_project}
                    </div>
                  </div>

                  <div className="user-ability user-data-wrapper">
                    <div className="user-story-header">
                      <div>
                        <h2>Abilities</h2>
                      </div>
                    </div>
                    <hr />
                    <div className="user-story-text">
                      <div className="abilities-wrapper">
                        {profile &&
                        profile.person_skill &&
                        profile.person_skill.length ? (
                          <TagList
                            onUpdated={onProfileUpdated}
                            singularName="Ability"
                            profileId={profile.id}
                            name="Abilities"
                            items={profile.person_skill}
                            itemName="person_skill"
                            isOwner={false}
                          />
                        ) : (
                          <>
                            <h3 className="pb-2 text-red">
                              {isOwner
                                ? "You have not added any skills yet."
                                : "This user has not added any skills yet."}
                            </h3>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* {person_of_determination && ( */}
                    <div className="user-ability user-data-wrapper">
                      <div className="user-story-header">
                        <div>
                          <h2>Preferred Accomodations</h2>
                        </div>
                      </div>
                      <hr />
                      <div className="user-story-text">
                        <div className="abilities-wrapper">
                          {profile &&
                          profile.person_accomodation &&
                          profile.person_accomodation.length ? (
                            <TagList
                              onUpdated={onProfileUpdated}
                              isOwner={isOwner}
                              singularName="Accomodation"
                              profileId={profile.id}
                              name="Accomodations"
                              items={profile.person_accomodation}
                              itemName="person_accomodation"
                            />
                          ) : (
                            <>
                              <h3 className="text-red m-0 pre_line">
                                No accommodations required.
                              </h3>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                  <Hobbies
                    onUpdated={onProfileUpdated}
                    profile={profile}
                    isOwner={isOwner}
                  />

                </div>

                <div className="col-md-7">
                  {profile && (
                    <WorkExperince
                      profileId={profile.id}
                      experience={profile.experience}
                      isOwner={isOwner}
                      refreshProfile={onProfileUpdated}
                    />
                  )}
                  {profile && (
                    <EducationHistory
                      profileId={profile.id}
                      education={profile.education}
                      isOwner={isOwner}
                      refreshProfile={onProfileUpdated}
                    />
                  )}

                  {profile && (
                    <Achievements
                      achievements={profile.achievement}
                      isOwner={isOwner}
                      refreshProfile={onProfileUpdated}
                      profileId={profile.id}
                    />
                  )}

                  {profile && profile.project.length ? (
                    <MyPortfolio 
                    portfolios={profile.project} 
                    isOwner={isOwner} 
                    refreshProfile={onProfileUpdated} 
                    profileId={profile.id}/>
                  ) : ""}
                </div>
              </div>

              <div className="container d-flex flex-column flex-md-row justify-content-md-around">
                <div className="col-md-12">
                  {applicationAnswers.length ? (
                        <div className="user-ability user-data-wrapper">
                          <div className="user-story-header">
                            <div>
                              <h2>Screening Questions</h2>
                            </div>
                          </div>
                          <hr />
                          {applicationAnswers.map((application, index) => (
                            <div key={application.id} className="application-answer-wrapper">
                              <div className="user-story-text">
                                <div className="question-wrapper">
                                  {application.question.question}
                                </div>
                                <div className="answer-wrapper">
                                  {application.answer.choice}
                                </div>
                              </div>
                              {index+1 === applicationAnswers.length ? "" : <hr/>}
                            </div>
                          ))}
                        </div>
                        ) : ""}
                  </div>
                </div>

            </ProfileViewer>
          )
        )}
      </Modal.Body>

      <Modal.Footer>
        <Container>
          <ProfileViewer>
          <Row>
            <Col className="mt-3 mt-md-0 d-flex" sm={12} md={6}>
              {profile && profile.person_cv_file !== null ? (
              <a href={profile.person_cv_file} download={`${profile.full_name}'s Resume`} target="_blank" className="download-resume pr-3">
              <i className="material-icons mr-1" style={{fontSize: "20px"}}>file_download</i>
                Resume
              </a>
              ):""}
              {profile && profile.person_supporting_doc_file !== null ? (
              <a href={profile.person_supporting_doc_file} download={`${profile.full_name}'s Supporting Document`} target="_blank" className="download-resume">
              <i className="material-icons mr-1" style={{fontSize: "20px"}}>file_download</i>
                Supporting Document
              </a>
              ):""}
            </Col>
            <Col sm={12} md={2}>
            </Col>
            <Col className="mt-3 mt-md-0" sm={12} md={2}>
              <Button
                disabled={status === "R" ? true : false}
                onClick={() => handleChange("R")}
                loading={isSubmittingR ? 1 : 0}
                block
                size="md"
                className="reject-button"
              >
                {isSubmittingR ? "Please wait..." : "Reject"}
              </Button>
            </Col>
            <Col className="mt-3 mt-md-0" sm={12} md={2}>
              <Button
                disabled={status === "S" ? true : false}
                onClick={() => handleChange("S")}
                loading={isSubmittingS ? 1 : 0}
                block
                size="md"
                className="shortlist-button"
              >
                {isSubmittingS ? "Please wait..." : "Shortlist"}
              </Button>
            </Col>
          </Row>
          </ProfileViewer>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewJobApplicant;

const ProfileViewer = styled.div`
font-family: 'Inter', sans-serif;
  font-style: normal;
  margin: 0;
  max-width: 100%;
  position: relative;
  white-space: pre-line;

.imgWrapper > img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    object-fit: cover;
}
}
.user-data-wrapper{
    margin: 1rem 0rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 0.4rem;
    padding: 1rem !important;
  }

  .user-story-header{
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-story-header h2, h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    @media (max-width: 900px){
      padding-left: 1rem;
    }
  }
  .user-story-header > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-story-text{
    //font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding-top: 0.5rem;
  }
  .user-data-wrapper{
    margin: 1rem 0rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 0.4rem;
    padding: 2rem 2.2rem;
  }
  .abilitytext{
    padding-left: 11px;
    font-size: 18px;
    color: #FFFFFF;
  }
  .crossWrapper{
    width: 31px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #B11030;
    border-radius: 25px 0px 0px 25px;
  }
  .ability{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #43A0F5;
    border-radius: 25px 25px 25px 25px;
    padding: 0px;
    padding-right: 10px;
  }
  .abilities-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .abilities-wrapper .title {
    font-weight: 600 !important;
    font-size: 18px !important;
}
.abilities-wrapper .text {
  padding-bottom: 1rem;
}
  .experience-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: border: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    padding: 1rem 0rem;
 
  }
  .companywrapper{
    display: flex;
    align-items: center;
  }
  .companywrapper > img {
    width: 91px;
    height: 91px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  .companyDetails{
      padding-left: 25px;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
  }
  .companyDetails div{
    padding-top:3px;
  }
  .position{
    font-weight: bold;
    font-size: 18px;
    padding-top:0px;
  }
  .answer-wrapper {
    font-weight: 600;
  }
  .user-achievments{
    display: flex;
    align-items: flex-start;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
  }
  .blueDot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blueDot > img {
    padding: 6px 0px;
  }
  .blueDot div {
    width: 0px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .abilities-wrapper .title{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .abilities-wrapper .place{
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    font-weight: 300;
  }
  .user-name-btn{
    font-weight: bold;
    font-size: 24px;
    color: #000;
  }
  .user-app-type, .user-app-loc, .person-email, .person-phone-number {
    font-weight: 500;
    color: #000;
  }
  // .person-email-link, .person-phone-number-link {
  //   color: #01579b;
  // }
  .person-email-link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .person-phone-number-link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .person-contact-button {
    width: 40%;
    height: 40px;
    letter-spacing: 0.04em;
    font-weight: 500;
    border-radius: 6px;
    background-color: #3F0E40 !important;
    border-color: #3F0E40 !important;
  }
  .reject-button, .shortlist-button {
    height: 40px;
    letter-spacing: 0.04em;
    font-weight: 500;
    border-radius: 6px;
  }
  .shortlist-button {
    color: #fff;
    background-color: #3F0E40 !important;
    border-color: #3F0E40 !important;
    &:active {
      color: #fff !important;
      background-color: #3F0E40 !important;
      border-color: #3F0E40 !important;
    }
  }
   .reject-button {
    color: #000;
    background-color: #fff !important;
    border: 2px solid #5F5F5F;
    &:active {
      color: #000 !important;
      background-color: #fff !important;
      border-color: #5F5F5F !important;
    }
  }
  .btn.disabled, .btn:disabled {
    cursor: default;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
  .download-resume {
    display: flex;
    align-items: center;
    height: 40px;
    letter-spacing: 0.04em;
    font-weight: 500;
  }
`;
