import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import LeftSideBar from "./components/LeftSideBar";
import RightSideBar from "./components/RightSideBar";
import { getAvailableProfiles } from "../../redux/user";
import EditOrganizationProfile from "./components/EditOrganizationProfile";
import { updateOrganization } from "../../api";
import { addToast } from "../../redux/toast";

const OrganizationProfile = ({
  activeProfile,
  getAvailableProfiles,
  addToast,
}) => {
  const [isEdittingProfile, setIsEdittingProfile] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [publicView, setPublicView] = useState(false);
  const [isOwner, setIsOwner] = useState(true);

  const refreshProfile = () => {
    getAvailableProfiles();
  };

  const onProfileUpdated = () => {
    getAvailableProfiles();
    setIsEdittingProfile(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshProfile();
  }, []);

  return (
    <OrganizationalProfile className="container-fluid d-flex flex-column ">
      {/* Display Public View */}
      {publicView && (
        <div style={{ marginBottom: "" }}>
          <div className="publicProfileView">
            <div>This is what your profile looks like to Public.</div>
            <div>
              <Button
                className="m-2 profile-follow-btn purple-btn"
                variant="primary"
                onClick={() => {
                  setPublicView(false);
                  setIsOwner(true);
                }}
                style={{ padding: '1.05rem 0.8rem' }}
              >
                Exit Public View
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* Display pending verification tag if it's not public view and verified is false. */}
      {activeProfile && (
        !publicView && (
          activeProfile.verified === false ? (
            <div>
              <div className="pendingVerification">
                <div>Your profile is pending verification. Our team members will verify your profile in 24-48 hours and share an email notification.</div>
              </div>
            </div>
          ) : ""
        ))}
      {activeProfile && (
        <BannerMain
          profile={activeProfile}
          refreshProfile={refreshProfile}
          addToast={addToast}
          publicView={publicView}
        />
      )}
      <div className="container ic-app-dashboard--wrapper d-flex flex-column flex-md-row">
        {activeProfile && (
          <React.Fragment>
            <LeftSideBar
              isOwner={isOwner}
              refreshProfile={refreshProfile}
              profile={activeProfile}
              addToast={addToast}
            />
            {activeProfile && (
              <EditOrganizationProfile
                show={isEdittingProfile}
                isUpdating={isUpdating}
                onProfileUpdated={onProfileUpdated}
                closeDialog={() => setIsEdittingProfile(false)}
              />
            )}
            {activeProfile && (
              <RightSideBar
                profile={activeProfile}
                isOwner={isOwner}
                onProfileUpdated={refreshProfile}
                activeProfileId={activeProfile.owner_id}
                onEditProfile={() => setIsEdittingProfile(true)}
                publicView={publicView}
                TogglePublicView={() => {
                  setPublicView(!publicView);
                  setIsOwner(!isOwner);
                }}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </OrganizationalProfile>
  );
};
const BannerMain = ({ profile, refreshProfile, addToast, publicView }) => {
  const [imgsrc, setImgSrc] = useState('');
  const [uploading, setPhotoUploading] = useState(false);
  const [show, setShow] = useState(false);
  const width = window.innerWidth;

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onPhotoChanged(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const onPhotoChanged = (image) => {
    setPhotoUploading(true);
    const formData = new FormData();
    formData.append("organization_cover_photo", image, image.name);
    formData.append("organization_email", profile.organization_email);
    formData.append("organization_industry", profile.organization_industry.id);
    formData.append("organization_website", profile.organization_website);
    updateOrganization(profile.slug, formData)
      .then(() => {
        setPhotoUploading(false);
        refreshProfile();
        addToast({
          title: "Success",
          text: "Your Cover has been uploaded.",
          type: "success",
        });
      })
      .catch(() => {
        setPhotoUploading(false);
        addToast({
          title: "Error",
          text: "An error occurred.",
          type: "error",
        });
      });
  };
  return (
    <Banner
      className="d-flex justify-content-center align-items-center"
      style={{ backgroundImage: `url(${profile.organization_cover_photo})`, zIndex: `${publicView ? '-1' : ''}` }}
      // onClick={() => setShow(!show)}
    >
      {uploading && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {/* <img className="imageBanner" src={imgsrc}/> */}
      {!publicView && (
        <Dropdown
          className="NavDropdown update-cover-dropdown"
          id="collasible-nav-dropdown"
          show={show}
        >
          <Dropdown.Toggle
            className="NavDropdown update-cover-button"
            id="collasible-nav-dropdown"
            style={{ backgroundColor: '#fff', color: '#5F5F5F', border: '2px solid #5F5F5F', padding: '0.675rem 0.8rem' }}
            onClick={() => setShow(!show)}
          >
            <i className="material-icons" style={{ color: '#5F5F5F' }}>edit</i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0.7rem 0 0.7rem 0.7rem', overflow: 'hidden' }} align="end">
            <Dropdown.Item aria-label="Block Profile" className="d-flex align-items-center">
              <i className="material-icons mr-2" style={{ color: '#5F5F5F', fontSize: '30px' }}>photo_camera</i>
              <div style={{ fontSize: '1rem', textAlign: 'left' }}>
                Update Cover Image
                <div style={{ fontSize: '0.8rem', color: '#5F5F5F' }}>{width} x 400px recommended</div>
              </div>
            </Dropdown.Item>
            <input
              accept="image/png, image/jpeg"
              type="file"
              onChange={handleChange}
              onClick={() => setShow(false)}
              name="myfile"
              className="cover-image-input"
            />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Banner>
  );
};
export default connect(
  (state) => ({
    availableProfiles: state.user.availableProfiles,
    activeProfile: state.user.activeProfile,
  }),
  {
    getAvailableProfiles,
    addToast,
  }
)(OrganizationProfile);
const Banner = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 400px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  position: relative;
  width: 100%;
  position: relative;
  }
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .cover-image-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    right: 0;
  }
  .overlay > .upload-profile-picture {
    position: absolute;
    background: #f8f9fa !important;
    color: #5F5F5F;
    border: 2px solid #5f5f5f !important;
    border-radius: 2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0 !important;
    transition: all 250ms ease;
    transform: translateX(-50%);
    &:focus {
      opacity: 1 !important;
      border: 0.15rem solid black;
    }
  }
  // .overlay {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba(52, 73, 94, 0.75);
  //   overflow: hidden;
  //   width: 100%;
  //   height: 0;
  //   transition: .5s ease;
  // }
  .imageBanner {
    width: 100%;
  }
  .percentageBox {
    position: absolute;
    width: 88px;
    height: 50px;
    right: 290px;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b11030;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 100;
    @media (max-width: 768px) {
      width: 70px;
      height: 40px;
      right: 185px;
    }
  }
  .percentage {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 38px;
    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .levelBox {
    position: absolute;
    width: 180px;
    height: 70px;
    right: 150px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #01579b;
    box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    @media (max-width: 768px) {
      width: 150px;
      height: 60px;
      right: 70px;
    }
  }
  .leveltext {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 23px;
    color: #ffffff;
    padding-right: 0.5rem;
    @media (max-width: 768px) {
      font-size: 1rem;
      padding-right: 0.8rem;
    }
  }
  .leveltop {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 38px;
    color: #ffffff;
    padding-right: 0.5rem;
    @media (max-width: 768px) {
      font-size: 1.2rem;
      padding-right: 0.3rem;
      line-height: 1.4rem;
    }
  }
  .outerBox {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
  }
  .spinner-border {
    width: 5rem;
    height: 5rem;
  }
`;
const OrganizationalProfile = styled.div`
font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
padding-top: 50px;
padding-right: 0px;
padding-left: 0px;
white-space: pre-line;
min-height:78vh;
.publicProfileView{
  //position:fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 38px;
  margin-top: 6px;
  background: #7C7C7E;
  color: white;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  @media(max-width: 576px) {
    padding: 0 15px;
    font-size: 1rem;
  }
}
.pendingVerification {
  //position:fixed;
  padding: 0.8rem;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #CD4A04;
  color: #ffffff;
  width: 100%;
}
.paddingtopless{
  padding-top: 25px;
}
.dashboard-right-sidebar{
  width:67%
}
.RedColr{
  color: #000 !important;
  font-weight:700 !important;
  font-size: 18px;
}
.organizationalImage{
    width: 115px;
    height: 115px;
    border-radius: 50%;
    object-fit: cover;
}
.redBackGround{
  position: relative;
  background-color: #3F0E40 !important;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
  border-radius: 14px;
  padding: 30px 0 0 0;
}
.organizationPic{
  padding:20px
  padding-top: 80px;
}
.comapnyName{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  padding: 1rem;
  color: #FFFFFF;
  @media (max-width: 900px) and (min-width: 600px) {
    padding-right: 0;
    padding-top: 0.5rem;
  }
}
.listgroupred{
  background-color: transparent !important;
  border: 0px;
  font-size: 1rem;
}
.borderBottom{
  border-bottom: 1px solid #D2CACA;
  margin-bottom: 16px;
  padding-bottom: 20px;
}
.textfont{
  font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 23px;
color: #000000;
}
.organizationCard{
  margin-top: 1rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
  border-radius: 14px;
  padding: 24px;
  font-size: 14px;
  line-height: 141.5%;
  color: #000000;
  @media (max-width: 768px){
    padding: 1.6rem 1.8rem;
  }
}
.awardHeading{
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 36px;
  color: #B11030;
}
.cardHeading{
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}
.profile-heading h2{
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #B11030;
}
.section-friend-suggestion{
  margin-top: 1rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
  padding: 2rem 0px 2rem;
}
.suggestion-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 48px;
}
.section-friend-suggestion h2 {
  margin: 0px;
  font-weight: bold;
  font-size: 20px;
  color: #B11030;
  padding-left: 10px;
}
.friendSuggestion{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  border-bottom: 1px solid #EFEFEF;
  padding: 30px 35px 30px;
}
.friendSuggestion > img {
  width: 58px;
  height: 58px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.friendData > h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #5F5F5F;
  margin:0px;
}
.friendprofession{
  font-size: 14px;
  line-height: 17px;
  color: #757575;
  padding-top: 5px;
}
.friendSuggestion .userAbilityIcon{
  width: 37px;
  height: 37px;
  background: #B11030;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-level{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.outerRing{
  display: flex;
  padding: 2px;
  border-radius: 50px;
  border: 1px solid white;
}
.level-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 25px;
  background: #000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 28px;
}
.level{
  padding-left:6px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
.recent-activity-text > div {
  font-weight: bold;
  font-size: 18px;
  line-height: 124.5%;
  color: #000000;
  padding-bottom: 15px;
}
.my-story-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: black;
}
.view-all-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: none;
  color: black;
  &:hover {
    color: #5F5F5F;
  }
}
.suggestionBtn{
  top: -207px;
  font-weight: 600;
  position: relative;
  border-radius: 10px;
  font-size: 1.1rem;
  line-height: 1rem;
  margin-top: 1rem;
  @media (max-width: 768px){
    padding: 0.8rem;
    border-radius: 10px;
    font-size: 18px;
    line-height: 22px;
    border: 0;
    font-weight: 500;
    margin-top: 0;
  }
}
.awardtext{
  font-weight: 300;
    font-size: 1rem;
    line-height: 22px;
    color: #000000;
}
.jobs-wrapper .job-img {
    width: 91px;
    height: 91px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
}
.outerJobWrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.jobs-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:50%;
}
.jobs-wrapper{
  padding: 21px 0px;
}
.jobpostedDate{
  padding-top: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #A4A4A4;
}
.joblocation{
  padding-top: 5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.jobcompany{
   padding-top: 3px;
  font-size: 16px;
    /* line-height: 19px; */
    color: #000000;
}
.jobtitle{
  font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.iconwrapper{
     width: 43.3px;
    height: 43.3px;
    background: #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin-right: 4px;
}
.paddingLeftCenter{
  padding-left: 60px;
}
.redBackGround .ContactBtn {
  background: #FFFFFF;
  border-radius: 0.4rem;
  color: #5F5F5F !important;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 0.3;
  width: 250px;
  height: 45px;
  align-self: center;
  &:hover, :active, :focus {
    background: #FFFFFF !important;
    color: #5F5F5F !important;
  }
  @media (max-width: 768px){
    font-size: 1rem;
    line-height: 0.5rem;
  }
  
}
.redBackGround .ContactBtn > a {
  color: #5F5F5F !important;
  &:hover {
    text-decoration: none;
  }
}
.update-cover-dropdown {
  position: absolute;
  right: 20px;
  top: 30px;
}
.update-cover-dropdown .dropdown-menu.dropdown-menu.show {
  left: auto !important;
  right: -98px;
  top: auto;
}
.update-cover-button {
    background-color: rgb(255, 255, 255);
    color: rgb(95, 95, 95);
    border: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.2rem;
    width: 3.2rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.org-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background: #3F0E40;
    color: #fff;
    border: none;
    &:active {
    background-color: #3F0E40 !important;
    color: #fff !important;
    border: none;
    }
}
.org-action-button:hover {
  color: #d6cece;
}
.user-app-mutal--wrapper{
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
}
.mutalimage{
  width: 39px;
  height: 39px;
  border: 1px solid #FFF9F9;
  border-radius:50px;
}
.mutal-text{
  padding-top: 8px;
  font-size: 15px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #736D6D;
  padding-left: 14px;
}
.mutal-img{
  padding-right: 31px;
  position: relative;
}
.imgAbsoulte{
  position: absolute;
  left: 28px;
  top: 16px;
}
.followUsbtn{
  width: 93px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}
.ic-app-dashboard--wrapper {
  padding: 10px 45px 50px;
  width: 100%;
  position: relative;
}
.show-on-mobile {
  display: none;
}
@media (max-width: 440px) {
  
  .show-on-regular {
    display: none;
  }
  .show-on-mobile {
    display: block;
    margin-top: 1rem;
  }
  .button-container {
    justify-content: center !important;
    margin-bottom: 0.25rem;
  }
  .profile-follow-btn{
    width: 48.5%;
    margin-top: 0.5rem;
  }
  .view-as {
    width:48.5%;
    margin-top: 0.5rem;
  }
  .profile-more-dropdown {
    width: 48.5%;
  }
  .publicProfileView .profile-follow-btn {
    width: 100% !important;
    font-size: 1rem;
    line-height: 18px;
    padding: 0.5rem !important;
  }
  .profile-more-dropdown .profile-follow-btn {
    width: 100% !important;
  }
}
@media (max-width: 767.98px) {
  .ic-app-dashboard--wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.ic-app-dashboard--wrapper .dashboard-left-sidebar {
  width: 33%;
  margin-top: 98px;
}
.paddingleftList{
  padding-left: 28px;
}
@media (max-width: 767.98px) {
  .ic-app-dashboard--wrapper .dashboard-left-sidebar {
    width: 100%;
  }
}
.ic-app-dashboard--wrapper
  .dashboard-left-sidebar
  aside
  .left-sidebar--wrapper
  .user-details-container {
  padding: 25px 0;
}
.sidebar-top {
    top: -207px;
    position: relative;
    @media(max-width: 440px) {
      top: 0;
    }
}
.sidebar-bottom {
    top: -207px;
    position: relative;
     @media(max-width: 440px) {
      top: 0;
    }
}
.ic-app-dashboard--wrapper
.dashboard-left-sidebar
aside
.left-sidebar--wrapper
.ic-app-dashboard--wrapper .dashboard-right-sidebar {
  width: 67%;
}
@media (max-width: 767.98px) {
  .ic-app-dashboard--wrapper .dashboard-right-sidebar {
    width: 100%;
  }
}
.ic-app-dashboard--wrapper .dashboard-right-sidebar aside {
  margin-left: 21px;
}
@media (max-width: 767.98px) {
  .ic-app-dashboard--wrapper .dashboard-right-sidebar aside {
    margin-left: 0;
    margin-top: 0.3rem;
  }
  
}
@media (max-width:480px){
  
.dashboard-right-sidebar > aside{
  width:100% !important;
}
  .organizationCard{
    padding: 20px !important;
  
  }
  .friendSuggestion{
    padding: 30px 10px 30px !important;
  }
  .colFlex{
    flex-direction:column !important;
  }
  .redBackGround {
    position: initial !important;
  }
  .left-sidebar--wrapper{
    max-height: none !important;
  }
  .dashboard-left-sidebar{
    margin-top:0px !important;
  }
  .mobbannericon{
    position: relative;
    height: 75px;
    marggion-top: 12px;
    margin-top: 21px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px;
  }
  
  .levelBox{
    left: 20px;
    top: 150px;
  }
  .percentageBox {
    left: -10px !important;
    top: 160px;
  }
  .paddingleft{
    padding-left:0px !important;
  }
}
    .user-picture--wrapper {
      position: relative;
    
    }
    .upload-profile-picture {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #f8f9fa !important;
      color: #5F5F5F;
      border: 2px solid #5f5f5f !important;
      border-radius: 2rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 250ms ease;
      transform: translateX(-50%);
      &:focus {
        opacity: 1 !important;
        border: 0.15rem solid black;
      }
    }
  }
    .user-picture--wrapper
    a
    picture
    img {
      width: 200px;
      height: 200px;
    border-radius: 50%;
    box-sizing: border-box;
    object-fit: cover;
    background: #fff;
    border: 1px solid #c4c4c4;
  }
  
.pages-results-item{
  width:100%
}
.pages-date{
    padding-right: 22px;
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: #dadbe2;
    width:12%;
}
.date-month{
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 0;
  padding-top: 15px;
}
.date-day{
  font-size: 40px;
  font-weight: bolder;
  line-height: 1.5em;
  @media (max-width: 768px){
    font-size: 1.8rem;
  }
}
.pages-content{
  margin-left: 15px;
  width:85%;
}
.page-date p {
  margin-bottom: 0;
  color: #a6a7ab;
  line-height: 2;
}
.page-location p {
  color: #a6a7ab;
    line-height: 0;
}
.pages-results-item .accessibility-location{
  padding-left: 18px;
}
.orgnav-link {
  color: #ffffff;
  //padding-left: 0.4rem;
  &:hover {
    text-decoration: none;
    color: #d6cece;
  }
}
button.profile-follow-btn {
  background-color: #3F0E40;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  margin-bottom: 2px;
  border-radius: 2rem;
  font-size: 18px;
  line-height: 10px;
  border: 0;
  font-weight: 500;
  height: 44px;
}
.purple-btn:active {
  background-color: #3F0E40 !important;
  border-color: #3F0E40 !important;
}
.ib-post-dropdown {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #B11030;
}
.dropdown-toggle::after {
  content: none;
}
a.dropdown-item {
  padding: 0.8rem;
}
a.dropdown-item:active {
background: white;
color: #212529;
}
`;