import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CropImage from "./CropImage";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

//Image Rotate and Crop for IB and Profile Photo Upload.
const EditImage = ({ show, image, onClose, index, photoChanged }) => {
  const [editor, SetEditor] = useState(null);
  const [scaleValue, SetScaleValue] = useState(1);
  const [rotate, setRotate] = useState(0);
  const setEditorRef = (e) => SetEditor(e);
  const { t } = useTranslation();
  const direction = i18n.dir();
  

  const onScaleChange = (scaleChangeEvent) => {
    const newScaleValue = parseFloat(scaleChangeEvent.target.value);
    SetScaleValue(newScaleValue);
  };

  const rotateleft = () => {
    let newRotation = rotate - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotate(newRotation);
  };

  const rotateRight = () => {
    let newRotation = rotate + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotate(newRotation);
  };

  const onCrop = () => {
    const i = image;
    if (editor !== null) {
      const url = editor.getImage().toDataURL();
      const newImage = dataURLtoFile(url, i.name);
      onClose();
      photoChanged(newImage, index);
      setRotate(0);
      SetEditor(null);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <Modal
      show={show}
      centered
      size="md"
    //onHide={onClose}
      className={direction === "rtl" ? "arabic-display" : ""}
    >
      <Modal.Header>{t("Edit Image")}</Modal.Header>
      <Modal.Body>
        {image ? (
          <React.Fragment>
            <CropImage
              imageSrc={image}
              setEditorRef={setEditorRef}
              rotation={rotate}
              leftRotate={rotateleft}
              rightRotate={rotateRight}
              scaleValue={scaleValue}
              onScaleChange={onScaleChange}
            />
          </React.Fragment>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <Button
                onClick={onClose}
                block
                size="lg"
                variant="outline-danger"
              >
                {t("Cancel")}
              </Button>
            </Col>
            <Col className="mt-3 mt-md-0" sm={12} md={6}>
              <Button onClick={onCrop} block size="lg" variant="danger">
                {t("Upload Photo")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default EditImage;
