import React from "react"; 
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";


const JobEqualEmploymentDisclaimer = ({ sectionValid, handleChange, handleBlur, values, errors, touched, companyName, disclaimer, setDisclaimer }) => {

    const handleDisclaimer = (disclaimer) => {
        setDisclaimer(disclaimer);
    }

return (
        <> 
        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label>Disclaimer of Equal Employment<span style={{color: "#dc3545"}}> *</span></Form.Label>
                <Form.Check
                    className="py-2"
                    type="radio"
                    label={`At ${companyName}, diverse, inclusive culture is vital to our mission. We offer opportunities for individuals of all abilities and backgrounds.`}
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    value="disclaimerOne"
                    checked={disclaimer === "disclaimerOne" ? "checked" : ""}
                    onChange={() => handleDisclaimer("disclaimerOne")}
                />
                <Form.Check
                    className="py-2"
                    type="radio"
                    label={`It is the policy of ${companyName} not to discriminate against any applicant for employment, or any employee because of age, color, sex, ability, national origin, race, religion, or veteran status.`}
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                    value="disclaimerTwo"
                    checked={disclaimer === "disclaimerTwo" ? "checked" : ""}
                    onChange={() => handleDisclaimer("disclaimerTwo")}
                />
                <Form.Check
                    className="py-2"
                    type="radio"
                    label={`At ${companyName}, we advocate for inclusion through action. All of our employment practice from screening to placement is equal for everyone.`}
                    name="formHorizontalRadios"
                    id="formHorizontalRadios3"
                    value="disclaimerThree"
                    checked={disclaimer === "disclaimerThree" ? "checked" : ""}
                    onChange={() => handleDisclaimer("disclaimerThree")}
                />
                <Form.Check
                    className="py-2"
                    type="radio"
                    label="Other"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios4"
                    value="disclaimerOther"
                    checked={disclaimer === "disclaimerOther" ? "checked" : ""}
                    onChange={() => handleDisclaimer("disclaimerOther")}
                />
      
            {disclaimer === "disclaimerOther" && (
                <Form.Control
                    placeholder="Please Share Disclaimer of Equal Employment"
                    name="job_disclaimer"
                    className=""
                    value={values.job_disclaimer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={4}
                    isInvalid={(touched.job_disclaimer && errors.job_disclaimer) || (!sectionValid && values.job_disclaimer === "")}
                />
            )}
                <Form.Control.Feedback type="invalid">
                    {errors.job_disclaimer}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>  
    </>
    )
};

export default JobEqualEmploymentDisclaimer;


