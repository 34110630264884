import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SVG from "react-inlinesvg";
import InclusiveLogo from "../assets/svg/InclusiveLogoBig.svg";

export const OnboardingHeader = () => (
  <header className="ic-header">
    <div className="ic-logo">
      <Link to="/" tabIndex="-1">
        <SVG src={InclusiveLogo} title="Inclusive Logo"/>  
      </Link>
    </div>
  </header>
);

export const AuthFooter = () => (
  <footer className="d-none d-md-block">
    <div className="ic-bottom">
      <div className="ic-bottom-wrapper">
        <ul className="d-flex flex-md-nowrap flex-lg-wrap flex-wrap">
          <li>
            <Link to="/termsandconditions" rel="nofollow">
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy" rel="nofollow">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
      <div className="ic-bottom-wrapper">
        <div className="d-flex flex-md-nowrap flex-lg-wrap flex-wrap">
          © 2020, INCLUSIVE. All rights reserved.
          </div>
      </div>
    </div>
  </footer>
);

export const AuthMobileFooter = () => (
  <StyledAuthMobileFooter className="d-sm-block d-md-none">
    <div className="container-fluid footer-wrapper">
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-5">
          <div className="ic-bottom">
            <div className="ic-bottom-wrapper">
              <ul className="d-flex flex-md-nowrap flex-lg-wrap flex-wrap">
                <li>
                  <Link to="/" target="_blank" rel="nofollow">
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/" target="_blank" rel="nofollow">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="ic-bottom-wrapper">
              <div className="d-flex flex-md-nowrap flex-lg-wrap flex-wrap">
                © 2020, INCLUSIVE. All rights reserved.
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledAuthMobileFooter>
);

export const OnboardingFooter = ({isAdd}) => (
  <StyledOnboardingFooter className="footer">
    <div className="container-fluid footer-wrapper">
      <div className="row">
        <div className={`col col-sm-12 col-md-12 ${isAdd?"col-lg-4":"col-lg-5"}`}>
          <div className="ic-bottom">
            <div className="ic-bottom-wrapper">
              <ul className="d-flex flex-md-nowrap flex-lg-wrap flex-wrap">
                <li>
                  <a href="#" target="_blank" rel="nofollow">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" rel="nofollow">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="ic-bottom-wrapper">
              <div className="d-flex flex-md-nowrap flex-lg-wrap flex-wrap">
                © 2020, INCLUSIVE. All rights reserved.
          </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </StyledOnboardingFooter>
);

const OnboardingContainer = ({ children, classes }) => {
  return (
    <StyledOnboardingContainer className={classes}>
      {children}
    </StyledOnboardingContainer>
  );
};

export default OnboardingContainer;

const StyledOnboardingContainer = styled.div`

  .ic-container-left {
    background-color: #f8f7fc;
    padding: 60px 80px;
    min-height: 85vh;
  }

  @media (max-width: 480px) {
    .ic-container-left {
      padding: 0 15px !important;
    }
  }

  .ic-container-left header {
    height: 15%;
    position: relative;
    resize: vertical;
  }

  .ic-container-left header .ic-logo {
    position: absolute;
    top: 32px;
    margin: auto 0;
  }

  .ic-container-left header .ic-logo img {
    max-width: 178px;
  }

  .ic-container-left main {
    height: 65%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    resize: vertical;
    margin: auto 0;
  }

  .ic-container-left main .ic-middle h1 {
    line-height: 1.5em;
  }

  .ic-container-left main .ic-middle p {
    font-size: 1.4rem;
    line-height: 1.5em;
  }

  .ic-container-right {
    min-height: 85vh;
    padding: 60px 140px;
  }

  @media (max-width: 480px) {
    .ic-container-right {
      padding: 0 15px !important;
    }
  }

  .ic-container-right aside {
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    resize: vertical;
    margin: auto 0;
  }

  @media (max-width: 768px) {
    .ic-container-right aside {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 480px) {
    .ic-container-right aside {
      height: auto;
    }
  }

  .ic-container-right aside .ic-cta-wrapper {
    position: relative;
  }

  .ic-container-right aside .ic-cta-wrapper .ic-cta-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    justify-content: space-around;
  }

  @media (max-width: 480px) {
    .ic-landing-container .ic-container-left {
      min-height: 50vh;
    }
  }

  @media (max-width: 480px) {
    .ic-landing-container .ic-container-left main {
      height: 85%;
    }
  }

  @media (max-width: 480px) {
    .ic-landing-container .ic-container-right {
      min-height: 50vh;
    }
  }

  @media (max-width: 480px) {
    .ic-landing-container .ic-container-right aside {
      height: 100%;
      margin-top: 0;
    }
  }

  &.ic-about-container .ic-container-left main {
    -webkit-box-pack: start;
    justify-content: flex-start;
    margin-top: 60px;
  }

  &.ic-about-container .ic-container-left main .ic-middle .ic-lists {
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    &.ic-about-container .ic-container-left main .ic-middle .ic-lists {
      min-height: 400px;
    }
  }

  &.ic-about-container .ic-container-left main .ic-middle .ic-lists ul {
    list-style: none;
    padding: 0;
  }

  &.ic-about-container .ic-container-left main .ic-middle .ic-lists ul li {
    padding: 25px 0;
    position: relative;
    padding-left: 70px;
    margin: 0;
  }

  &.ic-about-container .ic-container-left main .ic-middle .ic-lists ul li span {
    color: #a6a7ab;
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    left: 0;
    height: 45px;
    width: 45px;
    background: #edeaf6;
    text-align: center;
    border-radius: 20px;
    vertical-align: middle;
    line-height: 1.8;
  }

  &.ic-about-container .ic-container-left main .ic-middle .ic-lists ul li h2 {
    color: #a6a7ab;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 6px;
  }

  &.ic-about-container
    .ic-container-left
    main
    .ic-middle
    .ic-lists
    ul
    li.active
    span {
    color: #000 !important;
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    left: 0;
    height: 45px;
    width: 45px;
    background: #ffffff;
    text-align: center;
    border-radius: 20px;
    vertical-align: middle;
    line-height: 1.8;
  }

  &.ic-about-container
    .ic-container-left
    main
    .ic-middle
    .ic-lists
    ul
    li.active
    h2 {
    color: #000 !important;
  }

  &.ic-about-container .ic-container-right aside {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  &.ic-about-container .ic-container-right aside h1 {
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center;
    padding-right: 45px;
     @media(max-width: 440px) {
      font-size: 2rem;
      padding-right: 0;
      text-align: start;
    }
  }

  &.ic-about-container .ic-container-right aside .ic-form-wrapper {
    margin-top: 40px;
  }

  .ic-login-wrapper .main-heading, .ic-signup-wrapper .main-heading  {
    font-size: 4rem;
    font-weight: bold;
    @media(max-width: 440px) {
      font-size: 2.5rem;
    }
  }
  .ic-login-wrapper .sub-heading {
    @media(max-width: 440px) {
      font-size: 1.5rem;
    }
  }

 .ic-login-wrapper .ic-form-wrapper .form-label, .ic-signup-wrapper .ic-form-wrapper .form-label, .add-organization-wrapper .ic-form-wrapper .form-label {
    font-size: 1.2rem;
    font-weight: 500;
    color: #5F5F5F !important;
    margin-bottom: 0;
    margin-top: 1rem;
  } 

  .ic-login-wrapper .ic-form-wrapper .form-label.forgot-text {
    width: auto;
    min-width: auto;
    font-weight: 600;
    color: #3F0E40 !important;
    cursor: pointer;
    @media(max-width: 440px) {
      min-width: 50%;
      font-size: 1rem;
    }
  }

  .ic-login-wrapper .ic-form-wrapper .sign-up-text, .ic-signup-wrapper .ic-form-wrapper .login-text {
    font-weight: 500;
    color: #3F0E40 !important;
    font-size: 1.1rem;
    &:hover {
      text-decoration: none;
    } 
  }

   .ic-login-wrapper .ic-form-wrapper .text-lightgray, .ic-signup-wrapper .ic-form-wrapper .text-lightgray {
    font-weight: 500;
    font-size: 1.1rem;
    color: #5F5F5F;
  }

   .ic-login-wrapper .ic-form-wrapper .password__show, .ic-signup-wrapper .ic-form-wrapper .password__show{
    position: absolute;
    right: 0;
    top: 58px;
  }

  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }

   .ic-login-wrapper .ic-form-wrapper .login-button, .ic-signup-wrapper .ic-form-wrapper .signup-button, .add-organization-wrapper .ic-form-wrapper .create-button {
    font-weight: 500;
    background-color: #3F0E40 !important;
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 18px 0 18px 18px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    &:hover {
      transform: none;
      box-shadow: none;
      border-color: #3F0E40 !important;
    }
  }

  .ic-login-wrapper .ic-form-wrapper .login-button:focus-visible, .ic-signup-wrapper .ic-form-wrapper .signup-button:focus-visible, .add-organization-wrapper .ic-form-wrapper .create-button:focus-visible {
    outline: 3px solid #7c4dff !important;
    border: 0 !important;
  }

  .ic-signup-wrapper .ic-form-wrapper .signup-button:disabled {
    opacity: 0.8;
  }

  .ic-login-wrapper .ic-form-wrapper .form-control, .ic-signup-wrapper .ic-form-wrapper .form-control, .add-organization-wrapper .ic-form-wrapper .form-control {
    font-size: 1rem;
    border: none;
    border-bottom: 2px solid #d5d5d5;
    letter-spacing: 0.05rem;
    padding-left: 0;
    border-radius: 0;
    background-color: #fff;
  }

   .ic-login-wrapper .ic-form-wrapper .form-control:focus, .ic-signup-wrapper .ic-form-wrapper .form-control:focus, .add-organization-wrapper .ic-form-wrapper .form-control:focus {
    border: none;
    border-bottom: 2px solid #d5d5d5;
  }

  .ic-login-wrapper .ic-form-wrapper .form-control:focus-visible, .ic-signup-wrapper .ic-form-wrapper .form-control:focus-visible, .add-organization-wrapper .ic-form-wrapper .form-control:focus-visible {
    outline: 2px solid #000 !important;
    border: 0;
    border-radius: 5px;
    border-color: #d5d5d5 !important;
  }

  .add-organization-wrapper .ic-form-wrapper .textareaHeight.form-control {
    font-size: 1rem;
    border: none !important;
    border-bottom: 2px solid #d5d5d5 !important;
    letter-spacing: 0.05rem;
    border-radius: 0;
    padding: 20px 10px 0 0;
    background-color: #fff;
    height: auto !important;
  }

  .form-check-input {
    position: absolute;
    margin-top: 0.35rem;
    margin-left: -1.25rem;
    transform: scale(1.2);
}

label.form-check-label {
    color: #5F5F5F;
}

.form-check-link {
  color: #5F5F5F;
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }
}

  #passwordOne.form-control.is-invalid, #passwordTwo.form-control.is-invalid, #password.form-control.is-invalid {
    background-position: right calc(2em + .1875rem) center !important;
  }

  .ic-container-right .footer-container .footer-link a, .ic-container-right .footer-container .footer-link, .copyright-text {
    font-weight: 500;
    font-size: 1rem;
    color: #5F5F5F;
  }

  // .ic-container-right .footer-container .footer-link a:hover {
  //   text-decoration: none;
  // }

  .ic-container-right .footer-container .footer-link:not(:last-of-type)::after {
    content: none;
}

  &.ic-login-container {
    min-height: 100vh;
  }

  &.ic-login-container .ic-container-left {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ic-login-container .ic-container-left .ic-image {
    // width: 800px;
    width: auto;
  }

  &.ic-login-container .ic-container-left .ic-image img {
    width: 100%;
    object-fit: cover;
  }

  .send-icon-container {
    width: 250px;
    height: 250px;
    margin-bottom: 1rem;
    margin-left: 20%;
    @media (max-width: 480px) {
      width: 200px;
      height: 200px;
    }
  }

  .send-icon {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 480px) {
    &.ic-login-container .ic-container-left {
      min-height: 50vh;
    }
    .ic-container-left {
      display: none !important;
    }
    .ic-container-right {
      margin-top: 1rem;
    }
  }

  @media (max-width: 480px) {
    &.ic-login-container .ic-container-left header {
      height: 25%;
    }
  }

  @media (max-width: 480px) {
    &.ic-login-container .ic-container-left main h3 {
      font-size: 1.4em !important;
    }
  }

  &.ic-login-container .ic-container-left aside .ic-bottom {
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    resize: vertical;
    margin: auto 0;
    padding: 60px 0;
  }

  &.ic-login-container
    .ic-container-left
    footer
    .ic-bottom
    .ic-bottom-wrapper
    ul {
    list-style: none;
    padding: 0;
  }

  &.ic-login-container
    .ic-container-left
    footer
    .ic-bottom
    .ic-bottom-wrapper
    ul
    li
    a {
    color: #5C5C5C;
    font-size: 1.4rem;
  }

  &.ic-login-container
    .ic-container-left
    footer
    .ic-bottom
    .ic-bottom-wrapper
    ul
    li:nth-child(2) {
    margin-left: 36px;
  }

  @media (max-width: 480px) {
    &.ic-login-container
      .ic-container-left
      footer
      .ic-bottom
      .ic-bottom-wrapper
      ul
      li:nth-child(2) {
      margin-left: 0px;
    }
  }

  &.ic-login-container .ic-container-right h1 {
    margin-bottom: 25px;
    font-weight: bold;
    letter-spacing: 0.14px;
  }

  .ic-signup-block .ic-container-right .ic-form-check-wrapper {
    max-width: 960px;
    width: 100%;
  }

  .ic-signup-block .ic-container-right .ic-form-check-wrapper .ic-form-check {
    padding: 0;
    width: 50%;
    margin-right: 0;
  }

  .ic-signup-block
    .ic-container-right
    .ic-form-check-wrapper
    .ic-form-check:nth-child(1) {
    padding-right: 20px;
  }

  @media (max-width: 768px) {
    .ic-signup-block
      .ic-container-right
      .ic-form-check-wrapper
      .ic-form-check:nth-child(1) {
      padding-right: 2px;
    }
  }

  .ic-signup-block
    .ic-container-right
    .ic-form-check-wrapper
    .ic-form-check:nth-child(2) {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    .ic-signup-block
      .ic-container-right
      .ic-form-check-wrapper
      .ic-form-check:nth-child(2) {
      padding-left: 2px;
    }
  }

  .ic-signup-block .ic-container-right .ic-form-badge-wrapper {
    width: 100%;
  }

  @media (max-width: 480px) {
    .ic-signup-block .ic-container-right .ic-form-badge-wrapper p {
      width: 100%;
    }
  }

  .ic-signup-block .ic-container-right .ic-form-badge-wrapper ul {
    padding-left: 0;
    vertical-align: middle;
    position: relative;
    margin: 0;
    padding: 14px;
  }

  @media (max-width: 1400px) {
    .ic-signup-block .ic-container-right .ic-form-badge-wrapper ul {
      width: 100%;
      padding: 0;
    }
  }

  .ic-signup-block .ic-container-right .ic-form-badge-wrapper ul li {
    list-style: none;
    padding-left: 35px;
    display: inline-block;
    position: relative;
  }

  @media (max-width: 1400px) {
    .ic-signup-block .ic-container-right .ic-form-badge-wrapper ul li {
      padding-left: 0;
    }
  }

  @media (max-width: 480px) {
    .ic-signup-block .ic-container-right .ic-form-badge-wrapper ul li {
      padding-bottom: 10px;
    }
  }

  .ic-signup-block .ic-container-right .ic-form-badge-wrapper ul li p {
    color: #a6a7ab;
    margin-bottom: 0;
  }

  .ic-signup-block
    .ic-container-right
    .ic-form-badge-wrapper
    ul
    li
    p
    span.circle-radio {
    background: #fff;
    border: solid 1px #dadbe2;
    font-size: 0;
    padding: 12px;
    border-radius: 25px;
    position: absolute;
  }

  .ic-signup-block
    .ic-container-right
    .ic-form-badge-wrapper
    ul
    li
    p
    span.circle-text {
    margin-left: 35px;
  }

  .ic-signup-block .ic-container-right .form-row .col-md-6 {
    padding: 0;
  }

  .ic-signup-block .ic-container-right .form-row .col-md-6:nth-child(1) {
    padding-right: 20px;
  }

  @media (max-width: 768px) {
    .ic-signup-block .ic-container-right .form-row .col-md-6:nth-child(1) {
      padding-right: 0;
    }
  }

  .ic-signup-block .ic-container-right .form-row .col-md-6:nth-child(2) {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    .ic-signup-block .ic-container-right .form-row .col-md-6:nth-child(2) {
      padding-left: 0;
    }
  }
`;

const StyledOnboardingFooter = styled.footer`
  background: #ffffff;
  min-height: 10vh;

  .footer-wrapper .col {
    background-color: #f8f7fc;
    padding: 0 80px;
  }

  @media (max-width: 480px) {
    .footer-wrapper .col {
      padding: 0 15px;
    }
  }

  .footer-wrapper .col .ic-bottom {
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    resize: vertical;
    margin: auto 0;
    padding: 60px 0;
  }

  .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul {
    list-style: none;
    padding: 0;
  }

  .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li a {
    color: #a6a7ab;
    font-size: 1.4rem;
  }

  .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li:nth-child(1) {
    margin-right: 35px;
  }

  @media (min-width: 992px) {
    .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li:nth-child(1) {
      margin-right: 20px;
    }
  }

  @media (max-width: 576px) {
    .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li:nth-child(1) {
      margin-right: 0px;
    }
  }
`;

const StyledAuthMobileFooter = styled.footer`
  background: #ffffff;
  min-height: 10vh;

  .footer-wrapper .col {
    background-color: #f8f7fc;
    padding: 0 80px;
  }

  @media (max-width: 480px) {
    .footer-wrapper .col {
      padding: 0 15px;
    }
  }

  .footer-wrapper .col .ic-bottom {
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    resize: vertical;
    margin: auto 0;
    padding: 60px 0;
  }

  .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul {
    list-style: none;
    padding: 0;
  }

  .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li a {
    color: #a6a7ab;
    font-size: 1.4rem;
  }

  .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li:nth-child(1) {
    margin-right: 35px;
  }

  @media (min-width: 992px) {
    .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li:nth-child(1) {
      margin-right: 20px;
    }
  }

  @media (max-width: 576px) {
    .footer-wrapper .col .ic-bottom .ic-bottom-wrapper ul li:nth-child(1) {
      margin-right: 0px;
    }
  }
`;
