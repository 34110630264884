import React, {useMemo} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-2 footer-fixed ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-2">
          <span className="text-muted font-weight-bold">{today.toString()}</span> &copy;{" "}
          <a
            // href="http://keenthemes.com/metronic"
            // target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
            style={{ cursor: 'auto' }}
          >
            Inclusive
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-1">
          <a
            href="https://iminclusive.com/our-story"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0"
          >
            About
          </a>
          <a
            href="https://iminclusive.com/blog"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            Blog
          </a>
          <a
            href="/faqs"
            // target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            FAQs
          </a>
          <a
            href="/privacypolicy"
            // target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            Privacy Policy
          </a>
          <a
            href="/termsandconditions"
            // target="_blank"
            rel="noopener noreferrer"
            className="nav-link pl-3 pr-0"
          >
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
}
