import React from "react";
import { NavLink as Link } from "react-router-dom";
import { format } from 'date-fns';

const Footer = ({ currentLanguage, onLanguageChange }) => {
    return (
        <div className="footer_added" id="footerContainer">
            <div className="container text-center">
            <div className="row d-flex flex-column">
                <section id="lab_social_icon_footer">


        <div className="text-center center-block">
                <a href="https://twitter.com/iminclusive" target="_blank" aria-label="ImInclusive Twitter Profile (opens in a new tab)"><i id="social-tw" alt="twitter icon" className=" co fab fa-twitter fa-3x social"></i></a>
                <a href="https://www.facebook.com/Iminclusive/" target="_blank" aria-label="ImInclusive Facebook Profile (opens in a new tab)"><i id="social-fb" alt="facebook icon" className=" co fab fa-facebook fa-3x social"></i></a>
                <a href="https://www.instagram.com/iminclusive/" target="_blank" aria-label="ImInclusive Instagram Profile (opens in a new tab)"><i id="social-insta" alt="instagram icon" className=" co fab fa-instagram fa-3x social"></i></a>
                <a href="https://www.linkedin.com/company/iminclusive/about/" target="_blank" aria-label="ImInclusive LinkedIn Profile (opens in a new tab)"><i id="social-gp" alt="linkedin icon" className=" co fab fa-linkedin fa-3x social"></i></a>
                <a href="https://www.youtube.com/channel/UCs3byOnGxz6MAKfb31aCc2A" target="_blank" aria-label="ImInclusive Youtube Channel (opens in a new tab)"><i id="social-em" alt="youtube icon" className=" co fab fa-youtube fa-3x social"></i></a>
            </div>
        </section>
                    </div>
                    
                <div className="row footerLinkRow pt-3">
                    
                    <div className="footer-link">
                        <a href="https://iminclusive.com/blog" className="footer_links" target="_blank" aria-label="ImInclusive Blog (opens in a new tab)" rel="noopener noreferrer">Blog</a>
                    </div>
                    <div className="footer-link">
                        <Link className="footer_links" to="/faqs" aria-label="FAQs" >FAQs</Link>
                    </div>
                  
                    <div className="footer-link">
                        <Link className="footer_links privacyLink" to="/privacypolicy" aria-label="Privacy Policy" >Privacy Policy</Link>
                    </div>
                    <div className="footer-link">
                        <Link className="footer_links" to="/termsandconditions" aria-label="Terms & Conditions" >Terms & Conditions</Link>
                    </div>
                    {/* <div className="footer-link">
                    {currentLanguage === "en" ? (
                        <span className="footer_links" aria-label="Change Display Language" onClick={() => onLanguageChange("ar")} style={{fontFamily: "Almarai", cursor: 'pointer'}}>العربية</span> 
                    ) : (
                        <span className="footer_links" aria-label="Change Display Language" onClick={() => onLanguageChange("en")} style={{cursor: 'pointer'}}>English</span>
                    )}
                    </div> */}
                
                </div>
               <div className="row d-flex flex-column pt-3">
                 
                    <span className="copyright" style={{ color: "rgba(0, 0, 0, 0.8)" }}>&copy; {format(new Date(), "yyyy")} ImInclusive</span>
                </div>
                   
            </div>
        </div>
    )
}

export default Footer