import React, {useEffect, useState, useRef} from 'react';
import { addToast } from "../../redux/toast";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import AngryReaction from '../../assets/images/AngryReaction.png';
import CareReaction from '../../assets/images/CareReaction.png';
import CelebrateReaction from '../../assets/images/CelebrateReaction.png';
import AmazingReaction from '../../assets/images/AmazingReaction.png';
import HappyReaction from '../../assets/images/HappyReaction.png';
import LoveReaction from '../../assets/images/LoveReaction.png';
import SadReaction from '../../assets/images/SadReaction.png';
import WowReaction from '../../assets/images/WowReaction.png';
import { getCommentReactions, addCommentReaction, updateCommentReaction, deleteCommentReaction } from '../../api';
import useLongPress from '../../common/LongPress.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// Manage comment reactions.
const Reactions = ({ reactions, setReactionCount, reactionList, setReactionList, commentId, ownerId, activeProfileMeta, addToast, showDropdown, setShowDropdown }) => {
    const [userReactionPerson, setUserReactionPerson] = useState(reactions.reaction_person);
    const [userReactionOrg, setUserReactionOrg] = useState(reactions.reaction_org);
    const [reactionId, setReactionId] = useState(userReactionOrg ? userReactionOrg.id : userReactionPerson ? userReactionPerson.id : null);
    const [currentReactionType, setCurrentReactionType] = useState(userReactionOrg ? userReactionOrg.reaction_type : userReactionPerson ? userReactionPerson.reaction_type : "r1");
    const [hasReacted, setHasReacted] = useState(userReactionOrg || userReactionPerson ? true : false);
    const [currentReaction, setCurrentReaction] = useState(LoveReaction);
    const [currentReactionLabel, setCurrentReactionLabel] = useState("Love");
    const [ariaLabel, setAriaLabel] = useState("Love");
    const [showReactions, setShowReactions] = useState(false);
    const firstReaction = useRef(null);
    const { t } = useTranslation();
    const direction = i18n.dir();


    // Get the user reaction when profile is switched person <--> org.
    useEffect(() => {
        let unmounted = false;
        getCommentReactions(commentId)
        .then(({data}) => {
            if (!unmounted) {
                setUserReactionOrg(data.reaction_org);
                setUserReactionPerson(data.reaction_person);
            }
        })
        .catch(() => {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
        });
        if (activeProfileMeta.type === "org") {
            if (userReactionOrg !== null) {
                setCurrentReactionType(userReactionOrg.reaction_type);
                setReactionId(userReactionOrg.id);
                setHasReacted(true);
            }
            else {
                setCurrentReactionType("r1");
                setHasReacted(false);
            }
        }
        else if (activeProfileMeta.type === "individual") {
            if (userReactionPerson !== null) {
                setCurrentReactionType(userReactionPerson.reaction_type);
                setReactionId(userReactionPerson.id);
                setHasReacted(true);
            }
            else {
                setCurrentReactionType("r1");
                setHasReacted(false);
            }
        }
    return () => { unmounted = true };
    },[activeProfileMeta]);


    // Set the current reaction icon, label and aria-label based on reaction type.
    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
        currentReactionType === "r1" ? (
            setCurrentReaction(LoveReaction),
            setCurrentReactionLabel("Love"),
            setAriaLabel(hasReacted ? "Remove Love" : "Love")
            ) : 
        currentReactionType === "r2" ? (
            setCurrentReaction(CareReaction),
            setCurrentReactionLabel("Care"),
            setAriaLabel(hasReacted ? "Remove Care" : "Care")
        ) :
        currentReactionType === "r3" ? (
            setCurrentReaction(HappyReaction),
            setCurrentReactionLabel("Happy"),
            setAriaLabel(hasReacted ? "Remove Happy" : "Happy")
        ) :
        currentReactionType === "r4" ? (
            setCurrentReaction(AmazingReaction),
            setCurrentReactionLabel("Amazing"),
            setAriaLabel(hasReacted ? "Remove Amazing" : "Amazing")
        ) :
        currentReactionType === "r5" ? (
            setCurrentReaction(CelebrateReaction),
            setCurrentReactionLabel("Celebrate"),
            setAriaLabel(hasReacted ? "Remove Celebrate" : "Celebrate")
        ) :
        currentReactionType === "r6" ? (
            setCurrentReaction(WowReaction),
            setCurrentReactionLabel("Wow"),
            setAriaLabel(hasReacted ? "Remove Wow" : "Wow")
        ) :
        currentReactionType === "r7" ? (
            setCurrentReaction(SadReaction),
            setCurrentReactionLabel("Sad"),
            setAriaLabel(hasReacted ? "Remove Sad" : "Sad")
        ) :
        currentReactionType === "r8" ? (
            setCurrentReaction(AngryReaction),
            setCurrentReactionLabel("Angry"),
            setAriaLabel(hasReacted ? "Remove Angry" : "Angry")
        ) : ""}
    return () => { unmounted = true };
    },[currentReactionType]);

    // Handles adding, updating and deleting a reaction.
    const handleReaction = (reaction, reactionLabel, reactionType) => {
        if (hasReacted) {
            if (reaction === currentReaction) {
                deleteCommentReaction(reactionId)
                .then(() => {
                    onReactionDeleted(reactionId);
                    setCurrentReaction(LoveReaction);
                    setCurrentReactionType("r1");
                    setCurrentReactionLabel("Love");
                    setAriaLabel("Love");
                    setHasReacted(false);               
                })
                .catch(() => {
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    });
                });
            }
            else {
                updateCommentReaction(reactionId, {reaction_type: reactionType, reaction_by: ownerId})
                .then(({data}) => {
                    onReactionUpdated(data, reactionId);
                    setReactionId(data.id);
                    setCurrentReaction(reaction);
                    setCurrentReactionType(reactionType);
                    setCurrentReactionLabel(reactionLabel);
                    setAriaLabel(`Remove ${reactionLabel}`);
                    setHasReacted(true);
                })
                .catch(() => {
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    });
                });
            }
        }
        else {
            addCommentReaction(commentId, {reaction_type: reactionType, reaction_by: ownerId})
            .then(({data}) => {
                onReactionAdded(data);
                setReactionId(data.id);
                setCurrentReaction(reaction);
                setCurrentReactionType(reactionType);
                setCurrentReactionLabel(reactionLabel);  
                setAriaLabel(`Remove ${reactionLabel}`);
                setHasReacted(true);
            })
            .catch((error) => {
                if (Object.values(error.response.data)[0] === "Unauthorized") {
                    addToast({
                      type: "error",
                      title: "Error",
                      text: "Sorry, you're not authorized to perform this operation.",
                    });
                  }
                else {
                    addToast({
                      type: "error",
                      title: "Error",
                      text: "An error occurred, please try again.",
                    });
                }
            });
        }
        setShowReactions(false);
    }
    
    
    // Adds the reaction to the reaction list.
    const onReactionAdded = (newReaction) => {
        const newReactionList = [...reactionList];
        setReactionList((newReactionList) => [{id: newReaction.id, reaction_type: newReaction.reaction_type}, ...newReactionList]);
        setReactionCount(newReactionList.length + 1);
    }

    // Updates the reaction in the reaction list with the new.
    const onReactionUpdated = (newReaction, reactionId) => {
        const newReactionList = reactionList.filter((reaction) => reaction.id !== reactionId);
        setReactionList([newReaction, ...newReactionList]);
    }

    // Deletes the reaction from the reaction list.
    const onReactionDeleted = (reactionId) => {
        const newReactionList = [...reactionList];
        newReactionList.splice(newReactionList.findIndex((reaction) => reaction.id === reactionId), 1);
        setReactionList(newReactionList);
        setReactionCount(newReactionList.length);
    }

    // Handles focus for keyboard navigation.
    const handleKeyboardNav = (event) => {
        if (event.key === "Tab") {
            setShowReactions(!showReactions);
        }
        if (event.key === "Enter") {
            if (firstReaction.current !== null && showReactions) {
                firstReaction.current.focus();
            }
        }
    }

    // Handles onClick for clicking on a reaction on the reaction bar.
    const onClick = () => {
        handleReaction(currentReaction, currentReactionLabel, currentReactionType);
      }

    // Handles long press for touch screens. Displays reaction bar on long press.
    const onLongPress = () => {
        setShowReactions(true);
      };
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
      };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    

    return (
        // Renders reaction button and reaction bar.
        <StyledReactions>
            <Row className={`align-items-center reactions-bar ${direction === "rtl" ? "reactions-bar-rtl" : "reactions-bar-ltr"}`} onMouseEnter={() => setShowReactions(true)} onMouseLeave={() => setShowReactions(false)} style={{display: `${showReactions ? 'flex' : 'none'}`}}> 
                <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="love">{t("Love")}</Tooltip>}>
                        <img src={LoveReaction} alt="Love" className="reaction" onClick={() => handleReaction(LoveReaction, "Love", "r1")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(LoveReaction, "Love", "r1"):""} ref={firstReaction}/>
                    </OverlayTrigger>
                </Col>
                <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="care">{t("Care")}</Tooltip>}>
                        <img src={CareReaction} alt="Care" className="reaction" onClick={() => handleReaction(CareReaction, "Care", "r2")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(CareReaction, "Care", "r2"):""}/>
                    </OverlayTrigger>
                </Col>
                <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="happy">{t("Happy")}</Tooltip>}>
                        <img src={HappyReaction} alt="Happy" className="reaction" onClick={() => handleReaction(HappyReaction, "Happy", "r3")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(HappyReaction, "Happy", "r3"):""}/>
                    </OverlayTrigger>
                </Col>
                <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="amazing">{t("Amazing")}</Tooltip>}>
                        <img src={AmazingReaction} alt="Amazing" className="reaction" onClick={() => handleReaction(AmazingReaction, "Amazing", "r4")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(AmazingReaction, "Amazing", "r4"):""}/>
                    </OverlayTrigger>
                </Col>
                <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="celebrate">{t("Celebrate")}</Tooltip>}>
                        <img src={CelebrateReaction} alt="Celebrate" className="reaction" onClick={() => handleReaction(CelebrateReaction, "Celebrate", "r5")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(CelebrateReaction, "Celebrate", "r5"):""}/>
                    </OverlayTrigger>
                </Col>
                {/* <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="wow">{t("Wow")}</Tooltip>}>
                        <img src={WowReaction} alt="Wow" className="reaction" onClick={() => handleReaction(WowReaction, "Wow", "r6")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(WowReaction, "Wow", "r6"):""}/>
                    </OverlayTrigger>
                </Col> */}
                <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="sad">{t("Sad")}</Tooltip>}>
                        <img src={SadReaction} alt="Sad" className="reaction" onClick={() => handleReaction(SadReaction, "Sad", "r7")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(SadReaction, "Sad", "r7"):""}/>
                    </OverlayTrigger>
                </Col>
                <Col className="d-flex align-items-center reaction-container">
                    <OverlayTrigger overlay={<Tooltip id="angry">{t("Angry")}</Tooltip>}>
                        <img src={AngryReaction} alt="Angry" className="reaction" onClick={() => handleReaction(AngryReaction, "Angry", "r8")} role="button" tabIndex="0" onKeyUp={(event) => event.key === "Enter" ? handleReaction(AngryReaction, "Angry", "r8") : ""}/>
                    </OverlayTrigger>
                </Col>
            </Row>
            <div className="d-inline-flex align-items-center">
                {showDropdown &&
                    <Dropdown className="reaction-dropdown">
                        <Dropdown.Toggle split className="reaction-dropdown-toggle" aria-label="React to Comment" onKeyUp={(event) => handleKeyboardNav(event)}>
                            <i className="material-icons" alt="arrow down icon">arrow_drop_down</i>
                        </Dropdown.Toggle>
                    </Dropdown>
                }
                    <Button className={`comment-reaction-button d-flex align-items-center default-reaction ${hasReacted ? "active-reaction" : ""}`} aria-label={ariaLabel} {...longPressEvent} onMouseEnter={() => setShowReactions(true)} onMouseLeave={() => setShowReactions(false)} onKeyUp={(event) => event.key === "Tab" ? (setShowDropdown(false), setShowReactions(false)):""} style={{fontFamily: `${direction === "rtl" ? "Almarai" : ""}`}}>
                        {t(currentReactionLabel)}
                    </Button>
            </div>
        </StyledReactions>
    )
}

export default connect((state) => ({
    activeProfileMeta: state.user.activeProfileMeta,}), {
    addToast,
  })(Reactions);

const StyledReactions = styled.div`
.reaction-dropdown {
    height: 24px;
}
.comment-reaction-button {
    font-size: 16px;
    font-family: 'Inter',sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #8C8C8C;
    background: #fff;
    border-color: #fff;
    padding: 10px 0;
}
button.comment-reaction-button:hover {
    color: #343a40 !important;
    background: #ffffff !important;
    border-color: #ffffff !important;
}
button.comment-reaction-button:active {
  color: #343a40 !important;
  background: #ffffff !important;
  border-color: #ffffff !important;
}

button.comment-reaction-button:focus-visible {
  box-shadow: 0 0 0 0.13rem #000 !important;
  color: #343a40 !important;
}
.default-reaction.active-reaction {
    color: #343a40 !important;
}
.reactions-bar {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
    border-radius: 50px;
    flex-wrap: nowrap;
    position: absolute;
    z-index: 999;
    top: -45px;
    height: 52px;
    //width: 546px;
    padding: 0 0.5rem;
    border: 0.5px solid rgb(220, 220, 220);
}
.reactions-bar-ltr {
    left: 10px;
}
reactions-bar-rtl {
    right: 10px;
}
.reaction-container {
    padding: 0 0.5rem;
}
.reaction {
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(1.2);
    }
}
.reaction:focus {
    outline: 0;
}
.reaction:focus-visible {
    box-shadow: 0 0 0 0.13rem #000 !important;
    border-radius: 6px;
}
.reaction-dropdown-toggle {
    width: 34px;
    height: 24px;
    background: #fff;
    border-color: #fff;
    color: #8C8C8C;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
        background: #fff !important;
        border-color: #fff !important;
        color: #8C8C8C;
    }
}
.reaction-dropdown-toggle:focus {
    box-shadow: 0 0 0 0.13rem #000 !important;
}
.reaction-dropdown-toggle .material-icons {
    font-size: 30px;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #8C8C8C;
    background-color: #fff !important;
    border-color: #fff !important;
}

@media(max-width:576px) {
    .reactions-bar {
        width: auto;
        height: 40px;
        top: -32px;
    }
    .reaction {
        width: 32px;
        height: 33px;
    }
    .reaction-container {
        padding: 0 0.35rem;
    }
    .default-reaction {
        border-radius: 0 0 0 14px;
    }
}
@media(max-width: 380px) {
    .reaction-container {
        padding: 0 0.25rem;
    }
}
`
