import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { dismissToast } from '../redux/toast';
import i18n from 'i18next';
// import { Colors } from '../styles/constants';

const Toaster = ({ toast, dismissToast }) => {
  const direction = i18n.dir();
  let timeout;

  useEffect(() => {
    if (toast) {
      timeout = setTimeout(() => {
        dismissToast();
      }, 5000);
    }
  }, [toast]);

  const dismissToastLocal = () => {
    clearTimeout(timeout);

    dismissToast();
  };

  return (
    <ToastContainer>
      <TransitionGroup>
        {
          toast ? (
            <CSSTransition timeout={500} classNames="toast" role="alert" aria-live="assertive">
              <Toast error={toast.type === 'error'} success={toast.type === 'success'} info={toast.type === 'info'}>
                <ToastDismiss onClick={dismissToastLocal} className={`fa fa-times fa-lg ${direction === "rtl" ? "displayRtl" : "displayLtr"}`} />
                <ToastTitle error={toast.type === 'error'} success={toast.type === 'success'} info={toast.type === 'info'} className={direction === "rtl" ? "arabic-display" : ""}>{toast.title}</ToastTitle>
                <ToastText className={direction === "rtl" ? "arabic-display" : ""}>{toast.text}</ToastText>
              </Toast>
            </CSSTransition>
          ) : null
        }
      </TransitionGroup>
    </ToastContainer>
  );
};

export default connect((state) => ({
  toast: state.toast,
}), {
  dismissToast,
})(Toaster);

const ToastContainer = styled.div`
  position: fixed;
  z-index: 10;

  //bottom: 2rem;
  bottom: 3rem;

  //left: 50%;
  //right: 0%;
  right: -115px;

  transform: translateX(-50%);

  .toast-enter {
    opacity: 0;
    transform: translateY(100%) scale(0);
  }

  .toast-enter-active {
    opacity: 1;
    transform: translateY(0) scale(1);

    transition: 500ms ease;
  }

  .toast-exit-active {
    transform: translateY(100%) scale(0);
    opacity: 0;

    transition: 500ms ease;
  }
`;

const Toast = styled.div`
  //padding: 15px 15px 10px;
  position: relative;

  background-color: red;

  //opacity: .95;
  //opacity: 1;

  border-radius: 5px;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

  width: 275px;
  background-color: #fff;

  // ${(props) => props.error && 'background-color: red;'}
  // ${(props) => props.success && 'background-color: green;'}
  // ${(props) => props.info && 'background-color: lightblue;'}

  & + & {
    margin-top: 2rem;
  }

  .displayLtr {
    right: 10px;
  }

  .displayRtl {
    left: 10px;
  }
`;

const ToastTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  ${(props) => props.error && 'background-color: red;'}
  ${(props) => props.success && 'background-color: green;'}
  ${(props) => props.info && 'background-color: lightblue;'}
  
    padding-bottom: 0.1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 9px 11px 9px;
    border-radius: 5px 5px 0px 0px;
  

`;

const ToastText = styled.p`
  //color: white;
  color: black;
  padding: 0px 11px 9px;

 

`;

const ToastDismiss = styled.i`
  // position: absolute;
  // top: 12px;
  // right: 10px;
  // cursor: pointer;
  // //color: white;
  // color: black;

  position: absolute;
  top: 14px;
  cursor: pointer;
  color: white;

`;
