// export default (state = [], action) => {
//   if (action.type === 'ADD_TOAST') {
//     return [{
//       title: 'Hello',
//       text: 'This is some text',
//     }, ...state];
//   }

//   // if (action.type === 'DISMISS_TOAST') {

//   //   return [{
//   //     title: 'Hello',
//   //     text: 'This is some text',
//   //   }, ...state];
//   // }

//   return state;
// };

export default (state = null, action) => {
  if (action.type === 'ADD_TOAST') {
    return action.data;
  }

  if (action.type === 'DISMISS_TOAST') {
    return null;
  }

  return state;
};

export const addToast = (data) => ({
  type: 'ADD_TOAST',
  data,
});

export const dismissToast = () => ({
  type: 'DISMISS_TOAST',
});
