import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addToast } from "../../redux/toast";
import styled from "styled-components";
import AppContentContainer from "../../common/AppContentContainer";
import { getCourseList, getCourseCategoryList } from "../../api";
import CourseCard from "./components/CourseCard";
import { LoaderWithText } from "../../components/Loader";


const Courses = (activeProfileMeta, activeProfile, addToast) =>{

    const [selected, setSelected] = useState('all');
    const [allCourses, setAllCourses] = useState([]);
    const [myCourses, setMyCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      let unmounted = false;
      setIsLoading(true);
      getCourseList()
      .then(({ data }) => {
        setAllCourses(data.courses);
        setMyCourses(data.enrolled_courses);
        setIsLoading(false);
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
    });
      return () => { unmounted = true }
  }, []);

  let mycoursesJsx;
  if (myCourses && myCourses.length) {

    const firstcourseId = myCourses[0].course.id;
    mycoursesJsx = myCourses.map((enrolledcourse) => {
        
        return (
          <CourseCard
          key={enrolledcourse.course.id}
          course={enrolledcourse.course}
          firstCourseId={firstcourseId}
        />
        );        
      });
    } else {
      mycoursesJsx = (
        <div className="text-card">
          <span className="centered-text">You will find your in-progress courses here.</span>
        </div>
        );
      };





  let allcourseJsx;
  
  if (allCourses && allCourses.length) {

    const firstcourseId = allCourses[0].id;
    
      allcourseJsx = allCourses.map((course) => {
        
        return (
          <CourseCard
          key={course.id}
          course={course}
          firstCourseId={firstcourseId}
        />

        );
      });
    } else {
      allcourseJsx = (
        <div className="text-card">
          <span className="centered-text">No courses found. Please come back later.</span>
        </div>
        );
      };

    return (
        <AppContentContainer classes="container">
          <TabNavigation>
            <div className={`course-tab ${selected === 'all' ? 'selected-tab' : ''}`} onClick={() => setSelected('all')}>
              <h4>Home</h4>
            </div>
            <div className={`course-tab ${selected === 'enrolled' ? 'selected-tab' : ''} ml-5`} onClick={() => setSelected('enrolled')}>
              <h4>In Progress</h4>
            </div>
          </TabNavigation>
          <CourseListContainer classes="d-flex flex-md-row">
          {!isLoading && selected === 'all' && allcourseJsx}
          {!isLoading && selected === 'enrolled' && mycoursesJsx}
          {isLoading && <LoaderWithText minHeight="50vh" text={"Please wait..."} key="1"/>}
          </CourseListContainer>
        </AppContentContainer>
    );
};

export default connect((state) => ({
    activeProfileMeta: state.user.activeProfileMeta  
}),   
  {
    addToast
  }
  )(Courses);

  
const CourseListContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;

.text-card {
  display: flex;
  padding: 5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
}

.centered-text {
  display: flex;
  font-size:1.1rem;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
}

`;

const TabNavigation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .course-tab {
    padding-bottom: 0.2rem;
    color: #1F1F1F;
    min-width: 120px
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 4px solid transparent;
    &:hover {
      color: #1F1F1F;
      border-bottom: 4px solid #1F1F1F;
    }
  }

  .selected-tab {
    color: #1F1F1F;
    border-bottom: 4px solid #1F1F1F;
  }

  @media (max-width: 440px) {
    margin-top: 2.5rem;  
  }

`;