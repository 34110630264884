/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState,useMemo} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";

export function QuickUserToggler() {
  const {activeProfile,activeProfileMeta} = useSelector(state => state.user);
  // const [toggle, setToggle]=useState(true)
  // const uiService = useHtmlClassService();
  // const layoutProps = useMemo(() => {
  //   return {
  //     offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
  //   };
  // }, [uiService]);


  

//   const handleEnterPress  = (e) => {
    
//      const code = e.keyCode || e.which;
//      if(code === 13) { 
//       setToggle(false)
//      } 
   
//  };

  return (<>
        {/* {layoutProps.offcanvas && (<OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        > */}
          <div className="topbar-item">
            <div className="btn-icon w-auto d-flex align-items-center btn-lg px-2"
            // tabIndex="0"
            // id="kt_quick_user_toggle"
            // onKeyPress={(e) => handleEnterPress(e)}
            >
              <>

                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {/* {user.fullname} */} 
                  {activeProfile ?
              activeProfileMeta.type === "org" ? activeProfile.organization_title : `${activeProfile.person_first_name} ${activeProfile.person_last_name} ` : ""}
                </span>
                {/* <span className="symbol symbol-35 symbol-light-success">                
                    <span className="symbol-label font-size-h5 font-weight-bold">
                    {activeProfile ?
              activeProfileMeta.type === "org" ? activeProfile.organization_title[0] : `${activeProfile.person_first_name[0]} ` : ""}
                      </span>
                </span> */}
              </>
            </div>
          </div>
        {/* </OverlayTrigger>)} */}

        {/* {!layoutProps.offcanvas && (<UserProfileDropdown/>)} */}
        {/* {!toggle && (<UserProfileDropdown/>)} */}
      </>
  );
}
