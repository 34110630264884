import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AppContentContainer from "../../../common/AppContentContainer";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getLessonQuiz, submitQuiz } from "../../../api";
import i18n from "i18next";
import Form from "react-bootstrap/Form";

const QuizForm = (setActiveProfileMeta, setActiveProfile, addToast) => {
  const direction = i18n.dir();
  const [answers, setAnswers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { lessonId } = useParams();

  const [quiz, setQuiz] = useState([]);

  useEffect(() => {
    let unmounted = false;
    getLessonQuiz(lessonId)
      .then(({ data }) => {
        if (!unmounted) {
          setQuiz(data);
          for (let i = 0; i < data.length; i++) {
            setAnswers((answers) => [
              ...answers,
              { questionId: data[i].question.id, selectedAnswer: '' },
            ]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      unmounted = true;
    };
  }, []);


  const handleAnswer = (index, question, choiceId) => {
        const questionIndex = quiz.indexOf(question);
        const answerArray = [...answers];
        answerArray[questionIndex].selectedAnswer = choiceId;
        setAnswers(answerArray);

};

const onSubmit = (e) => {
  e.preventDefault();

  let total_questions = quiz.length;
  let total_replies = 0;
  for (let i=0;i < answers.length; i++) {
    if (answers[i].selectedAnswer != '')
    {
      total_replies +=1
    }
  }

  if (total_questions == total_replies) {

      let answerList = [];
      for (let i = 0; i < answers.length; i++) {
          answerList.push({ quiz: answers[i].questionId, answer: answers[i].selectedAnswer });
          }
      

      submitQuiz(answerList)
      .then(({ data }) => {
        console.log(data);
      }).catch((err) => {
        console.log(err);
      });

  }else{
    console.log("Missing Fields");
  }

};



  let quizform;
  if (quiz.length > 0 && answers.length==quiz.length) {
    quizform = quiz.map((quiz_question, index) => (
      <div key={quiz_question.id} className="screening-question">
        <h4>
          {quiz_question.question.question}
          <span style={{ color: "#dc3545" }}> *</span>
        </h4>
        <Form.Row>
          <Form.Group as={Col} col={12}>
            {quiz_question.question.quiz_question_choice.map((choice) => (
              <div key={choice.id} className="question-choice py-2">
                <Form.Check
                  type="radio"
                  label={choice.choice}
                  checked={answers[index].selectedAnswer == choice.id}
                  aria-label="Choice"
                  onChange={() => handleAnswer(index, quiz_question, choice.id)}
                  onKeyUp={(event) =>
                    event.key === "Enter"
                      ? handleAnswer(index, quiz_question, choice.id)
                      : ""
                  }
                />
              </div>
            ))}
            
          </Form.Group>
          
        </Form.Row>
      </div>
    ));
  }

  return (
    <AppContentContainer classes="container d-flex flex-column flex-md-row">
    <StyledCourseCard>
      <Form onSubmit={onSubmit} className="quiz-content" noValidate>
        {quizform}
        <Row className="job-post-action-btn">
          <Button
            className="job-post-next-btn"
            id="apply-job"
            type="submit"
            aria-label="Submit"
          >
            {isSubmitting ? "Please wait.." : "Submit"}
            
          </Button>
        </Row>
      </Form>
      </StyledCourseCard>
    </AppContentContainer>
  );
};

export default connect(
  (state) => ({
    activeProfileMeta: state.user.activeProfileMeta,
  }),
  {
    addToast,
  }
)(QuizForm);


const StyledCourseCard = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  width: 100%;

  .quiz-content {
    padding: 1.8rem 2rem;
  }
  #apply-job {
    float:right;
    background-color: #3f0e40;
    border-color: #3f0e40;
    color: white;
    border-radius: 7px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

`