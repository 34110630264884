import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { getOrganizationJobCategories } from "../../../../../api";

const prepareFilter = (queryParams, values) => {
  const { status, type, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status !== "" ? +status : undefined;
  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  filter.lastName = searchText;
  if (searchText) {
    filter.firstName = searchText;
    filter.email = searchText;
    filter.ipAddress = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ listLoading, onApplyFilters }) {
  // Customers UI Context
  const [filters, setFilters] = useState({
    q: null,
    cat: null,
    city: null,
    cognitive: "",
    sensoryfriendly: "",
    mobility: "",
    visual: "",
    hearing: "",
  });

  const [categories, setCategories] = useState([]);
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  useEffect(() => {
    getOrganizationJobCategories().then(({ data }) => {
      setCategories(
        data.map((item) => ({
          value: item.job_category,
          label: item.job_category,
        }))
      );
    });
    //   });
  }, []);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const onSearch = (e) => {
    setFilters({ ...filters, q: e.target.value });
    onApplyFilters({ ...filters, q: e.target.value });
  };

  const onFiltersChanged = (e, field) => {
    const newFilters = { ...filters };

    if ((field === "cat" || field === "city") && e.target.value === "all") {
      newFilters[field] = null;
    } else {
      newFilters[field] = e.target.value;
    }

    setFilters(newFilters);
    onApplyFilters(newFilters);
  };

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row justify-content-between">
              <div className="col-lg-3">
                {/* <select
                  className="form-control"
                  name="category"
                  placeholder="Filter by Status"
                  // TODO: Change this code
                  onChange={(e) => {
                    onFiltersChanged(e, "cat");
                    setFieldValue("status", e.target.value);
                    //handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="all" defaultValue>
                    All
                  </option>
                  {categories.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Category
                </small> */}
              </div>
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText || ""}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    onSearch(e);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
