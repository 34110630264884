import React from "react";
import { useDispatch } from "react-redux";
import { setNotificationsRead } from "../../../redux/notifications";

export const DropdownTopbarItemToggler = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const handleEnterPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      dispatch(setNotificationsRead());
      e.preventDefault();
      props.onClick(e);
    }
  };
  return (
    <div
      tabIndex="0"
      ref={ref}
      className="topbar-item"
      onKeyPress={(e) => handleEnterPress(e)}
      onClick={(e) => {
        dispatch(setNotificationsRead());
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </div>
  );
});

DropdownTopbarItemToggler.displayName = "DropdownTopbarItemToggler";
