import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/toast";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import { usePDF } from '@react-pdf/renderer';
import ProfilePdf from '../../PersonProfile/components/ProfilePdf';
import { getPersonContact, updatePersonContact, getJobScreeningQuestions, ApplyJob, postScreeningQuestionAnswer, updateProfile } from '../../../api';


const JobApplyModal = ({ show, setShow, job, profileId, profileType, profile, onApply }) => {

    const dispatch = useDispatch();
    const currentJob = { ...job };
    const jobSlug = currentJob ? currentJob.slug : null;
    const jobId = currentJob ? currentJob.id : null;

    const [sectionOne, setSectionOne] = useState(false);
    const [sectionTwo, setSectionTwo] = useState(false);
    const [sectionThree, setSectionThree] = useState(false);
    const [sectionValid, setSectionValid] = useState(true);

    const [hasQuestions, setHasQuestions] = useState(false);
    const [screeningQuestions, setScreeningQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [validAnswer, setValidAnswer] = useState(true);
    const [invalidIndex, setInvalidIndex] = useState(null);

    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [resume, setResume] = useState(null);
    const [instance, setInstance] = usePDF({ document: <ProfilePdf profile={profile} /> });
    const [applyInclusiveCv, setApplyInclusiveCv] = useState(true);
    const [additionalDoc, setAdditionalDoc] = useState(null);
    const [cvUploadState, setCvUploadState] = useState("No file selected");
    const [docUploadState, setDocUploadState] = useState("No file selected");
    const [invalidResume, setInvalidResume] = useState(false);
    const [invalidAdditionalDoc, setInvalidAdditionalDoc] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (jobSlug) {
            getJobScreeningQuestions(jobSlug)
                .then(({ data }) => {
                    if (data.length) {
                        if (!unmounted) {
                            setHasQuestions(true);
                            setSectionOne(true);
                            setSectionTwo(false);
                            setScreeningQuestions(data);
                            for (let i = 0; i < data.length; i++) {
                                setAnswers((answers) => [...answers, { questionId: data[i].id, selectedAnswers: [] }]);
                            }
                        }
                    }
                    else {
                        if (!unmounted) {
                            setSectionTwo(true);
                        }
                    }
                })
                .catch(() => {
                    dispatch(
                        addToast({
                            type: "error",
                            title: "Error",
                            text: "An error occurred, please try again.",
                        })
                    )
                });
        }
        return () => { unmounted = true };
    }, []);

    useEffect(() => {
        if (hasQuestions) {
            setSectionOne(true);
            setSectionTwo(false);
            setSectionThree(false);
        }
        else {
            setSectionTwo(true);
            setSectionThree(false);
        }
        setSectionValid(true);
        setInvalidIndex(null);
        setValidAnswer(true);
        setApplyInclusiveCv(true);
    }, [show]);

    useEffect(() => {
        let unmounted = false;
        if (profileId && profileType === "individual") {
            getPersonContact(profileId)
                .then(({ data }) => {
                    if (!unmounted) {
                        setEmail(data.person_email);
                        setPhoneNumber(data.person_phone_number);
                    }
                })
                .catch(() => {
                    dispatch(
                        addToast({
                            type: "error",
                            title: "Error",
                            text: "An error occurred, please try again.",
                        })
                    )
                });
        }
        return () => { unmounted = true };
    }, []);

    const handlePrevSection = () => {
        if (sectionThree) {
            setSectionThree(false);
            setSectionTwo(true);
        }
        if (sectionTwo) {
            setSectionTwo(false);
            setSectionOne(true);
        }
    }

    const handleNextSection = (values, errors) => {
        if (sectionOne) {
            const valid = validateSectionOne();
            setSectionValid(valid);
            if (valid) {
                setSectionOne(false);
                setSectionTwo(true);
            }
        }
        if (sectionTwo) {
            const valid = validateSectionTwo(values, errors);
            setSectionValid(valid);
            if (valid) {
                updatePersonContact(profileId, { person_email: values.email_address, person_phone_number: values.phone_number })
                    .then(({ data }) => {
                        setEmail(data.person_email);
                        setPhoneNumber(data.person_phone_number);
                    })
                    .catch(() => {
                        dispatch(
                            addToast({
                                type: "error",
                                title: "Error",
                                text: "An error occurred, please try again.",
                            })
                        )
                    });
                setSectionTwo(false);
                setSectionThree(true);
            }
        }
    }

    const validateSectionOne = () => {
        let selected;
        for (let i = 0; i < screeningQuestions.length; i++) {
            if (answers[i].selectedAnswers.length === 0) {
                setValidAnswer(false);
                setInvalidIndex(i);
                selected = false;
                return
            }
            else {
                setValidAnswer(true);
                selected = true;
            }
        }
        return selected;
    }

    const validateSectionTwo = (values, errors) => {
        if (values.email_address === "" || errors.email_address || errors.phone_number) {
            return false;
        }
        else {
            return true;
        }
    }

    const validateSectionThree = () => {
        if (applyInclusiveCv !== true && resume === null) {
            return false;
        }
        else {
            return true;
        }
    }

    const handleAnswer = (index, question, choiceId) => {
        const questionIndex = screeningQuestions.indexOf(question);
        let selected = answers[questionIndex].selectedAnswers;
        const hasChoice = selected.includes(choiceId);
        const answerArray = [...answers];
        if (hasChoice) {
            if (selected.length === 1) {
                selected = [];
            }
            else {
                selected = selected.splice(index, 1);
            }
        }
        else {
            selected = [...selected, choiceId];
        }
        answerArray[questionIndex].selectedAnswers = selected;
        setAnswers(answerArray);
        setValidAnswer(true);
    }

    const handleFile = (event, type) => {

        const files = event.currentTarget.files;
        let allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;

        if (files.length === 0) {
            if (type === "resume") {
                setCvUploadState("No file selected");
                setInvalidResume(false);
                setResume(null);
            }
            else {
                setDocUploadState("No file selected");
                setInvalidAdditionalDoc(false);
                setAdditionalDoc(null);
            }
            setInvalidFileType(false);
        }

        else {
            if (type === "resume") {
                let filePath = files[0].name;
                if (!allowedExtensions.exec(filePath)) {
                    setInvalidFileType(true);
                    setInvalidResume(true);
                    setCvUploadState("No file selected");
                    setResume(null);
                }
                else {
                    setInvalidFileType(false);
                    setInvalidResume(false);
                    setCvUploadState(files[0].name);
                    setResume(files[0]);
                }
            }

            else if (type === "additionalDoc") {
                let filePath = files[0].name;
                if (!allowedExtensions.exec(filePath)) {
                    setInvalidFileType(true);
                    setInvalidAdditionalDoc(true);
                    setDocUploadState("No file selected");
                    setAdditionalDoc(null);
                }
                else {
                    setInvalidFileType(false);
                    setInvalidAdditionalDoc(false);
                    setDocUploadState(files[0].name);
                    setAdditionalDoc(files[0]);
                }
            }
        }
    }

    const applyJobSchema = yup.object().shape({
        answer: yup.string(),
        email_address: yup.string().email("Enter a valid email.").required("Please provide an email."),
        phone_number: yup.string().matches(/^\+(?:[0-9]●?){10,13}[0-9]$/, "Contact number should start with + and contain 11 - 14 digits. No spaces allowed."),
    });

    let initialValues = {
        answers: [],
        email_address: email,
        phone_number: phoneNumber,
        resume: null,
        additional_documents: null,
    };

    const onSubmit = (values, { setSubmitting }) => {
        const valid = validateSectionThree();
        setSectionValid(valid);
        if (valid) {
            let form_data = new FormData();
            const fileToSend = applyInclusiveCv ? instance.blob : resume;
            form_data.append("person_cv_file", fileToSend);
            if (additionalDoc !== null) {
                form_data.append("person_supporting_doc_file", additionalDoc);
            }
            let answerList = [];
            for (let i = 0; i < answers.length; i++) {
                const questionId = answers[i].questionId;
                for (let j = 0; j < answers[i].selectedAnswers.length; j++) {
                    const answerId = answers[i].selectedAnswers[j];
                    answerList = [...answerList, { question: questionId, answer: answerId }];
                }
            }
            updateProfile(profileId, form_data)
                .then(() => {
                })
                .catch(() => {
                });
            ApplyJob(jobId, { applicant: profileId })
                .then(({ data }) => {
                    const applicationId = data.id;
                    onApply();
                    postScreeningQuestionAnswer(applicationId, answerList)
                        .then(() => {
                        })
                        .catch(() => {
                        })
                    dispatch(
                        addToast({
                            title: "success",
                            text: `Job applied successfully.`,
                            type: "success",
                        })
                    );
                })
                .catch(() => {
                    dispatch(
                        addToast({
                            type: "error",
                            title: "Error",
                            text: "An error occurred, please try again.",
                        })
                    );
                });
            setSubmitting(false);
            setShow(false);
        }
        else {
            setSubmitting(false);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg" centered aria-labelledby="Job Apply Modal" dialogClassName="job-apply-modal">
            <Formik validationSchema={applyJobSchema} initialValues={initialValues} onSubmit={onSubmit}>
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    handleReset,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    isValidating,
                    validateForm,
                }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                        <Modal.Header closeButton>
                            <Modal.Title id="Job Apply Modal">
                                Apply For Job
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {sectionOne && (
                                <>
                                    <div className="apply-job-header">
                                        <h2>Screening Questions</h2>
                                    </div>
                                    {screeningQuestions.map((question, index) => (
                                        <div key={question.id} className="screening-question">
                                            <h4>{question.question}<span style={{ color: "#dc3545" }}> *</span></h4>
                                            <Form.Row>
                                                <Form.Group as={Col} col={12}>
                                                    {question.job_question_choice.map((choice) => (
                                                        <div key={choice.id} className="question-choice py-2">
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={choice.choice}
                                                                checked={answers[index].selectedAnswers.includes(choice.id)}
                                                                aria-label="Choice"
                                                                onChange={() => handleAnswer(index, question, choice.id)}
                                                                onKeyUp={(event) => event.key === "Enter" ? handleAnswer(index, question, choice.id) : ""}
                                                            />
                                                        </div>
                                                    ))}
                                                    {(!validAnswer && invalidIndex === index) && (
                                                        <Form.Label className="invalid-section-feedback">Please select atleast one answer.</Form.Label>
                                                    )}
                                                </Form.Group>
                                            </Form.Row>
                                        </div>
                                    ))}
                                </>
                            )}

                            {sectionTwo && (
                                <>
                                    <div className="apply-job-header">
                                        <h2>Contact Information</h2>
                                    </div>
                                    <div className="contact-info-container">
                                        <h4>Email Address<span style={{ color: "#dc3545" }}> *</span></h4>
                                        <Form.Row className="py-2">
                                            <Form.Group as={Col} col={12}>
                                                <Form.Control
                                                    placeholder="Enter email address."
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email_address"
                                                    isInvalid={(touched.email_address && errors.email_address) || (!sectionValid && values.email_address === "")}
                                                    value={values.email_address}
                                                    type="text"
                                                    as="input"
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email_address}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>

                                        <h4>Phone Number<span className="text-muted"> (optional)</span></h4>
                                        <Form.Row className="py-2">
                                            <Form.Group as={Col} col={12}>
                                                <Form.Control
                                                    placeholder="Enter phone number."
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="phone_number"
                                                    isInvalid={touched.phone_number && errors.phone_number}
                                                    value={values.phone_number}
                                                    type="text"
                                                    as="input"
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phone_number}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </div>
                                </>
                            )}

                            {sectionThree && (
                                <>
                                    <div className="apply-job-header">
                                        <h2>Supporting Documents</h2>
                                    </div>

                                    <div className="documents-container">
                                        <Form.Check
                                            className="py-2 cvOption"
                                            type="radio"
                                            label="Apply with ImInclusive CV"
                                            name="formHorizontalRadios1"
                                            id="formHorizontalRadios1"
                                            value="inclusive"
                                            checked={applyInclusiveCv}
                                            onChange={() => setApplyInclusiveCv(true)}
                                        />

                                        <Form.Check
                                            className="py-2 cvOption"
                                            type="radio"
                                            label="Upload a CV"
                                            name="formHorizontalRadios2"
                                            id="formHorizontalRadios2"
                                            value="other"
                                            checked={!applyInclusiveCv}
                                            onChange={() => setApplyInclusiveCv(false)}
                                        />
                                        {!applyInclusiveCv && (
                                            <>
                                                {/* <h4>Upload CV<span style={{color: "#dc3545"}}> *</span></h4> */}
                                                <Form.Row className="pt-2">
                                                    <Form.Group as={Col} col={12} className="d-flex align-items-center">
                                                        <Button variant="contained" color="default" className={`cv-upload-btn ${invalidFileType && invalidResume || invalidResume ? "is-invalid" : ""}`} tabIndex="-1">
                                                            <Form.Control
                                                                onChange={(event) => {
                                                                    setFieldValue("resume", event.currentTarget.files[0]);
                                                                    handleFile(event, "resume");
                                                                }}
                                                                onBlur={handleBlur}
                                                                name="resume"
                                                                isInvalid={invalidFileType && invalidResume || invalidResume}
                                                                className={`file-input ${invalidFileType && invalidResume || invalidResume ? "is-invalid" : ""}`}
                                                                type="file"
                                                                as="input"
                                                                accept=".pdf,.docx"
                                                            />
                                                            <i className="material-icons mr-2">cloud_upload</i>
                                                            Upload
                                                        </Button>
                                                        <span> {cvUploadState}</span>
                                                        <Form.Control.Feedback type="invalid" style={{ position: "absolute", top: "48px" }}>
                                                            Please upload a valid file type.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                {(!sectionValid && resume === null) &&
                                                    <div className="invalid-section-feedback">Please upload a resume.</div>}
                                            </>
                                        )}
                                    </div>
                                    <div className="documents-container">
                                        <h4>Upload Additional Document<span className="text-muted" style={{ fontWeight: "500" }}> (optional)</span></h4>
                                        <span className="text-muted" style={{ fontWeight: "500" }}>Cover Letter, Reference List, Letter of Recommendation, Transcript, Portfolio, Certificate or any other supporting document.</span>
                                        <Form.Row className="pt-3">
                                            <Form.Group as={Col} col={12} className="d-flex align-items-center">
                                                <Button variant="contained" color="default" className={`cv-upload-btn ${invalidFileType && invalidAdditionalDoc || invalidAdditionalDoc ? "is-invalid" : ""}`} tabIndex="-1">
                                                    <Form.Control
                                                        onChange={(event) => {
                                                            setFieldValue("additional_documents", event.currentTarget.files[0]);
                                                            handleFile(event, "additionalDoc");
                                                        }}
                                                        onBlur={handleBlur}
                                                        name="additional_documents"
                                                        isInvalid={invalidFileType && invalidAdditionalDoc || invalidAdditionalDoc}
                                                        className={`file-input ${invalidFileType && invalidAdditionalDoc || invalidAdditionalDoc ? "is-invalid" : ""}`}
                                                        type="file"
                                                        as="input"
                                                        accept=".pdf,.docx"
                                                    />
                                                    <i className="material-icons mr-2">cloud_upload</i>
                                                    Upload
                                                </Button>
                                                <span> {docUploadState}</span>
                                                <Form.Control.Feedback type="invalid" style={{ position: "absolute", top: "48px" }}>
                                                    {invalidFileType && invalidAdditionalDoc ? "Please upload a valid file type." : ""}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </div>
                                </>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Row className="job-post-action-btn">
                                {(sectionOne || (!hasQuestions && sectionTwo)) && (
                                    <Button className="job-post-cancel-btn" onClick={() => { setShow(false); handleReset() }} aria-label="Cancel">
                                        <i className="material-icons mr-1" alt="close icon">close</i>
                                        Cancel
                                    </Button>
                                )}
                                {((sectionTwo && hasQuestions) || sectionThree) && (
                                    <Button className="job-post-prev-btn" onClick={() => handlePrevSection()} aria-label="Back">
                                        <i className="material-icons mr-1" alt="back arrow icon">navigate_before</i>
                                        Back
                                    </Button>
                                )}
                                {((sectionOne && hasQuestions) || sectionTwo) && (
                                    <Button className="job-post-next-btn" onClick={() => handleNextSection(values, errors)} aria-label="Next">
                                        Next
                                        <i className="material-icons ml-1" alt="forward arrow icon">navigate_next</i>
                                    </Button>
                                )}
                                {(sectionThree) && (
                                    <Button className="job-post-next-btn" type="submit" aria-label="Submit">
                                        {isSubmitting ? "Please wait.." : "Submit"}
                                        <i className="material-icons" alt="arrow up icon">upgrade</i>
                                    </Button>
                                )}
                            </Row>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default JobApplyModal;