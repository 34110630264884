import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Bio from "./components/Bio";
import RightSection from "./components/RightSection";
import EditProfile from "./components/EditProfile";
import { setActiveProfile, getAvailableProfiles } from "../../redux/user";


const PersonProfile = ({
  availableProfiles,
  activeProfile,
  activeProfileType,
  setActiveProfile,
  getAvailableProfiles,
}) => {
  const [isEdittingProfile, setIsEdittingProfile] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [publicView, setPublicView] = useState(false);
  const [isOwner, setIsOwner] = useState(true);

  const refreshProfile = () => {
    getAvailableProfiles();
  };

  const onProfileUpdated = () => {
    getAvailableProfiles();
    setIsEdittingProfile(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshProfile();
  }, []);

  return (
    <PODDashboard style={{ paddingTop: '56px'}}>
       {/* Display pending verification tag if it's not public view and verified is false. */}
       {/* {activeProfile && (
          !publicView && (
            activeProfile.verified === false ? (
            <div>
              <div className="pendingVerification">
                <div>Your profile is not verified. To get verified, please reach out to us on operations@iminclusive.com.</div>
              </div>
            </div>
        ) : ""  
      ))} */}
      {publicView && (
        <div style={{ marginBottom: "48px" }}>
          <div className="publicProfileView">
            <div>This is what your profile looks like to Public.</div>
            <div>
              <Button
                className="m-2 profile-follow-btn"
                variant="primary"
                onClick={() => {
                  setPublicView(false);
                  setIsOwner(true);
                }}
              >
                Exit Public View
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="container ic-app-dashboard--wrapper d-flex flex-column flex-md-row">
        {activeProfile && (
          <React.Fragment>
            <EditProfile
              show={isEdittingProfile}
              isUpdating={isUpdating}
              onProfileUpdated={onProfileUpdated}
              profile={activeProfile}
              closeDialog={() => setIsEdittingProfile(false)}
            />
            <Bio
              isOwner={isOwner}
              profile={activeProfile}
              refreshProfile={refreshProfile}
            />
            <RightSection
              publicView={publicView}
              TogglePublicView={() => {
                setPublicView(!publicView);
                setIsOwner(!isOwner);
              }}
              isOwner={isOwner}
              profile={activeProfile}
              onProfileUpdated={refreshProfile}
              onEditProfile={() => setIsEdittingProfile(true)}
            />
          </React.Fragment>
        )}
      </div>
    </PODDashboard>
  );
};

export default connect(
  (state) => ({
    availableProfiles: state.user.availableProfiles,
    activeProfile: state.user.activeProfile,
    activeProfileType: state.user.activeProfileType,
  }),
  {
    setActiveProfile,
    getAvailableProfiles,
  }
)(PersonProfile);

const PODDashboard = styled.main`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  padding-top: 66px;
  margin: 0;
  max-width: 100%;
  position: relative;
  white-space: pre-line;
  min-height:78vh;

  .publicProfileView{
    top: 56px;
    position:fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 38px;
    background: #7C7C7E;
    //opacity: 0.50;
    color: white;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    z-index: 10000000000000;
  }
  .pendingVerification {
    //position:fixed;
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #CD4A04;
    color: #ffffff;
    width: 100%;
  }

  .bioAside{
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 14px;
  }
  .user-app-loc--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-app-loc--wrapper 
  .locIcon{
    padding-right: 10px;
    @media (max-width: 900px) and (min-width: 600px) {
      padding-right: 0;
      padding-left: 1rem;
    }
  }
  .user-app-loc--wrapper 
  .locText{
    padding-top: 3px;
    color: #000;
  }

  .userPost{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
  .user-app-activity--wrapper{
    display: flex;
    justify-content: space-around;
    padding: 20px 45px;
    align-items: center;
    @media (max-width: 900px) and (min-width: 600px) {
      padding: 1rem 0.5rem;
    }
   
  }
  .userSupporter{
    padding: 0px 16px;
    border-width: 0px 1px 0 1px;
    border: 1px solid #DEDCDC;
    border-width: 0px 1px 0 1px;
    @media (max-width: 900px) and (min-width: 600px) {
      padding: 0 0.5rem;
    }
  }
  .user-activity-number{
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;  
    text-align: center;
    color: #625E5E;
  }
  .user-activity-text{
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }
  .user-app-tagline--wrapper{
    padding: 0.5rem 2.5rem;
    font-size: 15px;
    //line-height: 18px;
    text-align: center;
    color: #000000;
    
    @media (max-width: 900px) and (min-width: 600px) {
      padding: 0.5rem;
    }
  }
  .mutalimage{
    width: 39px;
    height: 39px;
    border: 1px solid #FFF9F9;
    border-radius:50px;
  }
  .user-app-mutal--wrapper{
    display: flex;
    flex-direction: row;
    padding: 1rem 2.5rem;
    justify-content: space-around;
    align-items: center;
  }
  .mutal-text{
    padding-top: 4px;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #736D6D;
  }
  .mutal-img{
    padding-right: 31px;
    position: relative;
  }
  .imgAbsoulte{
    position: absolute;
    left: 28px;
    top: 16px;
  }
  .user-app-social--wrapper{
    padding: 1rem 1rem 0rem 1rem;
  }

  a.profile-nav-link {
    color: #5F5F5F;
    font-weight: bold;
    text-decoration: none;
   
    &:hover {
      color: #757575;
      text-decoration: none;
    }
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #212529 !important;
    background-color: #fff !important;
  }
  
  .endrosments{
    padding: 20px 0px;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
  .endrosments > h2 {
    font-weight: bold;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    padding-left: 11px;
  }
  .user-app-endrosments{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    padding: 1rem 1.2rem;
  }
  .section-hobbies{
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 0.4rem;
    padding: 2rem 2.2rem;
  }
  .hobbie-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
  }
  .headerinner{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hobbie-header h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #B11030;
    padding-left: 10px;
  }
  .hobbies-wrapper{
    padding-top: 31px;
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
  }
  .hobbieImgIcon-warapper{
    background: #43A0F5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 59px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 8px 4px;
  }
  .section-friend-suggestion{
    margin-top: 21px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 0.4rem;
    padding: 2rem 0rem;
  }
  .suggestion-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 48px;
  }
  .section-friend-suggestion h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #B11030;
    padding-left: 10px;
  }
 
  .friendSuggestion{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    border-bottom: 1px solid #EFEFEF;
    padding: 30px 35px 30px;
  }
  .friendSuggestion > img {
    width: 58px;
    height: 58px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  }
  .friendData > h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #5F5F5F;
    margin:0px;
  }
  .friendprofession{
    font-size: 14px;
    line-height: 17px;
    color: #757575;
    padding-top: 5px;
  }
  .friendSuggestion .userAbilityIcon{
    width: 37px;
    height: 37px;
    background: #B11030;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .suggestionBtn{
    padding: 0.8rem;
    border-radius: 0.4rem;
    font-size: 18px;
    line-height: 22px;
  }

  .my-profile-badge {
    //margin-top: 0.74rem;
  }

  .show-on-mobile {
    display: none;
  }
  .show-on-ipad{
    display: none;
  }

  @media(max-width:440px){
    .show-on-regular{
      display: none !important;
    }
    .show-on-mobile{
      display: block;
    }
    .profile-follow-btn, .profile-edit-btn, .profile-more-dropdown {
      width: 50%;
    }
     .publicProfileView{
    font-size: 0.9rem;
    padding: 0px 6px;
  }
  .publicProfileView .profile-follow-btn {
    width: 92% !important;
    font-size: 0.9rem;
    line-height: 15px;
  }
  .profile-more-dropdown .profile-edit-btn {
    width: 96% !important;
  }
  }

  .following-text{
    border-top: solid 2px #dadbe2;
    padding-top: 1rem;
  }
  .user-story{
    //margin-top: 99px !important;
    
  }
  .user-story-header{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user-story-header h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #000;
  }
  .user-story-header > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-story-text{
    //font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .user-data-wrapper{
    margin: 1rem 0rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    padding: 24px;
  }
  .abilitytext{
    padding-left: 11px;
    font-size: 18px;
    color: #FFFFFF;
  }
  .crossWrapper{
    width: 31px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #B11030;
    border-radius: 25px 0px 0px 25px;
  }
  .ability{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #43A0F5;
    border-radius: 25px 25px 25px 25px;
    padding: 0px;
    padding-right: 10px;
  }
  .abilities-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .experience-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: border: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    padding: 1rem 0rem;
  }
  .companywrapper{
    display: flex;
    align-items: center;
  }
  .companywrapper > img {
    width: 91px;
    height: 91px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  .companyDetails{
      padding-left: 25px;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
  }
  .companyDetails div{
    padding-top:3px;
  }
  .position{
    font-weight: bold;
    font-size: 18px;
    padding-top:0px;
  }
  .company-description{
    padding-top: 10px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .user-achievments{
    display: flex;
    align-items: flex-start;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: 0;
  }
  }

  .blueDot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blueDot > img {
    padding: 6px 0px;
  }
  .blueDot div {
    width: 0px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .abilities-wrapper .title{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .abilities-wrapper .place{
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    font-weight: 300;
  }
  .user-testimonial{
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 0.4rem;
    margin: 1rem 0rem;
    padding: 2rem;
  }
  .user-testimonial-header > div{
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: 0.2rem;
  }
  .user-testimonial-header > div > h2{
    padding-left: 12px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #B11030;
  }
  .carouselCard{
    border: 0px;
    background: none;
    box-shadow:none;
    padding: 0px 10px;
  }
  #profile-dropdown {
    border: 1.5px solid #B11030;
    border-radius: 7px;
    font-weight: bold;
    font-size: 1.05rem;
    line-height: 22px;
    color: #B11030;
    background-color: #F7F4F4;
}
#more-dropdown {
  border: 1.5px solid #B11030;
  border-radius: 7px;
  font-weight: bold;
  font-size: 1.05rem;
  line-height: 22px;
  color: #B11030;
  background-color: #F7F4F4;
}

.badge {
  justify-content: space-between;
}

.readMoreText {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: black !important;
  padding:0 0 0 1rem;
  font-weight:700;
}
.btnContainer{
  justify-content:flex-end;
}
@media (max-width:480px){
  .user-data-wrapper{
    padding: 20px;
  }
  .badge{
    flex-direction: column-reverse !important;
    justify-content: flex-start !important;
  }
  .badge .btnContainer{
    padding: 28px 0px ;
    justify-content:flex-start;
  }
}

@media(maxwidth:440px){
  .badge-container.row {
    margin-top: 0 !important;
  }
}


  .ic-app-dashboard--wrapper {
    padding: 50px 45px 50px;
    width: 100%;
    position: relative;
  }

  @media (max-width: 767.98px) {
    .ic-app-dashboard--wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .ic-app-dashboard--wrapper .dashboard-left-sidebar {
    width: 33%;
    margin-top: 6.6rem;
    
  }

  @media (max-width: 767.98px) {
    .ic-app-dashboard--wrapper .dashboard-left-sidebar {
      width: 100%;
    }
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-details-container {
    padding: 1rem 0 0.5rem 0;
    
  }
  
  .ic-app-dashboard--wrapper
  .dashboard-left-sidebar
  aside
  .left-sidebar--wrapper

  .user-picture{
    position:relative;
    height: 100px;
  }

  .ic-app-dashboard--wrapper
  .dashboard-left-sidebar
  aside
  .left-sidebar--wrapper

  .user-picture 
  .user-picture-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ic-app-dashboard--wrapper
  .dashboard-left-sidebar
  aside
  .left-sidebar--wrapper

  .user-picture
  .userAbilityIcon{
    width: 49px;
    height: 49px;
    background: #B11030;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    top: -30px;
    font-size:14px;
    color:#fff;
    cursor:pointer;
    z-index:10000000000000;
  }
  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper

    .user-picture--wrapper {
      position: relative;
      top: -111px;
     // right: 63px;
    &:hover {
      .upload-profile-picture {
        opacity: 1;
      }
    }

    .upload-profile-picture {
      position: absolute;
      bottom: 0;
      left: 50%;

      opacity: 0;
      transition: all 250ms ease;

      transform: translateX(-50%);
    }
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
 
    .user-picture--wrapper
    a
    picture
    img {
      width: 208px;
      height: 208px;

    border-radius: 50%;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    object-fit: cover;
  }

  .userphoto-update {
    width: 98px;
    height: 28px;
    background: #263238;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    position: relative;
    top: -10px;
    cursor:pointer;
    z-index:100;
  }
  .update-photo {
    color: #FFFFFF;
    &:hover{
      color: #e8e6e6;
    } 
  }


  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-details-container
    .user-name--wrapper
    span {
      display: block;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #000;
  }

 

  .user-level{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

 
  .outerRing{
    display: flex;
    padding: 2px;
    border-radius: 50px;
    border: 1px solid white;
  }


  .level-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    height: 28px;
    background: #263238;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 28px;
  }

  
  .level{
    padding-left:6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #FFFFFF;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-details-container
    .user-app-type--wrapper
    span {
      display: block;
      padding: 8px 15px 4px 15px;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000;
      font-weight: 400;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-counter {
    background-color: #ffffff;
    border-left: solid 2px #dadbe2;
    border-right: solid 2px #dadbe2;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-counter
    .col:first-child {
    border-right: solid 2px #dadbe2;
  }

  @media (max-width: 929.98px) {
    .ic-app-dashboard--wrapper
      .dashboard-left-sidebar
      aside
      .left-sidebar--wrapper
      .user-counter
      .col:first-child {
      border-right: solid 0.9px #dadbe2;
    }
  }

  @media (max-width: 923px) {
    .ic-app-dashboard--wrapper
      .dashboard-left-sidebar
      aside
      .left-sidebar--wrapper
      .user-counter
      .col:first-child {
      border-bottom: solid 2px #dadbe2;
    }
  }

  @media (max-width: 767.98px) {
    .ic-app-dashboard--wrapper
      .dashboard-left-sidebar
      aside
      .left-sidebar--wrapper
      .user-counter
      .col:first-child {
      border-bottom: none;
      border-right: solid 2px #dadbe2;
    }
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-counter
    .col
    .counter-content--wrapper {
    padding: 25px 0;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-counter
    .col
    .counter-content--wrapper
    .counter-header
    h3 {
    font-size: 1rem;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-counter
    .col
    .counter-content--wrapper
    .counter-count
    h3 {
    font-size: 38px;
    font-family: 'Inter', sans-serif;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-other-info {
    background-color: #ffffff;
    border: solid 2px #dadbe2;
    padding: 20px 15px;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-other-info
    .user-info--wrapper
    .info-content
    p {
    color: #a6a7ab;
    font-size: 1rem;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-other-info
    .user-info--wrapper
    .info-content
    p
    img {
    max-width: 26px;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .user-other-info
    .user-info--wrapper
    .info-content
    p
    span:nth-child(2) {
    margin-left: 15px;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .ic-app-connect {
    margin-top: 15px;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .ic-app-connect
    .col {
    padding: 0;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .ic-app-switch {
    margin-top: 40px;
  }

  .ic-app-dashboard--wrapper
    .dashboard-left-sidebar
    aside
    .left-sidebar--wrapper
    .ic-app-switch
    a {
    font-size: 1rem;
    font-weight: 900;
  }

  .ic-app-dashboard--wrapper .dashboard-right-sidebar {
    width: 67%;
  }

  @media (max-width: 767.98px) {
    .ic-app-dashboard--wrapper .dashboard-right-sidebar {
      width: 100%;
    }
  }

  .ic-app-dashboard--wrapper .dashboard-right-sidebar aside {
    margin-left: 22px;
  }

  @media (max-width: 767.98px) {
    .ic-app-dashboard--wrapper .dashboard-right-sidebar aside {
      margin-left: 0;
      margin-top: 0;
    }
  }

  .ic-dashview {
    .add-new-btn {
      color: #B11030;
    }
  }

  .ic-app-dashboard--wrapper
    .dashboard-right-sidebar
    aside
    .right-sidebar--wrapper
    .ic-dashview:not(:first-child) {
    margin-top: 40px;
  }

  .ic-app-dashboard--wrapper
    .dashboard-right-sidebar
    aside
    .right-sidebar--wrapper
    .ic-dashview
    .dashview-md-6 {
    width: 48%;
  }

  @media (max-width: 767.98px) {
    .ic-app-dashboard--wrapper
      .dashboard-right-sidebar
      aside
      .right-sidebar--wrapper
      .ic-dashview
      .dashview-sm-12:not(:first-child) {
      margin-top: 40px;
    }
  }

  @media (max-width: 767.98px) {
    .ic-app-dashboard--wrapper
      .dashboard-right-sidebar
      aside
      .right-sidebar--wrapper
      .ic-dashview
      .dashview-sm-12 {
      width: 100% !important;
    }
  }

  .ic-app-dashboard--wrapper
    .dashboard-right-sidebar
    aside
    .right-sidebar--wrapper
    .ic-dashview--wrapper {
    border: solid 2px #dadbe2;
    background-color: #ffffff;
    width: 100%;
    padding: 0;
  }
  // .fixedsidebar{
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: 0;
  // }
  .achivementsWrapper{
    margin: 10px 0px 0px;
    position:relative
  }
  .achivementsWrapper > .title{
    padding-left: 20px;
  }
  .achivementsWrapper > .text{
    // padding-left: 20px;
    //border-left: 1px solid rgba(0, 0, 0, 0.2);
    // margin: 7px 0px 0px 0px;
    // padding-bottom: 30px;
    
  }
  .achivementsWrapper > img{
    position:absolute
    left: -5px;
    top: 20px;
  }
  .badgetext{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #5F5F5F;
  }  
  .my-profile-badge .badge-container {
    width: 45px;
    height: 45px;
  }
  .my-profile-badge .badge-container img {
    height: 100%;
    object-fit: cover;
  }
  button.profile-follow-btn {
    background-color: #3F0E40;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    margin-bottom: 2px;
    border-radius: 2rem;
    font-size: 18px;
    line-height: 10px;
    border: 0;
    font-weight: 500;
    height: 44px;
      &:active {
      background-color: #3F0E40 !important;
      border-color: #3F0E40 !important;
    }
  }
   button.profile-edit-btn {
    background-color: #fff;
    color: #5F5F5F;
    border: 2px solid #5F5F5F;
    padding: 0.475rem 0.77rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    border-radius: 2rem;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
    height: 44px;
      &:active {
      background-color: #fff !important;
      border-color: #5F5F5F !important;
      color: #5F5F5F !important;
    }
  }
  .show > .btn-primary.dropdown-toggle {
    background-color: #fff !important;
    border-color: #5F5F5F !important;
    color: #5F5F5F !important;
  }
  .dropdown-toggle::after {
  content: none;
}
`;
