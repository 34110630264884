import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { format } from "date-fns";
import Form from "../../../common/Form";
import DatePicker from "../../../components/FormikDatepicker";
import CountryCity from "../../../components/CountryCity";
import AccessibilityCheckBoxes from "../../../components/AccessibilityCheckBoxes";
import {
  getOrganizationJobCategories,
  updateOrganizationJob,
} from "../../../api";

const AddEditJob = ({
  activeProfile,
  show,
  closeDialog,
  isEditing,
  currentValues,
  onUpdated,
}) => {
  const [userCity, setUserCity] = useState(null);
  const [cityValid, setCityValid] = useState(false);
  const [categories, setCategories] = useState([]);
  const [city, setJobCity] = useState(null);
  const [orgCheck, setOrgCheck] = useState(false);

  const [filters, setFilters] = useState({
    cognitive: "",
    sensoryfriendly: "",
    mobility: "",
    visual: "",
    hearing: "",
  });

  const [accessibility, setAccessibility] = useState({
    cognitive: false,
    sensoryfriendly: false,
    mobility: false,
    visual: false,
    hearing: false,
    allOpen: false,
  });

  useEffect(() => {
    let unmounted = false;
    getOrganizationJobCategories().then(({ data }) => {
      if (!unmounted) {
      setCategories(
        data.map((item) => ({ value: item.id, label: item.job_category }))
      );
      }
    });
    return () => { unmounted = true }; 
  }, []);

  const jobSchema = yup.object().shape({
    job_title: yup.string().required("Please provide the Job Title."),
    job_description: yup
      .string()
      .required("Please provide the job Description"),
    //job_loc: yup.string().required('Please provide the location of job'),
    job_category: yup.string(),
    job_close_time: yup.date().required("Please provide closing date of job"),
    job_type: yup.string(),
    job_action_link: yup.string(),
    job_location: yup.string().required("Please provide organization address"),
    job_organization_detail: yup.string(),
    job_organization: yup.string(),
    job_organization_email: yup.string().email("Enter a Valid Email"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (userCity !== null) {
      values.job_city = Number(userCity);
      (values.accessibility_cognitive = accessibility.cognitive),
        (values.accessibility_hearing = accessibility.hearing),
        (values.accessibility_mobility = accessibility.mobility);
      values.accessibility_sensory_friendly = accessibility.sensoryfriendly;
      values.accessibility_visual = accessibility.visual;
      //values.job_category=Number(values.job_category)
      if (!orgCheck) {
        values.job_organization_detail = activeProfile.organization_overview;
      }

      const data = orgCheck
        ? {
            ...values,
            listed_by: activeProfile.id,
            job_close_time: format(values.job_close_time, "yyyy-MM-dd"),
          }
        : {
            ...values,
            listed_by: activeProfile.id,
            organization: activeProfile.id,
            job_close_time: format(values.job_close_time, "yyyy-MM-dd"),
          };

      updateOrganizationJob(currentValues.currentJob.slug, {
        ...values,
        listed_by: activeProfile.id,
        job_close_time: format(values.job_close_time, "yyyy-MM-dd"),
      })
        .then((data) => {
          setSubmitting(false);

          onUpdated();
          closeDialog();
        })
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      setSubmitting(false);
      setCityValid(true);
    }
  };

  let initialValues = {
    job_title: "",
    job_description: "",
    job_category: "",
    job_close_time: new Date(),
    job_type: "",
    job_action_link: "",
    job_location: "",
    job_organization_detail: "",
    job_organization: "",
    job_organization_email: "",
  };

  useEffect(() => {
    if (isEditing) {
      setJobCity(currentValues.currentJob.job_city);
      setAccessibility(currentValues.accessibility);
      setOrgCheck(true);
    }
  }, [currentValues]);

  if (isEditing) {
    initialValues = {
      job_title: currentValues.currentJob.job_title,
      job_description: currentValues.currentJob.job_description,
      job_close_time: new Date(currentValues.currentJob.job_close_time),
      job_type: currentValues.currentJob.job_type,
      job_action_link: currentValues.currentJob.job_action_link,
      job_location: currentValues.currentJob.job_location,
      job_organization_detail: currentValues.currentJob.job_organization_detail,
      job_organization: currentValues.currentJob.job_organization
        ? currentValues.currentJob.job_organization
        : currentValues.currentJob.organization
        ? currentValues.currentJob.organization.organization_title
        : currentValues.currentJob.listed_by.organization_title,
      job_organization_email: currentValues.currentJob.job_organization_email,
    };
  }

  const onToggleSwitchChange = () => {
    setOrgCheck(!orgCheck);
  };

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? "Edit" : "Post a new"} job <br />
          {!isEditing && (
            <div className="d-flex">
              <div className="">Post a job for other organization?</div>
              <div className="ToggleSwitch ToggleSwitch__rounded pl-5">
                <div className="ToggleSwitch__wrapper">
                  <div
                    className={`Slider ${orgCheck && "isChecked"}`}
                    onClick={onToggleSwitchChange}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={jobSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} col={orgCheck ? "6" : "12"}>
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    placeholder="Job Title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_title"
                    isInvalid={touched.job_title && errors.job_title}
                    value={values.job_title}
                    type="text"
                    as="input"
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.job_title}
                  </Form.Control.Feedback>
                </Form.Group>
                {orgCheck && (
                  <Form.Group as={Col} col={6}>
                    <Form.Label>Organization Name</Form.Label>
                    <Form.Control
                      placeholder="Organization Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="job_organization"
                      isInvalid={
                        touched.job_organization && errors.job_organization
                      }
                      value={values.job_organization}
                      type="text"
                      as="input"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.job_title}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} col={6}>
                  <Form.Label>Job Category</Form.Label>
                  <Form.Control
                    value={values.job_category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_category"
                    as="select"
                    className="ic-form-select"
                    isInvalid={touched.job_category && errors.job_category}
                  >
                    {categories.map((category) => (
                      <option key={category.value} value={category.value}>
                        {category.label}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {errors.job_category}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} col={6}>
                  <Form.Label>Job Type</Form.Label>
                  <Form.Control
                    value={values.job_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_type"
                    as="select"
                    className="ic-form-select"
                    isInvalid={touched.job_type && errors.job_type}
                  >
                    <option value="R" selected>
                      Remote
                    </option>
                    <option value="C" selected>
                      Contract
                    </option>
                    <option value="F" selected>
                      FullTime
                    </option>
                    <option value="P" selected>
                      PartTime
                    </option>
                    <option value="I" selected>
                      Internship
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.job_type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <CountryCity
                citytext="City"
                countrytext="Country"
                cityValid={cityValid}
                setCityValid={setCityValid}
                setUserCity={setUserCity}
                person_city={city}
              />
              <Form.Row>
                <Form.Group as={Col} col={6}>
                  <Form.Label>Organization Email</Form.Label>
                  <Form.Control
                    placeholder="Organization Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_organization_email"
                    isInvalid={
                      touched.job_organization_email &&
                      errors.job_organization_email
                    }
                    value={values.job_organization_email}
                    type="text"
                    as="input"
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.job_organization_email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} col={6}>
                  <Form.Label>Job Address</Form.Label>
                  <Form.Control
                    placeholder="Job Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_location"
                    isInvalid={touched.job_location && errors.job_location}
                    value={values.job_location}
                    type="text"
                    as="input"
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.job_location}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} col={12} md={6}>
                  <Form.Label>Closing Date</Form.Label>
                  <DatePicker
                    nativeInputAriaLabel="select closing date"
                    placeholderText="Closing Date"
                    name="job_close_time"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.job_close_time && errors.job_close_time}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.job_close_time}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} col={6}>
                  <Form.Label>Job Link</Form.Label>
                  <Form.Control
                    placeholder="Job Action Link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_action_link"
                    isInvalid={
                      touched.job_action_link && errors.job_action_link
                    }
                    value={values.job_action_link}
                    type="text"
                    as="input"
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.job_action_link}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Group as={Col} xs={12} md={8}>
                <Form.Label>Accessibility</Form.Label>
                <AccessibilityCheckBoxes
                  accessibility={accessibility}
                  setAccessibility={setAccessibility}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Form.Group>

              <Row>
                <Form.Group as={Col} col={12}>
                  <Form.Label>Job Description</Form.Label>
                  <Form.Control
                    placeholder="Please Share Job Description"
                    name="job_description"
                    className="italic-placeholder "
                    value={values.job_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={5}
                    isInvalid={
                      touched.job_description && errors.job_description
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.job_description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {orgCheck && (
                <Row>
                  <Form.Group as={Col} col={12}>
                    <Form.Label>Organization Description</Form.Label>
                    <Form.Control
                      placeholder="Please share organization Details"
                      name="job_organization_detail"
                      className="italic-placeholder "
                      value={values.job_organization_detail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      as="textarea"
                      rows={5}
                      isInvalid={
                        touched.job_organization_detail &&
                        errors.job_organization_detail
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.job_organization_detail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button
                      onClick={closeDialog}
                      block
                      size="lg"
                      variant="outline-secondary"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col
                    className="mt-3 mt-md-0 dashboard-main-wrapper"
                    sm={12}
                    md={6}
                  >
                    <Button
                      loading={isSubmitting ? 1 : 0}
                      variant="outline-primary"
                      block
                      size="lg"
                      type="submit"
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {}
)(AddEditJob);
