import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducers';

import axiosInstance from '../api';
import { logoutUser } from './user';
import { addToast } from './toast';

const UNAUTHORIZED = 401;
const store = createStore(reducer, applyMiddleware(thunk));

axiosInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    const { status } = err.response;

    if (status === UNAUTHORIZED) {
      // store.dispatch(logoutUser());
    }

    return Promise.reject(err);
  },
);

axiosInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    const token = localStorage.getItem('Token');

    if (token) {
      newConfig.headers = { Authorization: `Token ${token}` };
    }

    return newConfig;
  },
);

export default store;
