import React, { useEffect } from "react";
import { NavLink as Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

const FAQsection = styled.div`
  padding: 20px;
  background-color: white;
  color: #000;
  box-shadow: 1px 1px 4px rgba(128, 128, 128, 0.28);
`;

const GrayPage = styled.div`
  //background-color: rgb(236, 236, 236);
  .card-header {
    font-size: 20px;
    font-weight: 500;
  }

  h1 {
    font-size: 36px;
  }

  @media(max-width: 556px) {
    h1 {
      font-size: 22px;
    }
    h3 {  
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
`;

const HeaderSection = styled.div`
  background-color: #f44c58;
  padding: 1rem 0;
  margin-bottom: 1rem;
  color: white;
  .container > h1 {
    color: #ffffff;
  }
`;

const FAQitem = (item) => {
  const faq = item.item;
  return (
    <Accordion>
      <Card className="m-0">
        <Accordion.Toggle
          as={Card.Header}
          className="cursor_class"
          eventKey={faq.key}
        >
          {/* Q{faq.key.substr(1)}:*/}
          {faq.Q}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={faq.key}>
          <Card.Body
            dangerouslySetInnerHTML={{
              __html: faq.A,
            }}
          />
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const generalFaqs = [
  {
    key: "G1",
    Q: "What is Inclusive?",
    A:
      "Inclusive is a fun, collaborative, and the interactive platform of communication between individuals, special centers, policymakers, government bodies, institutions, and enterprises that uphold inclusion of People of Determination as their top priority above all else. ",
  },
  {
    key: "G2",
    Q: "What is the purpose of Inclusive?",
    A:
      "The purpose of Inclusive is to connect People of Determination to their local communities. Inclusive encourages local communities to collaborate across cities, countries, and continents to empower and include People of Determination in daily lifestyles. Inclusive ensures that inclusion of People of Determination is every person’s shared responsibility anda basic human right that is actioned through three pillars at Inclusive: community, awareness, and opportunity. ",
  },
  {
    key: "G3",
    Q: "Is Inclusive an online platform?",
    A:
      "Yes. Inclusive is an online platform that enables everyone equally. Including its online services, Inclusive offers on-ground services in the form of consultancy, training, events enhancers, recruitment of People of Determination, accessible environments, and digital inclusion. ",
  },
  {
    key: "G4",
    Q: "Who are People of Determination (POD)?",
    A:
      "People of Determination are uniquely abled people. Examples of People of Determination include people with Down Syndrome, Dyslexia, Autism, different physical abilities, people who use mobility aids such as wheelchairs, and people with different hearing, visual or cognitive abilities etc. ",
  },
  {
    key: "G5",
    Q: "How many People of Determination are there in the world?",
    A:
      "According to the WHO (World Health Organization), about 15% of the world's population are all People of Determination. ",
  },
  {
    key: "G6",
    Q: "Do People of Determination have a disease?",
    A:
      "No. A Person of Determination does not have a disease. A different/unique ability is a state that People of Determination live with, it is not an illness. It is not something for which a cure is commonly sought, and it isn’t contagious either. A Person of Determination has abilities that are different from others, but that is not an excuse to exclude them or make them feel left out. People with different abilities are as capable as any other human and should be treated/incorporated equally into communities, and societies. ",
  },
  {
    key: "G7",
    Q:
      "What terms does Inclusive encourage to describe a Person of Determination?",
    A:
      'At Inclusive, we encourage the use of positive and empowering words, for example, unique, abled, and talented. You can find our complete list of Inclusive <a href="https://inclusive.ae/resources/people-first-language-inclusive-platform-incorporated" target="_blank">Terms here.</a>',
  },
  {
    key: "G8",
    Q: "What does A11y mean?",
    A:
      'A11y, which is an abbreviation for accessibility, is a movement that works towards building a more accessible framework for all technologies to include and connect people of different abilities digitally. Its principal purpose is for the online infrastructure to be more inclusive and to enable everyone equally, as well as representing allyship to the People of Determination. An A11y is also pronounced as Ally (literally means supporter) as per <a href="https://www.merriam-webster.com/dictionary/ally" target="_blank">Merriam-Webster.</a> Therefore, A11yship is considered to be a term for advocacy for accessibility. By becoming an A11y, one can choose to actively empower all People of Determination. ',
  },
  {
    key: "G9",
    Q: "What is the Accessibility Bar?",
    A:
      "At Inclusive, we use the Accessibility Bar to determine how accessible the events and jobs listed under their respected tabs on the platform are. The Accessibility Bar has six categories: <ul> <li>Mobility-friendly</li><li>Hearing-friendly</li><li>Cognitive-friendly</li><li>Visual-friendly</li><li>Sensory-friendly</li><li>Open to All</li></ul>Each category has an icon representing itself to make it easy to understand. By selecting these categories while searching for events or jobs, you can find out how friendly the environments are for them.",
  },
  {
    key: "G10",
    Q: "Who are Inclusion A11ies?",
    A:
      "All-Inclusive team members (volunteers, interns, and full-time employees) are called Inclusion A11ies. They are the people who strive to make the online platform that is Inclusive into a better place for you to log onto every day. ",
  },
  {
    key: "G11",
    Q: "What accessibility criteria does Inclusive follow?",
    A:
      "The Inclusive Platform is constantly updating to follow the standard Web Content Accessibility Guidelines (WCAG) 2.0, published on 11 December 2008. It aims to accomplish most of the WCAG 2.1, which was published on 5 June 2018. Currently, Inclusive offers a tool on the platform to be utilized, making the platform more accessible. ",
  },
];

const WebAppFaqs = [
  {
    key: "W1",
    Q: "Which browser is best suited to an Inclusive web app?",
    A: "Google Chrome.",
  },
  {
    key: "W2",
    Q: "What does the Inclusive platform do?",
    A:
      "The platform provides a secure, online, accessible, space for everyone with all abilities, to discuss and action inclusion of People with Different Abilities into their local communities.",
  },
  {
    key: "W3",
    Q: "Is Inclusive only available as a web application?",
    A:
      "Inclusive is an app that is also coming soon to Android and iOS. All platform feedback can be shared with admin@iminclusive.com and we will immediately respond to resolve bugs.",
  },
  {
    key: "W4",
    Q: "What is the Impact Board?",
    A:
      "The Impact Board (IB) is a space where all discussions, topics, news, challenges, latest solutions, technologies, photos/videos (coming soon) and actions related to inclusion of People of Determination are shared by everyone. We encourage everyone who has signed up to Inclusive to post frequently on the IB and bring positive messages as well as key enhancements towards inclusion of People of Determination taking place globally, to the board.",
  },
  {
    key: "W5",
    Q: "What is the Events bar?",
    A:
      "The Inclusive Events bar is a list of events happening in your local community with their accessibility features listed.",
  },
  {
    key: "W6",
    Q: "What is the Jobs bar?",
    A:
      "The Inclusive Jobs bar is a list of jobs available in your local community that are centered around inclusion or/and hire People of Determination. Different jobs have different criteria thus, Inclusive supports with the overall recruitment concept more than just listing a job in the field of inclusion.",
  },
  {
    key: "W7",
    Q: "Where can I create an account?",
    A:
      "Go to app.inclusive.ae and click Signup Now. Enter your First Name, Last Name, Email and Password and Click Sign up.",
  },
  {
    key: "W8",
    Q: "Where can I delete an account?",
    A:
      "Tap your Username on the top-right. Tap Account Settings. >> Tap Delete Account. >> Tap Delete My Account to confirm.",
  },
  {
    key: "W9",
    Q: "Who are follower allies?",
    A:
      "At Inclusive, we are all allies to People of Determination and each other as a community. You will find many like-minded people at Inclusive. Follow their stories and talk to them. Build life-long friendships and a powerful community of allies.",
  },
  {
    key: "W10",
    Q: "Can I block a user on the platform?",
    A:
      "Yes. Go to the profile of the person you would like to block. Tap the Block button below profile details to block.",
  },
  {
    key: "W11",
    Q: "Is my participation in the platform secure?",
    A: "Yes.",
  },
  {
    key: "W12",
    Q: " What is an Organization Profile?",
    A:
      "An Organization Profile enables any entity to create its organization's representative profile page. The company can add its inclusion and diversity-related goals, initiatives, and recognitions on the Platform through the Organization page. Serving as the largest online directory of all inclusion-related organizations, the Platform's organization profile page informs users about the inclusive products and services provided by a company. The Web Platform has an extensive reach to the audience based in the UAE, Pakistan, and around the world. The Platform has users from private, public, and third-sectors. People Of Determination (POD), their loved ones, Supporters of POD, Supporters of Inclusion, all the like-minded people willing to make social impact read and get inspired by any organization's stories and services on their page.",
  },
  {
    key: "W13",
    Q: "How can an Organization Profile be created?",
    A:
      "Sign in to your Inclusive Account. Click the profile icon on the top right of the navigation bar. Click on Create Organization in the dropdown menu. Click on Create Profile to confirm. >> Add your Organization details. >> Tap Create Organization Profile.",
  },
  {
    key: "W14",
    Q: "How does an Organization update/delete its Cover Photo?",
    A:
      "Click on Update Cover at the top of your profile. >>  Select the Photo you need. >> Tap Open to update changes.",
  },
  {
    key: "W15",
    Q: "How does an Organization update/delete its Profile Photo?",
    A:
      "Click on Update on your Profile Photo. >> Click on Select Photo. >> Select the Photo you need. >> Tap Upload Photo to update changes.",
  },
  {
    key: "W16",
    Q: "How to delete an Organization Profile?",
    A:
      "Make sure you're logged in as your Organization Profile. Click on the profile icon on the top right of the navigation bar. Select Account Settings from the dropdown menu. Click on Delete Account on the side menu. >> Tap Delete My Account. >> Tap Confirm to permanently delete your account.",
  },
];
const InclusiveEventFaqs = [
  {
    key: "E1",
    Q: "How can I participate in Inclusive Events?",
    A:
      "Amid COVID-19, Inclusive Events reaches out to encourage the community to create online events that everyone may equally participate in. Shall your online event fill any of the above mentioned accessibility criteria, or if you want to volunteer/ make your online event accessible, please send an email to admin@iminclusive.com and our team will respond to you ASAP.",
  },
];

const FAQs = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { from } = location.state || { from: { pathname: "/" } };

  const generalFaqsTemplate = generalFaqs.map((item) => (
    <FAQitem item={item} key={item.key} />
  ));
  const webAppTemplate = WebAppFaqs.map((item) => (
    <FAQitem item={item} key={item.key} />
  ));
  const inclusiveEventTemplate = InclusiveEventFaqs.map((item) => (
    <FAQitem item={item} key={item.key} />
  ));

  return (
    <GrayPage className="topContainer">
      {/*<TopBar />*/}

      {/* <HeaderSection>
        <div className="container">
          <h1 style={{marginBottom:0}}>Frequently Asked Questions – FAQs</h1>
        </div>
      </HeaderSection> */}
      <section className="container pt-3 mt-5" id="faqsContainer">
        <div className="row">
          <div className="col-md-12">
            <Accordion
              defaultActiveKey="G"
              style={{
                borderRadius: "0.4rem",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.05)",
              }}
            >
              <Card className="m-0">
                <h1>Frequently Asked Questions – FAQs</h1>
                <hr />
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="G"
                  style={{ fontSize: "24px" }}
                >
                  General
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="G">
                  <Card.Body>{generalFaqsTemplate}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card
                className="m-0"
                style={{
                  borderRadius: "0.4rem",
                  boxShadow: "0px 4px 6px rgba(0,0,0,0.05)",
                }}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="W"
                  style={{ fontSize: "24px" }}
                >
                  Inclusive Web App
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="W">
                  <Card.Body>{webAppTemplate}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card
                className="m-0"
                style={{
                  borderRadius: "0.4rem",
                  boxShadow: "0px 4px 6px rgba(0,0,0,0.05)",
                }}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="I"
                  style={{ fontSize: "24px" }}
                >
                  Inclusive Events
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="I">
                  <Card.Body>{inclusiveEventTemplate}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </section>
    </GrayPage>
  );
};

export default connect(null, {})(FAQs);
