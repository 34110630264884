import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';


const CourseCard = ({
    addToast,
    activeProfile,
    course,
    firstCourseId,
    activeProfileMeta,
  }) => {

    const direction = i18n.dir();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

return (
    <StyledCourseCard
      style={{ marginTop: `${firstCourseId === course.id ? "0" : "30px"}` }}
    >
      <div className="d-flex flex-column course-card" aria-label="Course card">
        <Link
          to={`/learn/${course.id}`}
          style={{ textDecoration: "none", color: "#000000" }}
        >
          <Row className="d-flex align-items-center">
          
            <Col md={12} className="d-flex justify-content-center px-0">
              <div className="thumbnail-container">
                <img
                  src={course.thumbnail}
                  className="course-img"
                  alt="course-img"
                />
              </div>
            </Col>
            <Col md={12} className="course-content">
              <div className="job-title-a">{course.title}</div>
            </Col>
            <Col md={12} className="course-footer">
              {/* <div className="info-button">
                {course.category.category}
              </div> */}
              {course.course_knowledge_partner.map((partner) => 
               <Col md={12} className="d-flex align-items-center px-0" key={partner.knowledge_partner.id}>
                  <img src={partner.knowledge_partner.logo} className="partner-img" alt="Partner logo"/>
                  {/* <div className="partner-title">{partner.knowledge_partner.title}</div> */}
                  <div className="partner-title">Knowledge Partner</div>
              </Col>
              )}
            </Col>

          </Row>

        </Link>

      </div>
    </StyledCourseCard>
  );
  };

  export default connect((state) => ({
    activeProfile: state.user.activeProfile,
    activeProfileMeta: state.user.activeProfileMeta,
  }), { addToast })(CourseCard);


  const StyledCourseCard = styled.div`
  

  .job-header {
    padding: 20px 24px;
    color: #ffffff;
    background-color: #000000;
    font-size: 1.1rem;
    opacity: 0.9;
  }
.course-card{
  width:33%;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 5px 11px rgba(0,0,0,0.15);
  }
}
@media (max-width: 440px) {
  .course-card{
    width:100%;
  }
}


  .course-content {
    padding: 1.8rem 2rem;
  }


  .course-footer {
    padding: 20px 32px;
    font-size: 1.1rem;
    border-top: 1px solid rgb(233, 233, 233);
  }

  .job-close-date {
    color: #000000;
  }

  .job-title-a {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000000;
    @media(max-width: 560px) {
      font-size: 1.2rem;
    }
  }

  .job-organization, .partner-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #000;
     @media(max-width: 560px) {
      font-size: 1rem;
    }
  }

  .partner-title {
    margin-left: 0.5rem;
  }

  .job-location {
    font-size: 1rem;
    font-weight: 500;
    color: #5F5F5F;
  }

  .job-badge-icon {
    display: flex;
    justify-content: flex-end;
  }

.thumbnail-container, .course-img {
  width:100%;
}

.partner-img {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  border: 1px solid #e5e7e8;
  padding: 4px;
  object-fit: contain;
}

  .job-apply-button {
    display: flex;
    justify-content: flex-end;
  }

  #apply-job {
    background-color: #3F0E40;
    border-color: #3F0E40;
    border-radius: 7px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .info-button {
    font-size: 1rem;
    color: #3F0E40;
    background-color: #ededed;
    border-radius: 7px;
    font-weight: 500;
    display: inline-block;
    padding: .438rem .75rem;
  }

  .btn.disabled, .btn:disabled {
    cursor: default;
  }

  .show-on-mobile {
    display: none;
  }

  @media (max-width: 440px) {
    .show-on-regular {
      display: none !important;
    }
    .show-on-mobile {
      display: flex !important;
    }
    .course-content {
      padding: 1.5rem 1.8rem;
    }
    .course-footer {
      padding: 1.5rem 1.8rem;
    }
    .job-location {
      justify-content: flex-start;
    }
    .job-title.title-ltr {
      padding-left: 0;
    }
    .job-title.title-rtl {
      padding-right: 0;
    }
    .job-apply-button.apply-ltr {
      //justify-content: flex-start;
      //padding-top: 1rem;
      padding-left: 0;
    }
    .job-apply-button.apply-rtl {
      padding-right: 0;
    }
    .job-close-date.date-ltr {
      padding-right: 0;
    }
    .job-close-date.date-rtl {
      padding-left: 0;
    }
  }
`;