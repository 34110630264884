import React, { useState, useRef } from "react";
import TagList from "../../../components/TagList";
import { addHobby, deleteHobby } from "../../../api";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const Hobbies = ({ profile, isOwner, onUpdated }) => {
  const [show, setShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  return (
    <div className="user-ability user-data-wrapper">
      <div className="user-story-header">
        <div>
          <h2 style={{ display: 'flex' }}>{isOwner ? "My " : ``} Hobbies
          {isOwner && (
            <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="My hobbies Tooltip" aria-pressed={showTooltip}>
              <i className="material-icons help-icon">help_outline</i>
            </Button>
          )}
          </h2>
          <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
            {(props) => (
              <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                What activity, interest or pastime do you like outside of work?
              </Tooltip>
            )}
          </Overlay>
        </div>
        {isOwner ? (
          <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600', cursor: 'pointer' }} onClick={() => setShow(true)}>add</i>
        ) : (
          ""
        )}
      </div>
      <hr />
      <div className="user-story-text">
        <div className="abilities-wrapper">
          {profile && profile.person_hobby && profile.person_hobby.length ? (
            <TagList
              onUpdated={onUpdated}
              isOwner={isOwner}
              singularName="Hobby"
              profileId={profile.id}
              add={addHobby}
              remove={deleteHobby}
              name="Hobbies"
              show={show}
              close={setShow}
              items={profile.person_hobby}
              itemName="person_hobby"
            />
          ) : (
            <>
              <h3 className="pb-2 text-red">
                {isOwner
                  ? "You have not added any hobbies yet."
                  : "This user has not added any hobbies yet."}
              </h3>
              <div style={{ display: "none" }}>
                <TagList
                  onUpdated={onUpdated}
                  isOwner={isOwner}
                  singularName="Hobby"
                  profileId={profile.id}
                  add={addHobby}
                  remove={deleteHobby}
                  name="Hobbies"
                  show={show}
                  close={setShow}
                  items={profile.person_hobby}
                  itemName="person_hobby"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hobbies;
