import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { getLinkPreview } from "../api";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';

//Handles links for IB Posts.
const RichLinkPreview = ({ link, activeProfile, isCreator, setIsReq }) => {
    const [fetching, setFetching] = useState(false);

    const [title, setTitle] = useState(false);
    const [description, setDescription] = useState(null);
    const [image, setImage] = useState(null);
    const unmounted = useRef(false);

    const getdata = async () => {
        if (link) 
            if (link.length)                
                getLinkPreview({ link: link })
                    .then((response) => {
                        if (!unmounted.current) {
                            setTitle(response.data.title);
                            setDescription(response.data.description);
                            if (isCreator) {
                                if (!response.data.image)
                                    setIsReq(false)
                                else
                                    setIsReq(true)
                            }
                            setImage(response.data.image);
                        }   
                    })
                    .catch(() => {
                    });
    };

    useEffect(() => {
        setFetching(true);
        getdata();
    });

    useEffect(() => {
        return () => { unmounted.current = true } 
    }, []);
    

    if (!title || title.includes("Not Acceptable!") || title.includes("Page Not Found") || title.includes("Access Denied") || (title.includes("Forbidden") && title.includes("403"))) {

        return <div></div>;

    }

    return (
        <>
            <a href={link.replace("http://", "https://")} className="preview-link" target="_blank" rel="noopener noreferrer">
                <div className="card">
                    <div className="row ">
                        {image ?
                            <>
                                <Col md={4} xs={12}>

                                    <img src={image ? image.replace("http://", "https://") : null} className="w-100" style={{ borderRadius: '8px' }} />
                                </Col>
                                <Col md={8} xs={12}>
                                    <div className="card-block">
                                        <h4 className="card-title preview_link_title">{title ? title.substring(0, 50) + "..." : null}</h4>
                                        <p className="card-text preview_link_description">{description ? description.substring(0, 200) + "..." : null}</p>
                                    </div>
                                </Col>
                            </>
                            :
                            <Col md={12} xs={12}>
                                <div className="card-block">
                                    <h4 className="card-title preview_link_title">{title ? title.substring(0, 50) + "..." : null}</h4>
                                    <p className="card-text preview_link_description">{description ? description.substring(0, 200) + "..." : null}</p>
                                </div>
                            </Col>

                        }

                    </div>
                </div>
            </a>
        </>
    );

};

export default connect(
    state => ({
        activeProfile: state.user.activeProfile
    }),
    {
    }
)(RichLinkPreview);
