import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { addToast } from "../../../redux/toast";
import { connect } from "react-redux";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from 'react-bootstrap/Dropdown';
import BadgeIcon from "../../../assets/images/PODIcon.png";
import AdvocateBadge from "../../../assets/images/SupporterIcon.png";
import Button from "react-bootstrap/Button";
import MyStory from "./MyStory";
import MyConversations from "./MyConversations";
import MyPortfolio from './MyPortfolio';
import Followers from "./Followers";
import Followings from "./Followings";
import MyJobs from "./MyJobs";
import ReportContent from "../../../components/ReportContentModal";
import ProfilePdf from "./ProfilePdf";

import { FollowPerson, unFollowPerson, getFollowPerson, getBlockPersonById, BlockUser, unBlockUser } from "../../../api";

const RightSection = ({
  addToast,
  activeProfile,
  profile,
  onProfileUpdated,
  isOwner,
  TogglePublicView,
  publicView,
  onEditProfile,
}) => {
  const { path } = useRouteMatch();
  const [blockedProfile, setBlockedProfile] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const completeProfile = useSelector(state => state.user.completeProfile);

  useEffect(() => {
    let unmounted = false;
    if (!isOwner) {
      getBlockPersonById(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          if (!unmounted) {
            setBlockedProfile(res.data);
          }
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
        });
    }
    return () => { unmounted = true };
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!isOwner) {
      getFollowPerson(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          if (!unmounted) {
            setFollowing(res.data);
          }
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
        });
    }
    return () => { unmounted = true };
  }, []);

  const handleFollow = () => {
    if (activeProfile.owner_id === profile.owner_id) {
      addToast({
        type: "error",
        title: "Error",
        text: "You cannot follow yourself.",
      });
      return;
    }
    setFollowLoading(true);
    if (following) {
      unFollowPerson(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          if (res.status === 204) setFollowing(false);
          addToast({
            title: "Success",
            text: `Unfollowed successfully.`,
            type: "success",
          });
          setFollowLoading(false);
        })
        .catch((res) => {
          setFollowLoading(false);
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    } else {
      FollowPerson(activeProfile.owner_id, profile.owner_id, {})
        .then((res) => {
          addToast({
            title: "Success",
            text: `You are now following ${profile.full_name}.`,
            type: "success",
          });
          setFollowing(true);
          setFollowLoading(false);
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
          setFollowLoading(false);
        });
    }
  };

  const handleBlockProfile = () => {
    if (activeProfile.owner_id === profile.owner_id) {
      addToast({
        type: "error",
        title: "Error",
        text: "You cannot block yourself.",
      });
      return;
    }
    if (blockedProfile) {
      unBlockUser(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          setBlockedProfile(false);
          addToast({
            title: "Success",
            text: `Unblocked successfully.`,
            type: "success",
          });
        })
        .catch((res) => {
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    } else {
      BlockUser(activeProfile.owner_id, profile.owner_id, {})
        .then((res) => {
          setBlockedProfile(true);
          addToast({
            title: "Success",
            text: `Blocked successfully.`,
            type: "success",
          });
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
        });
    }
  };

  const handleReportProfile = () => {
    if (activeProfile.owner_id === profile.owner_id) {
      addToast({
        type: "error",
        title: "Error",
        text: "You cannot report yourself.",
      });
      return;
    }
    setShowReportModal(true);
  };

  return (
    <section className="dashboard-right-sidebar">
      <aside>
        <div className="right-sidebar">
          <div className="right-sidebar--wrapper">
            <Switch>
              <Route path={`${path}/story`}>
                <Row
                  className="badge-container show-on-regular"
                  style={{ marginTop: "2.8rem" }}
                >
                  <Col
                    md={6}
                    className="d-flex align-items-center my-profile-badge pr-0"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : !publicView ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="show-on-ipad">
                  <Col
                    md={12}
                    className="d-flex align-items-center my-profile-badge"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Row className="show-on-ipad mt-2">
                      <Col md={12} className="d-flex align-items-center">
                        <Button
                          className="profile-follow-btn mx-3"
                          style={{ width: "100%" }}
                          loading={followLoading ? 1 : 0}
                          onClick={handleFollow}
                        >
                          {followLoading ? (
                            <i className="fas fa-spinner fa-spin mr-2" />
                          ) : (
                            <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                          )}{" "}
                          {following ? "Unfollow" : "Follow"}
                        </Button>
                      </Col>
                    </Row>
                  ) : !publicView ? (
                    <Row className="show-on-ipad mt-2">
                      <Col
                        md={12}
                        className="d-flex align-items-center"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Button
                          className="profile-follow-btn"
                          onClick={TogglePublicView}
                        >
                          <i className="fas fa-eye mr-2" /> Public View
                        </Button>
                        <Dropdown
                          className="NavDropdown profile-more-dropdown"
                          id="collasible-nav-dropdown"
                        >
                          <Dropdown.Toggle
                            className="NavDropdown profile-edit-btn ml-2"
                            id="collasible-nav-dropdown"
                          >
                            <i className="fas fa-plus mr-2" />More
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                            <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                              <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                              {completeProfile ? (
                                <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </PDFDownloadLink>
                              ) : (
                                <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </div>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>

                {!isOwner ? (
                  <Row
                    className="badge-container show-on-mobile"
                    style={{ marginTop: "0" }}
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : !publicView ? (
                  <Row
                    className="badge-container show-on-mobile"
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {/* <PDFViewer width={'100%'} height={1000}>
                  <ProfilePdf profile={profile}/>
                </PDFViewer> */}


                <MyStory
                  isOwner={isOwner}
                  profile={profile}
                  onProfileUpdated={onProfileUpdated}
                  TogglePublicView={TogglePublicView}
                  publicView={publicView}
                  handleEditProfile={onEditProfile}
                />
              </Route>

              <Route path={`${path}/conversations`}>
                <Row
                  className="badge-container show-on-regular"
                  style={{ marginTop: "2.8rem" }}
                >
                  <Col
                    md={6}
                    className="d-flex align-items-center my-profile-badge pr-0"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : !publicView ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="show-on-ipad">
                  <Col
                    md={12}
                    className="d-flex align-items-center my-profile-badge"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Row className="show-on-ipad mt-2">
                      <Col md={12} className="d-flex align-items-center">
                        <Button
                          className="profile-follow-btn mx-3"
                          style={{ width: "100%" }}
                          loading={followLoading ? 1 : 0}
                          onClick={handleFollow}
                        >
                          {followLoading ? (
                            <i className="fas fa-spinner fa-spin mr-2" />
                          ) : (
                            <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                          )}{" "}
                          {following ? "Unfollow" : "Follow"}
                        </Button>
                      </Col>
                    </Row>
                  ) : !publicView ? (
                    <Row className="show-on-ipad mt-2">
                      <Col
                        md={12}
                        className="d-flex align-items-center"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Button
                          className="profile-follow-btn"
                          onClick={TogglePublicView}
                        >
                          <i className="fas fa-eye mr-2" /> Public View
                        </Button>
                        <Dropdown
                          className="NavDropdown profile-more-dropdown"
                          id="collasible-nav-dropdown"
                        >
                          <Dropdown.Toggle
                            className="NavDropdown profile-edit-btn ml-2"
                            id="collasible-nav-dropdown"
                          >
                            <i className="fas fa-plus mr-2" />More
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                            <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                              <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                              {completeProfile ? (
                                <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </PDFDownloadLink>
                              ) : (
                                <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </div>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>

                {!isOwner ? (
                  <Row
                    className="badge-container show-on-mobile"
                    style={{ marginTop: "0" }}
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : !publicView ? (
                  <Row
                    className="badge-container show-on-mobile"
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <MyConversations profile={profile} isOwner={isOwner} />
              </Route>

              <Route path={`${path}/portfolio`}>
                <Row
                  className="badge-container show-on-regular"
                  style={{ marginTop: "2.8rem" }}
                >
                  <Col
                    md={6}
                    className="d-flex align-items-center my-profile-badge pr-0"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : !publicView ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="show-on-ipad">
                  <Col
                    md={12}
                    className="d-flex align-items-center my-profile-badge"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Row className="show-on-ipad mt-2">
                      <Col md={12} className="d-flex align-items-center">
                        <Button
                          className="profile-follow-btn mx-3"
                          style={{ width: "100%" }}
                          loading={followLoading ? 1 : 0}
                          onClick={handleFollow}
                        >
                          {followLoading ? (
                            <i className="fas fa-spinner fa-spin mr-2" />
                          ) : (
                            <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                          )}{" "}
                          {following ? "Unfollow" : "Follow"}
                        </Button>
                      </Col>
                    </Row>
                  ) : !publicView ? (
                    <Row className="show-on-ipad mt-2">
                      <Col
                        md={12}
                        className="d-flex align-items-center"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Button
                          className="profile-follow-btn"
                          onClick={TogglePublicView}
                        >
                          <i className="fas fa-eye mr-2" /> Public View
                        </Button>
                        <Dropdown
                          className="NavDropdown profile-more-dropdown"
                          id="collasible-nav-dropdown"
                        >
                          <Dropdown.Toggle
                            className="NavDropdown profile-edit-btn ml-2"
                            id="collasible-nav-dropdown"
                          >
                            <i className="fas fa-plus mr-2" />More
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                            <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                              <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                              {completeProfile ? (
                                <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </PDFDownloadLink>
                              ) : (
                                <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </div>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>

                {!isOwner ? (
                  <Row
                    className="badge-container show-on-mobile"
                    style={{ marginTop: "0" }}
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : !publicView ? (
                  <Row
                    className="badge-container show-on-mobile"
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <MyPortfolio portfolios={profile.project} isOwner={isOwner} refreshProfile={onProfileUpdated} profileId={profile.id} />
              </Route>

              <Route path={`${path}/followers`}>
                <Row
                  className="badge-container show-on-regular"
                  style={{ marginTop: "2.8rem" }}
                >
                  <Col
                    md={6}
                    className="d-flex align-items-center my-profile-badge pr-0"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : !publicView ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="show-on-ipad">
                  <Col
                    md={12}
                    className="d-flex align-items-center my-profile-badge"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Row className="show-on-ipad mt-2">
                      <Col md={12} className="d-flex align-items-center">
                        <Button
                          className="profile-follow-btn mx-3"
                          style={{ width: "100%" }}
                          loading={followLoading ? 1 : 0}
                          onClick={handleFollow}
                        >
                          {followLoading ? (
                            <i className="fas fa-spinner fa-spin mr-2" />
                          ) : (
                            <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                          )}{" "}
                          {following ? "Unfollow" : "Follow"}
                        </Button>
                      </Col>
                    </Row>
                  ) : !publicView ? (
                    <Row className="show-on-ipad mt-2">
                      <Col
                        md={12}
                        className="d-flex align-items-center"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Button
                          className="profile-follow-btn"
                          onClick={TogglePublicView}
                        >
                          <i className="fas fa-eye mr-2" /> Public View
                        </Button>
                        <Dropdown
                          className="NavDropdown profile-more-dropdown"
                          id="collasible-nav-dropdown"
                        >
                          <Dropdown.Toggle
                            className="NavDropdown profile-edit-btn ml-2"
                            id="collasible-nav-dropdown"
                          >
                            <i className="fas fa-plus mr-2" />More
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                            <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                              <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                              {completeProfile ? (
                                <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </PDFDownloadLink>
                              ) : (
                                <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </div>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>

                {!isOwner ? (
                  <Row
                    className="badge-container show-on-mobile"
                    style={{ marginTop: "0" }}
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : !publicView ? (
                  <Row
                    className="badge-container show-on-mobile"
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Followers profile={profile} isOwner={isOwner} />
              </Route>

              <Route path={`${path}/following`}>
                <Row
                  className="badge-container show-on-regular"
                  style={{ marginTop: "2.8rem" }}
                >
                  <Col
                    md={6}
                    className="d-flex align-items-center my-profile-badge pr-0"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : !publicView ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="show-on-ipad">
                  <Col
                    md={12}
                    className="d-flex align-items-center my-profile-badge"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Row className="show-on-ipad mt-2">
                      <Col md={12} className="d-flex align-items-center">
                        <Button
                          className="profile-follow-btn mx-3"
                          style={{ width: "100%" }}
                          loading={followLoading ? 1 : 0}
                          onClick={handleFollow}
                        >
                          {followLoading ? (
                            <i className="fas fa-spinner fa-spin mr-2" />
                          ) : (
                            <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                          )}{" "}
                          {following ? "Unfollow" : "Follow"}
                        </Button>
                      </Col>
                    </Row>
                  ) : !publicView ? (
                    <Row className="show-on-ipad mt-2">
                      <Col
                        md={12}
                        className="d-flex align-items-center"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Button
                          className="profile-follow-btn"
                          onClick={TogglePublicView}
                        >
                          <i className="fas fa-eye mr-2" /> Public View
                        </Button>
                        <Dropdown
                          className="NavDropdown profile-more-dropdown"
                          id="collasible-nav-dropdown"
                        >
                          <Dropdown.Toggle
                            className="NavDropdown profile-edit-btn ml-2"
                            id="collasible-nav-dropdown"
                          >
                            <i className="fas fa-plus mr-2" />More
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                            <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                              <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                              {completeProfile ? (
                                <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </PDFDownloadLink>
                              ) : (
                                <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </div>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>

                {!isOwner ? (
                  <Row
                    className="badge-container show-on-mobile"
                    style={{ marginTop: "0" }}
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : !publicView ? (
                  <Row
                    className="badge-container show-on-mobile"
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Followings profile={profile} isOwner={isOwner} />
              </Route>
              <Route path={`${path}/opportunities`}>
                <Row
                  className="badge-container show-on-regular"
                  style={{ marginTop: "2.8rem" }}
                >
                  <Col
                    md={6}
                    className="d-flex align-items-center my-profile-badge pr-0"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : !publicView ? (
                    <Col
                      md={6}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="show-on-ipad">
                  <Col
                    md={12}
                    className="d-flex align-items-center my-profile-badge"
                  >
                    {(profile.identifies_as === "Proud to be Person of Determination" || profile.identifies_as === "Person with Disability") ? (
                      <div className="badge-container">
                        <img src={BadgeIcon} alt="badge" className="" />
                      </div>
                    ) : (
                      <div className="badge-container">
                        <img src={AdvocateBadge} alt="badge" className="" />
                      </div>
                    )}
                    <div className="badgetext pl-2 pt-1">
                      {profile.identifies_as}
                    </div>
                  </Col>
                  {!isOwner ? (
                    <Row className="show-on-ipad mt-2">
                      <Col md={12} className="d-flex align-items-center">
                        <Button
                          className="profile-follow-btn mx-3"
                          style={{ width: "100%" }}
                          loading={followLoading ? 1 : 0}
                          onClick={handleFollow}
                        >
                          {followLoading ? (
                            <i className="fas fa-spinner fa-spin mr-2" />
                          ) : (
                            <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                          )}{" "}
                          {following ? "Unfollow" : "Follow"}
                        </Button>
                      </Col>
                    </Row>
                  ) : !publicView ? (
                    <Row className="show-on-ipad mt-2">
                      <Col
                        md={12}
                        className="d-flex align-items-center"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Button
                          className="profile-follow-btn"
                          onClick={TogglePublicView}
                        >
                          <i className="fas fa-eye mr-2" /> Public View
                        </Button>
                        <Dropdown
                          className="NavDropdown profile-more-dropdown"
                          id="collasible-nav-dropdown"
                        >
                          <Dropdown.Toggle
                            className="NavDropdown profile-edit-btn ml-2"
                            id="collasible-nav-dropdown"
                          >
                            <i className="fas fa-plus mr-2" />More
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                            <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                              <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                              {completeProfile ? (
                                <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </PDFDownloadLink>
                              ) : (
                                <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                  Download CV
                                </div>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>

                {!isOwner ? (
                  <Row
                    className="badge-container show-on-mobile"
                    style={{ marginTop: "0" }}
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        loading={followLoading ? 1 : 0}
                        onClick={handleFollow}
                      >
                        {followLoading ? (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        ) : (
                          <i className="material-icons mr-2">{following ? 'person_remove' : 'person_add_alt_1'}</i>
                        )}{" "}
                        {following ? "Unfollow" : "Follow"}
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                            {blockedProfile ? `Unblock ${profile.full_name}` : `Block ${profile.full_name}`}
                          </Dropdown.Item>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                            {`Report ${profile.full_name}`}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : !publicView ? (
                  <Row
                    className="badge-container show-on-mobile"
                  >
                    <Col
                      md={12}
                      className="d-flex align-items-center"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        className="profile-follow-btn"
                        onClick={TogglePublicView}
                      >
                        <i className="fas fa-eye mr-2" /> Public View
                      </Button>
                      <Dropdown
                        className="NavDropdown profile-more-dropdown"
                        id="collasible-nav-dropdown"
                      >
                        <Dropdown.Toggle
                          className="NavDropdown profile-edit-btn ml-2"
                          id="collasible-nav-dropdown"
                        >
                          <i className="fas fa-plus mr-2" />More
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                          <Dropdown.Item style={{ padding: "0.8rem" }} aria-label="Edit Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                            <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                            Edit Profile
                          </Dropdown.Item>
                          <Dropdown.Item aria-label="Profile as PDF" className="d-flex align-items-center" as="div" style={{ padding: 0 }}>
                            {completeProfile ? (
                              <PDFDownloadLink document={<ProfilePdf profile={profile} />} fileName={`${profile.full_name}'s CV.pdf`} className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem" }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </PDFDownloadLink>
                            ) : (
                              <div className="d-flex align-items-center dropdown-item" style={{ padding: "0.8rem", opacity: 0.8 }}>
                                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>download</i>
                                Download CV
                              </div>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <MyJobs />
              </Route>

              <Redirect to={`${path}/story`} isOwner={isOwner} />
            </Switch>

            <ReportContent modalVisible={showReportModal} setModalVisible={setShowReportModal} contentType="U" contentId={profile.id} />

          </div>
        </div>
      </aside>
    </section>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {
    addToast,
  }
)(RightSection);
// export default RightSection