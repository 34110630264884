//Registeration & Login
const auth = '/auth';
export const register = `${auth}/registration/`;
export const verifyEmail = (key) => `/auth/registration/account-confirm-email/${key}/`;
export const login = `${auth}/login/`;


//Password
export const passwordChange = `${auth}/password/change/`;
export const passwordReset = `${auth}/password/reset/`;
export const passwordResetConfirm = `${auth}/password/reset/confirm/`;


//IB Feed
export const feed = '/feed/';
export const userFeed = (userId) => `${feed}${userId}/`;

//Feed Photo
export const feedPhotoByPhotoId = (postId, photo_id) => `/feed/post/${postId}/photo/${photo_id}/`;

//Feed Post
export const feedPost = `/feed/post/v2/`;
export const feedPostById = (postId) => `/feed/post/${postId}/`;
export const UserfeedById = (ownerId) => `/feed/post/profile/${ownerId}/`;

//Feed Comment
export const commentFeed = (postId) => `/feed/v2/post/${postId}/comment/`;
export const commentFeedById = (postId, commentId) => `/feed/post/${postId}/comment/${commentId}/`;
export const comment = (activityId) => `${feed}${activityId}/comment/`;
export const commentById = (activityId, commentId) => `${feed}${activityId}/comment/${commentId}/`;

//Feed Post Reactions
export const postReaction = (postId) => `/reaction/v2/post/${postId}/`;
export const postReactionById = (reactionId) => `/reaction/v2/${reactionId}/post/`;

//Feed Comment Reactions
export const commentReaction = (commentId) => `/reaction/v2/comment/${commentId}/`;
export const commentReactionById = (reactionId) => `/reaction/v2/${reactionId}/comment/`;

//Feed Follow Post
export const followPost = (postId, ownerId) => `/feed/post/${postId}/${ownerId}/follow/`;

//Feed Link
export const previewLink = '/feed/linkpreview/';

//Feed Content
export const feedContent ='/feed/reportcontent/';


//Notifications
const notifications = '/notification';
export const unreadNotifications = `${notifications}/unread/`;
export const readNotifications = `${notifications}/read/`;
export const markNotificationRead = `${notifications}/markread/`;

//Email Notifications
export const emailNotifications = (ownerId) => `/users/${ownerId}/settings/`;


//Location
export const countries = '/location/country/';
export const cityByCountry = (countryId) => `/location/country/${countryId}/`;


//Search
export const search = `/api/search/`;


//Follow User
export const followUser = (ownerId, followOwnerId) => `/users/${ownerId}/follow/${followOwnerId}/`;
export const followerList = (ownerId) => `/users/${ownerId}/followers/`;
export const followingList = (ownerId) => `/users/${ownerId}/following/`;


//Block User
export const blockUser = (ownerId) => `/users/${ownerId}/block/`;
export const blockUserByOwnerId = (ownerId, blockOwnerId) => `/users/${ownerId}/block/${blockOwnerId}/`;


//Person Profile
export const profile = '/users/profile/';
export const listProfile = '/profile/';
export const profileById = (profileId) => `/profile/${profileId}/`;
export const profileBySlug = (slug) => `/profile/${slug}/`;

//Update Profile
export const updateProfile = (id) => `/profile/${id}/`;

//Person Post
export const post = (profileId) => `/profile/${profileId}/post/`;
export const postById = (profileId, postId) => `/profile/${profileId}/post/${postId}/`;

//Person Photo
export const userPhoto = (profileId) => `/profile/${profileId}/photo/`;
export const photoById = (profileId, photoId) => `/profile/${profileId}/photo/${photoId}/`;

//Person Education
export const addEducation = (id) => `/profile/${id}/education/`;
export const educationById = (profileId, experienceId) => `/profile/${profileId}/education/${experienceId}/`;

//Person Experience
export const addExperience = (id) => `/profile/${id}/experience/`;
export const experienceById = (profileId, experienceId) => `/profile/${profileId}/experience/${experienceId}/`;

//Person Skill
export const personSkill = (profileId) => `/profile/${profileId}/skill/`;
export const personSkillById = (profileId, skillId) => `/profile/${profileId}/skill/${skillId}/`;

//Person Accommodation
export const personAcccomadation = (profileId) => `/profile/${profileId}/accommodation/`;
export const personAcccomadationById = (profileId, accommodationId) => `/profile/${profileId}/accommodation/${accommodationId}/`;

//Person Hobby
export const personHobbies = (profileId) => `/profile/${profileId}/hobby/`;
export const personHobbyById = (profileId, hobbyId) => `/profile/${profileId}/hobby/${hobbyId}`;

//Person Achievement
export const personAchievement = (profileId) => `/profile/${profileId}/achievement/`;
export const personAchievementById = (profileId, achievementId) => `/profile/${profileId}/achievement/${achievementId}/`;

//Person Project
export const personProject = (profileId) => `/profile/${profileId}/project/`;
export const personProjectById = (profileId, projectId) => `/profile/${profileId}/project/${projectId}/`;

//Person Event
export const userEvents = (profileId) => `/profile/${profileId}/event/`;
export const unattendEvent = (profileId, eventId) => `${userEvents(profileId)}${eventId}/`;

//Person Follower/Following
export const follow = (profileId) => `/profile/${profileId}/follower/`;
export const following = (profileId, followerId) => `/profile/${profileId}/follower/${followerId}/`;
export const getFollowers = (profileId) => `/profile/${profileId}/follower/`;
export const getFollowing = (profileId) => `/profile/${profileId}/following/`;

//Person Blocked
export const blockProfile = (profileId) => `/profile/${profileId}/block/`;
export const blockedProfile = (profileId, blockerId) => `/profile/${profileId}/block/${blockerId}/`;

//Person Contact
export const personContact = (profileId) => `/profile/${profileId}/contact/`;


//Organization Profile
export const organizationProfile = '/api/org/';
export const organizationProfileBySlug = (orgSlug) => `/api/org/${orgSlug}/`;

//Organization Industries
export const organizationIndustries = '/api/org/industry/';

//Organization Jobs
export const organizationJobs = `/api/org/job/`;
export const organizationAllJobs = '/api/org/job/';
export const organizationJobsByOrgSlug = (orgSlug) => `/api/org/${orgSlug}/job/`;
export const organizationJobBySlug = (jobSlug) => `/api/org/job/${jobSlug}/`;
export const organizationJobCategories = '/api/org/jobcategory/';

//Organization Job Locations
export const organizationJobLocation = (jobSlug) => `/api/org/job/${jobSlug}/location/`;
export const organizationJobLocationDetail = (jobSlug, locationId) => `/api/org/job/${jobSlug}/location/${locationId}/`;

//Organization Job Skill Options
export const organizationSkillOptions = '/api/org/jobskilloption/';

//Organization Job Skills
export const organizationJobSkill = (jobSlug) => `/api/org/job/${jobSlug}/skill/`;
export const organizationJobSkillDetail = (jobSlug, skillId) => `/api/org/job/${jobSlug}/skill/${skillId}/`;

//Organization Job Accommodations 
export const jobAccommodationCognitive = (jobSlug) => `/api/org/job/${jobSlug}/accommodations/cognitive/`;
export const jobAccommodationCognitiveDetail = (jobSlug, accommodationId) => `/api/org/job/${jobSlug}/accommodations/cognitive/${accommodationId}/`;
export const jobAccommodationHearing = (jobSlug) => `/api/org/job/${jobSlug}/accommodations/hearing/`;
export const jobAccommodationHearingDetail = (jobSlug, accommodationId) => `/api/org/job/${jobSlug}/accommodations/hearing/${accommodationId}/`;
export const jobAccommodationMobility = (jobSlug) => `/api/org/job/${jobSlug}/accommodations/mobility/`;
export const jobAccommodationMobilityDetail = (jobSlug, accommodationId) => `/api/org/job/${jobSlug}/accommodations/mobility/${accommodationId}/`;
export const jobAccommodationSensory = (jobSlug) => `/api/org/job/${jobSlug}/accommodations/sensory/`;
export const jobAccommodationSensoryDetail = (jobSlug, accommodationId) => `/api/org/job/${jobSlug}/accommodations/sensory/${accommodationId}/`;
export const jobAccommodationVisual = (jobSlug) => `/api/org/job/${jobSlug}/accommodations/visual/`;
export const jobAccommodationVisualDetail = (jobSlug, accommodationId) => `/api/org/job/${jobSlug}/accommodations/visual/${accommodationId}/`;

//Organization Job Accommodations Lists
export const accommodationsCognitive = `/api/org/jobaccommodations/cognitive/`;
export const accommodationsHearing = `/api/org/jobaccommodations/hearing/`;
export const accommodationsMobility = `/api/org/jobaccommodations/mobility/`;
export const accommodationsSensory = `/api/org/jobaccommodations/sensory/`;
export const accommodationsVisual = `/api/org/jobaccommodations/visual/`;

//Organization Job Screening Questions
export const jobScreeningQuestion = (jobSlug) => `/api/org/job/${jobSlug}/question/`;
export const jobScreeningQuestionDetail = (jobSlug, questionId) => `/api/org/job/${jobSlug}/question/${questionId}/`;
export const jobScreeningQuestionChoice = (jobSlug, questionId, choiceId) => `/api/org/job/${jobSlug}/question/${questionId}/choice/${choiceId}/`;

//Organization Events
export const organizationEvents = `/api/org/event/`;
export const organizationAllEvents = '/api/org/event/';
export const organizationEventsByorgSlug = (orgSlug) => `/api/org/${orgSlug}/event/`;
export const organizationEventBySlug = (eventSlug) => `/api/org/event/${eventSlug}/`;
export const organizationEventCategories = '/api/org/eventcategory/';

//Organization Event Time
export const organizationEventTime = (eventSlug) => `/api/org/event/${eventSlug}/timing/`;
export const OrganizationEventTimeById = (eventSlug, timeId) => `/api/org/event/${eventSlug}/timing/${timeId}/`;

//Organization Initiatives
export const organizationInitiatives = `/api/org/portfolio/all/`;
export const organizationInitiativeById = (orgSlug, initiativeId) => `/api/org/portfolio/${orgSlug}/${initiativeId}/`;


//Events
export const event = '/event/';
export const eventById = (eventId) => `${event}${eventId}/`;
export const eventCategories = `${event}/category/`;

//Event Attendee
export const attendedEvents = `/api/event/person/attendee/`;
export const attendeeList = (eventTimingId) => `/api/event/${eventTimingId}/attendee/`;

//Event Application
export const acceptApplication = (eventId, applicationId) => `/api/event/${eventId}/attendee/${applicationId}/accept/`;
export const rejectApplication = (eventId, applicationId) => `/api/event/${eventId}/attendee/${applicationId}/reject/`;
export const withdrawApplication = (attendeeEventId) => `/api/event/person/attendee/${attendeeEventId}/withdraw/`;


//Jobs
export const jobs = '/job/';
export const jobCategories = '/job/category/';

//Job Apply
export const applyJob = `/api/job/person/application/`;
export const applyForJob = (job_id) => `/api/job/${job_id}/application/`;

//Job Screening Questions Answer
export const screeningQuestionAnswer = (applicationId) => `/api/job/person/application/${applicationId}/question/`;

//Job Application
export const JobApplicants = (job_id) => `/api/job/${job_id}/application/`;
export const JobApplicantDetail = (job_id, application_id) => `/api/job/${job_id}/application/${application_id}/`;
export const shortlistApplicant = (job_id, application_pk) => `/api/job/${job_id}/application/${application_pk}/shortlist/`;
export const rejectApplicant = (job_id, application_pk) => `/api/job/${job_id}/application/${application_pk}/reject/`;


//Learn 
export const CourseList = `/learn/courses/`;
export const CourseCategoryList = `/learn/category/`;
export const CourseDetail = (course_id) => `/learn/courses/${course_id}/`;

export const LessonDetail = (module_id) => `/learn/module/${module_id}/`;
export const LessonQuiz = (lesson_id) => `/learn/lesson/${lesson_id}/quiz/`;

export const SubmitQuiz = (module_id) => `/learn/module/${module_id}/quiz/`;
export const QuizResult = (module_id) => `/learn/module/${module_id}/result/`;

export const enrollCourse = (course_id) => `/learn/enroll/${course_id}/`;
export const moduleComplete = (module_id) => `/learn/complete/${module_id}/`;