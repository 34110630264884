import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  NavLink as Link,
} from "react-router-dom";

import styled from "styled-components";
import { connect } from "react-redux";
import { addToast } from "../../redux/toast";

import PasswordChangeTab from "./Tabs/PasswordChangeTab";
import AccountDeleteTab from "./Tabs/AccountDeleteTab";
import BlockedUsers from "./Tabs/BlockedUsers";
import NotificationsTab from './Tabs/NotificationsTab';
import LanguageTab from './Tabs/LanguageTab';

import { setActiveProfile, getAvailableProfiles } from "../../redux/user";

const AccountPage = ({
  availableProfiles,
  activeProfile,
  activeProfileType,
  setActiveProfile,
  getAvailableProfiles,
  currentLanguage,
  onLanguageChange
}) => {

  //const { pathname: path } = useLocation();
  const { path, url } = useRouteMatch();

  return (
    <React.Fragment>
      <div
        className="container topContainer-account"
        style={{ minHeight: "78vh" }}
      >
        <div className="row mt-3 pt-3">
          <div className="col-md-3 account-card">
            <StyledAccountCard>
              <span className="account-heading">Account Settings</span>
              <div
                className={`account-nav px-0 ${
                  path === `${path}/passwordchange` ? "active" : ""
                }`}
              >
                <Link
                  aria-label="Change Password"
                  to={`${url}/passwordchange`}
                  className="account-nav-link"
                >
                  <i className="material-icons md-24 mr-2">vpn_key</i>
                  Change Password
                </Link>
              </div>

              <div
                className={`account-nav px-0 ${
                  path === `${path}/accountdelete` ? "active" : ""
                }`}
              >
                <Link
                  aria-label="Delete Account"
                  to={`${url}/accountdelete`}
                  className="account-nav-link"
                >
                  <i className="material-icons md-24 mr-2">delete</i>
                  Delete Account
                </Link>
              </div>

              <div
                className={`account-nav px-0 ${
                  path === `${path}/BlockedUsers` ? "active" : ""
                }`}
              >
                <Link
                  aria-label="Blocked Users"
                  to={`${url}/BlockedUsers`}
                  className="account-nav-link"
                >
                  <i className="material-icons md-24 mr-2">block</i>
                  Blocked Users
                </Link>
              </div>

              <div
                className={`account-nav px-0 ${
                  path === `${path}/notifications` ? "active" : ""
                }`}
              >
                <Link
                  aria-label="Notifications"
                  to={`${url}/notifications`}
                  className="account-nav-link"
                >
                  <i className="material-icons md-24 mr-2">notifications</i>
                  Notifications
                </Link>
              </div>

              {/* <div
                className={`account-nav px-0 ${
                  path === `${path}/language` ? "active" : ""
                }`}
              >
                <Link
                  aria-label="Language"
                  to={`${url}/language`}
                  className="account-nav-link"
                >
                  <i className="material-icons md-24 mr-2">translate</i>
                  Language
                </Link>
              </div> */}

              <div className="account-nav px-0">
                <Link
                  aria-label="Privacy Policy"
                  to="/privacypolicy"
                  rel="nofollow"
                  className="account-nav-link"
                >
                  <i className="material-icons md-24 mr-2">privacy_tip</i>
                  Privacy Policy
                </Link>
              </div>

              <div className="account-nav px-0">
                <Link
                  aria-label="Terms and Conditions"
                  to="/termsandconditions"
                  rel="nofollow"
                  className="account-nav-link"
                >
                  <i className="material-icons md-24 mr-2">article</i>
                  Terms and Conditions
                </Link>
              </div>

            </StyledAccountCard>
          </div>
          <StyledAccountTab className="col-md-9 account-card">
            <Switch>
              <Route path={`${path}/passwordchange`}>
                <PasswordChangeTab />
              </Route>

              <Route path={`${path}/accountdelete`}>
                <AccountDeleteTab />
              </Route>
              <Route path={`${path}/BlockedUsers`}>
                <BlockedUsers isOwner profile={activeProfile} />
              </Route>
              <Route path={`${path}/notifications`}>
                <NotificationsTab profile={activeProfile} />
              </Route>
              {/* <Route path={`${path}/language`}>
                <LanguageTab profile={activeProfile} currentLanguage={currentLanguage} onLanguageChange={onLanguageChange}/>
              </Route> */}
              <Redirect to={`${path}/passwordchange`} />
            </Switch>
          </StyledAccountTab>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {
    setActiveProfile,
  }
)(AccountPage);

const StyledAccountCard = styled.div`
  border-radius: 0.4rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border: 0;
  background-color: white;
  width: 100%;
  border-image: initial;
  margin-top: 1rem;

  .account-heading {
    padding: 1.2rem;
    display: flex;
    font-weight: bold;
    font-size: 26px;
    line-height: 24px;
    color: #000000;
  }

  .account-nav {
    border-top: solid 1.5px #dadbe2;
  }

  .account-nav a {
    color: #5F5F5F;
    font-weight: bold;
    text-decoration: none;
    display: block;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    //text-align: center;

    &:hover {
      color: #3F0E40;
      text-decoration: none;
    }
  }

  a.account-nav-link.active {
    color: #3F0E40;
    border-left: 3px solid #3F0E40;
    background-color: #f4f4f4;
  }
`;
const StyledAccountTab = styled.div`

  .password-change-card {
    border-radius: 0.4rem;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border: 0;
    margin-bottom: 100px;
    padding: 30px 30px 15px 30px;
    @media(max-width: 480px) {
      padding: 15px 0 5px 0;
      margin-bottom: 0;
    }
  }

  label.form-label {
    font-size: 1.2rem;
    font-weight: 500;
    color: #5F5F5F !important;
    margin-bottom: 0;
    margin-top: 1rem;
  }

  .change-password-btn, .delete-account-btn {
    font-weight: 500;
    background-color: #3F0E40 !important;
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 18px 0 18px 18px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: #fff;
    border: 0;
    &:hover {
      transform: none;
      box-shadow: none;
      border-color: #3F0E40 !important;
    }
  }

  .form-control {
    font-size: 1rem;
    border: none !important;
    border-bottom: 2px solid #d5d5d5 !important;
    letter-spacing: 0.05rem;
    padding-left: 0 !important;
    border-radius: 0 !important;
    background-color: #fff;
  }

  .delete-account-card {
    border-radius: 0.4rem;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border: 0;
    padding: 3rem 0;
      @media(max-width: 480px) {
      padding: 0;
      margin-bottom: 0 !important;
    }
  }

  .delete-account-btn {
    width: 50%;
    @media(max-width: 480px) {
      width: 90%;
      margin-bottom: 0.5rem;
    }
  }
`;
