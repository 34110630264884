import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { logoutUser, switchProfile, setUserFeed } from "../../redux/user";
import { addToast } from "../../redux/toast";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Toggle = React.forwardRef(({ children, className, onClick }, ref) => (
  <a
    className={className}
    id="dropdownUserMenu"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const Menu = React.forwardRef(({ children, className }, ref) => {
  const [value] = useState("");
  const direction = i18n.dir();

  return (
    <div
      className={className}
      tabIndex="-1"
      aria-labelledby="dropdownUserMenu"
      ref={ref}
      style={{right: `${direction === "rtl" ? "auto" : ""}`, left: `${direction === "rtl" ? 0 : ""}`}}
    >
      <ul className={`list-unstyled ${direction === "rtl" ? "pr-0" : ""}`}>
        {React.Children.toArray(children).filter(
          (child) =>
            !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </ul>
    </div>
  );
});

const ProfileDropdown = ({
  availableProfiles,
  activeProfile,
  logoutUser,
  switchProfile,
  onManageProfiles,
  activeProfileMeta,
  user,
  addToast,
  setExpanded,
}) => {
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [name, setName] = useState("");
  const { t } = useTranslation();
  const direction = i18n.dir();

  const [switchUser, setUser] = useState({});
  const verifiedProfile = activeProfile ? activeProfile.verified : null;

  const history = useHistory();

  let orgProfiles = [];

  if (availableProfiles) {
    orgProfiles = availableProfiles.org;
  }

  const switchUserProfile = (user) => {
    const isOrg = "organization_title" in user;
    isOrg ? setName(user.organization_title) : setName(user.full_name);
    setShow(true);
    setUser(user);
    setExpanded(false);
  };

  const switchToprofile = () => {
    setSwitchLoading(true);
    switchProfile(switchUser).then(() => {
      setSwitchLoading(true);
      addToast({
        title: `${t("Success")}`,
        text: `${t("Switched to")} ${name} ${t("profile successfully")}`,
        type: "success",
      });
      setShow(false);
      setSwitchLoading(false);
      setName("");
    });
  };

  const handleUnverifiedProfile = () => {
    setExpanded(false);
    addToast({
      title: `${t("Error")}`,
      text: `Sorry, you're not authorized to perform this operation due to pending verification of your profile.`,
      type: "error",
    });
  }

  return (
    <>
      <Dropdown
        className="NavDropdown"
        title="Profile"
        id="collasible-nav-dropdown"
      >
        <Dropdown.Toggle
          className="NavDropdown"
          as={Toggle}
          title="Dropdown"
          id="collasible-nav-dropdown"
        >
          {activeProfile && (
            <img
              src={
                activeProfileMeta.type === 'org'
                  ? activeProfile.organization_logo
                  : activeProfile.person_display_picture
              }
              aria-label="User profile dropdown"
              alt="Avatar"
              className="profile-dropdown hide-on-mobile"
            />
          )}
          <span className="show-on-mobile">
            {activeProfile
              ? activeProfileMeta.type === 'org'
                ? activeProfile.organization_title
                : `${activeProfile.person_first_name} ${activeProfile.person_last_name} `
              : ""}
            <i className="fas fa-caret-down"></i>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu as={Menu} className="dropdown-menu m-0 p-0">
          <Dropdown.Item
            as={Link}
            to="/profile"
            onClick={() => setExpanded(false)}
            aria-label="profile"
          >
            <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>account_circle</i>
            {activeProfile
              ? activeProfileMeta.type === 'org'
                ? activeProfile.organization_title
                : `${activeProfile.person_first_name} ${activeProfile.person_last_name} `
              : ""}
          </Dropdown.Item>
          <Dropdown.Divider />

          {activeProfile && orgProfiles.length ? (
            activeProfileMeta.type === "org" ? (
              <Dropdown.Item
                onClick={() => switchUserProfile(availableProfiles.individual)}
                role="link"
                aria-label="Switch Profile"
              >
                <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>
                  swap_horizontal_circle
                </i>
                {availableProfiles.individual.full_name}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={() => switchUserProfile(orgProfiles[0])}
                role="link"
                aria-label="Switch Profile"
              >
                <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>
                  swap_horizontal_circle
                </i>
                {orgProfiles[0]?.organization_title}
              </Dropdown.Item>
            )
          ) : (
            <Dropdown.Item
              onClick={() => {
                setShowNew(true);
                setExpanded(false);
              }}
              role="link"
              aria-label="Create Organization Profile"
            >
              <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>add_circle</i>{t("Create Organization")}
            </Dropdown.Item>
          )}

          {activeProfile ? (
            activeProfileMeta.type === 'org' ? (
              verifiedProfile ? (
              <Dropdown.Item
                as={Link}
                to="/org/dashboard"
                onClick={() => setExpanded(false)}
                aria-label="Organization Dashboard"
              >
                <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>dashboard</i>Dashboard
              </Dropdown.Item>
              ) : (
                <Dropdown.Item
                onClick={() => handleUnverifiedProfile()}
                aria-label="Organization Dashboard"
                style={{color: "#6c757d"}}
              >
                <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>dashboard</i>Dashboard
              </Dropdown.Item>
              )
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <Dropdown.Item
            as={Link}
            to="/account"
            onClick={() => setExpanded(false)}
            aria-label="Account Settings"
            >
            <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>settings</i>{t("Account Settings")}
          </Dropdown.Item>

          <Dropdown.Item
            as={Link}
            to="/faqs"
            onClick={() => setExpanded(false)}
            aria-label="Frequently Asked Questions"
            >
            <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>question_answer</i>{t("FAQs")}
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            className="mb-0"
            onClick={logoutUser}
            role="Link"
            aria-label="Logout"
          >
            <i className={`material-icons md-24 ${direction === "rtl" ? "ml-2" : "mr-2"}`}>exit_to_app</i>{t("Logout")}
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>

      <Modal show={show} centered size="md" onHide={() => setShow(false)} className={direction === "rtl" ? "arabic-display" : ""}>
        <Modal.Header style={{ fontSize: '20px', fontWeight: '500' }}>{t("Switch Accounts")}</Modal.Header>
        <Modal.Body className="py-0">
          {activeProfile && (<>
            <Row className="current-account d-flex align-items-center py-3" style={{ backgroundColor: '#f5f5f5' }}>
              <Col md={2} xs={3}>
                <div style={{ width: '60px', height: '60px' }}>
                  <img src={activeProfileMeta.type === 'org' ? activeProfile.organization_logo : activeProfile.person_display_picture} aria-label="active profile logo" style={{ width: '100%', height: '100%', objectFit: 'cover', border: '1px solid #c4c4c4', borderRadius: '50%' }}/>
                </div>
              </Col>
              <Col md={8} xs={7} style={{ fontSize: '20px', fontWeight: '500' }} className="pl-1">
                {activeProfileMeta.type === 'org' ? activeProfile.organization_title : activeProfile.full_name}
              </Col>
              <Col xs={2} className="d-flex justify-content-end">
                <i className="material-icons" style={{ color: 'rgb(0, 149, 246)', fontSize: '28px' }}>check_circle</i>
              </Col>
            </Row>
            <Row className="alt-account d-flex align-items-center py-3">
              <Col md={2} xs={3}>
                <div style={{ width: '60px', height: '60px' }}>
                  <img src={activeProfileMeta.type === 'org' ? availableProfiles.individual.person_display_picture : orgProfiles[0]?.organization_logo} aria-label="alt profile logo" style={{ width: '100%', height: '100%', objectFit: 'cover', border: '1px solid #c4c4c4', borderRadius: '50%' }} />
                </div>
              </Col>
              <Col md={10} xs={9} style={{ fontSize: '20px', fontWeight: '500' }} className="pl-1">
                {activeProfileMeta.type === 'org' ? availableProfiles.individual.full_name : orgProfiles[0]?.organization_title}
              </Col>
            </Row>
          </>)}
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <Button
                  onClick={() => setShow(false)}
                  block
                  className="modal-cancel-button"
                  >
                  {t("Cancel")}
                </Button>
              </Col>
              <Col className="mt-3 mt-md-0" sm={12} md={6}>
                <Button
                  className="modal-submit-button"
                  onClick={switchToprofile}
                  block
                >
                  {switchLoading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    'Switch Account'
                    )}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal show={showNew} centered size="md" onHide={() => setShowNew(false)} className={direction === "rtl" ? "arabic-display" : ""}>
        <Modal.Header style={{ fontSize: '20px', fontWeight: '500' }}>{t("Create Organization Profile")}</Modal.Header>
        <Modal.Body>
          <h4 style={{ fontSize: "1rem" }} className="py-3 mb-0">
            {t("Are you sure you want to create an organization profile?")}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <Button
                  onClick={() => setShowNew(false)}
                  block
                  className="modal-cancel-button"
                >
                  {t("Cancel")}
                </Button>
              </Col>
              <Col className="mt-3 mt-md-0" sm={12} md={6}>
                <Button
                  onClick={() => {
                    setShowNew(false);
                    history.push("/organization/add");
                  }}
                  block
                  className="modal-submit-button"
                >
                  {t("Create Profile")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    activeProfile: state.user.activeProfile,
    availableProfiles: state.user.availableProfiles,
    activeProfileMeta: state.user.activeProfileMeta,
  }),
  {
    logoutUser,
    switchProfile,
    addToast,
  }
)(ProfileDropdown);
