import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import ReadMoreAndLess from "react-read-more-less";
import AddEditInitiative from './AddEditInitiative';
import { LoaderWithText } from "../../../components/Loader";
import { getOrganizationInitiatives, deleteOrganizationInitiative } from '../../../api';
import { processDate } from '../../../common/utils';


const OurInitiatives = ({ profile, isOwner, refreshProfile, orgSlug, addToast }) => {

    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [initiatives, setInitiatives] = useState(profile.initiative);
    const [initiativesLoading, setInitiativesLoading] = useState(false);
    const [currentInitiative, setCurrentInitiative] = useState({});
    const [show, setShow] = useState(false);
    const [image, setImage] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        let unmounted = false;
        setInitiativesLoading(true);
        if (!unmounted) {
            setInitiatives(profile.initiative);
            setInitiativesLoading(false);
        }
        return () => { unmounted = true };
    }, [profile]);

    const onDelete = (initiativeId) => {
        setIsDeleting(true);
        setTimeout(() => setIsDeleting(false), 2000);
        deleteOrganizationInitiative(orgSlug, initiativeId)
            .then(() => {
                setIsDeleting(false);
                refreshProfile();
                addToast({
                    title: "Success",
                    text: `Project deleted successfully.`,
                    type: "success",
                });
            })
            .catch(() => {
                setIsDeleting(false);
                refreshProfile();
                addToast({
                    type: "error",
                    title: "Error",
                    text: "An error occurred, please try again.",
                });
            });
    };

    if (initiativesLoading) {
        return <LoaderWithText padding="150px 100px" text="Please wait..." />;
    }

    return (
        <Initiative>
            {initiatives && initiatives.length ? (
                <div>
                    <div className="initiative-header-card">
                        <span>All Initiatives</span>
                        {isOwner ?
                            <Button className="add-edit-delete-button" aria-label="Add Project" onClick={() => setIsAdding(true)}>
                                <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600' }}>add</i>
                            </Button>
                        : ""}
                    </div>
                    {initiatives.map((initiative, index) => (
                        <div className="initiative-card" key={initiative.id} style={{ marginTop: index === 0 ? '0.8rem' : '1.3rem' }}>
                           <Row className="initiative-header">
                                <img src={initiative.image} alt={initiative.initiative_title} className="initiative-image" onClick={() => { setShow(true), setImage(initiative.image); }}/>
                                {initiative.pinned ? (
                                    <div className="initiative-pinned-icon">
                                        <i className="material-icons">push_pin</i>
                                    </div>
                                ) : ""}
                            </Row>   
                            <Row className="initiative-body">
                                <Col md={11} xs={10} className="project-information px-0">
                                    <div className="project-title">
                                        {initiative.initiative_title}
                                    </div>
                                    <div className="project-date">
                                        {processDate(initiative.start_date, "PP")} - {processDate(initiative.end_date, "PP")}
                                    </div>
                                </Col>
                                {isOwner ? (
                                    <Col md={1} xs={2} className="d-flex align-items-start initiative-edit-delete px-0 justify-content-end">
                                        <Button className="add-edit-delete-button" aria-label="Edit Project" onClick={() => {
                                            setIsEditing(true);
                                            setCurrentInitiative(initiative);
                                        }}>
                                            <i className="material-icons" alt="Edit Icon">edit</i>        
                                        </Button>
                                        <Button className="add-edit-delete-button ml-2" aria-label="Delete Project" onClick={() => onDelete(initiative.id)}>
                                            <i className="material-icons" alt="Delete Icon">delete</i>
                                        </Button>
                                    </Col>
                                ) : ""} 
                            </Row>  
                            <div className="project-description" style={{ marginBottom: initiative.initiative_url ? '5px' : '24px', marginRight: '24px', marginLeft: '24px' }}>
                                <ReadMoreAndLess className="read-more-content my-story-link" charLimit={165} readMoreText="Read more" readLessText="Read less">
                                    {initiative.initiative_description}
                                </ReadMoreAndLess>
                            </div>
                            {initiative.initiative_url && (
                            <div className="project-link-button-container">
                                <Button id="project-link-button" onClick={() => { window.open(initiative.initiative_url, "_blank"); }} >
                                    <i className={`material-icons mr-2`} style={{ fontSize: "20px" }}>
                                        launch
                                    </i>
                                    View
                                </Button>
                            </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <section className="organizationCard d-flex flex-column">
                    <div className="cardHeading d-flex align-items-center justify-content-between pb-3" style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}>
                        All Initiatives{" "}
                        {isOwner ?
                            <Button className="add-edit-delete-button" aria-label="Add Project" onClick={() => setIsAdding(true)}>
                                <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600' }}>add</i>
                            </Button>
                            : ""}
                    </div>
                    <h3 className="text-red pt-3">
                        {isOwner ?
                            "You have not added any projects yet."
                            : "There are no projects available right now, visit us soon again!"
                        }
                    </h3>
                </section>
            )}

            {isOwner ? (
                isAdding ?
                    <AddEditInitiative isEditing={false} show={isAdding} closeDialog={() => setIsAdding(false)} onUpdated={refreshProfile} />
                    :
                    currentInitiative && (
                        <AddEditInitiative show={isEditing} isEditing initiativeId={currentInitiative.id} closeDialog={() => setIsEditing(false)} onUpdated={refreshProfile}
                            currentValues={{
                                pinned: currentInitiative.pinned,
                                initiative_title: currentInitiative.initiative_title,
                                initiative_description: currentInitiative.initiative_description,
                                start_date: currentInitiative.start_date,
                                end_date: currentInitiative.end_date,
                                initiative_url: currentInitiative.initiative_url,
                                image: currentInitiative.image
                            }} 
                        />
                    )
            ) : ""}

                <Modal
                    show={show}
                    size="lg"
                    onHide={() => setShow(false)}
                    dialogClassName="modelImage"
                    aria-label="Initiative Image Modal"
                >
                    <Modal.Body
                        className="modalBodyImg pt-0 pl-0 pr-0 pb-0"
                        aria-label="Initiative Image Modal"
                    >
                        <img
                            style={{ width: "100%" }}
                            src={image}
                            alt="Initiative Image"
                            title="Initiative Image"
                        />
                        <span className="closeBtn" onClick={() => setShow(false)}>
                            &#9447;
                        </span>
                    </Modal.Body>
                </Modal>
        </Initiative>
    )
}

export default connect(() => ({}), {
    addToast,
})(OurInitiatives);


const Initiative = styled.div`

.initiative-card {
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 14px;
    border: 0px;
    overflow: hidden;
}

.initiative-header-card {
    background: #ffffff;
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
    border-radius: 16px;
    overflow: hidden;
    color: #000;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 24px;
    margin-top: 11px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.initiative-header {
    height: 250px;
    border-bottom: 1px solid #EFEFEF;
    position: relative;
}

.initiative-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.initiative-pinned-icon {
    color: #000;
    position: absolute;
    right: 25px;
    top: 15px;
}

.initiative-body {
    display: flex;
    margin: 24px 24px 0 24px;
}

.add-edit-delete-button {
    color: #000;
    font-size: 1.3rem;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    &:active {
        background-color: transparent !important;
        border-color: #fff !important;
        color: #000 !important;
    }
}
.project-title {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}
.project-date {
    font-size: 0.9rem;
    font-weight: 600;
    color: #5F5F5F;
}
.project-description {
    line-height: 20px;
}
.project-link-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 24px;
    margin-top: 16px;
    border-top: 1px solid rgb(233, 233, 233);
}
 #project-link-button {
    background-color: #3F0E40;
    border-color: #3F0E40;
    border-radius: 7px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
span.short-text {
    font-size: 0.9rem;
    color: #000;
    font-weight: 400;
}
.short-text .readMoreText {
    font-size: 0.9rem;
    padding: 0 0 0 0.5rem;
    text-decoration-line: underline;
    color: black !important;
    font-weight: 700;
}
}
`