import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import Up from '../../../../assets/svg/Up-2.svg'

export function ScrollTop() {
  return (
      <div id="kt_scrolltop" className="scrolltop">
        <span className="svg-icon">
          <SVG src={Up}></SVG>
        </span>{" "}
      </div>
  );
}
