/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { set } from "object-path";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchResult({ data }) {
  // const [person,setPerson]=useState([])
  // const [orgs,setOrg]=useState([])
  var persons = [];
  var orgs = [];

  if (!data) {
    return null;
  }

  // useEffect(() => {
  //   if(data.length !== 0){
  //     const per = data.filter(x=>x.model==="profilepage.personprofile")
  //     const orgs = data.filter(x=>x.model==="organization.organizationprofile")
  //     setPerson(per)
  //     setOrg(orgs)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (data.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden", borderTop: '1px solid #eaeaea' }}
        className="quick-search-wrapper scroll ps--active-y pt-5 mt-2 pb-0"
      >
        <div className="quick-search-result">
          <div className="text-muted">No record found</div>
        </div>
      </div>
    );
  } else {
    persons = data.filter((x) => x.model === "profilepage.personprofile");
    orgs = data.filter((x) => x.model === "organization.organizationprofile");
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps--active-y my-0"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll search-result-list pt-3"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
          {/* begin: Section */}
          {orgs.length ? (
            <>
              <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                Organizations
              </div>
              <div className="mb-5">
                {orgs.map((item) => (
                  <div
                    className="d-flex align-items-center flex-grow-1 mb-2"
                    key={item.pk}
                  >
                    <div className="symbol symbol-30  flex-shrink-0">
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${`https://inclusive-storage.s3.amazonaws.com/media/public/${item.fields.organization_logo}`})`,
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <Link
                        to={`/o/${item.fields.slug}`}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        {`${item.fields.organization_title}`}
                      </Link>
                      {/* <span className="font-size-sm font-weight-bold text-muted">
                Organization
              </span> */}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          {persons.length ? (
            <>
              <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                People
              </div>
              <div className="mb-5">
                {persons.map((item) => (
                  <div
                    className="d-flex align-items-center flex-grow-1 mb-2"
                    key={item.pk}
                  >
                    <div className="symbol symbol-30  flex-shrink-0">
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${`https://inclusive-storage.s3.amazonaws.com/media/public/${item.fields.person_display_picture}`}
                    )`,
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <Link
                        to={`/p/${item.fields.slug}`}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        {`${item.fields.person_first_name} ${item.fields.person_last_name}`}
                      </Link>
                      {/* <span className="font-size-sm font-weight-bold text-muted">
                Person
              </span> */}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          {/* end: Section */}
          {/* begin: Section */}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
