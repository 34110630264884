import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "../common/Form";

//Dropdown used for CountryCity component.
const SearchDropDown = ({
  isModal,
  value,
  handlelistChange,
  list,
  handleValueChange,
  isValid,
  isCountry,
  isDisable,
}) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <React.Fragment>
      <Form.Control
        name="peron_city"
        placeholder={`${isCountry ? "Select Country" : "Select City"}`}
        type="text"
        disabled={isDisable}
        value={value}
        autoComplete="off"
        className={`ic-form-select  ${isValid ? "is-invalid" : ""}`}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        onChange={(e) => handleValueChange(e)}
      />
    </React.Fragment>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Search..."
            onChange={(e) => handleValueChange(e)}
            value={value}
            autoComplete="off"
          />
          <ul
            className={`list-unstyled ${isCountry ? "countryList" : "cityList"
              }`}
          >
            {React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children
                  .toLowerCase()
                  .startsWith(value.toLowerCase())
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        Custom toggle
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        className={`${isModal ? "CityDropDown" : ""}`}
      >
        {!isCountry
          ? list.map((city) => (
            <Dropdown.Item
              onSelect={handlelistChange}
              eventKey={city.id}
              key={city.id}
            >
              {city.city_name}
            </Dropdown.Item>
          ))
          : list.map((country) => (
            <Dropdown.Item
              onSelect={handlelistChange}
              eventKey={country.id}
              key={country.id}
            >
              {country.country_name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchDropDown;
