export default (state = 'en', action) => {
  if (action.type === 'SET_LANGUAGE') {
    return action.language;
  }

  return state;
};

export const setLanguage = (language) => ({
  type: 'SET_LANGUAGE',
  language,
});
