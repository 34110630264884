import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import ProgressBar from 'react-bootstrap/ProgressBar'


const ProfileCompletion = ({ profile, handleEditProfile, setEditStory, setShowAbility }) => {

    const [progressNumber, setProgressNumber] = useState(0);
    const [hasName, setHasName] = useState(false);
    const [hasLocation, setHasLocation] = useState(false);
    const [hasTagline, setHasTagline] = useState(false);
    const [hasStory, setHasStory] = useState(false);
    const [hasAbility, setHasAbility] = useState(false);

    const CalculateProgress = () => {
        setProgressNumber(0);
        if (profile.person_first_name !== '' && profile.person_last_name !== '') {
            setHasName(true);
            setProgressNumber(progress => progress + 20);
        }
        else {
            setHasName(false);
        }
        if (profile.person_city !== null) {
            setHasLocation(true);
            setProgressNumber(progress => progress + 20);
        }
        else {
            setHasLocation(false);
        }
        if (profile.person_summary !== '') {
            setHasTagline(true);
            setProgressNumber(progress => progress + 20);
        }
        else {
            setHasTagline(false);
        }
        if (profile.person_work_towards_project !== '') {
            setHasStory(true);
            setProgressNumber(progress => progress + 20);
        }
        else {
            setHasStory(false);
        }
        if (profile.person_skill.length !== 0) {
            setHasAbility(true);
            setProgressNumber(progress => progress + 20);
        }
        else {
            setHasAbility(false);
        }
    };

    useEffect(() => {
        CalculateProgress();
    },[profile]);

    return (
        <StyledProfileCompletion>
            <div className="profile-complete-card">
                <div className="profile-complete-header">
                    <h2 className="main-heading">Profile Completion</h2>
                </div>
                <div className="profile-complete-body">
                    <div className="profile-complete-progress-bar my-3">
                        <ProgressBar 
                            now={progressNumber} 
                            label={`${progressNumber}%`} 
                            className={progressNumber === 20 ? 'progress-1' : progressNumber === 40 ? 'progress-2' : progressNumber === 60 ? 'progress-3' : progressNumber === 80 ? 'progress-4' : progressNumber === 100 ? 'progress-5' : ''}/>
                    </div>
                    <h3 className="sub-heading">Steps to complete</h3>
                    <div className="profile-complete-item" onClick={handleEditProfile}>
                        <i className="material-icons mr-2" style={{ color: hasName ? '#00c853' : '#5F5F5F' }}>check_circle_outline</i>
                        <span className="profile-complete-item-text">Full Name</span>
                    </div>
                    <div className="profile-complete-item" onClick={handleEditProfile}>
                        <i className="material-icons mr-2" style={{ color: hasLocation ? '#00c853' : '#5F5F5F' }}>check_circle_outline</i>
                        <span className="profile-complete-item-text">Location</span>
                    </div>
                    <div className="profile-complete-item" onClick={handleEditProfile}>
                        <i className="material-icons mr-2" style={{ color: hasTagline ? '#00c853' : '#5F5F5F' }}>check_circle_outline</i>
                        <span className="profile-complete-item-text">Tagline</span>
                    </div>
                    <div className="profile-complete-item" onClick={() => setEditStory(true)}>
                        <i className="material-icons mr-2" style={{ color: hasStory ? '#00c853' : '#5F5F5F' }}>check_circle_outline</i>
                        <span className="profile-complete-item-text">Story</span>
                    </div>
                    <div className="profile-complete-item" onClick={() => setShowAbility(true)}>
                        <i className="material-icons mr-2" style={{ color: hasAbility ? '#00c853' : '#5F5F5F' }}>check_circle_outline</i>
                        <span className="profile-complete-item-text">Abilities</span>
                    </div>
                </div>
            </div>
        </StyledProfileCompletion>
    )
}

export default ProfileCompletion;


const StyledProfileCompletion = styled.div`
.profile-complete-card {
    margin-top: 1rem;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 14px;
    border: 0px;
    padding: 20px;
}
.profile-complete-header {
    // border-bottom: 1px solid #EFEFEF;
}
.main-heading {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #000;
}
.sub-heading {
    color: #5F5F5F;
    font-weight: 500;
    font-size: 1.1rem;
}
.profile-complete-item {
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    cursor: pointer;
    &:hover {
        background-color: #f8f9fa;
    }
}
.profile-complete-item-text {
    font-weight: 500;
}
.profile-complete-progress-bar .progress {
    height: 1.5rem;
    font-size: .9rem;
    border-radius: .9rem;
    font-weight: 500;
}
.profile-complete-progress-bar .progress-1 .progress-bar {
    background-color: #7b1fa2;
}
.profile-complete-progress-bar .progress-2 .progress-bar {
    background-color: #6200ea;
}
.profile-complete-progress-bar .progress-3 .progress-bar {
    background-color: #0091ea;
}
.profile-complete-progress-bar .progress-4 .progress-bar {
    background-color: #00bfa5;
}
.profile-complete-progress-bar .progress-5 .progress-bar {
    background-color: #00c853;
}
`