import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { addToast } from "../redux/toast";
import Form from "../common/Form";


const TagList = ({
  singularName,
  addToast,
  items,
  show,
  close,
  name,
  add,
  profileId,
  itemName,
  remove,
  isOwner,
  onUpdated,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [tags, setTags] = useState(items);
  // const [value, setvalue] = useState("");
  let value = "";

  const removeTags = (id, indexToRemove, nameToremove) => {
    remove(profileId, id)
      .then(() => {
        const newTag = [...tags.filter((_, index) => index !== indexToRemove)];
        setTags(newTag);
        addToast({
          title: "Success",
          text: `${nameToremove} removed.`,
          type: "success",
        });
        newTag.length ? "" : onUpdated();
      })
      .catch(() => {
        addToast({
          type: "error",
          title: "Error",
          text: "An error occurred, please try again.",
        });
      });
  };

  const addTags = (event) => {
    const tagLength = event.target.value.length;

    if (event.target.value !== "" && tagLength <= 30) {
      setIsAdding(true);
      setErrorMessage(false);

      const addNAme = event.target.value;
      add(profileId, { [itemName]: event.target.value, person: profileId })
        .then((data) => {
          onUpdated();
          setTags([...tags, data.data]);
          setIsAdding(false);

          addToast({
            title: "Success",
            text: `${addNAme} added. `,
            type: "success",
          });
        })
        .catch(() => {
          setIsAdding(false);
        });
      event.target.value = "";
      value = "";
    }
    else {
      setErrorMessage(true);
    }
  };

  const handleValueChange = (event) => {
    value = event.target.value;
  };

  const handleSubmit = () => {
    const tagLength = value.length;
    if (value !== "") {
      if (tagLength <= 30) {
      setIsSubmitting(true);
      setErrorMessage(false);

      add(profileId, { [itemName]: value, person: profileId })
        .then((data) => {
          onUpdated();
          setTags([...tags, data.data]);
          setIsSubmitting(false);
          close(false);
        })
        .catch(() => {
          setIsSubmitting(false);
          close(false);
        });
      }
      else {
        setErrorMessage(true);
        close(true);
      }
    } 
    else {
      setErrorMessage(false);
      close(false);
    }
  };

  return (
    <React.Fragment>
      <ul id="tags">
        {tags.map((tag, index) => (
          <li key={index} className="tag">
            {isOwner ? (
              <span
                className="tag-close-icon"
                onClick={() => removeTags(tag.id, index, tag[itemName])}
              >
                <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
              </span>
            ) : (
              <span className=""></span>
            )}
            <span className="tag-title">{tag[itemName]}</span>
          </li>
        ))}
      </ul>

      <Modal
        show={show}
        centered
        size="lg"
        onHide={() => close(false)}
        style={{ marginTop: "40px" }}
        className="modelTopmargin"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {"Add"} {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            {name === "Abilities" ? ( 
              <Form.Label>
              Please add your soft skills (example: Teamwork, Problem-solving, Time management) or your hard skills (example: SEO marketing, Coding ability, Proofreading).
            </Form.Label>
            ) : (
              <Form.Label htmlFor="current-company">{name}</Form.Label>              
            )}
            {isAdding ? (
              <center>
                <i
                  style={{ fontSize: "20px" }}
                  className="fas fa-spinner fa-spin"
                />
              </center>
            ) : (
              <Form.Control
                placeholder={`Type and press Enter to add new ${name}`}
                name="AddTags"
                onKeyUp={(event) =>
                  event.key === "Enter"
                    ? addTags(event)
                    : handleValueChange(event)
                }
                type="text"
                as="input"
              />
            )}
            {errorMessage &&
              ( <Form.Text className="text-muted">
                  You have exceeded the maximum character limit. (30 characters)
                </Form.Text>)
                }
          </Form.Group>

          <Form.Group>
            <hr />
            <ul id="tags">
              {tags.map((tag, index) => (
                <li key={index} className="tag">
                  <span
                    className="tag-close-icon"
                    onClick={() => removeTags(tag.id, index, tag[itemName])}
                  >
                    <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
                  </span>
                  <span className="tag-title">{tag[itemName]}</span>
                </li>
              ))}
            </ul>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <Button
                  onClick={() => {close(false); setErrorMessage(false);}}
                  block
                  className="modal-cancel-button"
                >
                  Cancel
                </Button>
              </Col>
              <Col className="mt-3 mt-md-0" sm={12} md={6}>
                <Button
                  onClick={handleSubmit}
                  loading={isSubmitting ? 1 : 0}
                  block
                  className="modal-submit-button"
                  type="submit"
                >
                  {isSubmitting ? "Please wait..." : "Submit"}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default connect(() => ({}), {
  addToast,
})(TagList);
