import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { addToast } from "../../../redux/toast";
import { format } from "date-fns";
import { NavLink as Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { processDate, processDateArabic, getTimeAgo } from "../../../common/utils";
import JobApplyModal from './JobApplyModal';
import { ApplyJob } from "../../../api";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const jobCard = ({
  addToast,
  activeProfile,
  job,
  firstJobId,
  onApply,
  onWithdraw,
  isApplied,
  activeProfileMeta,
}) => {
  const direction = i18n.dir();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const completeProfile = useSelector(state => state.user.completeProfile);

  let applyButton = null;

  //Remove apply button if job close date has passed or if active profile is Organization Profile.
  //Else link the Apply button to job apply modal.
  if (new Date() > new Date(job.job_close_time) || activeProfileMeta && activeProfileMeta.type === "org") {
    applyButton = "";
  } 
  else {
    applyButton = (
      <Button id="apply-job" onClick={() => { completeProfile ? setShow(true) : addToast({ type: "error", title: "Error", text: "Please complete your profile to apply for jobs."}) }} disabled={isApplied ? true : false}>
        <i className={`material-icons ${direction === "rtl" ? "ml-2" : "mr-2"}`} style={{ fontSize: "20px" }}>
          launch
        </i>
        {isApplied ? t("Applied") : t("Apply")}
      </Button>
    );
  } 

  return (
    <>
    {activeProfileMeta.type === "individual" &&
      <JobApplyModal show={show} setShow={setShow} job={job} profileId={activeProfileMeta.id} profileType={activeProfileMeta.type} profile={activeProfile} onApply={onApply} />
    }
    <StyledJobCard
      style={{ marginTop: `${firstJobId === job.id ? "0" : "30px"}` }}
    >
      <div className="job-card d-flex flex-column" aria-label="Job card">
        <Link
          to={`/opportunity/${job.slug}`}
          style={{ textDecoration: "none", color: "#000000" }}
        >
          <Row className="d-flex job-content align-items-center show-on-regular">
            <Col md={2} className="job-logo d-flex justify-content-center px-0">
              <div className="job-logo-container">
                <img
                  src={
                    job.job_organization_logo &&
                    job.job_organization_logo !==
                      "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
                      ? job.job_organization_logo
                      : job.organization
                      ? job.organization.organization_logo
                      : job.listed_by.organization_logo
                  }
                  className="job-img"
                  alt="job-img"
                />
              </div>
            </Col>
            <Col md={8} className="job-title">
              <div className="job-title-a">{job.job_title}</div>
              <div className="job-organization">
                {job.job_organization
                  ? job.job_organization
                  : job.listed_by.organization_title}
              </div>
              <div className="job-location">
                {job.job_location_cities.length > 0 && job.job_location_cities[0].job_city.city_name}
                {job.job_location_cities.length > 1 && ` • ${job.job_location_cities[1].job_city.city_name}`}
                {job.job_location_cities.length > 2 && ` • ${job.job_location_cities[2].job_city.city_name}`}
              </div>
            </Col>
            <Col md={2} className="d-flex justify-content-end">
              <div className="job-location">
                {getTimeAgo(job.created_at).includes('month') ? 
                  '30d+' : (
                getTimeAgo(job.created_at)
                  .replace("about ", "")
                  .replace(" hour", "h")
                  .replace("s", "")
                  .replace(" ago", "")
                  .replace(" day", "d")
                  .replace(" minute", "m")
                  .replace("les than am", "just now")
                )}
              </div>
            </Col>
          </Row>
          <Row className="job-content align-items-center show-on-mobile">
            <Col xs={4} className="job-logo d-flex justify-content-center">
              <div className="job-logo-container">
                <img
                    src={
                      job.job_organization_logo &&
                        job.job_organization_logo !==
                        "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
                        ? job.job_organization_logo
                        : job.organization
                          ? job.organization.organization_logo
                          : job.listed_by.organization_logo
                    }
                  className="job-img"
                  alt="job-img"
                />
              </div>
            </Col>
            <Col xs={8} className={`job-title ${direction === "rtl" ? "title-rtl" : "title-ltr"}`}>
              <div className="job-title-a">{job.job_title}</div>
              <div className="job-organization">
                {job.job_organization
                  ? job.job_organization
                  : job.listed_by.organization_title}
              </div>
              <div className="job-location">
                {job.job_location_cities.length > 0 && job.job_location_cities[0].job_city.city_name}
                {job.job_location_cities.length > 1 && ` • ${job.job_location_cities[1].job_city.city_name}`}
              </div>
            </Col>
          </Row>
        </Link>
        <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

        <Row className="d-flex job-footer align-items-center">
          <Col xs={7} className={`job-close-date ${direction === "rtl" ? "date-rtl" : "date-ltr"}`}>
           <span className="info-button mr-3">
              {job.job_type === "F" && t("Full-time")}
              {job.job_type === "P" && t("Part-time")}
              {job.job_type === "C" && t("Contract")}
              {job.job_type === "T" && t("Temporary")}
              {job.job_type === "O" && t("Other")}
              {job.job_type === "V" && t("Volunteer")}
           </span>
           <span className="info-button">
              {job.job_location === "R" ? t("Remote") : ""}
              {job.job_location === "H" ? t("Hybrid") : ""}
              {job.job_location === "C" ? t("Co-working Space") : ""}
              {job.job_location === "O" ? t("Office") : ""}
           </span>
            <div className="job-location show-on-mobile">
              {getTimeAgo(job.created_at).includes('month') ?
                '30d+' : (
                  getTimeAgo(job.created_at)
                    .replace("about ", "")
                    .replace(" hour", "h")
                    .replace("s", "")
                    .replace(" ago", "")
                    .replace(" day", "d")
                    .replace(" minute", "m")
                    .replace("les than am", "just now")
                )}
            </div>
          </Col>
          <Col xs={5} className={`job-apply-button ${direction === "rtl" ? "apply-rtl" : "apply-ltr"}`}>
            {applyButton}
          </Col>
        </Row>
      </div>
    </StyledJobCard>
  </>
  );
};

export default connect((state) => ({
  activeProfile: state.user.activeProfile,
  activeProfileMeta: state.user.activeProfileMeta,
}), { addToast })(jobCard);

const StyledJobCard = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;

  .job-header {
    padding: 20px 24px;
    color: #ffffff;
    background-color: #000000;
    font-size: 1.1rem;
    opacity: 0.9;
  }

  .job-content {
    padding: 1.8rem 2rem;
  }

  .job-footer {
    padding: 20px 24px;
    font-size: 1.1rem;
  }

  .job-close-date {
    color: #000000;
  }

  .job-title-a {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000000;
    @media(max-width: 560px) {
      font-size: 1.2rem;
    }
  }

  .job-organization {
    font-size: 1.1rem;
    font-weight: 500;
    color: #000;
     @media(max-width: 560px) {
      font-size: 1rem;
    }
  }

  .job-location {
    font-size: 1rem;
    font-weight: 500;
    color: #5F5F5F;
  }

  .job-badge-icon {
    display: flex;
    justify-content: flex-end;
  }

  .job-logo-container {
    width: 84px;
    height: 84px;
  }

  .job-logo-container img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    object-fit: contain;
  }

  .job-apply-button {
    display: flex;
    justify-content: flex-end;
  }

  #apply-job {
    background-color: #3F0E40;
    border-color: #3F0E40;
    border-radius: 7px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .info-button {
    font-size: 1rem;
    color: #3F0E40;
    background-color: #ededed;
    border-radius: 7px;
    font-weight: 500;
    display: inline-block;
    padding: .438rem .75rem;
     @media(max-width: 560px) {
      display: none;
    }
  }

  .btn.disabled, .btn:disabled {
    cursor: default;
  }

  .show-on-mobile {
    display: none;
  }

  @media (max-width: 440px) {
    .show-on-regular {
      display: none !important;
    }
    .show-on-mobile {
      display: flex !important;
    }
    .job-content {
      padding: 1.5rem;
    }
    .job-location {
      justify-content: flex-start;
    }
    .job-title.title-ltr {
      padding-left: 0;
    }
    .job-title.title-rtl {
      padding-right: 0;
    }
    .job-apply-button.apply-ltr {
      //justify-content: flex-start;
      //padding-top: 1rem;
      padding-left: 0;
    }
    .job-apply-button.apply-rtl {
      padding-right: 0;
    }
    .job-close-date.date-ltr {
      padding-right: 0;
    }
    .job-close-date.date-rtl {
      padding-left: 0;
    }
  }
`;
