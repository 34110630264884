import { cities } from '../../api';

import { addToast } from '../toast';

const initialState = {
  cities: [],
  citiesLoading: false,
};

export default (state = initialState, action) => {
  if (action.type === 'SET_CITIES') {
    return {
      ...state,
      cities: action.cities,
    };
  }

  if (action.type === 'SET_CITIES_LOADING') {
    return {
      ...state,
      citiesLoading: action.loading,
    };
  }

  return state;
};

export const setCities = (cityList) => ({
  type: 'SET_CITIES',
  cities: cityList,
});

export const setCitiesLoading = (loading) => ({
  type: 'SET_CITIES_LOADING',
  loading,
});

export const getCities = () => (dispatch) => {
  dispatch(setCitiesLoading(true));

  cities()
    .then(({ data }) => {
      dispatch(setCitiesLoading(false));
      dispatch(setCities(data));
    })
    .catch(() => {
      dispatch(setCitiesLoading(false));

      addToast({
        title: 'Error',
        text: 'An error occurred. Please try again later.',
        type: 'error',
      });
    });
};
