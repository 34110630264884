import { hot } from "react-hot-loader/root";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { Route, Switch, Redirect } from "react-router-dom";
import TagManager from "react-gtm-module";
import { setLanguage } from "./redux/language";
import { getNotifications } from "./redux/notifications";
import {
  setAuthenticated,
  getAvailableProfiles,
  setActiveProfile,
  setActiveProfileMeta,
} from "./redux/user";

import Favicon from "react-favicon";
import InclusiveIcon from './assets/images/ic_launcher.png';

import NavigationBar from "./components/navbar/NavigationBar";
import CompleteProfileBar from './components/CompleteProfileBar';
import Footer from "./components/Footer";
import Toaster from "./components/Toaster";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import DashBoardRoutes from "./components/DashBoardRoutes";

import ScrollToTopButton from "./components/ScrollToTopButton";
import Profile from "./pages/Profile";
import SearchUser from "./pages/SearchUser";
import Jobs from "./pages/Jobs";
import JobDetail from "./pages/Jobs/components/JobDetail";
import Home from "./pages/Home";
import Post from "./components/Post";

import FAQs from "./pages/Policy/faqs";
import Privacy from "./pages/Policy/privacy";
import Terms from "./pages/Policy/terms";
import AccountPage from "./pages/Account";

import OrganizationJobDetail from "./pages/OrganizationProfile/components/OrganizationJobDetail";
import ManageJob from './pages/OrganizationProfile/components/ManageJob';

import AddOrganization from "./pages/OrganizationProfile/components/AddOrganization";
import AddJob from "./pages/OrganizationProfile/components/AddJob";
import AllJobs from "./pages/OrganizationProfile/components/AllJobs";
import OtherUserProfile from "./pages/PersonProfile/OtherUserProfile";
import OtherOrgProfile from "./pages/OrganizationProfile/OtherOrgProfile";

import Dashboard from "./pages/Dashboard";
import OrgJobs from "./pages/Dashboard/Jobs";

import Courses from "./pages/learn";
import LessonDetail from "./pages/learn/components/lessonDetail";
import CourseDetail from "./pages/learn/components/CourseDetail";

import Auth from "./pages/Auth/Auth";
import QuizForm from "./pages/learn/components/QuizForm";
import QuizResult from "./pages/learn/components/QuizResult";

const tagManagerArgs = {
  gtmId: "GTM-5VWB6R5",
};

TagManager.initialize(tagManagerArgs);

const App = ({
  authenticated,
  language,
  setAuthenticated,
  setLanguage,
  getAvailableProfiles,
  setActiveProfile,
  setActiveProfileMeta,
  completeProfile,
  getNotifications,
}) => {
  // const { i18n } = useTranslation();
  const [initialLoad, setInitialLoad] = useState(true);
  const { pathname } = window.location;

  useEffect(() => {
    const token = localStorage.getItem("Token");

    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }

    setInitialLoad(false);

  }, []);

  useEffect(() => {
    if (authenticated) {
      const activeProfileMeta = JSON.parse(
        localStorage.getItem("activeProfileMeta")
      );
      if (activeProfileMeta) {
        setActiveProfileMeta({
          type: activeProfileMeta.type,
          id: activeProfileMeta.id,
        });
      }
      getAvailableProfiles();
    } 
  }, [authenticated]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (authenticated) {
        getNotifications();
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  //Checking for the language detected by i18n and storing it in redux state & local storage
  useEffect(() => {
    const detectedLanguage = i18n.language;
    if (detectedLanguage) {
      setLanguage(detectedLanguage);
      localStorage.setItem("language", detectedLanguage);
    } 
  }, []);

  //Passing this function to change language where needed, updates language stored in i18n, redux & local storage.
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    localStorage.setItem("language", lang);
  }
  // useEffect(() => {
  //   i18n.changeLanguage(language).then(() => {
  //     setLanguage(language);
  //     localStorage.setItem("language", language);
  //   });
  // }, [language]);

  // const changeLanguage = () => {
  //   if (language === "en") {
  //     i18n.changeLanguage("ar").then(() => {
  //       setLanguage("ar");
  //       localStorage.setItem("language", "ar");
  //     });
  //   } else {
  //     i18n.changeLanguage("en").then(() => {
  //       setLanguage("en");
  //       localStorage.setItem("language", "en");
  //     });
  //   }
  // };

  if (initialLoad) {
    return null;
  }

  if (!pathname.includes("onboarding") && !pathname.includes("auth")) {
    document.body.classList.add("ic-app-main");
  } else {
    document.body.classList.remove("ic-app-main");
  }

  return (
    <>
      <Favicon url={InclusiveIcon}/>

      <Toaster key="toaster" />

      {!pathname.includes("auth") ? (
        !pathname.includes("/org/") ? (
          <React.Fragment>
            <NavigationBar/>
            {!completeProfile && (
              <CompleteProfileBar/>
            )}
            <ScrollToTopButton />
          </React.Fragment>
        ) : null
      ) : null}

      <Switch>
        {authenticated && pathname.includes("auth") ? (
          <Redirect to="/impactboard" />
        ) : null}

        {pathname === "/" ? <Redirect to="/impactboard" /> : null}
        
        <AuthenticatedRoute path="/profile" component={props => <Profile {...props}/>} />
        
        <AuthenticatedRoute path="/account" component={props => <AccountPage {...props} currentLanguage={language} onLanguageChange={changeLanguage}/>} />
        
        <AuthenticatedRoute path="/opportunity/:jobslug" component={props => <JobDetail {...props}/>} />

        <AuthenticatedRoute path="/learn/lesson/:lessonId/quiz" component={props => <QuizForm {...props}/>} />
        <AuthenticatedRoute path="/learn/lesson/:lessonId/result" component={props => <QuizResult {...props}/>} />
        <AuthenticatedRoute path="/learn/:courseId/lesson/:lessonId" component={props => <LessonDetail {...props}/>} />
        <AuthenticatedRoute path="/learn/:courseId" component={props => <CourseDetail {...props}/>} />
        <AuthenticatedRoute path="/learn" component={props => <Courses {...props}/>} />

        <AuthenticatedRoute
          path="/organization/job/:jobslug/:jobId"
          component={OrganizationJobDetail}
        />
        <AuthenticatedRoute
          path="/organization/add"
          component={props => <AddOrganization {...props}/>}
        />
        <AuthenticatedRoute path="/addorganizationjob" component={props => <AddJob {...props}/>} />
 
        <AuthenticatedRoute path="/allorganizationJobs" component={AllJobs} />
        <AuthenticatedRoute path="/p/:slug" component={OtherUserProfile} />
        <AuthenticatedRoute path="/o/:slug" component={OtherOrgProfile} />

        <AuthenticatedRoute path="/opportunities" component={Jobs} />

        <DashBoardRoutes path="/org/dashboard" component={Dashboard} />

        <DashBoardRoutes path="/org/opportunities" component={OrgJobs} />

        <DashBoardRoutes path="/org/addjob" component={props => <AddJob {...props}/>} />

        <DashBoardRoutes
          path="/org/jobdetail/:jobslug/:jobId"
          component={ManageJob}
        />

        <DashBoardRoutes path="/org/account" component={AccountPage} />

        <AuthenticatedRoute
          path="/search/people/:query"
          component={SearchUser}
        />
        <AuthenticatedRoute
          path="/search/organization/:query"
          component={SearchUser}
        />
        <AuthenticatedRoute path="/search/:query" component={SearchUser} />
        <AuthenticatedRoute path="/activity/:postId" exact component={props => <Post {...props}/>} />

        <Route path="/auth" component={Auth} />
        <Route path="/termsandconditions" component={Terms} />
        <Route path="/privacypolicy" component={Privacy} />
        <Route path="/faqs" component={FAQs} />
        <AuthenticatedRoute path="/impactboard" component={props => <Home {...props} currentLanguage={language} onLanguageChange={changeLanguage}/>} />
      </Switch>
      {(!pathname.includes("onboarding") && !pathname.includes("auth") && !pathname.includes("/org/")) ? (
        <Footer currentLanguage={language} onLanguageChange={changeLanguage} />
      ) : null}
    </>
  );
};

export default connect(
  (state) => ({
    authenticated: state.user.authenticated,
    completeProfile: state.user.completeProfile,
    language: state.language,
  }),
  {
    setActiveProfile,
    setAuthenticated,
    setLanguage,
    getAvailableProfiles,
    getNotifications,
    setActiveProfileMeta,
  }
)(hot(App));
