import React, { useState, useRef } from "react";
import { format } from "date-fns";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import AddEditExperience from "./AddEditExperience";
import { deleteExperience } from "../../../api";

const WorkExpericence = ({
  experience,
  isOwner,
  refreshProfile,
  profileId,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  return (
    <div className="user-ability user-data-wrapper">
      <div className="user-story-header justify-content-between">
        <div>
          <h2 style={{ display: 'flex' }}>Work Experience
            {isOwner && (
              <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Work Experience Tooltip" aria-pressed={showTooltip}>
                <i className="material-icons help-icon">help_outline</i>
              </Button>
            )}
          </h2>
          <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
            {(props) => (
              <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                What is your history of work experience? Volunteering, traineeship, internship, freelance or full-time work that you can share? If you’re not a person with a disability, sharing your voluntary or work experience relevant to inclusion and diversity, is really helpful for others to connect with you!
              </Tooltip>
            )}
          </Overlay>
        </div>
        {isOwner ? (
          <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600', cursor: 'pointer' }} onClick={() => setIsAdding(true)}>add</i>
        ) : (
          ""
        )}
      </div>
      <hr />
      <div>
        {experience && experience.length ? (
          experience.map((item, index) => (
            <WorkExperienceItem
              profileId={profileId}
              explength={experience.length}
              key={item.id}
              item={item}
              index={index}
              isOwner={isOwner}
              refreshProfile={refreshProfile}
            />
          ))
        ) : (
          <h3 className="pb-2 text-red pt-2">
            {isOwner
              ? "You have not added any work experience yet."
              : "This user has not added any work experience yet."}
          </h3>
        )}
      </div>
      {isOwner ? (
        <AddEditExperience
          isEditing={false}
          show={isAdding}
          closeDialog={() => setIsAdding(false)}
          onUpdated={refreshProfile}
        />
      ) : null}
    </div>
  );
};

const WorkExperienceItem = ({
  item,
  index,
  isOwner,
  refreshProfile,
  explength,
  profileId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = () => {
    setIsDeleting(true);

    deleteExperience(profileId, item.id)
      .then(() => {
        setIsDeleting(false);

        refreshProfile();
      })
      .catch(() => {
        setIsDeleting(false);

        refreshProfile();
      });
  };

  return (
    <div
      className="experience-wrapper"
      style={{ borderBottom: `${explength - 1 === index ? "none" : ""}`, paddingTop: index === 0 ? 0 : '1rem', paddingBottom: index + 1 === explength ? 0 : '1rem' }}
    >
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="companywrapper">
          {/* <img src="https://i.ibb.co/vjjjs3g/Ellipse-9.jpg" /> */}
          <div className="companyDetails pl-0">
            <div className="position">{item.experience_title}</div>
            <div className="company-name">{item.experience_org}</div>
            <div className="period">
              {" "}
              {item.experience_end_present ? (
                <span>
                  {format(new Date(item.experience_start), "MMM yyyy")} -
                  Present
                </span>
              ) : (
                <span>
                  {format(new Date(item.experience_start), "MMM yyyy")} -{" "}
                  {format(new Date(item.experience_end), "MMM yyyy")}
                </span>
              )}
            </div>
            <div className="company-description">{item.experience_summary}</div>
          </div>
        </div>
        <div>
          {isOwner ? (
            <div className="d-flex align-items-center">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsEditing(true)}
            >
              <i className="material-icons" alt="Edit Icon">edit</i>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="pl-2"
                onClick={() => onDelete(item.id)}
              >
                {isDeleting ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  <i className="material-icons" alt="Delete Icon">delete</i>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {isOwner ? (
        <AddEditExperience
          show={isEditing}
          isEditing
          experienceId={item.id}
          closeDialog={() => setIsEditing(false)}
          currentValues={{
            experience_title: item.experience_title,
            experience_org: item.experience_org,
            experience_summary: item.experience_summary,
            experience_start: item.experience_start,
            experience_end: item.experience_end,
            experience_end_present: item.experience_end_present,
          }}
          onUpdated={refreshProfile}
        />
      ) : null}
    </div>
  );
};

export default WorkExpericence;
