import React, { useEffect } from "react";
import RichLinkPreview from "./../RichLinkPreview";


//Created a separate component for LinkPreview and wrapped setState inside useEffect to avoid:
//"Warning: Cannot update a component from inside the function body of a different component."
const LinkPreview = ({ value, setHasLink, setIsReq}) => {

    const urlMatches = value.match(/\b(http|https)?:\/\/\S+/gi) || [];
   
    useEffect(() => {
        urlMatches.length ? setHasLink(true) : setHasLink(false);
      }, [urlMatches]);

    return urlMatches.length ? (
      <RichLinkPreview
        setIsReq={setIsReq}
        isCreator
        key={urlMatches[0]}
        link={urlMatches[0]}
      />
    ) : (
      ""
    );
  };

  export default LinkPreview;