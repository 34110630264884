import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhotoUploadIcon from "../../assets/images/PhotoUploadIcon.png";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "../../common/Form";
//import Button from '../../../components/Button';
import { Colors } from "../../styles/constants";
import CropImage from "./CropImage";
import { addToast } from "../../redux/toast";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

//Upload Image IB
const UploadImage = ({
  margin,
  disabled,
  photoChanged,
  loading,
  isIcon,
  handleText,
  altText,
  imageUrlList,
}) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editor, SetEditor] = useState(null);
  const [scaleValue, SetScaleValue] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState(null);
  const { t } = useTranslation();
  const direction = i18n.dir();

  //Getting the actual total number of photos (compressed before and added now) in case user uploads photos in steps.
  const photoNumber = imageUrlList.length;

  const handleChange = (e) => {
    const files = e.target.files;

    //Assigned value of files.length if no photo's previously added.
    //Assigned value of number of files added now plus number of files previously added.
    const fileLength =
      photoNumber === 0 ? files.length : files.length + photoNumber;

    if (fileLength > 6) {
      // for (let i = 0; i < 6; i++) {
      //   var filePath = files[i].name;

      //   // Allowing file type
      //   var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

      //   if (!allowedExtensions.exec(filePath)) {
      //     dispatch(
      //       addToast({
      //         title: "Error",
      //         text: `Invalid Photo type.`,
      //         type: "error",
      //       })
      //     );

      //     return false;
      //   }
      //   photoChanged(files[i]);
      // }
      dispatch(
        addToast({
          title: "Error",
          text: `Maximum 6 Photos allowed.`,
          type: "error",
        })
      );
    } else
      for (let i = 0; i < files.length; i++) {
        var filePath = files[i].name;
        // Allowing file type
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

        if (!allowedExtensions.exec(filePath)) {
          dispatch(
            addToast({
              title: "error",
              text: `Invalid Photo type.`,
              type: "error",
            })
          );

          return false;
        }
        photoChanged(files[i]);
      }

    //setImage(e.target.files[0]);
    // const file = e.target.files[0];
    // if (file) {

    //   const reader = new FileReader();

    //   reader.onload = (e) => {
    //     setSrc(e.target.result);
    //   };

    //   reader.readAsDataURL(file);

    // }
  };

  useEffect(() => {
    if (image) {
      photoChanged(image);
    }
  }, [image]);

  const setEditorRef = (e) => SetEditor(e);

  const onScaleChange = (scaleChangeEvent) => {
    const newScaleValue = parseFloat(scaleChangeEvent.target.value);
    SetScaleValue(newScaleValue);
  };

  const rotateleft = () => {
    let newRotation = rotate - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotate(newRotation);
  };

  const rotateRight = () => {
    let newRotation = rotate + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotate(newRotation);
  };

  const onCrop = () => {
    const i = imageFile;
    if (editor !== null) {
      const url = editor.getImage().toDataURL();
      const newImage = dataURLtoFile(url, i.name);
      setShow(false);
      photoChanged(newImage);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onClose = () => {
    setShow(false);
  };


  if (isIcon) {
    return (
      <React.Fragment>
        <ButtonWrapper
          className="click_hand"
          disabled={disabled}
          margin={margin}
        >
          <Button
            className="upload-image-button"
            aria-label="Upload image"
            tabIndex="-1"
          >
            <img
              src={PhotoUploadIcon}
              alt="Photo Upload Icon"
              className={direction === "rtl" ? "ml-2" : "mr-2"}
            />
            {t("Photo")}
            {/* <FontAwesomeIcon size="3x" icon="camera" /> */}
            <input
              className="inputBtn"
              aria-label="Upload image"
              role="button"
              multiple
              accept="image/png, image/jpeg"
              onChange={handleChange}
              type="file"
              name="myfile"
            />
          </Button>
        </ButtonWrapper>
        <Modal
          show={show}
          centered
          size="md"
        //onHide={onClose}
        >
          <Modal.Header>Upload Image</Modal.Header>
          <Modal.Body>
            {imageFile ? (
              <React.Fragment>
                {src && <img src={src} height="300px" width="300" />}
                {/* 
                <CropImage
                  imageSrc={imageFile}
                  setEditorRef={setEditorRef}
                  rotation={rotate}
                  leftRotate={rotateleft}
                  rightRotate={rotateRight}
                  scaleValue={scaleValue}
                  onScaleChange={onScaleChange}
                /> */}

                <Row className="mt-3">
                  <Col sm={12} md={12}>
                    <Form.Control
                      className="italic-placeholder"
                      name="altText"
                      placeholder="Alternative text"
                      type="text"
                      onChange={handleText}
                      value={altText}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row>
                <Col sm={12} md={6}>
                  <Button
                    onClick={onClose}
                    block
                    size="lg"
                    variant="outline-danger"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="mt-3 mt-md-0" sm={12} md={6}>
                  <Button onClick={onCrop} block size="lg" variant="danger">
                    Upload Photo
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

  return (
    <ButtonWrapper className="click_hand" disabled={disabled} margin={margin}>
      <ButtonExtended fullWidth loading={loading ? 1 : 0} disabled={disabled}>
        Upload Photo
      </ButtonExtended>
      <input
        accept="image/png, image/jpeg"
        onChange={handleChange}
        type="file"
        name="myfile"
      />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  > svg {
    color: ${Colors.font.secondary};
  }
  margin: ${(props) => props.margin};
  > input {
    display: none;
  }
  ${(props) =>
    !props.disabled &&
    css`
      > input {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    `};
`;

const ButtonExtended = styled(Button)`
  width: 100%;
`;

export default UploadImage;
