import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Jobs from "./Jobs";
import OurJobs from "./OurJobs";
import OurStory from "./OurStory";
import OurConversations from "./OurConversations";
import Followers from "./Followers";
import Followings from "./Followings";
import Initiatives from './Initiatives';
import OurInitiatives from "./OurInitiatives";
import ManageJob from './ManageJob';


const RightSideBar = ({
  profile,
  isOwner,
  onProfileUpdated,
  activeProfileId,
  TogglePublicView,
  publicView,
  onEditProfile
}) => {
  const { path } = useRouteMatch();

  return (
    <section className="dashboard-right-sidebar">
      <aside>
        <div className="right-sidebar">
          <div className="right-sidebar--wrapper">

            <Switch>
              <Route path={`${path}/story`}>
                <OurStory
                  TogglePublicView={TogglePublicView}
                  publicView={publicView}
                  isOwner={isOwner}
                  profile={profile}
                  activeProfileId={activeProfileId}
                  onProfileUpdated={onProfileUpdated}
                  onEditProfile={onEditProfile}
                />
                {profile && (
                  <Jobs
                    profile={profile}
                    isOwner={isOwner}
                    refreshProfile={onProfileUpdated}
                  />
                )}
                {profile && (
                  <Initiatives profile={profile} isOwner={isOwner} orgSlug={profile.slug} refreshProfile={onProfileUpdated} />
                )}
              </Route>

              <Route path={`${path}/conversations`}>
                <OurConversations profile={profile} isOwner={isOwner} />
              </Route>

              <Route path={`${path}/initiatives`}>
                <OurInitiatives profile={profile} isOwner={isOwner} orgSlug={profile.slug} refreshProfile={onProfileUpdated}/>
              </Route>

              <Route path={`${path}/opportunities`}>
                <OurJobs
                  profile={profile}
                  isOwner={isOwner}
                  refreshProfile={onProfileUpdated}
                />
              </Route>

              <Route path={`${path}/followers`}>
                <Followers profile={profile} isOwner={isOwner} />
              </Route>

              <Route path={`${path}/following`}>
                <Followings profile={profile} isOwner={isOwner} />
              </Route>

              <Route path={`${path}/job/:jobslug/:jobId`}>
                <ManageJob/>
              </Route>

              <Redirect to={`${path}/story`} isOwner={isOwner} />
            </Switch>

          </div>
        </div>
      </aside>
    </section>
  );
};

export default RightSideBar;
