/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const history = useHistory();
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <LinkItem
          tabIndex="0"
          to="/org/dashboard"
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/org/dashboard"
          )}`}
        >
          <span tabIndex="-1" className="menu-link" to="/org/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </span>
        </LinkItem>
        {/*end::1 Level*/}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
        <LinkItem
          onClick={() => history.push("/impactboard")}
          tabIndex="0"
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          to="/impactboard"
          className={`menu-item  menu-item-rel ${getMenuItemActive(
            "/impactboard"
          )}`}
        >
          <span className="menu-link menu-toggle">
            <span className="menu-text">Home</span>
            <i className="menu-arrow"></i>
          </span>
        </LinkItem>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <LinkItem
          onClick={() => history.push("/opportunities")}
          to="/opportunities"
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item  menu-item-rel ${getMenuItemActive("/opportunities")}`}
        >
          <span className="menu-link menu-toggle">
            <span className="menu-text">Opportunities</span>
            <i className="menu-arrow"></i>
          </span>
        </LinkItem>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}

const LinkItem = styled(NavLink)`
  // padding: 0.65rem 1.1rem;
  //     color: #6c7293;
  //     font-weight: 500;
  &:focus {
    border: 0.1rem solid black;
  }
`;
