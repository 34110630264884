import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from '../common/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { reportContent } from '../api';
import { addToast } from '../redux/toast';
import { useDispatch } from "react-redux";


const ReportContent = ({ modalVisible, setModalVisible, contentType, contentId }) => {

    const [successMessage, setSuccessMessage] = useState(false);
    const [alreadyReported, setAlreadyReported] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!modalVisible) {
            setSuccessMessage(false);
            setAlreadyReported(false);
        }
    }, [modalVisible]);

    const handleReportContent = (values) => {
        reportContent({
            content_id: contentId,
            content_type: contentType,
            issue_type: values.issue_type,
            body: values.issue_info,
        }).then(() => {
            setSuccessMessage(true);
        })
        .catch((error) => {
            setSuccessMessage(false);
            if (Object.values(error)[2].data[0] === 'Already Exist') {
                setAlreadyReported(true);
            }
            else {
                dispatch(
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    })
                )
            }
        });
    };

    const validationSchema = yup.object().shape({
        issue_type: yup.string().required('Issue type is required.'),
        issue_info: yup.string().max(250),
    });

    const initialValues = {
        issue_type: '',
        issue_info: '',
    };

    return (
        <Modal centered size="lg" show={modalVisible} onHide={() => setModalVisible(false)}>
            <Modal.Header closeButton>
                <Modal.Title style={{ lineHeight: 1.2 }}>Report {contentType === "P" ? "Post" : contentType === "C" ? "Comment" : contentType === "U" ? "Person" : contentType === "O" ? "Organization" : ""}</Modal.Title>
            </Modal.Header>
            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleReportContent}>
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                isSubmitting,
                handleSubmit,
                touched,
            }) => (
                <>
                <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    {successMessage ? (
                        <div className="success-container">
                            <div className="success-icon py-2 d-flex align-items-center justify-content-center">
                                <i className="material-icons" style={{ fontSize: 60, color: '#00C853' }}>check_circle_outline</i>
                            </div>
                            <h2 className="success-heading py-2" style={{ textAlign: 'center' }}>Thanks for letting us know</h2>
                            <h3 className="success-text pb-2" style={{ textAlign: 'center' }}>Your feedback is important in helping us keep the ImInclusive space safe for everyone.</h3>
                        </div>
                    ) : alreadyReported ? (
                        <div className="success-container">
                            <div className="success-icon py-2 d-flex align-items-center justify-content-center">
                                <i className="material-icons" style={{ fontSize: 60, color: '#515151' }}>report_problem</i>
                            </div>
                            <h2 className="success-heading py-2" style={{ textAlign: 'center' }}>This {contentType === "P" ? "post" : contentType === "C" ? "comment" : contentType === "U" ? "person" : contentType === "O" ? "organization" : ""} has already been reported</h2>
                            <h3 className="success-text pb-2" style={{ textAlign: 'center' }}>Please be patient with us, while we work on resolving the issue.</h3>
                        </div>
                    ) : (
                        <div className="report-container">
                            <h3 className="report-heading py-2" style={{ fontSize: '1.25rem' }}>Why are you reporting this {contentType === "P" ? "post" : contentType === "C" ? "comment" : contentType === "U" ? "person" : contentType === "O" ? "organization" : ""}?</h3>
                            <Row>
                                <Form.Group as={Col} col={12} md={12}>
                                    <Form.Label>Issue Type</Form.Label>
                                    <Form.Control
                                        value={values.issue_type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="issue_type"
                                        as="select"
                                        className="ic-form-select"
                                        isInvalid={touched.issue_type && errors.issue_type}
                                    >
                                        <option value="" disabled>Select an issue</option>
                                        <option value="It's spam">It's spam</option>
                                        <option value="Nudity or sexual activity">Nudity or sexual activity</option>
                                        <option value="Hate speech or symbols">Hate speech or symbols</option>
                                        <option value="Violence or dangerous organizations">Violence or dangerous organizations</option>
                                        <option value="Bullying or harassment">Bullying or harassment</option>
                                        <option value="False information">False information</option>
                                        <option value="Scam or fraud">Scam or fraud</option>
                                        <option value="Suicide or self-injury">Suicide or self-injury</option>
                                        <option value="Sale of illegal or regulated goods">Sale of illegal or regulated goods</option>
                                        <option value="Something else">Something else</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.issue_type}
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} col={12} className="mb-0">
                                        <Form.Label>Issue Detail</Form.Label>
                                        <Form.Control
                                            placeholder="Tell us more about this issue to help us understand your concern."
                                            name="issue_info"
                                            className="textareaHeight"
                                            value={values.issue_info}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            as="textarea"
                                            isInvalid={touched.issue_info && errors.issue_info}
                                        />
                                        <span className="pt-2" style={{ color: '#515151' }}>(Character Limit: 250)</span>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.issue_info}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {(successMessage || alreadyReported) ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
                            <Button
                                onClick={() => setModalVisible(false)}
                                style={{ width: '45%' }}
                                className="modal-cancel-button"
                            >
                                Close
                            </Button>
                        </div>
                    ):(
                        <div className="button-container d-flex align-items-center justify-content-around" style={{ width: '100%' }}>
                            <Button
                                onClick={() => setModalVisible(false)}
                                className="modal-cancel-button"
                                style={{ width: '45%' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="modal-submit-button"
                                style={{ width: '45%', padding: isSubmitting ? '0.8rem 0.8rem' : '1.05rem 0.8rem' }}
                            >
                                {isSubmitting ? <i className="fas fa-spinner fa-spin"/> : 'Submit'} 
                            </Button>
                        </div>
                    )}
                </Modal.Footer>
            </Form>
            </>
            )}
        </Formik>
        </Modal>
    );
};

export default ReportContent;