import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";


const JobSuccessMessage = ({ jobSlug }) => {
    const history = useHistory();
    return (
        <StyledMessage className="success-container mt-4 py-3">
            <div className="success-icon py-2 mt-4 d-flex align-items-center justify-content-center">
                <i className="material-icons" style={{ fontSize: 60, color: '#00C853' }}>check_circle_outline</i>
            </div>
            <h2 className="success-heading py-3" style={{ textAlign: 'center' }}>Job Post Successful</h2>
            <h3 className="success-text pb-3" style={{ textAlign: 'center' }}>Congratulations! The job has been posted. Your journey towards inclusive hiring begins here!</h3>
            <h3 className="success-text pb-2 success-link" style={{ textAlign: 'center' }} onClick={() => history.push(`/opportunity/${jobSlug}`)}>View job</h3>
        </StyledMessage>
    )
};

export default JobSuccessMessage;


const StyledMessage = styled.div`
    border-top: 1px solid rgb(233, 233, 233);
    .success-link {
        text-decoration: underline;
        font-weight: 600;
        font-size: 1.1rem;
        &:hover {
            cursor: pointer;
        }
    }
`;

