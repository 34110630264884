const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  if (action.type === 'SET_LOADING') {
    return {
      ...state,
      loading: action.loading,
    };
  }

  return state;
};

export const setLoading = (loading) => ({
  type: 'SET_LOADING',
  loading,
});
