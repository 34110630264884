import { combineReducers } from 'redux';

import toast from './toast';
import language from './language';
import user from './user';
import ui from './ui';
import locations from './locations';
import events from './events';
import photos from './photos';
import notifications from './notifications';

export default combineReducers({
  toast,
  language,
  ui,
  user,
  locations,
  events,
  photos,
  notifications,
});
