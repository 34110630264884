import axios from 'axios';
import * as urls from './urls';


const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = 'https://api.iminclusive.com';
      break;
    case 'development':
    default:
      url = 'http://localhost:8000';
  }

  return url;
}



const API = axios.create({
  baseURL: getBaseUrl(),
});

export default API;


//Registeration & Login
export const register = (data) => API.post(urls.register, data);
export const verifyEmail = (key) => API.get(urls.verifyEmail(key));
export const login = (data) => API.post(urls.login, data);


//Password
export const passwordChange = (data) => API.post(urls.passwordChange, data);
export const resetPassword = (email) => API.post(urls.passwordReset, { email });
export const resetPasswordConfirm = (data) => API.post(urls.passwordResetConfirm, data);



//IB Feed
export const getFeed = (page) => API.get(urls.feed, {
  params: {
    page,
  },
});
export const getUserFeed = (id, page) => API.get(urls.userFeed(id), {
  params: {
    page,
  },
});

//Feed Photo
export const deletefeedPhoto = (postId, photoId) => API.delete(urls.feedPhotoByPhotoId(postId, photoId));
export const updatefeedPhoto = (postId, photoId, data) => API.put(urls.feedPhotoByPhotoId(postId, photoId), data);

//Feed Post
export const getPostFeed = (page) => API.get(urls.feedPost, {
  params: {
    page,
  },
});
export const getUserFeedById = (id, page) => API.get(urls.UserfeedById(id), {
  params: {
    page,
  },
});
export const getFeedPostById = (postId) => API.get(urls.feedPostById(postId));
export const addfeedPost = (data) => API.post(urls.feedPost, data);
export const deletefeedPost = (postId) => API.delete(urls.feedPostById(postId));

//Check New Post
export const checkNewPost = (lts) => API.get('/feed/post/checkupdate/', {
  params: {
    lts,
  },
});

//Feed Comment
export const addfeedComment = (postId, data) => API.post(urls.commentFeed(postId), data);
export const deletefeedComment = (postId, commentId) => API.delete(urls.commentFeedById(postId, commentId));
export const comment = (id, data) => API.post(urls.comment(id), data);
export const deleteComment = (activityId, commentId) => API.delete(urls.commentById(activityId, commentId));

//Feed Post Reactions
export const getPostReactions = (postId) => API.get(urls.postReaction(postId));
export const addPostReaction = (postId, data) => API.post(urls.postReaction(postId), data);
export const updatePostReaction = (reactionId, data) => API.put(urls.postReactionById(reactionId), data);
export const deletePostReaction = (reactionId) => API.delete(urls.postReactionById(reactionId));

//Feed Comment Reactions
export const getCommentReactions = (commentId) => API.get(urls.commentReaction(commentId));
export const addCommentReaction = (commentId, data) => API.post(urls.commentReaction(commentId), data);
export const updateCommentReaction = (reactionId, data) => API.put(urls.commentReactionById(reactionId), data);
export const deleteCommentReaction = (reactionId) => API.delete(urls.commentReactionById(reactionId));

//Feed Follow Post
export const getFollowPost = (postId, ownerId) => API.get(urls.followPost(postId, ownerId));
export const followPost = (postId, ownerId, data) => API.post(urls.followPost(postId, ownerId), data);
export const unFollowPost = (postId, ownerId) => API.delete(urls.followPost(postId, ownerId));

//Feed Link
export const getLinkPreview = (data) => API.post(urls.previewLink, data, { headers: { 'content-type': 'application/json' } });

//Report Feed Content
export const reportContent = (data) => API.post(urls.feedContent, data);


//Notifications
export const getUnreadNotifications = () => API.get(urls.unreadNotifications);
export const getReadNotifications = () => API.get(urls.readNotifications);
export const markNotificationsRead = () => API.post(urls.markNotificationRead);

//Email Notifications
export const getEmailNotifications = (ownerId) => API.get(urls.emailNotifications(ownerId));
export const updateEmailNotifications = (ownerId, data) => API.put(urls.emailNotifications(ownerId), data);

//Location
export const getCountries = () => API.get(urls.countries);
export const getCitiesByCountry = (id) => API.get(urls.cityByCountry(id));
export const cities = () => API.get('/location/');
export const getCities = (query) => API.get('/location/', { params: query });


//Search
export const searchProfile = (query) => API.get(urls.search, { params: query });


//Follow User
export const getFollowPerson = (ownerId, userOwnerId) => API.get(urls.followUser(ownerId, userOwnerId))
export const FollowPerson = (ownerId, userOwnerId, data) => API.post(urls.followUser(ownerId, userOwnerId), data)
export const unFollowPerson = (ownerId, userOwnerId) => API.delete(urls.followUser(ownerId, userOwnerId))
export const getUserFollowers = (ownerId) => API.get(urls.followerList(ownerId))
export const getUserFollowing = (ownerId) => API.get(urls.followingList(ownerId))


//Block User
export const getBlockProfiles = (ownerId) => API.get(urls.blockUser(ownerId))
export const getBlockPersonById = (ownerId, userOwnerId) => API.get(urls.blockUserByOwnerId(ownerId, userOwnerId))
export const BlockUser = (ownerId, userOwnerId, data) => API.post(urls.blockUserByOwnerId(ownerId, userOwnerId), data)
export const unBlockUser = (ownerId, userOwnerId) => API.delete(urls.blockUserByOwnerId(ownerId, userOwnerId))


//Person Profile
export const profile = () => API.get(urls.profile);
export const getProfileById = (profileId) => API.get(urls.profileById(profileId));
export const getUserProfileByslug = (slug) => API.get(urls.profileBySlug(slug))

export const listProfiles = (query) => API.get(urls.listProfile, { params: query });
export const getPersonProfiles = (filters, page) => API.get(urls.listProfile, {
  params: {
    ...filters,
    page,
  },
});

export const createNewProfile = () => API.post(urls.listProfile);
export const deleteProfile = (id) => API.delete(urls.updateProfile(id))
export const updateProfile = (id, data) => API.put(urls.updateProfile(id), data);

//Person Post
export const post = (id, data) => API.post(urls.post(id), data, { headers: { 'content-type': 'multipart/form-data' } });
export const getPostById = (userId, postId) => API.get(urls.postById(userId, postId));
export const deletePost = (profileId, postId) => API.delete(urls.postById(profileId, postId));

//Person Photo
export const uploadPhoto = (profileId, data) => API.post(urls.userPhoto(profileId), data, { headers: { 'content-type': 'multipart/form-data' } });
export const deletePhoto = (profileId, photoId) => API.delete(urls.photoById(profileId, photoId));

//Person Education
export const addEducation = (id, data) => API.post(urls.addEducation(id), data);
export const updateEducation = (profileId, educationId, data) => API.put(urls.educationById(profileId, educationId), data);
export const deleteEducation = (profileId, educationId) => API.delete(urls.educationById(profileId, educationId));


//Person Experience
export const addExperience = (id, data) => API.post(urls.addExperience(id), data);
export const updateExperience = (profileId, experienceId, data) => API.put(urls.experienceById(profileId, experienceId), data);
export const deleteExperience = (profileId, experienceId) => API.delete(urls.experienceById(profileId, experienceId));

//Person Skill
export const addSkill = (id, data) => API.post(urls.personSkill(id), data);
export const deleteSkill = (profileId, skillId) => API.delete(urls.personSkillById(profileId, skillId));

//Person Accommodation
export const addAccomodation = (id, data) => API.post(urls.personAcccomadation(id), data);
export const deleteAccomodation = (profileId, accomodationId) => API.delete(urls.personAcccomadationById(profileId, accomodationId));

//Person Hobby
export const addHobby = (id, data) => API.post(urls.personHobbies(id), data);
export const deleteHobby = (profileId, hobbyId) => API.delete(urls.personHobbyById(profileId, hobbyId));

//Person Achievement
export const addAchievement = (id, data) => API.post(urls.personAchievement(id), data);
export const deleteAchievement = (profileId, achievementId) => API.delete(urls.personAchievementById(profileId, achievementId));
export const updateAchievement = (profileId, achievementId, data) => API.put(urls.personAchievementById(profileId, achievementId), data);

//Person Project
export const addProject = (id, data) => API.post(urls.personProject(id), data);
export const deleteProject = (profileId, projectId) => API.delete(urls.personProjectById(profileId, projectId));
export const updateProject = (profileId, projectId, data) => API.put(urls.personProjectById(profileId, projectId), data);

//Person Event
export const attendingEvents = (profileId) => API.get(urls.userEvents(profileId));
export const unattendEvent = (profileId, eventId) => API.delete(urls.unattendEvent(profileId, eventId));

//Person Follower/Following
export const follow = (profileId, senderId) => API.post(urls.follow(profileId), {
  follower_person: senderId,
});
export const isFollowing = (profileId, followerId) => API.get(urls.following(profileId, followerId));
export const unfollow = (profileId, followerId) => API.delete(urls.following(profileId, followerId));
export const getFollowers = (profileId) => API.get(urls.getFollowers(profileId));
export const getFollowing = (profileId) => API.get(urls.getFollowing(profileId));

//Person Blocked
export const blockProfile = (profileId, blockerId) => API.post(urls.blockProfile(profileId), {
  blocker: blockerId,
});
export const getBlocked = (profileId) => API.get(urls.blockProfile(profileId));
export const getBlockedList = (profileId) => API.get(urls.blockProfile(profileId));
export const isBlocked = (profileId, blockerId) => API.get(urls.blockedProfile(profileId, blockerId));
export const unBlockProfile = (profileId, blockerId) => API.delete(urls.blockedProfile(profileId, blockerId));

//Person Contact
export const getPersonContact = (profileId) => API.get(urls.personContact(profileId));
export const updatePersonContact = (profileId, data) => API.put(urls.personContact(profileId), data);


//Organization Profile
export const getOrgProfiles = (filters, page) => API.get(urls.organizationProfile, {
  params: {
    ...filters,
    page,
  },
});
export const getOrgProfileByslug = (slug) => API.get(urls.organizationProfileBySlug(slug));

export const addOrganization = (data) => API.post(urls.organizationProfile, data);
export const updateOrganization = (slug, data) => API.put(urls.organizationProfileBySlug(slug), data);
export const deleteOrganization = (slug) => API.delete(urls.organizationProfileBySlug(slug));

//Organization Industries
export const getOrganizationIndustries = () => API.get(urls.organizationIndustries);

//Organization Jobs
export const getOrgjobs = (filters, page) => API.get(urls.organizationJobs, {
  params: {
    ...filters,
    page,
  },
});
export const getOrganizationJobs = (orgSlug, filters, page) => API.get(urls.organizationJobsByOrgSlug(orgSlug), {
  params: {
    ...filters,
    page,
  },
});
export const getOrganizationJobBySlug = (slug) => API.get(urls.organizationJobBySlug(slug));
export const getOrganizationJobCategories = () => API.get(urls.organizationJobCategories);

export const addOrganizationJob = (data) => API.post(urls.organizationJobs, data);
export const updateOrganizationJob = (slug, data) => API.put(urls.organizationJobBySlug(slug), data);
export const deleteOrganizationJob = (slug) => API.delete(urls.organizationJobBySlug(slug));

//Organization Job Locations
export const getOrganizationJobLocations = (jobSlug) => API.get(urls.organizationJobLocation(jobSlug));
export const addOrganizationJobLocation = (jobSlug, data) => API.post(urls.organizationJobLocation(jobSlug), data);
export const deleteOrganizationJobLocation = (jobSlug, locationId) => API.delete(urls.organizationJobLocationDetail(jobSlug, locationId));
export const getOrganizationJobLocationDetail = (jobSlug, locationId) => API.get(urls.organizationJobLocationDetail(jobSlug, locationId));

//Organization Skill Options
export const getOrganizationSkillOptions = () => API.get(urls.organizationSkillOptions); 

//Organization Job Skills
export const getOrganizationJobSkills = (jobSlug) => API.get(urls.organizationJobSkill(jobSlug));
export const addOrganizationJobSkill = (jobSlug, data) => API.post(urls.organizationJobSkill(jobSlug), data);
export const deleteOrganizationJobSkill = (jobSlug, skillId) => API.delete(urls.organizationJobSkillDetail(jobSlug, skillId));
export const getOrganizationJobSkillDetail = (jobSlug, skillId) => API.get(urls.organizationJobSkillDetail(jobSlug, skillId));

//Organization Job Accommodations 
export const getJobAccommodationsCognitive = (jobSlug) => API.get(urls.jobAccommodationCognitive(jobSlug));
export const addJobAccommodationCognitive = (jobSlug, data) => API.post(urls.jobAccommodationCognitive(jobSlug), data);
export const deleteJobAccommodationCognitive = (jobSlug, accomodationId) => API.delete(urls.jobAccommodationCognitiveDetail(jobSlug, accomodationId));
export const getJobAccommodationCognitiveDetail = (jobSlug, accomodationId) => API.get(urls.jobAccommodationCognitiveDetail(jobSlug, accomodationId));

export const getJobAccommodationsHearing = (jobSlug) => API.get(urls.jobAccommodationHearing(jobSlug));
export const addJobAccommodationHearing = (jobSlug, data) => API.post(urls.jobAccommodationHearing(jobSlug), data);
export const deleteJobAccommodationHearing = (jobSlug, accomodationId) => API.delete(urls.jobAccommodationHearingDetail(jobSlug, accomodationId));
export const getJobAccommodationHearingDetail = (jobSlug, accomodationId) => API.get(urls.jobAccommodationHearingDetail(jobSlug, accomodationId));

export const getJobAccommodationsMobility = (jobSlug) => API.get(urls.jobAccommodationMobility(jobSlug));
export const addJobAccommodationMobility = (jobSlug, data) => API.post(urls.jobAccommodationMobility(jobSlug), data);
export const deleteJobAccommodationMobility = (jobSlug, accomodationId) => API.delete(urls.jobAccommodationMobilityDetail(jobSlug, accomodationId));
export const getJobAccommodationMobilityDetail = (jobSlug, accomodationId) => API.get(urls.jobAccommodationMobilityDetail(jobSlug, accomodationId));

export const getJobAccommodationsSensory = (jobSlug) => API.get(urls.jobAccommodationSensory(jobSlug));
export const addJobAccommodationSensory = (jobSlug, data) => API.post(urls.jobAccommodationSensory(jobSlug), data);
export const deleteJobAccommodationSensory = (jobSlug, accomodationId) => API.delete(urls.jobAccommodationSensoryDetail(jobSlug, accomodationId));
export const getJobAccommodationSensoryDetail = (jobSlug, accomodationId) => API.get(urls.jobAccommodationSensoryDetail(jobSlug, accomodationId));

export const getJobAccommodationsVisual = (jobSlug) => API.get(urls.jobAccommodationVisual(jobSlug));
export const addJobAccommodationVisual = (jobSlug, data) => API.post(urls.jobAccommodationVisual(jobSlug), data);
export const deleteJobAccommodationVisual = (jobSlug, accomodationId) => API.delete(urls.jobAccommodationVisualDetail(jobSlug, accomodationId));
export const getJobAccommodationVisualDetail = (jobSlug, accomodationId) => API.get(urls.jobAccommodationVisualDetail(jobSlug, accomodationId));

//Organization Job Accommodations Lists
export const accommodationsCognitiveList = () => API.get(urls.accommodationsCognitive);
export const accommodationsHearingList = () => API.get(urls.accommodationsHearing);
export const accommodationsMobilityList = () => API.get(urls.accommodationsMobility);
export const accommodationsSensoryList = () => API.get(urls.accommodationsSensory);
export const accommodationsVisualList = () => API.get(urls.accommodationsVisual);

//Organization Job Screening Questions
export const getJobScreeningQuestions = (jobSlug) => API.get(urls.jobScreeningQuestion(jobSlug));
export const postJobScreeningQuestion = (jobSlug, data) => API.post(urls.jobScreeningQuestion(jobSlug), data);
export const updateJobScreeningQuestion = (jobSlug, questionId, data) => API.put(urls.jobScreeningQuestionDetail(jobSlug, questionId), data);
export const deleteJobScreeningQuestion = (jobSlug, questionId) => API.delete(urls.jobScreeningQuestionDetail(jobSlug, questionId));
export const updateJobScreeningQuestionChoice = (jobSlug, questionId, choiceId, data) => API.put(urls.jobScreeningQuestionChoice(jobSlug, questionId, choiceId), data);
export const deleteJobScreeningQuestionChoice = (jobSlug, questionId, choiceId) => API.delete(urls.jobScreeningQuestionChoice(jobSlug, questionId, choiceId));

//Organization Events
export const getOrgEvents = (filters, page) => API.get(urls.organizationEvents, {
  params: {
    ...filters,
    page,
  },
});
export const getOrganizationEvents = (orgSlug, filters, page) => API.get(urls.organizationEventsByorgSlug(orgSlug), {
  params: {
    ...filters,
    page,
  },
});
export const getOrganizationEventBySlug = (slug) => API.get(urls.organizationEventBySlug(slug));
export const getOrganizationEventCategories = () => API.get(urls.organizationEventCategories);

export const addOrganizationEvent = (data) => API.post(urls.organizationEvents, data);
export const updateOrganizationEvent = (slug, data) => API.put(urls.organizationEventBySlug(slug), data);
export const deleteOrganizationEvent = (slug) => API.delete(urls.organizationEventBySlug(slug));


//Organization Event Time
export const addOrganizationEventTime = (slug, data) => API.post(urls.organizationEventTime(slug), data);
export const updateOrganizationEventTime = (slug, id, data) => API.put(urls.OrganizationEventTimeById(slug, id), data);
export const deleteOrganizationEventTime = (slug, id) => API.delete(urls.OrganizationEventTimeById(slug, id));


//Organization Initiatives
export const getOrganizationInitiatives = () => API.get(urls.organizationInitiatives);
export const addOrganizationInitiative = (data) => API.post(urls.organizationInitiatives, data);
export const updateOrganizationInitiative = (slug, id, data) => API.put(urls.organizationInitiativeById(slug, id), data);
export const deleteOrganizationInitiative = (slug, id) => API.delete(urls.organizationInitiativeById(slug, id));


//Events
export const getEvents = (filters, page) => API.get(urls.event, {
  params: {
    ...filters,
    page,
  },
});
export const getEventById = (id) => API.get(urls.eventById(id));
export const getEventCategories = () => API.get(urls.eventCategories);

//Event Attendees
export const getAttendedEvents = () => API.get(urls.attendedEvents);
export const addAttendee = (eventTimingId, data) => API.post(urls.attendeeList(eventTimingId), data);
export const removeAttendee = (attendeeEventId, data) => API.post(urls.withdrawApplication(attendeeEventId), data);

export const getAttendeeList = (eventTimingId) => API.get(urls.attendeeList(eventTimingId));
export const acceptApplication = (eventId, applicationId, data) => API.post(urls.acceptApplication(eventId, applicationId), data);
export const rejectApplication = (eventId, applicationId, data) => API.post(urls.rejectApplication(eventId, applicationId), data);


//Jobs
export const getJobs = (filters) => API.get(urls.jobs, {
  params: filters,
});
export const getJobById = (id) => API.get(`${urls.jobs}${id}/`);
export const getJobCategories = () => API.get(urls.jobCategories);

//Job Applicants
export const getAppliedJobs = () => API.get(urls.applyJob);
export const ApplyJob = (jobId, data) => API.post(urls.applyForJob(jobId), data);

//Job Screening Questions Answer
export const postScreeningQuestionAnswer = (applicationId, data) => API.post(urls.screeningQuestionAnswer(applicationId), data);

//Job Application
export const getJobAppicationList = (jobId, status) => API.get(urls.JobApplicants(jobId), { params: status });
export const getJobApplication = (jobId, applicationId) => API.get(urls.JobApplicantDetail(jobId, applicationId));
export const shortlistApplicant = (jobId, applicantionId, data) => API.post(urls.shortlistApplicant(jobId, applicantionId), data);
export const rejectApplicant = (jobId, applicantionId, data) => API.post(urls.rejectApplicant(jobId, applicantionId), data);



// learn
export const getCourseList = (filters) => API.get(urls.CourseList, {params: filters });
export const getCourseCategoryList = () => API.get(urls.CourseCategoryList);

export const getCourseDetail = (courseID) => API.get(urls.CourseDetail(courseID));

export const getLessonDetail = (lessonID) => API.get(urls.LessonDetail(lessonID));
export const getLessonQuiz = (lessonID) => API.get(urls.LessonQuiz(lessonID));

export const submitQuiz = (moduleID, data) => API.post(urls.SubmitQuiz(moduleID), data);
export const getQuizResults = (moduleID) => API.get(urls.QuizResult(moduleID));

export const enrollCourse = (courseID) => API.post(urls.enrollCourse(courseID));
export const completeModule = (lessonID) => API.post(urls.moduleComplete(lessonID));