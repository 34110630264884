import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import AddEditAchievement from "./AddEditAchievement";
import { deleteAchievement } from "../../../api";

const AchievementItem = ({
  achievement,
  achLength,
  isOwner,
  index,
  refreshProfile,
  profileId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = () => {
    setIsDeleting(true);
    setTimeout(() => setIsDeleting(false), 2000);
    deleteAchievement(profileId, achievement.id)
      .then(() => {
        setIsDeleting(false);

        refreshProfile();
      })
      .catch(() => {
        setIsDeleting(false);

        refreshProfile();
      });
  };
  return (
    <div className="user-achievments justify-content-between align-items-center" style={{ paddingTop: index === 0 ? 0 : '1rem', paddingBottom: index + 1 === achLength ? 0 : '1rem' }}>
      <div className="abilities-wrapper">
        <div className="achivementsWrapper mt-0">
          {/*<img src={Bluedot} />  style={{borderLeft:achLength-1===index?"none":""}}*/}
          <div className="title p-0" style={{ fontSize: '18px' }}>{achievement.achievement_title}</div>
          <div className="text p-0">{achievement.achievement_summary}</div>
        </div>
      </div>

      {/* <div className="d-flex">
      <div className="blueDot">
        <img src={Bluedot} />
        {
          achLength - 1 === index ? "" : (<div></div>)
        }

      </div>
    </div> */}

      {isOwner ? (
        <div className="d-flex align-items-center">
          <div style={{ cursor: "pointer" }} onClick={() => setIsEditing(true)}>
            <i className="material-icons" alt="Edit Icon">edit</i>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="pl-2"
            onClick={() => onDelete(achievement.id)}
          >
            {isDeleting ? (
              <i className="fas fa-spinner fa-spin" />
            ) : (
              <i className="material-icons" alt="Delete Icon">delete</i>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {isOwner ? (
        <AddEditAchievement
          show={isEditing}
          isEditing
          achievementId={achievement.id}
          closeDialog={() => setIsEditing(false)}
          currentValues={{
            achievement_title: achievement.achievement_title,
            achievement_summary: achievement.achievement_summary,
            achievement_date: achievement.achievement_date,
          }}
          onUpdated={refreshProfile}
        />
      ) : null}
    </div>
  );
};

const Achievements = ({ achievements, isOwner, refreshProfile, profileId }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  return (
    <div className="user-ability user-data-wrapper">
      <div
        className="user-story-header justify-content-between"
        style={{ marginBottom: "1rem" }}
      >
        <div>
          <h2 style={{ display: 'flex' }}>Achievements & Awards
            {isOwner && (
              <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Achievement Tooltip" aria-pressed={showTooltip}>
                <i className="material-icons help-icon">help_outline</i>
              </Button>
            )}
          </h2>
          <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
            {(props) => (
              <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                Were you ever awarded or recognized for your efforts in a voluntary, education, or work role? Share your achievements!
              </Tooltip>
            )}
          </Overlay>
        </div>
        {isOwner ? (
          <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600', cursor: 'pointer' }} onClick={() => setIsAdding(true)}>add</i>
        ) : (
          ""
        )}
      </div>
      <hr />
      {achievements.length ? (
        achievements.map((item, index) => (
          <AchievementItem
            key={item.id}
            refreshProfile={refreshProfile}
            achievement={item}
            index={index}
            achLength={achievements.length}
            profileId={profileId}
            isOwner={isOwner}
          />
        ))
      ) : (
        <h3 className="py-2 text-red">
          {isOwner
            ? "You have not added any achievements yet."
            : "This user has not added any achievements yet."}
        </h3>
      )}

      {isOwner ? (
        <AddEditAchievement
          isEditing={false}
          show={isAdding}
          closeDialog={() => setIsAdding(false)}
          onUpdated={refreshProfile}
        />
      ) : null}
    </div>
  );
};

export default Achievements;
