import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { LoaderWithText } from "./Loader";
import SuccessMessage from "./SuccessMessage";
import ErrorMessage from "./ErrorMessage";
import { verifyEmail } from "../api";
import OnboardingContainer, {
  OnboardingHeader,
  AuthFooter,
  AuthMobileFooter
} from "../common/Onboarding";
import ImInclusive from '../assets/images/ImInclusiveLogo.png';
import LoginImage from '../assets/images/LoginSignupImage.png';

const EmailVerification = () => {
  let content = null;
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { key } = useParams();


  if (verifying) {
    content = <LoaderWithText text="Please wait..." />;
  }

  if (!verifying && verified && !redirect) {
    content = (
      <SuccessMessage
        margin="-100px auto 0"
        message="Your account has been successfully verified! Please wait while we redirect you."
      />
    );
  }

  if (!verifying && verified && redirect) {
    content = <Redirect to="/auth/login" />;
  }

  if (!verifying && !verified) {
    content = (
      <ErrorMessage message="Oh no! Looks like an error occurred. Please try again later." />
    );
  }

  useEffect(() => {
    setVerifying(true);

    if (key) {
      verifyEmail(key)
        .then(() => {
          setVerifying(false);
          setVerified(true);
        })
        .catch(() => {
          setVerifying(false);
          setVerified(false);
        });
    }
  }, [key]);

  useEffect(() => {
    if (verified) {
      setTimeout(() => {
        setRedirect(true);
      }, 4000);
    }
  }, [verified]);

  return (
    <>
      <OnboardingContainer classes="ic-login-container">
        <div className="container-fluid">
          <div className="row">

            <div className="col-sm-12 col-md-6 ic-container-left p-0">
              <div className="ic-image">
                <img src={LoginImage} alt="A graphic showing three people with different abilities." />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 ic-container-right">
              <header className="ic-header">
                <div className="ic-logo">
                  <img src={ImInclusive} style={{ width: 200 }} alt="Inclusive Logo" />
                </div>
              </header>
              <aside>
                <div className="ic-form-wrapper">{content}</div>
              </aside>
            </div>
          </div>
        </div>
      </OnboardingContainer>
      <AuthMobileFooter />
    </>
  );
};

export default EmailVerification;
