import React, {useState} from "react";
import { connect } from "react-redux";
import { addToast } from "../../../redux/toast";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import { Formik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "../../../common/Form";
import DatePicker from "../../../components/FormikDatepicker";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { addOrganizationInitiative, updateOrganizationInitiative } from "../../../api";

const AddEditInitiative = ({
  activeProfile,
  show,
  closeDialog,
  isEditing,
  currentValues,
  initiativeId,
  onUpdated,
  addToast
}) => {

  const [uploadState, setUploadState] = useState("No file selected");

  const projectSchema = yup.object().shape({
    pinned: yup
        .bool(),
    initiative_title: yup
        .string()
        .required("Please provide a title for the project.")
        .max(45),
    initiative_description: yup
        .string()
        .required("Please provide a description for the project."),
    start_date: yup
        .date()
        .required("Please provide the start date."),
    end_date: yup
        .date()
        .required("Please provide the end date."),
    initiative_url: yup
        .string()
        .matches(
            /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i,
            "Enter Url in https:// format."),
    image: yup
        .mixed()
        .required("Please provide a project image."),
  });

  let initialValues = {
    pinned: false,
    initiative_title: "",
    initiative_description: "",
    start_date: new Date(),
    end_date: new Date(),
    initiative_url: "",
    image: null,
  };

  if (isEditing) {
    initialValues = {
        pinned: currentValues.pinned,
        initiative_title: currentValues.initiative_title,
        initiative_description: currentValues.initiative_description,
        start_date: new Date(currentValues.start_date),
        end_date: new Date(currentValues.end_date),
        initiative_url: currentValues.initiative_url,
        image: currentValues.image
    };
  }

  const onSubmit = (values, { setSubmitting }) => { 
    if (isEditing) {
      const data = { ...values,
        start_date: `${format(values.start_date, "yyyy-MM-dd")}`,
        end_date: `${format(values.end_date, "yyyy-MM-dd")}`,
        }
      delete data.image;

      updateOrganizationInitiative(activeProfile.slug, initiativeId, {...data})     
        .then(() => {
          setSubmitting(false);
          closeDialog();
          onUpdated();
          addToast({
            title: "Success",
            text: `Project updated successfully.`,
            type: "success",
          });
        })
        .catch(() => {
          setSubmitting(false);
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    } 
    else {
      const project_image = values.image;
      const data = { ...values,
                  start_date: `${format(values.start_date, "yyyy-MM-dd")}`,
                  end_date: `${format(values.end_date, "yyyy-MM-dd")}`,
                  }
      delete data.image;    

      addOrganizationInitiative({ ...data })
        .then((data) => {
          const formData = new FormData();
          formData.append("image", project_image, project_image.name);
          updateOrganizationInitiative(activeProfile.slug, data.data.id, formData)
            .then(() => {
              setSubmitting(false);
              closeDialog();
              onUpdated();
              addToast({
                title: "Success",
                text: `Project added successfully.`,
                type: "success",
              });
            })
            .catch(() => {
              setSubmitting(false);
              addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
              });
            });
        })
        .catch((error) => {
          setSubmitting(false);
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    }
  };


  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit" : "Add"} Project</Modal.Title>
      </Modal.Header>
      <AddProjectForm>
      <Formik
        validationSchema={projectSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>

              <Row>
                <Form.Group as={Col} col={`${isEditing ? "12" : "6"}`}>
                  <Form.Label>Project Title</Form.Label>
                  <Form.Control
                    placeholder="Add a title for your project."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="initiative_title"
                    isInvalid={
                      touched.initiative_title && errors.initiative_title
                    }
                    value={values.initiative_title}
                    type="text"
                    as="input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.initiative_title}
                  </Form.Control.Feedback>
                </Form.Group>
                
                {!isEditing && (
                <Form.Group as={Col} col={6}>
                    <Form.Label htmlFor="image">Project Image</Form.Label>
                        <Form.Control
                          placeholder="Project Image"
                          onChange={(event) => {
                            setFieldValue(
                              "image",
                              event.currentTarget.files[0]
                            );
                            setUploadState("File selected");
                          }}
                          onBlur={handleBlur}
                          name="image"
                          isInvalid={touched.image && errors.image}
                          className={`project-image-input ${touched.image && errors.image ? "is-invalid" : ""}`}
                          type="file"
                          as="input"
                          accept="image/png, image/jpeg"
                        />
                        <Button variant="contained" color="default" className={`btnUpload mr-2 ${touched.image && errors.image? "is-invalid": ""}`}>                        
                          <CloudUploadIcon className={`mr-2 ml-0`}/>                         
                          Upload
                        </Button>
                        <span>{uploadState}</span>
                        <Form.Control.Feedback type="invalid">
                          {errors.image}
                        </Form.Control.Feedback>
                </Form.Group>
                )}
              </Row>              

              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="Add a description for your project."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="initiative_description"
                  isInvalid={
                    touched.initiative_description && errors.initiative_description
                  }
                  value={values.initiative_description}
                  as="textarea"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.initiative_description}
                </Form.Control.Feedback>
              </Form.Group>
              
            <Row>
                <Form.Group as={Col} col={6}>
                    <Form.Label>Start Date</Form.Label>
                    <DatePicker
                    nativeInputAriaLabel="Select project start date."
                    maxDate={new Date()}
                    placeholderText="When did you start the project?"
                    name="start_date"
                  />
                </Form.Group>
                <Form.Group as={Col} col={6}>
                    <Form.Label>End Date</Form.Label>
                    <DatePicker
                      nativeInputAriaLabel="Select project end date."
                      maxDate={new Date()}
                      minDate={new Date(values.start_date)}
                      name="end_date"
                      placeholderText="When did you end the project?"
                    />
                </Form.Group>
            </Row>

            <Form.Group>
                <Form.Label>Project Action Link <span style={{color: "rgb(117, 117, 117)"}}>(Optional)</span></Form.Label>
                <Form.Control
                  placeholder="e.g. https://www.behance.net/my-project"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="initiative_url"
                  isInvalid={
                    touched.initiative_url && errors.initiative_url
                  }
                  value={values.initiative_url}
                  type="text"
                  as="input"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.initiative_url}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Form.Check>
                    <Form.Check.Input
                      checked={values.pinned}
                      name="pinned"
                      value={values.pinned}
                      onChange={handleChange}
                      type="checkbox"
                    />
                    <Form.Check.Label style={{ fontSize: "16px", color: "#000" }}>
                      Pin this project
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group>
 
            </Modal.Body>

            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button onClick={closeDialog} block className="modal-cancel-button">
                       Cancel
                    </Button>
                  </Col>
                  <Col className="mt-3 mt-md-0" sm={12} md={6}>
                    <Button loading={isSubmitting ? 1 : 0} block className="modal-submit-button" type="submit">
                       {isSubmitting ? "Please wait..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </AddProjectForm>
    </Modal>
  );
};

export default connect(
  (state) => ({ activeProfile: state.user.activeProfile, }),
  { addToast }  
)(AddEditInitiative);


const AddProjectForm = styled.div`
.project-image-input{
  width: 112px;
  height: 70px;
  position: absolute;
  left: 22px;
  top: 11px;
  cursor: pointer;
  opacity: 0;
}
.btnUpload{
  color: white !important;
  display: inline-flex;
  align-items: center;
  margin: 8px;
}
}
`