import React, { useState, useEffect, useRef } from "react";
import { NavLink as Link, useRouteMatch, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import AddEditJob from "./AddEditJob";
import { getOrganizationJobs, deleteOrganizationJob } from "../../../api";
import { processDate } from "../../../common/utils";
import { LoaderWithText } from "../../../components/Loader";

const Jobs = ({ profile, isOwner, refreshProfile, isAll }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobloading, setjobloading] = useState(false);
  const unmounted = useRef(false);
  const { slug } = useParams();
  const orgSlug = slug ? slug : profile.slug;
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const getjobs = () => {
    setjobloading(true);
    getOrganizationJobs(orgSlug).then(({ data }) => {
      if (!unmounted.current) {
        const jobss = data.jobs.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setJobs(jobss);
        setjobloading(false);
      }
    });
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getjobs();
    return () => { unmounted.current = true } 
  }, []);

  if (jobloading)
    return <LoaderWithText padding="150px 100px" text="Please wait..." />;

  if (isAll) {
    return (
      <section className="organizationCard d-flex flex-column mt-0">
        <div
          className="cardHeading pb-3 d-flex align-items-center justify-content-between"
          style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}
        >
          All Opportunities{" "}
          {isOwner ? (
            <Link to={`/addorganizationjob`} aria-label="Add Organization Job">
              <i className="material-icons" alt="Add Icon" style={{ color: '#000', fontSize: '30px', fontWeight: '600', cursor: "pointer" }} onClick={() => setIsAdding(true)}>add</i>
            </Link>
          ) : (
            ""
          )}
        </div>
        {/* <div className="cardHeading d-flex align-items-center justify-content-between">Recent Jobs   {isOwner?
       "":""}</div> */}
        <div className="outerJobWrapper">
          {jobs && jobs.length ? (
            jobs &&
            jobs.map((item, index) => (
              <JobItems
                key={item.id}
                job={item}
                isOwner={isOwner}
                index={index}
                length={jobs.length}
                refreshProfile={refreshProfile}
              />
            ))
          ) : (
            <h3 className="pb-2 text-red pt-4">
              {isOwner
                ? "You have not added any opportunity yet."
                : "There are no opportunities available right now, visit us soon again!"}
            </h3>
          )}
        </div>
        {isOwner ? (
          <AddEditJob
            isEditing={false}
            show={isAdding}
            closeDialog={() => setIsAdding(false)}
            onUpdated={getjobs}
          />
        ) : null}
      </section>
    );
  }

  return (
    <section className="organizationCard d-flex flex-column">
      <div
        className="cardHeading d-flex align-items-center justify-content-between pb-3"
        style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}
      >
        <span style={{ display: 'flex' }}>
          Latest Opportunities{" "}
          {isOwner && (
            <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Latest Opportunities Tooltip" aria-pressed={showTooltip}>
              <i className="material-icons help-icon">help_outline</i>
            </Button>
          )}
        </span>
        <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
          {(props) => (
            <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
              Post available opportunities for persons with disabilities: full-time, part-time jobs, traineeships, internships, apprenticeships, freelance roles or work contracts.
            </Tooltip>
          )}
        </Overlay>
        {isOwner ? (
          <Link to={`/addorganizationjob`} aria-label="Add Organization Job">
            <i className="material-icons" alt="Add Icon" style={{ color: '#000', cursor: "pointer", fontSize: '30px', fontWeight: '600' }} onClick={() => setIsAdding(true)}>add</i>
          </Link>
        ) : (
          ""
        )}
      </div>
      {/* <div className="cardHeading d-flex align-items-center justify-content-between">Recent Jobs   {isOwner?
     "":""}</div> */}
      <div className="outerJobWrapper">
        {jobs && jobs.length ? (
          jobs &&
          jobs
            .slice(0, 3)
            .map((item, index) => (
              <JobItems
                key={item.id}
                job={item}
                isOwner={isOwner}
                index={index}
                length={jobs.length}
                refreshProfile={refreshProfile}
              />
            ))
        ) : (
          <h3 className="pb-2 text-red pt-4">
            {isOwner
              ? "You have not added any opportunity yet."
              : "There are no opportunities available right now, visit us soon again!"}
          </h3>
        )}

        {/* {jobs && jobs.length>3?<div style={{width:"100%",paddingTop:"22px"}} className="d-flex justify-content-center align-items-center"><Link to="/allorganizationJobs"  className='view-all-link'>View all jobs</Link></div>:""} */}
      </div>
      {isOwner ? (
        <AddEditJob
          isEditing={false}
          show={isAdding}
          closeDialog={() => setIsAdding(false)}
          onUpdated={getjobs}
        />
      ) : null}
    </section>
  );
};

const JobItems = ({ job, isOwner, refreshProfile, length, index }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleManageJob = (slug, id) => {
    history.push(`/org/jobdetail/${slug}/${id}`);
  };

  const onJobDelete = (slug) => {
    deleteOrganizationJob(slug)
      .then(() => {
        refreshProfile();
      })
      .catch(({ data: errors }) => {
        refreshProfile();
      });
  };

  const [isEditing, setIsEditing] = useState(false);
  return (
    <div
      className="jobs-wrapper justify-content-between col-12 col-md-12"
      style={{
        borderBottom: `${length - 1 === index ? "none" : "1px solid #EFEFEF"}`,
      }}
    >
      <div className="d-flex">
        <img
          src={
            job.job_organization_logo &&
            job.job_organization_logo !==
              "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
              ? job.job_organization_logo
              : job.organization
              ? job.organization.organization_logo
              : job.listed_by.organization_logo
          }
          className="job-img"
          alt="job-img"
        />
        <div className="jobDetailWrapper pl-3 align-self-center">
          <Link
            to={`/opportunity/${job.slug}`}
            className="RedColr"
            style={{ fontWeight: "bold" }}
          >
            {job.job_title}
          </Link>
          <div className="jobcompany">
            {job.organization
              ? job.organization.organization_title
              : job.listed_by.organization_title}
          </div>
          {/* <div className="joblocation">
            {job.job_city && job.job_city.city_name}
          </div> */}
          {/* <div className="jobpostedDate">
            {processDate(job.job_close_time, "PP")}
          </div> */}
        </div>
      </div>
      {/* {isOwner ? (
     <Link to={`${url}/job/${job.slug}/${job.id}`}><i className="fas fa-ellipsis-v" style={{ cursor: "pointer",fontSize:'32px',color:"#263238" }}></i></Link>
  
     ) : ""} */}

      {isOwner ? ( ///organzition/job/:jobId
        <JobDropdown>
          <Dropdown className="event-dropdown" alignRight>
            <Dropdown.Toggle variant="light" bg="white" id="dropdown-basic">
              <i className="fas fa-ellipsis-v" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0 m-0">
              <Dropdown.Item onClick={() => handleManageJob(job.slug, job.id)}>
                Manage Job
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </JobDropdown>
      ) : (
        //  <i className="fas fa-ellipsis-v" onClick={()=>handleManageJob(job.slug,job.id)} style={{ cursor: "pointer",fontSize:'32px',color:"#263238" }}></i>
        // <img src={edit} role="button" onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }} />
        ""
      )}

      {/* {
        isOwner ? (
          <AddEditJob
            show={isEditing}
            isEditing
            closeDialog={() => setIsEditing(false)}
            currentValues={{
                job_title:job.designation,
                job_summary:job.summary,
                job_loc:job.loc 
            }}
            onUpdated={refreshProfile}
          />
        ) : null
      }           */}
    </div>
  );
};

export default Jobs;

const JobDropdown = styled.div`
  i.fas.fa-ellipsis-v {
    font-size: 24px;
    color: #5f5f5f;
  }

  .dropdown-toggle::after {
    content: none;
  }

  a.dropdown-item {
    padding: 0.8rem;
  }

  a.dropdown-item:active {
    background: white;
    color: #212529;
  }

  button#dropdown-basic {
    background: white;
    border: none;
  }
  .dropdown-toggle {
    padding-right: 0 !important;
  }
  .dropdown-menu.show {
    border-radius: 0.7rem 0px 0.7rem 0.7rem;
    overflow: hidden;
}
`;
