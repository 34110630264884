import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';


const LanguageTab = ({ profile, addToast, currentLanguage, onLanguageChange }) => {
    const [language, setLanguage] = useState(currentLanguage === "ar" ? "العربية" : "English");
    
    const ChangeLanguage = (lang) => {
        onLanguageChange(lang);
        if (lang === "en") {
            setLanguage("English");
            addToast({
                title: "Success",
                text: `Language changed to English successfully.`,
                type: "success",
            });
        }
        else if (lang === "ar") {
            setLanguage("العربية");
            addToast({
                title: "Success",
                text: `Language changed to Arabic successfully.`,
                type: "success",
            });
        }
    }

    return (
        <StyledLanguageCard className="card">
           <div className="d-flex align-items-center cardHeader pb-3" >
                <h2 className="pb-0 mb-0 cardHeading">Language</h2>
            </div>       
            <div className="row pt-3">
                <div className="col-md-12">
                    <Row className="d-flex align-items-center py-3">
                        <Col md={8} xs={10}>
                            <div className="languageHeading">Change Language</div>
                            <div>Show Inclusive in this language.</div>
                        </Col>
                        <Col md={4} xs={2} className="d-flex justify-content-end">
                            <DropdownButton className="language-dropdown" title={language} alignRight={true}>
                                {currentLanguage === "ar" ? (
                                    <Dropdown.Item
                                        onClick={() => ChangeLanguage("en")}
                                        role="link"
                                        aria-label="Change Language"
                                        >
                                        English
                                    </Dropdown.Item>
                                    ) : (
                                    <Dropdown.Item
                                        onClick={() => ChangeLanguage("ar")}
                                        role="link"
                                        aria-label="Change Language"
                                    >
                                    العربية
                                    </Dropdown.Item>
                                )}
                            </DropdownButton>
                        </Col>
                    </Row>               
                </div>
            </div>   
        </StyledLanguageCard>
    );
}

export default connect(() => ({}), {
    addToast,
  })(LanguageTab);


const StyledLanguageCard = styled.div`
border-radius: 0.4rem;
box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
border: 0;
padding: 24px;

.cardHeading {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000; 
}
.cardHeader {
    border-bottom: solid 2px #dadbe2; 
}
h4.bodyHeading {
    font-size: 1.1rem !important;
    font-weight: 600;
    color: #000000;
}
.languageHeading {
    font-size: 1.1rem !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #000000;
}

.language-dropdown > button {
    display: flex;
    align-items: center;
    background: #B11030;
    border-color: #B11030;
    border-radius: 7px;
    padding: 0.5rem 0.8rem;
    min-width: 100px;
    justify-content: space-between;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    background-color: #B11030 !important;
    border-color: #B11030 !important;
}

@media(max-width: 576px){
    padding: 20px;
     
.language-dropdown > button {
    min-width: auto;
}
}

`