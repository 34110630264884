
import React from "react";
import AllJobs from './Components/AllJobs'
import {useSelector} from "react-redux";
import { CustomersUIProvider } from "./Components/CustomersUIContext";



const OrgJobs =()=>{
    const customersUIEvents={}
    const {activeProfile} = useSelector(state => state.user);

    return(
        <div className="row">
    {/* <div className="col-lg-4">
        <MixedWidget14 className="card-stretch gutter-b" />
    </div> */}
    <div className="col-lg-12">
    {activeProfile &&
     <CustomersUIProvider customersUIEvents={customersUIEvents}>
        <AllJobs profile={activeProfile} className="card-stretch gutter-b"/>
     </CustomersUIProvider>
    }
    </div>
</div>
    )
}

export default OrgJobs