import React, { useState, useRef } from "react";
import { NavLink as Link } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { format } from 'date-fns';

import Form from "../../common/Form";
import Button from "../../components/Button";
import ImInclusive from '../../assets/images/ImInclusiveLogo.png';
import LoginImage from '../../assets/images/LoginSignupImage.png';
import SendIcon from '../../assets/images/SendIcon.png';

import OnboardingContainer, {
    OnboardingHeader,
    AuthFooter,
    AuthMobileFooter,
} from "../../common/Onboarding";
import SuccessMessage from "../../components/SuccessMessage";

import { registerUser } from "../../redux/user";
import { addToast } from "../../redux/toast";
import PasswordValidator from "../../components/PasswordValidator";

const Signup = ({ registerUser, addToast }) => {
    const signupSchema = yup.object().shape({
        first_name: yup.string().required("Your first name is required."),
        last_name: yup.string().required("Your last name is required."),
        email: yup.string().email("Enter a valid email.").required("Your email is required."),
        password1: yup
            .string()
            .required("Your password is required.")
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,}$/,
                "Please enter a valid password."
            ),
        password2: yup
            .string()
            .required("Confirm password is required.")
            .oneOf([yup.ref("password1")], "Passwords do not match."),
    });

    const [agreementCheckbox, setAgreementCheckbox] = useState(false);
    const [PrivacyCheckbox, setPrivacyCheckbox] = useState(false);
    const [disableBtn, setdisableBtn] = useState(true);
    const width = window.innerWidth;
    const [show, setShow] = useState(width < 480 ? true : false);

    const errorFieldFirstName = useRef(null);
    const errorFieldLastName = useRef(null);
    const errorFieldEmail = useRef(null);
    const errorFieldPassword = useRef(null);
    const errorFieldConfirmPassword = useRef(null);

    const toggleAgreementChange = () => {
        if (agreementCheckbox) {
            setAgreementCheckbox(false);
        } else {
            setAgreementCheckbox(true);
        }

        if (!agreementCheckbox && PrivacyCheckbox) {
            setdisableBtn(false);
        } else {
            setdisableBtn(true);
        }
    };

    const togglePrivacyChange = () => {
        if (PrivacyCheckbox) {
            setPrivacyCheckbox(false);
        } else {
            setPrivacyCheckbox(true);
        }
        if (agreementCheckbox && !PrivacyCheckbox) {
            setdisableBtn(false);
        } else {
            setdisableBtn(true);
        }
    };

    const [signedUp, setSignedUp] = useState(false);
    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");

    const handlePasswordShow1 = () => {
        passwordType1 === "text"
            ? setPasswordType1("password")
            : setPasswordType1("text");
    };
    const handlePasswordShow2 = () => {
        passwordType2 === "text"
            ? setPasswordType2("password")
            : setPasswordType2("text");
    };

    const onSignup = (values, { setSubmitting }) => {
        registerUser(values)
            .then(() => {
                setSubmitting(false);
                setSignedUp(true);
            })
            .catch(({ data: errors }) => {
                setSubmitting(false);

                if (errors) {
                    addToast({
                        type: "error",
                        title: "Error",
                        text: Object.values(errors)[0][0],
                    });
                }
            });
    };

    return (
        <>
            <OnboardingContainer classes="ic-login-container">
                <div className="container-fluid ic-signup-block">
                    <div className="row">

                        <div className="col-sm-12 col-md-6 ic-container-left p-0">
                            <div className="ic-image">
                                <img src={LoginImage} alt="A graphic showing three people with different abilities." />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 ic-container-right">
                            <header className="ic-header">
                                <div className="ic-logo">
                                    <img src={ImInclusive} style={{ width: 200 }} alt="Inclusive Logo" />
                                </div>
                            </header>
                            <aside>
                                <div className="ic-signup-wrapper">
                                    {!signedUp ? <h1 role="heading" className="pt-3 main-heading">Create an account</h1> : null}
                                    <div className="ic-form-wrapper">
                                        {signedUp ? (
                                            <>
                                                <div className="send-icon-container">
                                                <img src={SendIcon} alt="send icon icon" className="send-icon"/>
                                                </div>
                                                <h1 role="heading" className="main-heading">Account Created</h1>
                                                <h2 role="sub-heading" className="sub-heading pb-5 mb-5">Congratulations! Your account has been successfully created. An email has been sent to you. Please follow the provided instructions to activate your account.</h2>
                                            </>
                                        ) : (
                                            <Formik
                                                initialValues={{
                                                    user_type: "I",
                                                    first_name: "",
                                                    last_name: "",
                                                    email: "",
                                                    password1: "",
                                                    password2: "",
                                                }}
                                                validationSchema={signupSchema}
                                                onSubmit={onSignup}
                                            >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    errors,
                                                    touched,
                                                    isSubmitting,
                                                }) => (
                                                    <Form onSubmit={handleSubmit} noValidate>
                                                        <Form.Row style={{ marginRight: 1, marginLeft: 1 }}>

                                                            <Form.Group as={Col} md={6}>
                                                                <Form.Label htmlFor="firstName">First name *</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="firstName"
                                                                    name="first_name"
                                                                    placeholder="Enter first name"
                                                                    value={values.first_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    aria-label="First Name"
                                                                    aria-required="true"
                                                                    aria-invalid={touched.first_name && errors.first_name ? true : false}
                                                                    aria-describedby={touched.first_name && errors.first_name ? "first-name-error" : ""}
                                                                    ref={errorFieldFirstName}
                                                                    isInvalid={touched.first_name && errors.first_name}
                                                                />
                                                                <Form.Control.Feedback type="invalid" id="first-name-error" aria-live="polite">
                                                                    {errors.first_name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={6}>
                                                                <Form.Label htmlFor="lastName">Last name *</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="lastName"
                                                                    name="last_name"
                                                                    placeholder="Enter last name"
                                                                    value={values.last_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    aria-label="Last Name"
                                                                    aria-required="true"
                                                                    aria-invalid={touched.last_name && errors.last_name ? true : false}
                                                                    aria-describedby={touched.last_name && errors.last_name ? "last-name-error" : ""}
                                                                    ref={errorFieldLastName}
                                                                    isInvalid={touched.last_name && errors.last_name}
                                                                />
                                                                <Form.Control.Feedback type="invalid" id="last-name-error" aria-live="polite">
                                                                    {errors.last_name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>

                                                        <Form.Group>
                                                            <Form.Label htmlFor="emailAddress">Email Address *</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                id="emailAddress"
                                                                name="email"
                                                                placeholder="Enter email address"
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                aria-label="Email Address"
                                                                aria-required="true"
                                                                aria-invalid={touched.email && errors.email ? true : false}
                                                                aria-describedby={touched.email && errors.email ? "email-error" : ""}
                                                                ref={errorFieldEmail}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.email && errors.email}
                                                            />
                                                            <Form.Control.Feedback type="invalid" id="email-error" aria-live="polite">
                                                                {errors.email}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group style={{ position: 'relative', marginBottom: 0 }}>
                                                            <Form.Label htmlFor="passwordOne">Create Password *</Form.Label>
                                                            <Form.Control
                                                                type={passwordType1}
                                                                id="passwordOne"
                                                                name="password1"
                                                                placeholder="Enter password"
                                                                value={values.password1}
                                                                onChange={handleChange}
                                                                aria-label="Password"
                                                                aria-required="true"
                                                                aria-describedby={touched.password1 && errors.password1 ?
                                                                    "password1-error validation-info-1 validation-info-2 validation-info-3 validation-info-4" : "validation-info-1 validation-info-2 validation-info-3 validation-info-4"}
                                                                aria-invalid={touched.password1 && errors.password1 ? true : false}
                                                                ref={errorFieldPassword}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.password1 && errors.password1}
                                                                style={{ paddingRight: '2.5rem' }}
                                                            />
                                                            <span className="password__show" role="button" tabIndex="0" aria-pressed={passwordType1 === "text" ? true : false} onClick={handlePasswordShow1} onKeyUp={(event) => event.key === "Enter" ? handlePasswordShow1() : ""}>
                                                                {passwordType1 === "text" ? <span className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility</span> : <span className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility_off</span>}
                                                            </span>
                                                            <Form.Control.Feedback type="invalid" id="password1-error" aria-live="polite">
                                                                {errors.password1}
                                                            </Form.Control.Feedback>
                                                            <PasswordValidator password={values.password1} />
                                                        </Form.Group>

                                                        <Form.Group style={{ position: 'relative' }}>
                                                            <Form.Label htmlFor="passwordTwo">Confirm Password *</Form.Label>
                                                            <Form.Control
                                                                type={passwordType2}
                                                                id="passwordTwo"
                                                                name="password2"
                                                                placeholder="Re-enter password"
                                                                value={values.password2}
                                                                onChange={handleChange}
                                                                aria-label="Re-enter Password"
                                                                aria-required="true"
                                                                aria-describedby={touched.password2 && errors.password2 ? "password2-error" : ""}
                                                                aria-invalid={touched.password2 && errors.password2 ? true : false}
                                                                ref={errorFieldConfirmPassword}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.password2 && errors.password2}
                                                                style={{ paddingRight: '2.5rem' }}
                                                            />
                                                            <span className="password__show" role="button" tabIndex="0" aria-pressed={passwordType2 === "text" ? true : false} onClick={handlePasswordShow2} onKeyUp={(event) => event.key === "Enter" ? handlePasswordShow2() : ""}>
                                                                {passwordType2 === "text" ? <span className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility</span> : <span className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility_off</span>}
                                                            </span>
                                                            <Form.Control.Feedback type="invalid" id="password2-error" aria-live="polite">
                                                                {errors.password2}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="privacyCheckbox" className="pt-3">
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="displyInline"
                                                                checked={PrivacyCheckbox}
                                                                onChange={togglePrivacyChange}
                                                                onKeyUp={(event) => event.key === "Enter" ? togglePrivacyChange() : ""}
                                                                name="privaycondtions"
                                                                label="I agree to the "
                                                                aria-label="I agree to the privacy policy."
                                                                aria-required="true"
                                                            />
                                                            <Link
                                                                className="form-check-link"
                                                                to="/privacypolicy"
                                                            >
                                                                privacy policy
                                                            </Link>
                                                            .
                                                        </Form.Group>
                                                        <Form.Group controlId="Terms&conditionCheckbox">
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="displyInline"
                                                                checked={agreementCheckbox}
                                                                onChange={toggleAgreementChange}
                                                                onKeyUp={(event) => event.key === "Enter" ? toggleAgreementChange() : ""}
                                                                name="agreecondtions"
                                                                label="I agree to the "
                                                                aria-label="I agree to the terms and conditions."
                                                                aria-required="true"
                                                            />
                                                            <Link
                                                                className="form-check-link"
                                                                to="/termsandconditions"
                                                            >
                                                                terms and conditions
                                                            </Link>
                                                            .
                                                        </Form.Group>

                                                            {/* <p className="ic-form-badge pt-4">
                                                                <span className="text-lightgray">
                                                                    By signing up, you agree to our 
                                                                </span>
                                                                &nbsp;
                                                                <Link className="login-text" to="/privacypolicy" aria-label="Already have an account? Login.">
                                                                    <b>Privacy Policy</b>
                                                                </Link>
                                                                &nbsp;
                                                                <span className="text-lightgray">
                                                                    and
                                                                </span>
                                                                &nbsp;
                                                                <Link className="login-text" to="/termsandconditions" aria-label="Already have an account? Login.">
                                                                    <b>Terms & Conditions</b>
                                                                </Link>
                                                                .
                                                            </p> */}

                                                        <Form.Group>
                                                            <Button
                                                                className="signup-button"
                                                                loading={isSubmitting}
                                                                submit
                                                                aria-label="Submit"
                                                                type="submit"
                                                                id="SignupBtn"
                                                                disabled={disableBtn}
                                                                onClick={() => touched.first_name && errors.first_name ? errorFieldFirstName.current.focus() :
                                                                    touched.last_name && errors.last_name ? errorFieldLastName.current.focus() :
                                                                        touched.email && errors.email ? errorFieldEmail.current.focus() :
                                                                            touched.password1 && errors.password1 ? errorFieldPassword.current.focus() :
                                                                                touched.password2 && errors.password2 ? errorFieldConfirmPassword.current.focus() :
                                                                                    {}}
                                                            >
                                                                Signup
                                                            </Button>
                                                            <p className="ic-form-badge">
                                                                <span className="text-lightgray">
                                                                    Already have an account?
                                                                </span>
                                                                &nbsp;
                                                                <Link className="login-text" to="/auth/login" aria-label="Already have an account? Login.">
                                                                    <strong>Login</strong>
                                                                </Link>
                                                            </p>
                                                        </Form.Group>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                    </div>
                                </div>
                                <div className="footer-container pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <span className="footer-link">
                                            <Link to="/privacypolicy" rel="nofollow">
                                                Privacy Policy
                                            </Link>
                                        </span>
                                        <span className="footer-link px-2">•</span>
                                        <span className="footer-link">
                                            <Link to="/termsandconditions" rel="nofollow">
                                                Terms and Conditions
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center pt-2">
                                        <span className="copyright-text">© {format(new Date(), "yyyy")} ImInclusive</span>
                                    </div>
                                </div>

                            </aside>
                        </div>

                    </div>
                </div>
            </OnboardingContainer>

            <Modal show={show} onHide={() => setShow(false)} aria-labelledby="Open App Modal" dialogClassName="open-app-modal">
                <Modal.Body>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="material-icons mr-1 open-app-icon">smartphone</i>
                        <h3 className="open-app-heading">Inclusive works better if you switch to our app</h3>
                    </div>
                    <div className="open-app-link" onClick={() => window.open('https://inclusiveapp.page.link/openapp', "_self")}>
                        Open in App
                    </div>
                    <Button className="open-app-text" onClick={() => setShow(false)}>Continue with mobile web</Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default connect(null, {
    registerUser,
    addToast,
})(Signup);

// {/* <Form.Row>
//   <Form.Label>User Type</Form.Label>
//   <div className="form-group ic-form-check-wrapper d-flex">
//     <div className="ic-form-check ic-checkbox-person">
//       <Form.Label className="ic-form-check-label isChecked" htmlFor="usertype-person-of-determination">
//       <Form.Control className="ic-form-check-input" type="checkbox" name="usertype-person" id="usertype-person-of-determination" value="option1" checked />
//         Person of determination
//         <span className="checkmark"></span>
//       </Form.Label>
//     </div>
//     <div className="ic-form-check ic-checkbox-supporter">
//       <label className="ic-form-check-label" htmlFor="usertype-supporter">
//       <Form.Control className="ic-form-check-input" type="checkbox" name="usertype-supporter" id="usertype-supporter" value="option2" />
//         Supporter of Inclusion
//         <span className="checkmark"></span>
//       </label>
//     </div>
//   </div>
// </Form.Row> */}
