import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Colors } from "../styles/constants";

export const IconButton = ({
  icon,
  onClick,
  className,
  loading,
  size,
  color,
  hoverColor,
}) => (
  <StyledIconButton
    hoverColor={hoverColor}
    color={color}
    className={className}
    onClick={onClick}
  >
    {size ? (
      <FontAwesomeIcon
        size={size}
        icon={!loading ? icon : "spinner"}
        spin={loading}
      />
    ) : (
      <FontAwesomeIcon icon={!loading ? icon : "spinner"} spin={loading} />
    )}
  </StyledIconButton>
);

const StyledIconButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;

  width: 30px;
  height: 30px;
  border-radius: 50%;

  & + & {
    margin-left: 0.5rem;
  }

  > svg {
    color: ${Colors.primary};

    ${(props) =>
      props.color &&
      css`
        color: ${Colors[props.color]};
      `};
  }

  background-color: transparent;
  transition: 250ms ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    ${(props) =>
      props.hoverColor &&
      css`
        > svg {
          color: ${Colors[props.hoverColor]};
        }
      `};
  }
`;

const Button = ({
  id,
  className,
  outline,
  uppercase,
  onClick,
  disabled,
  margin,
  centered,
  children,
  fullwidth,
  loading,
  minwidth,
  negative,
  type,
  primary,
  submit,
}) => {
  const content = !loading ? children : <FontAwesomeIcon icon="spinner" spin />;

  return (
    <StyledButton
      id={id}
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
      outline={outline ? 1 : 0}
      uppercase={uppercase ? 1 : 0}
      margin={margin}
      centered={centered ? 1 : 0}
      fullwidth={fullwidth}
      minwidth={minwidth}
      negative={negative ? 1 : 0}
      type={type}
      primary={primary ? 1 : 0}
      submit={submit ? 1 : 0}
    >
      {content}
    </StyledButton>
  );
};

Button.propTypes = {
  outline: PropTypes.bool,
  uppercase: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  centered: PropTypes.bool,
  children: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  minWidth: PropTypes.string,
  negative: PropTypes.bool,
  type: PropTypes.string,
  primary: PropTypes.bool,
  submit: PropTypes.bool,
};

Button.defaultProps = {
  outline: false,
  uppercase: false,
  disabled: false,
  margin: "0",
  centered: false,
  fullWidth: false,
  loading: false,
  minWidth: "auto",
  negative: false,
  type: "button",
  primary: false,
  submit: false,
};

export default Button;

export const LinkButton = ({
  outline,
  uppercase,
  onClick,
  disabled,
  margin,
  centered,
  fullWidth,
  children,
  path,
}) => (
  <StyledButton
    primary
    disabled={disabled}
    onClick={onClick}
    outline={outline}
    uppercase={uppercase}
    margin={margin}
    centered={centered}
    fullWidth={fullWidth}
  >
    <ButtonLink to={path}>{children}</ButtonLink>
  </StyledButton>
);

LinkButton.propTypes = {
  outline: PropTypes.bool,
  uppercase: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  centered: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

LinkButton.defaultProps = {
  outline: false,
  uppercase: false,
  disabled: false,
  margin: "0",
  centered: false,
  fullWidth: false,
};

export const StyledButton = styled(BootstrapButton)`
  max-width: 850px;
  width: 100%;
  min-width: 200px;
  padding: 45px 5px 45px 120px;
  border-radius: 11.3px;
  box-shadow: 0 0 73px 4px rgba(0, 0, 0, 0.06);
  font-size: 1.6rem;
  color: #ffffff;
  text-align: left;
  border: none;
  border: solid 2px transparent;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-align: center;

  @media (max-width: 480px) {
    //padding: 32px 5px 32px 90px;
    font-size: 1rem;
  }

  span {
    background: #ffffff;
    padding: 12px 0 12px;
    border-radius: 60px;
    height: 65px;
    width: 100%;
    max-width: 65px;
    position: absolute;
    margin-top: -10px;
    vertical-align: middle;
    left: 2rem;
    top: 2.7rem;
    text-align: center;

    @media (max-width: 480px) {
      max-width: 45px;
      height: 45px;
      top: 2rem;
    }
  }

  img {
    max-width: 32px;

    @media (max-width: 480px) {
      max-width: 22px;
    }
  }

  &:hover {
    box-shadow: 0px 4px 10px 0px rgba(39, 50, 82, 0.6);
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
  }

  &:focus {
    outline: 0 !important;
  }

  /* background-image: linear-gradient(263deg, #ee6f78 40%, #B11030 70%);
  background-image: -o-linear-gradient(263deg, #ee6f78 40%, #B11030 70%); */
  ${(props) =>
    props.primary &&
    `
    position: relative;
    background: ${props.disabled ? "grey" : "#B11030"};
    border-radius: 0.4rem;
    width: 100%;
    max-width: 850px;
    color: #ffffff !important;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 15px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding: 16px;

    &:hover,
    &:active {
      color: #ffffff;
      background: ${props.disabled ? "grey" : "#B11030"} !important;
      box-shadow: 0px 4px 10px 0px rgba(39, 50, 82, 0.6);
      border-color: transparent !important;
      -webkit-transform: translateY(-0.25em);
      transform: translateY(-0.25em);
    }

    &:focus {
      background: ${props.disabled ? "grey" : "#B11030"} !important;
      border-color: black !important;
    }
  `};

  ${(props) =>
    props.white &&
    `
    position: relative;
    color: #000;

    span {
      background-color: #f8f7fc;
      top: 50%;
      margin-top: -35px;
    }
  `};

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    background: grey !important;
    border-color: transparent !important;
  `};
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  color: white;

  &:active,
  &:visited,
  &:hover {
    text-decoration: none;
  }
`;

export const OutlineButton = styled(Button)`
  background-color: transparent;
  border: 1px solid #f44653;
  color: #f44653;

  ${(props) =>
    props.disabled &&
    css`
      color: palevioletred;
      border-color: palevioletred;
    `}
`;

export const TextButton = ({ children, margin, padding, block, onClick }) => (
  <StyledTextButton
    margin={margin}
    padding={padding}
    block={block}
    onClick={onClick}
  >
    {children}
  </StyledTextButton>
);

const StyledTextButton = styled.button`
  background-color: transparent;
  border: none;
  color: #f44653;
  padding: 1.5rem;
  text-align: center;
  font-size: 1.8rem;
  cursor: pointer;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};

  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `};
`;
