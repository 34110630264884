import React from 'react';
import styled from "styled-components";
import { NavLink as Link, useLocation, useRouteMatch } from "react-router-dom";

const NavProfile = ({ isOwner, name }) => {
    const { pathname: path } = useLocation();
    const { url } = useRouteMatch();;

    return (
        <StyledContainer>
                
                <div className={`nav-profile px-0 py-2 mt-3 ${
                  path === `${url}/story` ? "active" : ""}`}>
                  <Link to={`${url}/story`} className="nav-link">
                      {isOwner ? "My " : `${name.charAt(0).toUpperCase() + name.slice(1)}'s `} Story
                  </Link>
                </div>
                
                <div className={`nav-profile px-0 py-2 ${
                  path === `${url}/conversations` ? "active" : ""}`}>
                  <Link to={`${url}/conversations`} className="nav-link">
                      {isOwner ? "My " : ""} Conversations
                  </Link>
                </div>

                {isOwner ? 
                <div className={`nav-profile px-0 py-2 ${
                   path === `${url}/opportunities` ? "active" : ""}`}>
                   <Link to={`${url}/opportunities`} className="nav-link">
                       {isOwner ? "My " : `${name}'s `} Opportunities
                   </Link>
                </div>
                : ""}

                <div className={`nav-profile px-0 py-2 ${
                    path === `${url}/portfolio` ? "active" : ""}`} style={{ borderRadius: '0 0 14px 14px' }}>
                  <Link to={`${url}/portfolio`} className="nav-link">
                      {isOwner ? "My " : ""} Portfolio
                  </Link>
                </div>
               
               {/* {isOwner ? 
               <div className={`nav-profile px-0 py-2 ${
                  path === `${url}/events` ? "active" : ""}`}>
                  <Link to={`${url}/events`} className="nav-link">
                      {isOwner ? "My " : `${name}'s `} Events
                  </Link>
               </div>
               : ""} */}

                {/* <div className={`nav-profile px-0 py-2 ${
                  path === `${url}/followers` ? "active" : ""}`}>
                  <Link
                      to={`${url}/followers`}
                      className="nav-link"
                      href="followers.html"
                    >
                      Followers
                    </Link>
                </div>

                <div className={`nav-profile px-0 pt-2 ${
                      path === `${url}/following` ? "active" : ""}`}>
                   <Link
                      to={`${url}/following`}
                      className="nav-link"
                      href="followers.html"
                    >
                      Following
                    </Link>
                </div> */}
        </StyledContainer>
    )
}
export default NavProfile;

const StyledContainer = styled.div`

.nav-profile {
  border-top: 1px solid #ABABAB;
}

.nav-profile a {
  color: #5F5F5F;
  font-weight: bold;
  text-decoration: none;
 
    &:hover {
    color: #3F0E40;
    text-decoration: none;
  }
}

.nav-profile.active {
  border-left: 3px solid #3F0E40;
  background-color: #f4f4f4;
}

.nav-link.active {
  color: #3F0E40;
}

`