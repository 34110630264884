import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { LoaderWithText } from '../../../components/Loader';

import { getBlocked, unBlockProfile,getBlockProfiles,unBlockUser } from '../../../api';

const BlockUser = ({
  blockUser,
  owns,
  unblockLoading,
  onUnblock
}) => 
{
  
  (blockUser)
  if(blockUser.blocked.organisation){
    return(
      <div className="list-user">
        <div className="user-info d-flex flex-row flex-wrap align-items-center">
          <div className="picture">
            <Link to={`/o/${blockUser.blocked.organisation.slug}`} role="button"><img src={blockUser.blocked.organisation.organization_logo} alt="profile picture" title="profile picture" /></Link>
          </div>
          <div className="user-name-location d-flex flex-column col">
            <div className="name">
              <Link to={`/o/${blockUser.blocked.organisation.slug}`} role="button">{`${blockUser.blocked.organisation.organization_title}`}</Link>
            </div>
            <div className="user-type">
              <span className="type-of-user">Organization</span>
            </div>
            <div className="ic-feed-name">
              {blockUser.blocked.organisation.organization_tagline.substring(0, 45)}
            </div>
          </div>
          {
            owns ? <button type="button" onClick={onUnblock.bind(this, blockUser)} className="modal-submit-button"> {unblockLoading ? <i className="fas fa-spinner fa-spin" /> : "Unblock"}</button> : null
          }
  
        </div>
      </div>
    );
  }
  return(
    <div className="list-user">
      <div className="user-info d-flex flex-row flex-wrap align-items-center">
        <div className="picture">
          <Link to={`/p/${blockUser.blocked.person.slug}`} role="button"><img src={blockUser.blocked.person.person_display_picture} alt="profile picture" title="profile picture" /></Link>
        </div>
        <div className="user-name-location d-flex flex-column col">
          <div className="name">
            <Link to={`/p/${blockUser.blocked.person.slug}`} role="button">{`${blockUser.blocked.person.person_first_name} ${blockUser.blocked.person.person_last_name}`}</Link>
          </div>
          <div className="user-type">
            <span className="type-of-user">{blockUser.blocked.person.identifies_as}</span>
          </div>
          <div className="ic-feed-name">
            {blockUser.blocked.person.person_headline.substring(0, 45)}
          </div>
        </div>
        {
          owns ? <button type="button" onClick={onUnblock.bind(this, blockUser)} className="modal-submit-button"> {unblockLoading ? <i className="fas fa-spinner fa-spin" /> : "Unblock"}</button> : null
        }

      </div>
    </div>
  );
}



const BlockedUsers = ({
  profile,
  isOwner,
}) => {
  const [blockUsers, setblockUsers] = useState([]);
  const [unblockLoading , setUnblockingLoading]=useState(false)
  const [loadingblockedUsers , setLoadingblockedUsers]=useState(false)



  useEffect(() => {
    let unmounted = false;
    setLoadingblockedUsers(true);
    if (profile) {
      getBlockProfiles(profile.owner_id)
      .then(({ data }) => {
        if (!unmounted) {       
          setblockUsers(data);
          setLoadingblockedUsers(false);
        }
      })
      .catch((error) => {
        
        setLoadingblockedUsers(false);
      });
      // getBlocked(profile.id)
      //   .then(({ data }) => {
      //     setblockUsers(data);
      //     setLoadingblockedUsers(false);
      //   })
      //   .catch((error) => {
      //     setLoadingblockedUsers(false);
      //   });
    }
    return () => { unmounted = true }; 
  }, [profile]);

  const unblockPerson = (follow_item) => {
    setUnblockingLoading(true)
    
    unBlockUser(follow_item.blocker.id,follow_item.blocked.id)
      .then(() => {
        
        let list = blockUsers.filter(x => {
          return x.id != follow_item.id;
        })
        setblockUsers(list);
        setUnblockingLoading(false);
      })
      .catch(() => {
        setUnblockingLoading(false);
      });


    // unBlockProfile(follow_item.blocked.id,follow_item.blocker.id)
    //   .then(() => {
    //     let list = blockUsers.filter(x => {
    //       return x.id != follow_item.id;
    //     })
    //     setblockUsers(list);
    //     setUnblockingLoading(false);
    //   })
    //   .catch(() => {
    //     setUnblockingLoading(false);
    //   });
  };

  if (loadingblockedUsers) {
    return <LoaderWithText margin="50px 0 0" text="Please wait..." />;
  }

  if(unblockLoading){
     return <StyledBlockUser className="ic-dashview d-flex flex-row flex-wrap justify-content-between">
      <div className="dashview-followers">
        <div className="follower-list">
        <LoaderWithText margin="50px 0 0" text="Please wait..." />
        </div>
      </div>
    </StyledBlockUser>
  }

  return (
    <StyledBlockUser className="ic-dashview d-flex flex-row flex-wrap justify-content-between">
      <div className="dashview-followers">
        <div className="follower-list">
        <div className="d-flex align-items-center pb-3" >
            <h2 className="pb-0 mb-0 cardHeading">Blocked Users</h2>
          </div>
          {
            blockUsers.length ? blockUsers.map((blockUser) => <BlockUser key={blockUser.id} unblockLoading={unblockLoading} onUnblock={unblockPerson} owns={isOwner} blockUser={blockUser} />) : (
              isOwner ? <h3 className="text-red m-0 blocked-text">No Blocked Users.</h3> : <h3 className="text-red m-0 blocked-text">No Blocked Users.</h3>
            )
          }
        </div>
      </div>
    </StyledBlockUser>
  );
};

export default BlockedUsers;

const StyledBlockUser = styled.div`
  .dashview-followers {
    background-color: #ffffff;
    width: 100%;
    border-radius: 0.4rem;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border: 0;
    margin-top: 1.1rem;
    margin-bottom: 100px;
    padding: 25px 45px;
    @media(max-width: 480px) {
      padding: 15px 15px 0 15px;
      margin-bottom: 0;
    }
  }

  .blocked-text {
    border-top: solid 2px #dadbe2;
    padding-top: 1.5rem;
  }

  .cardHeading {
    font-weight: bold;
    font-size: 26px;
    line-height: 24px;
    color: #000000;
  }

  .dashview-followers .follower-list .list-user:not(:first-child) {
    border-top: solid 1px #dadbe2;
  }

  .dashview-followers .follower-list .list-user:first-child {
    padding-top: 15px;
  }

  .dashview-followers .follower-list .list-user {
    padding: 15px 0;
  }

  .dashview-followers .follower-list .list-user .user-info .picture {
    width: 11%
  }

  @media (min-width: 993px) and (max-width: 1340px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 15%;
    }
  }

  @media (max-width: 992px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 20%;
    }
  }

  @media (max-width: 576px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 18%;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .picture a img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    @media(max-width: 480px) {
      width: 60px;
      height: 60px;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .user-name-location .name a {
    color: #000;
    font-size: 1.1rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    @media(max-width: 480px) {
      font-size: 1rem;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .user-name-location .user-type, .ic-feed-name {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 500;
    @media(max-width: 480px) {
      font-size: 0.9rem;
    }
  }

  .ic-feed-name {
    @media(max-width: 480px) {
      display: none;
    }
  }

  .modal-submit-button {
    @media(max-width: 480px) {
      font-size: 14px;
      padding: 0.8rem;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .user-name-location .location {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 500;
  }

  .dashview-followers .follower-list .list-user .user-info .user-name-location .location .log-time {
    font-family: 'Inter', sans-serif;
  }

  .dashview-followers .follower-list .list-user .user-info .user-settings-option {
    width: 3%;
    position: relative;
  }

  .dashview-followers .follower-list .list-user .user-info .user-settings-option a {
    font-size: 2em;
  }

  .dashview-followers .follower-list .list-user .user-info .user-settings-option .dropdown-menu {
    -webkit-transform: translate3d(-156px, 45px, 0px) !important;
    transform: translate3d(-156px, 45px, 0px) !important;
    border-color: rgba(237, 234, 246, 0.5);
    box-shadow: 2px 3px 0px 0px rgba(43, 43, 43, 0.2);
  }

  .dashview-followers .follower-list .list-user .user-info .user-settings-option .dropdown-menu .dropdown-item {
    font-size: .8em;
  }

  .dashview-followers .follower-list .list-user .user-info .user-settings-option .dropdown-menu:after {
    width: 0;
    height: 0;
    position: absolute;
    top: 0%;
    content: '';
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(43, 43, 43, 0.5);
    right: 12px;
  }
`;
