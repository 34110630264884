const initialState = {
  selectedImage: null,
};

export default (state = initialState, action) => {
  if (action.type === 'SET_SELECTED_IMAGE') {
    return {
      ...state,
      selectedImage: action.image,
    };
  }

  return state;
};

export const setSelectedImage = (image) => ({
  type: 'SET_SELECTED_IMAGE',
  image,
});
