import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_translation from './translations/en.json';
import ar_translation from './translations/ar.json';
import ur_translation from './translations/ur.json';

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources: {
        en: {
            translation: en_translation,
        },
        ar: {
            translation: ar_translation,
        },
        ur: {
            translation: ur_translation,
        },
    },
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: 'en',
    debug: false,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
