import { getUnreadNotifications, getReadNotifications, markNotificationsRead } from '../../api';

import { addToast } from '../toast';

const initialState = {
  list: [],
  loading: false,
};

export default (state = initialState, action) => {
  if (action.type === 'SET_NOTIFICATIONS') {
    return {
      ...state,
      list: action.list,
    };
  }

  if (action.type === 'SET_NOTIFICATIONS_LOADING') {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (action.type === 'SET_NOTIFICATIONS_READ') {
    return {
      ...state,
      list: state.list.map((notification) => ({ ...notification, isNew: false })),
    };
  }

  return state;
};

export const setNotifications = (list) => ({
  type: 'SET_NOTIFICATIONS',
  list,
});

export const markRead = () => ({
  type: 'SET_NOTIFICATIONS_READ',
});

export const setNotificationsLoading = (loading) => ({
  type: 'SET_NOTIFICATIONS_LOADING',
  loading,
});

export const setNotificationsRead = () => (dispatch) => {
  dispatch(markRead());
  markNotificationsRead();
};

export const getNotifications = () => (dispatch) => {
  dispatch(setNotificationsLoading(true));

  let notifications = [];
  const unread = getUnreadNotifications();
  const read = getReadNotifications();

  Promise.all([unread, read])
    .then(([{ data: { notifications: unreadNotifications } }, { data: { notifications: readNotifications } }]) => {
      notifications = unreadNotifications.map((notification) => ({ ...notification, isNew: true }));
      notifications = notifications.concat(readNotifications);

      dispatch(setNotifications(notifications));

      setNotificationsLoading(false);
    })
    .catch(() => {
      dispatch(
        addToast({
          title: 'Error',
          text: 'Could not get notifications at this time.',
          type: 'error',
        }),
      );

      setNotificationsLoading(false);
    });
};
