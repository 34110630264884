/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";

import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { logoutUser, switchProfile } from "../../../../../redux/user";

export function QuickUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [switchUser, setUser] = useState({});
  const [switchLoading, setSwitchLoading] = useState(false);
  const [name, setName] = useState("");
  const { activeProfile, activeProfileMeta, availableProfiles } = useSelector(
    (state) => state.user
  );
  const logoutClick = () => {
    dispatch(logoutUser());
  };

  let orgProfiles = [];

  if (availableProfiles) {
    orgProfiles = availableProfiles.org;
  }

  const switchToprofile = () => {
    setSwitchLoading(true);
    dispatch(switchProfile(switchUser)).then(() => {
      setSwitchLoading(true);
      history.push("/");
      setShow(false);
      setSwitchLoading(false);
      setName("");
    });
  };

  const switchUserProfile = (user) => {
    const isOrg = "organization_title" in user;
    isOrg ? setName(user.organization_title) : setName(user.full_name);
    setShow(true);
    setUser(user);
    setExpanded(false);
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-5">
      <div className="offcanvas-header d-flex align-items-start justify-content-between pb-1">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-100 mr-5">
            {activeProfile && (
              <div
                className="symbol-label"
                style={{
                  backgroundImage: `url(${
                    activeProfileMeta.type === "org"
                      ? activeProfile.organization_logo
                      : activeProfile.person_display_picture
                  })`,
                  border: "2px solid #f3f3f3",
                  backgroundColor: '#fff'
                }}
              />
            )}
            {/* <i className="symbol-badge bg-success"/> */}
          </div>
          <div className="d-flex flex-column">
            <h4
              className="font-weight-bold mb-2"
              style={{ fontSize: "1.8rem" }}
            >
              {activeProfile
                ? activeProfileMeta.type === "org"
                  ? activeProfile.organization_title
                  : `${activeProfile.person_first_name} ${activeProfile.person_last_name} `
                : ""}
            </h4>
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              <span>Sign out</span>
            </button>
          </div>
        </div>

        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <Link to="/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-50 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <i className="material-icons" style={{ fontSize: "24px" }}>
                      account_circle
                    </i>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div
                  className="font-weight-bold"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "1.3rem",
                  }}
                >
                  My Profile
                </div>
                <div className="text-muted">Manage Profile Photo and Info.</div>
              </div>
            </div>
          </Link>

          <Link to="/org/account" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-50 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <i className="material-icons" style={{ fontSize: "24px" }}>
                      settings
                    </i>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div
                  className="font-weight-bold"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "1.3rem",
                  }}
                >
                  Account Settings
                </div>
                <div className="text-muted">
                  Manage Password and Account Info.
                </div>
              </div>
            </div>
          </Link>

          {/* {activeProfile && orgProfiles.length ? activeProfileMeta.type === "org" ? 
            <Dropdown.Item onClick={() => switchUserProfile(availableProfiles.individual)} role="link" aria-label="Switch Profile">
              <i className="material-icons md-24 mr-2">swap_horizontal_circle</i>{availableProfiles.individual.full_name}
            </Dropdown.Item> 
            : <Dropdown.Item onClick={() => switchUserProfile(orgProfiles[0])} role="link" aria-label="Switch Profile"><i className="material-icons md-24 mr-2">swap_horizontal_circle</i>{orgProfiles[0].organization_title}</Dropdown.Item> 
            : <Dropdown.Item onClick={()=>{setShowNew(true)
            setExpanded(false)}} role="link" aria-label="Create Organization Profile"><i className="material-icons md-24 mr-2">add_circle</i>Create Organization</Dropdown.Item>
            } */}
          {activeProfile && orgProfiles.length ? (
            activeProfileMeta.type === "org" ? (
              <span
                style={{ cursor: "pointer" }}
                className="navi-item"
                onClick={() => switchUserProfile(availableProfiles.individual)}
              >
                <div className="navi-link">
                  <div className="symbol symbol-50 bg-light mr-3">
                    <div className="symbol-label">
                      <span className="svg-icon svg-icon-md svg-icon-warning">
                        <i
                          className="material-icons"
                          style={{ fontSize: "24px" }}
                        >
                          swap_horizontal_circle
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="navi-text">
                    <div
                      className="font-weight-bold"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontSize: "1.3rem",
                      }}
                    >
                      {availableProfiles.individual.full_name}
                    </div>
                    <div className="text-muted">
                      Switch to Alternate Profile.
                    </div>
                  </div>
                </div>
              </span>
            ) : (
              <span
                className="navi-item"
                onClick={() => switchUserProfile(orgProfiles[0])}
              >
                <div className="navi-link">
                  <div className="symbol symbol-50 bg-light mr-3">
                    <div className="symbol-label">
                      <span className="svg-icon svg-icon-md svg-icon-warning">
                        <i
                          className="material-icons"
                          style={{ fontSize: "24px" }}
                        >
                          swap_horizontal_circle
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="navi-text">
                    <div
                      className="font-weight-bold"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontSize: "1.3rem",
                      }}
                    >
                      {orgProfiles[0].organization_title}
                    </div>
                    <div className="text-muted">
                      Switch to Alternate Profile.
                    </div>
                  </div>
                </div>
              </span>
            )
          ) : (
            ""
          )}

          <Modal show={show} centered size="lg" onHide={() => setShow(false)}>
            <Modal.Header>Confirm Switch Profile</Modal.Header>
            <Modal.Body>
              <h4 style={{ fontSize: "1rem" }}>
                Are you sure you want to switch to {name} profile?
              </h4>
            </Modal.Body>
            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button
                      style={{ fontSize: "1rem" }}
                      variant="outline-secondary"
                      onClick={() => setShow(false)}
                      block
                      size="lg"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col
                    className="dashboard-main-wrapper mt-3 mt-md-0"
                    sm={12}
                    md={6}
                  >
                    <Button
                      variant="outline-primary"
                      className="d-none d-md-block d-lg-block"
                      onClick={switchToprofile}
                      block
                      size="lg"
                    >
                      {switchLoading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        `Switch to ${name} Profile`
                      )}
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="d-block d-sm-block d-md-none "
                      onClick={switchToprofile}
                      block
                      size="lg"
                    >
                      {switchLoading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        `Switch Profile`
                      )}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="separator separator-dashed my-7"></div>
      </div>
    </div>
  );
}
