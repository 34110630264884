import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import ReadMoreAndLess from "react-read-more-less";
import AddEditPortfolio from './AddEditPortfolio';
import { deleteProject } from '../../../api';
import { processDate } from '../../../common/utils';


const Portfolios = ({ portfolios, isOwner, refreshProfile, profileId, addToast }) => {

    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentPortfolio, setCurrentPortfolio] = useState({});

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
 
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const onDelete = (projectId) => {
        setIsDeleting(true);
        setTimeout(() => setIsDeleting(false), 2000);
        deleteProject(profileId, projectId)
            .then(() => {
                setIsDeleting(false);
                refreshProfile();
                addToast({
                    title: "Success",
                    text: `Project deleted successfully.`,
                    type: "success",
                });
            })
            .catch(() => {
                setIsDeleting(false);
                refreshProfile();
                addToast({
                    type: "error",
                    title: "Error",
                    text: "An error occurred, please try again.",
                });
            });
    };

    return (
        <Portfolio>
            <div className="user-ability user-data-wrapper">
                <div className="user-story-header justify-content-between" style={{ marginBottom: "1rem" }}>
                    <h2 style={{ display: 'flex' }}>Portfolio of Experience
                    {isOwner && (
                        <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Portfolio of Experience Tooltip" aria-pressed={showTooltip}>
                            <i className="material-icons help-icon">help_outline</i>
                        </Button>
                    )}
                    </h2>
                    <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                        {(props) => (
                            <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                                This is your personal portfolio of relevant work, your portal to show your skills to the world, with pictures!
                            </Tooltip>
                        )}
                    </Overlay>
                    {isOwner ?
                        <Button className="add-edit-delete-button" aria-label="Add Project" onClick={() => setIsAdding(true)}>
                            <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600' }}>add</i>
                        </Button>
                        : ""}
            </div>
            {portfolios && portfolios.length ? (
                <div className="user-portfolio-body">
                    {portfolios.map((portfolio, index) => (
                        <Row key={portfolio.id} className="portfolio-item d-flex align-items-center" style={{ paddingBottom: index + 1 === portfolios.length ? 0 : '1rem' }}>
                            {portfolio.pinned ? (
                                <div className="portfolio-pinned-icon">
                                    <i className="material-icons">push_pin</i>
                                </div>
                            ) : ""}
                            <Col xs={3} className="project-image pl-0">
                                <img src={portfolio.photo} alt={portfolio.title} title={portfolio.title} />
                            </Col>
                            <Col xs={7} sm={9} className="project-information px-0">
                                <div className="project-title">
                                    {portfolio.project_title}
                                </div>
                                <div className="project-date">
                                    {processDate(portfolio.start_date, "PP")} - {processDate(portfolio.end_date, "PP")}
                                </div>
                                <div className="project-description">
                                    <ReadMoreAndLess className="read-more-content my-story-link" charLimit={100} readMoreText="Read more" readLessText="Read less">
                                        {portfolio.project_description}
                                    </ReadMoreAndLess>
                                </div>
                            </Col>
                            {isOwner ? (
                                <div className="d-flex align-items-center portfolio-edit-delete">
                                    <Button className="add-edit-delete-button" aria-label="Edit Project" onClick={() => {
                                        setIsEditing(true);
                                        setCurrentPortfolio(portfolio);
                                    }}>
                                        <i className="material-icons" alt="Edit Icon">edit</i>
                                    </Button>
                                    <Button className="add-edit-delete-button pl-2" aria-label="Delete Project" onClick={() => onDelete(portfolio.id)}>
                                        <i className="material-icons" alt="Delete Icon">delete</i>
                                    </Button>
                                </div>
                            ) : ""}
                        </Row>
                    ))}
                </div>
            ) : (
                <h3 className="text-red pt-3" style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>You have not added any projects yet.</h3>
            )}
            </div>

            {isOwner ? (
                isAdding ?
                    <AddEditPortfolio isEditing={false} show={isAdding} closeDialog={() => setIsAdding(false)} onUpdated={refreshProfile} />
                    :
                    currentPortfolio && (
                        <AddEditPortfolio show={isEditing} isEditing projectId={currentPortfolio.id} closeDialog={() => setIsEditing(false)} onUpdated={refreshProfile}
                            currentValues={{
                                pinned: currentPortfolio.pinned,
                                project_title: currentPortfolio.project_title,
                                project_description: currentPortfolio.project_description,
                                start_date: currentPortfolio.start_date,
                                end_date: currentPortfolio.end_date,
                                project_url: currentPortfolio.project_url,
                                photo: currentPortfolio.photo
                            }} />)
            ) : ""}

        </Portfolio>
    )
}

export default connect(() => ({}), {
    addToast,
})(Portfolios);


const Portfolio = styled.div`

h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #000;
}
.portfolio-item {
    position: relative;
    padding: 1rem 0;
    margin: 0 1px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.portfolio-pinned-icon {
    color: #000;
    position: absolute;
    right: 0;
    top: 20px;
}
.project-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #c4c4c4;
}
.project-image.col-3 {
    flex: 0 0 18%;
}
.portfolio-edit-delete {
    position: absolute;
    right: 0;
}
.add-edit-delete-button {
    color: #000;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    &:active {
        background-color: transparent !important;
        border-color: #fff !important;
        color: #000 !important;
    }
}
.project-title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}
.project-date {
    font-size: 0.9rem;
    font-weight: 500;
    color: #000;
}
span.short-text {
    font-size: 0.9rem;
    color: #000;
    font-weight: 400;
}
.short-text .readMoreText {
    font-size: 0.9rem;
    padding: 0 0 0 0.5rem;
}
@media(max-width: 440px) {
    .project-image img {
        width: 70px;
        height: 70px;
    }
}
`