import React, { useState } from "react";
import { NavLink as Link, useRouteMatch } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavOrganization from "./NavOrganization";
import ImageUploader from "../../../components/image/ImageUploader";
import { updateOrganization } from "../../../api";

const CompanyBio = ({
  isOwner,
  refreshProfile,
  profile,
  addToast,
}) => {
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [photoUploading, setPhotoUploading] = useState(false);
  const { url } = useRouteMatch();
 
  const onPhotoChanged = (image) => {
    setPhotoUploading(true);
    const formData = new FormData();
    formData.append("organization_logo", image, image.name);
    formData.append("organization_email", profile.organization_email);
    formData.append("organization_industry", profile.organization_industry.id);
    formData.append("organization_website", profile.organization_website);
    updateOrganization(profile.slug, formData)
      .then(() => {
        setPhotoUploading(false);
        setShowImageDialog(false);
        refreshProfile();
        addToast({
          title: "Success",
          text: "Your logo has been uploaded.",
          type: "success",
        });
      })
      .catch(() => {
        setPhotoUploading(false);
        setPhotoUploading(false);
        addToast({
          title: "Error",
          text: "An error occurred.",
          type: "error",
        });
      });
  };
  return (
    <React.Fragment>
      <aside className="bioAside">
        <div className="left-sidebar">
          <div
            className="left-sidebar--wrapper sidebar-top"
          >
            <div className="container-fluid redBackGround d-flex flex-column ">
              <div className="user-picture--wrapper d-flex flex-column justify-content-center align-items-center">
                <div className="user-picture">
                  {isOwner ? (
                    <ImageUploader
                      isUploading={photoUploading}
                      onPhotoChanged={onPhotoChanged}
                      onClose={() => setShowImageDialog(false)}
                      show={showImageDialog}
                    />
                  ) : null}
                  <div className="user-picture--wrapper">
                    {isOwner ? (
                      <React.Fragment>
                        <Button
                          variant="danger"
                          className="upload-profile-picture"
                          onClick={() => setShowImageDialog(true)}
                          aria-label="Update profile photo"
                          style={{ backgroundColor: '#fff', color: '#5F5F5F', border: '2px solid #5F5F5F', padding: '0.25rem', borderRadius: '50%' }}
                        >
                          <i className="material-icons" style={{ color: '#5F5F5F' }}>edit</i>
                        </Button>
                        <a className="user-picture-btn" role="button" style={{ cursor: 'auto'}}>
                          <picture>
                            <img
                              src={profile && profile.organization_logo}
                              alt={`${profile.organization_title}'s Logo`}
                              title={`${profile.organization_title}'s Logo`}
                            />
                          </picture>
                        </a>
                      </React.Fragment>
                    ) : (
                      <a className="user-picture-btn" role="button" style={{ cursor: 'auto'}}>
                        <picture>
                          <img
                            src={profile && profile.organization_logo}
                            alt={`${profile.organization_title}'s Logo`}
                            title={`${profile.organization_title}'s Logo`}
                          />
                        </picture>
                      </a>
                    )}
                  </div>
                </div>
                <div className="comapnyName justify-content-center text-center">
                  {profile.organization_title}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mx-3 px-3">
                <ListGroup
                  variant="flush"
                  className="pb-0"
                >
                  <ListGroup.Item className="listgroupred pb-2 pt-0 px-0">
                    <Row className="d-flex align-items-center">
                      <Col
                        xs={2}
                        className="pl-0 pr-2 d-flex align-items-center justify-content-center"
                      >
                        <i className="material-icons" style={{ fontSize: '30px' }}>location_on</i>
                      </Col>
                      <Col xs={10} className="px-0">
                        {profile.organization_city &&
                          profile.organization_city.city_name}
                        ,{" "}
                        {profile.organization_city &&
                          profile.organization_city.city_country.country_name}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="listgroupred pb-2 pt-0 px-0">
                    <Row className="d-flex align-items-center">
                      <Col
                        xs={2}
                        className="pl-0 pr-2 d-flex align-items-center justify-content-center"
                      >
                        <i className="material-icons" style={{ fontSize: '30px' }}>people</i>
                      </Col>
                      <Col xs={10} className="px-0">
                        <Link
                          to={`${url}/followers`}
                          className="orgnav-link py-1"
                          href="followers.html"
                        >
                          {profile.follower_count} Followers{" "}
                        </Link>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="listgroupred pb-2 pt-0 px-0">
                    <Row className="d-flex align-items-center">
                      <Col
                        xs={2}
                        className="pl-0 pr-2 d-flex align-items-center justify-content-center"
                      >
                        <i className="material-icons" style={{ fontSize: '30px' }}>category</i>
                      </Col>
                      <Col xs={10} className="px-0">
                        {profile.organization_industry.industry_title}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="listgroupred pb-3 pt-0 px-0">
                    <Row className="d-flex align-items-center">
                      <Col
                        xs={2}
                        className="pl-0 pr-2 d-flex align-items-center justify-content-center"
                      >
                        <i className="material-icons" style={{ fontSize: '30px' }}>corporate_fare</i>
                      </Col>
                      <Col xs={10} className="px-0">
                        {profile.organization_size == "1"
                          ? "1 - 5 Employees"
                          : profile.organization_size == "2"
                            ? "5 - 20 Employees"
                            : profile.organization_size == "3"
                              ? "20 - 50 Employees"
                              : profile.organization_size == "4"
                                ? "50 - 250 Employees"
                                : profile.organization_size == "5"
                                  ? "250 - 1000 Employees"
                                  : profile.organization_size == "6"
                                    ? "1000 - 10000 Employees"
                                    : profile.organization_size == "7"
                                      ? "10000 - 100000 Employees"
                                      : profile.organization_size == "8"
                                        ? "100000+ Employees"
                                        : ""}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <NavOrganization
                isOwner={isOwner}
                name={profile.organization_title}
              />
              <div className="d-flex align-items-center justify-content-around py-2">
                <Button onClick={() => { window.open(`${profile.organization_website}`); }} className="org-action-button">
                  <i className="material-icons" style={{ fontSize: '30px' }}>language</i>
                </Button>
                <Button onClick={() => { window.open(`mailto:${profile.organization_email}`); }} className="org-action-button">
                  <i className="material-icons" style={{ fontSize: '30px' }}>email</i>
                </Button>
                <Button onClick={() => { window.open(`tel:${profile.organization_phone_number}`); }} className="org-action-button">
                  <i className="material-icons" style={{ fontSize: '30px' }}>phone</i>
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="left-sidebar--wrapper sidebar-bottom">
              <div className="d-flex align-items-center justify-content-around">
                <Button onClick={() => { window.open(`${profile.organization_website}`); }} className="org-action-button">
                  <i className="material-icons" style={{ fontSize: '30px' }}>language</i>
                </Button>
                <Button onClick={() => { window.open(`mailto:${profile.organization_email}`); }} className="org-action-button">
                  <i className="material-icons" style={{ fontSize: '30px' }}>email</i>
                </Button>
                <Button onClick={() => { window.open(`tel:${profile.organization_phone_number}`); }} className="org-action-button">
                  <i className="material-icons" style={{ fontSize: '30px' }}>phone</i>
                </Button>
              </div>
          </div> */}
        </div>
      </aside>
    </React.Fragment>
  );
};
const LeftSideBar = ({
  profile,
  addToast,
  isOwner,
  refreshProfile,
}) => {
  return (
    <section className="dashboard-left-sidebar">
      <CompanyBio
        addToast={addToast}
        profile={profile}
        isOwner={isOwner}
        refreshProfile={refreshProfile}
      />
    </section>
  );
};
export default LeftSideBar;