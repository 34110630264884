import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import ReadMoreAndLess from "react-read-more-less";
import AddEditInitiative from './AddEditInitiative';
import { LoaderWithText } from "../../../components/Loader";
import { getOrganizationInitiatives, deleteOrganizationInitiative } from '../../../api';
import { processDate } from '../../../common/utils';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


const Initiatives = ({ profile, isOwner, refreshProfile, orgSlug, addToast }) => {
    const { t } = useTranslation();
    const direction = i18n.dir();
  
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [initiatives, setInitiatives] = useState(profile.initiative);
    const [initiativesLoading, setInitiativesLoading] = useState(false);
    const [currentInitiative, setCurrentInitiative] = useState({});

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, []);

    useEffect(() => {
        let unmounted = false;
        setInitiativesLoading(true);
          if (!unmounted) {
            setInitiatives(profile.initiative);
            setInitiativesLoading(false);
          }
        return () => { unmounted = true }; 
    }, [profile]);

    const onDelete = (initiativeId) => {
      setIsDeleting(true);
      setTimeout(() => setIsDeleting(false), 2000);
      deleteOrganizationInitiative(orgSlug, initiativeId)
        .then(() => {
          setIsDeleting(false);
          refreshProfile();
          addToast({
            title: "Success",
            text: `Project deleted successfully.`,
            type: "success",
          });
        })
        .catch(() => {
          setIsDeleting(false); 
          refreshProfile();
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    };

if (initiativesLoading) {
   return <LoaderWithText padding="150px 100px" text="Please wait..." />;     
}   

  return (
    <Initiative>
        <section className="organizationCard d-flex flex-column">
            <div className="cardHeading d-flex align-items-center justify-content-between pb-3" style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}>
                <span style={{ display: 'flex' }}>
                    Latest Initiatives{" "}
                    {isOwner && (
                        <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Latest Initiatives Tooltip" aria-pressed={showTooltip}>
                            <i className="material-icons help-icon">help_outline</i>
                        </Button>
                    )}
                </span>
                <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                    {(props) => (
                        <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                            Share your organization's initiatives relevant to Diversity and Inclusion (D&I), build your D&I projects portfolio here.
                        </Tooltip>
                    )}
                </Overlay>
                {isOwner ?
                    <Button className="add-edit-delete-button" aria-label="Add Project" onClick={() => setIsAdding(true)}>
                        <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600' }}>add</i>
                    </Button>
                : ""}                
            </div>
        {initiatives && initiatives.length ? (
        <div className="org-initiative-body">       
            {initiatives.slice(0, 3).map((initiative, index) => (
                <Row key={initiative.id} className="initiative-item d-flex align-items-center" style={{ borderBottom: `${((index === 2) || (initiatives.length === index+1)) ? "none" : "1px solid #EFEFEF"}` }}>
                    <Col md={2} xs={3} className="project-image pl-0">
                        <img src={initiative.image} alt={initiative.initiative_title} title={initiative.initiative_title}/>
                    </Col>
                    <Col md={10} xs={9} className="project-information px-0">
                        <div className="project-title">
                            {initiative.initiative_title}
                        </div>
                        <div className="project-date">
                            {processDate(initiative.start_date, "PP")} - {processDate(initiative.end_date, "PP")}
                        </div>
                        <div className="project-description">
                            <ReadMoreAndLess className="read-more-content my-story-link" charLimit={120} readMoreText="Read more" readLessText="Read less">
                                {initiative.initiative_description}
                            </ReadMoreAndLess>
                        </div>
                    </Col>
                    {initiative.pinned ? (
                        <div className="initiative-pinned-icon">
                            <i className="material-icons">push_pin</i>
                        </div>
                    ) : ""}
                </Row>
            ))}
        </div>
        ) : (
            <h3 className="text-red pt-3">
                {isOwner ? 
                "You have not added any projects yet."
                : "There are no projects available right now, visit us soon again!"
                }
            </h3>
        )}

    {isOwner ? (
        isAdding ?  
        <AddEditInitiative isEditing={false} show={isAdding} closeDialog={() => setIsAdding(false)} onUpdated={refreshProfile}/>
        :
        currentInitiative && (
            <AddEditInitiative show={isEditing} isEditing initiativeId={currentInitiative.id} closeDialog={() => setIsEditing(false)} onUpdated={refreshProfile}
              currentValues={{
                pinned: currentInitiative.pinned,
                initiative_title: currentInitiative.initiative_title,
                initiative_description: currentInitiative.initiative_description,
                start_date: currentInitiative.start_date,
                end_date: currentInitiative.end_date,
                initiative_url: currentInitiative.initiative_url,
                image: currentInitiative.image
            }}/>)  
    ): "" }

    </section>
    </Initiative>
 )}

export default connect(() => ({}), {
    addToast,
  })(Initiatives);


const Initiative = styled.div`
margin-top: 1rem;
background-color: #ffffff;
width: 100%;
box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
border-radius: 14px;
border: 0px;

h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #000 !important;
}
.initiative-item {
    padding: 1rem 0;
    margin: 0 0.25rem;
    position: relative;
}
.initiative-pinned-icon {
    color: #000;
    position: absolute;
    right: 0px;
    top: 24px;
}
.project-image img {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.project-image.col-3 {
    flex: 0 0 20%;
}
.initiative-edit-delete.col-sm-1 {
    position: absolute;
    right: 34px;
}
.add-edit-delete-button {
    color: #000;
    font-size: 1.3rem;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    &:active {
        background-color: transparent !important;
        border-color: #fff !important;
        color: #000 !important;
    }
}
.project-title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}
.project-date {
    font-size: 0.9rem;
    font-weight: 500;
    color: #000;
}
span.short-text {
    font-size: 0.9rem;
    color: #000;
    font-weight: 400;
}
.short-text .readMoreText {
    font-size: 0.9rem;
    padding: 0 0 0 0.5rem;
    text-decoration-line: underline;
    color: black !important;
    font-weight: 700;
}
@media(max-width: 440px) {
    .project-image img {
        width: 70px;
        height: 70px;
    }
}
`