import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import ReadMoreAndLess from "react-read-more-less";
import AddEditPortfolio from './AddEditPortfolio';
import { deleteProject } from '../../../api';
import { processDate } from '../../../common/utils';


const MyPortfolio = ({ portfolios, isOwner, refreshProfile, profileId, addToast }) => {
  
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentPortfolio, setCurrentPortfolio] = useState({});
    const [show, setShow] = useState(false);
    const [image, setImage] = useState(null);

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, []);

    const onDelete = (projectId) => {
      setIsDeleting(true);
      setTimeout(() => setIsDeleting(false), 2000);
      deleteProject(profileId, projectId)
        .then(() => {
          setIsDeleting(false);
          refreshProfile();
          addToast({
            title: "Success",
            text: `Project deleted successfully.`,
            type: "success",
          });
        })
        .catch(() => {
          setIsDeleting(false); 
          refreshProfile();
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    };

  return (
    <Portfolio>
        {portfolios && portfolios.length ? (
        <div className="user-portfolio">  
            <div className="portfolio-header-card">
                <span>Portfolio of Experience</span>
                {isOwner ?
                    <Button className="add-edit-delete-button" aria-label="Add Project" onClick={() => setIsAdding(true)}>
                        <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600' }}>add</i>
                    </Button>
                    : ""}
            </div>
            {portfolios.map((portfolio, index) => (
                <div key={portfolio.id} className="portfolio-item" style={{ marginTop: index === 0 ? '0.8rem' : '1.3rem' }}>
                    <Row className="portfolio-header">
                        <img src={portfolio.photo} alt={portfolio.title} title={portfolio.title} className="portfolio-image" onClick={() => { setShow(true), setImage(portfolio.photo); }}/>
                        {portfolio.pinned ? (
                            <div className="portfolio-pinned-icon">
                                <i className="material-icons">push_pin</i>
                            </div>
                        ) : ""}
                    </Row>
                    <Row className="portfolio-body">
                        <Col md={11} xs={10} className="project-information px-0">
                            <div className="project-title">
                                {portfolio.project_title}
                            </div>
                            <div className="project-date">
                                {processDate(portfolio.start_date, "PP")} - {processDate(portfolio.end_date, "PP")}
                            </div>
                        </Col>
                        {isOwner ? (
                            <Col md={1} xs={2} className="d-flex align-items-start portfolio-edit-delete px-0 justify-content-end">
                                <Button className="add-edit-delete-button" aria-label="Edit Project" onClick={() => {
                                    setIsEditing(true);
                                    setCurrentPortfolio(portfolio);
                                }}>
                                    <i className="material-icons" alt="Edit Icon">edit</i>
                                </Button>
                                <Button className="add-edit-delete-button ml-2" aria-label="Delete Project" onClick={() => onDelete(portfolio.id)}>
                                    <i className="material-icons" alt="Delete Icon">delete</i>
                                </Button>
                            </Col>
                        ) : ""}
                    </Row>
                    <div className="project-description" style={{ marginBottom: portfolio.project_url ? '5px' : '24px', marginRight: '24px', marginLeft: '24px' }}>
                        <ReadMoreAndLess className="read-more-content my-story-link" charLimit={165} readMoreText="Read more" readLessText="Read less">
                            {portfolio.project_description}
                        </ReadMoreAndLess>
                    </div>
                    {portfolio.project_url && (
                    <div className="project-link-button-container">
                        <Button id="project-link-button" onClick={() => { window.open(portfolio.project_url, "_blank"); }} >
                            <i className={`material-icons mr-2`} style={{ fontSize: "20px" }}>
                            launch
                            </i>
                            View
                        </Button>
                    </div>
                    )}
                </div>
            ))}
        </div>
        ) : (
            <div className="portfolio-card">
            <div className="user-portfolio-header pb-2 mb-3">
                <Row className="d-flex align-items-center">
                    <Col xs={10}>
                        <h2>Portfolio of Experience</h2>
                    </Col>
                    <Col xs={2} className="d-flex justify-content-end pr-2">
                        {isOwner ?
                            <Button className="add-edit-delete-button" aria-label="Add Project" onClick={() => setIsAdding(true)}>
                                <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600' }}>add</i>
                            </Button>
                        : ""} 
                    </Col>
                </Row>
            </div>
            <h3 className="text-red pt-3" style={{borderTop: "1px solid rgba(0, 0, 0, 0.1)"}}>{isOwner ? 'You have not added any projects yet.' : 'No projects added yet.'}</h3>
            </div>
        )}

    {isOwner ? (
        isAdding ?  
        <AddEditPortfolio isEditing={false} show={isAdding} closeDialog={() => setIsAdding(false)} onUpdated={refreshProfile}/>
        :
        currentPortfolio && (
        <AddEditPortfolio show={isEditing} isEditing projectId={currentPortfolio.id} closeDialog={() => setIsEditing(false)} onUpdated={refreshProfile}
          currentValues={{
            pinned: currentPortfolio.pinned,
            project_title: currentPortfolio.project_title,
            project_description: currentPortfolio.project_description,
            start_date: currentPortfolio.start_date,
            end_date: currentPortfolio.end_date,
            project_url: currentPortfolio.project_url,
            photo: currentPortfolio.photo
          }}/>)      
    ): "" }

        <Modal
            show={show}
            size="lg"
            onHide={() => setShow(false)}
            dialogClassName="modelImage"
            aria-label="Initiative Image Modal"
        >
            <Modal.Body
                className="modalBodyImg pt-0 pl-0 pr-0 pb-0"
                aria-label="Initiative Image Modal"
            >
                <img
                    style={{ width: "100%" }}
                    src={image}
                    alt="Initiative Image"
                    title="Initiative Image"
                />
                <span className="closeBtn" onClick={() => setShow(false)}>
                    &#9447;
                </span>
            </Modal.Body>
        </Modal>

    </Portfolio>
 )}

export default connect(() => ({}), {
    addToast,
  })(MyPortfolio);


const Portfolio = styled.div`
.portfolio-card {
    margin-top: 1rem;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 14px;
    border: 0px;
    padding: 20px;
}
.portfolio-header-card {
    background: #ffffff;
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
    border-radius: 16px;
    overflow: hidden;
    color: #000;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 24px;
    margin-top: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.portfolio-header {
    height: 250px;
    border-bottom: 1px solid #EFEFEF;
    position: relative;
}

.portfolio-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.portfolio-pinned-icon {
    color: #000;
    position: absolute;
    right: 25px;
    top: 15px;
}
.portfolio-body {
    display: flex;
    margin: 24px 24px 0 24px;
}
h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #000;
}
.portfolio-item {
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 14px;
    border: 0px;
    overflow: hidden;
}
.project-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #c4c4c4;
}
.project-image.col-3 {
    flex: 0 0 20%;
}
.portfolio-edit-delete.col-sm-1 {
    position: absolute;
    right: 34px;
}
.add-edit-delete-button {
    color: #263238;
    font-size: 1.3rem;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    &:active {
        background-color: transparent !important;
        border-color: #fff !important;
        color: #263238 !important;
    }
}
.project-title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}
.project-date {
    font-size: 0.9rem;
    font-weight: 500;
    color: #000;
}
.project-link-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 24px;
    margin-top: 16px;
    border-top: 1px solid rgb(233, 233, 233);
}
 #project-link-button {
    background-color: #3F0E40;
    border-color: #3F0E40;
    border-radius: 7px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
span.short-text {
    font-size: 0.9rem;
    color: #000;
    font-weight: 400;
}
.short-text .readMoreText {
    font-size: 0.9rem;
    padding: 0 0 0 0.5rem;
}

`