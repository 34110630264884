import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Form from "../../../common/Form";
import { updateProfile } from "../../../api";

const AddEditStory = ({
  activeProfile,
  show,
  closeDialog,
  isEditing,
  currentValues,
  onUpdated,
}) => {
  const storySchema = yup.object().shape({
    org_story: yup
      .string()
      .required("Please Share your story/thoughts towards inclusion."),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    closeDialog();
    onUpdated();
    // updateProfile(activeProfile.id, { ...values})
    // .then(() => {
    //   setSubmitting(false);
    //   closeDialog();
    //   onUpdated();
    // })
    // .catch(() => {
    //   setSubmitting(false);
    // });
  };

  let initialValues = {
    org_story: "",
  };

  if (isEditing) {
    initialValues = {
      org_story: currentValues.org_story,
    };
  }

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit" : "Add"} Story</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={storySchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              {/* <Row>
                    <Form.Group as={Col} sm={12} md={12}>
                      <Form.Label>
                        Organization Vision video link
                    </Form.Label>
                      <Form.Control
                        name="org_videoLink"
                        placeholder="Your Organization Vision video link"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_videoLink}
                        isInvalid={touched.org_videoLink && errors.org_videoLink}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.org_industry}
                      </Form.Control.Feedback>
                    </Form.Group>
                  
                  </Row>             
                  */}
              <Row>
                <Form.Group as={Col} col={12}>
                  <Form.Label>Share your story</Form.Label>
                  <Form.Control
                    placeholder="Please Share your story"
                    name="org_story"
                    className="italic-placeholder textareaHeight"
                    value={values.org_story}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    isInvalid={touched.org_story && errors.org_story}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.org_story}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button
                      onClick={closeDialog}
                      block
                      size="lg"
                      variant="outline-danger"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="mt-3 mt-md-0" sm={12} md={6}>
                    <Button
                      loading={isSubmitting ? 1 : 0}
                      block
                      size="lg"
                      variant="danger"
                      type="submit"
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {}
)(AddEditStory);
