import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Form from "../../common/Form";
import ImageDropDown from "./ImageDropDown";
import { addToast } from "../../redux/toast";
import { deletefeedPhoto, updatefeedPhoto } from "../../api";

//View the added images on a carousel/slider, edit and delete images. 
const ImageCarousal = ({
  images,
  editable,
  deletePic,
  addToast,
  activityId,
  imgIndex,
}) => {
  const [imageShow, setImageShow] = useState(images);
  const [isLoading, setLoadng] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [imageData, setImageData] = useState({});
  const [index, setIndex] = useState(imgIndex);

  const handlenext = (eventKey, direction, event) => {
    setIndex(eventKey);
  };

  const handleRemoveImage = (picId, index) => {
    setLoadng(true);
    deletefeedPhoto(activityId, picId)
      .then(() => {
        const img = [...images];
        img.splice(
          img.findIndex((post) => post.id === picId),
          1
        );
        setImageShow(img);
        deletePic(picId, activityId, index);
        addToast({
          title: "Success",
          text: `photo deleted successfully`,
          type: "success",
        });
        setLoadng(false);
      })
      .catch((error) => {
        addToast({
          type: "error",
          title: "Error",
          text: "An error occurred, please try again.",
        });
        setLoadng(false);
      });
  };

  const handleEditImage = (picId, text) => {
    const data = {
      picId: picId,
      text: text,
    };
    setImageData(data);
    setEditing(true);
  };

  const handleImageTextChange = (picId, text) => {
    const img = [...images];
    const index = img.findIndex((post) => post.id === picId);
    img[index].photo_alt_text = text;
    setImageShow(img);
  };

  return (
    <Carousel
      activeIndex={index}
      interval={null}
      className=""
      onSelect={(eventKey, direction, event) => {
        handlenext(eventKey, direction, event);
      }}
    >
      {imageShow.length
        ? imageShow.map((image, index) => (
          <Carousel.Item key={image.id}>
            {editable && (
              <div className="d-flex flex-row-reverse align-items-center">
                {/* <ImageDropDown handledelete={handleRemoveImage} picId={image.id} index={index} /> */}
                <div
                  className="d-flex"
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "30px",
                    right: "16px",
                  }}
                >
                  <Button
                    variant="outline-dark"
                    style={{
                      backgroundColor: "#808080",
                      opacity: "0.6",
                      fontWeight: "500",
                      color: "#000000",
                      borderColor: "#000000",
                    }}
                    className="mr-3"
                    onClick={() =>
                      handleEditImage(image.id, image.photo_alt_text)
                    }
                  >
                    Edit
                    </Button>
                  <Button
                    variant="outline-dark"
                    style={{
                      backgroundColor: "#808080",
                      opacity: "0.6",
                      fontWeight: "500",
                      color: "#000000",
                      borderColor: "#000000",
                    }}
                    onClick={() => handleRemoveImage(image.id, index)}
                  >
                    Delete
                    </Button>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center black-bg mt-2">
              {isLoading ? (
                <div className="spinner-border m-5" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                  <img
                    style={{ cursor: "pointer" }}
                    src={image.photo}
                    alt={image.photo_alt_text}
                  />
                )}
            </div>
            <p className="pre_line">
              {image.photo_alt_text !== ""
                ? `alt text : ${image.photo_alt_text}`
                : ""}
            </p>
            <AltTextModel
              show={isEditing}
              onChangetext={handleImageTextChange}
              data={imageData}
              onclose={() => setEditing(false)}
              text={image.photo_alt_text}
              activityId={activityId}
            />
          </Carousel.Item>
        ))
        : ""}
      {/* <Carousel.Item className="">
              <img className="" src={images[0].photo} alt="Card image cap" />
              </Carousel.Item>
              <Carousel.Item className="">
              <img className="" src={images[1].photo} alt="Card image cap" />
              </Carousel.Item> */}
    </Carousel>
  );
};

export default connect(null, {
  addToast,
})(ImageCarousal);

const AltTextModel = ({
  text,
  show,
  onclose,
  activityId,
  data,
  onChangetext,
}) => {
  const [alttext, setAltText] = useState(data.text);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setAltText(e.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    updatefeedPhoto(activityId, data.picId, { photo_alt_text: alttext })
      .then(() => {
        onChangetext(data.picId, alttext);
        onclose();
        addToast({
          title: "Success",
          text: `photo deleted successfully`,
          type: "success",
        });
        setIsSubmitting(false);
      })
      .catch((error) => {
        addToast({
          type: "error",
          title: "Error",
          text: "An error occurred, please try again.",
        });
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      show={show}
      centered
      size="md"
      onHide={onclose}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Body>
        <Form.Group>
          <Form.Label>Alt text</Form.Label>
          <Form.Control
            placeholder="Image Alt text"
            onChange={(e) => handleChange(e)}
            name="alt_text"
            value={alttext}
            type="text"
            as="input"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <Button
                onClick={onclose}
                block
                size="lg"
                variant="outline-danger"
              >
                Cancel
              </Button>
            </Col>
            <Col className="mt-3 mt-md-0" sm={12} md={6}>
              <Button
                loading={isSubmitting ? 1 : 0}
                onClick={handleSubmit}
                block
                size="lg"
                variant="danger"
                type="submit"
              >
                {isSubmitting ? "Please wait..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};
