import React from 'react';
import { useField, useFormikContext } from 'formik';
//import DatePicker from 'react-datepicker';
import DatePicker from 'react-date-picker';

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      className="form-control"
      aria-label="Select date"
      {...field}
      {...props}
      yearAriaLabel="enter Year"
      monthAriaLabel="enter Month"
      dayAriaLabel="enter Day"
      clearAriaLabel="clear Date"
      calendarAriaLabel="Toggle calendar"
      nextAriaLabel="Next"
      next2AriaLabel="Jump forwards"
      prevAriaLabel="Previous"
      prev2AriaLabel="Jump backwards"
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

export default DatePickerField;
