import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Redirect, useParams, NavLink as Link } from "react-router-dom";
import * as yup from "yup";
import Button from "./Button";
import ImInclusive from '../assets/images/ImInclusiveLogo.png';
import LoginImage from '../assets/images/LoginSignupImage.png';
import SendIcon from '../assets/images/SendIcon.png';
import { format } from 'date-fns';

import OnboardingContainer, {
  OnboardingHeader,
  AuthFooter,
  AuthMobileFooter,
} from "../common/Onboarding";
import Form from "../common/Form";
import SuccessMessage from "./SuccessMessage";
import PasswordValidator from "./PasswordValidator";

import { resetPassword, resetPasswordConfirm } from "../api";
import { addToast } from "../redux/toast";

const ResetPasswordForm = connect(null, {
  addToast,
})(({ uid, token, addToast }) => {
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectToReset, setRedirectToReset] = useState(false);
  const unmounted = useRef(false);
 
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setRedirect(true);
      }, 3000);
    }
    return () => {
      clearTimeout()
    }
  }, [success]);

  useEffect(() => {
    return () => { unmounted.current = true }
  }, []);

  const resetPasswordSchema = yup.object().shape({
    new_password1: yup
      .string()
      .required("A new password is required.")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,}$/,
        "Enter a valid password."
      ),
    new_password2: yup
      .string()
      .required("Confirm password is required.")
      .oneOf([yup.ref("new_password1")], "Passwords do not match."),
  });

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const handlePasswordShow1 = () => {
    passwordType1 === "text"
      ? setPasswordType1("password")
      : setPasswordType1("text");
  };
  const handlePasswordShow2 = () => {
    passwordType2 === "text"
      ? setPasswordType2("password")
      : setPasswordType2("text");
  };

  const onResetPassword = (values, { setSubmitting }) => {
    setSubmitting(true);

    resetPasswordConfirm({ ...values, uid, token })
      .then(() => {
        if (!unmounted.current) {
          setSuccess(true);
        }
      })
      .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.new_password2
          ) {
            addToast({
              type: "error",
              text: err.response.data.new_password2[0],
              title: "Error",
            });
          } else {
            addToast({
              type: "error",
              text: "The password reset link has expired. Please wait while we redirect you.",
              title: "Error",
            });
            setTimeout(() => {
              setRedirectToReset(true);
            }, 2000);
          }
          setSuccess(false);
        });
      setSubmitting(false);
  };

  if (success) {
    if (!redirect) {
      return (
        <SuccessMessage
          margin="-100px auto 0"
          message="Your password has been reset. Please wait while we redirect you."
        />
      );
    }
    return <Redirect to="/auth/login" />;
  }
  
  if(!success && redirectToReset) {
    return <Redirect to="/auth/password/reset/confirm" />;
  }

  return (
    <>
    <h1 role="heading" className="main-heading">Reset Password</h1>
    <Formik
      validationSchema={resetPasswordSchema}
      initialValues={{
        new_password1: "",
        new_password2: "",
      }}
      onSubmit={onResetPassword}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} noValidate>
            <Form.Group style={{ position: 'relative', marginBottom: 0 }}>
            <Form.Label>New Password *</Form.Label>
            <Form.Control
              type={passwordType1}
              id="passwordOne"
              name="new_password1"
              placeholder="Enter password"
              value={values.new_password1}
              isInvalid={touched.new_password1 && errors.new_password1}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ paddingRight: '2.5rem' }}
            />
            <span className="password__show" role="button" tabIndex="0" aria-pressed={passwordType1 === "text" ? true : false} onClick={handlePasswordShow1} onKeyUp={(event) => event.key === "Enter" ? handlePasswordShow1() : ""}>
              {passwordType1 === "text" ? <i className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility</i> : <i className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility_off</i>}
            </span>
            <Form.Control.Feedback type="invalid">
              {errors.new_password1}
            </Form.Control.Feedback>
            <PasswordValidator password={values.new_password1} />
          </Form.Group>

          <Form.Group style={{ position: 'relative' }}>
            <Form.Label>Confirm Password *</Form.Label>
            <Form.Control
              type={passwordType2}
              id="passwordTwo"
              name="new_password2"
              placeholder="Re-enter password"
              value={values.new_password2}
              isInvalid={touched.new_password2 && errors.new_password2}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ paddingRight: '2.5rem' }}
            />
            <span className="password__show" role="button" tabIndex="0" aria-pressed={passwordType2 === "text" ? true : false} onClick={handlePasswordShow2} onKeyUp={(event) => event.key === "Enter" ? handlePasswordShow2() : ""}>
              {passwordType2 === "text" ? <i className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility</i> : <i className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility_off</i>}
            </span>
            <Form.Control.Feedback type="invalid">
              {errors.new_password2}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Button loading={isSubmitting} submit type="submit" className="login-button">
              Change Password
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
    </>
  );
});

const SendEmail = connect(null, {
  addToast,
})(({ addToast }) => {
  const [emailSent, setEmailSent] = useState(false);
  const unmounted = useRef(false);

  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .required("Your email is required to reset your password."),
  });

  const sendEmail = (values, { setSubmitting }) => {
    setEmailSent(true);
    resetPassword(values.email)
      .then(() => {
        if (!unmounted.current) {
          setSubmitting(false);
        }
      })
      .catch(() => {
        if (!unmounted.current) {
        setSubmitting(false);
        }
        addToast({
          type: "error",
          text: "That email doesn't exist in our database.",
          title: "Error",
        });
      });
  };

  useEffect(() => {
    return () => { unmounted.current = true }
  }, []);

  if (emailSent) {
    return (
      <>
        <div className="send-icon-container">
          <img src={SendIcon} alt="send icon icon" className="send-icon"/>
        </div>
        <h1 role="heading" className="main-heading">Reset Password</h1>
        <h2 role="sub-heading" className="sub-heading mb-5">An email has been sent to you. Please follow the provided instructions to reset your password.</h2>
      </>
    );
  }

  return (
    <>
      <h1 role="heading" className="main-heading">Reset Password</h1>
      <h2 role="sub-heading" className="pb-3 sub-heading">Forgot your password? No worries. Just enter the email you used to sign up and we'll send you a link to reset it.</h2>

      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={sendEmail}
        validationSchema={emailSchema}
      >
        {({
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label htmlFor="emailAddress">
                Email Address
              </Form.Label>
              <Form.Control
                name="email"
                type="email"
                id="emailAddress"
                value={values.email}
                isInvalid={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="example@email.com"
                aria-required="true"
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              submit
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              className="login-button mb-5"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
});

const ResetPassword = () => {
  const { uid, token } = useParams();

  let form;

  if (uid && token) {
    form = <ResetPasswordForm uid={uid} token={token} />;
  } else {
    form = <SendEmail />;
  }

  return (
    <>
      <OnboardingContainer classes="ic-login-container">
        <div className="container-fluid">
          <div className="row">

            <div className="col-sm-12 col-md-6 ic-container-left p-0">
              <div className="ic-image">
                <img src={LoginImage} alt="A graphic showing three people with different abilities." />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 ic-container-right">
              <header className="ic-header">
                <div className="ic-logo">
                  <img src={ImInclusive} style={{ width: 200 }} alt="Inclusive Logo" />
                </div>
              </header>
              <aside>
                <div className="ic-login-wrapper" aria-live="assertive">
                  <div className="ic-form-wrapper">{form}</div>
                </div>
                <div className="footer-container pt-5 mt-5">
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="footer-link">
                      <Link to="/privacypolicy" rel="nofollow">
                        Privacy Policy
                      </Link>
                    </span>
                    <span className="footer-link px-2">•</span>
                    <span className="footer-link">
                      <Link to="/termsandconditions" rel="nofollow">
                        Terms and Conditions
                      </Link>
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="copyright-text">© {format(new Date(), "yyyy")} ImInclusive</span>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </OnboardingContainer>
    </>
  );
};

export default connect(null, {
  addToast,
})(ResetPassword);
