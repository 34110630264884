import React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import i18n from 'i18next';
import { Colors } from "../styles/constants";

const direction = i18n.dir();

export const LoaderWithText = ({ text, margin, padding, minHeight }) => (
  <LoaderWithTextContainer
    padding={padding}
    margin={margin}
    minHeight={minHeight}
  >
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={Colors.font.primary}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
    <p style={{fontFamily: `${direction === "rtl" ? "Almarai" : ""}`}}>{text}</p>
  </LoaderWithTextContainer>
);

const Loader = ({ loading }) => {
  return (
    <Overlay loading={loading ? 1 : 0}>
      <LoaderContainer>
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#fff"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </LoaderContainer>
    </Overlay>
  );
};

export default connect((state) => ({
  loading: state.ui.loading,
}))(Loader);

const Overlay = styled.div`
  position: fixed;

  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 60;

  visibility: hidden;
  opacity: 0;

  transition: 200ms ease-in;

  ${(props) =>
    props.loading &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate() (-50%, -50%);
`;

const LoaderWithTextContainer = styled.div`
  color: ${Colors.font.primary};
  text-align: center;
  //min-height: 78vh;
  z-index: 30;

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    margin: 0 0.5rem;
  }

  > p {
    margin-top: 0.5rem;
  }

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}
`;
