import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AngryReaction from '../../assets/images/AngryReaction.png';
import CareReaction from '../../assets/images/CareReaction.png';
import CelebrateReaction from '../../assets/images/CelebrateReaction.png';
import AmazingReaction from '../../assets/images/AmazingReaction.png';
import HappyReaction from '../../assets/images/HappyReaction.png';
import LoveReaction from '../../assets/images/LoveReaction.png';
import SadReaction from '../../assets/images/SadReaction.png';
import WowReaction from '../../assets/images/WowReaction.png';
import { getPostReactions } from '../../api';
import i18n from 'i18next';

// Manage mini reactions bar for post.
const ReactionsBar = ({ reactionList, postId, reactionCount }) => {
    const direction = i18n.dir();
    let content;
    const [userReactionList, setUserReactionList] = useState([]);
    const [love, setLove] = useState(false);
    const [care, setCare] = useState(false);
    const [happy, setHappy] = useState(false);
    const [amazing, setAmazing] = useState(false);
    const [celebrate, setCelebrate] = useState(false);
    const [wow, setWow] = useState(false);
    const [sad, setSad] = useState(false);
    const [angry, setAngry] = useState(false);

    //Updates the reactions state based on the modified reaction list.
    useEffect(() => {
        setLove(false);
        setCare(false);
        setHappy(false);
        setAmazing(false);
        setCelebrate(false);
        setWow(false);
        setSad(false);
        setAngry(false);

        reactionList.map((reaction) => {
            reaction.reaction_type === "r1" ? setLove(true):
            reaction.reaction_type === "r2" ? setCare(true):
            reaction.reaction_type === "r3" ? setHappy(true):
            reaction.reaction_type === "r4" ? setAmazing(true):
            reaction.reaction_type === "r5" ? setCelebrate(true):
            reaction.reaction_type === "r6" ? setWow(true):
            reaction.reaction_type === "r7" ? setSad(true):
            reaction.reaction_type === "r8" ? setAngry(true):
        ""});
    },[reactionList]);

    // Gets the reactions list with user data and stores into userReactionList.
    const handleUserReactions = () => {
        getPostReactions(postId)
        .then(({data}) => {
            setUserReactionList(data.reaction_list);
        })   
    }

    //Person fullname or Org title with their reaction is stored in content to be passed to Tooltip for display. 
    if (userReactionList && userReactionList.length) {
        const UserReactions = userReactionList.map((reaction) => {
            return (
                <Row className={`d-flex align-items-center justify-content-between reactions-user-item ${direction === "rtl" ? "reactions-user-item-rtl" : ""}`} key={reaction.id}>
                    <Col xs={9} className="d-flex align-items-center reaction-username px-1">
                        {reaction.reaction_by.person && reaction.reaction_by.person.full_name}
                        {reaction.reaction_by.organisation && reaction.reaction_by.organisation.organization_title} 
                    </Col>
                    <Col xs={3} className="d-flex align-items-center justify-content-end reaction-image px-1">
                    {reaction.reaction_type === "r1" ? <img src={LoveReaction} alt="Love" className="reaction"/> :
                        reaction.reaction_type === "r2" ? <img src={CareReaction} alt="Care" className="reaction"/> :
                        reaction.reaction_type === "r3" ? <img src={HappyReaction} alt="Happy" className="reaction"/> :
                        reaction.reaction_type === "r4" ? <img src={AmazingReaction} alt="Amazing" className="reaction"/> :
                        reaction.reaction_type === "r5" ? <img src={CelebrateReaction} alt="Celebrate" className="reaction"/> :
                        reaction.reaction_type === "r6" ? <img src={WowReaction} alt="Wow" className="reaction"/> :
                        reaction.reaction_type === "r7" ? <img src={SadReaction} alt="Sad" className="reaction"/> :
                        reaction.reaction_type === "r8" ? <img src={AngryReaction} alt="Angry" className="reaction"/> :
                        ""}
                    </Col>
                </Row>
            );
        });
        content = (<StyledUserReactions>{UserReactions}</StyledUserReactions>);
    }

    return (
        // Renders reactions and their count for the post. Displays list of users on click.
        <StyledReactions>
            {reactionCount ? (
            <OverlayTrigger placement="bottom-start" overlay={<Tooltip id="user-list">{content}</Tooltip>} trigger="click" rootClose>
                <Row className="d-flex align-items-center reactions-bar" onClick={() => handleUserReactions()}> 
                    {love && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={LoveReaction} alt="Love" className="reaction" />
                        </Col>
                    }
                    {care && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={CareReaction} alt="Care" className="reaction" />
                        </Col>
                    }
                    {happy && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={HappyReaction} alt="Happy" className="reaction" />
                        </Col>
                    }
                    {amazing && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={AmazingReaction} alt="Amazing" className="reaction" />
                        </Col>
                    }
                    {celebrate && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={CelebrateReaction} alt="Celebrate" className="reaction" />
                        </Col>
                    }
                    {wow && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={WowReaction} alt="Wow" className="reaction" />
                        </Col>
                    }
                    {sad && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={SadReaction} alt="Sad" className="reaction" />
                        </Col>
                    }
                    {angry && 
                        <Col className="d-flex align-items-center reaction-container">
                            <img src={AngryReaction} alt="Angry" className="reaction" />
                        </Col>
                    }                  
                        <Col className="d-flex align-items-center reaction-container">
                            <span className="reaction-count">{reactionCount}</span>
                        </Col>                  
                </Row>
            </OverlayTrigger>
            ):""}     
        </StyledReactions>
    )
}

export default ReactionsBar;

const StyledReactions = styled.div`
.reactions-bar{
    padding: 0 15px 10px 15px;
    width: fit-content;
    cursor: pointer;
    &:hover {
        .reaction-count {
            color: #000;
        }
    }
}
.reaction-container {
    flex-grow: initial;
    padding: 0 0.25rem;
}
.reaction {
    width: 30px;
    height: 31px;
}
.reaction-count {
    font-weight: 500;
    font-size: 18px;
    color: #515151;
}
`
const StyledUserReactions = styled.div`
.reactions-user-item {
    padding: 0.25rem 0;
    margin: 0 0.1rem;
    // min-width: 180px;
}
.reactions-user-item:not(:last-child) {
    border-bottom: 0.25px solid #5f5f5f;
}
.reactions-user-item:first-child {
    padding-top: 0 !important;
}
.reactions-user-item:last-child {
    padding-bottom: 0 !important;
}
.reactions-user-item-rtl {
    direction: rtl;
}
.reaction-username {
    font-size: 0.9rem;
    font-family: "Inter", sans-serif;
}
.reaction {
    width: 30px;
    height: 31px;
}
`
