import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import PostCard from "../../../components/post/PostCard";
import { LoaderWithText } from "../../../components/Loader";

import PostCreator from "../../../components/post/PostCreator";

import { Colors } from "../../../styles/constants";

import { getUserFeed, getUserFeedById } from "../../../api";

const MyConversations = ({ profile, isOwner }) => {
  const [userFeed, setUserFeed] = useState([]);
  const [initFeedPulled, setInitFeedPulled] = useState(false);
  const unmounted = useRef(false);

  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 1,
  });
  const [feedLoading, setFeedLoading] = useState(false);

  const [show, setShow] = useState(false);

  const width = window.innerWidth;
  const isMob = width < 650 ? true : false;

  const getFeed = (page = pagination.current_page) => {
    if (page === 1) {
      setFeedLoading(true);
    }

    getUserFeedById(profile.owner_id, page)
      .then(({ data }) => {
        if (!unmounted.current) {
          setUserFeed(userFeed.concat(data.posts));
          setPagination(data.pagination);
          setFeedLoading(false);
        }
      })
      .catch(() => {
        setFeedLoading(false);
      });

    // getUserFeed(profile.id, page)
    //   .then(({ data }) => {
    //     setUserFeed(userFeed.concat(data.activities));

    //     setPagination(data.pagination);
    //     setFeedLoading(false);
    //   })
    //   .catch(() => {
    //     setFeedLoading(false);
    //   });
  };

  useEffect(() => {
    return () => { unmounted.current = true } 
  }, []);

  const onPostAdded = (newPost) => {
    const newFeed = [...userFeed];
    newFeed.unshift(newPost);

    setUserFeed(newFeed);
  };

  const onPostDeleted = (id) => {
    const newFeed = [...userFeed];
    newFeed.splice(
      newFeed.findIndex((post) => post.id === id),
      1
    );
    setUserFeed(newFeed);
  };

  const onLoadMore = () => {
    getFeed((pagination.current_page += 1));
  };

  const onPicDeleted = (picId, postId, index) => {
    const newFeed = [...userFeed];
    const postsIndex = newFeed.findIndex((post) => post.id === postId);
    const updatePost = newFeed[postsIndex];
    updatePost.photos.splice(
      updatePost.photos.findIndex((post) => post.id === picId),
      1
    );
    newFeed[postsIndex] = updatePost;
    setUserFeed(newFeed);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!initFeedPulled) {
      getFeed();
      setInitFeedPulled(true);
    }
  }, [userFeed]);

  if (feedLoading) {
    return <LoaderWithText key="1" margin="50px 0 0" text="Please wait..." />;
  }

  if (!feedLoading && !userFeed.length) {
    return (
      <MyConvo>
        <Row>
          {/* <Col md={12} className="d-flex align-items-center user-story-header">
            <h2 className="pl-1">{isOwner ? "My " : `${profile.person_first_name}'s `} Conversations</h2>
          </Col> */}
        </Row>
        

        <Modal
          show={show}
          centered
          size="xl"
          onHide={() => setShow(false)}
          style={{ marginTop: "50px" }}
          className="modelTopmargin carousalModal"
        >
          <Modal.Body className="p-0">
            <Modal.Header className="headeer" closeButton></Modal.Header>
            <PostCreator
              isOwner={isOwner}
              profile={profile}
              onPostAdded={onPostAdded}
              isModel
              setShow={setShow}
            />
          </Modal.Body>
        </Modal>

        <NothingHere>Nothing here. Why don't you share something?</NothingHere>
      </MyConvo>
    );
  }

  return (
    <MyConvo>
      <Row>
        {/* <Col md={12} className="d-flex align-items-center user-story-header">
            <h2 className="pl-1">{isOwner ? "My " : `${profile.person_first_name}'s `} Conversations</h2>
          </Col> */}
      </Row>
      
      <InfiniteScroll
        pageStart={1}
        loadMore={onLoadMore}
        hasMore={pagination.current_page < pagination.total_page}
        loader={<LoaderWithText key="2" margin="40px 0 0" text="Please wait..." />}
      >
        {userFeed.map((feedItem) => (
          <PostCard
            key={feedItem.id}
            posterName={`${
              feedItem.posted_by.organisation
                ? feedItem.posted_by.organisation.organization_title
                : feedItem.posted_by.person.full_name
            }`}
            activityId={feedItem.id}
            postedBy={feedItem.posted_by}
            postedTo={feedItem.posted_on}
            content={feedItem.body}
            postedOn={feedItem.created_at}
            reactions={feedItem.reactions}
            comments={feedItem.comments}
            photos={feedItem.photos}
            onCommentAdded={onPostAdded}
            onDelete={() => onPostDeleted(feedItem.id)}
            onDeletePic={onPicDeleted}
            fullpost={feedItem}
          />

          //   <PostCard
          //   key={feedItem.id}
          //   posterName={`${feedItem.posted_by.organisation?feedItem.posted_by.organisation.organization_title:feedItem.posted_by.person.full_name}`}
          //   activityId={feedItem.id}
          //   postedBy={feedItem.posted_by}
          //   postedTo={feedItem.posted_on }
          //   content={feedItem.body}
          //   postedOn={feedItem.created_at}
          //   comments={feedItem.comments}
          //   photos={feedItem.photos}
          //   onCommentAdded={fetchFeed}
          //   onDelete={() => onPostDeleted(feedItem.id)}
          //   onDeletePic={onPicDeleted}

          //   isFeed
          // />

          // <PostCard
          //   key={feedItem.id}
          //   posterName={`${feedItem.activity_by.person_first_name} ${feedItem.activity_by.person_last_name}`}
          //   activityId={feedItem.id}
          //   postedBy={feedItem.activity_by}
          //   postedTo={feedItem.content_object.person}
          //   content={feedItem.content_object}
          //   postedOn={feedItem.created_at}
          //   comments={feedItem.comments}
          //   onCommentAdded={onPostAdded}
          //   onDelete={() => onPostDeleted(feedItem.id)}
          // />
        ))}

        <Modal
          show={show}
          centered
          size="xl"
          onHide={() => setShow(false)}
          style={{ marginTop: "50px" }}
          className="modelTopmargin carousalModal"
        >
          <Modal.Body className="p-0">
            <Modal.Header className="headeer" closeButton></Modal.Header>
            <PostCreator
              isOwner={isOwner}
              profile={profile}
              onPostAdded={onPostAdded}
              isModel
              setShow={setShow}
            />
          </Modal.Body>
        </Modal>
      </InfiniteScroll>
    </MyConvo>
  );
};

export default MyConversations;

const NothingHere = styled.div`
  padding: 2rem;
  text-align: center;

  color: ${Colors.font.secondary};
`;

const MyConvo = styled.div`
  font-family: "Inter", sans-serif;

  .ic-app-create-post,
  .ic-app-feeds {
    margin-top: 1rem;
    margin-bottom: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    border: 0px;
  }
`;
