import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { addToast } from "../../redux/toast";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { Picker } from "emoji-mart";
import Form from "../../common/Form";
import MentionTextarea from "./../MentionTextarea";
import Reactions from './CommentCardReactions';
import ReactionsBar from './CommentReactionsBar';
import ReportContent from "../ReportContentModal";
import { getTimeAgo, getTimeAgoArabicShort } from "../../common/utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { addfeedComment, deletefeedComment } from "../../api";

const Comments = connect((state) => ({
  activeProfile: state.user.activeProfile,
}), { addToast })(({ comments, activityId, activeProfile, refs, setCommentNumber, addToast}) => {
  const [viewingComments, setViewingComments] = useState(false);
  const [commentList, setCommentList] = useState(comments);
  const [deletingCommentId, setDeletingCommmentId] = useState(null);

  const { t } = useTranslation();
  const direction = i18n.dir();

  //Updating comment list and comment number everytime a comment is added.
  const onCommentAdded = (newComment) => {
    const newCommentList = [...commentList];
    setCommentList((newCommentList) => [...newCommentList, newComment]);
    setCommentNumber(newCommentList.length);
    setViewingComments(true);
  };

  //On deleting a comment, calling the API and removing the deleted comment from the comment list and updating comment number.
  const onDeleteComment = (commentId) => {
    setDeletingCommmentId(commentId);

    deletefeedComment(activityId, commentId)
      .then(() => {
        const newCommentList = [...commentList];
        newCommentList.splice(
          newCommentList.findIndex((_comment) => _comment.id === commentId),
          1
        );
        setCommentList(newCommentList);
        setCommentNumber(newCommentList.length);
        setDeletingCommmentId(null);
      })
      .catch(() => {
        setDeletingCommmentId(null);
      });
  };

  let commentsJsx = null;
  let seeAllCommentsJsx = null;

  if (commentList.length) {
    //if viewingComments is false, display the first comment in the list.
    commentsJsx = !viewingComments ? (
      <Comment
        isDeleting={deletingCommentId === commentList[0].id}
        onDelete={onDeleteComment}
        activeProfile={activeProfile}
        comment={commentList[0]}
      />
    ) : (
      // Otherwise, display the entire list of comments.
      commentList.map((currComment) => (
        <Comment
          key={currComment.id}
          isDeleting={deletingCommentId === currComment.id}
          onDelete={onDeleteComment}
          activeProfile={activeProfile}
          comment={currComment}
          id={currComment.id}
        />
      ))
    );

    //If comments are > 1, display a button to toggle view/hide comments.
    if (commentList.length > 1) {
      seeAllCommentsJsx = (
        <div className="ic-show-all-comments">
          <a
            onClick={() => setViewingComments(!viewingComments)}
            role="button"
            className="text-red"
            aria-label={viewingComments ? "Hide comments" : "View all comments"}
          >
            {viewingComments ? t("Hide comments") : t("View all comments")}{" "}
            <span>({commentList.length})</span>
          </a>
        </div>
      );
    }
  }

  return (
    <div className="container-fluid ic-feed-user-comment-wrapper">
      {/* Only displaying comments if comment list is not empty. */}
      {commentList.length > 0 ? (
        <div className="row" style={{ borderBottom: "0.5px solid #DCDCDC" }}>
          <div className="ic-feed-user-comment-body">
            {commentsJsx}
            {seeAllCommentsJsx}
          </div>
        </div>
      ) : (
        ""
      )}
      <Row className={`post-comment-container d-flex align-items-center ${direction === "rtl" ? "display-rtl" : "display-ltr"}`}>
        <Col xs={2} className={`d-flex justify-content-center ${direction === "rtl" ? "pl-0" : "pr-0"}`}>
          <div className="comment-picture">
            <img
              src={
                activeProfile.person_display_picture
                  ? activeProfile.person_display_picture
                  : activeProfile.organization_logo
              }
              alt=""
              title=""
            />
          </div>
        </Col>
        <Col xs={10} className={`${direction === "rtl" ? "pr-0" : "pl-0"}`}>
          <PostComment
            refs={refs}
            onCommentAdded={onCommentAdded}
            authorId={activeProfile.owner_id}
            activityId={activityId}
            addToast={addToast}
          />
        </Col>
      </Row>
    </div>
  );
});

const Comment = ({ comment, activeProfile, onDelete, isDeleting }) => {
  const [showCommentReactionDropdown, setShowCommentReactionDropdown] = useState(false);
  const [reactionCount, setReactionCount] = useState(comment.reactions.count);
  const [reactionList, setReactionList] = useState(comment.reactions.reaction_list);
  const history = useHistory();
  const { t } = useTranslation();
  const direction = i18n.dir();
  const language = i18n.language;
  const [showReportModal, setShowReportModal] = useState(false);
  
  const contentClickHandler = (e) => {
    //Finding the closest link (<a>) and storing in targetLink.
    const targetLink = e.target.closest("a");

    if (!targetLink) return;
    if (
      //Adding a check to ensure a link is only treated as an internal link if it includes /p/, /o/, 'inclusive' or 'localhost'
      (!targetLink.href.includes("/p/") && !targetLink.href.includes("/o/")) ||
      (!targetLink.href.includes("inclusive") &&
        !targetLink.href.includes("localhost"))
    )
      return;
    e.preventDefault();

    const sp = targetLink.href.includes("/p/") ? "/p/" : "/o/";
    const link = targetLink.href.split(sp)[1];
    history.push(sp + link);
  };

  //Replacing mentions in the comment body with links to the mentioned profiles.
  var commentBody = comment.body;

  if (comment.comment_mentions) {
    if (comment.comment_mentions.length) {
      comment.comment_mentions.forEach((element) => {
        if (element.mention.organisation) {
          commentBody = commentBody.replace(
            new RegExp(element.mention_title, "g"),
            `<a href="/o/${element.mention.organisation.slug}">${element.mention_title}</a>`
          );
        } else {
          commentBody = commentBody.replace(
            new RegExp(element.mention_title, "g"),
            `<a href="/p/${element.mention.person.slug}">${element.mention_title}</a>`
          );
        }
      });
    }
  }

  return (
    <div className="comment-wrapper-container">
      <div className="ic-feed-comment-user-details d-flex flex-row">
        {comment.commented_by.id === activeProfile.owner_id ? (
          <Dropdown className={`ib-comment-dropdown ${direction === "rtl" ? "display-rtl" : "display-ltr"}`} alignRight={direction === "rtl" ? false : true}>
            <Dropdown.Toggle
              variant="light"
              bg="white"
              id="dropdown-basic"
              aria-label="Comment options"
            >
              <i className="fas fa-ellipsis-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0">
              <Dropdown.Item
                disabled={isDeleting}
                onClick={() => onDelete(comment.id)}
                aria-label="Delete Comment"
              >
                {t("Delete Comment")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
            <Dropdown className={`ib-comment-dropdown ${direction === "rtl" ? "display-rtl" : "display-ltr"}`} alignRight={direction === "rtl" ? false : true}>
              <Dropdown.Toggle
                variant="light"
                bg="white"
                id="dropdown-basic"
                aria-label="Comment options"
              >
                <i className="fas fa-ellipsis-v" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 m-0">
                <Dropdown.Item
                  onClick={() => setShowReportModal(true)}
                  aria-label="Report Comment"
                >
                  Report Comment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        )}
        <div className="ic-comment-user-name">
          <Link
            to={
              comment.commented_by.person
                ? `/p/${comment.commented_by.person.slug}`
                : `/o/${comment.commented_by.organisation.slug}`
            }
            className="text-red"
            onKeyUp={(event) => event.key === "Tab" ? setShowCommentReactionDropdown(true):""}
          >{`${
            comment.commented_by.organisation
              ? comment.commented_by.organisation.organization_title
              : comment.commented_by.person.full_name.charAt(0).toUpperCase() +
                comment.commented_by.person.full_name.slice(1)
          }`}</Link>
        </div>
      </div>
      <div className="ic-feed-comment-user-content">
        <div className="conter">
          <p
            onClick={contentClickHandler}
            dangerouslySetInnerHTML={{
              __html: commentBody,
            }}
          />
        </div>
        <Row className="ic-comment-user-logs d-flex align-items-center">
          <Col xs={4} className="d-inline-flex align-items-center">
            <Reactions reactions={comment.reactions} reactionCount={reactionCount} setReactionCount={setReactionCount} reactionList={reactionList} setReactionList={setReactionList} commentId={comment.id} ownerId={activeProfile.owner_id} showDropdown={showCommentReactionDropdown} setShowDropdown={setShowCommentReactionDropdown}/>
            <span className="log-time px-1">·</span>
            <span className="log-time" style={{fontFamily: `${language === "ar" ? "Almarai" : ""}`}}>
              {/* Replacing the long log-time with the shortform log-time. */}
              {language !== "ar" ? (
              getTimeAgo(comment.created_at)
                .replace("about ", "")
                .replace(" ago", "")
                .replace(" hours", "h")
                .replace(" hour", "h")
                .replace(" day", "d")
                .replace("ds", "d")
                .replace(" minutes", "m")
                .replace(" minute", "m")
                .replace("less than am", "just now"))
                :
                (
                getTimeAgoArabicShort(comment.created_at)
                .replace("تقريباً","")
                )}
            </span>
          </Col>
          <Col xs={8} className="d-flex align-items-center justify-content-end pr-1">
            <ReactionsBar reactionList={reactionList} commentId={comment.id} reactionCount={reactionCount}/>
          </Col>
        </Row>
      </div>
      <ReportContent modalVisible={showReportModal} setModalVisible={setShowReportModal} contentType="C" contentId={comment.id} />
    </div>
  );
};

const PostComment = ({ activityId, authorId, onCommentAdded, refs, addToast }) => {
  const [tvalue, settValue] = useState("");
  const [mention, setMention] = useState([]);
  const [value, setValue] = useState("");
  const textareaRef = useRef();
  const emojiRef = useRef();
  const emojiclose = useRef();
  const [showEmoji, setShowEmoji] = useState(false);

   //Pass an empty object to the i18n property for Emoji Picker if lang is en.
   let emojiTranslations = {};

   //Pass an object with translations to the i18n property for Emoji Picker if lang is ar.
   if (i18n.language === "ar") {
     emojiTranslations = {
       search: "بحث",
       notfound: 'لم يتم العثور على رموز تعبيرية',
       skintext: 'اختر لون بشرتك الافتراضي',
       categories: {
         search: 'نتائج البحث',
         recent: 'كثيرا ما تستخدم',
         smileys: 'الوجوه الضاحكة والعاطفة',
         people: 'الناس والجسم',
         nature: 'الحيوانات والطبيعة',
         foods: 'طعام شراب',
         activity: 'نشاط',
         places: 'السفر والأماكن',
         objects: 'شاء',
         symbols: 'حرف او رمز',
         flags: 'أعلام',
         custom: 'مخصص',
       }}
   } 

  const direction = i18n.dir();

  const emojiAdded = (emoji) => {
    let sym = emoji.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emojis = String.fromCodePoint(...codesArray);

    var index = value.length;
    //setPValue(Pvalue.slice(0, index) + emoji.native + Pvalue.slice(index))

    //To ensure emoji is added to the end of text, not in the middle.
    setValue(value.slice(0, index) + emojis + value.slice(index));
    settValue(tvalue.slice(0, index) + emojis + tvalue.slice(index));
    setShowEmoji(!showEmoji);
    var index = index + 7;

    const cursorPosition = textareaRef.current.selectionEnd;
    const start = value.substring(0, textareaRef.current.selectionEnd);
    const end = value.substring(textareaRef.current.selectionEnd);
    const text = start + emoji.native + end;
  };

  const postComment = (values, { setSubmitting, resetForm }) => {
    if (tvalue.length == 0) return;

    const mentions = mention.map((mention) => {
      return {
        mention: mention.id,
        mention_title: mention.display,
      };
    });
    const uniqueMentions = [
      ...new Map(mentions.map((item) => [item.mention, item])).values(),
    ];

    addfeedComment(activityId, {
      comment: { body: tvalue, commented_by: authorId, post: activityId },
      comment_mentions: uniqueMentions,
    })
      .then(({ data: newComment }) => {
        onCommentAdded(newComment);

        setSubmitting(false);
        resetForm();
        setValue("");
        setMention([]);
        settValue("");
      })
      .catch((error) => {
        setSubmitting(false);
        if (Object.values(error)[2].status === 405) {
          addToast({
            type: "error",
            title: "Error",
            text: "Sorry, you're not authorized to perform this operation.",
          });
        }
        else {
          addToast({
            type: "error",
            title: t("Error"),
            text: t("An error occurred, please try again."),
          });
        }
      });
  };

  return (
    <div className="ic-feed-post-comment-wrapper">
      <Formik
        initialValues={{
          body: "",
        }}
        onSubmit={postComment}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="row mb-0">
              <div className="col-md-12">
                <MentionTextarea
                  setMention={setMention}
                  value={value}
                  setValue={setValue}
                  settValue={settValue}
                  textareaRef={textareaRef}
                  isComment
                  handleSubmit={handleSubmit}
                />
                <div className={`emojiWrapper ${direction === "rtl" ? "display-rtl" : "display-ltr"}`} style={{ paddingTop: "6px" }}>
                  <button
                    tabIndex="0"
                    className="emoji-triggersss add-emoji-button"
                    style={{
                      cursor: "pointer",
                      border: "0px",
                      backgroundColor: "transparent",
                      color: "#8C8C8C",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      //textareaRef.current.focus();
                      setShowEmoji(!showEmoji);
                    }}
                    ref={emojiclose}
                    aria-label="Add Emoji"
                  >
                    <i className="material-icons" style={{ fontSize: "28px" }}>
                      sentiment_satisfied_alt
                    </i>
                  </button>

                  <div className={`emoji-trigger ${direction === "rtl" ? "displayRtl" : ""}`} ref={emojiRef}>
                    {showEmoji && (
                      <Picker
                        show={false}
                        onSelect={emojiAdded}
                        title={"Emojis"}
                        color={`#000000`}
                        emojiSize={30}
                        i18n={emojiTranslations}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Comments;
