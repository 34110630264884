import React, { useState, useEffect, useRef } from "react";
import { NavLink as Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";
import AppContentContainer from "../../common/AppContentContainer";
import PostCreator from "../../components/post/PostCreator";
import PostCard from "../../components/post/PostCard";
import Button from "react-bootstrap/Button";
import { LoaderWithText } from "../../components/Loader";
import ImpactBoardPhone from '../../assets/images/IBBannerMobile.png';
import ImpactBoardWeb from '../../assets/images/IBBannerWeb.png';
import { Mixpanel } from '../../mixpanel';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getPostFeed, checkNewPost } from '../../api';

const ImpactBoardBanner = ({ currentLanguage, onLanguageChange }) => {
  const direction = i18n.dir();
  const { t } = useTranslation();
  return (
    <section className={`ic-app-content-left d-md-block ${direction === "rtl" ? "display-rtl" : "display-ltr"}`}>
      <aside>
        <div className="ic-app-content-left-items impact_banner_container step-1">
          <img className="impactBannerImage bannerphone" alt="Text that reads Welcome to Impact Board, with two people of different abilities communicating with each other." src={ImpactBoardPhone} />
          <img className="impactBannerImage bannerweb" alt="Text that reads Welcome to Impact Board, with two people of different abilities communicating with each other." src={ImpactBoardWeb} />
        </div>
        {/* <div className="ib-nav-link-container mt-3">
          <Nav className="justify-content-center" activeKey="/home">
            <Nav.Item className={`ib-nav-item ${direction === "rtl" ? "display-rtl" : "display-ltr"}`}>
              <a href="https://inclusive.ae/blog" className="ib-nav-link" target="_blank" aria-label="Blog" rel="noopener noreferrer">{t("Blog")}</a>
            </Nav.Item>
            <Nav.Item className={`ib-nav-item ${direction === "rtl" ? "display-rtl" : "display-ltr"}`}>
              <Link className="ib-nav-link" to="/faqs" aria-label="Frequently Asked Questions" >{t("FAQs")}</Link>
            </Nav.Item>
            <Nav.Item className={`ib-nav-item ${direction === "rtl" ? "display-rtl" : "display-ltr"}`}>
              <Link className="ib-nav-link" to="/privacypolicy" aria-label="Privacy Policy" >{t("Privacy")}</Link>
            </Nav.Item>
            <Nav.Item className={`ib-nav-item ${direction === "rtl" ? "display-rtl" : "display-ltr"}`}>
                <Link className="ib-nav-link" to="/termsandconditions" aria-label="Terms and conditions" >{t("Terms")}</Link>
            </Nav.Item>
            <Nav.Item className={`ib-nav-item ${direction === "rtl" ? "display-rtl" : "display-ltr"}`}>
              {currentLanguage === "en" ? (
                <span className="ib-nav-link" aria-label="Change Display Language" onClick={() => onLanguageChange("ar")} style={{fontFamily: "Almarai"}}>العربية</span> 
              ) : (
                <span className="ib-nav-link" aria-label="Change Display Language" onClick={() => onLanguageChange("en")}>English</span>
              )}
              
            </Nav.Item>
          </Nav>
          <div className="mt-2">
            <span className="ib-nav-copyright">Copyrights &copy; 2020 Inclusive </span>
          </div>
        </div> */}
      </aside>
    </section>

  );
};

const PostFeedConitainer = ({ activeProfile, impactboardlink }) => {

  const direction = i18n.dir();
  const { t } = useTranslation();
  const [feed, setFeed] = useState([]);
  const [postDate, setPostDate] = useState(null);
  const [newPost, setNewPost] = useState(false);
  let date = postDate;
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 1,
  });
  const [feedLoading, setFeedLoading] = useState(false);
  const [show, setShow] = useState(false);

  const width = window.innerWidth;
  const isMob = width < 650 ? true : false;

  const unmounted = useRef(false);


  useEffect(() => {
    let unmounted = false;
    if (impactboardlink !== null) {
      setFeedLoading(true);
      window.scrollTo(0, 0)
      getPostFeed(1)
        .then(({ data }) => {  
          if (!unmounted) {
            setFeedLoading(false);
            setFeed(data.posts);
            setPostDate(data.posts[0].created_at);
            setPagination(data.pagination);
          }
        })
        .catch(() => {
          if (!unmounted) {
            setFeedLoading(false);
          }
        });
    }
    return () => { unmounted = true }; 
  }, [impactboardlink])

  //Fetch feed and concatenate the new posts to the feed array. 
  const fetchFeed = (page = pagination.current_page) => {
    let newFeed = [];

    if (page === 1) {
      setFeedLoading(true);
    }

    getPostFeed(page)
      .then(({ data }) => {
        if (!unmounted.current) {

        Mixpanel.track("impactboard", { "page": page });

        setFeedLoading(false);
        newFeed = feed.concat(data.posts);
        setFeed(newFeed);
        setPostDate(newFeed[0].created_at);
        setPagination(data.pagination);
        }
      })
      .catch(() => {
        if (!unmounted.current) {
          setFeedLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchFeed();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  
    return () => { unmounted.current = true } 
  }, []);


  //If New Post, scroll to top and fetch feed.
  const handleNewPost = () => {

    setFeedLoading(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    getPostFeed(1)
      .then(({ data }) => {

        setFeedLoading(false);
        setFeed(data.posts);
        setPostDate(data.posts[0].created_at);
        setNewPost(false);
        setPagination(data.pagination);
      })
      .catch(() => {

        setFeedLoading(false);
      });
  }

  //Checking for new post.
  useEffect(() => {
    //Using interval to call API at 1 min intervals.
    const interval = setInterval(() => {

      //State right after calling setState will have value before the update.
      //Calling updater just to get the latest value.
      setPostDate((state) => {
        checkNewPost(state)
          .then(({ data }) => {
            setNewPost(data);
          })
        return state;
      });

    }, 60000);
    return () => clearInterval(interval);
  }, []);



  const onPostAdded = (newPost) => {
    const newFeed = [...feed];
    newFeed.unshift(newPost);
    setPostDate(newPost.created_at);
    setFeed(newFeed);
  };

  const onPostDeleted = (id) => {
    const newFeed = [...feed];
    newFeed.splice(newFeed.findIndex((post) => post.id === id), 1);
    setFeed(newFeed);
  };

  const onPicDeleted = (picId, postId, index) => {

    const newFeed = [...feed];
    const postsIndex = newFeed.findIndex((post) => post.id === postId)
    const updatePost = newFeed[postsIndex]
    updatePost.photos.splice(updatePost.photos.findIndex((post) => post.id === picId), 1);
    newFeed[postsIndex] = updatePost
    setFeed(newFeed);
  }

  const onLoadMore = () => {
    fetchFeed(pagination.current_page += 1);
  };

  if (feedLoading || !activeProfile) {
    return (

      <section className="ic-app-content-right">
        <aside>
          <LoaderWithText minHeight="78vh" text={t("Please wait...")} key="1"/>
        </aside>
      </section>
    );
  }

  if (!feedLoading && feed && !feed.length) {
    return null;

  }
  return (
    <section className="ic-app-content-right">
      <aside className={direction === "rtl" ? "display-aside-rtl" : "display-aside-ltr"}>
        <StyledButton>
          <Button
            className={`new-post-button ${newPost ? "show-button" : "hide-button"
              }`}
            aria-label="View New Post"
            onClick={() => handleNewPost()}
          >
            <i className={`material-icons ${direction === "rtl" ? "ml-1" : "mr-1"}`} alt="arrow upward icon">
              arrow_upward
            </i>
            {t("New Posts")}
          </Button>
        </StyledButton>
        <InfiniteScroll
          className="ic-app-content-right-items d-flex flex-column"
          pageStart={1}
          loadMore={onLoadMore}
          hasMore={pagination.current_page < pagination.total_page}
          loader={
            <LoaderWithText margin="30px 0 0" text={t("Please wait...")} key="2" />
          }
        >
          {!isMob ? (
            //On Desktop, the regular PostCreator will render.
            <PostCreator
              isOwner
              onPostAdded={onPostAdded}
              profile={activeProfile}
            />
          ) : (
              //On Mobile, PostCreator is wrapped in a div > clicking on div opens up a modal with PostCreator in it.
              <div
                onClick={(e) => {
                  setShow(!show);
                  //event.preventDefault() gives you the ability to prevent a browser’s default behavior for events. e.g. Clicking on a link won't lead anywhere.
                  e.preventDefault();
                }}
              >
                <PostCreator
                  isOwner
                  onPostAdded={onPostAdded}
                  profile={activeProfile}
                  isEmojiDisable
                />
              </div>
            )}

          {feed && feed.length ? (
            feed.map((feedItem) => (
              <PostCard
                key={feedItem.id}
                posterName={`${feedItem.posted_by.organisation
                    ? feedItem.posted_by.organisation.organization_title
                    : feedItem.posted_by.person.full_name
                      .charAt(0)
                      .toUpperCase() +
                    feedItem.posted_by.person.full_name.slice(1)
                  }`}
                activityId={feedItem.id}
                postedBy={feedItem.posted_by}
                postedTo={feedItem.posted_on}
                content={feedItem.body}
                postedOn={feedItem.created_at}
                comments={feedItem.comments}
                reactions={feedItem.reactions}
                photos={feedItem.photos}
                onCommentAdded={fetchFeed}
                onDelete={() => onPostDeleted(feedItem.id)}
                onDeletePic={onPicDeleted}
                fullpost={feedItem}
                isFeed
              />
            ))
          ) : (
              <h1>Nothing</h1>
            )}
        </InfiniteScroll>

        <Modal
          show={show}
          centered
          size="xl"
          onHide={() => setShow(false)}
          style={{ marginTop: "" }}
          className={`modelTopmargin carousalModal ${direction === "rtl" ? "arabic-display" : ""}`}
        >
          <Modal.Header closeButton style={{ padding: "12px 20px"}}>
            <Modal.Title
              style={{
                color: "#515151",
                fontSize: "1.2rem",
                fontWeight: "500",
              }}
            >
              {t("Create Post")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-0">
            <PostCreator
              isOwner
              onPostAdded={onPostAdded}
              profile={activeProfile}
              isModel
              setShow={setShow}
            />

          </Modal.Body>
        </Modal>


      </aside>
    </section>

  )


}

const Home = ({
  activeProfile,
  impactboardlink,
  currentLanguage,
  onLanguageChange
}) => {
  const width = window.innerWidth;
  const [show, setShow] = useState(width < 480 ? true : false);
  return (
    <AppContentContainer classes="container d-flex flex-column flex-md-row" aria-live="assertive">
      <ImpactBoardBanner currentLanguage={currentLanguage} onLanguageChange={onLanguageChange}/>
      <PostFeedConitainer activeProfile={activeProfile} impactboardlink={impactboardlink} />
      <Modal show={show} onHide={() => setShow(false)} aria-labelledby="Open App Modal" dialogClassName="open-app-modal">
        <Modal.Body>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <i className="material-icons mr-1 open-app-icon">smartphone</i>
            <h3 className="open-app-heading">Inclusive works better if you switch to our app</h3>
          </div>
            <div className="open-app-link" onClick={() => window.open('https://inclusiveapp.page.link/openapp', "_self")}>
                Open in App
            </div>
          <Button className="open-app-text" onClick={() => setShow(false)}>Continue with mobile web</Button>
        </Modal.Body>
      </Modal>
    </AppContentContainer>
  );


};

export default connect((state) => ({
  activeProfile: state.user.activeProfile,
  impactboardlink: state.user.impactboardlink,


}))(Home);



const StyledButton = styled.div`

position: fixed;
top: 80px;
left: 46%;
z-index: 99999;

.new-post-button {
  display: flex;
  align-items: center;
  background-color: #3F0E40;
  border-color: #3F0E40;
  font-weight: 600;
  font-style: normal;
  border-radius: 25px;
  padding: 8px 15px;
    &:active {
    background-color: #3F0E40 !important;
    border-color: #3F0E40 !important;
  }
}

.hide-button {
  display: none !important;
}

.show-button {
  display: flex !important;
}

@media(max-width: 768px) {
  left: 40%;
}
@media(max-width: 425px) {
  left: 31%;
}

`