import React, { useEffect, useState, useRef } from "react";

import SearchFilters from "./components/SearchFilters";
import JobsList from "./components/JobList";
import { getJobs, getOrgjobs } from "../../api";
import AccessibleJobs from '../../assets/images/JobsImage.png';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { Mixpanel } from '../../mixpanel';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import AppContentContainer from "../../common/AppContentContainer";

const Jobs = () => {
  const direction = i18n.dir();
  const { t } = useTranslation();
  const [jobs, setJobs] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 1,
  });

  const unmounted = useRef(false);

  useEffect (() => {
    return () => { unmounted.current = true }
  }, []);

  const getJobsList = (page = pagination.current_page, filters) => {
    if (page === 1) {
      setIsSearching(true);
    }

    getOrgjobs({ ...filters, jobstatus: 'A' }, page)
      .then(({ data }) => {
        if (!unmounted.current) {
          Mixpanel.track("joblist",filters);
  
          if (page === 1) {
            setJobs(data.jobs);
          } else {
            setJobs(jobs.concat(data.jobs));
          }
  
          setPagination(data.pagination);
          setIsSearching(false);
        }
      })
      .catch(() => {
        setIsSearching(false);
      });
  };

  const onLoadNext = () => {
    getJobsList((pagination.current_page += 1));
  };

  useEffect(() => {
    getJobsList(1, {
      q: null,
      cat: null,
      city: null,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppContentContainer classes="container d-flex flex-column flex-md-row">
      <SearchFilters onApplyFilters={(filters) => getJobsList(1, filters)} />
      <section className="ic-app-content-right">
        <aside className={direction === "rtl" ? "display-aside-rtl" : "display-aside-ltr"}>
          <StyledJobCard>
            <div className="job-card d-flex flex-column" aria-label="Job card">
              <Row className="align-items-center accessible-jobs-container">
                {/* <Col md={5} className="accessible-jobs-text">
                  <h1>
                    {t("Apply to")} <strong>{t("Accessible")}</strong>
                  </h1>
                </Col> */}
                <Col md={12} className="accessible-jobs-image px-0">
                  <img src={AccessibleJobs} alt="Two people with different abilities, one dreaming to be a ballerina and the other to be an astronaut."/>
                </Col>
              </Row>
            </div>
          </StyledJobCard>
          <JobsList
            jobs={jobs}
            onLoadNext={onLoadNext}
            hasMore={pagination.current_page < pagination.total_page}
            isSearching={isSearching}
          />
        </aside>
      </section>
    </AppContentContainer>
  );
};

export default Jobs;

const StyledJobCard = styled.div`
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;

  .accessible-jobs-container {
    // padding: 2.5rem 3.6rem;
    padding: 0;
    height: 300px;
  }

  .accessible-jobs-text h1 {
    font-size: 40px;
    color: #000000;
  }

  .accessible-jobs-image img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 440px) {
    display: none !important;
  }
`;
