// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {NavLink as Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";


export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,

) {
 // const history = useHistory()
  // const handleManageJob=(row)=>{
    
  //   history.push(`/org/jobdetail/${row.slug}/${row.id}`)
  // }

  return (
    
      // <Link to={`/org/jobdetail/${row.slug}/${row.id}`}><span onClick={()=>handleManageJob(row)} tabIndex="0" className="btn btn-primary font-weight-bolder font-size-sm">Manage</span></Link>
      <Link to={`/org/jobdetail/${row.slug}/${row.id}`}><span className="btn btn-primary font-weight-bolder font-size-sm">Manage</span></Link>
     
      /*{
      <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
      } */

  );
}
