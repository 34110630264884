import React, { useState, useRef } from "react";
import { format } from "date-fns";

import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import AddEditEducation from "./AddEditEducation";
import { deleteEducation } from "../../../api";

const EducationHistory = ({
  education,
  isOwner,
  refreshProfile,
  profileId,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  return (
    <div className="user-ability user-data-wrapper ">
      <div className="user-story-header">
        <div>
          <h2 style={{ display: 'flex' }}>Education & Certifications
            {isOwner && (
              <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Education Tooltip" aria-pressed={showTooltip}>
                <i className="material-icons help-icon">help_outline</i>
              </Button>
            )}
          </h2>
          <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
            {(props) => (
              <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                What is the education you have completed so far? Please add accurate details.
              </Tooltip>
            )}
          </Overlay>
        </div>
        {isOwner ? (
          <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600', cursor: 'pointer' }} onClick={() => setIsAdding(true)}>add</i>
        ) : (
          ""
        )}
      </div>
      <div>
        <hr />
        {education && education.length ? (
          education.map((edu, index) => (
            <EducationItem
              edulength={education.length}
              profileId={profileId}
              key={edu.id}
              education={edu}
              index={index}
              isOwner={isOwner}
              refreshProfile={refreshProfile}
            />
          ))
        ) : (
          <h3 className="pb-2 text-red pt-2">
            {isOwner
              ? "You have not added any education history yet."
              : "This user has not added any education history yet."}
          </h3>
        )}
      </div>

      {isOwner ? (
        <AddEditEducation
          isEditing={false}
          show={isAdding}
          closeDialog={() => setIsAdding(false)}
          onUpdated={refreshProfile}
        />
      ) : null}
    </div>
  );
};

const EducationItem = ({
  education,
  index,
  isOwner,
  refreshProfile,
  edulength,
  profileId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = () => {
    setIsDeleting(true);

    deleteEducation(profileId, education.id)
      .then(() => {
        setIsDeleting(false);

        refreshProfile();
      })
      .catch(() => {
        setIsDeleting(false);

        refreshProfile();
      });
  };

  return (
    <div
      className="abilities-wrapper align-items-center"
      style={{
        borderBottom: `${
          edulength - 1 === index ? "none" : "1px solid #EFEFEF"
        }`,
        paddingTop: index === 0 ? 0 : '1rem', paddingBottom: index + 1 === edulength ? 0 : '1rem'
      }}
    >
      <div className="companywrapper">
        {/* <img src={book} /> */}
        <div className="companyDetails pl-0">
          <div className="position">{education.education_summary}</div>
          <div className="company-name">{education.education_title}</div>
          <div className="period">
            {education.education_end_present ? (
              <span>
                {format(new Date(education.education_start), "MMM yyyy")} -
                Present
              </span>
            ) : (
              <span>
                {format(new Date(education.education_start), "MMM yyyy")} -{" "}
                {format(new Date(education.education_end), "MMM yyyy")}
              </span>
            )}
          </div>
          {/* <div className="company-loc">Islamabad, Pakistan</div> */}
        </div>
      </div>
      <div>
        {isOwner ? (
          <div className="d-flex align-items-center">
            <div style={{ cursor: "pointer" }} onClick={() => setIsEditing(true)}>
              <i className="material-icons" alt="Edit Icon">edit</i>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="pl-2"
              onClick={() => onDelete(education.id)}
            >
              {isDeleting ? (
                <i className="fas fa-spinner fa-spin" />
              ) : (
                <i className="material-icons" alt="Delete Icon">delete</i>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {isOwner ? (
        <AddEditEducation
          show={isEditing}
          isEditing
          educationId={education.id}
          closeDialog={() => setIsEditing(false)}
          currentValues={{
            education_title: education.education_title,
            education_summary: education.education_summary,
            education_start: education.education_start,
            education_end: education.education_end,
            education_end_present: education.education_end_present,
            education_loc: "Islamabad, Pakistan",
          }}
          onUpdated={refreshProfile}
        />
      ) : null}
    </div>
  );
};

export default EducationHistory;
