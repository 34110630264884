import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import UserSearch from "./UserSearch";
import NotificationsDropdown from ".//NotificationsDropdown";
import ProfileDropdown from "./ProfileDropdown";
import ImInclusive from '../../assets/images/ImInclusiveLogo.png';
import { setImpactboardlink } from "../../redux/user";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const NavigationBar = ({ setImpactboardlink, impactboardlink, currentLanguage, onLanguageChange }) => {
  
  const direction = i18n.dir();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const handleHomeClick = () => {
    setImpactboardlink(!impactboardlink);
  };

  return (
    <HeaderNavBar className={i18n.language === 'ar' ? 'arabic-display' : ''}>
      <Navbar
        expanded={expanded}
        collapseOnSelect
        className="container"
        expand="lg"
        bg="white"
        variant="light"
        id="navigationBar"
      >
        <Navbar.Brand className="py-0 mr-0">
          <Link to="/" aria-label="Inclusive Homepage">
              <div className="logo-container">
                <img src={ImInclusive} alt="Inclusive Logo"/>
              </div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : true)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={direction === "rtl" ? "mr-auto" : "ml-auto"}>
            <UserSearch setExpanded={setExpanded} />

            <Link
              className={`nav-link ${direction === "rtl" ? "mx-lg-4" : "mx-lg-4"}`}
              aria-label="Home"
              to="/impactboard"
              onClick={() => {
                setExpanded(false);
                handleHomeClick();
              }}
            >
              {t("HOME")}
            </Link>
            <Link
              className={`nav-link ${direction === "rtl" ? "ml-lg-4" : "mr-lg-4"}`}
              aria-label="Opportunities"
              to="/opportunities"
              onClick={() => setExpanded(false)}
            >
              {/* {t("JOBS")} */}
              OPPORTUNITIES
            </Link>
            <Link
              className={`nav-link ${direction === "rtl" ? "ml-lg-4" : "mr-lg-4"}`}
              aria-label="learn"
              to="/learn"
              onClick={() => setExpanded(false)}
            >
              {/* {t("JOBS")} */}
              LEARN
            </Link>
            <NotificationsDropdown setExpanded={setExpanded} />
            <ProfileDropdown setExpanded={setExpanded} currentLanguage={currentLanguage} onLanguageChange={onLanguageChange}/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </HeaderNavBar>
  );
};

export default connect(
  (state) => ({
    impactboardlink: state.user.impactboardlink,
  }),
  {
    setImpactboardlink,
  }
)(NavigationBar);

const HeaderNavBar = styled.div`
  font-family: "Inter", sans-serif;
  position: fixed;
  width: 100%;
  z-index: 10000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  background-color: #fff !important;

  .nav-link {
    color: #5F5F5F !important;
  }

  .nav-link.active {
    color: #000 !important;
  }

  .container {
    max-width: 1104px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    @media(max-width: 560px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .logo-container {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-container img {
    width: 100%;
    object-fit: cover;
  }

  .profile-dropdown {
    border: 1px solid #c4c4c4;
    width: 41px;
    height: 41px;
    border-radius: 50px;
  }

  .profile-dropdown:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu.dropdown-menu.show {
    right: 0;
    left: auto;
  }

  a.dropdown-item {
    padding: 0.8rem;
    display: flex;
    align-items: center;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }

  // .dropdown-item:hover {
  //     color: #16181b;
  //     text-decoration: none;
  //     background-color: #f8f8f8;
  //     border-left: 2px solid grey;
  // }

  .dropdown-divider {
    margin: 0;
  }

  ul.list-unstyled {
    margin-bottom: 0;
  }

  .show-on-mobile {
    display: none;
  }

  @media (max-width: 991px) {
    .hide-on-mobile {
      display: none;
    }
    .show-on-mobile {
      display: block;
      color: rgba(0, 0, 0, 0.5);
      padding: 8px 0;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    a#dropdownUserMenu {
      text-decoration: none;
    }
    i.fas.fa-caret-down {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 991px) {
    .navbar-collapse {
      box-shadow: none !important;
      border: none !important;
    }

  }
`;
