import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "../../../../../redux/toast";
import CountryCity from "../../../../../components/CountryCity";
import { getOrganizationJobLocations, addOrganizationJobLocation, deleteOrganizationJobLocation, getOrganizationJobLocationDetail } from '../../../../../api';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';


const JobLocation = ({ jobSlug, jobId, setCityId, orgCity, setOrgCity, orgCountry, setOrgCountry, location, locations, setLocations, sectionValid, handleChange, handleBlur, values, errors, touched, setUserCity, setMultipleLocation }) => {

    const [clearFields, setClearFields] = useState(false);
    const [cityValid, setCityValid] = useState(false);
    const [validLocation, setValidLocation] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        getOrganizationJobLocations(jobSlug)
        .then(({ data }) => {
            if (data.length) {
                for (let i = 0; i < data.length; i++) {
                    const loc = data[i].job_city;
                    if (i === 0) {
                        setLocations([{ id: data[i].id, cityId: loc.id, cityName: loc.city_name, countryId: loc.city_country.id, countryName: loc.city_country.country_name }]);
                    }
                    else {
                        setLocations(locations => [...locations, { id: data[i].id, cityId: loc.id, cityName: loc.city_name, countryId: loc.city_country.id, countryName: loc.city_country.country_name }]);
                    }
                }
            }
            else {
                setLocations([{ id: null, cityId: null, cityName: '', countryId: null, countryName: '' }]);
            }
        });
    },[]);

    useEffect(() => {
        setClearFields(false);
        if (locations.length > 1) {
            setMultipleLocation(true);
        }
        else {
            setMultipleLocation(false);
        }
    }, [locations]);

    useEffect(() => {
        if (locations[locations.length - 1].cityId !== null && locations[locations.length - 1].countryId !== null) {
            setValidLocation(true);
        }
    }, [locations[locations.length - 1].cityName]);

    const handleAddLocation = () => {
        if (locations[locations.length - 1].cityId === null || locations[locations.length - 1].countryId === null) {
            setValidLocation(false);
        }
        else {
            if (locations[locations.length - 1].id === null) {
                const city = locations[locations.length-1].cityId;
                addOrganizationJobLocation(jobSlug, { job: jobId, job_city: Number(city) })
                .then(({ data }) => {
                    locations[locations.length-1].id = data.id;
                })
                .catch(() => {});
            }
            setValidLocation(true);
            setLocations(locations => [...locations, { id: null, cityId: null, cityName: '', countryId: null, countryName: '' }]);
        }
    };

    const handleRemoveLocation = (index, locationId) => {
        if (locationId !== null) {
            deleteOrganizationJobLocation(jobSlug, locationId)
            .then(() => {
                if (locations.length === 1) {
                    setLocations([{ id: null, cityId: null, cityName: '', countryId: null, countryName: '' }]);
                }
                else {
                    const filtered = locations.slice(0, index).concat(locations.slice(index + 1));
                    setLocations(filtered);
                }
            })
            .catch(() => {
                dispatch(
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    })
                )
            });
        }
        else {
            if (locations.length === 1) {
                setLocations([{ id: null, cityId: null, cityName: '', countryId: null, countryName: '' }]);
            }
            else {
                const filtered = locations.slice(0, index).concat(locations.slice(index + 1));
                setLocations(filtered);
            }
        }
        setClearFields(true);
    };
    
    return (
        <>
        {location === 'Organization' && (
            <>
            <Form.Row>
                <Form.Group as={Col} md={12} col={12}>
                    <Form.Label>Main Branch Address<span style={{ color: "#dc3545" }}> *</span></Form.Label>
                    <Form.Control
                        placeholder="Main Branch Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="organization_address"
                        isInvalid={(touched.organization_address && errors.organization_address) || (!sectionValid && values.organization_address === "")}
                        value={values.organization_address}
                        type="text"
                        as="input"
                        autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.organization_address}
                    </Form.Control.Feedback>
                    {(!sectionValid && values.organization_address === "" && !touched.organization_address) && (
                        <Form.Label className="invalid-section-feedback">Please provide the Organization Address.</Form.Label>
                    )}
                </Form.Group>
            </Form.Row>


            <CountryCity
                citytext="Main Branch City"
                countrytext="Main Branch Country"
                cityValid={cityValid}
                setCityValid={setCityValid}
                setUserCity={setCityId}
                person_city={null}
                orgForm={true}
                orgCity={orgCity}
                setOrgCity={setOrgCity}
                orgCountry={orgCountry}
                setOrgCountry={setOrgCountry}
            />
            {(!sectionValid && (orgCity === '' || orgCountry === '')) && (
                <Form.Label className="invalid-section-feedback">Please select atleast one city and country as the organization location.</Form.Label>
            )}
            </>
        )}

        {location === 'Job' && (
            <>
            <Form.Label className="mb-0 py-3">Please select one or more locations for the job.</Form.Label>
            {locations.map((location, index) => (
                <div key={index} className="d-flex align-items-center justify-content-between">
                    <div style={{ width: (location.cityId === null || location.countryId === null) ? '100%' : '96.5%' }}>
                        <CountryCity
                            citytext="City"
                            countrytext="Country"
                            cityValid={cityValid}
                            setCityValid={setCityValid}
                            setUserCity={setUserCity}
                            person_city={null}
                            jobForm={true}
                            location={location}
                            clearFields={clearFields}
                        />
                    </div>
                    {(location.cityId !== null && location.countryId !== null) && (
                    <Button className="add-question-btn mt-3" onClick={() => handleRemoveLocation(index, location.id)} aria-label="Remove Location">
                        <i className="material-icons">cancel</i>
                    </Button>
                    )} 
                </div>
            ))}
            {!validLocation && (
                <Form.Label className="invalid-section-feedback">Please select country and city.</Form.Label>
            )}
            <Button className="add-question-btn" onClick={() => handleAddLocation()} aria-label="Add Location">
                <i className="material-icons mr-1">add_circle</i> Add Location
            </Button>
            {(!sectionValid && locations[0].cityId === null) && (
                <Form.Label className="invalid-section-feedback">Please select atleast one city and country as the job location.</Form.Label>
            )}
            </>
        )}
        </>
    )
};

export default JobLocation;


