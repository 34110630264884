import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { addToast } from "../../../redux/toast";
import { getUserAppliedJobs } from "../../../redux/user";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import ListGroup from 'react-bootstrap/ListGroup';
import AppContentContainer from "../../../common/AppContentContainer";
import JobApplyModal from './JobApplyModal';
import { getOrganizationJobBySlug } from "../../../api";
import { LoaderWithText } from "../../../components/Loader";
import { processDate, processDateArabic, getTimeAgo } from "../../../common/utils";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const JobDetail = ({ activeProfileMeta, activeProfile, appliedJobs, getUserAppliedJobs, addToast }) => {
  const direction = i18n.dir();
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const { jobslug } = useParams();

  const [show, setShow] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [JobLoading, setJobLoading] = useState(false);
  const [isAccessible, setAccessible] = useState(false);
  const [multipleLocation, setMultipleLocation] = useState(false);

  const completeProfile = useSelector(state => state.user.completeProfile);

  const isAppliedJob = (jobId) => appliedJobs.find((attendingJob) => attendingJob.job.id === jobId);
  const attendance = currentJob ? isAppliedJob(currentJob.id) : false;
  const isApplied = attendance || false;
  
  const onApply = () => {
    getUserAppliedJobs();
  };

  const Job = () => {
    setCurrentJob(null);
    setJobLoading(true);

    //If One of the accessibility categories are true, setAccessible to true.
    getOrganizationJobBySlug(jobslug)
      .then(({ data }) => {
        if (data.accessibility_cognitive || data.accessibility_hearing || data.accessibility_mobility || data.accessibility_sensory_friendly || data.accessibility_visual) {
            setAccessible(true);
          }
        if (data.job_location_cities.length > 1) {
          setMultipleLocation(true);
        }
        setCurrentJob(data);
        setJobLoading(false);
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (jobslug) {
      Job();
    } 
  }, [jobslug]);


  let applyButton = null;

  //Remove apply button if job close date has passed or if active profile is Organization Profile.
  //Else link the Apply button to job apply modal.
  if (new Date() > new Date(currentJob && currentJob.job_close_time) || activeProfileMeta && activeProfileMeta.type === "org") {
    applyButton = "";
  } 
  else {
    applyButton = (
      <Button id="apply-job" aria-label="Apply to Job" onClick={() => { completeProfile ? setShow(true) : addToast({ type: "error", title: "Error", text: "Please complete your profile to apply for jobs." }) }} disabled={isApplied ? true : false}>
        <i className={`material-icons ${direction === "rtl" ? "ml-2" : "mr-2"}`} style={{ fontSize: "20px" }}>
          launch
        </i>
        {isApplied ? t("Applied") : t("Apply")}
      </Button>
    );
  } 

  if (JobLoading)
    return <LoaderWithText minHeight="78vh" text={t("Please wait...")} />;

  return (
    <AppContentContainer classes="container d-flex flex-column flex-md-row">
      {activeProfileMeta.type === "individual" &&
        <JobApplyModal show={show} setShow={setShow} job={currentJob} profileId={activeProfileMeta.id} profileType={activeProfileMeta.type} profile={activeProfile} onApply={onApply} />
      }
      {currentJob && (
        <StyledJobDetail>
          <div className="job-header-card d-flex flex-column">

            <Row className="d-flex job-header-details align-items-center show-on-regular">
              <Col
                xs={2}
                className={`job-logo d-flex justify-content-center`}
                style={{ flex: '0 0 11%' }}
              >
                <div className="job-logo-container">
                  <img
                    src={
                      currentJob.job_organization_logo &&
                      currentJob.job_organization_logo !==
                        "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
                        ? currentJob.job_organization_logo
                        : currentJob.organization
                        ? currentJob.organization.organization_logo
                        : currentJob.listed_by.organization_logo
                    }
                    className="job-img"
                    alt="job-img"
                  />
                </div>
              </Col>
              <Col xs={9} className={`job-headline pl-0`} style={{ flex: '0 0 80.4%', maxWidth: '80.4%' }}>
                <span className="job-title">{currentJob.job_title}</span>
                <span className="job-organization">
                  {currentJob.job_organization}
                </span>
              </Col>
              <Col xs={1} className="d-flex justify-content-end">
                <div className="job-time-ago">
                  {getTimeAgo(currentJob.created_at).includes('month') ?
                    '30d+' : (
                      getTimeAgo(currentJob.created_at)
                        .replace("about ", "")
                        .replace(" hour", "h")
                        .replace("s", "")
                        .replace(" ago", "")
                        .replace(" day", "d")
                        .replace(" minute", "m")
                        .replace("les than am", "just now")
                    )}
                </div>
              </Col>
            </Row>
            <Row className="job-header-details align-items-center show-on-mobile">
              <Col
                xs={3}
                className="job-logo d-flex justify-content-center px-0"
              >
                <div className="job-logo-container">
                  <img
                    src={
                      currentJob.job_organization_logo &&
                        currentJob.job_organization_logo !==
                        "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
                        ? currentJob.job_organization_logo
                        : currentJob.organization
                          ? currentJob.organization.organization_logo
                          : currentJob.listed_by.organization_logo
                    }
                    className="job-img"
                    alt="job-img"
                  />
                </div>
              </Col>
              <Col xs={9} className={`job-headline ${direction === "rtl" ? "pr-0" : "pl-0"}`}>
                <span className="job-title">{currentJob.job_title}</span>
                <span className="job-organization">
                  {currentJob.job_organization}
                </span>
              </Col>
            </Row>

            <div
              style={{ borderBottom: "1px solid #e9e9e9" }}
            ></div>

            <div className="job-detail-footer d-flex flex-column">
              <Row className="d-flex job-footer align-items-center">
                <Col xs={7} className="job-close-date">
                  {t("Closes on")}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {direction === "rtl" ? 
                    (processDateArabic(currentJob.job_close_time, "PP")) 
                    : 
                    (processDate(currentJob.job_close_time, "PP"))
                    }
                  </span>
                </Col>
                <Col xs={5} className="job-apply-button">
                  {applyButton}
                </Col>
              </Row>
            </div>
          </div>

          <div className="job-detail-card d-flex flex-column">
            <div className="job-detail-header d-flex flex-column pb-3">
              <Row className="d-flex align-items-start">
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Industry")}</span>
                  <div>
                    {currentJob.organization.organization_industry.industry_title && t(currentJob.organization.organization_industry.industry_title)}
                  </div>
                </Col>
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Employment Type")}</span>
                  <div>
                    {currentJob.job_type === "F" ? t("Full-time") : ""}
                    {currentJob.job_type === "P" ? t("Part-time") : ""}
                    {currentJob.job_type === "C" ? t("Contract") : ""}
                    {currentJob.job_type === "T" ? t("Temporary") : ""}
                    {currentJob.job_type === "O" ? t("Other") : ""}
                    {currentJob.job_type === "V" ? t("Volunteer") : ""}
                  </div>
                </Col>
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Experience Level")}</span>
                  <div className="">
                    {currentJob.job_experience_level === "N" ? t("Not Applicable") : ""}
                    {currentJob.job_experience_level === "I" ? t("Internship") : ""}
                    {currentJob.job_experience_level === "E" ? t("Entry Level") : ""}
                    {currentJob.job_experience_level === "A" ? t("Associate") : ""}
                    {currentJob.job_experience_level === "M" ? t("Mid-Senior Level") : ""}
                    {currentJob.job_experience_level === "MA" ? t("Managerial") : ""}
                    {currentJob.job_experience_level === "D" ? t("Director") : ""}
                    {currentJob.job_experience_level === "X" ? t("Executive") : ""}
                  </div>
                </Col>
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Workspace")}</span>
                  {currentJob.job_location && (
                    <div className="">
                      {currentJob.job_location === "R" ? t("Remote") : ""}
                      {currentJob.job_location === "H" ? t("Hybrid (Remote & Office)") : ""}
                      {currentJob.job_location === "C" ? t("Co-working Space") : ""}
                      {currentJob.job_location === "O" ? t("Office") : ""}
                    </div>
                  )}
                </Col>
              </Row>
              {currentJob.job_pay_interval && currentJob.job_pay_range ? (
                <Row className="d-flex align-items-start justify-content-start pt-2">
                    <Col md={3} className="job-category">
                    <span className="job-heading">{t("Pay Interval")}</span>
                    <div>
                      {currentJob.job_pay_interval === "H" ? t("Hourly") : ""}
                      {currentJob.job_pay_interval === "D" ? t("Daily") : ""}
                      {currentJob.job_pay_interval === "B" ? t("Bi-Weekly") : ""}
                      {currentJob.job_pay_interval === "S" ? t("Semi-Monthly") : ""}
                      {currentJob.job_pay_interval === "M" ? t("Per Month") : ""}
                      {currentJob.job_pay_interval === "Y" ? t("Per Year") : ""}
                      {currentJob.job_pay_interval === "NA" ? t("NA") : ""}
                    </div>
                    </Col>
                    <Col md={3} className="job-category">
                    <span className="job-heading">{t("Pay Range")}</span>
                    <div>
                        {currentJob.job_pay_range && t(currentJob.job_pay_range)}
                    </div>
                    </Col>
                </Row>
              ) : ""}
              {currentJob.job_location_cities.length ? (
                <Row className="d-flex align-items-start justify-content-start pt-2">
                  <Col md={12} className="job-category">
                    <span className="job-heading">{multipleLocation ? "Locations" : "Location"}</span>
                    <div className="skill-pills-container pt-1">
                      <ListGroup horizontal className="skill-pills">
                        {currentJob.job_location_cities.map((location) => (
                          <ListGroup.Item className="skill-pill-item" key={location.id} style={{ backgroundColor: '#fff', borderColor: '#8c8c8c', borderWidth: 1, color: '#000' }}>
                            <span className="skill-pill-title">
                              {location.job_city.city_name}, {location.job_city.city_country.country_name}
                            </span>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  </Col>
                </Row>
              ) : ""}
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                  <h2>{t("Job Description")}</h2>
                  <pre className="preTextwrap card-text">
                    <p className="mb-0">
                      {currentJob.job_description && currentJob.job_description}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            {currentJob.job_skills && currentJob.job_skills.length ? (
              <>
            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                  <h2>{t("Skills Required")}</h2>
                    <div className="skill-pills-container">
                        <ListGroup horizontal className="skill-pills">
                            {currentJob.job_skills.map((skill) => (
                                <ListGroup.Item className="skill-pill-item" key={skill.id} value={skill.job_skill}>
                                    <span className="skill-pill-title">
                                        {skill.job_skill}
                                    </span>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>                   
                    </div>
                </div>
              </Row>
            </div>
            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
            </>
            ) : ""}     

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                <h2>{t("Education & Qualifications")}</h2>
                  <pre className="preTextwrap">
                    <p className="mb-0">
                      {currentJob.job_education && currentJob.job_education}
                    </p>
                  </pre>
                </div>
              </Row>
              <Row className="d-flex job-description align-items-center">
                <div>
                <h2>{t("Perks & Benefits")}</h2>
                  <pre className="preTextwrap card-text">
                    <p className="mb-0">
                      {currentJob.job_perks && currentJob.job_perks}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            {isAccessible && (
              <>
              <div className="job-detail-body d-flex flex-column">
                <Row className="d-flex job-accessibility flex-column">
                    <h2 style={{ display: 'flex' }}>
                      {t("Performance Drivers")}
                      <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Performance Drivers Tooltip" aria-pressed={showTooltip}>
                        <i className="material-icons help-icon">help_outline</i>
                      </Button>
                    </h2>
                    <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                      {(props) => (
                        <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                          With performance drivers, employers can build a truly equitable workplace. Performance drivers offer a range of baked-in, in-built, or additional 30 accessibility options within a range of five key drivers. When an employer uses the super simple process of posting a job with performance drivers, they choose to inclusive hiring of persons with disabilities.
                        </Tooltip>
                      )}
                    </Overlay>
                    {currentJob.accessibility_hearing && (
                      <>
                      {(multipleLocation || currentJob.job_accommodation_hearing.length > 0) && (
                        <span className={`job-heading ${multipleLocation ? 'mb-0' : ''}`}>{t("Communication Drivers")}</span>
                      )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">This job offers different ways through which all employees can communicate with each other equally.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_hearing.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_cognitive && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_cognitive.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Learning Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">Everyone learns differently, this job offers options to enable different learning styles.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_cognitive.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_mobility && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_mobility.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Movement Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">This job provides accessibility infrastructure for someone using a mobility aid or wheelchair.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_mobility.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_sensory_friendly && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_sensory.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Neurodivergent Talent Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">This job offers options to enable individuals with cognitive abilities to perform equally at the workplace.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_sensory.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_visual && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_visual.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Vision Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info">If a person with visual differences joins the workforce, this job provides them equal access.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_visual.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
              </Row>
            </div>
            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
            </>
            )}

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                 <h2>{t("Disclaimer of Equal Employment")}</h2>
                  <pre className="preTextwrap card-text">
                    <p className="mb-0">
                      {currentJob.job_disclaimer && currentJob.job_disclaimer}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-org-description align-items-center">
                <h2 className="">
                  {t("About")} {currentJob.job_organization}
                </h2>
                <div className="mt-2">
                  <pre className="preTextwrap card-text">
                    <p className="mb-0">
                      {currentJob.job_organization_detail && currentJob.job_organization_detail}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            {/* <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            <div className="job-detail-footer d-flex flex-column">
              <Row className="d-flex job-footer align-items-center">
                <Col xs={7} className="job-close-date">
                  {t("Closes on")}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {direction === "rtl" ? 
                    (processDateArabic(currentJob.job_close_time, "PP")) 
                    : 
                    (processDate(currentJob.job_close_time, "PP"))
                    }
                  </span>
                </Col>
                <Col xs={5} className="job-apply-button">
                  {applyButton}
                </Col>
              </Row>
            </div> */}
          </div>
        </StyledJobDetail>
      )}
    </AppContentContainer>
  );
};

export default connect((state) => ({
  activeProfileMeta: state.user.activeProfileMeta,
  appliedJobs: state.user.appliedJobs,
}),   
{
  getUserAppliedJobs,
  addToast
}
)(JobDetail);

const StyledJobDetail = styled.div`
  //font-family: "Inter", sans-serif;
  width: 100%;
  color: #000000;

  .job-header-card {
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
  }

  .job-header {
    padding: 20px 24px;
    color: #ffffff;
    background-color: #000000;
    font-size: 1.1rem;
    opacity: 0.8;
  }

  .job-badge-icon {
    display: flex;
    justify-content: flex-end;
  }

  .job-header-details {
    padding: 1.8rem 2rem;
  }

  .job-detail-header,
  .job-detail-body {
    padding: 24px;
  }

  .job-description,
  .job-accessibility,
  .job-org-description {
    padding: 0 1rem;
  }

  pre.preTextwrap {
    color: #000000;
  }

  .job-logo-container {
    width: 84px;
    height: 84px;
    @media(max-width: 560px) {
      width: 60px;
      height: 60px;
    }
  }

  .job-logo-container img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    object-fit: contain;
  }

  .job-headline {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .job-title {
    font-size: 1.8rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .job-heading {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .job-organization {
    font-size: 1.2rem;
    font-weight: 500;
    color: #5F5F5F;
  }

  .job-time-ago {
    font-size: 1.1rem;
    font-weight: 500;
    color: #5F5F5F;
  }

  .job-accessibility > .row {
    padding: 0 14px;
  }

  .skill-pills-container {
    // padding-bottom: 0.5rem;
    // padding-top: 0.5rem;
}

.skill-pills {
    flex-wrap: wrap;
}

.skill-pill-item {
    background: #3F0E40;
    border-radius: 25px !important;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    list-style: none;
    padding: 0 !important;
    margin-bottom: 0.5rem;
    
    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.skill-pill-title {
    padding: 4px 10px;
    align-items: center;
    display: flex;
}

  .job-apply-button {
    display: flex;
    justify-content: flex-end;
  }

  #apply-job {
    background-color: #3F0E40;
    border-color: #3F0E40;
    border-radius: 7px;
    font-weight: 500;
    opacity: 0.9;
    display: flex;
    align-items: center;
  }

  .btn.disabled, .btn:disabled {
    cursor: default;
  }

  .job-footer {
    padding: 20px 24px;
    font-size: 1.1rem;
  }

  .job-close-date {
    color: #000000;
  }

  .job-detail-card {
    margin-top: 25px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
  }

  .show-on-mobile {
    display: none;
  }

  @media (max-width: 440px) {
    .job-header-card {
      margin-top: 50px;
    }

    .show-on-regular {
      display: none !important;
    }
    .show-on-mobile {
      display: flex !important;
    }
    .job-header-details {
      padding: 1.4rem;
    }
    .job-title {
      font-size: 1.2rem;
    }
    .job-organization {
      font-size: 1rem;
    }
    .job-close-date {
      font-size: 0.9rem;
    }
    .job-heading {
      font-size: 1rem;
    }
    h2 {
      font-size: 1.1rem;
    }
    .skill-pill-title {
      font-size: 0.9rem;
    }
    p {
      font-size: 1rem;
    }
    .job-category {
      margin: 0.4rem 0;
    }
    .job-detail-header,
    .job-detail-body,
    .job-footer {
      padding: 20px;
    }
  }
`;
