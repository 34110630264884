import React, { useState, useEffect } from "react"; 
import { useDispatch } from "react-redux";
import { addToast } from "../../../../../redux/toast";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ListGroup from 'react-bootstrap/ListGroup';
import Form from "react-bootstrap/Form";
import { accommodationsCognitiveList, accommodationsHearingList, accommodationsMobilityList, accommodationsSensoryList, accommodationsVisualList,
        getJobAccommodationsCognitive, addJobAccommodationCognitive, deleteJobAccommodationCognitive, getJobAccommodationCognitiveDetail,
        getJobAccommodationsHearing, addJobAccommodationHearing, deleteJobAccommodationHearing, getJobAccommodationHearingDetail,
        getJobAccommodationsMobility, addJobAccommodationMobility, deleteJobAccommodationMobility, getJobAccommodationMobilityDetail,
        getJobAccommodationsSensory, addJobAccommodationSensory, deleteJobAccommodationSensory, getJobAccommodationSensoryDetail,
        getJobAccommodationsVisual, addJobAccommodationVisual, deleteJobAccommodationVisual, getJobAccommodationVisualDetail 
} from '../../../../../api';


const JobAccessibilityAccommodations = ({ editing, sectionValid, jobSlug, remoteWorkspace, multipleLocation, cognitiveFriendly, hearingFriendly, mobilityFriendly, sensoryFriendly, visualFriendly, setCognitiveFriendly, setHearingFriendly, setMobilityFriendly, setSensoryFriendly, setVisualFriendly }) => {

  const [cognitiveAccommodationsList, setCognitiveAccommodationsList] = useState([]);
  const [hearingAccommodationsList, setHearingAccommodationsList] = useState([]);
  const [mobilityAccommodationsList, setMobilityAccommodationsList] = useState([]);
  const [sensoryAccommodationsList, setSensoryAccommodationsList] = useState([]);
  const [visualAccommodationsList, setVisualAccommodationsList] = useState([]);
  
  const [accommodationsCognitive, setAccommodationsCognitive] = useState([]);
  const [accommodationsHearing, setAccommodationsHearing] = useState([]);
  const [accommodationsMobility, setAccommodationsMobility] = useState([]);
  const [accommodationsSensory, setAccommodationsSensory] = useState([]);
  const [accommodationsVisual, setAccommodationsVisual] = useState([]);

  const dispatch = useDispatch();

  //Get the list of accommodation filters for all 5 accessibility types.
  //If some filters are selected, show the filtered list without the selected ones. 
  useEffect(() => {
    getJobAccommodationsCognitive(jobSlug)
    .then(({data}) => {
        setAccommodationsCognitive(data);
        const accommodations = data;
        if (accommodations.length) {
            accommodationsCognitiveList()
            .then(({data}) => {
                const filtered = data;
                for (let i = 0; i < accommodations.length; i++) {
                    filtered.splice(filtered.findIndex((fil) => fil.id === accommodations[i].accommodation.id), 1);
                }
                if (remoteWorkspace) {
                    setCognitiveAccommodationsList(filtered.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setCognitiveAccommodationsList(filtered);
                }
            })
        }
        else {
            accommodationsCognitiveList()
            .then(({data}) => {
                if (remoteWorkspace) {
                    setCognitiveAccommodationsList(data.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setCognitiveAccommodationsList(data);
                }
            })
        }
    })
    getJobAccommodationsHearing(jobSlug)
    .then(({data}) => {
        setAccommodationsHearing(data);
        const accommodations = data;
        if (accommodations.length) {
            accommodationsHearingList()
            .then(({data}) => {
                const filtered = data;
                for (let i = 0; i < accommodations.length; i++) {
                    filtered.splice(filtered.findIndex((fil) => fil.id === accommodations[i].accommodation.id), 1);
                }
                if (remoteWorkspace) {
                    setHearingAccommodationsList(filtered.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setHearingAccommodationsList(filtered);
                }
            })
        }
        else {
            accommodationsHearingList()
            .then(({data}) => {
                if (remoteWorkspace) {
                    setHearingAccommodationsList(data.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setHearingAccommodationsList(data);
                }
            })
        }
    })
    getJobAccommodationsMobility(jobSlug)
    .then(({data}) => {
        setAccommodationsMobility(data);
        const accommodations = data;
        if (accommodations.length) {
            accommodationsMobilityList()
            .then(({data}) => {
                const filtered = data;
                for (let i = 0; i < accommodations.length; i++) {
                    filtered.splice(filtered.findIndex((fil) => fil.id === accommodations[i].accommodation.id), 1);
                }
                if (remoteWorkspace) {
                    setMobilityAccommodationsList(filtered.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setMobilityAccommodationsList(filtered);
                }
            })
        }
        else {
            accommodationsMobilityList()
            .then(({data}) => {
                if (remoteWorkspace) {
                    setMobilityAccommodationsList(data.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setMobilityAccommodationsList(data);
                }
            })
        }
    })
    getJobAccommodationsSensory(jobSlug)
    .then(({data}) => {
        setAccommodationsSensory(data);
        const accommodations = data;
        if (accommodations.length) {
            accommodationsSensoryList()
            .then(({data}) => {
                const filtered = data;
                for (let i = 0; i < accommodations.length; i++) {
                    filtered.splice(filtered.findIndex((fil) => fil.id === accommodations[i].accommodation.id), 1);
                }
                if (remoteWorkspace) {
                    setSensoryAccommodationsList(filtered.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setSensoryAccommodationsList(filtered);
                }
            })
        }
        else {
            accommodationsSensoryList()
            .then(({data}) => {
                if (remoteWorkspace) {
                    setSensoryAccommodationsList(data.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setSensoryAccommodationsList(data);
                }
            })
        }
    })
    getJobAccommodationsVisual(jobSlug)
    .then(({data}) => {
        setAccommodationsVisual(data);
        const accommodations = data;
        if (accommodations.length) {
            accommodationsVisualList()
            .then(({data}) => {
                const filtered = data;
                for (let i = 0; i < accommodations.length; i++) {
                    filtered.splice(filtered.findIndex((fil) => fil.id === accommodations[i].accommodation.id), 1);
                }
                if (remoteWorkspace) {
                    setVisualAccommodationsList(filtered.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setVisualAccommodationsList(filtered);
                }
            })
        }
        else {
            accommodationsVisualList()
            .then(({data}) => {
                if (remoteWorkspace) {
                    setVisualAccommodationsList(data.filter(accommodation => accommodation.remote === true));
                }
                else {
                    setVisualAccommodationsList(data);
                }
            })
        }
    })
  },[]);


  //Handles adding accommodation
  const handleSelectAccommodation = (filterValue, type) => {
    let filteredAccommodations;
    if (type === "Cognitive") {
        addJobAccommodationCognitive(jobSlug, {accommodation: filterValue})
        .then(({data}) => {
            getJobAccommodationCognitiveDetail(jobSlug, data.id)
            .then(({data}) => {
                setAccommodationsCognitive([...accommodationsCognitive, {id: data.id, accommodation: {id: data.accommodation.id, title: data.accommodation.title}}]);
                filteredAccommodations = [...cognitiveAccommodationsList];
                filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.title === data.accommodation.title), 1);
                setCognitiveAccommodationsList(filteredAccommodations);
                setCognitiveFriendly(accommodationsCognitive.length >= 1 ? true : false);
            })
            .catch(() => {
                dispatch (
                    addToast({
                      type: "error",
                      title: "Error",
                      text: "An error occurred, please try again.",
                    })
                )
            });
        })
        .catch(() => {
            dispatch (
                addToast({
                  type: "error",
                  title: "Error",
                  text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Hearing") {
        addJobAccommodationHearing(jobSlug, {accommodation: filterValue})
        .then(({data}) => {
            getJobAccommodationHearingDetail(jobSlug, data.id)
            .then(({data}) => {
                setAccommodationsHearing([...accommodationsHearing, {id: data.id, accommodation: {id: data.accommodation.id, title: data.accommodation.title}}]);
                filteredAccommodations = [...hearingAccommodationsList];
                filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.title === data.accommodation.title), 1);
                setHearingAccommodationsList(filteredAccommodations);
                setHearingFriendly(accommodationsHearing.length >= 1 ? true : false);
            })
            .catch(() => {
                dispatch (
                    addToast({
                      type: "error",
                      title: "Error",
                      text: "An error occurred, please try again.",
                    })
                )
            });
        })
        .catch(() => {
            dispatch (
                addToast({
                  type: "error",
                  title: "Error",
                  text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Mobility") {
        addJobAccommodationMobility(jobSlug, {accommodation: filterValue})
        .then(({data}) => {
            getJobAccommodationMobilityDetail(jobSlug, data.id)
            .then(({data}) => {
                setAccommodationsMobility([...accommodationsMobility, {id: data.id, accommodation: {id: data.accommodation.id, title: data.accommodation.title}}]);
                filteredAccommodations = [...mobilityAccommodationsList];
                filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.title === data.accommodation.title), 1);
                setMobilityAccommodationsList(filteredAccommodations);
                setMobilityFriendly(accommodationsMobility.length >= 1 ? true : false);
            })
            .catch(() => {
                dispatch (
                    addToast({
                      type: "error",
                      title: "Error",
                      text: "An error occurred, please try again.",
                    })
                )
            });
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Sensory") {
        addJobAccommodationSensory(jobSlug, {accommodation: filterValue})
        .then(({data}) => {
            getJobAccommodationSensoryDetail(jobSlug, data.id)
            .then(({data}) => {
                setAccommodationsSensory([...accommodationsSensory, {id: data.id, accommodation: {id: data.accommodation.id, title: data.accommodation.title}}]);
                filteredAccommodations = [...sensoryAccommodationsList];
                filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.title === data.accommodation.title), 1);
                setSensoryAccommodationsList(filteredAccommodations);
                setSensoryFriendly(accommodationsSensory.length >= 1 ? true : false);
            })
            .catch(() => {
                dispatch (
                    addToast({
                      type: "error",
                      title: "Error",
                      text: "An error occurred, please try again.",
                    })
                )
            });
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Visual") {
        addJobAccommodationVisual(jobSlug, {accommodation: filterValue})
        .then(({data}) => {
            getJobAccommodationVisualDetail(jobSlug, data.id)
            .then(({data}) => {
                setAccommodationsVisual([...accommodationsVisual, {id: data.id, accommodation: {id: data.accommodation.id, title: data.accommodation.title}}]);
                filteredAccommodations = [...visualAccommodationsList];
                filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.title === data.accommodation.title), 1);
                setVisualAccommodationsList(filteredAccommodations);
                setVisualFriendly(accommodationsVisual.length >= 1 ? true : false);
            })
            .catch(() => {
                dispatch (
                    addToast({
                      type: "error",
                      title: "Error",
                      text: "An error occurred, please try again.",
                    })
                )
            });
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
}


//Handles deleting accommodation
const handleDeleteAccommodation = (filterId, filterValue, filterTitle, type) => {
    let filteredAccommodations;
    if (type === "Cognitive") {
        deleteJobAccommodationCognitive(jobSlug, filterId)
        .then(() => {
            setCognitiveAccommodationsList([...cognitiveAccommodationsList, {id: filterValue, title: filterTitle}]);
            filteredAccommodations = [...accommodationsCognitive];
            filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.accommodation.title === filterTitle), 1);
            setAccommodationsCognitive(filteredAccommodations);
            setCognitiveFriendly(accommodationsCognitive.length <= 2 ? false : true);
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Hearing") {
        deleteJobAccommodationHearing(jobSlug, filterId)
        .then(() => {
            setHearingAccommodationsList([...hearingAccommodationsList, {id: filterValue, title: filterTitle}]);
            filteredAccommodations = [...accommodationsHearing];
            filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.accommodation.title === filterTitle), 1);
            setAccommodationsHearing(filteredAccommodations);
            setHearingFriendly(accommodationsHearing.length <= 2 ? false : true);
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Mobility") {
        deleteJobAccommodationMobility(jobSlug, filterId)
        .then(() => {
            setMobilityAccommodationsList([...mobilityAccommodationsList, {id: filterValue, title: filterTitle}]);
            filteredAccommodations = [...accommodationsMobility];
            filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.accommodation.title === filterTitle), 1);
            setAccommodationsMobility(filteredAccommodations);
            setMobilityFriendly(accommodationsMobility.length <= 2 ? false : true);
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Sensory") {
        deleteJobAccommodationSensory(jobSlug, filterId)
        .then(() => {
            setSensoryAccommodationsList([...sensoryAccommodationsList, {id: filterValue, title: filterTitle}]);
            filteredAccommodations = [...accommodationsSensory];
            filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.accommodation.title === filterTitle), 1);
            setAccommodationsSensory(filteredAccommodations);
            setSensoryFriendly(accommodationsSensory.length <= 2 ? false : true);
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
    else if (type === "Visual") {
        deleteJobAccommodationVisual(jobSlug, filterId)
        .then(() => {
            setVisualAccommodationsList([...visualAccommodationsList, {id: filterValue, title: filterTitle}]);
            filteredAccommodations = [...accommodationsVisual];
            filteredAccommodations.splice(filteredAccommodations.findIndex((fil) => fil.accommodation.title === filterTitle), 1);
            setAccommodationsVisual(filteredAccommodations);
            setVisualFriendly(accommodationsVisual.length <= 2 ? false : true);
        })
        .catch(() => {
            dispatch (
                addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
                })
            )
        });
    }
}

return (
        <> 
        {multipleLocation ? (
        <Form.Row style={{ padding: editing === true ? '16px' : '32px', paddingBottom: '16px', borderBottom: '1px solid rgba(0,0,0,0.125)' }}>
            <Form.Group as={Col} col={12}>
                <Form.Check
                    className="pt-2 pb-1"
                    type="checkbox"
                    label="Communication Drivers"
                    id="formBasicCheckbox1"
                    checked={hearingFriendly}
                    onChange={() => setHearingFriendly(!hearingFriendly)}
                />
                <Form.Label className="job-post-info">Do you offer different ways through which all employees communicate with each other equally?</Form.Label>
                <Form.Check
                    className="pt-3 pb-1"
                    type="checkbox"
                    label="Learning Drivers"
                    id="formBasicCheckbox2"
                    checked={cognitiveFriendly}
                    onChange={() => setCognitiveFriendly(!cognitiveFriendly)}
                />
                <Form.Label className="job-post-info">Everyone learns differently, do you offer options to enable different learning styles?</Form.Label>
                {!remoteWorkspace && (
                    <>
                    <Form.Check
                        className="pt-3 pb-1"
                        type="checkbox"
                        label="Movement Drivers"
                        id="formBasicCheckbox4"
                        checked={mobilityFriendly}
                        onChange={() => setMobilityFriendly(!mobilityFriendly)}
                    />
                    <Form.Label className="job-post-info">Do you provide accessibility infrastructure for someone using a mobility aid or wheelchair?</Form.Label>
                    </>
                )}
                <Form.Check
                    className="pt-3 pb-1"
                    type="checkbox"
                    label="Neurodivergent Talent Drivers"
                    id="formBasicCheckbox3"
                    checked={sensoryFriendly}
                    onChange={() => setSensoryFriendly(!sensoryFriendly)}
                />
                <Form.Label className="job-post-info">Do you offer options to enable individuals with cognitive abilities perform equally at your workplace?</Form.Label>
                <Form.Check
                    className="pt-3 pb-1"
                    type="checkbox"
                    label="Vision Drivers"
                    id="formBasicCheckbox5"
                    checked={visualFriendly}
                    onChange={() => setVisualFriendly(!visualFriendly)}
                />
                <Form.Label className="job-post-info">If a person with visual differences joins your workforce, do you provide them equal access?</Form.Label>
            </Form.Group>
            {(!sectionValid && !cognitiveFriendly && !hearingFriendly && !mobilityFriendly && !sensoryFriendly && !visualFriendly) && (
                <Form.Label className="invalid-section-feedback">Please select atleast one performance drivers category.</Form.Label>
            )}
        </Form.Row>
        ) : (
        <div style={{ padding: editing === true ? '16px' : '32px', borderBottom: '1px solid rgba(0,0,0,0.125)' }}>
        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label className="mb-0">Communication Drivers</Form.Label>
                <div className="job-post-info mb-2">What are the different ways through which all employees communicate with each other equally?</div>
                    {accommodationsHearing.length ? (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {accommodationsHearing.map((accommodation) => (
                                    <ListGroup.Item
                                        className="skill-pill-item"
                                        key={accommodation.id}
                                        value={accommodation.accommodation.id}
                                    >
                                        <Button className="delete-skill-button" onClick={() => handleDeleteAccommodation(accommodation.id, accommodation.accommodation.id, accommodation.accommodation.title, "Hearing")} aria-label="Delete accommodation">
                                            <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
                                        </Button>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                    ) : ""} 
                {accommodationsHearing.length === 1 && !hearingFriendly && (
                    <Form.Label className="job-post-info">Please select a minimum of two filters.</Form.Label>
                )}
                    <Form.Control
                        onChange={(e) => handleSelectAccommodation(e.target.value, "Hearing")}
                        name="job_accommodations_hearing"
                        value={""}
                        as="select"
                        className="ic-form-select"
                        aria-label="Communication Drivers"
                        disabled={accommodationsHearing.length >= 10 ? true : false}
                    >
                        <option value="" disabled>Select Communication Drivers</option>
                    {hearingAccommodationsList.map((accommodation) => (
                        <option key={accommodation.id} value={accommodation.id}>{accommodation.title}</option>
                    ))}
                </Form.Control>
            </Form.Group>
        </Form.Row> 

        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label className="mb-0 mt-2">Learning Drivers</Form.Label>
                <div className="job-post-info mb-2">Everyone learns differently, choose options you can offer to enable different learning styles.</div>
                    {accommodationsCognitive.length ? (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {accommodationsCognitive.map((accommodation) => (
                                    <ListGroup.Item
                                        className="skill-pill-item"
                                        key={accommodation.id}
                                        value={accommodation.accommodation.id}
                                    >
                                        <Button className="delete-skill-button" onClick={() => handleDeleteAccommodation(accommodation.id, accommodation.accommodation.id, accommodation.accommodation.title, "Cognitive")} aria-label="Delete accommodation">
                                            <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
                                        </Button>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                    ) : ""} 
                    {accommodationsCognitive.length === 1 && !cognitiveFriendly && (
                        <Form.Label className="job-post-info">Please select a minimum of two filters.</Form.Label>
                    )}
                    <Form.Control
                        onChange={(e) => handleSelectAccommodation(e.target.value, "Cognitive")}
                        name="job_accommodations_cognitive"
                        value={""}
                        as="select"
                        className="ic-form-select"
                        aria-label="Learning Drivers"
                        disabled={accommodationsCognitive.length >= 10 ? true : false}
                    >
                        <option value="" disabled>Select Learning Drivers</option>
                    {cognitiveAccommodationsList.map((accommodation) => (
                        <option key={accommodation.id} value={accommodation.id}>{accommodation.title}</option>
                    ))}
                </Form.Control>
            </Form.Group>
        </Form.Row>

        {!remoteWorkspace && (
        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label className="mb-0 mt-2">Movement Drivers</Form.Label>
                <div className="job-post-info mb-2">What accessibility infrastructure  do you provide for someone using a mobility aid or wheelchair?</div>
                    {accommodationsMobility.length ? (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {accommodationsMobility.map((accommodation) => (
                                    <ListGroup.Item
                                        className="skill-pill-item"
                                        key={accommodation.id}
                                        value={accommodation.accommodation.id}
                                    >
                                        <Button className="delete-skill-button" onClick={() => handleDeleteAccommodation(accommodation.id, accommodation.accommodation.id, accommodation.accommodation.title, "Mobility")} aria-label="Delete accommodation">
                                            <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
                                        </Button>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                    ) : ""} 
                    {accommodationsMobility.length === 1 && !mobilityFriendly && (
                    <Form.Label className="job-post-info">Please select a minimum of two filters.</Form.Label>
                    )}
                    <Form.Control
                        onChange={(e) => handleSelectAccommodation(e.target.value, "Mobility")}
                        name="job_accommodations_mobility"
                        value={""}
                        as="select"
                        className="ic-form-select"
                        aria-label="Movement Drivers"
                        disabled={accommodationsMobility.length >= 10 ? true : false}
                    >
                        <option value="" disabled>Select Movement Drivers</option>
                    {mobilityAccommodationsList.map((accommodation) => (
                        <option key={accommodation.id} value={accommodation.id}>{accommodation.title}</option>
                    ))}
                </Form.Control>
            </Form.Group>
        </Form.Row> 
        )}

        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label className="mb-0 mt-2">Neurodivergent Talent Drivers</Form.Label>
                <div className="job-post-info mb-2">What options do you offer to enable individuals with cognitive abilities perform equally at your workplace?</div>
                    {accommodationsSensory.length ? (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {accommodationsSensory.map((accommodation) => (
                                    <ListGroup.Item
                                        className="skill-pill-item"
                                        key={accommodation.id}
                                        value={accommodation.accommodation.id}
                                    >
                                        <Button className="delete-skill-button" onClick={() => handleDeleteAccommodation(accommodation.id, accommodation.accommodation.id, accommodation.accommodation.title, "Sensory")} aria-label="Delete accommodation">
                                            <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
                                        </Button>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                    ) : ""} 
                    {accommodationsSensory.length === 1 && !sensoryFriendly && (
                    <Form.Label className="job-post-info">Please select a minimum of two filters.</Form.Label>
                    )}
                    <Form.Control
                        onChange={(e) => handleSelectAccommodation(e.target.value, "Sensory")}
                        name="job_accommodations_sensory"
                        value={""}
                        as="select"
                        className="ic-form-select"
                        aria-label="Neurodivergent Talent Drivers"
                        disabled={accommodationsSensory.length >= 10 ? true : false}
                    >
                        <option value="" disabled>Select Neurodivergent Talent Drivers</option>
                    {sensoryAccommodationsList.map((accommodation) => (
                        <option key={accommodation.id} value={accommodation.id}>{accommodation.title}</option>
                    ))}
                </Form.Control>
            </Form.Group>
        </Form.Row>   

        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label className="mb-0 mt-2">Vision Drivers</Form.Label>
                <div className="job-post-info mb-2">If a person with visual differences joins your workforce, how do you provide them equal access?</div>
                    {accommodationsVisual.length ? (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {accommodationsVisual.map((accommodation) => (
                                    <ListGroup.Item
                                        className="skill-pill-item"
                                        key={accommodation.id}
                                        value={accommodation.accommodation.id}
                                    >
                                        <Button className="delete-skill-button" onClick={() => handleDeleteAccommodation(accommodation.id, accommodation.accommodation.id, accommodation.accommodation.title, "Visual")} aria-label="Delete accommodation">
                                            <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
                                        </Button>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                    ) : ""} 
                    {accommodationsVisual.length === 1 && !visualFriendly && (
                    <Form.Label className="job-post-info">Please select a minimum of two filters.</Form.Label>
                    )}
                    <Form.Control
                        onChange={(e) => handleSelectAccommodation(e.target.value, "Visual")}
                        name="job_accommodations_visual"
                        value={""}
                        as="select"
                        className="ic-form-select"
                        aria-label="Vision Drivers"
                        disabled={accommodationsVisual.length >= 10 ? true : false}
                    >
                        <option value="" disabled>Select Vision Drivers</option>
                    {visualAccommodationsList.map((accommodation) => (
                        <option key={accommodation.id} value={accommodation.id}>{accommodation.title}</option>
                    ))}
                    </Form.Control>
                </Form.Group>
            </Form.Row> 
            {(!sectionValid && !cognitiveFriendly && !hearingFriendly && !mobilityFriendly && !sensoryFriendly && !visualFriendly) && (
                <Form.Label className="invalid-section-feedback">Please select performance drivers from atleast one category.</Form.Label>
            )}
        </div>
        )}
        </>
    )
};

export default JobAccessibilityAccommodations;


