import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { format } from "date-fns";
import Form from "react-bootstrap/Form";
import ChevronDown from '../../../assets/images/ChevronDown.png';
import { updateOrganizationJob, updateOrganization, addOrganizationJobLocation } from "../../../api";
import JobDescription from './AddJob/components/JobDescription';
import JobLocation from './AddJob/components/JobLocation';
import JobSkills from './AddJob/components/JobSkills';
import JobEducationExperiencePerks from "./AddJob/components/JobEducationExperiencePerks";
import JobAccessibilityAccommodations from './AddJob/components/JobAccessibilityAccommodations';
import JobEqualEmploymentDisclaimer from './AddJob/components/JobEqualEmploymentDisclaimer';
import JobScreeningQuestions from './AddJob/components/JobScreeningQuestions';

const JobEdit = ({ activeProfile, show, closeDialog, isEditing, currentJob, onUpdated, addToast }) => {

    const jobSlug = currentJob.slug;
    const jobId = currentJob.id;
    const orgSlug = activeProfile ? activeProfile.slug : '';
    const [userCity, setUserCity] = useState(null);

    const [sectionOne, setSectionOne] = useState(true);
    const [sectionTwo, setSectionTwo] = useState(false);
    const [sectionThree, setSectionThree] = useState(false);
    const [sectionFour, setSectionFour] = useState(false);
    const [sectionFive, setSectionFive] = useState(false);
    const [sectionValid, setSectionValid] = useState(true);

    const [remoteWorkspace, setRemoteWorkspace] = useState(false);
    const [multipleLocation, setMultipleLocation] = useState(false);
    const [cityId, setCityId] = useState(activeProfile ? activeProfile.organization_city.id : null);
    const [orgCity, setOrgCity] = useState(activeProfile ? activeProfile.organization_city.city_name : "");
    const [orgCountry, setOrgCountry] = useState(activeProfile ? activeProfile.organization_city.city_country.country_name : "");
    const [locations, setLocations] = useState([{ cityId: null, cityName: '', countryId: null, countryName: '' }]);

    const [cognitiveFriendly, setCognitiveFriendly] = useState(currentJob && currentJob.accessibility_cognitive);
    const [hearingFriendly, setHearingFriendly] = useState(currentJob && currentJob.accessibility_hearing);
    const [mobilityFriendly, setMobilityFriendly] = useState(currentJob && currentJob.accessibility_mobility);
    const [sensoryFriendly, setSensoryFriendly] = useState(currentJob && currentJob.accessibility_sensory_friendly);
    const [visualFriendly, setVisualFriendly] = useState(currentJob && currentJob.accessibility_visual);
    
    const [disclaimer, setDisclaimer] = useState("disclaimerOne");
    const companyName = currentJob ? currentJob.job_organization : "";

    const [postScreeningQuestions, setPostScreeningQuestions] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);

    const handleNextSection = (values, errors) => {
        if (sectionOne) {
          const valid = validateSectionOne(values, errors);
              setSectionValid(valid);
              if (valid) {
                  if (values.job_location === 'R') {
                      setRemoteWorkspace(true);
                  }
                  else {
                      setRemoteWorkspace(false);
                  }
              const data = {
                  job_title: values.job_title,
                  job_close_time: format(values.job_close_time, "yyyy-MM-dd"),
                  job_type: values.job_type,
                  job_location: values.job_location,
                  job_description: values.job_description,
                  listed_by: activeProfile.id,       
                };   
              updateOrganizationJob(jobSlug, data)
                  .then((data) => {
                      setSectionOne(false);
                      setSectionTwo(true);
                  })
                  .catch(() => {
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    })
                });
              }
          }
        if (sectionTwo) {
            const valid = validateSectionTwo(values, errors);
            setSectionValid(valid);
            const lastIndex = locations.length - 1;
            if (valid) {
                if (!remoteWorkspace && (locations[lastIndex].cityId === null || locations[lastIndex].countryId === null)) {
                    locations.splice(lastIndex, 1);
                    if (locations.length === 1) {
                        setMultipleLocation(false);
                    }
                }
                else {
                    if (locations[lastIndex].id === null && locations[lastIndex].cityId !== null && locations[lastIndex].countryId !== null) {
                        addOrganizationJobLocation(jobSlug, { job: jobId, job_city: Number(locations[lastIndex].cityId) })
                            .then(({ data }) => {
                                locations[lastIndex].id = data.id;
                            })
                            .catch(() => { });
                    }
                }
                updateOrganization(orgSlug, {
                    organization_address: values.organization_address,
                    organization_city: cityId !== null ? Number(cityId) : cityId,
                    organization_email: activeProfile.organization_email,
                    organization_website: activeProfile.organization_website,
                    organization_industry: activeProfile.organization_industry.id
                }).then(() => {
                    setSectionTwo(false);
                    setSectionThree(true);
                })
                .catch(() => {
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    })
                });
            }
          }
          if (sectionThree) {
              const valid = validateSectionThree(values, errors);
              setSectionValid(valid);
              if (valid) {
                  const data = (values.job_pay_interval === "" || values.job_pay_range === "") ? 
                  {
                      job_education: values.job_education,
                      job_experience_level: values.job_experience_level,
                      job_perks: values.job_perks,
                      listed_by: activeProfile.id
                  } 
                  : 
                  {
                      job_education: values.job_education,
                      job_experience_level: values.job_experience_level,
                      job_perks: values.job_perks,
                      job_pay_interval: values.job_pay_interval,
                      job_pay_range: values.job_pay_range,
                      listed_by: activeProfile.id
                  }; 
                  updateOrganizationJob(jobSlug, data)
                  .then((data) => {
                      setSectionThree(false);
                      setSectionFour(true);
                  })
                  .catch(() => {
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    })
                });
              }
          }
          if (sectionFour) {
              const valid = validateSectionFour(values, errors);
              setSectionValid(valid);
              if (valid) {
                  updateOrganizationJob(jobSlug, {
                      job_disclaimer: disclaimer === "disclaimerOne" ? `At ${companyName}, diverse, inclusive culture is vital to our mission. We offer opportunities for individuals of all abilities and backgrounds.` : 
                                      disclaimer === "disclaimerTwo" ? `It is the policy of ${companyName} not to discriminate against any applicant for employment, or any employee because of age, color, sex, ability, national origin, race, religion, or veteran status.` :
                                      disclaimer === "disclaimerThree" ? `At ${companyName}, we advocate for inclusion through action. All of our employment practice from screening to placement is equal for everyone.` :
                                      values.job_disclaimer,
                      accessibility_cognitive: cognitiveFriendly,
                      accessibility_hearing: hearingFriendly,
                      accessibility_mobility: mobilityFriendly,
                      accessibility_sensory_friendly: sensoryFriendly,
                      accessibility_visual: visualFriendly,
                      listed_by: activeProfile.id
                  })
                  .then((data) => {
                      setSectionFour(false);
                      setSectionFive(true);
                  })
                  .catch(() => {
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    })
                });
              }
          }
      }
      
      const handlePrevSection = () => {
          if (sectionFive) {
              setSectionFive(false);
              setSectionFour(true);
          }
          if (sectionFour) {
              setSectionFour(false);
              setSectionThree(true);
          }
          if (sectionThree) {
              setSectionThree(false);
              setSectionTwo(true);
          }
          if (sectionTwo) {
              setSectionTwo(false);
              setSectionOne(true);
          }
      }
      
      const validateSectionOne = (values, errors) => {
          if (values.job_title === "" || values.job_location === "" || values.job_type === "" || values.job_description === "" || errors.job_description || errors.job_title) {
              return false;
          }
          else {
              return true;
          }
      }

      const validateSectionTwo = (values, errors) => {
          if ((locations[0].cityId === null && !remoteWorkspace) || values.organization_address === '' || orgCity === '' || orgCountry === '' || errors.organization_address) {
              return false;
          }
          else {
              return true;
          }
      }
  
      const validateSectionThree = (values, errors) => {
          if (values.job_education === "" || values.job_experience_level === "" || values.job_perks === "" || errors.job_education || errors.job_perks) {
              return false;
          }
          else {
              return true;
          }
      }
  
      const validateSectionFour = (values, errors) => {
          if ((disclaimer === "disclaimerOther" && values.job_disclaimer === "") || (!cognitiveFriendly && !hearingFriendly && !mobilityFriendly && !sensoryFriendly && !visualFriendly) || errors.job_disclaimer) {
              return false;
          }
          else {
              return true;
          }
      }
 
      useEffect(() => {
        setSectionOne(true);
        setSectionTwo(false);
        setSectionThree(false);
        setSectionFour(false);
        setSectionFive(false);
      },[show]);
  
      useEffect(() => {
          window.scrollTo(0, 0);
      }, []);
      
    const jobSchema = yup.object().shape({
      job_organization: yup.string().max(100),
      job_organization_logo: yup.mixed(),
      job_organization_detail: yup.string().max(250),
      job_title: yup.string().max(100, 'You have exceeded the maximum character limit of 100 characters.').required("Please provide the Job Title."),
      job_close_time: yup.date().required("Please select the Job Closing Date."),
      job_location: yup.string().required("Please select the Job Location."),
      job_type: yup.string().required("Please select the Job Type."),
      job_description: yup.string().max(2500, 'You have exceeded the maximum character limit of 2500 characters.').required("Please provide the Job Description."),
      job_education: yup.string().max(500, 'You have exceeded the maximum character limit of 500 characters.').required("Please provide the Education Requirements."),
      job_experience_level: yup.string().required("Please select the Experience Level."),
      job_perks: yup.string().max(250, 'You have exceeded the maximum character limit of 250 characters.').required("Please provide the Perks and Benefits."),
      job_pay_interval: yup.string(),
      job_pay_range: yup.string(),
      job_disclaimer: disclaimer === "disclaimerOther" ? yup.string().max(250, 'You have exceeded the maximum character limit of 250 characters.').required("Please provide the Disclaimer of Equal Employment.") : yup.string(),
      organization_address: yup.string().max(100, 'You have exceeded the maximum character limit of 100 characters.').required("Please provide the Organization Address.")
    });
  
    let initialValues = {
      job_organization: currentJob.job_organization,
      job_organization_logo: currentJob.job_organization_logo,
      job_organization_detail: currentJob.job_organization_detail,
      job_title: currentJob.job_title,
      job_close_time: new Date(currentJob.job_close_time),
      job_location: currentJob.job_location,
      job_type: currentJob.job_type,
      job_description: currentJob.job_description,
      job_education: currentJob.job_education,
      job_experience_level: currentJob.job_experience_level,
      job_perks: currentJob.job_perks,
      job_pay_interval: currentJob.job_pay_interval,
      job_pay_range: currentJob.job_pay_range,
      job_disclaimer: currentJob.job_disclaimer,
      job_screening_questions: [],
      organization_address: activeProfile && activeProfile.organization_address
    };
  
    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
        onUpdated();
        closeDialog();
        addToast({
            title: "Success",
            text: "Job updated successfully.",
            type: "success",
        });
    };

  return (
    <Modal show={show} centered size="lg" onHide={closeDialog} style={{ marginTop: "40px" }} className="modelTopmargin">
        <StyledJobEdit>
        <Modal.Header closeButton>
            <Modal.Title>
            Edit Job
            </Modal.Title>
      </Modal.Header>
      <Formik
           validationSchema={jobSchema}
           initialValues={initialValues}
           onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body style={{padding: "0"}}>

                {sectionOne && (
                <>
                <div className="post-job-section-header">
                    <h4>Job Details</h4>
                </div>
                <div className="post-job-body">
                    <JobDescription sectionValid={sectionValid} handleChange={handleChange} handleBlur={handleBlur} values={values} errors={errors} touched={touched} setUserCity={setUserCity}/>
                </div>
                </>   
                )}      

                {sectionTwo && (
                    <>
                    <div className="post-job-section-header">
                        <h4>Organization Location</h4>
                    </div>
                    <div className="post-job-body">
                        <JobLocation jobSlug={jobSlug} jobId={jobId} location='Organization' setCityId={setCityId} orgCity={orgCity} setOrgCity={setOrgCity} orgCountry={orgCountry} setOrgCountry={setOrgCountry} locations={locations} setLocations={setLocations} sectionValid={sectionValid} handleChange={handleChange} handleBlur={handleBlur} values={values} errors={errors} touched={touched} setUserCity={setUserCity} setMultipleLocation={setMultipleLocation} />
                    </div>
                    {remoteWorkspace ? (
                        <div style={{ marginBottom: '16rem' }} />
                    ) : (
                        <>
                        <div className="post-job-section-header pb-0" style={{ borderTop: '1px solid rgba(0,0,0,0.125)', borderBottom: 'none', paddingTop: '32px' }}>
                            <h4>Job Location</h4>
                        </div>
                        <div className="post-job-body pt-0">
                           <JobLocation jobSlug={jobSlug} jobId={jobId} location='Job' orgCity={orgCity} setOrgCity={setOrgCity} orgCountry={orgCountry} setOrgCountry={setOrgCountry} locations={locations} setLocations={setLocations} sectionValid={sectionValid} handleChange={handleChange} handleBlur={handleBlur} values={values} errors={errors} touched={touched} setUserCity={setUserCity} setMultipleLocation={setMultipleLocation} />
                        </div>
                        </>
                    )}
                    </>
                )}

                {sectionThree && (
                <>
                <div className="post-job-section-header">
                    <h4>Job Details</h4>
                </div>
                 <div className="post-job-body">
                    <JobSkills jobSlug={jobSlug}/>                    
                    <JobEducationExperiencePerks sectionValid={sectionValid} handleChange={handleChange} handleBlur={handleBlur} values={values} errors={errors} touched={touched}/>
                 </div>
                 </>
                )}     

                {sectionFour && (
                    <>
                    <div className="post-job-section-header">
                        <h4>Performance Drivers</h4>
                    </div>
                    <div className="post-job-section-header">
                        <div className="job-post-info">
                            {multipleLocation ? 'Performance drivers may vary per location, therefore select the applicable categories depending on the kind of drivers available for the job. ' : 'What do you offer (or are willing to offer) the Candidate to perform at the workplace?'}
                        </div>
                    </div>
                        <JobAccessibilityAccommodations editing={true} sectionValid={sectionValid} jobSlug={jobSlug} remoteWorkspace={remoteWorkspace} multipleLocation={multipleLocation} cognitiveFriendly={cognitiveFriendly} hearingFriendly={hearingFriendly} mobilityFriendly={mobilityFriendly} sensoryFriendly={sensoryFriendly} visualFriendly={visualFriendly} setCognitiveFriendly={setCognitiveFriendly} setHearingFriendly={setHearingFriendly} setMobilityFriendly={setMobilityFriendly} setSensoryFriendly={setSensoryFriendly} setVisualFriendly={setVisualFriendly} />
                    <div className="post-job-body">
                        <JobEqualEmploymentDisclaimer sectionValid={sectionValid} handleChange={handleChange} handleBlur={handleBlur} values={values} errors={errors} touched={touched} disclaimer={disclaimer} setDisclaimer={setDisclaimer} companyName={companyName} />
                    </div>
                    </>
                )}

                {sectionFive && (
                <>
                <div className="post-job-section-header">
                    <h4>Screening Questions</h4>
                </div>
                 <div className="post-job-body">
                    <JobScreeningQuestions jobSlug={jobSlug} jobId={jobId} isEditing={isEditing} postScreeningQuestions={postScreeningQuestions} setPostScreeningQuestions={setPostScreeningQuestions} setSubmitForm={setSubmitForm}/>
                 </div>
                 </>
                )}     

            </Modal.Body>

            <Modal.Footer style={{padding: "0", margin: "0"}}>
                <div className="post-job-footer">
                    <Row className="job-post-action-btn">
                        {sectionOne && (
                        <Button className="job-post-cancel-btn" onClick={() => history.push("/profile/")} aria-label="Cancel">
                            <i className="material-icons mr-1" alt="close icon">close</i>
                            Cancel
                        </Button>
                        )}
                        {(sectionTwo || sectionThree || sectionFour || sectionFive) && (
                        <Button className="job-post-prev-btn" onClick={() => handlePrevSection()} aria-label="Back">
                            <i className="material-icons mr-1" alt="back arrow icon">navigate_before</i>
                            Back
                        </Button>
                        )}
                        {(sectionOne || sectionTwo || sectionThree || sectionFour) && (
                        <Button className="job-post-next-btn" onClick={() => handleNextSection(values, errors)} aria-label="Next">
                            Save & Next
                            <i className="material-icons ml-1" alt="forward arrow icon">navigate_next</i>
                        </Button>
                        )}
                        {(sectionFive && !submitForm) && (
                        <Button className="job-post-next-btn" onClick={() => setPostScreeningQuestions(true)} aria-label="Next">
                            Save & Next
                            <i className="material-icons ml-1" alt="forward arrow icon">navigate_next</i>
                        </Button>
                        )}
                        {(sectionFive && submitForm) && (
                        <Button className="job-post-next-btn" type="submit" aria-label="Submit">
                            {isSubmitting ? "Please wait.." : "UPDATE JOB"}
                            <i className="material-icons" alt="arrow up icon">upgrade</i>
                        </Button>
                        )}
                    </Row>
                </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
      </StyledJobEdit>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {
    addToast,
  }
)(JobEdit);

const StyledJobEdit = styled.div`
font-family: 'Inter', sans-serif;
white-space: pre-line;
width: 100%;
background: #fff;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
border-radius: 16px;
overflow: hidden;

.post-job-header {
    padding: 1.3rem 2rem;
    background-color: #000000;
    opacity: 0.8;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.post-job-header .material-icons {
    font-size: 28px;
}

.post-job-toggle {
    font-size: 1.1rem;
    color: #000;
    padding: 1.3rem 2rem 0 2rem;
}

.post-job-body {
    padding: 1rem;
}

.post-job-footer {
    padding: 1rem;
    width: 100%;
}

.post-job-header h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
  }

.post-job-section-header h4 {
    font-size: 1.3rem;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0;
  }

.post-job-section-header {
    border-bottom: 1px solid rgba(0,0,0,0.125);
    padding: 1rem;
  }

label.form-label, label.form-check-label {
    font-weight: 500;
    color: #000;
    width: 100%;
}

input.form-control {
    padding: 1.62rem 1rem;
    border-radius: 0.4rem;
}

select.ic-form-select.form-control {
    padding: 0.87rem 1rem;
    border-radius: 0.4rem;
    height: auto;
    background-image: url(${ChevronDown});
    background-position: right 0.5em top 50%, 0 0;
    background-repeat: no-repeat;
    appearance: none;
}

textarea.form-control {
    padding: 1rem;
    border-radius: 0.4rem;
}

.react-date-picker.react-date-picker--closed.react-date-picker--enabled.form-control {
    border-radius: 0.4rem;
}

input.form-check-input {
    transform: scale(1.2);
    cursor: pointer;
}

.job-post-info {
    color: #515151 !important;
    font-weight: 400 !important;
}

.invalid-section-feedback, .invalid-feedback {
    font-size: 90% !important;
    color: #dc3545 !important;
    font-weight: 500 !important;
}

.skills-row {
    position: relative;
}

.skill-suggestions {
    width: 99%;
    position: absolute;
    top: 93%;
    left: 5px;
    z-index: 99;
    max-height: 247px;
    overflow: auto;
}

.skill-suggestion-item {
    cursor: pointer;
    color: #000;
    &:hover {
        background-color: #f3f3f3;
    }
}

.skill-pills-container {
    padding-bottom: 1rem;
    padding-top: 0.5rem;
}

.skill-pills {
    flex-wrap: wrap;
}

.skill-pill-item {
    background: #3F0E40;
    border-radius: 25px !important;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    list-style: none;
    padding: 0 !important;
    margin-bottom: 0.5rem;
    
    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.skill-pill-title {
    padding: 0px 10px;
    height: 34px;
    align-items: center;
    display: flex;
}

.delete-skill-button {
    width: 31px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #000;
    border-color: #000 !important;
    border-radius: 25px 0px 0px 25px;
        &:active {
        background-color: #000 !important;
        border-color: #000 !important;
    }
}

.question-container {
    border-top: 1px solid rgba(0,0,0,0.125);
    padding-top: 1.5rem;
    margin-top: 1.5rem;
}

.add-question-btn, .remove-question-btn {
    color: #000;
    background: #fff;
    border-color: #fff;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
        color: #000 !important;
        background-color: #fff !important;
        border-color: #fff !important;
    }
}

.add-question-btn:focus-visible, .remove-question-btn:focus-visible {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.40);
}

.fileInputPic {
    width: 112px;
    height: 70px;
    position: absolute;
    left: 22px;
  top: 11px;
  cursor: pointer;
  opacity: 0;
  z-index: 999;
} 

.logo-upload-btn { 
    background-color: #000000;
    color: #fff;
    border-color: #000000;
    border-radius: 7px;
    font-weight: 500;
    opacity: 0.9;
    display: inline-flex;
    align-items: center;
    margin: 8px;
}

.logo-upload-btn:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.40);
}

.job-post-next-btn {
    background-color: #3F0E40;
    color: #fff;
    border-color: #3F0E40;
    border-radius: 7px;
    font-weight: 500;
    opacity: 0.9;
    display: flex;
    align-items: center;
    padding: 0.6rem 1rem 0.6rem 1.4rem;
    &:active {
        color: #fff !important;
        background-color: #3F0E40 !important;
        border-color: #3F0E40 !important;
    }
}

.job-post-prev-btn {
    background-color: #3F0E40;
    color: #fff;
    border-color: #3F0E40;
    border-radius: 7px;
    font-weight: 500;
    opacity: 0.9;
    display: flex;
    align-items: center;
    padding: 0.6rem 1.4rem 0.6rem 1rem;
    margin-right: 1rem;
    &:active {
        color: #fff !important;
        background-color: #3F0E40 !important;
        border-color: #3F0E40 !important;
    }
}

.job-post-cancel-btn {
    background-color: #fff;
    color: #000;
    border: 2px solid #5F5F5F;
    border-radius: 7px;
    font-weight: 500;
    opacity: 0.9;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 0.6rem 1rem 0.6rem 0.7rem;
    &:active {
        color: #000 !important;
        background-color: #fff !important;
        border-color: #5F5F5F !important;
    }
}

.job-post-next-btn:focus-visible, .job-post-prev-btn:focus-visible, .job-post-cancel-btn:focus-visible {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.40);
}

.job-post-action-btn {
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.job-post-cancel-btn .material-icons {
    font-size: 20px;
}

@media (max-width: 576px) {
    margin-top: 2.25rem;
}
`;
