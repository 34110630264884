import React from 'react';
import styled from 'styled-components';

const PasswordValidator = ({
  password,
}) => {
  const hasDigits = (string) => /\d/.test(string);
  const hasUppercase = (string) => /[A-Z]/.test(string);
  const hasLowercase = (string) => /[a-z]/.test(string);

  return (
    <StyledPasswordValidator className="ic-form-badge-wrapper">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <ValidationRule isValid={password.length > 7} ariaID="validation-info-1">
            Atleast 8 characters
          </ValidationRule>
          <ValidationRule isValid={hasDigits(password)} ariaID="validation-info-2">
            Atleast 1 digit
          </ValidationRule>
        </div>
        <div className="col-lg-6 col-md-12">
          <ValidationRule isValid={hasUppercase(password)} ariaID="validation-info-3">
            Atleast 1 uppercase letter
          </ValidationRule>
          <ValidationRule isValid={hasLowercase(password)} ariaID="validation-info-4">
            Atleast 1 lowercase letter
          </ValidationRule>
        </div>
      </div>
    </StyledPasswordValidator>
  );
};

export default PasswordValidator;

const ValidationRule = ({
  isValid,
  children,
  ariaID
}) => (
  <p className="ic-form-badge font-italic password-validation-rule">
    {
      isValid ? (
        <span className="badge badge-warning">
          <i className="fas fa-check" aria-hidden="false" />
        </span>
      ) : (
        <span className="circle-radio" />
      )
    }
    &nbsp;
    <span id={ariaID}>{children}</span>
  </p>
);

const StyledPasswordValidator = styled.div`
  margin-top: 15px;

  .row > div {
    margin-bottom: 10px;
  }

  .password-validation-rule {
    margin: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > span {
      display: block;
      flex-shrink: 0;
    }

    .circle-radio {
      background: #fff;
      border: solid 1px #dadbe2;
      font-size: 0;
      padding: 10px;
      border-radius: 50%;
    }
  }
`;
