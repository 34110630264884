import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Form from "../../../common/Form";
import { updateProfile } from "../../../api";

const AddEditStory = ({
  activeProfile,
  show,
  closeDialog,
  isEditing,
  currentValues,
  onUpdated,
}) => {
  const storySchema = yup.object().shape({
    person_work_towards_project: yup
      .string()
      .required("Please add something to your story."),
  });

  const onSubmit = (values, { setSubmitting }) => {
    updateProfile(activeProfile.id, { ...values })
      .then(() => {
        setSubmitting(false);
        closeDialog();
        onUpdated();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  let initialValues = {
    person_work_towards_project: "",
  };

  if (isEditing) {
    initialValues = {
      person_work_towards_project: currentValues.person_work_towards_project,
    };
  }

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit" : "Add"} Story</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={storySchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              <Row>
                <Form.Group as={Col} col={12}>
                  <Form.Label className="pb-2" style={{fontSize:'1.2rem', fontWeight:'600'}}>Share your story</Form.Label>                
                  <Form.Control
                    placeholder="Share more details about you and what brings you here so other users and companies can learn more about you and your motivations."
                    name="person_work_towards_project"
                    className="textareaHeight"
                    value={values.person_work_towards_project}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    isInvalid={
                      touched.person_work_towards_project &&
                      errors.person_work_towards_project
                    }
                  />
                  <Form.Label className="pt-2" style={{color: '#515151'}}>(Character Limit: 250)</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.person_work_towards_project}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button
                      onClick={closeDialog}
                      block
                      className="modal-cancel-button"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="mt-3 mt-md-0" sm={12} md={6}>
                    <Button
                      loading={isSubmitting ? 1 : 0}
                      block
                      className="modal-submit-button"
                      type="submit"
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {}
)(AddEditStory);
