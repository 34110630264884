import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Form from "../../../common/Form";
import DatePicker from "../../../components/FormikDatepicker";
import { addEducation, updateEducation } from "../../../api";

const AddEditEducation = ({
  activeProfile,
  show,
  closeDialog,
  isEditing,
  currentValues,
  educationId,
  onUpdated,
}) => {
  const educationSchema = yup.object().shape({
    education_title: yup
      .string()
      .required(
        "Please provide the name of the degree/program you were enrolled in."
      ),
    education_summary: yup
      .string()
      .required(
        "Please provide the name of the University/School you were enrolled in."
      ),
    education_start: yup
      .date()
      .required(
        "Please provide the date the date you started working for this Company."
      ),
    education_end: yup
      .date()
      .required(
        "Please provide the date the date you stopped working for this Company."
      ),
    //education_loc: yup.string().required('Please provide the location of University/School you were enrolled in.'),

    education_end_present: yup.bool(),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (isEditing) {
      updateEducation(activeProfile.id, educationId, {
        ...values,
        person: activeProfile.id,
      })
        .then(() => {
          setSubmitting(false);
          closeDialog();

          onUpdated();
        })
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      addEducation(activeProfile.id, { ...values, person: activeProfile.id })
        .then(() => {
          setSubmitting(false);
          closeDialog();

          onUpdated();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  let initialValues = {
    education_title: "",
    education_summary: "",
    education_start: new Date(),
    education_end: new Date(),
    education_end_present: false,
    // education_loc: "",
  };
  if (isEditing) {
    initialValues = {
      education_title: currentValues.education_title,
      education_summary: currentValues.education_summary,
      education_start: new Date(currentValues.education_start),
      education_end: new Date(currentValues.education_end),
      education_end_present: currentValues.education_end_present,
      //  education_loc : currentValues.education_loc,
    };
  }

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit" : "Add"} Education</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={educationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              <Form.Group>
                <Form.Label htmlFor="current-company">Degree/Program</Form.Label>
                <Form.Control
                  placeholder="The name of the degree/program you were enrolled in"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="education_title"
                  isInvalid={touched.education_title && errors.education_title}
                  value={values.education_title}
                  type="text"
                  as="input"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.education_title}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="position">University/School</Form.Label>
                <Form.Control
                  placeholder="The name of the University/School you were enrolled in"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="education_summary"
                  isInvalid={
                    touched.education_summary && errors.education_summary
                  }
                  value={values.education_summary}
                  type="text"
                  as="input"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.education_summary}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group>
                <Form.Label htmlFor="education_loc">Location</Form.Label>
                <Form.Control
                  placeholder="The location of the University/School you were enrolled in"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="education_loc"
                  isInvalid={touched.education_loc && errors.education_loc}
                  value={values.education_loc}
                  type="text"
                  as="input"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.education_loc}
                </Form.Control.Feedback>
              </Form.Group> */}

              {/* <p className="ic-form-badge font-italic"><span className="badge badge-warning"><i className="fas fa-check" aria-hidden="false"></i> </span> <span>Current position</span></p> */}
              {/* <Form.Group>
                <Form.Label htmlFor="position">Position</Form.Label>
                <Form.Control as="select" className="ic-form-select" id="position">
                  <option value="0">Select</option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                </Form.Control>
                <p className="ic-form-badge font-italic"><span className="badge badge-warning"><i className="fas fa-check" aria-hidden="false"></i> </span> <span>Current position</span></p>
              </Form.Group> */}

              <Form.Row className="ic-form-inline-fullwidth">
                <Form.Group as={Col} col={12} md={6}>
                  <Form.Label>From</Form.Label>
                  <DatePicker
                    nativeInputAriaLabel="select starting date"
                    maxDate={new Date()}
                    placeholderText="When did you start?"
                    name="education_start"
                  />

                  {/* <Form.Control.Feedback type="invalid">
                    {errors.education_start}
                  </Form.Control.Feedback> */}
                </Form.Group>

                <Col col={12} md={6}>
                  <Form.Group as={Col} col={12}>
                    <Form.Label>To</Form.Label>
                    <DatePicker
                      nativeInputAriaLabel="select ending date"
                      disabled={values.education_end_present}
                      maxDate={new Date()}
                      minDate={new Date(values.experience_start)}
                      name="education_end"
                      placeholderText="When did you finish?"
                    />
                  </Form.Group>
                  <Form.Check>
                    <Form.Check.Input
                      checked={values.education_end_present}
                      name="education_end_present"
                      value={values.education_end_present}
                      onChange={handleChange}
                      type="checkbox"
                    />
                    <Form.Check.Label style={{ fontSize: "16px" }}>
                      I still study here
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Row>
            </Modal.Body>

            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button
                      onClick={closeDialog}
                      block
                      className="modal-cancel-button"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="mt-3 mt-md-0" sm={12} md={6}>
                    <Button
                      loading={isSubmitting ? 1 : 0}
                      block
                      className="modal-submit-button"
                      type="submit"
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {}
)(AddEditEducation);
