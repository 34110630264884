import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Colors } from "../../styles/constants";
import { capitalizeFirstLetter } from "../../common/utils";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { searchProfile } from "../../api";

const UserSearch = ({ activeProfile, setExpanded }) => {
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [hasFocus, setHasFocus] = useState(false);
  const node = useRef();
  const history = useHistory();
  const { t } = useTranslation();
  const direction = i18n.dir();

  const handleChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const search = () => {
    setSearching(true);

    searchProfile({ q: query })
      .then(({ data: { profiles } }) => {
        const prof = JSON.parse(profiles);

        const filteredProfiles = prof.filter(
          (profile) => profile.fields.owner_id !== activeProfile.owner_id
        );
        setResults(filteredProfiles);
        setSearching(false);
      })
      .catch(() => {
        setResults([]);
        setSearching(false);
      });
  };
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setHasFocus(false);
  };

  const handleEnterPress = (e) => {
    if (hasFocus) {
      const code = e.keyCode || e.which;
      if (code === 13 && query !== "") {
        e.preventDefault();
        setSearching(true);
        setExpanded(false);
        history.push(`/search/${query}`);
      }
    }
  };

  const handlePills = () => {
    setSearching(true);
    setExpanded(false);
  };

  const seeAll = () => {
    if (hasFocus) {
      setSearching(true);
      setExpanded(false);
      history.push(`/search/${query}`);
    }
  };

  useEffect(() => {
    if (query && query.length >= 3) {
      search();
    } else {
      setSearching(false);
      setResults([]);
    }
  }, [query]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let searchContent;

  //If user searches something, but no results.
  if (!searching && results.length === 0 && query && hasFocus) {
    searchContent = <SearcheMessage className={direction === "rtl" ? 'arabic-display' : ''}>{t("No users found")}</SearcheMessage>;

    //If user searches, and results are available.
  } else if (!searching && results.length && query && hasFocus) {
    searchContent = (
      <SearchResults className={direction === "rtl" ? 'arabic-display' : ''}>
        {
          <React.Fragment>
            <SearchPills>
              <h4>{t("Find in")}</h4>
              <Nav variant="pills">
                <Link
                  to={`/search/people/${query}`}
                  className="nav-link nav-item"
                  onClick={() => handlePills()}
                >
                  {t("People")}
                </Link>
                <Link
                  to={`/search/organization/${query}`}
                  className="nav-link nav-item"
                  onClick={() => handlePills()}
                >
                  {t("Organization")}
                </Link>
              </Nav>
            </SearchPills>
            {results.slice(0, 5).map((result) =>
              result.model === "profilepage.personprofile" ? (
                <SearchResult key={result.pk}>
                  <Link
                    onClick={() => {
                      setHasFocus(false);
                      setExpanded(false);
                    }}
                    to={`/p/${result.fields.slug}`}
                  >
                    <UserAvatar
                      className={direction === "rtl" ? 'ml-3' : 'mr-3'}
                      src={`https://inclusive-storage.s3.amazonaws.com/media/public/${result.fields.person_display_picture}`}
                    />
                    <span>
                      {`${capitalizeFirstLetter(
                        result.fields.person_first_name
                      )} ${capitalizeFirstLetter(
                        result.fields.person_last_name
                      )}`}
                    </span>
                  </Link>
                </SearchResult>
              ) : (
                <SearchResult key={result.pk}>
                  <Link
                    onClick={() => {
                      setHasFocus(false);
                      setExpanded(false);
                    }}
                    to={`/o/${result.fields.slug}`}
                  >
                    <UserAvatar
                      className={direction === "rtl" ? 'ml-3' : 'mr-3'}
                      src={`https://inclusive-storage.s3.amazonaws.com/media/public/${result.fields.organization_logo}`}
                    />
                    <span>
                      {`${capitalizeFirstLetter(
                        result.fields.organization_title
                      )}`}
                    </span>
                  </Link>
                </SearchResult>
              )
            )}
            <SearchResult onClick={seeAll}>
              <span className="show-all-results">
                {/* See all results for<strong>{` ${query}`}</strong> */}
                {t("Show All Results")}
              </span>
            </SearchResult>
          </React.Fragment>
        }
      </SearchResults>
    );
  }

  return (
    <StyledUserSearch className={`form-inline px-lg-4 ${direction === "rtl" ? 'arabic-display' : ''}`} ref={node} role="search">
      <input
        className={`form-control search_bar px-4 ${direction === "rtl" ? 'arabic-display' : ''}`}
        value={query}
        onChange={(e) => handleChange(e)}
        onFocus={() => setHasFocus(true)}
        onKeyPress={(e) => handleEnterPress(e)}
        type="search"
        placeholder={t("Search...")}
        aria-label="Search"
      />
      {searchContent}
      <SearchIcon icon="search" className={direction === "rtl" ? 'display-rtl' : 'display-ltr'}/>
    </StyledUserSearch>
  );
};

export default connect((state) => ({
  activeProfile: state.user.activeProfile,
}))(UserSearch);

const StyledUserSearch = styled.form`
  font-family: "Inter", sans-serif;
  position: relative;

  @media (max-width: 991px) {
    padding: 12px 0 8px 0;
  }

  .search_bar {
    font-family: "Inter", sans-serif;
    position: relative;
    font-style: normal;
    font-size: 14px;
    width: 300px;
    height: 40px;
    background: #f8f8f8;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    border: none;
    padding-left: 1.4rem;

    // &:focus {
    //   box-shadow: none;
    //   border: solid 0.05rem rgba(0, 0, 0, 0.08);
    // }
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 426px) {
      width: 366px;
    }
    @media (max-width: 375px) {
      width: 327px;
    }
    @media (max-width: 320px) {
      width: 272px;
    }
  }
  
  .search_bar:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
    border: solid 0.05rem rgba(0, 0, 0, 0.08);
  }
    
  .search_bar:focus-visible {
    outline: 2px solid #8c8c8c;
  }

  /* clears the 'X' from Internet Explorer */
  input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  .display-ltr {
    right: 35px;
    @media (max-width: 991px) {
      top: 54%;
      right: 15px;
    }
    @media (max-width: 768px) {
      top: 33px;
    }
  }
  
  .display-rtl {
    left: 35px;
    @media (max-width: 991px) {
      top: 54%;
      left: 15px;
    }
    @media (max-width: 768px) {
      top: 33px;
    }
  }

`;

const SearchIcon = styled(FontAwesomeIcon)`
  color: ${Colors.font.secondary};
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
`;

const SearchResults = styled.div`
  font-family: "Inter", sans-serif;
  width: 86%;
  position: absolute;
  //max-height: 440px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  top: calc(100% + 5px);
  padding: 0;
  z-index: 50;

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
  }
`;

const SearchResult = styled.div`
  a {
    text-decoration: none;
    color: ${Colors.font.primary};
    letter-spacing: 0.1rem;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    background-color: white;
    transition: 250ms ease;
    border-bottom: 1px solid #eee;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      text-decoration: none;
      cursor: pointer;
    }
    @media (max-width: 480px) {
      padding: 0.5rem 0.8rem;
    }
  }

  .show-all-results {
    letter-spacing: 0.1rem;
    padding: 0.8rem;
    display: flex;
    color: #3F0E40;
    font-weight: 500;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      text-decoration: none;
      cursor: pointer;
    }
  }

  > span {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
`;

const SearcheMessage = styled(SearchResults)`
  padding: 1rem 2rem;
  color: ${Colors.font.secondary};
`;

const SearchPills = styled.div`
  overflow: hidden;
  padding: 0;
  .navbar-nav.nav-pills {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #eee;
    padding: 0.8rem 0;
    @media (max-width: 556px) {
      display: -webkit-box;
      padding: 0.8rem;
    }
  }

  .nav-pills .nav-item {
    border-radius: 25px;
    color: #515151;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #8a8a8a;
    padding: 0.1rem 1rem;
    min-width: 115px;
    text-align: center;
    cursor: pointer;
    @media (max-width: 556px) {
      margin-right: 0.8rem;
      min-width: 144px;
    }
  }

  a.nav-link.nav-item:hover {
    background-color: #3F0E40 !important;
    border-color: #3F0E40 !important;
    color: #ffffff;
  }

  h4 {
    //border-bottom: 1px solid #eee;
    padding: 0.8rem 0.8rem 0 0.8rem;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

const UserAvatar = styled.img`
  width: 30px;
  height: 30px;
  border: 1px solid #dadbe2;
  border-radius: 50%;
  //margin-right: 1rem;
`;
