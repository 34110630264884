import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from '../common/Form';
import SearchDropDown from './SearchDropDown';
import { cities as getCities, getCountries, getCitiesByCountry } from '../api';

//Get cities list based on selected country.
const CountryCity = ({
  cityValid,
  setCityValid,
  setUserCity,
  person_city,
  citytext,
  countrytext,
  row,
  jobForm,
  location,
  clearFields,
  orgForm,
  orgCity,
  setOrgCity,
  orgCountry,
  setOrgCountry
}) => {


  const [cities, setCities] = useState([]);
  const [value, setValue] = useState('');
  const [countries, setCountries] = useState([]);

  const [country, setCountry] = useState('')
  const [userCountry, setUserCountry] = useState(null)
  const [countryValid, setCitycountryValid] = useState(false)
  const [loadcity, setLoadCity] = useState(false)

  const handleList = (e) => {
    const selectedCity = cities.filter(m => m.id == e)
    setValue(selectedCity[0].city_name)
    setUserCity(e)
    setCityValid(false)
    if (jobForm === true) {
      location.cityId = e;
      location.cityName = selectedCity[0].city_name;
    }
    if (orgForm === true) {
      setOrgCity(selectedCity[0].city_name);
    }
  }

  const handleValueChange = (e) => {
    if (e.target.value === "")
      setUserCity(null)
      setValue(e.target.value)
    if (jobForm === true) {
      location.cityName = e.target.value;
    }
    if (orgForm === true) {
      setOrgCity(e.target.value);
    }
  }

  const handleCountryList = (e) => {
    setLoadCity(true)
    const selectedCountry = countries.filter(m => m.id == e)
    getCitiesByCountry(e)
      .then(({ data: _cities }) => {
        setCities(_cities);
        setLoadCity(false)
      });
    setCountry(selectedCountry[0].country_name)
    setUserCountry(e)
    setCitycountryValid(false)
    setValue('')
    setUserCity(null)
    if (jobForm === true) {
      location.countryId = e;
      location.countryName = selectedCountry[0].country_name;
    }
    if (orgForm === true) {
      setOrgCountry(selectedCountry[0].country_name);
      setOrgCity('');
    }
  }
  const handleCountryValueChange = (e) => {
    if (e.target.value === "") {
      setUserCountry(null)
      setValue('')
      setUserCity(null)
      setCities([])
    }
    setCountry(e.target.value)
    if (jobForm === true) {
      location.countryName = e.target.value;
    }
    if (orgForm === true) {
      setOrgCountry(e.target.value);
    }
  }

  useEffect(() => {
    if (clearFields && jobForm) {
      if (location.countryId === null) {
        setValue('');
        setUserCity(null);
        setCities([]);
      }
    }
  },[clearFields]);

  useEffect(() => {
    let unmounted = false;
    getCountries()
      .then(({ data: _countries }) => {
        if (!unmounted) {
          setCountries(_countries);
          if (person_city) {
            const selectedCity = _countries.filter(m => m.id == person_city.city_country.id)
            setCountry(selectedCity[0].country_name)
            setUserCountry(selectedCity[0].id)
            setCitycountryValid(false)
            getCitiesByCountry(selectedCity[0].id)
              .then(({ data: _cities }) => {
                setCities(_cities);
                const selectedCity = _cities.filter(m => m.id == person_city.id)
                setValue(selectedCity[0].city_name)
                setUserCity(person_city.id)
                setCityValid(false)
              });
            //setUserCity(profile.person_city.id)
            //setCityValid(false)
          }
        }
      });
    return () => { unmounted = true };
  }, []);




  return (
    row ? (
      <Row>
        <Form.Group as={Col} col={12}>
          <Form.Label>{countrytext ? countrytext : "Which Country do you live in?"}</Form.Label>
          <SearchDropDown isModal isDisable={false} isCountry={true} value={country} handlelistChange={handleCountryList} list={countries} handleValueChange={handleCountryValueChange} isValid={countryValid} />
          {cityValid && ( !country ? (
            <Form.Control.Feedback style={{ display: "block" }} type="invalid">
              Please select a country to view the list of cities.
            </Form.Control.Feedback>
            ):""
          )}
        </Form.Group>

        <Form.Group as={Col} col={12}>
          <Form.Label>{citytext ? citytext : "Which City do you live in?"}</Form.Label>
          {loadcity ? <center><i style={{ fontSize: "20px" }} className="fas fa-spinner fa-spin" /></center> : <SearchDropDown isModal isDisable={cities.length ? false : true} value={value} handlelistChange={handleList} list={cities} handleValueChange={handleValueChange} isValid={cityValid} />}
          {cityValid &&
            <Form.Control.Feedback style={{ display: "block" }} type="invalid">
              Please select the city you currently reside in.
            </Form.Control.Feedback>
          }
        </Form.Group>
      </Row>
    ) : (
        <Form.Row>
          <Form.Group as={Col} col={12}>
            <Form.Label>{countrytext ? countrytext : "Which Country do you live in?"}<span style={{color: "#dc3545"}}> *</span></Form.Label>
            <SearchDropDown isModal isDisable={false} isCountry={true} value={jobForm ? location.countryName : orgForm ? orgCountry : country} handlelistChange={handleCountryList} list={countries} handleValueChange={handleCountryValueChange} isValid={countryValid} />
            {countryValid &&
              <Form.Control.Feedback style={{ display: "block" }} type="invalid">
                Please select the city you currently reside in.
            </Form.Control.Feedback>
            }
          </Form.Group>

          <Form.Group as={Col} col={12}>
            <Form.Label>{citytext ? citytext : "Which City do you live in?"}<span style={{color: "#dc3545"}}> *</span></Form.Label>
            {loadcity ? <center><i style={{ fontSize: "20px" }} className="fas fa-spinner fa-spin" /></center> : <SearchDropDown isModal isDisable={orgCity !== '' ? false : cities.length ? false : true} value={jobForm ? location.cityName : orgForm ? orgCity : value} handlelistChange={handleList} list={cities} handleValueChange={handleValueChange} isValid={cityValid} />}
            {cityValid &&
              <Form.Control.Feedback style={{ display: "block" }} type="invalid">
                Please select the city you currently reside in.
            </Form.Control.Feedback>
            }
          </Form.Group>
        </Form.Row>
      )

  )
}

export default CountryCity