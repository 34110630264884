import React, {Suspense, lazy} from "react";
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {LayoutSplashScreen,
   ContentRoute,MaterialThemeProvider
   ,Layout
   ,MetronicLayoutProvider,
  MetronicSubheaderProvider} from "../_metronic/layout";

//import DashboardCSS from '../common/DashboardCSS'
//const DashboardCSS = React.lazy(() => import('../common/DashboardCSS'));
const DashBoardRoutes = ({ authenticated, component: Component, ...rest }) => {
  
  return(
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
       <MaterialThemeProvider>
    <Layout>
  <Route
    {...rest}
    render={(props) => {
      if (authenticated) {
        return <Component {...props} />;
      }

      return (
        <Redirect to={{
          pathname: '/auth/login',
          state: {
            from: props.location,
          },
        }}
        />
      );
    }}
  />
   </Layout>
  </MaterialThemeProvider>
  </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  )
};

DashBoardRoutes.propTypes = {
  component: PropTypes.func.isRequired,
};

export default connect((state) => ({
  authenticated: state.user.authenticated,
}))(DashBoardRoutes);
