import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from "@sentry/react";
//const AppCSS = React.lazy(() => import('../common/AppCSS'));


const AuthenticatedRoute = ({ authenticated, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (authenticated) {
        return <div className="impact-board-main">
            <Component {...props} />
        </div>
      }

      return (
        <Redirect to={{
          pathname: '/auth/login',
          state: {
            from: props.location,
          },
        }}
        />
      );
    }}
  />
);

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default connect((state) => ({
  authenticated: state.user.authenticated,
}))(AuthenticatedRoute);
