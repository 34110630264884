import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { addToast } from "../../redux/toast";
import styled from "styled-components";
import { Formik } from "formik";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import Form from "../../common/Form";
import * as yup from "yup";
import { addfeedPost } from "../../api";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import UploadImage from "../image/UploadImage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import imageCompression from "browser-image-compression";
import EditImage from "../image/EditImage";
import MentionTextarea from "../MentionTextarea";
import LinkPreview from './LinkPreview';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const PostCreator = ({
  onPostAdded,
  profile,
  poster,
  isModel,
  setShow,
  addToast,
  isEmojiDisable,
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [image, setImage] = useState(null);
  const [isPosting, setIsPosting] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [altText, setAltText] = useState("");
  const [imageUrlList, setImageUrlList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [compressImages, setCompressImages] = useState([]);
  const [tvalue, settValue] = useState("");

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  
  const direction = i18n.dir();
  const { t } = useTranslation();

  const [mention, setMention] = useState([]);
  const textareaRef = useRef();
  const emojiRef = useRef();
  const emojiclose = useRef();
  
  //Pass an empty object to the i18n property for Emoji Picker if lang is en.
  let emojiTranslations = {};

  //Pass an object with translations to the i18n property for Emoji Picker if lang is ar.
  if (i18n.language === "ar") {
    emojiTranslations = {
      search: "بحث",
      notfound: 'لم يتم العثور على رموز تعبيرية',
      skintext: 'اختر لون بشرتك الافتراضي',
      categories: {
        search: 'نتائج البحث',
        recent: 'كثيرا ما تستخدم',
        smileys: 'الوجوه الضاحكة والعاطفة',
        people: 'الناس والجسم',
        nature: 'الحيوانات والطبيعة',
        foods: 'طعام شراب',
        activity: 'نشاط',
        places: 'السفر والأماكن',
        objects: 'شاء',
        symbols: 'حرف او رمز',
        flags: 'أعلام',
        custom: 'مخصص',
      }}
  } 

  const [value, setValue] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const [hasLink, setHasLink] = useState(false);
  const [linkAlttext, setLinkAlttext] = useState("");
  const [isReq, setIsReq] = useState(false);
  const [isError, setIsError] = useState(false);


  const handleChangeAltText = (e) => {
    if (isError && e.target.value !== "") {
      setIsError(false);
    }
    setLinkAlttext(e.target.value);
  };

  const handleRemoveImage = (index) => {
    const file = [...compressImages];
    const newfles = file.slice(0, index).concat(file.slice(index + 1));
    setImageUrlList(
      imageUrlList.slice(0, index).concat(imageUrlList.slice(index + 1))
    );
    setCompressImages(newfles);
  };

  const handleChangeText = (e) => {
    setAltText(e.target.value);
  };

  //Compressing added photo to less than 1 mb, and adding it's URL to a an object.
  const onPhotoAdded = (_image) => {
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };
    imageCompression(_image, options)
      .then(function (compressedFile) {
        setCompressImages((compressImages) => [
          ...compressImages,
          new File([compressedFile], compressedFile.name),
        ]);
        setImageUrlList((imageUrlList) => [
          ...imageUrlList,
          URL.createObjectURL(compressedFile),
        ]);
      })
      .catch(function (error) {});
  };

  const photoChanged = (image, index) => {
    const file = [...compressImages];
    const urls = [...imageUrlList];
    file[index] = image;
    urls[index] = URL.createObjectURL(image);

    setImageUrlList(urls);
  };

  const handleEditImage = (index) => {
    const file = [...compressImages];
    setEditIndex(index);

    setEditImage(file[index]);
    setShowEdit(true);
  };

  const makePost = (values) => {
    setSubmitting(true);
    let bodyText = tvalue;

    if (hasLink) {
      if (isReq) {
        if (!linkAlttext.length) {
          setIsError(true);
          setSubmitting(false);
          return;
        } else
          bodyText =
            tvalue +
            ` </br></br>` + `${t("Image description of photo in link:")}` +
            ` ${linkAlttext}`;
      }
      if (linkAlttext.length) {
      }
    }
    const formData = new FormData();
    formData.append("posted_by", poster.owner_id);
    formData.append("posted_on", profile.owner_id);
    formData.append("post_body", bodyText);

    const mentions = mention.map((mention) => {
      return {
        mention: mention.id,
        mention_title: mention.display,
      };
    });

    const uniqueObjects = [
      ...new Map(mentions.map((item) => [item.mention, item])).values(),
    ];
    formData.append("mentions", JSON.stringify(uniqueObjects));

    if (compressImages) {
      const imageFiles = [...compressImages];
      if (imageFiles.length) {
        imageFiles.map((file) => {
          formData.append("photos", file, file.name);
          formData.append("photos[photo_alt_text]", "alt text for images");
        });
      }
    }

    addfeedPost(formData)
      .then(({ data: newPost }) => {
        onPostAdded(newPost);
        setPostContent("");
        setIsPosting(false);
        setIsCreating(false);
        setSubmitting(false);
        setImage(null);
        setValue("");
        settValue("");
        setMention([]);
        setCompressImages([]);
        setImageUrlList([]);
        setHasLink(false);
        setLinkAlttext("");
        setIsReq(false);
        setIsError(false);
        isModel ? setShow(false) : "";
      })
      .catch((error) => {
        setIsPosting(false);
        setIsPosting(false);
        setIsCreating(false);
        setSubmitting(false);
        if (Object.values(error)[2].status === 405) {
          addToast({
            type: "error",
            title: "Error",
            text: "Sorry, you're not authorized to perform this operation.",
          });
        }
      });
  };

  if (!poster) {
    return null;
  }

  const emojiAdded = (emoji) => {
    let sym = emoji.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emojis = String.fromCodePoint(...codesArray);

    var index = value.length;
    //setPValue(Pvalue.slice(0, index) + emoji.native + Pvalue.slice(index))

    //To ensure emoji is added to the end of text, not in the middle.
    setValue(value.slice(0, index) + emojis + value.slice(index));
    settValue(tvalue.slice(0, index) + emojis + tvalue.slice(index));
    setShowEmoji(!showEmoji);

    var index = index + 7;

    const cursorPosition = textareaRef.current.selectionEnd;
    const start = value.substring(0, textareaRef.current.selectionEnd);
    const end = value.substring(textareaRef.current.selectionEnd);
    const text = start + emoji.native + end;
  };

  const validationSchema = yup.object().shape({
    // body: yup.string().max(1500, 'Maximum Character limit is 1500.'),
  });

  return (
    <StyledPostCreator className="ic-app-create-post second-step">
      <div className="container-fluid pl-0 pr-0">
        <Formik
          initialValues={{
            body: "",
          }}
          validationSchema={validationSchema}
          onSubmit={makePost}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <>
              <div
                className="card border-0 p-0 m-0"
                style={{ marginBottom: "0px !important" }}
              >
                <div className={`card-heading ${direction === "rtl" ? "arabic-display" : ""}`}>
                  {t("Share something amazing!")}
                </div>
                <div className="card-body pb-0 pl-0 pt-1 pr-0">
                  <div className="">
                    <div className="ic-create-post">
                      <div className="ic-create-post-message">
                        <div
                          col={12}
                          className="row"
                          style={{ width: "100%" }}
                        >
                          <MentionTextarea
                            setShowEmoji={setShowEmoji}
                            setMention={setMention}
                            value={value}
                            setValue={setValue}
                            settValue={settValue}
                            textareaRef={textareaRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form.Group className="form-group row">
                    <div className="col-lg-12 col-md-12">
                      {/* {image ? "Image selected" : null} */}
                      {imageUrlList && imageUrlList.length ? (
                        <div className="d-flex flex-wrap">
                          {imageUrlList.map((src, index) => (
                            <div className="imgswrapper" key={index}>
                              <img src={src} height="100" width="90" />
                              <i
                                onClick={() => handleRemoveImage(index)}
                                className="closebtn fa fa-times-circle"
                                aria-hidden="true"
                              ></i>
                              <div className="overlay">
                                <i
                                  onClick={() => handleEditImage(index)}
                                  className="text editbtn fa fa-edit"
                                  aria-hidden="true"
                                ></i>

                                {editImage && (
                                  <EditImage
                                    show={showEdit}
                                    image={editImage}
                                    onClose={() => setShowEdit(false)}
                                    index={editIndex}
                                    photoChanged={photoChanged}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form.Group>
                </div>

                {value && value !== "" ? (
                  <div
                    className="row"
                    style={{ display: `${hasLink ? "flex" : "none"}` }}
                  >
                    <div className="d-flex flex-column richlink">
                     <LinkPreview value={value} setHasLink={setHasLink} setIsReq={setIsReq} />
                      {hasLink && isReq && (
                        <>
                          <Form.Row className="pt-3">
                            <Form.Group as={Col} col={"12"}>
                              <Form.Label style={{ display: 'flex' }}>{t("Alternative-text")}
                              <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Alt-text Tooltip" aria-pressed={showTooltip}>
                                <i className="material-icons help-icon">help_outline</i>
                              </Button>
                              </Form.Label>
                              <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                                {(props) => (
                                  <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                                    Add a description of the image in words to be read by screen readers in place of the image, allowing your image content to be accessed by people with visual disabilities.
                                  </Tooltip>
                                )}
                              </Overlay>
                              <Form.Control
                                placeholder={t("Image description of photo in link")}
                                onChange={handleChangeAltText}
                                className={`${isError ? "is-invalid" : ""}`}
                                name="alt_text"
                                value={linkAlttext}
                                type="text"
                                as="input"
                              />
                              <Form.Control.Feedback type="invalid">
                                {t("Please Enter the image description of photo in link")}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row d-flex justify-content-between align-items-center card-footer border-0 bg-transparent" style={{position: "relative", padding: "0 15px"}}>
               
                  <div className="d-flex align-items-center">
                      <UploadImage
                        handleText={handleChangeText}
                        altText={altText}
                        isIcon
                        disabled={isPosting}
                        loading={isPosting ? 1 : 0}
                        photoChanged={onPhotoAdded}
                        imageUrlList={imageUrlList}
                      />
                      <Button
                        ref={emojiRef}
                        onClick={() => {
                          setShowEmoji(!showEmoji);
                          //textareaRef.current.focus()
                          //setShowEmoji(!showEmoji)
                        }}
                        className={`add-emoji-button fourth-step ${direction === "rtl" ? "ml-0":""}`}
                        style={{marginRight: `${direction === "rtl" ? "6px":""}`}}
                        aria-label="Add Emoji"
                      >
                          <i
                            className="material-icons"
                            style={{ fontSize: "26px" }}
                          >
                            sentiment_satisfied_alt
                          </i>
                      </Button>
                      <div className={`emoji-trigger ${direction === "rtl" ? "displayRtl" : ""}`} ref={emojiRef}>
                        {showEmoji && (
                          <Picker
                            show={false}
                            onSelect={emojiAdded}
                            title={"Emojis"}
                            color={`#000000`}
                            emojiSize={30}
                            i18n={emojiTranslations}
                          />
                        )}
                      </div>
                    </div>
                    <Button
                      disabled={isSubmitting || !tvalue.trim()}
                      style={{ height: "100%" }}
                      className="ic-primary-button last-step"
                      block
                      type="submit"
                      id="postBtn"
                      onClick={makePost}
                    >
                      {isSubmitting ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        `${t("POST")}`
                      )}
                    </Button>
                  </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </StyledPostCreator>
  );
};

export default connect((state) => ({
  poster: state.user.activeProfile,
}),  {
  addToast,
}
)(PostCreator);

const StyledPostCreator = styled.div`
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  border: 0px !important;

  @media(max-width: 600px) {
    margin-bottom: 0;
    box-shadow: none !important;
  }

  .imgswrapper{
    padding: 3px;
    border: 2px solid;
    margin: 7px;
    border: solid 3px #dadbe2;
    position: relative;
  }
  .imgswrapper:hover .overlay {
    .editbtn{
      opacity: 1 !important;
    }
   } 

   .overlay > .editbtn {
    position: absolute;
    opacity: 0 !important;
    transition: all 250ms ease;
    transform: translateX(-50%);
    cursor:pointer
  
    &:focus {
      opacity: 1 !important;
      border: 0.15rem solid black;
    }
  }

  .card-heading {
    font-size: 18px;
    font-family: 'Inter',sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #000000;
    padding: 0 0 5px 5px;
    display: flex;
    align-items: center;
  }

  i.material-icons.help-icon {
    font-size: 20px;
    color: #8c8c8c;
  }

  i.material-icons.help-icon:hover {
    color: #515151;
  }

  .help-button {
    background-color: #fff;
    padding: 0 0.3rem;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .help-button:active {
    background-color: #fff !important;
    border-color: #fff !important;
  }

  .help-button:focus-visible {
    box-shadow: 0 0 0 0.13rem #000 !important;
    border-radius: 6px;
  }

   .tooltip-inner {
    max-width: 400px !important;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 42px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .imgswrapper:hover{

  }
  .closebtn{
    position:absolute;
    top:-10px;
    color: gray;
    cursor:pointer
  }

  .ic-primary-button {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.065em; 
    color: #FFFFFF;
    background: #3F0E40;
    border-color: #3F0E40;
    border-radius: 6px;
    width: 115px;
    height: 40px !important;
    &:focus {
      box-shadow: 0 0 0 0.15rem #0056b3 !important;
    }
    &:active {
      color: #FFFFFF !important;
      border-color: #3F0E40 !important;
      background-color: #3F0E40 !important;
    }
  }

  .upload-image-button {
    width: 120px;
    height: 40px;
    background: #000000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      color: #FFFFFF !important;
      border-color: #000000 !important;
      background-color: #000000 !important;
    }
    //Using focus-within pseudo class, the outer element gets highlighted when the inner element has focus.
    &:focus-within {
      border: 2px solid #0056b3 !important;
    }
  }

  .add-emoji-button {
    width: 46px;
    height: 40px;
    background: #000000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    color: #FFFFFF;
    border-color: #000000;
    display: flex !important;
    //align-items: center !important;
    justify-content: center !important;
    margin-left: 6px;
    &:focus-visible {
      box-shadow: 0 0 0 0.15rem #0056b3 !important;
    }
    &:active {
      color: #FFFFFF !important;
      border-color: #000000 !important;
      background-color: #000000 !important;
    }
  }

  input.inputBtn {
    width: 120px;
    height: 67px;
    position: absolute;
    left: 0px;
    top: -27px;
    cursor: pointer;
    opacity: 0;
}

  .ic-profile-wrapper {
    max-width: 7%;
    width: 100%;
  }

  @media (max-width: 1200px) {
    .ic-profile-wrapper {
      max-width: 12%;
    }
  }

  @media (max-width: 991px) {
    .ic-profile-wrapper {
      max-width: 15%;
    }
  }

  @media (max-width: 768px) {
    .ic-profile-wrapper {
      max-width: 10%;
    }
  }

  @media (max-width: 567px) {
    .ic-profile-wrapper {
      max-width: 15%;
    }
  }

  @media (max-width: 480px) {
    .ic-profile-wrapper {
      max-width: 20%;
    }
  }

  @media (max-width: 320px) {
    .ic-profile-wrapper {
      max-width: 25%;
    }
  }

  .ic-profile-wrapper .ic-profile img {
    border-radius: 50%;
    width: 55px;
    height: 55px;

    object-fit: cover;
  }

  .ic-create-post-wrapper {
    max-width: 93%;
    width: 100%;
  }

  @media (max-width: 1200px) {
    .ic-create-post-wrapper {
      max-width: 88%;
    }
  }

  @media (max-width: 991px) {
    .ic-create-post-wrapper {
      max-width: 85%;
    }
  }

  @media (max-width: 768px) {
    .ic-create-post-wrapper {
      max-width: 90%;
    }
  }

  @media (max-width: 567px) {
    .ic-create-post-wrapper {
      max-width: 85%;
    }
  }

  @media (max-width: 480px) {
    .ic-create-post-wrapper {
      max-width: 100%;
    }
    .emoji-mart-light{
      left: -5px !important;
      max-width:100% !important;
    }

  }

  @media (max-width: 320px) {
    .ic-create-post-wrapper {
      max-width: 75%;
    }
  }

  .ic-create-post-wrapper .ic-create-post .ic-create-post-message form {
    max-width: 100%;
  }

  .ic-create-post-wrapper
    .ic-create-post
    .ic-create-post-message
    form
    .form-group {
    margin: 0;
  }

  .ic-create-post-wrapper
    .ic-create-post
    .ic-create-post-message
    form
    .form-group
    input {
    border: solid 0.8px rgba(218, 219, 226, 0.2);
    /* border-left: none; */
    max-width: 100%;
  }
  .mentionedTextarea__input{
    height: 137px;
  } 
  .imgDP{
    border-radius: 50%;
    }
    .mentionedTextarea {
      width:100%
    }
    .emoji-trigger{
      // position: absolute;
      // top: 94px;
      // left: 88px;
      padding-bottom: 5px;
      cursor:pointer
       // z-index:100000
    }
    .emoji-triggersss{
      // position: absolute;
      // top: 0;
      // left: -37px;
       cursor:pointer
      // z-index:1
      padding: 0px 6px;
    }
    // .emoji-triggersss:hover{
    //  color:yellow
    // }

    .emoji-mart-bar {
        display: none;
    }

    .emoji-mart-category-label span {
        font-family: 'Inter', sans-serif;
    }

    .emoji-mart-emoji span {
      cursor: pointer !important;
    }

    .emoji-mart-search {
        font-family: 'Inter', sans-serif;
    }

    .emoji-mart-search input {
        border: 1px solid #E8E8E8;
        border-radius: 7px;
    }

    button.emoji-mart-search-icon {
        display: flex;
        align-self: center;
    }

    .displayRtl .emoji-mart-category-label span {
      font-family: 'Almarai', sans-serif;
    }

    .displayRtl .emoji-mart-search {
      font-family: 'Almarai', sans-serif;
    }

    .displayRtl .emoji-mart-no-results-label {
      font-family: 'Almarai', sans-serif;
    }

    .mentionedTextarea__suggestions__list{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

    }
    .mentionedTextarea__suggestions__item{
      padding:0.8rem;
      width:100%;
      border-bottom: 1px solid rgba(0,0,0,0.125);
      border-radius: .25rem;
      &:hover {
        background-color: rgba(0,0,0,0.05);
      }
    }
    mentionedTextarea__suggestions__item:last-child:after
{
  border:0px !important
}
    .mentionedTextarea__suggestions{
    //  top: 41px !important;
      z-index: 1000000000000000 !important;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: .25rem;
      top: 16px !important;
    }
    .emoji-mart-light{
      position:absolute;
      top:45px;
     z-index:100000;
      left: 110px;
      border: 1px solid #E8E8E8;
      border-radius: 10px;
      overflow: hidden;
    }
 
    .customTextArea:focus{
      //border:0px
      outline: none;
    }
    .mentionedTextarea__highlighter{
     // margin:12px 0;
    }
    .mentionedTextarea__highlighter, .customTextArea {
      padding: 1.2rem;    
      min-height: 137px;
      background: rgba(196, 196, 196, 0.05);
      border: 1px solid #E8E8E8;
      border-radius: 18px;
      margin: 0px 14px !important;   
    }
    .rtl-textarea {
      left: auto !important;
    }
    .cardheadPost{
      border:0px;
      padding-bottom: 4px;
    }
    .MentionedInput{
      background-color: #C5DAF4;
    }
    .emojiWrapper{
      padding-top: 1px;
    }

  .richlink{
    width:100%
    padding: 0px 15px 10px;
  }

  @media(max-width: 576px) {
    .preview_link_title {
      padding-top: 8px;
    }
    .richlink {
      padding: 0px 15px;
    }
  }

`;
