import React, { useState, useRef, useEffect } from "react"; 
import { useDispatch } from "react-redux";
import { addToast } from "../../../../../redux/toast";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Col from "react-bootstrap/Col";
import ListGroup from 'react-bootstrap/ListGroup';
import Form from "react-bootstrap/Form";
import { getOrganizationSkillOptions, getOrganizationJobSkills, addOrganizationJobSkill, deleteOrganizationJobSkill } from '../../../../../api';


const JobSkills = ({ jobSlug }) => {

  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillSuggestions, setSkillSuggestions] = useState([]);
  const [skillCount, setSkillCount] = useState(0);
  const [hasSkill, setHasSkill] = useState(false);
  const [skillValue, setSkillValue] = useState("");
  const skillField = useRef();
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    getOrganizationJobSkills(jobSlug)
    .then(({data}) => {
        setSelectedSkills(data);
    })
    .catch(() => {
        dispatch (
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            })
        )
    });
    getOrganizationSkillOptions()
    .then(({data}) => {
        setSkills(data);
    })
    .catch(() => {
        dispatch (
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            })
        )
    });
  }, []);

  const handleSkillSuggestion = (value) => {
    //Comparing the value entered to the skills list to show suggestions.
    setSkillValue(value);
    const skillEntered = value.trim().toLowerCase();
    const skillLength = skillEntered.length;
    let filteredSkills = [];
    filteredSkills = skills.filter(skill => skill.title.toLowerCase().slice(0, skillLength) === skillEntered);
    
    //If skill already selected, it won't appear in the suggested skills list.
    if (selectedSkills.length > 0) {
        for (let i = 0; i < selectedSkills.length; i++) {
            filteredSkills = filteredSkills.filter(skill => skill.title !== selectedSkills[i].job_skill);
        }
    }
    
    if (skillLength === 0 || value === "") {
        setSkillSuggestions([]);
        setHasSkill(false);
     }   
    else if (skillLength >= 1) {
        setSkillSuggestions(filteredSkills);
        setHasSkill(true);
    }
  };

  const handleAddSkill = (skill) => {
    //To make sure first letter is uppercase
    const firstLetter = skill.charAt(0).toUpperCase();
    const captitalizedSkill = firstLetter.concat(skill.slice(1, skill.length));  

    //To ensure no custom skill is added twice.
    const identicalSkill = handleCheckSkill(captitalizedSkill);

    if (!identicalSkill) {
        addOrganizationJobSkill(jobSlug, {job_skill: captitalizedSkill})
        .then(({data}) => {
            setSelectedSkills([...selectedSkills, {job_skill: captitalizedSkill, id: data.id}]);
            setSkillCount(selectedSkills.length + 1);
        })
        .catch(() => {
            dispatch (
                addToast({
                  type: "error",
                  title: "Error",
                  text: "An error occurred, please try again.",
                })
            )
        });
    }
    setSkillSuggestions([]);
    setHasSkill(false);
    skillField.current.value = "";
  }

  const handleCheckSkill = (skill) => {
    if (selectedSkills.length === 0) {
        return false;
    }
    else {
        for (let i = 0; i < selectedSkills.length; i++) {
            if (selectedSkills[i].job_skill === skill) {
                return true;
            }
        }
        return false;
    }
  }

  const handleDeleteSkill = (skillToRemove, skillId) => {
    deleteOrganizationJobSkill(jobSlug, skillId)
    .then(() => {
        const filteredSkills = [...selectedSkills];
        filteredSkills.splice(filteredSkills.findIndex((skill) => skill.job_skill === skillToRemove), 1);
        setSelectedSkills(filteredSkills);
        setSkillCount(selectedSkills.length - 1);        
    })
    .catch(() => {
        dispatch (
            addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
            })
        )
    });
  }

return (
        <> 
        <Form.Row className="skills-row">
            <Form.Group as={Col} col={12}>
                <Form.Label style={{ display: 'flex' }}>
                    Job Skills<span style={{ color: "#dc3545", paddingLeft: '4px' }}>*</span>
                    <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Job Skills Tooltip" aria-pressed={showTooltip}>
                        <i className="material-icons help-icon">help_outline</i>
                    </Button>
                </Form.Label>
                <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                    {(props) => (
                        <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                            Once you type, you will see a list of auto-suggested skills. Click on a skill to select it or add one of your own, if not on the list.
                        </Tooltip>
                    )}
                </Overlay>
                <Form.Label className="job-post-info">Add skills to make your job reach the right candidates. (Select up to 10)</Form.Label>                                       
                    {selectedSkills.length ? (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {selectedSkills.map((skill) => (
                                    <ListGroup.Item
                                        className="skill-pill-item"
                                        key={skill.id}
                                        value={skill.job_skill}
                                    >
                                        <Button className="delete-skill-button" onClick={() => handleDeleteSkill(skill.job_skill, skill.id)} aria-label="Delete skill">
                                            <i className="material-icons" style={{ fontSize: '20px' }}>clear</i>
                                        </Button>
                                        <span className="skill-pill-title">
                                            {skill.job_skill}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                ) : ""} 
                <Form.Control
                    placeholder={`${skillCount >= 10 ? "A maximum of 10 skills can be added." : "Type to add a skill"}`}
                    name="job_skills"
                    className=""
                    onChange={(e) => handleSkillSuggestion(e.target.value)}
                    type="text"
                    as="input"
                    ref={skillField}
                    autoComplete="off"
                    disabled={skillCount >= 10 ? true : false}
                    />
            </Form.Group>
                        
            <ListGroup className="skill-suggestions">
                {skillSuggestions.length ? (
                    skillSuggestions.map((suggestion) => (
                        <ListGroup.Item
                            className="skill-suggestion-item"
                            key={suggestion.id}
                            value={suggestion.title}
                            tabIndex="0"
                            aria-label={`Add ${suggestion.title} as a skill`}
                            role="button"
                            onClick={() => handleAddSkill(suggestion.title)}
                            onKeyUp={(event) => event.key === "Enter" ? handleAddSkill(suggestion.title):""}
                        >
                            {suggestion.title}
                        </ListGroup.Item>
                    ))
                    ) : (
                    hasSkill && (
                    <>
                        <ListGroup.Item className="d-flex align-items-center">
                            <i className="material-icons mr-2">search</i>No matching skill
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex align-items-center skill-suggestion-item" tabIndex="0" aria-label={`Add ${skillValue} as a skill`} role="button"onClick={() => handleAddSkill(skillValue)} onKeyUp={(event) => event.key === "Enter" ? handleAddSkill(skillValue):""}>
                            <i className="material-icons mr-2">add</i><span>Add <strong>{skillValue}</strong></span>
                        </ListGroup.Item>
                    </>
                ))}                             
            </ListGroup>  
        </Form.Row>
    </>
    )
};

export default JobSkills;


