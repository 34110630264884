export const Colors = {
  primary: '#f44653',
  delete: '#D3D3D3',
  white: '#fff',
  font: {
    primary: '#2a2a2a',
    secondary: '#939393',
  },
};

export const Spacing = {
  small: '0.5rem',
};
