import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import WarningIcon from '../assets/images/WarningIcon.png';


const CompleteProfileBar = ({ }) => {
    const history = useHistory();
    const { pathname: path } = useLocation();
    const [onProfile, setOnProfile] = useState(false);

    const activeProfileMeta = useSelector(state => state.user.activeProfileMeta);

    useEffect(() => {
        if (path === '/profile/story' || path === '/profile/conversations' || path === '/profile/opportunities' || path === '/profile/portfolio') {
            setOnProfile(true);
        }
        else {
            setOnProfile(false);
        }
    },[path]);

    if (activeProfileMeta.type !== "individual" || onProfile ) return null;

    return (
        <StyledBanner>
            <div className="profile-complete-banner" onClick={() => history.push("/profile/story")} style={{ cursor: onProfile ? 'auto' : 'pointer' }}>
                <div className="profile-complete-icon mr-2">
                    <img src={WarningIcon} alt="warning icon"/>
                </div>
                <div className="profile-complete-text">
                    Mandatory fields in your profile are missing. Please complete your profile to unlock all features.
                </div>
            </div>
        </StyledBanner>
    )
};

export default CompleteProfileBar;

const StyledBanner = styled.div`
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 56px;

    @media(max-width: 540px) {
        top: 55px;
    }

    .profile-complete-banner {
        padding: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #CD4A04;
    }
    .profile-complete-icon {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 540px) {
            display: none;
        }
    }
    .profile-complete-icon img {
        height: 100%;
    }
    .profile-complete-text {
        color: #ffffff;
        font-weight: 500;
        letter-spacing: 1px;
    }
`;
