import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import AppContentContainer from "../../../common/AppContentContainer";
import { format } from "date-fns";
import Row from 'react-bootstrap/Row';

import { getQuizResults } from "../../../api";

import i18n from "i18next";

const QuizResult = (setActiveProfileMeta, setActiveProfile, addToast) => {
  const direction = i18n.dir();
  const [quizResults, setQuizResults] = useState();

  const { lessonId } = useParams();

  useEffect(() => {
    let unmounted = false;
    getQuizResults(lessonId)
      .then(({ data }) => {
        if (!unmounted) {
          setQuizResults(data);
          console.log(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      unmounted = true;
    };
  }, []);
  let resultlistJsx;
  let resultbestJsx;
  

if (quizResults){
    resultlistJsx = quizResults.all.map((quiz_result) => {
    return (
      <tr key={quiz_result.id}>
        <td>{quiz_result.result}%</td>
        <td>
          {format(new Date(quiz_result.created_at), "MMMM do, yyyy H:mma")}
        </td>
      </tr>
    );
  });

  resultbestJsx = (
    <div>
        <h1>{quizResults.best.result}%</h1>
    </div>
  );



}

  return (
    <AppContentContainer classes="container">
    <StyledCourseCard>
      <Row style={{align:"center"}}>{resultbestJsx}</Row>
      <Row>
        <Table striped bordered>
          <tbody>{resultlistJsx}</tbody>
        </Table>
      </Row>
      </StyledCourseCard>
    </AppContentContainer>
  );
};

export default connect(
  (state) => ({
    activeProfileMeta: state.user.activeProfileMeta,
  }),
  {
    addToast,
  }
)(QuizResult);

const StyledCourseCard = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 100%;
  padding: 1.8rem 2rem;
`;
