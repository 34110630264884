import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  NavLink as Link,
  Redirect,
  useLocation,
  useRouteMatch,
  useParams,
  useHistory,
  Switch,
  Route,
} from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import styled from "styled-components";
import Button from "react-bootstrap/Button";
import AppContentContainer from "../../../common/AppContentContainer";
import AccessibiltiyIconDisplay from "../../../components/AccessibilityIconDIsplay";
import {
  getOrganizationJobBySlug,
  deleteOrganizationJob,
  getJobAppicationList,
} from "../../../api";
import { LoaderWithText } from "../../../components/Loader";
import { processDate } from "../../../common/utils";
import AddEditJob from "./AddEditJob";
import ViewJobApplicant from "./ViewJobApplicant";

const OrganizationJobDetail = () => {
  const { jobslug, jobId } = useParams();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [currentJob, setJob] = useState(null);
  const [JobLoading, setJobLoading] = useState(false);
  const [isAccessible, setAccessible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  //const [appplicants, setApplicants] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [appplicants, setApplicants] = useState([]);

  const Job = () => {
    setJob(null);
    setJobLoading(true);

    getOrganizationJobBySlug(jobslug)
      .then(({ data }) => {
        if (
          data.accessibility_hearing ||
          data.vaccessibility_hearing ||
          data.accssibility_cognitive ||
          data.accessibility_mobility ||
          data.accessibility_sensory_friendly
        ) {
          setAccessible(true);
        }
        setJob(data);
        setJobLoading(false);
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (jobslug) {
      Job();
    } else {
    }
  }, [jobslug]);

  useEffect(() => {
    if (jobId) {
      setJobLoading(true);
      getJobAppicationList(jobId)
        .then(({ data }) => {
          setApplicants(data.applications);
          setJobLoading(false);
        })
        .catch((err) => {
          setJobLoading(false);
        });
    } else {
    }
  }, [jobId]);

  const onJobDelete = () => {
    setDeleteLoading(true);
    deleteOrganizationJob(currentJob.slug)
      .then(() => {
        setDeleteLoading(false);
        history.replace(`/profile`);
      })
      .catch(({ data: errors }) => {
        setDeleteLoading(false);
      });
  };

  if (JobLoading)
    return <LoaderWithText padding="150px 0px" text="Please wait..." />;

  return (
    <AppContentContainer classes="container d-flex flex-column flex-md-row">
      {currentJob && (
        <JobDetail>
          <div className="card">
            <div className="card-header">
              <NavOrganization count={appplicants.length} />
            </div>
            <div className="card-body">
              <Switch>
                <Route path={`${path}/description`}>
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="header">Job Title</div>
                        <span>{currentJob.job_title}</span>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="fas fa-edit"
                          role="button"
                          alt="Edit Education"
                          onClick={() => setIsEditing(true)}
                          style={{
                            cursor: "pointer",
                            fontSize: "1.2rem",
                            color: "#000",
                          }}
                        ></i>
                        <div
                          style={{
                            cursor: "pointer",
                            fontSize: "1.2rem",
                            color: "#000",
                          }}
                          className="pl-2"
                          onClick={() => setShow(true)}
                        >
                          <i className="far fa-trash-alt" />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="jobDescription-wrapper col-md-8">
                        <div className="header">
                          Job Description
                          {isAccessible && (
                            <AccessibiltiyIconDisplay
                              accessibilityCognitive={
                                currentJob.accessibility_cognitive
                              }
                              accessibilitySensoryFriendly={
                                currentJob.accessibility_sensory_friendly
                              }
                              accessibilityVisual={
                                currentJob.accessibility_visual
                              }
                              accessibilityHearing={
                                currentJob.accessibility_hearing
                              }
                              accessibilityMobility={
                                currentJob.accessibility_mobility
                              }
                              islist
                            />
                          )}
                        </div>

                        <div className="mt-2">
                          <pre className="preTextwrap card-text">
                            <p>
                              {currentJob.job_description &&
                                currentJob.job_description}
                            </p>
                          </pre>
                        </div>
                        <div className="header">Organization Detail </div>

                        <div className="mt-2">
                          <pre className="preTextwrap card-text">
                            <p>
                              {currentJob.job_organization_detail &&
                                currentJob.job_organization_detail}
                            </p>
                          </pre>
                        </div>
                      </div>
                      <div className="col-md-4">
                        {currentJob.job_organization ? (
                          <>
                            <div className="header">Organization Name</div>
                            <div className="pb-2">
                              {currentJob.job_organization}{" "}
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="header">
                          {currentJob.organization
                            ? "Organization Name"
                            : "Listed By"}
                        </div>
                        <div className="pb-2">
                          {currentJob.organization
                            ? currentJob.organization.organization_title
                            : currentJob.listed_by.organization_title}{" "}
                        </div>
                        {currentJob.job_location && (
                          <div>
                            <div className="header">Location</div>
                            <div className="pb-2">
                              {currentJob.job_location &&
                                currentJob.job_location}
                              {currentJob.job_city &&
                              currentJob.job_city.city_country.country_name
                                ? ", "
                                : ""}
                              {currentJob.job_city &&
                                currentJob.job_city.city_name}
                              {currentJob.job_city &&
                              currentJob.job_city.city_country.country_name
                                ? ", "
                                : ""}
                              {currentJob.job_city &&
                                currentJob.job_city.city_country.country_name}
                            </div>
                          </div>
                        )}
                        <div className="header">Category</div>
                        <div className="pb-2">
                          {currentJob.job_category.job_category &&
                            currentJob.job_category.job_category}
                        </div>
                        <div className="header">Closing Date</div>
                        <div className="pb-2">
                          {" "}
                          {processDate(currentJob.job_close_time, "PP")}
                        </div>
                        {/* <div className="header">Status</div>
                  <span className="pb-2 badge badge-success">{currentJob.job_status}</span> */}
                        {currentJob.job_action_link !== "" ? (
                          currentJob.job_action_link !== "#" ? (
                            <>
                              <div className="header">Job link</div>
                              <div className="">
                                {" "}
                                <a
                                  href={currentJob.job_action_link}
                                  target="_blank"
                                >
                                  Job page link
                                </a>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentJob.job_organization_email !== "" ? (
                          <>
                            <div className="header">Email</div>
                            <div className="">
                              {" "}
                              <a
                                href={`mailto:${currentJob.job_organization_email}`}
                              >
                                Send Email
                              </a>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <Modal
                      show={show}
                      centered
                      size="md"
                      onHide={() => setShow(false)}
                    >
                      <Modal.Header>Confirm Job Delete</Modal.Header>
                      <Modal.Body>
                        <h4 style={{ fontSize: "1rem" }}>
                          Are you sure you want to delete Job?
                        </h4>
                      </Modal.Body>
                      <Modal.Footer>
                        <Container>
                          <Row>
                            <Col sm={12} md={6}>
                              <Button
                                onClick={() => setShow(false)}
                                block
                                size="lg"
                                variant="outline-danger"
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col className="mt-3 mt-md-0" sm={12} md={6}>
                              <Button
                                onClick={onJobDelete}
                                block
                                size="lg"
                                variant="danger"
                              >
                                {deleteLoading ? (
                                  <i className="fas fa-spinner fa-spin" />
                                ) : (
                                  "Delete Job"
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Modal.Footer>
                    </Modal>

                    <AddEditJob
                      show={isEditing}
                      isEditing
                      onUpdated={Job}
                      closeDialog={() => setIsEditing(false)}
                      currentValues={{
                        currentJob,
                        accessibility: {
                          cognitive: currentJob.accessibility_cognitive,
                          sensoryfriendly:
                            currentJob.accessibility_sensory_friendly,
                          mobility: currentJob.accessibility_mobility,
                          visual: currentJob.accessibility_visual,
                          hearing: currentJob.accessibility_hearing,
                          allOpen: false,
                        },
                      }}
                    />
                  </>
                </Route>

                <Route path={`${path}/applicants`}>
                  {/* <div className="heading">Applicants ({appplicants.length})</div> */}
                  <div className="applicant-wrapper">
                    {appplicants.length ? (
                      appplicants.map((item, index) => (
                        <ApplicantItem
                          applicant={item}
                          index={index}
                          length={appplicants.length}
                        />
                      ))
                    ) : (
                      <h3 className="pb-2 text-red pt-4">
                        There is no Applicant for this job now
                      </h3>
                    )}
                  </div>
                </Route>
                <Redirect to={`${path}/description`} />
              </Switch>
            </div>
          </div>
        </JobDetail>
      )}
    </AppContentContainer>
  );
};

const ApplicantItem = ({ applicant, index, length }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className="applicantDetail-wrapper  d-flex flex-column flex-md-row justify-content-between"
      style={{
        borderBottom: `${length - 1 === index ? "none" : "1px solid #EFEFEF"}`,
      }}
    >
      <div className="d-flex flex-column flex-md-row align-items-center">
        <img src={applicant.applicant.person_display_picture} alt="pic" />
        <div className="friendData">
          <h3>{applicant.applicant.full_name}</h3>
          <div className="friendprofession">
            {applicant.applicant.identifies_as}
          </div>
          {applicant.applicant.person_city && (
            <div className="friendprofession">
              {applicant.applicant.person_city &&
                applicant.applicant.person_city.city_name}
              ,{" "}
              {applicant.applicant.person_city &&
                applicant.applicant.person_city.city_country.country_name}
            </div>
          )}
          <div
            className="user-level"
            style={{ justifyContent: "flex-start", paddingTop: "10px" }}
          ></div>
        </div>
      </div>

      <div>
        <div>
          Applied on : <span>{processDate(applicant.created_at, "PP")}</span>
        </div>
        <div>
          Status:{" "}
          <span
            className={`badge badge-${
              applicant.applicant_status === "A"
                ? "secondary"
                : applicant.applicant_status === "Rejected"
                ? "danger"
                : applicant.applicant_status === "Shortlisted"
                ? "success"
                : "primary"
            }`}
          >
            {applicant.applicant_status === "A"
              ? "Applied"
              : applicant.applicant_status === "S"
              ? "Shortlist"
              : applicant.applicant_status === "I"
              ? "Interviewing"
              : applicant.applicant_status === "O"
              ? "Offered"
              : applicant.applicant_status === "R"
              ? "Reject"
              : "Archived"}
          </span>
        </div>
      </div>
      <div>
        <Button id="applyJob" variant="danger" onClick={() => setShow(true)}>
          View Application
        </Button>
      </div>
      <ViewJobApplicant
        show={show}
        closeDialog={() => setShow(false)}
        profileId={applicant.applicant.id}
        status={applicant.applicant_status}
        jobId={applicant.job}
        applicationId={applicant.id}
      />
    </div>
  );
};

export default OrganizationJobDetail;

const JobDetail = styled.div`
font-family: 'Inter', sans-serif;
  width:100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
  min-height: 80vh;

  .header{
    font - size: 18px;
    font-weight: 700;
  }

  .header > .row{
    padding: 12px 16px;
  }
  .heading{
    font-size: 20px;
    font-weight: 900;
    padding-bottom: 15px;
  }
  .applicantDetail-wrapper{
    padding: 20px 0px;
    align-items: center;
  }
  .applicantDetail-wrapper > div > img{
    width: 100px;
    height: 100px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
    border-radius: 50px;
  }
  .friendData > h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #5F5F5F;
    margin: 0px;
}
 .level-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 25px;
  background: #263238;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
  border-radius: 28px;
}
  .outerRing {
  display: flex;
  padding: 2px;
  border-radius: 50px;
  border: 1px solid white;
  }
  .level {
    padding-left: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}
.friendData{
  padding-left: 23px;
}
.card-header{
  background-color: #fff;
    padding: 0px;
}
`;

const NavOrganization = ({ count }) => {
  const { pathname: path } = useLocation();
  const { url } = useRouteMatch();

  return (
    <StyledContainer>
      <div
        className={`nav-profile px-0 py-2 ${
          path === `${url}/description` ? "active" : ""
        }`}
      >
        <Link to={`${url}/description`} className="nav-link">
          Job Description
        </Link>
      </div>

      <div
        className={`nav-profile px-0 py-2 ${
          path === `${url}/applicants` ? "active" : ""
        }`}
      >
        <Link to={`${url}/applicants`} className="nav-link">
          Applicants ({count})
        </Link>
      </div>

      {/* <div className={`nav-profile px-0 pt-2 ${
                    path === `${url}/following` ? "active" : ""}`}>
                 <Link
                    to={`${url}/following`}
                    className="nav-link"
                    href="followers.html"
                  >
                    Following
                  </Link>
              </div> */}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  .nav-profile {
    //border-top: 1px solid #ABABAB;
    background-color: #ffffff;
  }

  .nav-profile a {
    color: #5f5f5f;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: #ababab;
      text-decoration: none;
    }
  }

  .nav-link.active {
    color: #000000;
  }
`;
