import React from "react"; 
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DatePicker from "../../../../../components/FormikDatepicker";


const JobDescription = ({ sectionValid, handleChange, handleBlur, values, errors, touched }) => {

return (
        <> 
            <Form.Row>
                <Form.Group as={Col} md={6} col={12}>
                    <Form.Label>Job Title<span style={{color: "#dc3545"}}> *</span></Form.Label>
                    <Form.Control
                        placeholder="Job Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="job_title"
                        isInvalid={(touched.job_title && errors.job_title) || (!sectionValid && values.job_title === "")}
                        value={values.job_title}
                        type="text"
                        as="input"
                        autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.job_title}
                    </Form.Control.Feedback>   
                </Form.Group>

                <Form.Group as={Col} col={12} md={6}>
                    <Form.Label>Closing Date<span style={{color: "#dc3545"}}> *</span></Form.Label>
                    <DatePicker
                        nativeInputAriaLabel="select closing date"
                        placeholderText="Closing Date"
                        minDate={new Date()}
                        name="job_close_time"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.job_close_time && errors.job_close_time}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.job_close_time}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md={6} col={12}>
                    <Form.Label>Job Type<span style={{color: "#dc3545"}}> *</span></Form.Label>
                    <Form.Control
                        value={values.job_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="job_type"
                        as="select"
                        className="ic-form-select"
                        aria-label="Job Type"
                        isInvalid={(touched.job_type && errors.job_type) || (!sectionValid && values.job_type === "")}
                    >
                        <option value="" disabled>Select Job Type</option>
                        <option value="F">Full-time</option>
                        <option value="P">Part-time</option>
                        <option value="C">Contract</option>
                        <option value="T">Temporary</option>
                        <option value="O">Other</option>
                        <option value="V">Volunteer</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errors.job_type}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={6} col={12}>
                    <Form.Label>Workspace<span style={{ color: "#dc3545" }}> *</span></Form.Label>
                    <Form.Control
                        value={values.job_location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="job_location"
                        as="select"
                        className="ic-form-select"
                        aria-label="Workspace"
                        isInvalid={(touched.job_location && errors.job_location) || (!sectionValid && values.job_location === "")}
                    >
                        <option value="" disabled>Select Workspace</option>
                        <option value="R">Remote</option>
                        <option value="H">Hybrid (Remote & Office)</option>
                        <option value="C">Co-working Space</option>
                        <option value="O">Office</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errors.job_location}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} col={12}>
                    <Form.Label>Job Description<span style={{color: "#dc3545"}}> *</span></Form.Label>
                    <Form.Control
                        placeholder="Please Share Job Description"
                        name="job_description"
                        className=" "
                        value={values.job_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        as="textarea"
                        rows={5}
                        isInvalid={(touched.job_description && errors.job_description) || (!sectionValid && values.job_description === "")}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.job_description}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>  
        </>
    )
};

export default JobDescription;


