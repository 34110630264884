import React, { useState, useEffect } from "react";
import { Page, Text, View, Document, StyleSheet, Font, Link, Image } from '@react-pdf/renderer';
import { format } from "date-fns";
import Background2 from '../../../assets/images/cert-background.png';


const CertificatePdf = ({ title, userName, partnerLogo, partnerName }) => {
    return (
        <Document title={title}>
            <Page size="A4" style={styles.page} orientation="landscape">

                <View>
                    <Image style={styles.backgroundImage} src={Background2}/>
                </View>

                <View style={styles.section}>

                    {/* <View style={styles.header}>
                        <Image style={styles.partnerLogo} src={partnerLogo}/>
                        <Text style={styles.partnerName}>{partnerName}</Text>
                    </View> */}
                    
                    <Text style={styles.date}>{format(new Date(), "MMM d, yyyy")}</Text>
                    <Text style={styles.heading}>{userName}</Text>
                    <Text style={styles.subheading}>has successfully completed</Text>
                    <Text style={styles.title}>{title}</Text>
                    <Text style={styles.subheading}>This course is built by ImInclusive in partnership with Visa.</Text>
                    
                    {/* <View style={styles.footer}>
                        <Image style={styles.companyLogo} src={CompanyLogo}/>
                    </View> */}

                </View>

                {/* <View style={[styles.section, { paddingLeft: 0, width: '30%', marginLeft: 20 }]}>

                    <Text style={[styles.sectionHeading, { paddingTop: 10 }]}>COURSE CERTIFICATE</Text>
                    <View style={styles.divider} />
                    <Text style={styles.sectionText}>{}</Text>

                </View> */}

            </Page>
        </Document>
    )
};

export default CertificatePdf;

const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        // backgroundColor: '#fff',
        // paddingHorizontal: 60,
        // paddingVertical: 50,
    },
    backgroundImage: {
        width: '100%',
        height: '100%',
    },
    section: {
        position: "absolute",
        top: '200px',
        left: '0px',
        right: '0px',
        marginHorizontal: 'auto',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 60,
    },
    partnerLogo: {
        width: 200,
        // height: 200,
        objectFit: 'contain',
    },
    companyLogo: {
        width: 200,
        // height: 200,
        objectFit: 'contain',
        marginTop: 60,
    },
    partnerName: {
        fontSize: 75,
        fontWeight: 'bold',
        marginLeft: 5,
    },
    date: {
        fontSize: 14,
        color: '#5f5f5f',
        marginBottom: 30,
    },
    heading: {
        fontSize: 32,
        color: '#000',
        fontWeight: 'bold',
        marginBottom: 20,
    },
    subheading: {
        fontSize: 14,
        color: '#5f5f5f',
        marginBottom: 20,
    },
    title: {
        fontSize: 20,
        color: '#000',
        fontWeight: 'bold',
        marginBottom: 20,
    },
});