import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "../../../common/Form";
import DatePicker from "../../../components/FormikDatepicker";

import { addAchievement, updateAchievement } from "../../../api";

const AddEditAchievement = ({
  activeProfile,
  show,
  closeDialog,
  isEditing,
  currentValues,
  achievementId,
  onUpdated,
}) => {
  const achivementSchema = yup.object().shape({
    achievement_title: yup
      .string()
      .required("Please provide the achievement name."),
    achievement_summary: yup.string().required("Please provide the summary."),
    achievement_date: yup
      .date()
      .required("Please provide the achievement Date."),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (isEditing) {
      updateAchievement(activeProfile.id, achievementId, {
        ...values,
        person: activeProfile.id,
      })
        .then(() => {
          setSubmitting(false);
          closeDialog();

          onUpdated();
        })
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      addAchievement(activeProfile.id, { ...values, person: activeProfile.id })
        .then(() => {
          setSubmitting(false);
          closeDialog();

          onUpdated();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  let initialValues = {
    achievement_title: "",
    achievement_summary: "",
    achievement_date: new Date(),
  };

  if (isEditing) {
    initialValues = {
      achievement_title: currentValues.achievement_title,
      achievement_summary: currentValues.achievement_summary,
      achievement_date: currentValues.achievement_date,
    };
  }

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit" : "Add"} Achievement</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={achivementSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Award Name</Form.Label>
                <Form.Control
                  placeholder="Award Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="achievement_title"
                  isInvalid={
                    touched.achievement_title && errors.achievement_title
                  }
                  value={values.achievement_title}
                  type="text"
                  as="input"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.achievement_title}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="awardingBody">Summary</Form.Label>
                <Form.Control
                  placeholder="Summary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="achievement_summary"
                  isInvalid={
                    touched.achievement_summary && errors.achievement_summary
                  }
                  value={values.achievement_summary}
                  as="textarea"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.achievement_summary}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <DatePicker
                  nativeInputAriaLabel="select achievement date"
                  maxDate={new Date(values.achievement_date)}
                  placeholderText="When did you received the award?"
                  name="achievement_date"
                />
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button
                      onClick={closeDialog}
                      block
                      className="modal-cancel-button"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="mt-3 mt-md-0" sm={12} md={6}>
                    <Button
                      loading={isSubmitting ? 1 : 0}
                      block
                      className="modal-submit-button"
                      type="submit"
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {}
)(AddEditAchievement);
