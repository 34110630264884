import React, { useState, useMemo, useEffect } from "react";
import SplitButton from "react-bootstrap/SplitButton";
import Badge from "react-bootstrap/Badge";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { Modal } from "react-bootstrap";
import { useCustomersUIContext } from "./CustomersUIContext";
import { rejectApplicant, shortlistApplicant, getJobApplication } from "../../../../api";

const JobApplicants = ({
  applicants,
  onStatusChange,
  path,
  rejected,
  shortlisted,
  jobId,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const customersUIContext = useCustomersUIContext();
  const [selectedStatus, setSelectedStatus] = useState("");
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);
  const [status, setStatus] = useState("All");

  useEffect(() => {
    setSelectedStatus("");
  },[show]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const updateStatus = () => {
    setLoading(true);
    customersUIProps.ids.map((id) => handleChangeStatus(id));
    setLoading(false);
    setShow(false);
  };
  const handleChangeStatus = (applicantId) => {
    getJobApplication(jobId, applicantId)
      .then(({ data }) => {
        if (selectedStatus === "R" && data.applicant_status !== "R") {
          rejectApplicant(jobId, applicantId, {})
            .then((resp) => {
              onStatusChange(resp.data.applicant, "R");
            })
            .catch((error) => {});
        } else if (selectedStatus === "S" && data.applicant_status !== "S") {
          shortlistApplicant(jobId, applicantId, {})
            .then((resp) => {
              onStatusChange(resp.data.applicant, "S");
            })
            .catch((error) => {});
        }
      })
      .catch((error) => { });
  };

  const customersUIEvents = {};
  //const { path } = useRouteMatch();
  return (
    <div className="card">
      <div className="card-body p-0">
        <CustomersFilter handleStatusChange={handleStatusChange} applicants={applicants}/>
        {customersUIProps.ids.length > 0 && (
          <CustomersGrouping setShow={setShow} />
        )}
        {applicants && (
          <CustomersTable
            applicants={
              status === "R"
                ? rejected
                : status === "S"
                ? shortlisted
                : applicants
            }
            onStatusChange={onStatusChange}
          />
        )}
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Update Applicant Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <div className="form-group">
            <select
              className="form-control"
              name="status"
              placeholder="Filter by Status"
              // TODO: Change this code
              onChange={(e) => setSelectedStatus(e.target.value)}
              value={selectedStatus}
            >
              {/* <option value="All">All</option> */}
              {/* <option value="A">Applied</option> */}
              <option value="" disabled>Select Status</option>
              <option value="S">Shortlist</option>
              <option value="R">Reject</option>
            </select>
          </div>

          {/*begin::Loading*/}
          {isLoading && (
            <div className="overlay-layer">
              <div className="spinner spinner-lg spinner-primary" />
            </div>
          )}
        </Modal.Body>
        {/*end::Loading*/}

        <Modal.Footer className="form">
          <div className="form-group">
            <button
              type="button"
              onClick={() => setShow(false)}
              className="btn btn-light btn-elevate mr-3"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={updateStatus}
              disabled={selectedStatus===""}
              className="btn btn-primary btn-elevate"
              style={{ backgroundColor: '#3F0E40', borderColor: '#3F0E40', cursor: selectedStatus === "" ? "auto" : "pointer" }}
            >
              Update Status
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JobApplicants;

const ApplicantItem = ({ applicant, index, length, onStatusChange }) => {
  const [isSubmittingS, setIsSubmittingS] = useState(false);
  const [isSubmittingR, setIsSubmittingR] = useState(false);

  const handleChange = (e) => {
    if (e === "R") {
      setIsSubmittingR(true);
      rejectApplicant(applicant.job, applicant.id, {})
        .then((resp) => {
          onStatusChange(resp.data.applicant, "R");
          setIsSubmittingR(false);
          setShow(false);
        })
        .catch((error) => {
          setIsSubmittingR(false);

          closeDialog();
        });
    } else if (e === "S") {
      setIsSubmittingS(true);

      shortlistApplicant(applicant.job, applicant.id, {})
        .then((resp) => {
          onStatusChange(resp.data.applicant, "S");
          setShow(false);
          setIsSubmittingS(false);
        })
        .catch((error) => {
          setShow(false);
          setIsSubmittingS(false);
        });
    }
  };

  const [show, setShow] = useState(false);
  return (
    <div
      className="applicantDetail-wrapper  d-flex flex-column flex-md-row justify-content-between"
      style={{
        borderBottom: `${length - 1 === index ? "none" : "1px solid #EFEFEF"}`,
      }}
    >
      <div className="d-flex flex-column flex-md-row align-items-center">
        <img src={applicant.applicant.person_display_picture} alt="pic" />
        <div className="friendData">
          <h3>{applicant.applicant.full_name}</h3>
          <div className="friendprofession">
            {applicant.applicant.identifies_as}
          </div>
          {applicant.applicant.person_city && (
            <div className="friendprofession">
              {applicant.applicant.person_city &&
                applicant.applicant.person_city.city_name}
              ,{" "}
              {applicant.applicant.person_city &&
                applicant.applicant.person_city.city_country.country_name}
            </div>
          )}
          <div className="friendprofession">
            Applied {getTimeAgo(applicant.created_at)}{" "}
          </div>
          <div className="">
            <Badge variant="secondary">
              {applicant.applicant_status === "A"
                ? "Applied"
                : applicant.applicant_status === "S"
                ? "Shortlist"
                : applicant.applicant_status === "I"
                ? "Interviewing"
                : applicant.applicant_status === "O"
                ? "Offered"
                : applicant.applicant_status === "R"
                ? "Reject"
                : "Archived"}
            </Badge>
          </div>
        </div>
      </div>

      {/* <div>
          <div>Applied on : <span>{processDate(applicant.created_at,"PP")}</span></div>
        <div>Status: <span className={`badge badge-${applicant.applicant_status === "A" ? "secondary" : applicant.applicant_status === "Rejected" ? "danger" : applicant.applicant_status === "Shortlisted" ? "success" : "primary"}`}>{applicant.applicant_status === "A" ? "Applied" : applicant.applicant_status === "S" ? "Shortlist" : applicant.applicant_status === "I" ? "Interviewing" : applicant.applicant_status === "O" ? "Offered":applicant.applicant_status === "R" ? "Reject":"Archived"}</span></div>
      </div>
      */}
      <div>
        <SplitButton
          alignRight
          key="danger"
          id="dropdown-split-variants-danger"
          variant="danger"
          title="View Profile"
          onClick={() => setShow(true)}
        >
          <Dropdown.Item
            onClick={() => handleChange("S")}
            loading={isSubmittingS ? 1 : 0}
            className={`${
              applicant.applicant_status === "S" ? "disabled" : ""
            }`}
          >
            {isSubmittingS ? "Please wait..." : "Shortlist"}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => handleChange("R")}
            loading={isSubmittingR ? 1 : 0}
            className={`${
              applicant.applicant_status === "R" ? "disabled" : ""
            }`}
          >
            {isSubmittingR ? "Please wait..." : "Reject"}
          </Dropdown.Item>
        </SplitButton>
        {/* <Button
          id="applyJob"
          variant="danger"
          onClick={() => setShow(true)}
        >
          View Application
      </Button> */}
      </div>

      {/* <ViewJobApplicant
        show={show}
     //   onUpdate={onStatusChange}
        closeDialog={() => setShow(false)}
        profileId={applicant.applicant.id}
        status={applicant.applicant_status}
       // jobId={applicant.job}
       // applicationId={applicant.id}
        isSubmittingS={isSubmittingS}
        isSubmittingR={isSubmittingR}
        handleChange={handleChange}
      /> */}
    </div>
  );
};
