import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { addToast } from "../../../redux/toast";
import AddEditStory from "./AddEditStory";
import ReportContent from "../../../components/ReportContentModal";
import { getFollowPerson, FollowPerson, unFollowPerson, getBlockPersonById, BlockUser, unBlockUser } from "../../../api";

const OurStory = ({
  profile,
  activeProfileId,
  onProfileUpdated,
  isOwner,
  addToast,
  activeProfile,
  TogglePublicView,
  publicView,
  onEditProfile,
}) => {
  const [editStory, setEditStory] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const [blockedProfile, setBlockedProfile] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    let unmounted = false;
    if (!isOwner) {
      getFollowPerson(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          if (!unmounted) {
            setFollowing(res.data);
          }
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
        });
      getBlockPersonById(activeProfileId, profile.owner_id)
        .then((res) => {
          if (!unmounted) {
            setBlockedProfile(res.data);
          }
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
        });
    }
    return () => { unmounted = true }; 
  }, []);

  const handleFollow = () => {
    if (activeProfile.owner_id === profile.owner_id) {
      addToast({
        type: "error",
        title: "Error",
        text: "You cannot follow yourself.",
      });
      return;
    }
    setFollowLoading(true);
    if (following) {
      unFollowPerson(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          if (res.status === 204) setFollowing(false);
          addToast({
            title: "Success",
            text: `Unfollowed successfully.`,
            type: "success",
          });
          setFollowLoading(false);
        })
        .catch((res) => {
          setFollowLoading(false);
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    } else {
      FollowPerson(activeProfile.owner_id, profile.owner_id, {})
        .then((res) => {
          addToast({
            title: "Success",
            text: `You are now following ${profile.organization_title}.`,
            type: "success",
          });
          setFollowing(true);
          setFollowLoading(false);
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
          setFollowLoading(false);
        });
    }
  };

  const handleBlockProfile = () => {
    if (activeProfileId === profile.owner_id) {
      addToast({
        type: "error",
        title: "Error",
        text: "You cannot block yourself.",
      });
      return;
    }
    if (blockedProfile) {
      unBlockUser(activeProfileId, profile.owner_id)
        .then((res) => {
          addToast({
            title: "Success",
            text: `Unblocked successfully.`,
            type: "success",
          });
          setBlockedProfile(false);
        })
        .catch((res) => {
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    } else {
      BlockUser(activeProfileId, profile.owner_id, {})
        .then((res) => {
          addToast({
            title: "Success",
            text: `Blocked successfully.`,
            type: "success",
          });
          setBlockedProfile(true);
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
        });
    }
  };

  const handleReportProfile = () => {
    if (activeProfileId === profile.owner_id) {
      addToast({
        type: "error",
        title: "Error",
        text: "You cannot report yourself.",
      });
      return;
    }
    setShowReportModal(true);
  };

  return (
    <>
      {!isOwner ? (
        <Row>
          <Col md={12} className="d-flex align-items-center button-container" style={{ justifyContent: "flex-end" }}>
            <Button
              className="profile-follow-btn purple-btn"
              loading={followLoading ? 1 : 0}
              onClick={handleFollow}
              style={{ padding: '0.613rem 0.8rem' }}
            >
              {followLoading ? (
                <i className="fas fa-spinner fa-spin mr-2" />
              ) : (
                <i className="material-icons mr-2">{following ? 'person_remove': 'person_add_alt_1'}</i>
              )}
              {following ? "Unfollow" : "Follow"}
            </Button>
            <Dropdown
              className="NavDropdown profile-more-dropdown"
              id="collasible-nav-dropdown"
            >
              <Dropdown.Toggle
                className="NavDropdown profile-follow-btn ml-2"
                id="collasible-nav-dropdown"
                style={{ backgroundColor: '#fff', color: '#5F5F5F', border: '2px solid #5F5F5F', padding: '0.675rem 0.8rem' }}
              >
                  <i className="fas fa-plus mr-2" />More
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                <Dropdown.Item aria-label="Block Profile" className="d-flex align-items-center" onClick={handleBlockProfile}>
                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>block</i>
                  {blockedProfile ? `Unblock ${profile.organization_title}` : `Block ${profile.organization_title}`}
                </Dropdown.Item>
                <Dropdown.Item aria-label="Report Profile" className="d-flex align-items-center" onClick={handleReportProfile}>
                  <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>error_outline</i>
                  {`Report ${profile.organization_title}`}
                </Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
          </Col>
        </Row>
      ) : !publicView ? (
        <Row>
          <Col
            md={12}
            className="d-flex align-items-center button-container"
            style={{ justifyContent: "flex-end" }}
          >
            <Button className="profile-follow-btn view-as purple-btn" onClick={TogglePublicView}>
              <i className="fas fa-eye mr-2" />Public View
            </Button>
              <Button className="profile-follow-btn ml-2" onClick={onEditProfile} style={{ backgroundColor: '#fff', color: '#5F5F5F', border: '2px solid #5F5F5F', padding: '0.475rem 0.77rem' }}>
                <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                Edit Profile
            </Button>
              {/* <Dropdown
                className="NavDropdown profile-more-dropdown"
                id="collasible-nav-dropdown"
              >
                <Dropdown.Toggle
                  className="NavDropdown profile-follow-btn ml-2"
                  id="collasible-nav-dropdown"
                  style={{ backgroundColor: '#fff', color: '#5F5F5F', border: '2px solid #5F5F5F', padding: '0.675rem 0.8rem' }}
                >
                  <i className="fas fa-plus mr-2" />More
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu m-0 p-0" style={{ borderRadius: '0 0.7rem 0.7rem 0.7rem', overflow: 'hidden' }}>
                  <Dropdown.Item aria-label="Block Profile" className="d-flex align-items-center" onClick={onEditProfile}>
                    <i className="material-icons mr-2" style={{ color: '#5F5F5F' }}>edit</i>
                    Edit Profile
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
          </Col>
        </Row>
      ) : (
        ""
      )}
      <ReportContent modalVisible={showReportModal} setModalVisible={setShowReportModal} contentType="O" contentId={profile.id} />
      <section className="organizationCard story mt-2 d-flex flex-column">
        {/* <div className="cardHeading d-flex justify-content-between">Our Story{isOwner ? <img src={edit} role="button" onClick={() => setEditStory(true)} style={{ cursor: "pointer" }} /> : ""}</div> */}
        <div
          className="cardHeading d-flex pb-3"
          style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}
        >
          {isOwner ? "Our " : `${profile.organization_title}'s `} Story
          {isOwner && (
            <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Organization Story Tooltip" aria-pressed={showTooltip}>
              <i className="material-icons help-icon">help_outline</i>
            </Button>
          )}
        </div>
        <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
          {(props) => (
            <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
              Tell us the story of your company: what brings you here? When was the company founded? Share your mission, vision and values. Tell us why you prioritize inclusion of persons with disabilities and your motivation for joining the platform. All users will be able to view your story and learn more about your company.
            </Tooltip>
          )}
        </Overlay>
        {/* <img src={youtube} className="recent-activity-img" alt="Recent Activity" /> */}
        {/* <video width="100%" controls>
      <source src={data.videolink} type="video/mp4" />
    </video> */}
        <div className="recent-activity-text pt-3">
          <span style={{ fontSize: "16px", lineHeight: "23px" }}>
            {profile.organization_overview}
          </span>
        </div>
        <AddEditStory
          show={editStory}
          isEditing
          closeDialog={() => setEditStory(false)}
          currentValues={{
            org_story: profile.organization_overview,
          }}
          onUpdated={onProfileUpdated}
        />
      </section>
    </>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {
    addToast,
  }
)(OurStory);
