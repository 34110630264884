import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import styled, { css } from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import CropImage from "./CropImage";

//Upload Image Person/Org Profile
const ImageUploader = ({
  show,
  isUploading,
  // margin,
  // disabled,
  // photoChanged,
  // loading,
  // isIcon,
  // children,
  onClose,
  onPhotoChanged,
}) => {
  const [image, setImage] = useState(null);
  const [src, setSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editor, SetEditor] = useState(null);
  const [scaleValue, SetScaleValue] = useState(1);
  const [rotate, setRotate] = useState(0);

  const setEditorRef = (e) => SetEditor(e);

  const onScaleChange = (scaleChangeEvent) => {
    const newScaleValue = parseFloat(scaleChangeEvent.target.value);
    SetScaleValue(newScaleValue);
  };

  const onCrop = () => {
    const i = image;
    if (editor !== null) {
      const url = editor.getImageScaledToCanvas().toDataURL();
      setImage(dataURLtoFile(url, i.name));
    }
  };

  const rotateleft = () => {
    let newRotation = rotate - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotate(newRotation);
  };

  const rotateRight = () => {
    let newRotation = rotate + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotate(newRotation);
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImage(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        setSrc(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const onPhotoSelected = () => {
    if (image) {
      // onPhotoChanged(image);
      const i = image;
      if (editor !== null) {
        //const newi = editor.getCroppingRect();
        const url = editor.getImage().toDataURL();
        const newImage = dataURLtoFile(url, i.name);
        onPhotoChanged(newImage);
      }
    }
  };

  return show ? (
    <Modal show={show} centered size="md" onHide={onClose}>
      <Modal.Header style={{ fontSize: '1.2rem', fontWeight: '500' }}>Upload Image</Modal.Header>
      <Modal.Body>
        {image ? (
          <React.Fragment>
            {/*<RenderedImg src={src} />*/}

            <CropImage
              imageSrc={imageFile}
              setEditorRef={setEditorRef}
              rotation={rotate}
              leftRotate={rotateleft}
              rightRotate={rotateRight}
              scaleValue={scaleValue}
              onScaleChange={onScaleChange}
            />
          </React.Fragment>
        ) : null}

        <UploadContainer>
          <input
            accept="image/png, image/jpeg"
            aria-label="upload photo"
            alt="upload photo icon"
            onChange={handleChange}
            type="file"
            name="myfile"
            className="image-upload-input"
          />
          <Button className="image-upload-button">
            <i className="material-icons" style={{ color: '#8c8c8c', fontSize: '50px' }}>photo_camera</i>
          </Button>
        </UploadContainer>
      </Modal.Body>
      <Modal.Footer>
        <StyledFooter>
          <Row className="d-flex align-items-center">
            <Col sm={6} md={6} className="d-flex align-items-center justify-content-center">
              <Button
                disabled={isUploading}
                onClick={onClose}
                block
                className="cancel-button"
              >
                Cancel
              </Button>
            </Col>
            <Col sm={6} md={6} className="d-flex align-items-center justify-content-center">
              <Button
                disabled={isUploading || !image}
                onClick={onPhotoSelected}
                block
                className="upload-button"
              >
                {isUploading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                    "Upload Photo"
                  )}
              </Button>
            </Col>
          </Row>
        </StyledFooter>
      </Modal.Footer>
    </Modal>
  ) : null;
};

const UploadContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  .image-upload-input {
    position: absolute;
    top: 32px;
    right: 175px;
    height: 118px;
    width: 117px;
    opacity: 0;
    cursor: pointer;
  }

  .image-upload-button {
    background-color: #fff;
    border: 2px solid #ABABAB;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 0.6rem;

  }
`;

const StyledFooter = styled.div`
  width: 100%;

.cancel-button {
  background-color: #fff;
  color: #515151;
  border: 2px solid #5F5F5F;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.925rem 0.8rem;
  margin-bottom: 2px;
  border-radius: 2rem;
  font-size: 18px;
  line-height: 10px;
  font-weight: 500;
  &:active {
    background-color: #fff !important;
    color: #515151 !important;
    border-color: #5F5F5F !important;
  }
}

.upload-button {
  background-color: #3F0E40;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.05rem 0.8rem;
  margin-bottom: 2px;
  border-radius: 2rem;
  font-size: 18px;
  line-height: 10px;
  border: 0;
  font-weight: 500;
  &:active {
    background-color: #3F0E40 !important;
  }
}

.btn.disabled, .btn:disabled {
  opacity: .65;
  cursor: default;
}
`;

export default ImageUploader;
