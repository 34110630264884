import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ListGroup from 'react-bootstrap/ListGroup';
import AppContentContainer from "../../../common/AppContentContainer";
import { processDate, processDateArabic } from "../../../common/utils";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const JobDetail = ({ setShow, setIsEditing, currentJob, isAccessible }) => {
  const direction = i18n.dir();
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const jobDetailProfile = path === "/profile/job/:jobslug/:jobId/description";
  const multipleLocation = currentJob.job_location_cities.length > 1 ? true : false;
  
  return (
    <AppContentContainer classes="container d-flex flex-column flex-md-row p-0">
      {currentJob && (
        <StyledJobDetail>
          <div className="job-header-card d-flex flex-column">
            {/* <Row className="d-flex job-header align-items-center">
              <Col xs={10} className="job-post-date">
                {t("Posted on")}
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {direction === "rtl" ? 
                  (processDateArabic(currentJob.created_at, "PP")) 
                  : 
                  (processDate(currentJob.created_at, "PP"))
                  }
                </span>
              </Col>
              <Col xs={2} className="job-badge-icon">
                <i className="fas fa-edit mr-2" role="button" alt="Edit Job" onClick={() => setIsEditing(true)} style={{ cursor: "pointer", color: "#fff" }}/>
                <i className="far fa-trash-alt" role="button" alt="Delete Job" onClick={() => setShow(true)} style={{ cursor: "pointer", color: "#fff" }}/>
              </Col>
            </Row> */}

            <Row className="job-header-details align-items-center show-regular" style={{ display: 'flex' }}>
              <Col xs={2} className={`job-logo d-flex justify-content-center`} style={{ flex: '0 0 8.7%' }}>
                <div className="job-logo-container">
                  <img
                    src={
                      currentJob.job_organization_logo &&
                      currentJob.job_organization_logo !==
                        "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
                        ? currentJob.job_organization_logo
                        : currentJob.organization
                        ? currentJob.organization.organization_logo
                        : currentJob.listed_by.organization_logo
                    }
                    className="job-img"
                    alt="job-img"
                  />
                </div>
              </Col>
              <Col xs={8} className={`job-headline ${direction === "rtl" ? "pr-0" : "pl-0"}`} style={{ flex: '0 0 73.9%', maxWidth: '73.9%' }}>
                <span className="job-title pb-2">{currentJob.job_title}</span>
                <span className="job-organization">
                  {currentJob.job_organization}
                </span>
              </Col>
              <Col xs={2} className="job-badge-icon pr-0">
                <i className="material-icons mr-2 p-1" alt="Edit Icon" onClick={() => setIsEditing(true)} style={{ cursor: "pointer", color: "#000", fontSize: '28px' }}>edit</i>
                <i className="material-icons p-1" alt="Delete Icon" onClick={() => setShow(true)} style={{ cursor: "pointer", color: "#000", fontSize: '28px' }}>delete</i>
              </Col>
            </Row>
            <Row className="job-header-details align-items-center show-mobile">
              <Col xs={3} className="job-logo d-flex justify-content-center px-0">
                <div className="job-logo-container">
                  <img
                    src={
                      currentJob.job_organization_logo &&
                        currentJob.job_organization_logo !==
                        "https://inclusive-storage.s3.amazonaws.com/media/public/org/logo_job/None/no-img.jpg"
                        ? currentJob.job_organization_logo
                        : currentJob.organization
                          ? currentJob.organization.organization_logo
                          : currentJob.listed_by.organization_logo
                    }
                    className="job-img"
                    alt="job-img"
                  />
                </div>
              </Col>
              <Col xs={7} className={`job-headline ${direction === "rtl" ? "pr-0" : "pl-0"}`}>
                <span className="job-title">{currentJob.job_title}</span>
                <span className="job-organization">
                  {currentJob.job_organization}
                </span>
              </Col>
              <Col xs={2} className="job-badge-icon pr-0">
                <i className="material-icons mr-2 p-1" alt="Edit Icon" onClick={() => setIsEditing(true)} style={{ cursor: "pointer", color: "#000", fontSize: '28px' }}>edit</i>
                <i className="material-icons p-1" alt="Delete Icon" onClick={() => setShow(true)} style={{ cursor: "pointer", color: "#000", fontSize: '28px' }}>delete</i>
              </Col>
            </Row>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            <div className="job-detail-footer d-flex flex-column">
              <Row className="d-flex job-footer align-items-center">
                <Col xs={12} className="job-close-date">
                  {t("Closes on")}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {direction === "rtl" ? 
                    (processDateArabic(currentJob.job_close_time, "PP")) 
                    : 
                    (processDate(currentJob.job_close_time, "PP"))
                    }
                  </span>
                </Col>
              </Row>
            </div>
          </div>

          <div className="job-detail-card d-flex flex-column">
            <div className="job-detail-header d-flex flex-column">
              <Row className="d-flex align-items-start">
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Industry")}</span>
                  <div>
                    {currentJob.organization.organization_industry.industry_title && t(currentJob.organization.organization_industry.industry_title)}
                  </div>
                </Col>
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Employment Type")}</span>
                  <div>
                    {currentJob.job_type === "F" ? t("Full-time") : ""}
                    {currentJob.job_type === "P" ? t("Part-time") : ""}
                    {currentJob.job_type === "C" ? t("Contract") : ""}
                    {currentJob.job_type === "T" ? t("Temporary") : ""}
                    {currentJob.job_type === "O" ? t("Other") : ""}
                    {currentJob.job_type === "V" ? t("Volunteer") : ""}
                  </div>
                </Col>
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Experience Level")}</span>
                  <div className="">
                    {currentJob.job_experience_level === "N" ? t("Not Applicable") : ""}
                    {currentJob.job_experience_level === "I" ? t("Internship") : ""}
                    {currentJob.job_experience_level === "E" ? t("Entry Level") : ""}
                    {currentJob.job_experience_level === "A" ? t("Associate") : ""}
                    {currentJob.job_experience_level === "M" ? t("Mid-Senior Level") : ""}
                    {currentJob.job_experience_level === "MA" ? t("Managerial") : ""}
                    {currentJob.job_experience_level === "D" ? t("Director") : ""}
                    {currentJob.job_experience_level === "X" ? t("Executive") : ""}
                  </div>
                </Col>
                <Col md={3} className="job-category">
                  <span className="job-heading">{t("Workspace")}</span>
                  {currentJob.job_location && (
                    <div className="">
                      {currentJob.job_location === "R" ? t("Remote") : ""}
                      {currentJob.job_location === "H" ? t("Hybrid (Remote & Office)") : ""}
                      {currentJob.job_location === "C" ? t("Co-working Space") : ""}
                      {currentJob.job_location === "O" ? t("Office") : ""}
                    </div>
                  )}
                </Col>
              </Row>
              {currentJob.job_pay_interval && currentJob.job_pay_range ? (
                <Row className="d-flex align-items-start justify-content-start">
                    <Col md={3} className="job-category">
                    <span className="job-heading">{t("Pay Interval")}</span>
                    <div>
                        {currentJob.job_pay_interval === "H" ? t("Hourly") : ""}
                        {currentJob.job_pay_interval === "D" ? t("Daily") : ""}
                        {currentJob.job_pay_interval === "B" ? t("Bi-Weekly") : ""}
                        {currentJob.job_pay_interval === "S" ? t("Semi-Monthly") : ""}
                        {currentJob.job_pay_interval === "M" ? t("Per Month") : ""}
                        {currentJob.job_pay_interval === "Y" ? t("Per Year") : ""}
                        {currentJob.job_pay_interval === "NA" ? t("NA") : ""}
                    </div>
                    </Col>
                    <Col md={3} className="job-category">
                    <span className="job-heading">{t("Pay Range")}</span>
                    <div>
                        {currentJob.job_pay_range && t(currentJob.job_pay_range)}
                    </div>
                    </Col>
                </Row>
              ) : ""}
              {currentJob.job_location_cities.length ? (
                <Row className="d-flex align-items-start justify-content-start pt-2">
                  <Col md={12} className="job-category">
                    <span className="job-heading">{multipleLocation ? "Locations" : "Location"}</span>
                    <div className="skill-pills-container pt-1">
                      <ListGroup horizontal className="skill-pills job-location">
                        {currentJob.job_location_cities.map((location) => (
                          <ListGroup.Item className="skill-pill-item" key={location.id} style={{ borderColor: '#263238', borderWidth: 1, color: '#000' }}>
                            <span className="skill-pill-title">
                              {location.job_city.city_name}, {location.job_city.city_country.country_name}
                            </span>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  </Col>
                </Row>
              ) : ""}
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                  <h2>{t("Job Description")}</h2>
                  <pre className="preTextwrap card-text">
                    <p>
                      {currentJob.job_description && currentJob.job_description}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            {currentJob.job_skills && currentJob.job_skills.length ? (
              <>
            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                  <h2>{t("Skills Required")}</h2>
                    <div className="skill-pills-container">
                        <ListGroup horizontal className="skill-pills">
                            {currentJob.job_skills.map((skill) => (
                                <ListGroup.Item className="skill-pill-item" key={skill.id} value={skill.job_skill}>
                                    <span className="skill-pill-title">
                                        {skill.job_skill}
                                    </span>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>                   
                    </div>
                </div>
              </Row>
            </div>
            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
            </>
            ) : ""}     

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                <h2>{t("Education & Qualifications")}</h2>
                  <pre className="preTextwrap">
                    <p>
                      {currentJob.job_education && currentJob.job_education}
                    </p>
                  </pre>
                </div>
              </Row>
              <Row className="d-flex job-description align-items-center">
                <div>
                <h2>{t("Perks & Benefits")}</h2>
                  <pre className="preTextwrap card-text">
                    <p>
                      {currentJob.job_perks && currentJob.job_perks}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            {isAccessible && (
              <>
              <div className="job-detail-body d-flex flex-column">
                <Row className="d-flex job-accessibility flex-column">
                    <h2>{t("Performance Drivers")}</h2>
                    {currentJob.accessibility_hearing && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_hearing.length > 0) && (
                          <span className={`job-heading ${multipleLocation ? 'mb-0' : ''}`}>{t("Communication Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">This job offers different ways through which all employees can communicate with each other equally.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_hearing.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_cognitive && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_cognitive.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Learning Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">Everyone learns differently, this job offers options to enable different learning styles.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_cognitive.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_mobility && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_mobility.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Movement Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">This job provides accessibility infrastructure for someone using a mobility aid or wheelchair.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_mobility.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_sensory_friendly && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_sensory.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Neurodivergent Talent Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info mb-2">This job offers options to enable individuals with cognitive abilities to perform equally at the workplace.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_sensory.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
                    {currentJob.accessibility_visual && (
                      <>
                        {(multipleLocation || currentJob.job_accommodation_visual.length > 0) && (
                          <span className={`job-heading mt-2 ${multipleLocation ? 'mb-0' : ''}`}>{t("Vision Drivers")}</span>
                        )}
                        {multipleLocation ? (
                          <span className="accommodation-info">If a person with visual differences joins the workforce, this job provides them equal access.</span>
                        ) : (
                        <div className="skill-pills-container">
                            <ListGroup horizontal className="skill-pills">
                                {currentJob.job_accommodation_visual.map((accommodation) => (
                                    <ListGroup.Item className="skill-pill-item" key={accommodation.id} value={accommodation.accommodation.id}>
                                        <span className="skill-pill-title">
                                            {accommodation.accommodation.title}
                                        </span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>                   
                        </div>
                        )}
                      </>
                    )} 
              </Row>
            </div>
            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
            </>
            )}

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-description align-items-center">
                <div>
                 <h2>{t("Disclaimer of Equal Employment")}</h2>
                  <pre className="preTextwrap card-text">
                    <p>
                      {currentJob.job_disclaimer && currentJob.job_disclaimer}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            <div className="job-detail-body d-flex flex-column">
              <Row className="d-flex job-org-description flex-column">
                <h2 className="">
                  {t("About")} {currentJob.job_organization}
                </h2>
                <div className="">
                  <pre className="preTextwrap card-text">
                    <p>
                      {currentJob.job_organization_detail && currentJob.job_organization_detail}
                    </p>
                  </pre>
                </div>
              </Row>
            </div>

            {/* <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>

            <div className="job-detail-footer d-flex flex-column">
              <Row className="d-flex job-footer align-items-center">
                <Col xs={12} className="job-close-date">
                  {t("Closes on")}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {direction === "rtl" ? 
                    (processDateArabic(currentJob.job_close_time, "PP")) 
                    : 
                    (processDate(currentJob.job_close_time, "PP"))
                    }
                  </span>
                </Col>
              </Row>
            </div> */}
          </div>
        </StyledJobDetail>
      )}
    </AppContentContainer>
  );
};

export default JobDetail;

const StyledJobDetail = styled.div`
width: 100%;
color: #000000;

.job-header-card {
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.job-header {
  padding: 20px 24px;
  color: #ffffff;
  background-color: #000000;
  font-size: 1.1rem;
  opacity: 0.8;
}

.job-badge-icon {
  display: flex;
  justify-content: flex-end;
}

.material-icons:hover {
  background-color: #f4f4f4;
  border-radius: 8px;
}

.job-header-details {
  padding: 1.8rem 2rem;
}

.job-detail-header,
.job-detail-body {
  padding: 24px;
}

.job-description,
.job-accessibility,
.job-org-description {
  padding: 0 1rem;
}

pre.preTextwrap {
  color: #000000;
}

.job-logo-container {
  width: 84px;
  height: 84px;
}

.job-logo-container img {
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  object-fit: contain;
}

.job-headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.job-title {
  font-size: 1.8rem;
  font-weight: 600;
}

h2 {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  margin-bottom: 1rem !important;
}

.job-heading {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.job-organization {
  font-size: 1.2rem;
  font-weight: 500;
}

.job-accessibility > .row {
  padding: 0 14px;
}

.skill-pills-container {
  // padding-bottom: 0.5rem;
  // padding-top: 0.5rem;
}

.skill-pills {
  flex-wrap: wrap;
}

.job-location .skill-pill-item {
  background: #fff !important;
}

.skill-pill-item {
  background: #3F0E40 !important;
  border-radius: 25px !important;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  list-style: none;
  padding: 0 !important;
  margin-bottom: 0.5rem;
  
  &:not(:last-child) {
      margin-right: 0.5rem;
  }
}

.skill-pill-title {
  padding: 0px 10px;
  height: 34px;
  align-items: center;
  display: flex;
}

.job-apply-button {
  display: flex;
  justify-content: flex-end;
}

#apply-job {
  background-color: #000000;
  border-color: #000000;
  border-radius: 7px;
  font-weight: 500;
  opacity: 0.9;
  display: flex;
  align-items: center;
}

.job-footer {
  padding: 20px 24px;
  font-size: 1.1rem;
}

.job-close-date {
  color: #000000;
}

.job-detail-card {
  margin-top: 25px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.show-mobile {
  display: none !important;
}

@media (max-width: 440px) {

  .show-regular {
    display: none !important;
  }
  .show-mobile {
    display: flex !important;
  }
  .material-icons {
    font-size: 24px !important;
    padding: 0;
  }
  .job-logo-container {
    width: 64px;
    height: 64px;
}
  .job-header-details {
    padding: 1.2rem;
  }
  .job-title {
    font-size: 1.2rem;
  }
  .job-organization, .job-close-date {
    font-size: 1rem;
  }
  .job-description h2, .job-accessibility h2, .job-org-description h2 {
    font-size: 1.2rem !important;
  }
  .job-category {
    margin: 0.4rem 0;
  }
  .job-detail-header,
  .job-detail-body,
  .job-footer {
    padding: 20px;
  }
  }
`;
