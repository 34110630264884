import React, { useState, useRef } from "react"; 
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';


const JobEducationExperiencePerks = ({ sectionValid, handleChange, handleBlur, values, errors, touched }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

return (
        <> 
        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label>Education and Qualifications<span style={{color: "#dc3545"}}> *</span></Form.Label>
                <Form.Control
                    placeholder="Please Share Education and Qualifications"
                    name="job_education"
                    className=""
                    value={values.job_education}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={4}
                    isInvalid={(touched.job_education && errors.job_education) || (!sectionValid && values.job_education === "")}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.job_education}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
 
        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label>Experience Level<span style={{color: "#dc3545"}}> *</span></Form.Label>
                <Form.Control
                    value={values.job_experience_level}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_experience_level"
                    as="select"
                    className="ic-form-select"
                    aria-label="Experience Level"
                    isInvalid={(touched.job_experience_level && errors.job_experience_level) || (!sectionValid && values.job_experience_level === "")}
                >
                    <option value="" disabled>Select Experience Level</option>
                    <option value="N">Not Applicable</option>
                    <option value="I">Internship</option>
                    <option value="E">Entry Level</option>
                    <option value="A">Associate</option>
                    <option value="M">Mid-Senior Level</option>
                    <option value="MA">Managerial</option>
                    <option value="D">Director</option>
                    <option value="X">Executive</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {errors.job_experience_level}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
 
        <Form.Row>
            <Form.Group as={Col} col={12}>
                <Form.Label style={{ display: 'flex' }}>
                    Perks and Benefits<span style={{ color: "#dc3545", paddingLeft: '4px' }}>*</span>
                    <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Perks and Benefits Tooltip" aria-pressed={showTooltip}>
                        <i className="material-icons help-icon">help_outline</i>
                    </Button>
                </Form.Label>
                <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                    {(props) => (
                        <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                            Other than the salary, training, and accessibility accommodations, what does your job role provide the Candidates? Share about the health benefits, travel costs, phone bill payments, flexible hours. Example: Friday lunches and off-site team buildings your team offers!
                        </Tooltip>
                    )}
                </Overlay>
                <Form.Control
                    placeholder="Please Share Perks and Benefits"
                    name="job_perks"
                    className=""
                    value={values.job_perks}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={4}
                    isInvalid={(touched.job_perks && errors.job_perks) || (!sectionValid && values.job_perks === "")}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.job_perks}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>  

        <Form.Row>
            <Form.Group as={Col} md={6} col={12}>
                <Form.Label>Pay Interval <span className="text-muted">(optional)</span></Form.Label>
                <Form.Control
                    value={values.job_pay_interval}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_pay_interval"
                    as="select"
                    className="ic-form-select"
                    aria-label="Pay Interval"
                    isInvalid={touched.job_pay_interval && errors.job_pay_interval}
                >
                    <option value="" disabled>Select Pay Interval</option>
                    <option value="H">Hourly</option>
                    <option value="D">Daily</option>
                    <option value="B">Bi-Weekly</option>
                    <option value="S">Semi-Monthly</option>
                    <option value="M">Per Month</option>
                    <option value="Y">Per Year</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {errors.job_pay_interval}
                </Form.Control.Feedback>
            </Form.Group>
 
            <Form.Group as={Col} md={6} col={12}>
                <Form.Label>Pay Range <span className="text-muted">(optional) Example: 3K - 6K USD</span></Form.Label>
                <Form.Control
                    placeholder="Pay Range"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="job_pay_range"
                    isInvalid={touched.job_pay_range && errors.job_pay_range}
                    value={values.job_pay_range}
                    type="text"
                    as="input"
                />
                <Form.Control.Feedback type="invalid">
                    {errors.job_pay_range}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
        </>
    )
};

export default JobEducationExperiencePerks;


