import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import leftRotateIcon from "../../assets/images/leftRotate.png";
import rightRotateIcon from "../../assets/images/rightRotate.png";

//CropImage for Profile Photo Upload and Impact Board Photo Upload
const CropImage = ({
  imageSrc,
  setEditorRef,
  scaleValue,
  onScaleChange,
  rotation,
  leftRotate,
  rightRotate,
}) => {
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });

  const handlePositionChange = (position) => {
    const pos = { ...position };
    setPosition(position);
  };

  return (
    <div className="editorOverlayInner">
      <div className="editorModalContent clearfix">
        <div className="cropCnt">
          <AvatarEditor
            position={position}
            onPositionChange={handlePositionChange}
            image={imageSrc}
            width={300}
            height={300}
            border={30}
            scale={scaleValue}
            rotate={rotation}
            ref={setEditorRef}
            className="cropCanvas"
          />
          <div className="options">
            <img
              onClick={leftRotate}
              style={{ marginRight: "12px" }}
              src={leftRotateIcon}
              alt="map"
              title="map"
              height="30"
              width="30"
            />
            <input
              type="range"
              value={scaleValue}
              step="0.01"
              name="points"
              min="1"
              max="2"
              onChange={onScaleChange}
            />
            <img
              onClick={rightRotate}
              style={{ marginLeft: "12px" }}
              src={rightRotateIcon}
              alt="map"
              title="map"
              height="30"
              width="30"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropImage;
