import React,{ useEffect } from "react";


const ScrollToTopButton = () => {
    const handleScroll=()=>{
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if ((document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) && width>480) {
            document.getElementById("scrollTotop").style.display = "block";
          } else {
            document.getElementById("scrollTotop").style.display = "none";
          }
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [])


    const handleScrollToTop=()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
    }

    return (
        <div aria-label="scroll to top"  className="outerBacktoTop" id="scrollTotop" onClick={handleScrollToTop}>
        <i alt="scroll to top icon" className="arrow fas fa-angle-up"></i>
         </div>
    )
}

export default ScrollToTopButton