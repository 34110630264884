/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { logoutUser } from '../../../../../redux/user';
import { useDispatch } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";


export function AsideMenuList({ layoutProps }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          tabIndex="-1"
          className={`menu-item ${getMenuItemActive("/org/dashboard", false)}`}
          aria-haspopup="true"
        >
          <LinkItem tabIndex="0" className="menu-link" to="/org/dashboard">
            <span className="svg-icon menu-icon">
              <i className="material-icons" style={{ color: checkIsActive(location, '/org/dashboard') ? '#fff' : '#A2A3B7', fontSize: '22px' }}>
                grid_view
              </i>
            </span>
            <span className="menu-text">Dashboard</span>
          </LinkItem>
        </li>

        {/* <li
          tabIndex="-1"
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/org/events",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <LinkItem
            tabIndex="0"
            className="menu-link menu-toggle"
            to="/org/events"
          >
            <span className="svg-icon menu-icon">
              <SVG src={Cap} />
            </span>
            <span className="menu-text">Events</span>
            <i className="menu-arrow" />
          </LinkItem>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Material UI</span>
                </span>
                </li> */}

              {/* Inputs */}
              {/*begin::2 Level*/}
              {/* <li
                tabIndex="-1"
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/org/events",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <LinkItem
                  tabIndex="0"
                  className="menu-link menu-toggle"
                  to="/org/events"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">All Events</span> */}
                  {/* <i className="menu-arrow"/> */}
                {/* </LinkItem>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/org/addevent",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <LinkItem
                  tabIndex="0"
                  className="menu-link menu-toggle"
                  to="/org/addevent"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Add New Event</span> */}
                  {/* <i className="menu-arrow"/> */}
                {/* </LinkItem>
              </li>
            </ul>
          </div>
        </li>  */}
        {/*end::1 Level*/}

        {/* Bootstrap */}
        {/*begin::1 Level*/}
        <li
          tabIndex="-1"
          className={`menu-item ${getMenuItemActive("/profile", false)}`}
          aria-haspopup="true"
        >
          <LinkItem tabIndex="0" className="menu-link" to="/profile">
            <span className="svg-icon menu-icon">
              <i className="material-icons" style={{ color: checkIsActive(location, '/profile') ? '#fff' : '#A2A3B7', fontSize: '24px' }}>
                person_outline
              </i>
            </span>
            <span className="menu-text">My Profile</span>
          </LinkItem>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/org/opportunities",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <LinkItem className="menu-link menu-toggle" to="/org/opportunities">
            <span className="svg-icon menu-icon">
              <i className="material-icons" style={{ color: checkIsActive(location, '/org/opportunities') ? '#fff' : '#A2A3B7', fontSize: '22px' }}>
                work_outline
              </i>
            </span>
            <span className="menu-text">My Opportunities</span>
            <i className="menu-arrow" />
          </LinkItem>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Material UI</span>
                </span>
                </li> */}

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/org/opportunities",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <LinkItem className="menu-link menu-toggle" to="/org/opportunities">
                  <i className="menu-bullet menu-bullet-dot">
                    <span style={{ backgroundColor: checkIsActive(location, '/org/opportunities') ? '#fff' : '#A2A3B7' }}/>
                  </i>
                  <span className="menu-text">All Opportunities</span>
                  {/* <i className="menu-arrow"/> */}
                </LinkItem>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "org/addjob",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <LinkItem className="menu-link menu-toggle" to="/org/addjob">
                  <i className="menu-bullet menu-bullet-dot">
                    <span style={{ backgroundColor: checkIsActive(location, '/org/addjob') ? '#fff' : '#A2A3B7' }}/>
                  </i>
                  <span className="menu-text">Post a Job</span>
                  {/* <i className="menu-arrow"/> */}
                </LinkItem>
              </li>
            </ul>
          </div>
        </li>


        <li
          tabIndex="-1"
          className={`menu-item ${getMenuItemActive("/org/account", false)}`}
          aria-haspopup="true"
        >
          <LinkItem tabIndex="0" className="menu-link" to="/org/account">
            <span className="svg-icon menu-icon">
              <i className="material-icons" style={{ color: checkIsActive(location, '/org/account') ? '#fff' : '#A2A3B7', fontSize: '22px' }}>
                settings
              </i>
            </span>
            <span className="menu-text">Account Settings</span>
          </LinkItem>
        </li>

        <li
          tabIndex="-1"
          className={`menu-item`}
          aria-haspopup="true"
        >
          <span tabIndex="0" className="menu-link" onClick={() => dispatch(logoutUser())}>
            <span className="svg-icon menu-icon">
              <i className="material-icons" style={{ color: '#A2A3B7', fontSize: '22px' }}>
                logout
              </i>
            </span>
            <span className="menu-text">Logout</span>
          </span>
        </li>

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

const LinkItem = styled(NavLink)`
  // padding: 0.65rem 1.1rem;
  //     color: #6c7293;
  //     font-weight: 500;
  &:focus {
    border: 0.1rem solid white;
    //border-radius: 0.3rem;
  }
`;
