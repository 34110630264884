import React from 'react';
import hearingIcon from '../assets/images/hearing.png';
import mobilityIcon from '../assets/images/mobility.png';
import sensoryIcon from '../assets/images/sensory_.png';
import sensorySvg from '../assets/images/sensorySvg.svg';

import visualIcon from '../assets/images/visual.png';
import cognitiveIcon from '../assets/images/cognitive.png';

const AccessibiltiyIconDisplay = ({
  accessibilityCognitive,
  accessibilitySensoryFriendly,
  accessibilityVisual,
  accessibilityHearing,
  accessibilityMobility,
  islist
}) => (
  <div className="row" style={{ borderTop: !islist ? "2px solid #dadbe2":"" }}>
  <div className="ic-pages-search-results-header--wrapper">
    <div className="results-header-title">
      <div className="d-flex">
        {accessibilityCognitive &&
          <div className={`${!islist?"imagBox":"imaglistBox"} imgbar`} aria-label="Cognitive Accessibility">
            <img className="imgbar" alt="inclusive accessibility widget icon" title="Cognitive Accessibility" src={cognitiveIcon} aria-label="Cognitive Accessibility image" />
          </div>
        }
        {accessibilitySensoryFriendly &&
          <div className={`${!islist?"imagBox":"imaglistBox"} imgbar`} aria-label="Sensory friendly Accessibility image" style={{padding: '1px'}}>
            <img className="imgbar imgsensory" alt="inclusive accessibility widget icon" title="Sensory Accessibility" src={sensoryIcon} height='100' width='200' style={{margin:'0 10px'}} aria-label="Sensory friendly Accessibility image" />
            <span dangerouslySetInnerHTML={{__html: sensorySvg}} className="imgbar" />
        
          </div>

        }
        {accessibilityVisual &&
          <div className={`${!islist?"imagBox":"imaglistBox"} imgbar`} aria-label="Visual Accessibility image">
            <img className="imgbar" alt="inclusive accessibility widget icon" title="Visual Accessibility" src={visualIcon} aria-label="Visual Accessibility image" />
          </div>
        }
        {accessibilityHearing &&
          <div className={`${!islist?"imagBox":"imaglistBox"} imgbar`} aria-label="Hearing Accessibility image">
            <img className="imgbar" alt="inclusive accessibility widget icon" title="Hearing Accessibility" src={hearingIcon} aria-label="Hearing Accessibility image" />
          </div>
        }
        {accessibilityMobility &&
          <div className={`${!islist?"imagBox":"imaglistBox"} imgbar`} aria-label="Mobility Accessibility image">
            <img className="imgbar" alt="inclusive accessibility widget icon" title="Mobility Accessibility" src={mobilityIcon} aria-label="Mobility Accessibility image" />
          </div>
        }

                          
    
      </div>
    </div>
  </div>
</div>
)



export default AccessibiltiyIconDisplay