import React from "react";
import styled from "styled-components";

const PostedByOn = ({ postedBy, postedTo }) => {
  return (
    <StyledPostedByTo>
      <ProfileLink
        to={
          postedBy.person
            ? `/p/${postedBy.person.slug}`
            : `/o/${postedBy.organisation.slug}`
        }
      >
        {posterName}
      </ProfileLink>
      <span> posted on </span>
      <ProfileLink
        to={
          postedTo.person
            ? `/p/${postedTo.person.slug}`
            : `/o/${postedTo.organisation.slug}`
        }
      >{`${
        postedTo.organisation
          ? postedTo.organisation.organization_title
          : postedTo.person.full_name.charAt(0).toUpperCase() +
            postedTo.person.full_name.slice(1)
      }``s`}</ProfileLink>
      <span> profile</span>
    </StyledPostedByTo>
  );
};

export default PostedByOn;

const StyledPostedByTo = styled.div`
  //padding: 20px 24px;
  font-family: "Inter", sans-serif;
  font-weight: bold;

  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #343a40;
    background: #ffffff;
    border-color: #ffffff;
  }

  a.dropdown-item:active {
    background-color: #ffffff;
    color: #212529;
  }

  .dropdown-item:hover {
    background-color: #f8f9fa !important;
  }
`;
