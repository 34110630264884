import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink as Link, Redirect, useLocation, useRouteMatch, useParams, useHistory, Switch, Route } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { Container, Row, Col } from "react-bootstrap";
import SplitButton from "react-bootstrap/SplitButton";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { LoaderWithText } from "../../../components/Loader";
import { getTimeAgo } from "../../../common/utils";
import ViewJobApplicant from "./ViewJobApplicant";
import JobApplicants from "./JobApplicants";
import JobDetail from './JobDetail';
import JobEdit from './JobEdit';
import { CustomersUIProvider } from "./JobApplicants/CustomersUIContext";

import { getOrganizationJobBySlug, deleteOrganizationJob, getJobAppicationList, rejectApplicant, shortlistApplicant } from "../../../api";

const ManageJob = () => {
  const { jobslug, jobId } = useParams();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [currentJob, setJob] = useState(null);
  const [isAccessible, setAccessible] = useState(false);
  const [JobLoading, setJobLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [appplicants, setApplicants] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [shortlisted, setShortlisted] = useState([]);
  const [status, setStatus] = useState(null);

  const unmounted = useRef(false);

  const Job = () => {
    setJob(null);
    setJobLoading(true);

    getOrganizationJobBySlug(jobslug)
      .then(({ data }) => {
        if (!unmounted.current) {
            if (data.accessibility_cognitive || data.accessibility_hearing || data.accessibility_mobility || data.accessibility_sensory_friendly || data.accessibility_visual) {
                setAccessible(true);
              }
          setJob(data);
        }
      })
      .catch((err) => {
      });
      setJobLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (jobslug) {
      Job();
    } else {
    }
  }, [jobslug]);

  const getApplicants = () => {
    setJobLoading(true);

    getJobAppicationList(jobId, { status: status })
      .then(({ data }) => {
        if (!unmounted.current) {
          const reject = data.applications.filter(
            (x) => x.applicant_status === "R"
          );
          const short = data.applications.filter(
            (x) => x.applicant_status === "S"
          );
          setRejected(reject);
          setShortlisted(short);
          setApplicants(data.applications);
          setJobLoading(false);
        }
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  useEffect(() => {
    if (jobId) {
      getApplicants();
    } else {
    }
  }, [jobId]);

  useEffect(() => {
    return () => { unmounted.current = true } 
  }, []);

  const customersUIEvents = {};

  const onJobDelete = () => {
    setDeleteLoading(true);
    deleteOrganizationJob(currentJob.slug)
      .then(() => {
        setDeleteLoading(false);
        history.replace("/org/opportunities");
      })
      .catch(({ data: errors }) => {
        setDeleteLoading(false);
      });
  };

  const handleStatusChange = (applicantId, status) => {
    const index = appplicants.findIndex((x) => x.applicant.id === applicantId);
    const applicantobj = appplicants.filter(
      (x) => x.applicant.id === applicantId
    );
    appplicants[index].applicant_status = status;

    if (status === "R") {
      const reject = [...rejected];
      const short = shortlisted.findIndex(
        (x) => x.applicant.id === applicantId
      );
      if (short !== -1) {
        const shortlist = [...shortlisted];
        shortlist.splice(short, 1);
        setShortlisted(shortlist);
      }
      setRejected([...reject, applicantobj[0]]);
    }

    if (status === "S") {
      const shortlist = [...shortlisted];
      const rej = rejected.findIndex((x) => x.applicant.id === applicantId);
      if (rej !== -1) {
        const rejlist = [...rejected];
        rejlist.splice(rej, 1);
        setRejected(rejlist);
      }

      setShortlisted([...shortlist, applicantobj[0]]);
    }
    setApplicants(applicants);
  };

  if (JobLoading)
    return <LoaderWithText padding="150px 0px" text="Please wait..." />;

  return (
    <>
      {currentJob && (
        <StyledManageJob>
          
          <NavOrganization count={appplicants.length} />

          <Switch>
            <Route path={`${path}/description`}>
              <>
                <JobDetail setShow={setShow} setIsEditing={setIsEditing} currentJob={currentJob} isAccessible={isAccessible}/>

                <Modal show={show} centered size="md" onHide={() => setShow(false)}>
                  <Modal.Header style={{ fontSize: '20px', fontWeight: '500' }}>Delete Job</Modal.Header>
                  <Modal.Body>
                    <h4 style={{ fontSize: "1rem" }} className="py-3 mb-0">
                      Are you sure you want to delete this job?
                    </h4>
                  </Modal.Body>
                  <Modal.Footer>
                    <Container>
                      <Row>
                        <Col sm={12} md={6}>
                          <Button onClick={() => setShow(false)} block className="modal-cancel-button">
                            Cancel
                          </Button>
                        </Col>
                        <Col className="mt-3 mt-md-0" sm={12} md={6}>
                          <Button onClick={onJobDelete} block size="lg" className="modal-submit-button">
                            {deleteLoading ? (
                              <i className="fas fa-spinner fa-spin" />
                            ) : (
                              "Delete Job"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Footer>
                </Modal>

                <JobEdit
                  show={isEditing}
                  isEditing
                  onUpdated={Job}
                  closeDialog={() => setIsEditing(false)}
                  currentJob={currentJob}
                />
              </>
            </Route>

            <Route path="/profile/job/:jobslug/:jobId/applicants">
              <AllApplicants
                appplicants={appplicants}
                rejected={rejected}
                shortlisted={shortlisted}
                onStatusChange={handleStatusChange}
                setStatus={setStatus}
                path={path}
              />
            </Route>
            <Route path={`/org/jobdetail/:jobslug/:jobId/applicants`}>
              <CustomersUIProvider customersUIEvents={customersUIEvents}>
                <JobApplicants
                  jobId={jobId}
                  applicants={appplicants}
                  rejected={rejected}
                  shortlisted={shortlisted}
                  onStatusChange={handleStatusChange}
                  setStatus={setStatus}
                  path={path}
                />
              </CustomersUIProvider>
            </Route>
            <Redirect to={`${path}/description`} />
          </Switch>
        </StyledManageJob>
      )}
    </>
  );
};

const ApplicantItem = ({ applicant, index, length, onStatusChange }) => {
  const [isSubmittingS, setIsSubmittingS] = useState(false);
  const [isSubmittingR, setIsSubmittingR] = useState(false);

  const handleChange = (e) => {
    if (e === "R") {
      setIsSubmittingR(true);
      rejectApplicant(applicant.job, applicant.id, {})
        .then((resp) => {
          onStatusChange(resp.data.applicant, "R");
          setIsSubmittingR(false);
          setShow(false);
        })
        .catch((error) => {
          setIsSubmittingR(false);

          closeDialog();
        });
    } else if (e === "S") {
      setIsSubmittingS(true);

      shortlistApplicant(applicant.job, applicant.id, {})
        .then((resp) => {
          onStatusChange(resp.data.applicant, "S");
          setShow(false);
          setIsSubmittingS(false);
        })
        .catch((error) => {
          setShow(false);
          setIsSubmittingS(false);
        });
    }
  };

  const [show, setShow] = useState(false);
  return (
    <div className="applicantDetail-wrapper  d-flex flex-column flex-md-row justify-content-between" style={{ borderBottom: `${length - 1 === index ? "none" : "1px solid #EFEFEF"}`}}>
      <div className="d-flex flex-column flex-md-row align-items-center">
        <img src={applicant.applicant.person_display_picture} alt="pic" />
        <div className="friendData">
          <h3>{applicant.applicant.full_name}</h3>
          <div className="friendprofession">
            {applicant.applicant.identifies_as}
          </div>
          {applicant.applicant.person_city && (
            <div className="friendprofession">
              {applicant.applicant.person_city &&
                applicant.applicant.person_city.city_name}
              ,{" "}
              {applicant.applicant.person_city &&
                applicant.applicant.person_city.city_country.country_name}
            </div>
          )}
          <div className="friendprofession">
            Applied {getTimeAgo(applicant.created_at)}{" "}
          </div>
          <div className="">
            <Badge variant="secondary">
              {applicant.applicant_status === "A"
                ? "Applied"
                : applicant.applicant_status === "S"
                ? "Shortlist"
                : applicant.applicant_status === "I"
                ? "Interviewing"
                : applicant.applicant_status === "O"
                ? "Offered"
                : applicant.applicant_status === "R"
                ? "Reject"
                : "Archived"}
            </Badge>
          </div>
        </div>
      </div>

      <div>
        <SplitButton alignRight key="danger" id="dropdown-split-variants-danger" variant="danger" title="View Profile" onClick={() => setShow(true)}>
          <Dropdown.Item onClick={() => handleChange("S")} loading={isSubmittingS ? 1 : 0} className={`${applicant.applicant_status === "S" ? "disabled" : ""}`}>
            {isSubmittingS ? "Please wait..." : "Shortlist"}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleChange("R")} loading={isSubmittingR ? 1 : 0} className={`${applicant.applicant_status === "R" ? "disabled" : ""}`}>
            {isSubmittingR ? "Please wait..." : "Reject"}
          </Dropdown.Item>
        </SplitButton>
      </div>

      <ViewJobApplicant
        show={show}
        closeDialog={() => setShow(false)}
        profileId={applicant.applicant.id}
        jobId={applicant.job}
        applicationId={applicant.id}
        status={applicant.applicant_status}
        isSubmittingS={isSubmittingS}
        isSubmittingR={isSubmittingR}
        handleChange={handleChange}
      />
    </div>
  );
};

const AllApplicants = ({ appplicants, onStatusChange, path, rejected, shortlisted }) => {

  return (
    <div className="card p-0">
      <NavJobs allcount={appplicants.length} scount={shortlisted.length} rcount={rejected.length} />
      <Switch>
        <Route path={`${path}/applicants/all`}>
          <div className="applicant-wrapper">
            {appplicants.length ? (
              appplicants.map((item, index) => (
                <ApplicantItem
                  key={index}
                  onStatusChange={onStatusChange}
                  applicant={item}
                  index={index}
                  length={appplicants.length}
                />
              ))
            ) : (
              <h3 className="text-red p-3">
                There is no Applicant for this job now
              </h3>
            )}
          </div>
        </Route>
        <Route path={`${path}/applicants/shortlisted`}>
          <Shortlisted
            applicants={shortlisted}
            onStatusChange={onStatusChange}
            status={"S"}
          />
        </Route>
        <Route path={`${path}/applicants/rejected`}>
          <Shortlisted
            applicants={rejected}
            onStatusChange={onStatusChange}
            status={"R"}
          />
        </Route>
        <Redirect to={`${path}/applicants/all`} />
      </Switch>
    </div>
  );
};

const Shortlisted = ({ applicants, status, onStatusChange }) => {

  return (
    <div className="applicant-wrapper">
      {applicants.length ? (
        applicants.map((item, index) => (
          <ApplicantItem
            key={index}
            onStatusChange={onStatusChange}
            applicant={item}
            index={index}
            length={applicants.length}
          />
        ))
      ) : (
        <h3 className="py-4 px-3 text-red">{`There is no ${status === "S" ? "shortlisted" : "rejected"} applicant for this job now`}</h3>
      )}
    </div>
  );
};

export default ManageJob;

const StyledManageJob = styled.div`
font-family: 'Inter', sans-serif;
  width:100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
  min-height: 80vh;
  color:black;
  .headerspan{
    font - size: 18px;
    font-weight: 700;
  }

  .headerspan > .row{
    padding: 12px 16px;
  }
  .heading{
    font-size: 20px;
    font-weight: 900;
    padding-bottom: 15px;
  }
  .card {
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
    border-radius: 16px;
    border: 0;
  }
  .applicantDetail-wrapper{
    padding: 20px;
    align-items: center;
  }
  .applicantDetail-wrapper > div > img{
    width: 100px;
    height: 100px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
    border-radius: 50px;
  }
  .friendData > h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #5F5F5F;
    margin: 0px;
}
 .level-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 25px;
  background: #263238;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
  border-radius: 28px;
}
  .outerRing {
  display: flex;
  padding: 2px;
  border-radius: 50px;
  border: 1px solid white;
  }
  .level {
    padding-left: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}
.friendData{
  padding-left: 11px;
}
.card-headerspan{
  background-color: #fff;
  padding: 20px 0px;
    
}
.job-img {
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
`;

const NavOrganization = ({ count }) => {
  const { pathname: path } = useLocation();
  const { url } = useRouteMatch();

  return (
    <StyledContainer>
      <div className={`nav-profile px-0 ${path === `${url}/description` ? "active" : ""}`}>
        <Link to={`${url}/description`} className="nav-link  px-0 mx-3 py-3">
          Job Description
        </Link>
      </div>

      <div className={`nav-profile px-0 ${path === `${url}/applicants` ? "active" : ""}`}>
        <Link to={`${url}/applicants`} className="nav-link px-0 mx-3 py-3">
          Applicants ({count})
        </Link>
      </div>
    </StyledContainer>
  );
};

const NavJobs = ({ allcount, scount, rcount }) => {
  const { pathname: path } = useLocation();
  const { url } = useRouteMatch();

  return (
    <StyledContainer style={{ borderRadius: "16px 16px 0 0", overflow: "hidden" }}>
      <div className={`nav-profile px-0 ${path === `${url}/all` ? "active" : ""}`}>
        <Link to={`${url}/all`} className="nav-link  px-0 mx-3 py-3">
          All ({allcount})
        </Link>
      </div>

      <div className={`nav-profile px-0 ${path === `${url}/shortlisted` ? "active" : ""}`}>
        <Link to={`${url}/shortlisted`} className="nav-link px-0 mx-3 py-3">
          Shortlisted ({scount})
        </Link>
      </div>
      <div className={`nav-profile px-0 ${path === `${url}/rejected` ? "active" : ""}`}>
        <Link to={`${url}/rejected`} className="nav-link px-0 mx-3 py-3">
          Rejected ({rcount})
        </Link>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-bottom: 25px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;

  .nav-profile {
    background-color: #ffffff;
  }

  .nav-profile a {
    color: #5f5f5f;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  .nav-link.active {
    color: #000000;
    border-bottom: 3px solid;
    // padding: 1rem 0px;
  }
`;
