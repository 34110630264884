import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import * as yup from 'yup';
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { format } from 'date-fns';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
//import Dropdown from 'react-bootstrap/Dropdown';

import Form from '../../../common/Form';
import DatePicker from '../../../components/FormikDatepicker';
import { cities as getCities, updateProfile , getCountries , getCitiesByCountry } from '../../../api'

import CountryCity from '../../../components/CountryCity';

const EditProfile = ({
  activeProfile,
  show,
  profile,
  closeDialog,
  onProfileUpdated,
}) => {

  const [userCity,setUserCity] = useState(null)
  const [cityValid , setCityValid] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);


  const validationSchema = yup.object().shape({
    person_first_name: yup.string().strict(false).trim().required('Your name is required.'),
    person_last_name: yup.string().strict(false).trim().required('Your name is required.'),
   // person_linkedin:yup.string(),
   // person_be:yup.string(),
   // person_fb:yup.string(),
    //person_ability: yup.string().required('Your choice is required.'),
    person_headline: yup.string().strict(false).trim().required('Please describe your profession.').max(45),
    person_summary: yup.string().strict(false).trim().required('Please provide a tagline.'),
    person_identifies_as: yup.string().required('Your choice is required.'),
    //person_work_towards_project: yup.string()
  });

  const onUpdateProfile = (values, { setSubmitting }) => {
    if(userCity !== null){
      values.person_city = Number(userCity)
      updateProfile(activeProfile.id, { ...values})
      .then(() => {
        setSubmitting(false);

        onProfileUpdated();
      })
      .catch(() => {
        setSubmitting(false);
      });
    }
    else{
      setSubmitting(false);
      setCityValid(true)
    }
    
    
  };

  const initialValues = {
    person_first_name: profile.person_first_name,
    person_last_name: profile.person_last_name,
    //person_ability: profile.person_ability,
    person_summary:profile.person_summary,
    person_city: profile.person_city ? profile.person_city.id : null,
    person_headline: profile.person_headline,
    person_identifies_as: profile.person_identifies_as,
    //person_linkedin:"",
    //person_be:"",
   // person_fb:""
  };

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      className="EditProfileModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onUpdateProfile}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
          handleSubmit,
          touched,
        }) => (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>

               
                  <Row>
                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>
                        First Name
                    </Form.Label>
                      <Form.Control
                        name="person_first_name"
                        placeholder="Your first name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.person_first_name}
                        isInvalid={touched.person_first_name && errors.person_first_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.person_first_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>
                        Last Name
                    </Form.Label>
                      <Form.Control
                        name="person_last_name"
                        placeholder="Your last name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.person_last_name}
                        isInvalid={touched.person_last_name && errors.person_last_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.person_last_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Tell us about yourself</Form.Label>
                      <Form.Control
                        aria-label="Tell us about yourself"
                        placeholder="How do you describe yourself in less than 9 words?"
                        name="person_headline"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.person_headline}
                        isInvalid={touched.person_headline && errors.person_headline}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.person_headline}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                 
                  <CountryCity  cityValid={cityValid} setCityValid={setCityValid} setUserCity={setUserCity} person_city={profile.person_city}/>
                
     
                  <Row>
                  <Form.Group as={Col} col={12} md={12}>
                    <Form.Label style={{ display: 'flex' }}>Select Identity
                      <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Person Identity Tooltip" aria-pressed={showTooltip}>
                        <i className="material-icons help-icon">help_outline</i>
                      </Button>
                    </Form.Label>
                    <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
                      {(props) => (
                        <Tooltip id="overlay-tooltip" className="help-tooltip modal-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
                          Please choose how you prefer to identify yourself on the platform. Your chosen identity will be visible to other users, employers and organizations.
                        </Tooltip>
                      )}
                    </Overlay>
                      <Form.Control
                        value={values.person_identifies_as}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="person_identifies_as"
                        as="select"
                        className="ic-form-select"
                        isInvalid={touched.person_identifies_as && errors.person_identifies_as}
                      >
                        <option value="" disabled>Select an option</option>
                        <option value="SOI"> Supporter of Inclusion</option>
                        <option value="PWD"> Person with Disability</option>
                        <option value="POD"> Proud to be Person of Determination</option>
                        <option value="POP"> Parent of Person of Determination</option>
                        <option value="SOP"> Sibling of Person of Determination</option>
                        <option value="ROP"> Relative of Person of Determination</option>
                        <option value="HSP"> Playing a role in the Health Sector for People of Determination</option>
                        <option value="ESP"> Playing a role in the Education Sector for People of Determination</option>

                      </Form.Control>

                      <Form.Control.Feedback type="invalid">
                        {errors.person_identifies_as}
                      </Form.Control.Feedback>
                    </Form.Group>
                  
                    {/* <Form.Group as={Col} col={12} md={12}>
                      <Form.Label>Choose Your Special Ability</Form.Label>
                      <Form.Control
                        value={values.person_identifies_as}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="person_ability"
                        as="select"
                        className="ic-form-select"
                        isInvalid={touched.person_ability && errors.person_ability}
                      >
                        <option selected disabled>Select One of the Below Options</option>
                        <option value="HEAR"> Hearing Impairment</option>
                        <option value="MOTOR"> Motor Impairment</option>
                        <option value="COGN"> Cognitive Impairment</option>
                        <option value="VISUL"> Visual Impairment</option>
                        <option value="SEN"> Sensory Impairment</option>
                        <option value="NON"> None</option>
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">
                        {errors.person_ability}
                      </Form.Control.Feedback>
                    </Form.Group> */}
                  </Row>


                  {/* <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>LinkedIn profile link</Form.Label>
                      <Form.Control
                        placeholder="Your linkedIn profile link"
                        name="person_linkedin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.person_linkedin}
                        isInvalid={touched.person_linkedin && errors.person_linkedin}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.person_linkedin}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                 
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Behance profile link</Form.Label>
                      <Form.Control
                        placeholder="Your behance profile link"
                        name="person_be"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.person_be}
                        isInvalid={touched.person_be && errors.person_be}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.person_be}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                 
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Facebook profile link</Form.Label>
                      <Form.Control
                        placeholder="Your facebook profile link"
                        name="person_fb"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.person_fb}
                        isInvalid={touched.person_fb && errors.person_fb}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.person_fb}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                 */}
                
                
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Your Tagline</Form.Label>
                      <Form.Control
                        placeholder="A one-liner about YOU: your introduction to the world. "
                        name="person_summary"
                        className="textareaHeight"
                        value={values.person_summary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        as="textarea"
                        isInvalid={touched.person_summary && errors.person_summary}
                      />
                      <Form.Label className="pt-2" style={{color: '#515151'}}>(Character Limit: 250)</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.person_summary}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                 
                
               </Modal.Body>

              <Modal.Footer>
                  <Container>
                    <Row>
                      <Col sm={12} md={6}>
                        <Button onClick={closeDialog} block className="modal-cancel-button">Cancel</Button>
                      </Col>
                      <Col className="mt-3 mt-md-0" sm={12} md={6}>
                        <Button type="submit" disabled={isSubmitting} block className="modal-submit-button">{isSubmitting ? <i className="fas fa-spinner fa-spin" /> : 'Save Changes'}</Button>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Footer>
              </Form>
            </>
          )}
      </Formik>
    </Modal>
  );
};

export default connect((state) => ({
  activeProfile: state.user.activeProfile,
}), {

})(EditProfile);
