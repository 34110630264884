import React from "react";
import { useTranslation } from 'react-i18next';

const AccessibilityCheckBoxes = ({
  accessibility,
  setAccessibility,
  filters,
  setFilters,
  isFilter,
  OnApplyFilters,
}) => {
  const { t } = useTranslation();
  const checkBoxChange = (e, field) => {
    const newFilters = { ...filters };
    const newAccessibility = { ...accessibility };
    if (field === "Cognitive") {
      if (!newAccessibility.cognitive) {
        newFilters.cognitive = true;
      } else newFilters.cognitive = "";
      newAccessibility.cognitive = !newAccessibility.cognitive;
    }
    if (field === "Mobility") {
      if (!newAccessibility.mobility) {
        newFilters.mobility = true;
      } else newFilters.mobility = "";
      newAccessibility.mobility = !newAccessibility.mobility;
    }
    if (field === "Visual") {
      if (!newAccessibility.visual) {
        newFilters.visual = true;
      } else newFilters.visual = "";
      newAccessibility.visual = !newAccessibility.visual;
    }
    if (field === "Hearing") {
      if (!newAccessibility.hearing) {
        newFilters.hearing = true;
      } else newFilters.hearing = "";
      newAccessibility.hearing = !newAccessibility.hearing;
    }
    if (field === "Sensory") {
      if (!newAccessibility.sensoryfriendly) {
        newFilters.sensoryfriendly = true;
      } else newFilters.sensoryfriendly = "";
      newAccessibility.sensoryfriendly = !newAccessibility.sensoryfriendly;
    }
    if (field === "openAll") {
      if (!newAccessibility.allOpen) {
        newFilters.cognitive = true;
        newFilters.hearing = true;
        newFilters.mobility = true;
        newFilters.sensoryfriendly = true;
        newFilters.visual = true;

        newAccessibility.cognitive = true;
        newAccessibility.hearing = true;
        newAccessibility.mobility = true;
        newAccessibility.sensoryfriendly = true;
        newAccessibility.visual = true;
        newAccessibility.allOpen = !newAccessibility.allOpen;
      } else {
        newFilters.cognitive = "";
        newFilters.hearing = "";
        newFilters.mobility = "";
        newFilters.sensoryfriendly = "";
        newFilters.visual = "";

        newAccessibility.cognitive = false;
        newAccessibility.hearing = false;
        newAccessibility.mobility = false;
        newAccessibility.sensoryfriendly = false;
        newAccessibility.visual = false;
        newAccessibility.allOpen = !newAccessibility.allOpen;
      }
    }
    setFilters(newFilters);
    setAccessibility(newAccessibility);
    if (isFilter) {
      OnApplyFilters(newFilters);
    }
  };

  return (
    <div className="button-group-pills" data-toggle="buttons">
      <label
        role="checkbox"
        aria-checked={accessibility.hearing}
        tabIndex="0"
        className={`btn btn-default ${accessibility.hearing ? "active" : ""}`}
        onKeyUp={(event) =>
          event.key === "Enter" ? checkBoxChange(event, "Hearing") : ""
        }
      >
        <input
          tabIndex="-1"
          className="inputlbl"
          type="checkbox"
          name="options"
          defaultChecked={accessibility.hearing}
          onClick={(e) => checkBoxChange(e, "Hearing")}
        />
        <div>{t("Communication")}</div>
      </label>

      <label
        role="checkbox"
        aria-checked={accessibility.cognitive}
        tabIndex="0"
        className={`btn btn-default ${accessibility.cognitive ? "active" : ""}`}
        onKeyUp={(event) =>
          event.key === "Enter" ? checkBoxChange(event, "Cognitive") : ""
        }
      >
        <input
          tabIndex="-1"
          className="inputlbl"
          type="checkbox"
          name="options"
          defaultChecked={accessibility.cognitive}
          onClick={(e) => checkBoxChange(e, "Cognitive")}
        />
        <div>{t("Learning")}</div>
      </label>

      <label
        role="checkbox"
        aria-checked={accessibility.mobility}
        tabIndex="0"
        className={`btn btn-default ${accessibility.mobility ? "active" : ""}`}
        onKeyUp={(event) =>
          event.key === "Enter" ? checkBoxChange(event, "Mobility") : ""
        }
      >
        <input
          tabIndex="-1"
          className="inputlbl"
          type="checkbox"
          name="options"
          defaultChecked={accessibility.mobility}
          onClick={(e) => checkBoxChange(e, "Mobility")}
        />
        <div>{t("Movement")}</div>
      </label>

      <label
        role="checkbox"
        aria-checked={accessibility.sensoryfriendly}
        tabIndex="0"
        className={`btn btn-default ${
          accessibility.sensoryfriendly ? "active" : ""
        }`}
        onKeyUp={(event) =>
          event.key === "Enter" ? checkBoxChange(event, "Sensory") : ""
        }
      >
        <input
          tabIndex="-1"
          className="inputlbl"
          type="checkbox"
          name="options"
          defaultChecked={accessibility.sensoryfriendly}
          onClick={(e) => checkBoxChange(e, "Sensory")}
        />
        <div>{t("Neurodivergence")}</div>
      </label>

      <label
        role="checkbox"
        aria-checked={accessibility.visual}
        tabIndex="0"
        className={`btn btn-default ${accessibility.visual ? "active" : ""}`}
        onKeyUp={(event) =>
          event.key === "Enter" ? checkBoxChange(event, "Visual") : ""
        }
      >
        <input
          tabIndex="-1"
          className="inputlbl"
          type="checkbox"
          name="options"
          defaultChecked={filters.visual}
          onClick={(e) => checkBoxChange(e, "Visual")}
        />
        <div>{t("Vision")}</div>
      </label>

      <label
        role="checkbox"
        aria-checked={accessibility.allOpen}
        tabIndex="0"
        className={`btn btn-default ${accessibility.allOpen ? "active" : ""}`}
        onKeyUp={(event) =>
          event.key === "Enter" ? checkBoxChange(event, "openAll") : ""
        }
      >
        <input
          tabIndex="-1"
          className="inputlbl"
          type="checkbox"
          name="options"
          defaultChecked={accessibility.allOpen}
          onClick={(e) => checkBoxChange(e, "openAll")}
        />
        <div>{t("Open to All")}</div>
      </label>
    </div>
  );
};

export default AccessibilityCheckBoxes;
