import React from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/constants';

const ErrorMessage = ({
  message,
}) => (
  <Container>
    <svg className="cross__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className="cross__circle" cx="26" cy="26" r="25" fill="none" />
      <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
      <path className="cross__path cross__path--right" fill="none" d="M16,36 l20,-20" />
    </svg>
    <p>
      {message}
    </p>
  </Container>
);

export default ErrorMessage;

const Container = styled.div`
  > p {
    text-align: center;
    color: ${Colors.primary};
    font-size: 1.8rem;
  }

  .cross__svg {
      border-radius: 50%;
      display: block;
      height: 154px;
      margin: 4rem auto;
      stroke-width: 3;
      width: 154px;
  }

  .cross__circle {
      animation: 0.6s ease 0s normal forwards 1 running stroke;
      fill: none;
      margin: 0 auto;
      stroke: #e55454;
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
  }

  .cross__path {
      stroke: #e55454;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      transform-origin: 50% 50% 0;
      &--right {
        animation: 0.3s ease 0.8s normal forwards 1 running stroke;
      }
      &--left {
        animation: 1s ease 0.8s normal forwards 1 running stroke;
      }
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
`;
