import React, { useState, useEffect } from "react";
import { Page, Text, View, Document, StyleSheet, Font, Link } from '@react-pdf/renderer';
import { getPersonContact } from "../../../api";
import { format } from "date-fns";
import RobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from '../../../assets/fonts/Roboto-Medium.ttf';
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import RobotoBlack from '../../../assets/fonts/Roboto-Black.ttf';

//Option to apply using profile when applying to a job

const ProfilePdf = ({ profile }) => {
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    // useEffect(() => {
    //     getPersonContact(profile?.id)
    //         .then(({ data }) => {
    //                 setEmail(data.person_email);
    //                 setPhoneNumber(data.person_phone_number);
    //         })
    //         .catch(() => {});
    // },[]);

    return (
        <Document title={profile?.full_name}>
            <Page size="A4" style={styles.page}>

                <View style={[styles.section, { marginRight: 0, width: '40%', backgroundColor: '#111' }]}>

                    <Text style={styles.heading}>{profile?.full_name}</Text>
                    <Text style={styles.subHeading}>{profile?.person_headline}</Text>
                    <Text style={styles.sectionItemLight}>{profile?.identifies_as}</Text>

                    <Text style={styles.sectionItemLight}>{profile && format(new Date(profile?.person_dob), "dd MMMM yyyy")}</Text>
                    {/* {phoneNumber !== "" && <Text style={styles.sectionItemLight}>{phoneNumber}</Text>}
                    {email !== "" && <Text style={styles.sectionItemLight}>{email}</Text>} */}
                    <Text style={styles.sectionItemLight}>{profile?.person_city.city_name}, {profile?.person_city.city_country.country_name}</Text>
                    <Link src={`https://app.iminclusive.com/p/${profile?.slug}/`} style={[styles.sectionItemLight, { paddingBottom: 0, textDecoration: 'none' }]}>https://app.iminclusive.com/p/{profile?.slug}</Link>

                    <Text style={styles.sectionHeadingLight}>SKILLS & ABILITIES</Text>
                    <View style={styles.dividerDark} />
                    {profile?.person_skill.length && profile?.person_skill.map((skill) =>
                        <Text key={skill.id} style={styles.sectionItemLight}>• {skill.person_skill}</Text>
                    )}

                    {profile?.person_accomodation.length > 0 && (
                        <>
                            <Text style={styles.sectionHeadingLight}>ACCOMMODATIONS</Text>
                            <View style={styles.dividerDark} />
                            {profile?.person_accomodation.length && profile?.person_accomodation.map((accommodation) =>
                                <Text key={accommodation.id} style={styles.sectionItemLight}>• {accommodation.person_accomodation}</Text>
                            )}
                        </>
                    )}

                    {profile?.person_hobby.length > 0 && (
                        <>
                            <Text style={styles.sectionHeadingLight}>HOBBIES</Text>
                            <View style={styles.dividerDark} />
                            {profile?.person_hobby.length && profile?.person_hobby.map((hobby) =>
                                <Text key={hobby.id} style={styles.sectionItemLight}>• {hobby.person_hobby}</Text>
                            )}
                        </>
                    )}

                </View>

                <View style={[styles.section, { paddingLeft: 0, width: '60%', marginLeft: 20 }]}>

                    <Text style={[styles.sectionHeading, { paddingTop: 10 }]}>SUMMARY</Text>
                    <View style={styles.divider} />
                    <Text style={styles.sectionText}>{profile?.person_work_towards_project}</Text>

                    <Text style={styles.sectionHeading}>EXPERIENCE</Text>
                    <View style={styles.divider} />
                    {profile?.experience.length !== 0 && profile?.experience.map((work, index) =>
                        <View key={work.id} style={styles.sectionItem}>
                            <Text style={[styles.itemTitle, { paddingTop: index === 0 ? 0 : 5 }]}>{work.experience_title}</Text>
                            <Text style={styles.itemDate}>
                                ({work.experience_end_present ? (
                                    <span>
                                        {profile && format(new Date(work.experience_start), "MMM yyyy")} -
                                        Present
                                    </span>
                                ) : (
                                    <span>
                                        {profile && format(new Date(work.experience_start), "MMM yyyy")} -{" "}
                                        {profile && format(new Date(work.experience_end), "MMM yyyy")}
                                    </span>
                                )})
                            </Text>
                            <Text style={styles.itemText}>{work.experience_org}</Text>
                            <Text style={styles.itemText}>{work.experience_summary}</Text>
                        </View>
                    )}

                    <Text style={styles.sectionHeading}>EDUCATION</Text>
                    <View style={styles.divider} />
                    {profile?.achievement.length !== 0 && profile?.achievement.map((ach, index) =>
                        <View key={ach.id} style={styles.sectionItem}>
                            <Text style={[styles.itemTitle, { paddingTop: index === 0 ? 0 : 5 }]}>{ach.achievement_title}</Text>
                            <Text style={styles.itemDate}>({profile && format(new Date(ach.achievement_date), "MMM yyyy")})</Text>
                            <Text style={styles.itemText}>{ach.achievement_summary}</Text>
                        </View>
                    )}
                    {profile?.education.length !== 0 && profile?.education.map((edu, index) =>
                        <View key={edu.id} style={styles.sectionItem}>
                            <Text style={[styles.itemTitle, { paddingTop: (index === 0 && profile?.achievement.length === 0) ? 0 : 5 }]}>{edu.education_title}</Text>
                            <Text style={styles.itemDate}>
                                ({edu.education_end_present ? (
                                    <span>
                                        {profile && format(new Date(edu.education_start), "MMM yyyy")} -
                                        Present
                                    </span>
                                ) : (
                                    <span>
                                        {profile && format(new Date(edu.education_start), "MMM yyyy")} -{" "}
                                        {profile && format(new Date(edu.education_end), "MMM yyyy")}
                                    </span>
                                )})
                            </Text>
                            <Text style={styles.itemText}>{edu.education_org}</Text>
                            <Text style={styles.itemText}>{edu.education_summary}</Text>
                        </View>
                    )}

                    {/* {profile?.achievement.length > 0 && (
                        <>
                        <Text style={styles.sectionHeading}>ACHIEVEMENTS</Text>
                        <View style={styles.divider} />
                        {profile?.achievement.length && profile?.achievement.map((ach, index) =>
                            <View key={ach.id} style={styles.sectionItem}>
                                <Text style={[styles.itemTitle, { paddingTop: index === 0 ? 0 : 5 }]}>{ach.achievement_title}</Text>
                                <Text style={styles.itemDate}>({profile && format(new Date(ach.achievement_date), "MMM yyyy")})</Text>
                                <Text style={styles.itemText}>{ach.achievement_summary}</Text>
                            </View>
                        )}
                    </>
                    )} */}

                    {/* {profile?.project.length > 0 && (
                        <>
                        <Text style={styles.sectionHeading}>PORTFOLIO</Text>
                        <View style={styles.divider} />
                        {profile?.project.length && profile?.project.map((pro, index) =>
                            <View key={pro.id} style={styles.sectionItem}>
                                <Text style={[styles.itemTitle, { paddingTop: index === 0 ? 0 : 5 }]}>{pro.project_title}</Text>
                                <Text style={styles.itemDate}>
                                    ({profile && format(new Date(pro.start_date), "MMM yyyy")} -{" "}
                                    {profile && format(new Date(pro.end_date), "MMM yyyy")})
                                </Text>
                                <Text style={styles.itemText}>{pro.project_description}</Text>
                            </View>
                        )}
                    </>
                    )} */}

                </View>

            </Page>
        </Document>
    )
};

export default ProfilePdf;

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    section: {
        padding: 20,
        // width: '50%',
    },
    heading: {
        fontSize: 32,
        color: '#fff',
        paddingBottom: 5,
        fontFamily: 'Roboto-Black'
    },
    subHeading: {
        fontSize: 13,
        color: '#f4f4f4',
        paddingBottom: 5,
        fontFamily: 'Roboto-Medium',
        letterSpacing: 0.2,
        textTransform: 'uppercase'
    },
    sectionHeading: {
        fontSize: 12,
        fontFamily: 'Roboto-Bold',
        color: '#000',
        paddingTop: 30,
        letterSpacing: 1,
    },
    sectionHeadingLight: {
        fontSize: 12,
        fontFamily: 'Roboto-Bold',
        color: '#fff',
        paddingTop: 30,
        letterSpacing: 1,
    },
    sectionText: {
        paddingTop: 5,
        fontSize: 10,
        fontFamily: 'Roboto-Regular',
        color: '#333',
    },
    sectionItem: {
        paddingTop: 5,
        fontSize: 10,
        fontFamily: 'Roboto-Regular',
        color: '#333',
    },
    sectionItemLight: {
        paddingTop: 5,
        fontSize: 10,
        fontFamily: 'Roboto-Regular',
        color: '#f6f6f6',
    },
    divider: {
        paddingTop: 10,
        marginBottom: 5,
        width: 60,
        borderBottomWidth: 2,
        borderBottomColor: '#E4E4E4'
    },
    dividerDark: {
        paddingTop: 10,
        marginBottom: 5,
        width: 60,
        borderBottomWidth: 2,
        borderBottomColor: '#222'
    },
    itemTitle: {
        fontSize: 10,
        fontFamily: 'Roboto-Medium',
        color: '#111',
    },
    itemText: {
        paddingTop: 2,
        fontSize: 10,
        fontFamily: 'Roboto-Regular',
        color: '#333',
    },
    itemDate: {
        paddingTop: 2,
        fontSize: 10,
        fontFamily: 'Roboto-Regular',
        color: '#515151',
    }
});

Font.register({ family: 'Roboto-Regular', src: RobotoRegular });
Font.register({ family: 'Roboto-Medium', src: RobotoMedium });
Font.register({ family: 'Roboto-Bold', src: RobotoBold });
Font.register({ family: 'Roboto-Black', src: RobotoBlack }); 