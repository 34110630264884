import { getEvents } from '../../api';

const initialState = {
  eventsList: [],
  eventSearching: false,
};

export default (state = initialState, action) => {
  if (action.type === 'SET_EVENTS_LIST') {
    return {
      ...state,
      eventsList: action.eventsList,
    };
  }

  if (action.type === 'SET_EVENTS_SEARCHING') {
    return {
      ...state,
      eventsSearching: action.searching,
    };
  }
  return state;
};

const setEventsSearching = (searching) => ({
  type: 'SET_EVENTS_SEARCHING',
  searching,
});

const setEventsList = (eventsList) => ({
  type: 'SET_EVENTS_LIST',
  eventsList,
});

export const searchEvents = (filters) => (dispatch) => {
  dispatch(setEventsSearching(true));

  getEvents(filters)
    .then(({ data }) => {
      dispatch(setEventsSearching(false));
      dispatch(setEventsList(data.events));
    })
    .catch(() => {
      dispatch(setEventsSearching(false));
    });
};
