import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const PostShareOptions = ({ url, body }) => {
  const { t } = useTranslation();
  const direction = i18n.dir();

  return (
    <StyledPostedByTo>
      <Dropdown className={`share-dropdown d-flex ${direction === "rtl" ? "display-rtl" : ""}`}>
        <Dropdown.Toggle
          variant="outline-dark"
          id="dropdown"
          className="post-action"
          aria-label="Share post"
          style={{fontFamily: `${direction === "rtl" ? "Almarai" : ""}`}}
        >
          <i className="fas fa-share"></i> {t("Share")}
        </Dropdown.Toggle>

        <Dropdown.Menu className="p-0 m-0" style={{fontFamily: `${direction === "rtl" ? "Almarai" : ""}`}}>
          <Dropdown.Header
            style={{
              borderBottom: "1px solid #e9ecef",
              fontSize: "1.1rem",
              padding: "0.8rem",
            }}
          >
            {t("Share")}
          </Dropdown.Header>

          <FacebookShareButton
            className="dropdown-item"
            aria-label="Share to Facebook"
            url={url}
            quote={body}
            style={{ padding: "0.8rem", fontWeight: "400" }}
          >
            <i
              className="fab fa-facebook"
              style={{ fontSize: "1.2rem", paddingRight: `${direction === "rtl" ? "0" : "0.4rem"}`, paddingLeft: `${direction === "rtl" ? "0.4rem" : "0"}` }}
            ></i>
            {t("Facebook")}
          </FacebookShareButton>

          <TwitterShareButton
            className="dropdown-item"
            aria-label="Share to Twitter"
            url={url}
            title={body}
            style={{ padding: "0.8rem", fontWeight: "400" }}
          >
            <i
              className="fab fa-twitter"
              style={{ fontSize: "1.2rem", paddingRight: `${direction === "rtl" ? "0" : "0.4rem"}`, paddingLeft: `${direction === "rtl" ? "0.4rem" : "0"}` }}
            ></i>
            {t("Twitter")}
          </TwitterShareButton>

          <LinkedinShareButton
            className="dropdown-item"
            aria-label="Share to Linkedin"
            url={url}
            summary={body}
            style={{ padding: "0.8rem", fontWeight: "400" }}
          >
            <i
              className="fab fa-linkedin"
              style={{ fontSize: "1.2rem", paddingRight: `${direction === "rtl" ? "0" : "0.4rem"}`, paddingLeft: `${direction === "rtl" ? "0.4rem" : "0"}` }}
            ></i>
            {t("Linkedin")}
          </LinkedinShareButton>

          <EmailShareButton
            className="dropdown-item"
            aria-label="Share via Email"
            url={url}
            body={body}
            style={{ padding: "0.8rem", fontWeight: "400" }}
          >
            <i
              className="fas fa-envelope"
              style={{ fontSize: "1.2rem", paddingRight: `${direction === "rtl" ? "0" : "0.4rem"}`, paddingLeft: `${direction === "rtl" ? "0.4rem" : "0"}` }}
            ></i>
            {t("Email")}
          </EmailShareButton>

          <WhatsappShareButton
            className="dropdown-item"
            aria-label="Share to Whatsapp"
            url={url}
            title={body}
            style={{ padding: "0.8rem", fontWeight: "400" }}
          >
            <i
              className="fab fa-whatsapp"
              style={{ fontSize: "1.2rem", paddingRight: `${direction === "rtl" ? "0" : "0.4rem"}`, paddingLeft: `${direction === "rtl" ? "0.4rem" : "0"}` }}
            ></i>
            {t("Whatsapp")}
          </WhatsappShareButton>
        </Dropdown.Menu>
      </Dropdown>
    </StyledPostedByTo>
  );
};

export default PostShareOptions;

const StyledPostedByTo = styled.div`
  //padding: 20px 24px;
  font-family: "Inter", sans-serif;
  font-weight: bold;

  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #343a40;
    background: #ffffff;
    border-color: #ffffff;
  }

  a.dropdown-item:active {
    background-color: #ffffff;
    color: #212529;
  }

  .dropdown-item:hover {
    background-color: #f8f9fa !important;
  }

  @media(max-width: 576px) {
    .post-action {
      border-radius: 0 0 14px 0;
    }
  }
`;
