import React from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';
import AppContentContainer from '../../../common/AppContentContainer';
import Jobs from './Jobs';
import {getAvailableProfiles } from '../../../redux/user';

const AllJobs = ({activeProfile,getAvailableProfiles,isOwner})=>{


    const refreshProfile = () => {
        getAvailableProfiles();
      };

      
    
    return(
        <section className="justify-content-center d-flex flex-column flex-md-row">
              <Jobwrapper>
              {activeProfile && 
              <Jobs profile={activeProfile} isOwner={isOwner} refreshProfile={refreshProfile} isAll />
              }
              </Jobwrapper>

        </section>
    )
}



export default connect(
    state => ({
      activeProfile: state.user.activeProfile,
    }),{
        getAvailableProfiles,
      }
  )(AllJobs);

const Jobwrapper= styled.div`
font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
width:100%;

.organizationCard{
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 14px;
    padding: 24px;
    font-size: 14px;
    line-height: 141.5%;
    color: #000000;
  
  }
  .cardHeading{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  .outerJobWrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.jobs-wrapper .job-img {
  width: 91px;
  height: 91px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.RedColr{
  color: #263238 !important;
  font-weight:700;
  font-size: 18px;
}
.jobcompany{
  padding-top: 3px;
 font-size: 16px;
   /* line-height: 19px; */
   color: #000000;
}
.jobtitle{
 font-weight: bold;
   font-size: 18px;
   line-height: 22px;
   color: #000000;
}
.jobpostedDate{
  padding-top: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #A4A4A4;
}
.joblocation{
  padding-top: 5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.jobs-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:50%;
}
.jobs-wrapper{
padding: 21px 0px;
}

  

`