import React, { useEffect, useState, useMemo, useRef } from "react";
import { NavLink as Link, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import SVG from "react-inlinesvg";
import { getOrganizationJobs, deleteOrganizationJob } from "../../../../api";
import { processDate } from "../../../../common/utils";

import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { useCustomersUIContext } from "./CustomersUIContext";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";

const AllJobs = ({ className, profile }) => {
  const customersUIContext = useCustomersUIContext();
  const [del, setDel] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  const customersUIEvents = {};

  // const [isAdding, setIsAdding] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobloading, setjobloading] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 1,
  });

  const getJobsList = (page = pagination.current_page, filters) => {
    if (page === 1) {
      setjobloading(true);
    }

    getOrganizationJobs(profile.slug, filters, page)
      .then(({ data }) => {
        const jobss = data.jobs.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        //setPagination(data.pagination);
        setJobs(jobss);
        setjobloading(false);
      })
      .catch(() => {
        setIsSearching(false);
      });
  };

  // const getjobs=()=>{
  //   setjobloading(true)
  //   getOrganizationJobs(profile.slug)
  //   .then(({ data }) => {
  //     const jobss = data.jobs.sort(function(a,b){
  //       return new Date(b.created_at) - new Date(a.created_at);
  //     });

  //     setJobs(jobss);
  //     setjobloading(false)

  //   });
  // }

  const handleJobDel = (ids) => {
    const job = [...jobs];
    for (var i = 0; i < ids.length; i++) {
      var index = job.findIndex((x) => x.id === ids[i]);
      onJobDelete(job[index].slug);
      job.splice(index, 1);
    }
    setJobs(job);
  };

  const onJobDelete = (slug) => {
    deleteOrganizationJob(slug)
      .then(() => {
        //history.push(`/profile`);
        // setDeleteLoading(false)
      })
      .catch(({ data: errors }) => {
        //setDeleteLoading(false);
      });
  };

  useEffect(() => {
    getJobsList(1, {
      q: null,
      cat: null,
      city: null,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`card card-custom gutter-b`}>
      {/* Head */}
      <div className="card-header align-items-center">
        <h3 className="card-title align-items-center d-flex">
          <span className="card-label font-weight-bolder text-dark mr-0">Opportunities</span>
          <Button className="help-button" ref={tooltipRef} onClick={() => setShowTooltip(!showTooltip)} aria-label="Opportunities Dashboard Tooltip" aria-pressed={showTooltip}>
            <i className="material-icons help-icon">help_outline</i>
          </Button>
        </h3>
        <Overlay target={tooltipRef.current} show={showTooltip} placement="bottom" rootClose={true} onHide={() => setShowTooltip(false)} aria-describedby={showTooltip ? "overlay-tooltip" : ""}>
          {(props) => (
            <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showTooltip ? "polite" : "off"} {...props}>
              Welcome to the ImInclusive Organization dashboard, here you have access to the employer hiring tool where you can post new jobs, view the full listing of applicants, shortlist applicants, extract their contact number and email and view their profiles for each job application.
            </Tooltip>
          )}
        </Overlay>
        <div className="card-toolbar">
          {/* <a href="#" className="btn btn-info font-weight-bolder font-size-sm mr-3">New Report</a> */}
          <Link to={"/org/addjob"} className="font-weight-bolder font-size-sm">
            <Button variant="outline-primary">Post Job</Button>
          </Link>
        </div>
      </div>
      <div className="card-body pb-3">
        <CustomersFilter
          onApplyFilters={(filters) => getJobsList(1, filters)}
        />
        {customersUIProps.ids.length > 0 && (
          <CustomersGrouping handleJobDel={handleJobDel} />
        )}
        {jobs && <CustomersTable jobs={jobs} />}
      </div>

      {/* Body */}
      {/* <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75">Title</span></th>
                  <th style={{minWidth: "100px"}}>Location</th> 
                  <th style={{minWidth: "100px"}}>Closing Date</th>           
                  <th style={{minWidth: "100px"}}>Company</th>
                  <th style={{minWidth: "130px"}}>Applicants</th>
                  <th style={{minWidth: "80px"}}/>
                </tr>
                </thead>
                <tbody>
                    {jobs && jobs.length?jobs && jobs.map((item,index)=>(
                <JobItems job={item}/>
      )) : <h3 className="pb-2 text-red pt-4">{'You have not added any job yet'}</h3>}

    
            </tbody>
              </table>
            </div>
          </div>
        </div>
     
      */}
    </div>
  );
};

export default AllJobs;

const JobItems = ({ job }) => {
  const history = useHistory();
  const handleManageJob = (slug, id) => {
    history.push(`/org/jobdetail/${slug}/${id}`);
  };
  return (
    <tr>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div className="mr-4">
            <span className="symbol-label">
              <img src={job.job_organization_logo} height={50} width={50} />
            </span>
          </div>
          <div>
            <span className="text-dark-75 font-weight-bolder  mb-1 font-size-lg">
              {job.job_title}
            </span>
            {/* <span className="text-muted font-weight-bold d-block">HTML, JS, ReactJS</span> */}
          </div>
        </div>
      </td>
      <td>
        <span className="text-muted font-weight-bold">
          {job.job_city && job.job_city.city_name}
        </span>
      </td>
      <td>
        <span className="text-muted font-weight-bold">
          {processDate(job.job_close_time, "PP")}
        </span>
      </td>
      <td>
        {/* <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          $520
        </span> */}
        <span className="text-muted font-weight-bold">
          {job.job_organization}
        </span>
      </td>
      <td>
        <span className="text-muted font-weight-bold">1</span>
      </td>
      <td className="pr-0 text-right">
        <span
          onClick={() => handleManageJob(job.slug, job.id)}
          className="btn btn-light-success font-weight-bolder font-size-sm"
        >
          View Job
        </span>
      </td>
    </tr>
  );
};
