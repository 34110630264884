import React from "react";
import { connect } from "react-redux";

import OrganizationalProfile from "../OrganizationProfile";
import PersonProfile from "../PersonProfile";
import { setActiveProfile, getAvailableProfiles } from "../../redux/user";
import { Mixpanel } from '../../mixpanel';

//Renders Person or Organization profile depending on which profile is active.

const Profile = ({
  availableProfiles,
  activeProfile,
  activeProfileType,
  setActiveProfile,
  getAvailableProfiles,
  activeProfileMeta,
}) => {
  if (!activeProfile) return null;

  if (activeProfileMeta) {
    if (activeProfileMeta.type === "org") {
      return <OrganizationalProfile />;
    } else {
      return <PersonProfile />;
    }
  }
  Mixpanel.track("OwnPersonProfileView");

};

export default connect(
  (state) => ({
    availableProfiles: state.user.availableProfiles,
    activeProfile: state.user.activeProfile,
    activeProfileType: state.user.activeProfileType,
    activeProfileMeta: state.user.activeProfileMeta,
  }),
  {
    setActiveProfile,
    getAvailableProfiles,
  }
)(Profile);
