import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addToast } from "../../../redux/toast";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Switch from "@material-ui/core/Switch";
import { getEmailNotifications, updateEmailNotifications } from '../../../api';


const NotificationsTab = ({ profile, addToast }) => {
    const [ subscribed, setSubscribed] = useState(false);
    const [loadingSubscription, setLoadingSubscription ] = useState(false);

    //Get the current value (true or false) of email_notifications and store it in subscribed. 
    useEffect(() => {
        let unmounted = false;
        setLoadingSubscription(true);
        if (profile) {
          getEmailNotifications(profile.owner_id)
          .then(({ data }) => { 
            if (!unmounted) {          
                setSubscribed(data.email_notifications);
                setLoadingSubscription(false);
            }
          })
          .catch((error) => {
            setLoadingSubscription(false);
          });
        }
        return () => { unmounted = true }; 
      }, [profile]);

    
    //OnChange of toggle state, call API and update email_notifications with the opposite of subscribed. (true<-->false)
    const handleEmailNotification = () => {
        updateEmailNotifications(profile.owner_id, { owner: profile.owner_id, email_notifications: !subscribed})
        .then((res) => {
            setSubscribed(res.data.email_notifications);
              addToast({
                title: "Success",
                text: `Email notifications ${subscribed ? "unsubscribed" : "subscribed"} successfully.`,
                type: "success",
              });
            })
            .catch((res) => {
              addToast({
                type: "error",
                title: "Error",
                text: "An error occurred, please try again.",
              });
            });
        };

    return (
        <StyledNotificationCard className="card">
           <div className="d-flex align-items-center cardHeader pb-3" >
                <h2 className="pb-0 mb-0 cardHeading">Notifications</h2>
            </div>       
            <div className="row pt-3">
                <div className="col-md-12">
                    <Row className="d-flex align-items-center py-3">
                        <Col md={8} xs={10}>
                            <div className="emailHeading">Email Notifications</div>
                            <div>Receive email updates.</div>
                        </Col>
                        <Col md={4} xs={2} className="d-flex justify-content-end">
                            <Switch
                                checked={subscribed}
                                onChange={handleEmailNotification}
                                value={subscribed}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Col>
                    </Row>               
                </div>
            </div>   
        </StyledNotificationCard>
    );
}

export default connect(() => ({}), {
    addToast,
  })(NotificationsTab);


const StyledNotificationCard = styled.div`
border-radius: 0.4rem;
box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
border: 0;
padding: 25px 45px;

.cardHeading {
    font-weight: bold;
    font-size: 26px;
    line-height: 24px;
    color: #000000; 
}
.cardHeader {
    border-bottom: solid 1px #dadbe2; 
}
h4.bodyHeading {
    font-size: 1.1rem !important;
    font-weight: 600;
    color: #000000;
}
.emailHeading {
    font-size: 1.1rem !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #000000;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #510d52 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #3F0E40 !important;
}

@media(max-width: 576px){
    padding: 20px;
.d-flex.justify-content-end.col-md-4.col-2 {
    padding-right: 5px;
 }   
}

`