import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { arSA } from 'date-fns/locale'

export const getTimeDuration = (start, end) => {
  let duration = '';

  duration += format(new Date(start), 'MMMM, yyyy');
  duration += ` - ${format(new Date(end), 'MMMM, yyyy')}`;

  return duration;
};

export const getTimeDurationPresent = (start) => {
  let duration = '';

  duration += format(new Date(start), 'MMMM, yyyy');
  duration += ' - Present';

  return duration;
};

export const getFormattedDate = (date) => format(new Date(date), 'd MMMM, yyyy');
export const getTimeAgo = (date) => `${formatDistanceToNow(new Date(date), new Date())} ago`;
export const getTimeAgoArabic = (date) => `${formatDistanceToNow(new Date(date), {locale: arSA, addSuffix: true})}`;
export const getTimeAgoArabicShort = (date) => `${formatDistanceToNow(new Date(date), {locale: arSA})}`;
export const processDate=(time,type) => format(parseISO(time),type);
export const processDateArabic=(time,type) => format(parseISO(time),type, {locale: arSA});
export const capitalizeString = (str) => str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1)})
export const capitalizeFirstLetter = (str) =>  str.charAt(0).toUpperCase() + str.slice(1)