import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import SignUp from './SignUp';
import Login from './Login';
import EmailVerification from '../../components/EmailVerification';
import ResetPassword from '../../components/ResetPassword';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/signup/:type?`} component={SignUp} />
      <Route path={`${path}/login`} component={Login} />
      <Route path={`${path}/registration/account-confirm-email/:key`} exact component={EmailVerification} />
      <Route path={`${path}/password/reset/confirm/:uid?/:token?`} exact component={ResetPassword} />

      <Route path="*" render={() => <Redirect to="/auth/login" />} />
    </Switch>
  );
};
