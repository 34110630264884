import React, { useState, useRef, useEffect } from 'react';
import { MentionsInput, Mention } from 'react-mentions'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { searchProfile } from '../api';

//Text Area that renders user suggestions on @ trigger.
const MentionTextarea = ({
  //handleValueChange,
  textareaRef,
  value,
  setValue,
  isComment,
  settValue,
  setMention,
  handleSubmit,
  setShowEmoji
}) => {
  const { t } = useTranslation()
  const direction = i18n.dir()
  //const textareaRef = useRef();
  //const [value,setValue]= useState("")
  const [Pvalue, setPValue] = useState("")

  //const [mention,setMention]= useState([])
  //const [showEmoji,setShowEmoji]= useState(false)
  const [showtext, setShowtext] = useState(false)
  const width = window.innerWidth
  const maxChar = width < 440 ? 30 : 60
  const [longComment, setLongComment] = useState(false)

  const loader = '<div>loading</div>'

  useEffect(() => {
    // textareaRef.current.placeholder="Share a story, the latest news on inclusion of POD or initiate a positive energy exchange..."
    if (!isComment) {
      textareaRef.current.placeholder = `${t("Express your views, share a story or post the latest news on inclusion of People of Determination...")}`
      const classes = textareaRef.current.className
      textareaRef.current.className = classes + "  customTextArea" + ` ${direction === "rtl" ? "rtl-textarea" : ""}`
    }
    else {
      textareaRef.current.placeholder = `${width > 385 ? `${t("Share your thoughts on this...")}`:`${t("Share your thoughts...")}`}`
      const classes = textareaRef.current.className
      textareaRef.current.className = classes + " comment form-control"
      textareaRef.current.addEventListener('keydown', handleEnter)
    }
    
    return () => textareaRef.current.removeEventListener('keydown', handleEnter);
  }, [])


  const handleEnter = (evt) => {

    if (evt.keyCode == 13 && !evt.shiftKey) {
      if (textareaRef.current.contains(evt.target)) {

        evt.preventDefault()
        handleSubmit()
        setLongComment(false)
      }
    }
  }


  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    !isComment ? setShowEmoji(false) : ""

    isComment && newPlainTextValue && newPlainTextValue.length >= maxChar ? setLongComment(true) : setLongComment(false)

    setMention(mentions)
    //setMention(mentions)
    setPValue(newPlainTextValue)
    settValue(newPlainTextValue)

    setValue(event.target.value)
  }


  //Using this function to display dp's along with user names.
  const renderUserSuggestion = (entry, search, highlightedDisplay, index, focused) => {

    return <div> <img className="imgDP mr-2" src={`https://inclusive-storage.s3.amazonaws.com/media/public/${entry.dp}`} height="30" width="30" />{entry.display} </div>

  }


  const userData = (search, callback) => {

    if (search !== "")
      searchProfile({ q: search })
        .then(({ data: { profiles } }) => {
          const prof = JSON.parse(profiles)

          //getting data from searchProfile API depending on search and slicing the array to get the first 5.
          const searc = prof.slice(0, 5).map(item => {

            if (item.model === "profilepage.personprofile") {
              return { id: item.fields.get_owner_id, display: `${item.fields.person_first_name} ${item.fields.person_last_name}`, dp: item.fields.person_display_picture }
            }
            else {

              return { id: item.fields.get_owner_id, display: item.fields.organization_title, dp: item.fields.organization_logo }
            }
          })

          if (searc.length)
            callback(searc)
          else
            callback()


        })
        .catch((error) => {

        });



  }



  return (
    <>

      <MentionsInput inputRef={textareaRef} className={`${!isComment ? "mentionedTextarea" : "commetTextArea"} ${longComment ? "long-comment" : ""}`} aria-label={`${!isComment ? "Impact Board Post" : "Comment"}`} value={value} onChange={handleChange}>
        <Mention
          trigger="@"
          data={userData}
          //onAdd={onAddedMention}
          className={`${!isComment ? "MentionedInput" : "commetInput"}`}
          //markup="[__display__]"
          //  style={{:"blue"}}
          //displayTransform={handleReturn}
          renderSuggestion={renderUserSuggestion}
          appendSpaceOnAdd
        />
      </MentionsInput>

    </>
  )




}

export default MentionTextarea