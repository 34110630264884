import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { addToast } from "../../../redux/toast";
import { logoutUser } from "../../../redux/user";
import Button from "react-bootstrap/Button";

import { deleteProfile, deleteOrganization } from "../../../api";

const AccountDeleteTab = ({ activeProfile, addToast, logoutUser }) => {
  const [show, setShow] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const profile = (activeProfile && "organization_title" in activeProfile) ? 'org' : 'individual';
  const accountTitle = profile === 'individual' ? 'account' : 'organization profile';
  const buttonTitle = profile === 'individual' ? 'Delete My Account' : 'Delete My Profile';

  const showModal = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };

  const availableProfiles = useSelector(
    (state) => state.user.availableProfiles
  );

  const onAccountDelete = () => {
    setDeleteLoading(true);
    // check if active profile is organization profile
    const isOrg = "organization_title" in activeProfile;
    if (isOrg) {
      deleteOrganization(activeProfile.slug)
        .then(() => {
          setDeleteLoading(false);
          addToast({
            type: "success",
            title: "Success",
            text: "Organization Profile deleted.",
          });
          logoutUser();
          return <Redirect to="/" />;
        })
        .catch(({ data: errors }) => {
          setDeleteLoading(false);
          setDeleteLoading(false);
          if (errors) {
            addToast({
              type: "error",
              title: "Error",
              text: Object.values(errors)[0][0],
            });
          }
        });
    } else {
      //Check if there is no Org profile, before deleting User Account.
      if (availableProfiles.org.length === 0) {
        deleteProfile(activeProfile.id)
          .then(() => {
            setDeleteLoading(false);
            addToast({
              type: "success",
              title: "Success",
              text: "User Account successfully deleted.",
            });
            logoutUser();
            return <Redirect to="/" />;
          })
          .catch(({ data: errors }) => {
            setSubmitting(false);
            setDeleteLoading(false);
            if (errors) {
              addToast({
                type: "error",
                title: "Error",
                text: Object.values(errors)[0][0],
              });
            }
          });
      } else {
        addToast({
          type: "error",
          title: "Error",
          text: "Please delete Organization Profile first.",
        });
        setDeleteLoading(false);
        onClose();
      }
    }
  };

  return (
    <>
      <div className="card delete-account-card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12  text-center">
              <h4 style={{ fontSize: "1.1rem", fontWeight: "600", color: '#000000' }}>
                Are you sure you want to delete your {accountTitle}?
              </h4>{" "}
              <Button
                onClick={showModal}
                className="delete-account-btn">
                {buttonTitle}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} centered size="md" onHide={onClose}>
        <Modal.Header style={{ fontSize: '20px', fontWeight: '500' }}>{buttonTitle}</Modal.Header>
        <Modal.Body className="py-4">
          <h4 style={{ fontSize: "1rem", marginBottom: '1rem' }}>
            Deleting your {accountTitle} is permanent. Keep in mind that you will not be able to reactivate your {accountTitle} or retrieve any of the content or information you have added.
          </h4>
          <h4 style={{ fontSize: "1rem", marginBottom: 0 }}>If you would still like your {accountTitle} deleted, click '{buttonTitle}'.</h4>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <Button
                  onClick={onClose}
                  block
                  className="modal-cancel-button"
                >
                  Cancel
                </Button>
              </Col>
              <Col className="mt-3 mt-md-0" sm={12} md={6}>
                <Button
                  onClick={onAccountDelete}
                  block
                  className="modal-submit-button"
                >
                  {deleteLoading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                      buttonTitle
                    )}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  { addToast, logoutUser }
)(AccountDeleteTab);
