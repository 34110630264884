import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import i18n from 'i18next';



const AppContentContainer = ({
  children,
  classes,
}) => {
  
  const direction = i18n.dir();
  const containerRef = useRef();

  useEffect(() => {
    const classes = containerRef.current.className
    containerRef.current.className = classes + ` ${direction === "rtl" ? "arabic-display" : ""}`
  
  },[direction]);

  return <StyledAppContentContainer className={classes} ref={containerRef}>{children}</StyledAppContentContainer>;
} 

export default AppContentContainer;

const StyledAppContentContainer = styled.main`
  // padding: 50px 45px 0;
  padding: 85px 45px 0;
  width: 100%;
  position: relative;
  white-space: pre-line;
  min-height:78vh;

  @media (max-width: 768px) {
    // padding-left: 15px;
    // padding-right: 15px;
    padding:35px 15px
  }

  .ic-app-content-left {
    width: 33%;
  }

  @media (max-width: 767.98px) {
    .ic-app-content-left {
      width: 100%;
    }
  }

  .ic-app-content-left aside .ic-app-content-left-items > div {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 0.4rem;
    border: 0px;
  }

  .ic-search {
    border-bottom: 2px solid #dadbe2 !important;
}

  .ic-app-content-left aside .ic-app-content-left-items > div:not(:nth-child(1)) {
    border-top-width: 0px;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar {
    position: relative;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar .ic-search-wrapper ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar .ic-search-wrapper ul li a {
    padding: 0;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar .ic-search-wrapper ul li a img {
    max-width: 32px;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar .ic-search-wrapper ul li h2 {
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin: 0;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar .ic-search-wrapper ul li .dropdown-menu {
    width: 100%;
    -webkit-transform: translate3d(12px, 35px, 0px) !important;
    transform: translate3d(12px, 35px, 0px) !important;
    padding: 10px;
    border-color: rgba(237, 234, 246, 0.5);
    box-shadow: 2px 3px 0px 0px rgba(43, 43, 43, 0.2);
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar .ic-search-wrapper ul li .dropdown-menu form input {
    width: 100%;
    background-color: #f8f7fc;
    border: solid transparent;
    padding: 12px;
    border-radius: 25px;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-search .ic-search-sidebar .ic-search-wrapper ul li .dropdown-menu:after {
    width: 0;
    height: 0;
    position: absolute;
    top: 0%;
    content: '';
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(43, 43, 43, 0.5);
    right: 12px;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item:not(:first-child):after {
    position: absolute;
    content: '';
    border: solid 1px #dadbe2;
    width: 90%;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item:first-child {
    padding-top: 6px;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item {
    padding: 26px 0;
    position: relative;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-date {
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: #dadbe2;
  }

  @media (max-width: 991px) {
    .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-date {
      border: none;
    }
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-date .date-month {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 0;
    letter-spacing: 0.1px;
    padding-top: 15px;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-date .date-day {
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: 0.5px;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-content .event-title h3 {
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-content .event-date p {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    color: #a6a7ab;
    margin-bottom: 0;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-content .event-location {
    padding: 0;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .events-content .event-location p {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    color: #a6a7ab;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .event-attend-btn {
    font-size: 22.5px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .event-attend-btn span {
    vertical-align: middle;
    line-height: 1em;
  }

  .ic-app-content-left aside .ic-app-content-left-items .ic-events .ic-events-sidebar .ic-events-item .event-attend-btn span .fas {
    font-size: 1.2em;
  }

  .ic-app-content-right {
    width: 67%;
  }

  @media (max-width: 767.98px) {
    .ic-app-content-right {
      width: 100%;
    }
  }

  .ic-app-content-right .display-aside-ltr {
    margin-left: 30px;
  }

  .ic-app-content-right .display-aside-rtl {
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    .ic-app-content-right .display-aside-ltr {
      margin-left: 0;
    }
    .ic-app-content-right .display-aside-rtl {
      margin-right: 0;
    }
  }

  .ic-app-content-right aside .ic-app-content-right-items > div {
    background-color: #ffffff;
    margin-bottom: 30px;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 14px;
    border: 0px;
  }

  .ic-app-content--wrapper {
    margin-top: -15px;
  }

  .ic-app-content--wrapper .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin: 0;
    vertical-align: middle;
  }

  .ic-app-content--wrapper .breadcrumb .breadcrumb-back {
    padding: 15px 0 20px;
  }

  .ic-app-content--wrapper .breadcrumb .breadcrumb-back .back-btn a img {
    max-width: 36px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .ic-app-content--wrapper .breadcrumb .breadcrumb-back .bradcrumb-page-title {
    margin-left: 15px;
  }

  .ic-app-content--wrapper .breadcrumb .breadcrumb-back .bradcrumb-page-title h1 {
    font-size: 1rem;
  }

  .ic-app-content--wrapper .ic-app-page-body {
    background-color: #ffffff;
    padding: 20px 35px 60px;
    border: solid 1.9px #dadbe2;
  }

  .ic-app-content-left--wrapper {
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
    border-radius: 16px;
    border: 0px;
  }

  .ic-app-content-left--wrapper > div {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    @media (max-width: 990px) and (min-width: 768px){
      padding: 14px;
    }
  }

  .ic-app-content-left--wrapper > div:not(:nth-child(1)) {
    border-top-width: 0px;
    border-radius: 0 0 16px 16px;
  }

  .ic-app-content-left--wrapper .ic-search .ic-search-sidebar--wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .ic-app-content-left--wrapper .ic-search .ic-search-sidebar--wrapper ul li h2 {
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
  }

  .ic-app-content-left--wrapper .ic-page-search-sidebar .ic-default-sidebar-form {
    margin: 0;
  }

  .ic-app-content-left--wrapper .ic-page-search-sidebar .ic-default-sidebar-form form input {
    background: #F8F8F8;
    box-shadow: inset 0px 4px 10px rgba(0,0,0,0.08);
    border-radius: 32px;
    border: none;
  }

  @media (max-width: 768px) {
    .ic-app-content-right, .ic-app-content-left {
      margin-top: 30px;
    }
  }
  @media (max-width: 768px) {
    .ic-app-content-left.display-ltr {
      margin-right: 16px;
    }
    .ic-app-content-left.display-rtl {
      margin-left: 16px;
    }
  }

  .ic-app-content-right--wrapper > div {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
    border-radius: 0.4rem;
    border: 0px;
    overflow: hidden;
  }

  .ic-app-content-right--wrapper > div:not(:nth-child(1)) {
    border-top-width: 0px;
  }


  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-header {
    border-bottom: 2px solid #dadbe2;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-header--wrapper {
    padding: 20px;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-header--wrapper .results-header-title h2 {
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body {
    margin-top: 12px;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper {
    width: 100%;
    padding: 20px;
    @media (max-width: 990px) and (min-width: 768px){
      padding: 14px;
    }
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item:not(:first-child) {
    border-top: solid 2px #dadbe2;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item:first-child {
    padding-top: 0;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item {
    padding: 25px 0;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-date {
    width: 15%;
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: #dadbe2;
  }

  @media (max-width: 991px) {
    .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-date {
      border: none;
    }
  }

  @media (max-width: 480px) {
    .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-date {
      display: none !important;
    }
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-date .date-month {
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 0;
    padding-top: 15px;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-date .date-day {
    font-size: 40px;
    font-weight: bolder;
    line-height: 1.5em;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content {
    margin-left: 15px;
    width: 85%;
    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 576px) {
    .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content {
      margin-left: 25px;
    }
  }

  @media (max-width: 480px) {
    .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content {
      margin-left: 0;
      width: 95%;
    }
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content .page-title {
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content .page-date p {
    margin-bottom: 0;
    color: #a6a7ab;
    padding: 0.3rem 0;
  }

  @media (max-width: 480px) {
    .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content .page-date p .mobile-event-date {
      display: inline-block !important;
    }
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content .page-location {
    padding: 0;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .pages-content .page-location p {
    margin-bottom: 0;
    color: #a6a7ab;
    line-height: 1;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .page-item-option {
    > a {
      color: #B11030;
    }
    position: relative;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .page-item-option a {
    font-size: 2em;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .page-item-option .dropdown-menu {
    -webkit-transform: translate3d(-134px, 45px, 0px) !important;
    transform: translate3d(-134px, 45px, 0px) !important;
    border-color: rgba(237, 234, 246, 0.5);
    box-shadow: 2px 3px 0px 0px rgba(43, 43, 43, 0.2);
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .page-item-option .dropdown-menu .dropdown-item {
    font-size: .8em;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .pages-results-item .page-item-option .dropdown-menu:after {
    width: 0;
    height: 0;
    position: absolute;
    top: 0%;
    content: '';
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(43, 43, 43, 0.5);
    right: 12px;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .job-result-item .pages-content {
    margin-left: 0;
    width: 98%;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .job-result-item .pages-content .page-content {
    padding: 0;
  }

  .ic-app-content-right--wrapper .ic-page-search-results .ic-pages-search-results-body--wrapper .job-result-item .pages-content .page-content p {
    margin-bottom: 0;
    color: #a6a7ab;
`;
