import React, { useState, useRef, useEffect } from "react";
import { NavLink as Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from 'react-bootstrap/Modal';
import ImInclusive from '../../assets/images/ImInclusiveLogo.png';
import LoginImage from '../../assets/images/LoginSignupImage.png';
import { format } from 'date-fns';

import Form from "../../common/Form";
import OnboardingContainer, {
    OnboardingHeader,
    AuthFooter,
    AuthMobileFooter,
} from "../../common/Onboarding";
import Button from "../../components/Button";

import { loginUser } from "../../redux/user";
import { addToast } from "../../redux/toast";

const Login = ({ loginUser, addToast }) => {
    const history = useHistory();
    const location = useLocation();
    const unmounted = useRef(false);
    const errorFieldEmail = useRef(null);
    const errorFieldPassword = useRef(null);
    const width = window.innerWidth;
    const [show, setShow] = useState(width < 480 ? true : false);

    let { from } = location.state || { from: { pathname: "/" } };

    const loginSchema = yup.object().shape({
        email: yup.string().email("Enter a valid email.").required("Your email is required."),
        password: yup.string().required("Your password is required."),
    });

    const [passwordType1, setPasswordType1] = useState("password");

    const handlePasswordShow1 = () => {
        passwordType1 === "text"
            ? setPasswordType1("password")
            : setPasswordType1("text");
    };

    useEffect(() => {
        return () => { unmounted.current = true }
    }, []);

    // `onLogin` function definition.
    // It takes two parameters - `values` and a second argument with `setSubmitting` method.
    // `values` are the data submitted by the user, email and password.
    // `setSubmitting` is a function that can be called to signal that the form submission is done (or failed).

    const onLogin = (values, { setSubmitting }) => {
        loginUser(values)
            .then(() => {
                if (!unmounted.current) {
                    history.replace(from);
                    setSubmitting(false);
                }
            })
            .catch(({ data: errors }) => {
                if (!unmounted.current) {
                    setSubmitting(false);
                }
                if (errors) {
                    addToast({
                        type: "error",
                        title: "Error",
                        text:
                            Object.values(errors)[0][0] === "E-mail is not verified."
                                ? "We sent you an email to confirm your account. Please check your email and click the link to confirm your account."
                                : Object.values(errors)[0][0],
                    });
                }
            });
    };

    return (
        <>
            <OnboardingContainer classes="ic-login-container">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-sm-12 col-md-6 ic-container-left p-0">
                            <div className="ic-image">
                                <img src={LoginImage} alt="A graphic showing three people with different abilities." />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 ic-container-right">
                            <header className="ic-header">
                                <div className="ic-logo">
                                    <img src={ImInclusive} style={{ width: 200 }} alt="Inclusive Logo"/>
                                </div>
                            </header>
                            <aside>
                                <div className="ic-login-wrapper" aria-live="assertive">
                                    <h1 role="heading" className="pt-3 main-heading">Welcome back</h1>
                                    <h2 role="sub-heading" className="pb-3 sub-heading">Log in to your account.</h2>
                                    <div className="ic-form-wrapper">
                                        <Formik
                                            validationSchema={loginSchema}
                                            initialValues={{
                                                email: "",
                                                password: "",
                                            }}
                                            onSubmit={onLogin}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                errors,
                                                touched,
                                                isSubmitting,
                                            }) => (
                                                <Form onSubmit={handleSubmit} noValidate>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="emailAddress">
                                                            Email Address
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            id="emailAddress"
                                                            placeholder="Enter your email"
                                                            value={values.email}
                                                            isInvalid={touched.email && errors.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            aria-required="true"
                                                            aria-invalid={touched.email && errors.email ? true : false}
                                                            aria-describedby={touched.email && errors.email ? "email-error" : ""}
                                                            ref={errorFieldEmail}
                                                        />

                                                        <Form.Control.Feedback type="invalid" id="email-error" aria-live="polite">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group style={{ position: 'relative' }}>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                        <Form.Label htmlFor="password" style={{ width: 'auto' }}>
                                                            Password
                                                        </Form.Label>
                                                        <Link to="/auth/password/reset/confirm">
                                                            <Form.Label className="forgot-text">Forgot password?</Form.Label>
                                                        </Link>
                                                        </div>
                                                        <Form.Control
                                                            type={passwordType1}
                                                            name="password"
                                                            id="password"
                                                            value={values.password}
                                                            placeholder="Enter your password"
                                                            isInvalid={touched.password && errors.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            aria-required="true"
                                                            aria-invalid={touched.password && errors.password ? true : false}
                                                            aria-describedby={touched.password && errors.password ? "password-error" : ""}
                                                            ref={errorFieldPassword}
                                                            style={{ paddingRight: '2.5rem' }}
                                                        />
                                                        <span className="password__show" role="button" tabIndex="0" aria-pressed={passwordType1 === "text" ? true : false} onClick={handlePasswordShow1} onKeyUp={(event) => event.key === "Enter" ? handlePasswordShow1() : ""}>
                                                            {passwordType1 === "text" ? <span className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility</span> : <span className="material-icons" style={{ fontSize: '28px', color: '#5F5F5F' }}>visibility_off</span>}
                                                        </span>
                                                        <Form.Control.Feedback type="invalid" id="password-error" aria-live="polite">
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Button
                                                            className="login-button"
                                                            loading={isSubmitting}
                                                            submit
                                                            aria-label="Submit"
                                                            type="submit"
                                                            onClick={() => touched.email && errors.email ? errorFieldEmail.current.focus() : touched.password && errors.password ? errorFieldPassword.current.focus() : {}}
                                                        >
                                                            Login
                                                        </Button>
                                                        <p className="ic-form-badge">
                                                            <span className="text-lightgray">
                                                                Don't have an account?
                                                            </span>
                                                            &nbsp;
                                                            <Link className="sign-up-text" to="/auth/signup">
                                                                <strong>Sign up</strong>
                                                            </Link>
                                                        </p>
                                                    </Form.Group>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                                <div className="footer-container pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <span className="footer-link">
                                            <Link to="/privacypolicy" rel="nofollow">
                                                Privacy Policy
                                            </Link>
                                        </span>
                                        <span className="footer-link px-2">•</span>
                                        <span className="footer-link">
                                            <Link to="/termsandconditions" rel="nofollow">
                                                Terms and Conditions
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center pt-2">
                                        <span className="copyright-text">© {format(new Date(), "yyyy")} ImInclusive</span>
                                    </div>
                                </div>
                            </aside>
                        </div>

                    </div>
                </div>
            </OnboardingContainer>

            <Modal show={show} onHide={() => setShow(false)} aria-labelledby="Open App Modal" dialogClassName="open-app-modal">
                <Modal.Body>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="material-icons mr-1 open-app-icon">smartphone</i>
                        <h3 className="open-app-heading">Inclusive works better if you switch to our app</h3>
                    </div>
                    <div className="open-app-link" onClick={() => window.open('https://inclusiveapp.page.link/openapp', "_self")}>
                        Open in App
                    </div>
                    <Button className="open-app-text" onClick={() => setShow(false)}>Continue with mobile web</Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default connect(null, {
    loginUser,
    addToast,
})(Login);
