/* Pagination Helprs */
import React from "react";
import SVG from "react-inlinesvg";

import sort from '../../assets/svg/Sort1.svg'
import up from '../../assets/svg/Up-2.svg'
import down from '../../assets/svg/Down-2.svg'


export const sortCaret = (order, column) => {
  if (!order) return (
    <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
      <SVG src={sort}/>
    </span>
  );
  else if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={up}/>
      </span>
    );
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={down}/>
      </span>
    );
  return null;
};

export const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
  (sortOrder === 'asc' || sortOrder === "desc") ? 'sortable-active' : ''
);