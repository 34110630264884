import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Form from "../../../common/Form";
import styled from "styled-components";
import Collapse from "react-bootstrap/Collapse";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import { cities as getCitiesList, getCities } from "../../../api";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const SearchFilter = ({ onApplyFilters, query }) => {
  const { t } = useTranslation();
  const direction = i18n.dir();
  const width = window.innerWidth;
  const [open, setOpen] = useState(width < 440 ? false : true);
  const [cities, setCities] = useState([]);
  const [cityLabel, setCityLabel] = useState(t("Select City"));
  const [searchValue, setSearchValue] = useState(query);
  const [suggestions, setSuggestions] = useState([
    { city_name: "Abu Dhabi" },
    { city_name: "Dubai" },
    { city_name: "Sharjah" },
  ]);

  const [filters, setFilters] = useState({
    q: query,
    city: "",
  });

  useEffect(() => {
    onApplyFilters(filters);
  }, []);

  const onSearch = (e) => {
    const inputLength = e.target.value.length;
    if (inputLength >= 3) {
      onApplyFilters({ ...filters, q: e.target.value });
      setFilters({ ...filters, q: e.target.value });
    }
  };

  const onFiltersChanged = (val, field, label) => {
    const newFilters = { ...filters };

    newFilters[field] = val;

    setFilters(newFilters);
    onApplyFilters(newFilters);

    setCityLabel(label);
  };

  // useEffect(() => {

  //   getCitiesList()
  //     .then(({ data }) => {
  //       // setCities(data.map((item) => ({ value: item.id, label: item.event_category })));
  //       setCities(data);
  //     });

  //   // getCountries()
  //   //   .then(({ data }) => {
  //   //     setCountries(data);
  //   //   });
  // }, []);

  const onChange = (e) => {
    getSuggestions(e.target.value);
  };

  //Calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    setCityLabel(t("Select City"));
    // let filteredCities = [];
    // filteredCities = cities.filter(city => city.city_name.toLowerCase().slice(0, inputLength) === inputValue);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0 || value === "") {
      setSuggestions([
        { city_name: "Abu Dhabi" },
        { city_name: "Dubai" },
        { city_name: "Sharjah" },
      ]);
    } else if (inputLength >= 1 && inputLength <= 3) {
      setSuggestions([]);
    } else if (inputLength >= 4) {
      getCities({ q: value }).then(({ data }) => {
        setSuggestions(data);
      });
    }
  };

  return (
    <section className="ic-app-content-left d-md-block">
      <aside>
        <div className="ic-app-content-left--wrapper">
          <StyledSearch style={{ padding: "20px 0 0 0" }}>
            <div className="ic-search">
              <div className="ic-search-sidebar">
                <div className="ic-search-sidebar--wrapper">
                  <header className="header-plain">
                    <Button
                      onClick={() => setOpen(!open)}
                      aria-label="Toggle Search Filters"
                      aria-controls="search-filter-collapse"
                      aria-expanded={open}
                      className="toggle-filter-button"
                    >
                      {t("Search Filters")}
                      {open ? (
                        <i
                          className="material-icons"
                          style={{ fontSize: "28px" }}
                          alt="down arrow"
                        >
                          expand_more
                        </i>
                      ) : (
                        <i
                          className="material-icons"
                          style={{ fontSize: "28px" }}
                          alt="right arrow"
                        >
                          chevron_right
                        </i>
                      )}
                    </Button>
                  </header>
                </div>
              </div>
            </div>
          </StyledSearch>

          <StyledSearch>
            <div className="ic-page-search-sidebar">
              <div className="ic-page-search-sidebar--wrapper">
                <div className="containter-fluid">
                  <div className="row ic-default-sidebar-form">
                    <Form noValidate>
                      <div className="ic-form-content clearfix">
                        <Form.Row>
                          <Form.Group className="mb-0" as={Col} xs={12} lg={12}>
                            <Form.Control
                              onChange={(e) => {
                                onSearch(e);
                                setSearchValue(e.target.value);
                              }}
                              placeholder={t("Search...")}
                              value={searchValue}
                              aria-label="Search keywords"
                              className="px-4"
                              style={{ height: "40px" }}
                            />
                          </Form.Group>
                        </Form.Row>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <Collapse in={open}>
              <div id="search-filter-collapse">
                <div className="ic-page-search-sidebar">
                  <div className="ic-page-search-sidebar--wrapper">
                    <div className="containter-fluid">
                      <Dropdown>
                        <Dropdown.Toggle
                          className="search-filter form-control toggle-filter-button"
                          id="dropdown-filter-city"
                          value={filters.city}
                          aria-label="Select Person City"
                        >
                          {cityLabel} {/*Select City*/}
                          <i
                            className="material-icons"
                            style={{ fontSize: "28px" }}
                          >
                            expand_more
                          </i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{textAlign: `${direction === "rtl" ? "right" : ""}`}}>
                          <Form.Row style={{ width: "100%", marginRight: `${direction === "rtl" ? "0" : ""}`}}>
                            <Form.Group
                              className="m-0 p-0"
                              as={Col}
                              xs={12}
                              lg={12}
                            >
                              <Form.Control
                                onChange={(e) => onChange(e)}
                                placeholder={t("Type a city name...")}
                                aria-label="Search city name"
                                className="city-input-search"
                              />
                            </Form.Group>
                          </Form.Row>

                          {suggestions.length ? (
                            suggestions.map((suggestion) => (
                              <Dropdown.Item
                                key={suggestion.city_name}
                                value={suggestion.city_name}
                                onClick={(e) =>
                                  onFiltersChanged(
                                    `${suggestion.city_name}`,
                                    "city",
                                    `${t(suggestion.city_name)}`
                                  )
                                }
                              >
                                {t(suggestion.city_name)}
                              </Dropdown.Item>
                            ))
                          ) : (
                            <Dropdown.Item style={{ cursor: "default" }}>
                              {t("City not found.")}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </StyledSearch>
        </div>
      </aside>
    </section>
  );
};

export default SearchFilter;

const StyledSearch = styled.div`
  .ic-search {
    border-bottom: 2px solid #dadbe2 !important;
  }

  .toggle-filter-name {
    font-size: 1.1rem;
    color: #000000 !important;
    font-weight: 600;
    border: 0;
    border-radius: 0;
    background-color: #ffffff;
    box-shadow: none;
    text-align: start;
    width: 100%;
    padding: 20px 15px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toggle-filter-button {
    font-size: 1.2rem;
    color: #000000;
    font-weight: 700;
    border: 0;
    border-radius: 0;
    background-color: #ffffff;
    box-shadow: none;
    text-align: start;
    width: 100%;
    padding: 0 24px 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .city-input-search {
    padding: 0.8rem;
    border-bottom: 1.5px solid #cecece;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    margin-left: 5px;
    height: 50px;
    font-weight: 500;
  }

  input.city-input-search.form-control:focus {
    box-shadow: none !important;
    color: #000000 !important;
  }

  a.dropdown-item {
    padding: 0.8rem;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }

  .dropdown-menu.show {
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 204px;
    overflow: auto;
  }

  .toggle-filter-button:active {
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
  }

  .toggle-filter-name:active {
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
  }

  .search-identity {
    border-bottom: 0 !important;
  }

  .search-filter {
    font-size: 1.1rem;
    color: #000000 !important;
    font-weight: 600;
    padding: 28px 0px 28px 5px;
    border: 0;
    border-radius: 0;
    background-color: transparent !important;
    //border-bottom: 2px solid #ECECEC;
    @media(max-width: 440px) {
      font-size: 1rem;
    }
  }

  .search-filter:active {
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
    border-bottom-color: #ececec !important;
    border-bottom: 0 !important;
  }

  @media (max-width: 576px) {
    .ic-app-content-left--wrapper {
      margin-top: 1rem;
    }
  }
`;
