import React, { useState } from "react";
import styled, { css } from "styled-components";
import { NavLink as Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { setNotificationsRead } from "../../redux/notifications";
import { Colors } from "../../styles/constants";
import { getTimeAgo, getTimeAgoArabic } from "../../common/utils";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


const NoNotification = styled.div`
  padding: 1rem;
  text-align: start;
  color: ${Colors.font.secondary};
`;

const Toggle = React.forwardRef(
  ({ children, className, onClick, onOpenNotifications }, ref) => (
    <a
      className={className}
      id="dropdownNotification"
      aria-label="Notifications Dropdown"
      href=""
      ref={ref}
      onClick={(e) => {
        onOpenNotifications();
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )
);

const Menu = React.forwardRef(({ children, className }, ref) => {
  const [value] = useState("");
  const direction = i18n.dir();

  return (
    <div
      style={{
        maxHeight: "364px",
        padding: 0,
        right: `${direction === "rtl" ? "auto" : 0}`,
        left: `${direction === "rtl" ? 0 : "auto"}`,
        overflow: "hidden",
        minWidth: "270px",
      }}
      className={`${className}`}
      aria-label="Notifications Dropdown"
      ref={ref}
    >
      <ul className={`list-unstyled notificationDropDown ${direction === "rtl" ? "pr-0" : ""}`} style={{textAlign: `${direction === "rtl" ? "right" : ""}`}}>
        {React.Children.toArray(children).filter(
          (child) =>
            !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </ul>
    </div>
  );
});

const NotificationsDropdown = ({
  activeProfile,
  notifications,
  setNotificationsRead,
  setExpanded,
}) => {
  const { t } = useTranslation();
  const newNotifications = notifications.filter(
    (notification) => notification.isNew
  );

  if (activeProfile) {
    return (
      <StyledDropdown>
        <Dropdown
          className="NavDropdown mx-lg-4"
          title="Notifications"
          id="collasible-nav-dropdown"
        >
          <Dropdown.Toggle
            onOpenNotifications={setNotificationsRead}
            className="NavDropdown"
            as={Toggle}
          >
            <i
              className="fas fa-bell fa-2x pt-1 hide-on-mobile"
              aria-label="Notifications Dropdown"
            ></i>
            <span className="show-on-mobile">
              {t("NOTIFICATIONS")}{" "}
              <i className="fas fa-caret-down notification-caret"></i>
            </span>
          </Dropdown.Toggle>
          {newNotifications.length ? (
            <Badge className="bdgeeNotify" pill variant="danger">
              {newNotifications.length}
            </Badge>
          ) : null}

          <Dropdown.Menu as={Menu}>
            <span className="notifications-heading">{t("Notifications")}</span>
            {notifications && notifications.length ? (
              notifications.map((notification) => (
                <NotificationItem
                  key={notification.timestamp}
                  setExpanded={setExpanded}
                  notification={notification}
                  activeUserId={activeProfile.id}
                />
              ))
            ) : (
                <NoNotification>{t("No new notifications.")}</NoNotification>
              )}
          </Dropdown.Menu>
        </Dropdown>
      </StyledDropdown>
    );
  }
  return <div></div>;
};

export default connect(
  (state) => ({
    notifications: state.notifications.list,
    activeProfile: state.user.activeProfile,
  }),
  {
    setNotificationsRead,
  }
)(NotificationsDropdown);

const NotificationItem = ({ notification, activeUserId, setExpanded }) => {
  const history = useHistory();
  const [unread, setUnread] = useState(notification.unread ? true : false);
  let notificationText = notification.verb;
  let type = "post";
  const direction = i18n.dir();

  // if (notification.verb === 'Post') {
  //   notificationText += ` posted ${type === 'photo' ? 'a photo' : ''} on your profile.`;
  // } else if (notification.verb === 'Commented') {
  //   if(notification.target && notification.target.activity_by.id!==activeUserId)
  //   notification.target.content_object.author.id===notification.actor.id?(
  //     notificationText += ` also commented on ${notification.target.activity_by.person_gender === "F"  ? "her" : "his"} ${type === 'photo' ? 'photo' : 'post'}.`):(
  //       notificationText += ` also commented on ${notification.target.activity_by.full_name} ${type === 'photo' ? 'photo' : 'post'}.`
  //     )
  //   else
  //   notificationText += ` commented on your ${type === 'photo' ? 'photo' : 'post'}.`;
  // }

  const onClick = () => {
    if (notification.target) {
      if (notification.target.applicant) {
        history.replace(`/org/jobdetail/${notification.target.job.slug}/${notification.target.job.id}/applicants/${notification.target.id}`);
      }
      else {
        history.replace(`/activity/${notification.target.id}`);
      }
    }
    else {
      history.replace('/profile/conversations');
    }
    setUnread(false);
    setExpanded(false);
  };

  return (
    <StyledNotificationItem
      role="link"
      onClick={onClick}
      // isNew={notification.isNew}
      className="dropdonwlist"
      style={{ backgroundColor: `${unread ? 'rgb(0, 0 , 0, 0.05)':''}`, color: `${unread ? '#01579b':''}`}}
    >
      <div className="dropDownDiv">
        <NotificationText className="dropDownPtag">
          {notificationText}
        </NotificationText>
        <NotificationTimetamp className={direction === "rtl" ? "arabic-display" : ""}>
          {direction === "rtl" ? getTimeAgoArabic(notification.timestamp) : getTimeAgo(notification.timestamp)}
        </NotificationTimetamp>
      </div>
    </StyledNotificationItem>
  );
};

const StyledNotificationItem = styled(Dropdown.Item)`
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  user-select: none;
  border-bottom: 1px solid #eee;
  top: 3.8rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:focus {
    border: 0.15rem solid black;
    border-radius: 0.3rem;
  }
`
//   ${(props) =>
//     props.isNew &&
//     css`
//       background-color: #edf2fa;
//     `};
// ;

const NotificationText = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const NotificationTimetamp = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
  margin-bottom: 0;
`;

const StyledDropdown = styled.div`
  #dropdownNotification:hover {
    text-decoration: none;
  }

  .fa-2x {
    font-size: 2em !important;
  }

  .fa,
  .fas {
    // color: #43A0F5;
    color: #3F0E40;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-toggle {
    width: auto !important;
    height: 3.2rem !important;
  }

  .dropdown-toggle a:hover {
    text-decoration: none !important;
  }

  .notifications-heading {
    border-bottom: 1px solid #eee;
    padding: 0.8rem;
    display: flex;
    font-size: 1.1rem;
    font-weight: 600;
  }
`;
