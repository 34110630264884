import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { addToast } from "../../../redux/toast";
import AppContentContainer from "../../../common/AppContentContainer";
import { useParams, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LoaderWithText } from "../../../components/Loader";

import {
  getCourseDetail,
  getLessonDetail,
  submitQuiz,
  completeModule,
  getQuizResults,
} from "../../../api";

import i18n from "i18next";
import Form from "react-bootstrap/Form";


const LessonDetail = (activeProfileMeta, activeProfile, addToast) => {
  const direction = i18n.dir();
  const [lesson, setLesson] = useState();
  const { lessonId, courseId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [answers, setAnswers] = useState([]);
  const [result, setResult] = useState([]);
  const [maxResult, setMaxResult] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [course, setCourse] = useState([]);
  const [modules, setModules] = useState([]);
  const [moduleNumber, setModuleNumber] = useState({});
  const [completedModules, setCompletedModules] = useState([]);

  let quiz = [];

  useEffect(() => {
    let unmounted = false;
    setIsLoading(true);
    getLessonDetail(lessonId)
      .then(({ data }) => {
        if (!unmounted) {
          setLesson(data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let unmounted = false;
    getCourseDetail(courseId)
      .then(({ data }) => {
        if (!unmounted) {
          setCourse(data.course);
          setModules(data.modules);
          setCompletedModules(data.completed_modules);

        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      unmounted = true;
    };
  }, []);



  useEffect(() => {
    let unmounted = false;
    if (lesson && lesson.module.module_type === 'quiz') {
      getQuizResults(lessonId)
      .then(({ data }) => {
        if (!unmounted) {
          setMaxResult(data.best.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    return () => {
      unmounted = true;
    };
  },[lesson]);

  useEffect(() => {
    if (completedModules.length > 0) {
      completedModules.forEach((module) => {
        if (module.id === Number(lessonId)) {
          setIsCompleted(true);
        } 
      });
    }
  },[completedModules]);

  const handleCompleted = () => {
    completeModule(lessonId)
      .then(() => {
        setIsCompleted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAnswer = (index, question, choiceId) => {
    const questionIndex = quiz.indexOf(question);
    const answerArray = [...answers];
    answerArray[questionIndex].selectedAnswer = choiceId;
    setAnswers(answerArray);
  };

  useEffect(() => {
    if (lesson && lesson.module.module_type === 'quiz') {
      let quiz = lesson.quiz_question;
      for (let i = 0; i < quiz.length; i++) {
        answers.push({ questionId: quiz[i].id, selectedAnswer: "" });
      }
    } 
  },[lesson]);

  useEffect(() => {
    if (lesson) {
      let total_questions = lesson.quiz_question.length;
      let total_replies = 0;
      for (let i = 0; i < answers?.length; i++) {
        if (answers[i]?.selectedAnswer !== "") {
          total_replies += 1;
        }
      }
      if (total_questions === total_replies) {
        setDisableSubmit(false)
      }
      else {
        setDisableSubmit(true)
      };
    }
  },[answers]);

  useEffect(() => {
    if (lesson?.module?.module_type === 'quiz' && result >= 80) {
      setIsCompleted(true);
    }
  },[result]);

  const onSubmit = (e) => {
    // e.preventDefault();

    let answerList = [];
    for (let i = 0; i < answers?.length; i++) {
      answerList.push({
        quiz: lessonId,
        answer: answers[i].selectedAnswer,
      });
    }
    setIsSubmitting(true);
    submitQuiz(lessonId, answerList)
      .then(({ data }) => {
        setIsSubmitting(false);
        setResult(data.result.result);
        setMaxResult(data.result.result);
        setFeedback(data.answers);
        setQuizSubmitted(true);
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
      });
  };

  const handleResubmit = () => {
    setQuizSubmitted(false);
    let quiz = lesson.quiz_question;
      for (let i = 0; i < quiz.length; i++) {
        answers[i].selectedAnswer = '';
      }
  }

  let main_content;

  if (lesson) {
    let module_content;

    switch (lesson.module.module_type) {
      case "video":
        module_content = (
          <div className="lesson-content">
          <ReactPlayer
            config={{
              file: {
                attributes: { controlsList: "nodownload" },
                tracks: [
                  {
                    kind: "subtitles",
                    src: lesson.video_subtitle,
                    srcLang: "en",
                    default: true,
                  },
                ],
              },
            }}
            // Disable right click
            onContextMenu={(e) => e.preventDefault()}
            url={lesson.video_link}
            className="react-player"
            controls
            width="100%"
            height="100%"
          />
          <div className="pt-4">
            <a
              href={lesson.video_subtitle}
              className="download-pdf-btn job-post-next-btn btn btn-primary"
              download
            >
              <i className="material-icons md-20 mr-2">download</i>
              Transcript
            </a>
          </div>
          </div>
        );
        break;

      case "pdf":
        module_content = (
          <div className="lesson-content">
            {lesson.pdf_files.map((pdf_file) => (
              <a
                key="{pdf_file.id}"
                href={pdf_file.file}
                className="download-pdf-btn job-post-next-btn btn btn-primary"
                target="_blank"
              >
                 <i className="material-icons md-20 mr-2">download</i>
                {pdf_file.file_title}
              </a>
            ))}
          </div>
        );
        break;

      case "text":
        module_content = (
          <div className="lesson-content">{lesson.text_content}</div>
        );
        break;

      case "quiz":
        quiz = lesson.quiz_question;
        let quizform;
        if (quiz.length > 0) {
          quizform = quiz.map((quiz_question, index) => (
            <div key={quiz_question.id} className={`quiz-question ${quiz.length === index+1 ? 'mt-4' : 'my-4' }`}>
              <h4>
                <span>{index+1}. </span>
                {quiz_question.question}
              </h4>
              <Form.Row>
                <Form.Group as={Col} col={12}>
                  {quiz_question.quiz_question_choice.map((choice) => (
                    <div key={choice.id} className="question-choice py-2">
                      <Form.Check
                        type="radio"
                        id={choice.id}
                        label={choice.choice}
                        checked={answers[index]?.selectedAnswer == choice.id}
                        aria-label="Choice"
                        onChange={() =>
                          handleAnswer(index, quiz_question, choice.id)
                        }
                        onKeyUp={(event) =>
                          event.key === "Enter"
                            ? handleAnswer(index, quiz_question, choice.id)
                            : ""
                        }
                      />
                    </div>
                  ))}
                </Form.Group>
              </Form.Row>
              {quizSubmitted && (
                <div className="quiz-feedback">
                  {feedback[index]?.is_correct ? 
                    <div className="feedback-item d-flex align-items-start" style={{background: '#f7fbf9'}}>
                      <i className="material-icons md-24" style={{ color: '#1d7c50', fontSize: '1.5rem' }}>check_circle</i>
                      <div className="ml-2">
                        <h4 className="feedback-heading mb-0" style={{ color: '#1d7c50'}}>Correct</h4>
                        <h4 className="feedback-hint pt-1">Correct! {feedback[index]?.hint}</h4>
                      </div>
                    </div>
                    :
                    <div className="feedback-item d-flex align-items-start" style={{background: '#fdf5f5'}}>
                      <i className="material-icons md-24" style={{ color: '#a90001', fontSize: '1.5rem' }}>warning</i>
                      <div className="ml-2">
                        <h4 className="feedback-heading mb-0" style={{ color: '#a90001'}}>Incorrect</h4>
                        <h4 className="feedback-hint pt-1">Not quite. {feedback[index]?.hint}</h4>
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
          ));
        }

        module_content = (
          isCompleted ? 
          <div className="quiz-content">
            <div className="result-banner">
              <div className="header d-flex align-items-center mb-4">
                <i className="material-icons md-24" style={{ color: '#1d7c50', fontSize: '1.8rem' }}>check_circle</i>
                <h2 className="result-heading mb-0 ml-2">Congratulations! You passed!</h2>
              </div>
              <div className="result-info">
                <h5 className="result-received mb-3">Grade received <span style={{ color: '#1d7c50', fontWeight: '600' }}>{maxResult}%</span></h5>
                <h5 className="result-expected mb-0">To pass 80% or higher</h5>
              </div>
            </div>
          </div>
          // :
          // quizSubmitted ? 
          // <div className="quiz-content">
          //   <div className="result-banner">
          //     <div className="header d-flex align-items-center mb-4">
          //       <i className="material-icons md-24" style={{ color: result >= 80 ? '#1d7c50' : '#a90001', fontSize: '1.8rem' }}>{result >= 80 ? 'check_circle' : 'warning'}</i>
          //       <h2 className="result-heading mb-0 ml-2">{result >= 80 ? 'Congratulations! You passed!' : 'Try again once you are ready'}</h2>
          //     </div>
          //     <div className="result-info">
          //       <h5 className="result-received mb-3">Grade received <span style={{ color: result >= 80 ? '#1d7c50' : '#a90001', fontWeight: '600' }}>{result}%</span></h5>
          //       <h5 className="result-expected mb-0">To pass 80% or higher</h5>
          //     </div>
          //   </div>
          // </div>
          :
          <Form onSubmit={onSubmit} className="quiz-content" noValidate>
            {quizSubmitted && (
              <div className="result-banner pt-0 pb-3">
                <div className="header d-flex align-items-center mb-4">
                  <i className="material-icons md-24" style={{ color: result >= 80 ? '#1d7c50' : '#a90001', fontSize: '1.8rem' }}>{result >= 80 ? 'check_circle' : 'warning'}</i>
                  <h2 className="result-heading mb-0 ml-2">{result >= 80 ? 'Congratulations! You passed!' : 'Try again once you are ready'}</h2>
                </div>
                <div className="result-info">
                  <h5 className="result-received mb-3">Grade received <span style={{ color: result >= 80 ? '#1d7c50' : '#a90001', fontWeight: '600' }}>{result}%</span></h5>
                  <h5 className="result-expected mb-0">To pass 80% or higher</h5>
                </div>
              </div>
            )}
            {quizform}
              {/* <Button
                className="job-post-next-btn"
                id="apply-job"
                type="submit"
                aria-label="Submit"
              >
                {isSubmitting ? "Please wait.." : "Submit"}
              </Button> */}
          </Form>
        );
    }

    /* Main Content contains unique module content codes based on the type of module.  */

    main_content = (
      <StyledLessonCard>
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={'div'}>
              <Link to="/learn" className="breadcrumb-link breadcrumb-item">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={'div'}>
              <Link to={`/learn/${courseId}/`} className="breadcrumb-link">{course.title}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{lesson.module.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row>
          <Col md={12}>
            <div className="lesson-header">
              <h2>{lesson.module.title}</h2>
              <div className="lesson-subheader d-flex align-items-center justify-content-between">
                <div className="lesson-info">
                  <i className="material-icons md-24 mr-2">
                    {lesson.module.module_type === 'text' && 'import_contacts'}
                    {lesson.module.module_type === 'video' && 'play_circle'}
                    {lesson.module.module_type === 'pdf' && 'description'}
                    {lesson.module.module_type === 'quiz' && 'quiz'}
                  </i>
                  <div className="d-flex align-items-center">
                    <h4 className="module-type">{lesson.module.module_type === 'text' ? 'Reading' : lesson.module.module_type === 'pdf' ? 'Additional Resources' : lesson.module.module_type === 'video' ? 'Video' : 'Practice Quiz'}</h4>
                    <span className="px-1">•</span>
                    <h4 className="lesson-duration">{lesson.module.duration.replace('Mins', 'min').replace('mins', ' min')}</h4>
                  </div>
                </div>
                <div className="lesson-nav d-flex align-items-center show-on-regular">
                  {modules.length>0 && Number(lessonId) !== modules[0]?.id && (
                    <Link to={`/learn/${courseId}/lesson/${modules[modules.findIndex((module) => module.id.toString() === lessonId)-1]?.id}`} className="prev-link mr-2">
                      <i className="material-icons md-24">navigate_before</i>
                      Previous
                    </Link>
                  )}
                  {modules.length>0 && Number(lessonId) !== modules[modules.length - 1]?.id && (
                    <Link to={`/learn/${courseId}/lesson/${modules[modules.findIndex((module) => module.id.toString() === lessonId)+1]?.id}`} className="next-link">
                      Next
                      <i className="material-icons md-24">navigate_next</i>
                    </Link>
                  )}
                  {Number(lessonId) == modules?.length && (
                    <Link to={`/learn/${courseId}/`} className="next-link">
                      Back to Course
                      <i className="material-icons md-24">navigate_next</i>
                    </Link>
                  )}
                </div>
              </div>
              <div className="lesson-nav d-flex align-items-center show-on-mobile mt-3" style={{ justifyContent: Number(lessonId) === 1 ? 'end' : 'space-between'}}>
                {Number(lessonId) !== modules[0]?.id && (
                  <Link to={`/learn/${courseId}/lesson/${modules[modules.findIndex((module) => module.id.toString() === lessonId)-1]?.id}`} className="prev-link mr-2">
                    <i className="material-icons md-24">navigate_before</i>
                    Previous
                  </Link>
                )}
                {Number(lessonId) !== modules[modules.length - 1]?.id && (
                  <Link to={`/learn/${courseId}/lesson/${modules[modules.findIndex((module) => module.id.toString() === lessonId)+1]?.id}`} className="next-link">
                    Next
                    <i className="material-icons md-24">navigate_next</i>
                  </Link>
                )}
                {Number(lessonId) == modules?.length && (
                  <Link to={`/learn/${courseId}/`} className="next-link">
                    Back to Course
                    <i className="material-icons md-24">navigate_next</i>
                  </Link>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {lesson.module.module_type !== 'quiz' && (
          <div className="lesson-content pb-0">{lesson.module.description}</div>
        )}
        {module_content}
        <div className="lesson-content" style={{ borderTop: '1px solid rgb(233, 233, 233)' }}>
        {isCompleted ? (
          <div className="lesson-complete d-flex align-items-center">
            <i className="material-icons md-24 mr-1">done</i>
            Completed
          </div>
        ) : (
          <Button
            className="job-post-next-btn"
            id="apply-job"
            onClick={() => lesson.module.module_type === 'quiz' ? quizSubmitted ? handleResubmit() : onSubmit() : handleCompleted()}
            aria-label="Submit"
            disabled={lesson.module.module_type === 'quiz' ? disableSubmit : false}
          >
            {lesson.module.module_type === 'quiz' ? quizSubmitted ? isSubmitting ? 'Submitting...' : 'Try Again' : 'Submit' : 'Mark as Completed'}
          </Button>
        )}
        </div>
      </StyledLessonCard>
    );
  }

  return (
    <AppContentContainer classes="container d-flex flex-column flex-md-row">
      {isLoading && <LoaderWithText minHeight="50vh" text={"Please wait..."} key="1"/>}
      <section className="">
        <aside
          className={
            direction === "rtl" ? "display-aside-rtl" : "display-aside-ltr"
          }
        >
          {!isLoading && main_content}
        </aside>
      </section>
    </AppContentContainer>
  );
};

export default connect(
  (state) => ({
    activeProfileMeta: state.user.activeProfileMeta,
  }),
  {
    addToast,
  }
)(LessonDetail);

const StyledLessonCard = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;

  @media(max-width: 440px) {
    margin-top: 2.5rem;
    .lesson-header h2, .result-heading {
      font-size: 1.4rem !important;
    }
    .lesson-header, .lesson-content {
      padding: 1.5rem !important;
    }
    .lesson-header {
      border-top: 1px solid rgb(233,233,233);
    }
    .result-banner {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .quiz-content {
      padding: 24px !important;
    }
  }

  .lesson-header {
    padding: 1rem 2rem 1.8rem 2rem;
    border-bottom: 1px solid rgb(233, 233, 233);
  }

  .lesson-header h2 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .lesson-info {
    color: #5F5F5F;
    display: flex;
    align-items: center;
  }

  .lesson-info h4 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  .lesson-content {
    padding: 2rem;
  }

  .prev-link, .next-link {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: #5f5f5f;
    padding: 3px 0;
    &:hover {
      text-decoration: none;
      color: #3f0e40;
      background-color: #f4f4f4;
      border-radius: 5px;
    }
  }

  .prev-link {
    padding-right: 9px;
  }

  .next-link {
    padding-left: 9px;
  }

  .lesson-complete {
    font-size: 1.1rem;
    font-weight: 500;
    color: #5f5f5f;
    padding: 5px 8px 5px 4px;
    background-color: #f4f4f4;
    border-radius: 5px;
    width: fit-content;
  }

  .breadcrumb {
    background-color: #fff;
    margin: 1rem 0 0 2rem;
    flex-wrap: nowrap;
    @media(max-width: 440px) {
      margin-left: 1.5rem;
      flex-wrap: wrap;
      line-height: 1.8rem;
      padding-bottom: 1.5rem;
    }
  }

  .breadcrumb-item {
    white-space: nowrap;
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .breadcrumb-link {
    color: #000;
  }

  .quiz-question h4 {
    font-size: 1.1rem;
  }

  .result-banner {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  .form-check-input, .form-check-label {
    cursor: pointer;
  }

  .form-check-label {
    display: block;
  }

  .feedback-item {
    padding: 12px;
  }

  .feedback-heading, .feedback-hint {
    font-size: 1rem !important;
  }

  .job-footer {
    padding: 20px 24px;
    font-size: 1.1rem;
  }

  .job-close-date {
    color: #000000;
  }

  .job-title-a {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000000;
    @media (max-width: 560px) {
      font-size: 1.2rem;
    }
  }

  .job-organization {
    font-size: 1.1rem;
    font-weight: 500;
    color: #000;
    @media (max-width: 560px) {
      font-size: 1rem;
    }
  }

  .job-location {
    font-size: 1rem;
    font-weight: 500;
    color: #5f5f5f;
  }

  .job-badge-icon {
    display: flex;
    justify-content: flex-end;
  }

  .job-logo-container {
    width: 84px;
    height: 84px;
  }

  .job-logo-container img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    object-fit: contain;
  }

  .job-apply-button {
    display: flex;
    justify-content: flex-end;
  }

  #apply-job, .download-pdf-btn {
    background-color: #3f0e40;
    border-color: #3f0e40;
    color: #fff;
    border-radius: 7px;
    font-weight: 500;
    display: flex;
    align-items: center;
    min-width: 125px;
    justify-content: center;
  }

  .download-pdf-btn {
    width: fit-content;
    &:active {
      background-color: #3f0e40 !important;
      border-color: #3f0e40 !important;
      color: #fff;
    }
  }

  .info-button {
    font-size: 1rem;
    color: #3f0e40;
    background-color: #ededed;
    border-radius: 7px;
    font-weight: 500;
    display: inline-block;
    padding: 0.438rem 0.75rem;
    @media (max-width: 560px) {
      display: none;
    }
  }

  .btn.disabled,
  .btn:disabled {
    cursor: default;
  }

  .show-on-mobile {
    display: none  !important;
  }

  @media (max-width: 440px) {
    .show-on-regular {
      display: none !important;
    }
    .show-on-mobile {
      display: flex !important;
    }
    .job-content {
      padding: 1.5rem;
    }
    .job-location {
      justify-content: flex-start;
    }
    .job-title.title-ltr {
      padding-left: 0;
    }
    .job-title.title-rtl {
      padding-right: 0;
    }
    .job-apply-button.apply-ltr {
      //justify-content: flex-start;
      //padding-top: 1rem;
      padding-left: 0;
    }
    .job-apply-button.apply-rtl {
      padding-right: 0;
    }
    .job-close-date.date-ltr {
      padding-right: 0;
    }
    .job-close-date.date-rtl {
      padding-left: 0;
    }
  }

  .quiz-content {
    padding: 1.8rem 2rem;
    width: 1050px;
    @media(max-width: 440px) {
      width: auto;
    }
  }
`;
