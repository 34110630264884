import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import * as yup from "yup";

import styled from "styled-components";
import { connect } from "react-redux";

import { addToast } from "../../../redux/toast";
import Button from "../../../components/Button";
import PasswordValidator from "../../../components/PasswordValidator";

import Form from "../../../common/Form";

import { passwordChange } from "../../../api";

import { setActiveProfile, getAvailableProfiles } from "../../../redux/user";
const PasswordChangeTab = ({
  availableProfiles,
  activeProfile,
  activeProfileType,
  setActiveProfile,
  getAvailableProfiles,
  addToast,
}) => {
  const PasswordChangeSchema = yup.object().shape({
    new_password1: yup
      .string()
      .required("Enter new password.")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,}$/,
        "Please enter a valid password"
      ),
    new_password2: yup
      .string()
      .required("Re-enter new password.")
      .oneOf([yup.ref("new_password1"), null], "Passwords must match"),
    old_password: yup.string().required("Enter current password."),
  });

  const onPasswordChange = (values, { setSubmitting, resetForm }) => {
    passwordChange(values)
      .then(() => {
        addToast({
          type: "success",
          title: "Success",
          text: "Password Changed",
        });
        resetForm({});

        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error) {
          const msg =
            Object.values(error.response.data)[0] == "Invalid password"
              ? "Old password is incorrect."
              : Object.values(error.response.data)[0] ==
                "The password is too similar to the first name."
              ? "New password is too similar to the first name"
              : `${Object.values(error.response.data)[0]}`;
          addToast({
            type: "error",
            title: "Error",
            text: msg,
          });
        }
      });
  };
  return (
    <>
      <div className="card password-change-card">
        <div className="card-body py-1">
          <div className="row">
            <div className="col-md-12">
              <h4
                style={{
                  fontSize: "26px",
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  color: '#000000',
                }}
              >
                Change Password
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Formik
                validationSchema={PasswordChangeSchema}
                initialValues={{
                  new_password1: "",
                  new_password2: "",
                  old_password: "",
                }}
                onSubmit={onPasswordChange}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>

                    <Form.Group>
                      <Form.Label>Current Password *</Form.Label>
                      <Form.Control
                        type="password"
                        name="old_password"
                        aria-label="Enter current password"
                        placeholder="Enter current password"
                        value={values.old_password}
                        isInvalid={touched.old_password && errors.old_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.old_password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>New Password *</Form.Label>
                      <Form.Control
                        type="password"
                        name="new_password1"
                        aria-label="Enter new password"
                        placeholder="Enter new password"
                        value={values.new_password1}
                        isInvalid={
                          touched.new_password1 && errors.new_password1
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.new_password1}
                      </Form.Control.Feedback>
                      <PasswordValidator password={values.new_password1} />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Confirm Password *</Form.Label>
                      <Form.Control
                        type="password"
                        name="new_password2"
                        aria-label="Re-enter new password"
                        placeholder="Re-enter new password"
                        value={values.new_password2}
                        isInvalid={
                          touched.new_password2 && errors.new_password2
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.new_password2}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                      loading={isSubmitting}
                      submit
                      primary
                      type="submit"
                      className="change-password-btn"
                    >
                      Update Password
                    </Button>

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {
    setActiveProfile,
    addToast,
  }
)(PasswordChangeTab);
