import React, { useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";

const Toggle = React.forwardRef(({ children, className, onClick }, ref) => (
  <a
    className={className}
    id="dropdownImgMenu"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const Menu = React.forwardRef(({ children, className }, ref) => {
  const [value] = useState("");

  return (
    <div
      className={className}
      tabIndex="-1"
      aria-labelledby="dropdownUserMenu"
      ref={ref}
    >
      <ul className="list-unstyled mb-0">
        {React.Children.toArray(children).filter(
          (child) =>
            !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </ul>
    </div>
  );
});

const ImageDropDown = ({ handledelete, picId, index }) => {
  return (
    <Dropdown
      as="li"
      className="nav-item dropdown d-flex flex-row imageDropDown"
    >
      <Dropdown.Toggle
        className="nav-link dropdown-toggle px-0 py-1 mr-md-5 ml-2"
        as={Toggle}
      >
        <i className="fas fa-ellipsis-v reddots" style={{ Color: "#263238" }} />
      </Dropdown.Toggle>

      <Dropdown.Menu as={Menu} className="dropdown-menu">
        <Dropdown.Item>Edit Photo</Dropdown.Item>
        <Dropdown.Item onClick={() => handledelete(picId, index)}>
          Delete Photo
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ImageDropDown;
