import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import ReadMoreAndLess from "react-read-more-less";
import TagList from "../../../components/TagList";
import ProfileCompletion from "./ProfileCompletion";
import Hobbies from "./Hobbies";
import Portfolios from "./Portfolios";
import WorkExperince from "./WorkExperience";
import EducationHistory from "./EducationHistory";
import Achievements from "./Achievements";
import AddEditStory from "./AddEditStory";
import { addToast } from "../../../redux/toast";

import {
  addSkill,
  deleteSkill,
  addAccomodation,
  deleteAccomodation,
  FollowPerson,
  unFollowPerson,
  getFollowPerson,
} from "../../../api";

const MyStory = ({
  addToast,
  activeProfile,
  profile,
  onProfileUpdated,
  isOwner,
  handleEditProfile,
  TogglePublicView,
  publicView,
}) => {
  const [showAbility, setShowAbility] = useState(false);
  const [showAccomodations, setShowAccomodations] = useState(false);
  const [editStory, setEditStory] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);

  const completeProfile = useSelector(state => state.user.completeProfile);
  const [showCompleteProfile, setShowCompleteProfile] = useState(false);

  const [showStoryTooltip, setShowStoryTooltip] = useState(false);
  const storyTooltipRef = useRef(null);
  const [showAbilityTooltip, setShowAbilityTooltip] = useState(false);
  const abilityTooltipRef = useRef(null);
  const [showAccommodationTooltip, setShowAccommodationTooltip] = useState(false);
  const accommodationTooltipRef = useRef(null);

  useEffect(() => {
    let unmounted = false;
    if (!isOwner) {
      getFollowPerson(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          if (!unmounted) {
            setFollowing(res.data);
          }
        })
        .catch((res) => {
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    }
    return () => { unmounted = true }; 
  }, []);

  //After completion, keep the card for a few secs with a toast message before disappearing.
  useEffect(() => {
    if (completeProfile) {
      if (showCompleteProfile) {
        addToast({
          type: "success",
          title: "Success",
          text: "Mandatory profile fields completed successfully.",
        });
        setTimeout(() => {
          setShowCompleteProfile(false);
        }, 3000);
      }
    }
    else {
      setShowCompleteProfile(true);
    }
  }, [completeProfile]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleFollow = () => {
    if (activeProfile.owner_id === profile.owner_id) {
      addToast({
        type: "error",
        title: "Error",
        text: "you cannot follow yourself.",
      });
      return;
    }

    setFollowLoading(true);
    if (following) {
      unFollowPerson(activeProfile.owner_id, profile.owner_id)
        .then((res) => {
          if (res.status === 204) setFollowing(false);
          addToast({
            title: "Success",
            text: `Unfollowed successfully.`,
            type: "success",
          });
          setFollowLoading(false);
        })
        .catch((res) => {
          setFollowLoading(false);
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
        });
    } else {
      FollowPerson(activeProfile.owner_id, profile.owner_id, {})
        .then((res) => {
          addToast({
            title: "Success",
            text: `You are now following ${profile.person_first_name}.`,
            type: "success",
          });
          setFollowing(true);
          setFollowLoading(false);
        })
        .catch((res) => {
          addToast({
            type: "error",
            title: "Error",
            text: "An error occurred, please try again.",
          });
          setFollowLoading(false);
        });
    }
  };

  var person_of_determination = false;
  if (profile.identifies_as == "Proud to be Person of Determination" || profile.identifies_as == "Person with Disability") {
    person_of_determination = true;
  }

  return (
    <>
      {/* <Row className="badge-container show-on-regular" style={{marginTop:'2.56rem'}}>
              <Col md={9} className="d-flex align-items-center my-profile-badge">
                <img src={BadgeIcon} alt="badge" className="" />
                <div className="badgetext pl-2 pt-1">{profile.identifies_as}</div>
              </Col>
              {
              !isOwner ? (
                <Col md={3} className="d-flex align-items-center" style={{justifyContent:'flex-end'}}>
                  <Button className="profile-follow-btn" loading={followLoading} onClick={handleFollow}>{followLoading?<i className="fas fa-spinner fa-spin" />:<i className="fas fa-user-plus"></i>} {following ? 'Unfollow' : 'Follow'}</Button>
                </Col>
              ) : !publicView?<Col md={3} className="d-flex align-items-center" style={{justifyContent:'flex-end'}}>
              <Button className="profile-follow-btn"  onClick={TogglePublicView}><i className="fas fa-eye" /> View As</Button>
            </Col>
            :""
            }
            </Row>
            <Row className="show-on-ipad">
              <Col md={12} className="d-flex align-items-center my-profile-badge">
                <img src={BadgeIcon} alt="badge" className="" />
                <div className="badgetext pl-2 pt-1">{profile.identifies_as}</div>
              </Col>
              {
              !isOwner ? (
                <Row className="show-on-ipad mt-2">
                <Col md={12} className="d-flex align-items-center">
                  <Button className="profile-follow-btn mx-3" style={{width: '100%'}} loading={followLoading} onClick={handleFollow}>{followLoading?<i className="fas fa-spinner fa-spin" />:<i className="fas fa-user-plus"></i>} {following ? 'Unfollow' : 'Follow'}</Button>
                </Col>
                </Row>
              ) :  !publicView?<Row className="show-on-ipad mt-2"><Col md={12} className="d-flex align-items-center" style={{justifyContent:'flex-end'}}>
              <Button className="profile-follow-btn"  onClick={TogglePublicView}><i className="fas fa-eye" /> View As</Button>
            </Col>
            </Row>
            :""
            }
            </Row>
     
              {
              !isOwner ? (
                <Row className="badge-container show-on-mobile" style={{marginTop:'0'}}>
                <Col md={12} className="d-flex align-items-center" style={{justifyContent:'flex-end'}}>
                  <Button className="profile-follow-btn" loading={followLoading} onClick={handleFollow}>{followLoading?<i className="fas fa-spinner fa-spin" />:<i className="fas fa-user-plus"></i>} {following ? 'Unfollow' : 'Follow'}</Button>
                </Col>
                </Row>
              ) : !publicView?
              <Row className="badge-container show-on-mobile" style={{marginTop:'15px'}}>
               <Col md={12} className="d-flex align-items-center" style={{justifyContent:'flex-end'}}>
                          <Button className="profile-follow-btn"  onClick={TogglePublicView}><i className="fas fa-eye" /> View As</Button>

            </Col>
            </Row>:""
            }
           */}
      {(isOwner && showCompleteProfile) && (
        <ProfileCompletion profile={profile} handleEditProfile={handleEditProfile} setEditStory={setEditStory} setShowAbility={setShowAbility} />
      )}
      
      <div className="user-story user-data-wrapper">
        <div className="user-story-header">
          <div>
            <h2 style={{ display: 'flex' }}>
              {isOwner
                ? "My "
                : `${
                    profile.person_first_name.charAt(0).toUpperCase() +
                    profile.person_first_name.slice(1)
                  }'s `}{" "}
              Story
              {isOwner && (
              <Button className="help-button" ref={storyTooltipRef} onClick={() => setShowStoryTooltip(!showStoryTooltip)} aria-label="My Story Tooltip" aria-pressed={showStoryTooltip}>
                <i className="material-icons help-icon">help_outline</i>
              </Button>
              )}
            </h2>
            <Overlay target={storyTooltipRef.current} show={showStoryTooltip} placement="bottom" rootClose={true} onHide={() => setShowStoryTooltip(false)} aria-describedby={showStoryTooltip ? "overlay-tooltip" : ""}>
              {(props) => (
                <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showStoryTooltip ? "polite" : "off"} {...props}>
                  Share more details about you and what brings you here so other users and companies can learn more about you and your motivations.
                </Tooltip>
              )}
            </Overlay>
          </div>
          {isOwner ? (
            <i className="material-icons" alt="Edit Icon" onClick={() => setEditStory(true)} style={{ cursor: 'pointer' }}>edit</i>
          ) : (
            ""
          )}
        </div>
        <hr />
        <div className="user-story-text">
          {profile && (
            <ReadMoreAndLess
              className="read-more-content my-story-link"
              charLimit={250}
              readMoreText="Read more"
              readLessText="Read less"
            >
              {profile && profile.person_work_towards_project}
            </ReadMoreAndLess>
          )}
        </div>
        {profile && (
          <AddEditStory
            show={editStory}
            isEditing
            closeDialog={() => setEditStory(false)}
            currentValues={{
              person_work_towards_project: profile.person_work_towards_project,
            }}
            onUpdated={onProfileUpdated}
          />
        )}
      </div>

      {profile && !isOwner ? (
        profile.person_skill.length ? (
          <div className="user-ability user-data-wrapper">
            <div className="user-story-header">
              <div>
                <h2>Abilities</h2>
              </div>
            </div>
            <hr />
            <div className="user-story-text">
              <div className="abilities-wrapper">
                <TagList
                  onUpdated={onProfileUpdated}
                  singularName="Ability"
                  profileId={profile.id}
                  add={addSkill}
                  remove={deleteSkill}
                  name="Abilities"
                  show={showAbility}
                  close={setShowAbility}
                  items={profile.person_skill}
                  itemName="person_skill"
                  isOwner={isOwner}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        <div className="user-ability user-data-wrapper">
          <div className="user-story-header">
            <div>
              <h2 style={{ display: 'flex' }}>My Abilities
                <Button className="help-button" ref={abilityTooltipRef} onClick={() => setShowAbilityTooltip(!showAbilityTooltip)} aria-label="My Abilities Tooltip" aria-pressed={showAbilityTooltip}>
                  <i className="material-icons help-icon">help_outline</i>
                </Button>
              </h2>
              <Overlay target={abilityTooltipRef.current} show={showAbilityTooltip} placement="bottom" rootClose={true} onHide={() => setShowAbilityTooltip(false)} aria-describedby={showAbilityTooltip ? "overlay-tooltip" : ""}>
                {(props) => (
                  <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showAbilityTooltip ? "polite" : "off"} {...props}>
                    What are your skills? What are you good at? Add both your soft skills and hard skills here.
                  </Tooltip>
                )}
              </Overlay>
              {/* <span
                className="ml-2 show-on-regular"
                style={{ color: "#757575" }}
              >
                (example: MS Excel, Leadership, Social Media Marketing)
              </span> */}
            </div>
              <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600', cursor: 'pointer' }} onClick={() => setShowAbility(true)}>add</i>
          </div>
          <span className="ml-2 show-on-mobile" style={{ color: "#757575" }}>
            (example: MS Excel, Leadership, Social Media Marketing)
          </span>
          <hr />
          <div className="user-story-text">
            <div className="abilities-wrapper">
              {profile.person_skill.length ? (
                <TagList
                  onUpdated={onProfileUpdated}
                  singularName="Ability"
                  profileId={profile.id}
                  add={addSkill}
                  remove={deleteSkill}
                  name="Abilities"
                  show={showAbility}
                  close={setShowAbility}
                  items={profile.person_skill}
                  itemName="person_skill"
                  isOwner={isOwner}
                />
              ) : (
                <>
                  <h3 className="pb-2 text-red">
                    You have not added any skills yet.
                  </h3>
                  <div style={{ display: "none" }}>
                    <TagList
                      onUpdated={onProfileUpdated}
                      singularName="Ability"
                      profileId={profile.id}
                      add={addSkill}
                      remove={deleteSkill}
                      name="Abilities"
                      show={showAbility}
                      close={setShowAbility}
                      items={profile.person_skill}
                      itemName="person_skill"
                      isOwner={isOwner}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {profile ? (
        !isOwner ? (
          profile.person_accomodation.length ? (
            <div className="user-ability user-data-wrapper">
              <div className="user-story-header">
                <div>
                  <h2>Preferred Accommodations</h2>
                </div>
              </div>
              <hr />
              <div className="user-story-text">
                <div className="abilities-wrapper">
                  <TagList
                    onUpdated={onProfileUpdated}
                    isOwner={isOwner}
                    singularName="Accomodation"
                    profileId={profile.id}
                    add={addAccomodation}
                    remove={deleteAccomodation}
                    name="Accommodations"
                    show={showAccomodations}
                    close={setShowAccomodations}
                    items={profile.person_accomodation}
                    itemName="person_accomodation"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="user-ability user-data-wrapper">
            <div className="user-story-header">
              <div>
                  <h2 style={{ display: 'flex' }}>Preferred Accommodations
                    <Button className="help-button" ref={accommodationTooltipRef} onClick={() => setShowAccommodationTooltip(!showAccommodationTooltip)} aria-label="My Accommodation Tooltip" aria-pressed={showAccommodationTooltip}>
                      <i className="material-icons help-icon">help_outline</i>
                    </Button>
                  </h2>
                  <Overlay target={accommodationTooltipRef.current} show={showAccommodationTooltip} placement="bottom" rootClose={true} onHide={() => setShowAccommodationTooltip(false)} aria-describedby={showAccommodationTooltip ? "overlay-tooltip" : ""}>
                    {(props) => (
                      <Tooltip id="overlay-tooltip" className="help-tooltip" aria-live={showAccommodationTooltip ? "polite" : "off"} {...props}>
                        What do you require at the workplace to have an equal work experience? Wheelchair ramps, accessible toilets, sign language? Please list all items that are your requirement.
                      </Tooltip>
                    )}
                  </Overlay>
              </div>
                <i className="material-icons" alt="Add Icon" style={{ fontSize: '30px', fontWeight: '600', cursor: 'pointer' }} onClick={() => setShowAccomodations(true)}>add</i>
            </div>
            <hr />
            <div className="user-story-text">
              <div className="abilities-wrapper">
                {profile.person_accomodation.length ? (
                  <TagList
                    onUpdated={onProfileUpdated}
                    isOwner={isOwner}
                    singularName="Accomodation"
                    profileId={profile.id}
                    add={addAccomodation}
                    remove={deleteAccomodation}
                    name="Accommodations"
                    show={showAccomodations}
                    close={setShowAccomodations}
                    items={profile.person_accomodation}
                    itemName="person_accomodation"
                  />
                ) : (
                  <>
                    <h3 className="text-red m-0 pre_line">
                      You have not added any accommodations yet.
                    </h3>
                    <div style={{ display: "none" }}>
                      <TagList
                        onUpdated={onProfileUpdated}
                        isOwner={isOwner}
                        singularName="Accomodation"
                        profileId={profile.id}
                        add={addAccomodation}
                        remove={deleteAccomodation}
                        name="Accommodations"
                        show={showAccomodations}
                        close={setShowAccomodations}
                        items={profile.person_accomodation}
                        itemName="person_accomodation"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        ""
      )}

      {profile && !isOwner ? (
        profile.person_hobby.length ? (
          <Hobbies
            onUpdated={onProfileUpdated}
            profile={profile}
            isOwner={isOwner}
          />
        ) : (
          ""
        )
      ) : (
        <Hobbies
          onUpdated={onProfileUpdated}
          profile={profile}
          isOwner={isOwner}
        />
      )}

      {profile && !isOwner ? (
        profile.experience.length ? (
          <WorkExperince
            profileId={profile.id}
            experience={profile.experience}
            isOwner={isOwner}
            refreshProfile={onProfileUpdated}
          />
        ) : (
          ""
        )
      ) : (
        <WorkExperince
          profileId={profile.id}
          experience={profile.experience}
          isOwner={isOwner}
          refreshProfile={onProfileUpdated}
        />
      )}
      {profile && !isOwner ? (
        profile.education.length ? (
          <EducationHistory
            profileId={profile.id}
            education={profile.education}
            isOwner={isOwner}
            refreshProfile={onProfileUpdated}
          />
        ) : (
          ""
        )
      ) : (
        <EducationHistory
          profileId={profile.id}
          education={profile.education}
          isOwner={isOwner}
          refreshProfile={onProfileUpdated}
        />
      )}

      {profile && !isOwner ? (
        profile.achievement.length ? (
          <Achievements
            achievements={profile.achievement}
            isOwner={isOwner}
            refreshProfile={onProfileUpdated}
            profileId={profile.id}
          />
        ) : (
          ""
        )
      ) : (
        <Achievements
          achievements={profile.achievement}
          isOwner={isOwner}
          refreshProfile={onProfileUpdated}
          profileId={profile.id}
        />
      )}

    {profile && !isOwner ? (
      profile.project.length ? (
        <Portfolios 
          portfolios={profile.project}
          isOwner={isOwner}
          refreshProfile={onProfileUpdated}
          profileId={profile.id}
        />
      ) : ("")) 
      : (   
        <Portfolios 
          portfolios={profile.project}
          isOwner={isOwner}
          refreshProfile={onProfileUpdated}
          profileId={profile.id}
        />
      )} 
    </>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {
    addToast,
  }
)(MyStory);
