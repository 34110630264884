import React, { useEffect, useState, useRef } from "react";
import {
  NavLink as Link,
  useLocation,
  useParams,
  Switch,
  Route,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { searchProfile, getPersonProfiles, getOrgProfiles } from "../../api";
import UserList from "./components/UserListAll";
import UserListPerson from "./components/UserListPerson";
import UserListOrg from "./components/UserListOrg";
import SearchFilterAll from "./components/SearchFilterAll";
import SearchFilterPeople from "./components/SearchFilterPeople";
import SearchFilterOrganization from "./components/SearchFilterOrganization";
import AppContentContainer from "../../common/AppContentContainer";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

//3 types of search >> all Users, all Persons, all Orgs 
const SearchUser = () => {
  const activeProfile = useSelector((state) => state.user.activeProfile);
  const { pathname: path } = useLocation();
  const { t } = useTranslation();
  const direction = i18n.dir();

  const [users, setUsers] = useState([]);
  const [persons, setPersons] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const { query } = useParams();
  const [value, setValue] = useState(query);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 1,
  });

  const unmounted = useRef(false);
 
  const getPersonProfileList = (page = pagination.current_page, filters) => {
    if (page === 1) {
      setIsSearching(true);
    }

    getPersonProfiles(filters, page)
      .then(({ data }) => {
        if (!unmounted.current) {
          if (page === 1) {
            setPersons(data.profiles);
          } else {
            setPersons(persons.concat(data.profiles));
          }
          setPagination(data.pagination);
          setIsSearching(false);
        }
      })
      .catch((error) => {
        setIsSearching(false);
      });
  };

  const getOrgProfileList = (page = pagination.current_page, filters) => {
    if (page === 1) {
      setIsSearching(true);
    }

    getOrgProfiles(filters, page)
      .then(({ data }) => {
        if (!unmounted.current) {
          if (page === 1) {
            setOrgs(data.profiles);
          } else {
            setOrgs(orgs.concat(data.profiles));
          }

          setPagination(data.pagination);
          setIsSearching(false);
        }
      })
      .catch((error) => {
        setIsSearching(false);
      });
  };

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  const search = (search) => {
    setIsSearching(true);

    searchProfile({ q: search })
      .then(({ data: { profiles } }) => {
        if (!unmounted.current) {
          const prof = JSON.parse(profiles);
          const filteredProfiles = prof.filter(
            (profile) => profile.fields.owner_id !== activeProfile.owner_id
          );
          setUsers(filteredProfiles);
          setIsSearching(false);
        }
      })
      .catch(() => {
        setUsers([]);
        setIsSearching(false);
      });
  };

  useEffect(() => {
    if (value !== "" && value.length >= 3) {
      search(value);
    } else {
      setUsers([]);
      setIsSearching(false);
    }
  }, [value]);

  useEffect(() => {
    if (query !== "" && query.length >= 3) {
      search(query);
    } else {
      setUsers([]);
      setIsSearching(false);
    }
  }, [query]);

  useEffect (() => {
    return () => { unmounted.current = true }
  }, []);

  return (
    <AppContentContainer>
      <StyledSearchNav>
        <div className="container d-flex flex-column flex-md-row">
          <Nav variant="pills">
            <Link
              to={`/search/${query}`}
              className={`nav-link nav-item ${path === `/search/${query}` ? "active" : "not-active"
                }`}
              onClick={() => setValue(query)}
            >
              {t("All")}
            </Link>
            <Link
              to={`/search/people/${query}`}
              className={`nav-link nav-item ${path === `/search/people/${query}` ? "active" : ""
                }`}
            >
              {t("People")}
            </Link>
            <Link
              to={`/search/organization/${query}`}
              className={`nav-link nav-item ${path === `/search/organization/${query}` ? "active" : ""
                }`}
            >
              {t("Organization")}
            </Link>
          </Nav>
        </div>

        <div className="container d-flex flex-column flex-md-row">
          <Switch>
            <Route path={`/search/${query}`}>
              <>
                <SearchFilterAll handleChange={handleChange} value={value} />
                <section className="ic-app-content-right">
                  <aside className={direction === "rtl" ? "aside-rtl" : "aside-ltr"}>
                    <div
                      className="ic-app-content-right--wrapper d-flex flex-column"
                      style={{
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "16px",
                        overflow: "hidden",
                      }}
                    >
                      <div className="ic-page-search-results">
                        <UserList profile={users} isSearching={isSearching} />
                      </div>
                    </div>
                  </aside>
                </section>
              </>
            </Route>

            <Route path={`/search/people/${query}`}>
              <>
                <SearchFilterPeople
                  query={query}
                  onApplyFilters={(filters) => getPersonProfileList(1, filters)}
                />
                <section className="ic-app-content-right">
                  <aside className={direction === "rtl" ? "aside-rtl" : "aside-ltr"}>
                    <div
                      className="ic-app-content-right--wrapper d-flex flex-column"
                      style={{
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "16px",
                        overflow: "hidden",
                      }}
                    >
                      <div className="ic-page-search-results">
                        <UserListPerson
                          profile={persons}
                          isSearching={isSearching}
                        />
                      </div>
                    </div>
                  </aside>
                </section>
              </>
            </Route>

            <Route path={`/search/organization/${query}`}>
              <>
                <SearchFilterOrganization
                  query={query}
                  onApplyFilters={(filters) => getOrgProfileList(1, filters)}
                />
                <section className="ic-app-content-right">
                  <aside className={direction === "rtl" ? "aside-rtl" : "aside-ltr"}>
                    <div
                      className="ic-app-content-right--wrapper d-flex flex-column"
                      style={{
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "16px",
                        overflow: "hidden",
                      }}
                    >
                      <div className="ic-page-search-results">
                        <UserListOrg profile={orgs} isSearching={isSearching} />
                      </div>
                    </div>
                  </aside>
                </section>
              </>
            </Route>
          </Switch>
        </div>
      </StyledSearchNav>
    </AppContentContainer>
  );
};

export default SearchUser;

const StyledSearchNav = styled.div`
  .nav.nav-pills {
    width: 100%;
    margin-bottom: 25px;
  }

  .nav-pills .nav-item {
    border-radius: 25px;
    color: #515151;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #8a8a8a;
    padding: 0.1rem 1rem;
    margin-right: 0.5rem;
    min-width: 103px;
    text-align: center;
    cursor: pointer;
  }

  .nav-item.active {
    background-color: #3F0E40 !important;
    border-color: #3F0E40 !important;
    color: #ffffff;
  }

  .nav-item.not-active {
    background-color: transparent !important;
    border-color: #8a8a8a !important;
    color: #515151;
  }

  .dashview-followers .follower-list .list-user .user-info .picture a img {
    border: 2px solid #dadbe2;
  }

  .ic-app-content-right .aside-rtl {
    margin-right: 30px;
  }

  .ic-app-content-right .aside-ltr {
    margin-left: 30px;
  }

  @media (max-width: 576px) {
    .container {
      padding-right: 0;
      padding-left: 0;
    }

    section.ic-app-content-left.d-md-block {
      margin: 0;
    }

    section.ic-app-content-right {
      margin-top: 1.5rem !important;
    }

    .ic-app-content-right .aside-rtl {
      margin-right: 0;
    }

    .ic-app-content-right .aside-ltr {
      margin-left: 0;
    }

    .nav.nav-pills {
      margin-top: 2.6rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-evenly;
    }

    a.nav-link.nav-item {
      margin-right: 0;
    }
  }
`;
