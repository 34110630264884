import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  width: 100%;

  margin: 0 auto;

  @media (max-width: 1250px) {
    max-width: 90%;
  }
`;

export const ProfileContainer = styled(Container)`
  margin-top: 25px;

  height: 100%;

  display: grid;
  grid-template-columns: 325px 1fr;
  grid-column-gap: 25px;

  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 275px 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
`;
