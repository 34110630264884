import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Form from "../../../common/Form";
import DatePicker from "../../../components/FormikDatepicker";
import { addExperience, updateExperience } from "../../../api";

const AddEditExperience = ({
  activeProfile,
  show,
  closeDialog,
  isEditing,
  currentValues,
  experienceId,
  onUpdated,
}) => {
  const experienceSchema = yup.object().shape({
    experience_title: yup
      .string()
      .required("Please provide the position you held at this Company."),
    experience_summary: yup
      .string()
      .required("Please provide the brief discription worked at that company."),
    experience_org: yup.string().required("Please provide the Company Name."),
    experience_start: yup
      .date()
      .required(
        "Please provide the date the date you started working for this Company."
      ),
    experience_end: yup
      .date()
      .required(
        "Please provide the date the date you stopped working for this Company."
      ),
    experience_end_present: yup.bool(),
    //  experience_loc: yup.string().required('Please provide the Company Name.'),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (isEditing) {
      updateExperience(activeProfile.id, experienceId, {
        ...values,
        person: activeProfile.id,
      })
        .then(() => {
          setSubmitting(false);
          closeDialog();

          onUpdated();
        })
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      addExperience(activeProfile.id, { ...values, person: activeProfile.id })
        .then(() => {
          setSubmitting(false);
          closeDialog();

          onUpdated();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  let initialValues = {
    experience_title: "",
    experience_summary: "",
    experience_org: "",
    // experience_loc:'',
    experience_start: new Date(),
    experience_end: new Date(),
    experience_end_present: false,
  };

  if (isEditing) {
    initialValues = {
      experience_title: currentValues.experience_title,
      experience_summary: currentValues.experience_summary,
      experience_start: new Date(currentValues.experience_start),
      experience_end: new Date(currentValues.experience_end),
      experience_end_present: currentValues.experience_end_present,
      experience_org: currentValues.experience_org,
      // experience_loc:currentValues.experience_loc
    };
  }

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      style={{ marginTop: "40px" }}
      className="modelTopmargin"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit" : "Add"} Work Experience</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={experienceSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Position</Form.Label>
                <Form.Control
                  placeholder="The position you held at this Company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="experience_title"
                  isInvalid={
                    touched.experience_title && errors.experience_title
                  }
                  value={values.experience_title}
                  type="text"
                  as="input"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.experience_title}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="position">Company Name</Form.Label>
                <Form.Control
                  placeholder="The name of the company you worked at"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="experience_org"
                  isInvalid={touched.experience_org && errors.experience_org}
                  value={values.experience_org}
                  type="text"
                  as="input"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.experience_org}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group>
                  <Form.Label htmlFor="position">Location</Form.Label>
                  <Form.Control
                    placeholder="The Location of the company you worked at"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="experience_loc"
                    isInvalid={touched.experience_loc && errors.experience_loc}
                    value={values.experience_loc}
                    type="text"
                    as="input"
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.experience_loc}
                  </Form.Control.Feedback>
                </Form.Group>
 */}

              {/* <p className="ic-form-badge font-italic"><span className="badge badge-warning"><i className="fas fa-check" aria-hidden="false"></i> </span> <span>Current position</span></p> */}
              {/* <Form.Group>
                <Form.Label htmlFor="position">Position</Form.Label>
                <Form.Control as="select" className="ic-form-select" id="position">
                  <option value="0">Select</option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                </Form.Control>
                <p className="ic-form-badge font-italic"><span className="badge badge-warning"><i className="fas fa-check" aria-hidden="false"></i> </span> <span>Current position</span></p>
              </Form.Group> */}

              <Form.Row className="ic-form-inline-fullwidth">
                <Form.Group className="col-md-6">
                  <Form.Label>From</Form.Label>
                  <DatePicker
                    nativeInputAriaLabel="select starting date"
                    maxDate={new Date()}
                    placeholderText="When did you start?"
                    name="experience_start"
                  />
                </Form.Group>

                <Col col={12} md={6}>
                  <Form.Group as={Col} col={12}>
                    <Form.Label>To</Form.Label>
                    <DatePicker
                      nativeInputAriaLabel="select ending date"
                      disabled={values.experience_end_present}
                      minDate={new Date(values.experience_start)}
                      placeholderText="When did you start?"
                      name="experience_end"
                    />

                    {/* <Form.Control.Feedback type="invalid">
                      {errors.education_start}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Check>
                    <Form.Check.Input
                      checked={values.experience_end_present}
                      name="experience_end_present"
                      value={values.experience_end_present}
                      onChange={handleChange}
                      type="checkbox"
                    />
                    <Form.Check.Label style={{ fontSize: "16px" }}>
                      I still work here
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Row>

              <Row>
                <Form.Group as={Col} col={12}>
                  <Form.Label>
                    Tell about your work and role in this company
                  </Form.Label>
                  <Form.Control
                    placeholder="Your responsibilities and duties and nature of work?"
                    name="experience_summary"
                    className="italic-placeholder textareaHeight"
                    value={values.experience_summary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    isInvalid={
                      touched.experience_summary && errors.experience_summary
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.experience_summary}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <Button
                      onClick={closeDialog}
                      block
                      className="modal-cancel-button"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="mt-3 mt-md-0" sm={12} md={6}>
                    <Button
                      loading={isSubmitting ? 1 : 0}
                      block
                      className="modal-submit-button"
                      type="submit"
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {}
)(AddEditExperience);
