// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {NavLink as Link} from "react-router-dom";
import SplitButton from 'react-bootstrap/SplitButton';
import Dropdown from 'react-bootstrap/Dropdown';
import SVG from "react-inlinesvg";
//import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import editIcon from '../../../../../../assets/svg/Write.svg'
import delIcon from '../../../../../../assets/svg/Trash.svg'



export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditCustomerDialog, openDeleteCustomerDialog }
) {
 // const history = useHistory()
  
 const handleChange=()=>{

 }

  return (
    <>
      <a
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditCustomerDialog(row.applicant.id,row.applicant_status,row.id,row.job)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={editIcon}
          />
        </span>
      </a>
      

      

      {/* <SplitButton alignRight key="danger" id="dropdown-split-variants-danger" variant="danger" title="View Profile"
                 onClick={()=>setShow(true)}>
                    <Dropdown.Item onClick={()=>handleChange("S")} 
                      className={`${row.applicant_status === "S" ? "disabled" : ""}`}>
                      {isSubmittingS ? 'Please wait...' : 'Shortlist'}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={()=>handleChange("R")}  
                      className={`${row.applicant_status === "R" ? "disabled" : ""}`}>
                      {isSubmittingR ? 'Please wait...' : 'Reject'}</Dropdown.Item>
                </SplitButton> */}
     
    </>
  );
}
