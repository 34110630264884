import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import { format } from 'date-fns';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
//import Dropdown from 'react-bootstrap/Dropdown';

import Form from '../../../common/Form';
import DatePicker from '../../../components/FormikDatepicker';
import CountryCity from '../../../components/CountryCity';
import { getOrganizationIndustries, updateOrganization } from '../../../api';

const EditOrganizationProfile = ({
  activeProfile,
  show,
  closeDialog,
  onProfileUpdated,
}) => {
  const [userCity, setUserCity] = useState(null)
  const [cityValid, setCityValid] = useState(false)
  const [industries, setIndustries] = useState([])

  const orgSchema = yup.object().shape({
    organization_industry: yup.string().required('Please provide organization industry.'),
    organization_title: yup.string().required('Please provide organization title.').max(60).label('Organization Title'),
    organization_address: yup.string().required('Please provide the main branch address.').max(60).label('Organization Address'),
    organization_tagline: yup.string().required('Please provide a tagline.').max(60).label('Organization Tagline'),
    organization_overview: yup.string().required('Please provide a brief description of your organization.'),
    organization_email: yup.string().email().required('Please provide organization email.'),
    organization_founded: yup.date().required('Please provide organization founded date.'),
    organization_website: yup.string().required('Please provide organization website.').matches(/\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i, "Enter Url in https:// format."),
    organization_phone_number: yup.string().required("Please provide organization contact number.").matches(/^\+(?:[0-9]●?){10,13}[0-9]$/, "Contact number should start with + and contain 11 - 14 digits."),
    organization_size: yup.string()
  });

  const initialValues = {
    organization_industry: activeProfile.organization_industry.id,
    organization_title: activeProfile.organization_title,
    organization_address: activeProfile.organization_address,
    organization_tagline: activeProfile.organization_tagline,
    organization_overview: activeProfile.organization_overview,
    organization_email: activeProfile.organization_email,
    organization_founded: new Date(activeProfile.organization_founded),
    organization_website: activeProfile.organization_website,
    organization_phone_number: activeProfile.organization_phone_number,
    organization_size: activeProfile.organization_size
  }

  useEffect(() => {
    let unmounted = false;
    getOrganizationIndustries()
      .then(({ data }) => {
        if (!unmounted) {
          setIndustries(data.map((item) => ({ value: item.id, label: item.industry_title })));
        }
      });
    return () => { unmounted = true }; 
  }, []);


  // const validationSchema = yup.object().shape({

  //   org_name:yup.string().strict(false).trim().required('Organization name is required.'),
  //   org_industry:yup.string().strict(false).trim().required('Organization industry is required.'),
  //   org_size:yup.string().required('Organization Size is required'),
  //   org_abilities: yup.string().required('Your choice is required.'),
  //   org_website:yup.string(),
  //   org_email:yup .string().email().required('organization Email is required.'),
  //   org_linkedin:yup.string(),
  //   org_be:yup.string(),
  //   org_fb:yup.string(),
  //   org_cellNo:yup.string(),
  // });
  const onUpdateProfile = (values, { setSubmitting }) => {

    if (userCity !== null) {
      values.organization_city = Number(userCity)
      const data =
      {
        ...values,
        organization_founded: format(values.organization_founded, 'yyyy-MM-dd'),
        organization_industry: Number(values.organization_industry)
      }


      updateOrganization(activeProfile.slug, data)
        .then(() => {

          setSubmitting(false);

          onProfileUpdated();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
    else {
      setSubmitting(false);
      setCityValid(true)
    }


  };

  // const initialValues = {
  //   org_name: profile.Name,
  //   org_industry:profile.industry,
  //   org_size:profile.companysize,
  //   org_abilities: profile.ability,
  //   org_city: profile.city ? profile.city : null,
  //   org_website:"",
  //   org_email:"",
  //   org_linkedin:"",
  //   org_be:"",
  //   org_fb:"",
  //   org_cellNo:"",
  // }; 

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={closeDialog}
      className="EditProfileModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Organization Profile</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={orgSchema}
        initialValues={initialValues}
        onSubmit={onUpdateProfile}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
          handleSubmit,
          touched,
        }) => (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                  {/* <Row>
                    <Form.Group as={Col} sm={12} md={12}>
                      <Form.Label>
                        Organization Name
                    </Form.Label>
                      <Form.Control
                        name="org_name"
                        placeholder="Your organization name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_name}
                        isInvalid={touched.org_name && errors.org_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.org_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>
                        Organization Industry
                    </Form.Label>
                      <Form.Control
                        name="org_industry"
                        placeholder="Your organization industry"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_industry}
                        isInvalid={touched.org_industry && errors.org_industry}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.org_industry}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>
                        Organization Size
                    </Form.Label>
                      <Form.Control
                        name="org_size"
                        placeholder="Your organization Size"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_size}
                        isInvalid={touched.org_size && errors.org_size}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.org_size}
                      </Form.Control.Feedback>
                    </Form.Group>
                
                  </Row>             
                  <CountryCity 
                  citytext='Which City your organization is located?' 
                  countrytext='Which Country your organization is located?' 
                  cityValid={cityValid} 
                  setCityValid={setCityValid} 
                  setUserCity={setUserCity} 
                  person_city={profile.person_city}/>
                
                <Row>
                    <Form.Group as={Col} col={12} md={12}>
                      <Form.Label>Choose Accomadations for Special Ability</Form.Label>
                      <Form.Control
                        value={values.org_identifies_as}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="org_abilities"
                        as="select"
                        className="ic-form-select"
                        isInvalid={touched.org_abilities && errors.org_abilities}
                      >
                        <option selected disabled>Select One of the Below Options</option>
                        <option value="HEAR"> Hearing Impairment</option>
                        <option value="MOTOR"> Motor Impairment</option>
                        <option value="COGN"> Cognitive Impairment</option>
                        <option value="VISUL"> Visual Impairment</option>
                        <option value="SEN"> Sensory Impairment</option>
                        <option value="NON"> None</option>
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">
                        {errors.org_abilities}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Website Link</Form.Label>
                      <Form.Control
                        placeholder="Your organization's website link"
                        name="org_website"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_website}
                        isInvalid={touched.org_website && errors.org_website}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.org_website}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        placeholder="Your organization's Contact Number"
                        name="org_cellNo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_cellNo}
                        isInvalid={touched.org_cellNo && errors.org_cellNo}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.org_cellNo}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        placeholder="Your organization's email address"
                        name="org_email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_email}
                        isInvalid={touched.org_email && errors.org_email}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.org_email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>                
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>LinkedIn profile link</Form.Label>
                      <Form.Control
                        placeholder="Your organization's linkedIn profile link"
                        name="org_linkedin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_linkedin}
                        isInvalid={touched.org_linkedin && errors.org_linkedin}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.org_linkedin}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>                
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Behance profile link</Form.Label>
                      <Form.Control
                        placeholder="Your organization's behance profile link"
                        name="org_be"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_be}
                        isInvalid={touched.org_be && errors.org_be}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.org_be}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>             
                  <Row>
                    <Form.Group as={Col} col={12}>
                      <Form.Label>Facebook profile link</Form.Label>
                      <Form.Control
                        placeholder="Your organization's facebook profile link"
                        name="org_fb"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_fb}
                        isInvalid={touched.org_fb && errors.org_fb}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.org_fb}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
           */}

                  <Form.Group>
                    <Form.Label>Organization Title</Form.Label>
                    <Form.Control
                      placeholder="Enter Organization's title."
                      name="organization_title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.organization_title}
                      isInvalid={touched.organization_title && errors.organization_title}
                      type="text"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.organization_title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row>
                    <Form.Group as={Col} col={12} md={6}>
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        value={values.organization_industry}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="organization_industry"
                        as="select"
                        className="ic-form-select"
                        isInvalid={touched.organization_industry && errors.organization_industry}
                      >
                        {
                          industries.map((industry) => <option key={industry.value} value={industry.value}>{industry.label}</option>)
                        }
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">
                        {errors.organization_industry}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} col={12} md={6}>
                      <Form.Label>Founded Date</Form.Label>
                      <DatePicker
                        nativeInputAriaLabel="Select Organization's Founded Date"
                        placeholderText="When were you born?"
                        name="organization_founded"
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group>
                    <Form.Label>Main Branch Address</Form.Label>
                    <Form.Control
                      placeholder="Enter Organization's Main Address.'"
                      name="organization_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.organization_address}
                      isInvalid={touched.organization_address && errors.organization_address}
                      type="text"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.organization_address}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <CountryCity
                    citytext='Main Branch City'
                    countrytext='Main Branch Country'
                    cityValid={cityValid}
                    setCityValid={setCityValid}
                    setUserCity={setUserCity}
                    person_city={activeProfile.organization_city}
                    row
                  />
                  <Row>
                    <Form.Group as={Col} col={12} md={6}>
                      <Form.Label>Organization Email</Form.Label>
                      <Form.Control
                        placeholder="Enter Organization's Email Address."
                        name="organization_email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.organization_email}
                        isInvalid={touched.organization_email && errors.organization_email}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.organization_email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} col={12} md={6}>
                      <Form.Label>Organization website</Form.Label>
                      <Form.Control
                        placeholder="Enter Organization's Website."
                        name="organization_website"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.organization_website}
                        isInvalid={touched.organization_website && errors.organization_website}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.organization_website}
                      </Form.Control.Feedback>
                    </Form.Group>


                  </Row>
                  <Row>
                    <Form.Group as={Col} col={12} md={6}>
                      <Form.Label>Select Company Size</Form.Label>
                      <Form.Control
                        value={values.organization_size}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="organization_size"
                        as="select"
                        className="ic-form-select"
                        isInvalid={touched.organization_size && errors.organization_size}
                      >
                        <option value="" disabled>Select Company Size</option>
                        <option value="1">1 - 5 Employees</option>
                        <option value="2">5 - 20 Employees</option>
                        <option value="3">20 - 50 Employees</option>
                        <option value="4">50 - 250 Employees</option>
                        <option value="5">250 - 1000 Employees</option>
                        <option value="6">1000 - 10000 Employees</option>
                        <option value="7">10000 - 100000 Employees</option>
                        <option value="8">100000+ Employees</option>
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">
                        {errors.organization_size}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} col={12} md={6} >
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        placeholder="Enter Organization's Contact Number."
                        name="organization_phone_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.organization_phone_number}
                        isInvalid={touched.organization_phone_number && errors.organization_phone_number}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.organization_phone_number}
                      </Form.Control.Feedback>
                    </Form.Group>

                  </Row>
                  <Row>
                    <Form.Group as={Col} col={12} md={12}>
                      <Form.Label>Organization Tagline</Form.Label>
                      <Form.Control
                        placeholder="Enter Organization's Tagline."
                        name="organization_tagline"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.organization_tagline}
                        isInvalid={touched.organization_tagline && errors.organization_tagline}
                        type="text"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.organization_tagline}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>


                  <Form.Group className="form-group">
                    <Form.Label>Organization Overview</Form.Label>
                    <Form.Control
                      placeholder="Enter Organization's Overview."
                      name="organization_overview"
                      value={values.organization_overview}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="italic-placeholder textareaHeight"
                      as="textarea"
                      isInvalid={touched.organization_overview && errors.organization_overview}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.organization_overview}
                    </Form.Control.Feedback>
                  </Form.Group>

                </Modal.Body>

                <Modal.Footer>
                  <Container>
                    <Row>
                      <Col sm={12} md={6}>
                        <Button onClick={closeDialog} block className="modal-cancel-button">Cancel</Button>
                      </Col>
                      <Col className="mt-3 mt-md-0" sm={12} md={6}>
                        <Button type="submit" disabled={isSubmitting} block className="modal-submit-button">{isSubmitting ? <i className="fas fa-spinner fa-spin" /> : 'Save Changes'}</Button>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Footer>
              </Form>
            </>
          )}
      </Formik>
    </Modal>
  );
};

export default connect((state) => ({
  activeProfile: state.user.activeProfile,
}), {

})(EditOrganizationProfile);
