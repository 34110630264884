// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import { rejectApplicant, shortlistApplicant } from "../../../../../api";

//import * as actions from "../../../_redux/customers/customersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  //NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import ViewJobApplicant from "../../ViewJobApplicant";

import { processDate, getTimeAgo } from "../../../../../common/utils";

export function CustomersTable({ applicants, onStatusChange }) {
  // Customers UI Context

  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState(null);
  const [status, setStatus] = useState(null);
  const [applicantId, setApplicantId] = useState(null);
  const [jobId, setJobId] = useState(null);

  const [isSubmittingS, setIsSubmittingS] = useState(false);
  const [isSubmittingR, setIsSubmittingR] = useState(false);

  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  const totalCount = applicants.length;
  const entities = applicants.length ? applicants : null;

  const history = useHistory();
  const { pathname } = useLocation();

  const { path, url } = useRouteMatch();

  const match = useRouteMatch("/org/jobdetail/:jobslug/:jobId/applicants/:appId");

  useEffect(() => {
    if (match) {
      const { isExact, params } = match;
      if (isExact) {
        for (let i = 0; i < applicants.length; i++) {
          if (applicants[i].id === Number(params.appId)) {
            handleOpenEdit(applicants[i].applicant.id, applicants[i].applicant_status, Number(params.appId), Number(params.jobId));
            return;
          }
        }
      }
    }
  },[]);

  const handleOpenEdit = (profileId, status, appId, jobId) => {
    setEditId(profileId);
    setStatus(status);
    setShow(true);
    setApplicantId(appId);
    setJobId(jobId.id ? jobId.id : jobId);
    history.push(`${url}/${appId}`);
  };
  
  const handleChange = (e) => {
    if (e === "R") {
      setIsSubmittingR(true);
      rejectApplicant(jobId, applicantId, {})
        .then((resp) => {
          onStatusChange(resp.data.applicant, "R");
          setIsSubmittingR(false);
          setShow(false);
        })
        .catch((error) => {
          setIsSubmittingR(false);

          setShow(false);
        });
    } else if (e === "S") {
      setIsSubmittingS(true);

      shortlistApplicant(jobId, applicantId, {})
        .then((resp) => {
          onStatusChange(resp.data.applicant, "S");
          setShow(false);
          setIsSubmittingS(false);
        })
        .catch((error) => {
          setShow(false);
          setIsSubmittingS(false);
        });
    }
  };

  const columns = [
    {
      dataField: "applicant.id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "applicant.full_name",
      text: "Full Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "applicant.identifies_as",
      text: "Identifies As",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "applicant.person_city.city_name",
      text: "City",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Applied Date",
      sort: true,
      formatter: (data) => processDate(data, "PP"),
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "applicant_status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: (data) => {
        return (
          <div
            className={`label label-lg label-light-${
              data === "A"
                ? "info"
                : data === "S"
                ? "success"
                : data === "R"
                ? "danger"
                : ""
            } label-inline`}
          >
            {data === "A"
              ? "Applied"
              : data === "S"
              ? "Shortlist"
              : data === "R"
              ? "Reject"
              : ""}
          </div>
        );
      },
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: handleOpenEdit,
        openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
        isSubmittingR: isSubmittingR,
        isSubmittingS: isSubmittingS,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={false} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: customersUIProps.ids,
                  setIds: customersUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      {/* {editId && ( */}
        <ViewJobApplicant
          show={show}
          //   onUpdate={onStatusChange}
          closeDialog={() => setShow(false)}
          profileId={editId}
          status={status}
          jobId={jobId}
          applicationId={applicantId}
          isSubmittingS={isSubmittingS}
          isSubmittingR={isSubmittingR}
          handleChange={handleChange}
        />
      {/* )} */}
    </>
  );
}

const NoRecordsFoundMessage = ({ entities }) => {
  const customersList = entities === null ? [] : entities;
  return (
    <>
      {customersList.length === 0 && entities !== null && (
        <div>No records found</div>
      )}
    </>
  );
};
