import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

import downArrow from '../assets/images/ArrowDown.png';
import calendarIcon from '../assets/images/calendar.png';
import locationIcon from '../assets/images/location-pin.png';

const StyledForm = styled(Form)`
  max-width: 850px;
  width: 100%;
  min-width: 200px;
  white-space: pre-line;

  label {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    color: #757575;
    font-size: 1rem;
  }

  p.ic-form-notes {
    background-color: #edeaf6;
    padding: 15px 20px;
    border-radius: 0.4rem;
    margin-top: 25px;
  }

  p.ic-form-notes label {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2.25;
  }

  @media (max-width: 480px) {
    p.ic-form-notes label {
      line-height: 1.3;
    }
  }

  input[type="text"] {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    padding: 25px 20px;
    border-radius: 0.4rem;
    border: solid 1.9px #dadbe2;
        white-space: pre-line;
  }

  input[type="text"]:focus {
    outline: 0 !important;
    box-shadow: none !important;

    border-color: #80bdff !important;

    &.is-invalid {
      border-color: #B11030 !important;
    }
  }

  input[type="text"]::-webkit-input-placeholder {
    color: #a6a7ab;
  }

  input[type="text"]::-moz-placeholder {
    color: #a6a7ab;
  }

  input[type="text"]:-ms-input-placeholder {
    color: #a6a7ab;
  }

  input[type="text"]::-ms-input-placeholder {
    color: #a6a7ab;
  }

  input[type="text"]::placeholder {
    color: #a6a7ab;
  }

  input[type="search"]:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border-color: #f8f7fc !important;
  }

  input[type="search"]::-webkit-input-placeholder {
    color: #a6a7ab;
  }

  input[type="search"]::-moz-placeholder {
    color: #a6a7ab;
  }

  input[type="search"]:-ms-input-placeholder {
    color: #a6a7ab;
  }

  input[type="search"]::-ms-input-placeholder {
    color: #a6a7ab;
  }

  input[type="search"]::placeholder {
    color: #a6a7ab;
  }

  input[type="email"] {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    padding: 27px 20px;
    border-radius: 0.4rem;
    border: solid 1.9px #dadbe2;
  }

  input[type="email"]:focus {
    outline: 0 !important;
    box-shadow: none !important;

    border-color: #80bdff !important;

    &.is-invalid {
      border-color: #B11030 !important;
    }
  }

  input[type="password"] {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    padding: 27px 20px;
    border-radius: 0.4rem;
    border: solid 1.9px #dadbe2;
  }

  input[type="password"]:focus {
    outline: 0 !important;
    box-shadow: none !important;

    border-color: #80bdff !important;

    &.is-invalid {
      border-color: #B11030 !important;
    }
  }

  input[type="date"] {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    padding: 27px 20px;
    border-radius: 0.4rem;
    border: solid 1.9px #dadbe2;
    padding-right: 45px;
  }

  input[type="date"]:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }

  input.is-invalid {
    border-color: #B11030 !important;
  }

  .form-control {
    color: #000000;
  }

  select.ic-form-select {
    display: block;
    color: #000000;
    border-radius: 0.4rem;
    height: auto;
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    padding: 15px 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(${downArrow}), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
    background-image: url(${downArrow}), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 1.5em top 50%, 0 0;
    background-size: 1.5em auto, 100%;
    border: solid 1.9px #dadbe2;
  }

  select.ic-form-select:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border-color: #80bdff !important;

    &.is-invalid {
      border-color: #B11030 !important;
    }
  }

  select.ic-form-select.is-invalid {
    border-color: #B11030 !important;
  }

  .ic-form-inline {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
  }

  .ic-form-inline input[type="text"]:not(#current-city) {
    max-width: 90px;
    min-width: 0;
    margin-right: 5px;
  }

  @media (max-width: 480px) {
    .ic-form-inline input[type="text"]:not(#current-city) {
      max-width: 70px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .ic-form-inline a {
    margin-left: 15px;
  }

  .ic-form-inline a img {
    max-width: 50px;
  }

  .ic-form-inline-fullwidth {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .ic-form-inline-fullwidth .form-group {
    padding: 0;
  }

  .ic-form-inline-fullwidth .form-group input[type="date"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(${calendarIcon}), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
    background-image: url(${calendarIcon}), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 1.5em top 50%, 0 0;
    background-size: 1.5em auto, 100%;
  }

  .ic-form-inline-fullwidth .form-group input[type="date"]::before {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .ic-form-inline-fullwidth .form-group:nth-child(1) {
    padding-right: 20px;
  }

  @media (max-width: 768px) {
    .ic-form-inline-fullwidth .form-group:nth-child(1) {
      padding-right: 0;
    }
  }

  .ic-form-inline-fullwidth .form-group:nth-child(2) {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    .ic-form-inline-fullwidth .form-group:nth-child(2) {
      padding-left: 0;
    }
  }

  textarea {
    max-width: 850px;
    width: 100%;
    min-width: 200px;
    padding: 27px 20px;
    border-radius: 0.4rem;
    border: solid 1.9px #dadbe2 !important;
    max-height: 200px;
    // height: 170px !important;
    white-space: pre-line;
  }

  textarea:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border-color: #80bdff !important;

    &.is-invalid {
      border-color: #B11030 !important;
    }
  }

  textarea.is-invalid {
    border-color: #B11030 !important;
  }

  input[id="current-city"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(${locationIcon}), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
    background-image: url(${locationIcon}), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 1.5em top 50%, 0 0;
    background-size: 1em auto, 100%;
    padding-right: 45px;
  }

  select#current-city {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(${locationIcon}), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
    background-image: url(${locationIcon}), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 1.5em top 50%, 0 0;
    background-size: 1em auto, 100%;
  }

  .ic-form-badge {
    position: relative;
    margin-top: 15px;
    color: #3F0E40;
    font-weight: 500;
  }

  .ic-form-badge span.badge {
    color: white;
    background-color: #3F0E40;
    border-radius: 50%;
    height: 22.2px;
    width: 22.2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ic-form-check-wrapper {
    margin-bottom: 0px;
  }

  .ic-form-check-wrapper .ic-form-check {
    margin-right: 20px;
  }

  .ic-form-check-wrapper .ic-form-check .ic-form-check-label {
    min-width: 140px;
    text-align: center;
    display: block;
    position: relative;
    padding: 20px 15px;
    cursor: pointer;
    background-color: #ffffff;
    border: solid 1px #B11030;
    border-radius: 0.4rem;
    color: #B11030;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    /* Hide the browser's default checkbox */
  }

  @media (max-width: 576px) {
    .ic-form-check-wrapper .ic-form-check .ic-form-check-label {
      font-size: 17px;
    }
  }

  .ic-form-check-wrapper .ic-form-check .ic-form-check-label input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .ic-form-check-wrapper .ic-form-check .ic-form-check-label:hover {
    box-shadow: 0px 4px 10px 0px rgba(39, 50, 82, 0.6);
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
  }

  .ic-form-check-wrapper .ic-form-check .isChecked {
    color: #ffffff;
    box-shadow: 0 0 73px 4px rgba(0, 0, 0, 0.06);
    background-image: linear-gradient(252deg, #ee6f78 50%, #f34652 70%);
    background-image: -o-linear-gradient(263deg, #ee6f78 40%, #B11030 70%);
  }

  .form-control.datepicker {
    width: 100%;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 30px;
`;

export default StyledForm;
