

import React from "react";
import OrgJobs from './Jobs'

import '../../Dashboard.scss';


const Dashboard = () => {
  const className=''
  return (
<div className="row">
    <div className="col-lg-12">
        <OrgJobs/>
    </div>
</div>


  );

}

export default Dashboard