import React, { useState, useRef, useEffect } from "react"; 
import { useDispatch } from "react-redux";
import { addToast } from "../../../../../redux/toast";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getJobScreeningQuestions, postJobScreeningQuestion, updateJobScreeningQuestion, deleteJobScreeningQuestion, updateJobScreeningQuestionChoice, deleteJobScreeningQuestionChoice } from '../../../../../api';


const JobScreeningQuestions = ({ jobSlug, jobId, postScreeningQuestions, setPostScreeningQuestions, submitForm, setSubmitForm }) => {
    const dispatch = useDispatch();
    const lastQuestion = useRef(null);
    const [validQuestion, setValidQuestion] = useState(true);
    const [validChoice, setValidChoice] = useState(true);
    const [invalidIndex, setInvalidIndex] = useState(null);
    const [screeningQuestions, setScreeningQuestions] = useState([]);
    const [hasSelectedChoice, setHasSelectedChoice] = useState(true);

    useEffect(() => {
        let unmounted = false;
        getJobScreeningQuestions(jobSlug)
        .then(({data}) => {
            if (data.length) {
                for (let i = 0; i < data.length; i++) {
                    if (!unmounted) {
                        setScreeningQuestions(data);
                    }
                }
            }
            else {
                if (!unmounted) {
                    setScreeningQuestions([{question: "", job_question_choice: [{choice: "", is_answer: false}]}]);
                }
            }
        })
        .catch(() => {
            dispatch (
                addToast({
                  type: "error",
                  title: "Error",
                  text: "An error occurred, please try again.",
                })
            )
        });
    return () => { unmounted = true }; 
    },[]);

    useEffect(() => {
        let unmounted = false;
        if (postScreeningQuestions) {
            const filteredQuestions = filterQuestions();
            if (filteredQuestions && filteredQuestions.length) {
                for (let i = 0; i < filteredQuestions.length; i++) {
                    if (filteredQuestions[i].id) {
                        const questionId = screeningQuestions[i].id;
                        const choices = screeningQuestions[i].job_question_choice;
                        updateJobScreeningQuestion(jobSlug, questionId, {
                            job: jobId,
                            question: screeningQuestions[i].question
                        })
                        .then(({data}) => {
                            for (let j = 0; j < choices.length; j++) {
                                const choiceId = choices[j].id;
                                updateJobScreeningQuestionChoice(jobSlug, questionId, choiceId, {
                                    job: jobId,
                                    choice: choices[j].choice,
                                    is_answer: choices[j].is_answer
                                })
                                .then(({data}) => {
                                    if (!unmounted) {
                                        setSubmitForm(true);
                                    }
                                })
                                .catch(() => {
                                    dispatch (
                                        addToast({
                                          type: "error",
                                          title: "Error",
                                          text: "An error occurred, please try again.",
                                        })
                                    )
                                });
                            }
                        })
                        .catch(() => {
                            dispatch (
                                addToast({
                                  type: "error",
                                  title: "Error",
                                  text: "An error occurred, please try again.",
                                })
                            )
                        });
                    }
                    else {
                        postJobScreeningQuestion(jobSlug, {
                            job: jobId,
                            question: screeningQuestions[i].question,
                            job_question_choice: screeningQuestions[i].job_question_choice
                        }).then(({data}) => {
                            if (!unmounted) {
                                setSubmitForm(true);
                            }
                        })
                        .catch(() => {
                            dispatch (
                                addToast({
                                  type: "error",
                                  title: "Error",
                                  text: "An error occurred, please try again.",
                                })
                            )
                        });
                    }
                }
            }
            else if (filteredQuestions !== null) {
                if (!unmounted) {
                    setSubmitForm(true);
                }
            }
            else {
                if (!unmounted) {
                    setSubmitForm(false);
                    setPostScreeningQuestions(false);
                }
            }
        }
        window.scrollTo({ top: 0, behavior: 'smooth'});
    return () => { unmounted = true }; 
    }, [postScreeningQuestions]);


    const handleQuestion = (index, questionText) => {
        const questionArray = [...screeningQuestions];
        questionArray[index].question = questionText;
        if (questionText !== "") {
            setValidQuestion(true);
        }
        else {
            setValidQuestion(false);
            setInvalidIndex(index);
        }
        setScreeningQuestions(questionArray);
    }

    const handleAddQuestion = (index) => {
        const valid = validateQuestion(index);
        if (valid) {
            setScreeningQuestions((screeningQuestions) => [...screeningQuestions, {question: "", job_question_choice: [{choice: "", is_answer: false}]}]);
            if (lastQuestion) {
              lastQuestion.current.scrollIntoView({behavior: "smooth"});
            }
        }
    }
  
    const handleRemoveQuestion = (index, questionId) => {
      if (questionId) {
          deleteJobScreeningQuestion(jobSlug, questionId)
            .then(() => {
                let filteredArray = [...screeningQuestions];
                filteredArray = filteredArray.slice(0, index).concat(filteredArray.slice(index + 1));
                if (screeningQuestions.length === 1) {
                    setScreeningQuestions([{question: "", job_question_choice: [{choice: "", is_answer: false}]}])
                  }
                else {
                    setScreeningQuestions(filteredArray);
                }
            })
            .catch(() => {
                dispatch (
                    addToast({
                        type: "error",
                        title: "Error",
                        text: "An error occurred, please try again.",
                    })
               )
            });
        }
        else {
            let filteredArray = [...screeningQuestions];
            filteredArray = filteredArray.slice(0, index).concat(filteredArray.slice(index + 1));
            if (screeningQuestions.length === 1) {
                setScreeningQuestions([{question: "", job_question_choice: [{choice: "", is_answer: false}]}])
              }
            else {
                setScreeningQuestions(filteredArray);
            }
        }
    };

    const validateQuestion = (index) => {
        const lastIndex = screeningQuestions[index].job_question_choice.length-1;
        if (screeningQuestions[index].question === "") {
            setValidQuestion(false);
            setInvalidIndex(index);
            return false;
        } 
        else if (screeningQuestions[index].job_question_choice[lastIndex].choice === "") {
            setValidChoice(false);
            setInvalidIndex(lastIndex);
            return false;
        }
        else {
            const selectedChoice = validateSelectedChoice(index);
            if (selectedChoice) {
                setValidQuestion(true);
                setValidChoice(true);
                setInvalidIndex(null);
                return true;
            }
            return false;
        }
    }

    const handleChoice = (index, choiceText, question) => {
        const questionIndex = screeningQuestions.indexOf(question);
        const questionArray = [...screeningQuestions];
        questionArray[questionIndex].job_question_choice[index].choice = choiceText; 
        if (choiceText !== "") {
            setValidChoice(true);
        }
        else {
            setValidChoice(false);
            setInvalidIndex(index);
        }
        setScreeningQuestions(questionArray);
    }

    const handleAddChoice = (index, question) => {
        const questionIndex = screeningQuestions.indexOf(question);
        const valid = validateChoice(index, questionIndex);
        if (valid) {
            const updatedQuestion = {...question, job_question_choice: [...question.job_question_choice, {choice: "", is_answer: false}]};
            const questionArray = [...screeningQuestions];
            questionArray[questionIndex] = updatedQuestion;
            setScreeningQuestions(questionArray);
        }
    }

    const handleRemoveChoice = (index, questionId, choiceId, question) => {
        const questionIndex = screeningQuestions.indexOf(question);
        const choiceArray = question.job_question_choice;
        if (choiceId) {
            deleteJobScreeningQuestionChoice(jobSlug, questionId, choiceId)
                .then(() => {
                    let filteredArray = [...screeningQuestions];
                    if (choiceArray.length === 1) {
                        filteredArray[questionIndex].job_question_choice = [{choice: "", is_answer: false}];
                    }
                    else {
                        filteredArray[questionIndex].job_question_choice = choiceArray.slice(0, index).concat(choiceArray.slice(index + 1));
                    }
                    setScreeningQuestions(filteredArray);
                })
                .catch(() => {
                    dispatch (
                        addToast({
                            type: "error",
                            title: "Error",
                            text: "An error occurred, please try again.",
                        })
                    )
              });
          }
        else {
            let filteredArray = [...screeningQuestions];
                if (choiceArray.length === 1) {
                    filteredArray[questionIndex].job_question_choice = [{choice: "", is_answer: false}];
                }
                else {
                    filteredArray[questionIndex].job_question_choice = choiceArray.slice(0, index).concat(choiceArray.slice(index + 1));
                }
            setScreeningQuestions(filteredArray);
        }
    }

    const validateChoice = (index, questionIndex) => {
        if (screeningQuestions[questionIndex].job_question_choice[index].choice === "") {
            setValidChoice(false);
            setInvalidIndex(index);
            return false;
        } 
        else {
            setValidChoice(true);
            setInvalidIndex(null);
            return true;
        }
    }

    const validateSelectedChoice = (index) => {
        let selected;
        const choiceArray = screeningQuestions[index].job_question_choice;
        for (let i = 0; i < choiceArray.length; i++) {
            if (choiceArray[i].is_answer === true) {
                setHasSelectedChoice(true);
                selected = true;
                return selected;
            }
            else {
                setHasSelectedChoice(false);
                selected = false;
            }
        }
        return selected;
    }
 
    const toggleCorrectChoice = (index, question) => {
        const questionIndex = screeningQuestions.indexOf(question);
        const correctChoice = question.job_question_choice[index].is_answer;
        const questionArray = [...screeningQuestions];
        questionArray[questionIndex].job_question_choice[index].is_answer = !correctChoice;
        setScreeningQuestions(questionArray);
        setHasSelectedChoice(true);
    }

    const filterQuestions = () => {
        const lastIndex = screeningQuestions.length - 1;
        const lastChoiceIndex = screeningQuestions[lastIndex].job_question_choice.length-1;
        if (screeningQuestions[lastIndex].question === "") {
            const filteredArray = [...screeningQuestions];
            filteredArray.splice(lastIndex, 1);
            return filteredArray;
        }
        else if (screeningQuestions[lastIndex].question !== "" && screeningQuestions[lastIndex].job_question_choice[lastChoiceIndex].choice === "") {
            setValidChoice(false);
            setInvalidIndex(lastChoiceIndex);
            return null;
        } 
        else {
            setValidChoice(true);
            setInvalidIndex(null);
            return screeningQuestions;
        }
    }

return (
        <> 
        {submitForm ? (
        <div>
            {screeningQuestions && screeningQuestions.length ? (
                screeningQuestions.map((question, index) => (
                    question.question === "" ? (
                    <div key={index} className="screening-question" style={{paddingBottom: '6rem'}}>
                        No screening questions added. Click POST to post the job.
                    </div>
                    ) : (
                    <div key={index} className="screening-question-container">
                        <div className="screening-question pb-2">
                            {question.question}
                        </div>
                        {question.job_question_choice.map((choice, index) => (
                        <div key={index} className="screening-question-answer py-1 d-flex align-items-center"> 
                            {choice.is_answer ? (<i className="material-icons mr-2" style={{color: "#00cc00"}}>check</i>) : (<i className="material-icons mr-2">clear</i>)}
                            {choice.choice}
                        </div>
                        ))}
                    </div>
                )))
            ):""}
        </div>
        ) : (
            screeningQuestions && screeningQuestions.length ? (
                 screeningQuestions.map((question, index) => (
                    <div key={index} className={`${index !== 0 ? 'question-container' : ''}`} ref={index+1 === screeningQuestions.length ? lastQuestion : null}>
                
                        <Form.Row>
                            <Form.Group as={Col} col={12}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Form.Label htmlFor={`${index+1}-question_title`} className="question-title">
                                        Question
                                    </Form.Label>
                                    {index + 1 === screeningQuestions.length ? (
                                        <>
                                        {/* {screeningQuestions.length < 5 && (
                                            <Button className="add-question-btn" onClick={() => handleAddQuestion(index)} aria-label="Add Question">
                                                <i className="material-icons">add_circle</i>
                                            </Button>
                                        )} */}
                                        {(index !== 0 || index === 0 && screeningQuestions[index].question !== "") && (
                                            <Button className="remove-question-btn ml-2" onClick={() => handleRemoveQuestion(index, question.id)} aria-label="Delete Question">
                                                <i className="material-icons" style={{fontSize: "26px"}}>remove_circle_outline</i>
                                            </Button>
                                        )}
                                        </>
                                    ) : (
                                        <Button className="remove-question-btn ml-2" onClick={() => handleRemoveQuestion(index, question.id)} aria-label="Delete Question">
                                            <i className="material-icons" style={{fontSize: "26px"}}>remove_circle_outline</i>
                                        </Button>
                                    )}
                                </div>
                                <Form.Control
                                    value={question.question}
                                    placeholder="Please type a question."
                                    name="question_title"
                                    onChange={(e) => handleQuestion(index, e.target.value)}
                                    type="text"
                                    as="textarea"
                                    rows={3}
                                    isInvalid={!validQuestion && invalidIndex === index}
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Please provide the screening question.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        {question.job_question_choice.map((choice, index) => (
                                <Form.Row key={index}>
                                    <Form.Group as={Col} col={12}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Form.Label>Answer {`${index+1}`}</Form.Label>
                                            {index + 1 === question.job_question_choice.length ? (
                                                <>
                                                {question.job_question_choice.length < 4 && (
                                                    <Button className="add-question-btn" onClick={() => handleAddChoice(index, question)} aria-label="Add Choice">
                                                        <i className="material-icons" style={{fontSize: "26px"}}>add_circle</i>
                                                    </Button>
                                                )}
                                                {(index !== 0 || index === 0 && question.job_question_choice[index].choice !== "") && (
                                                    <Button className="remove-question-btn ml-2" onClick={() => handleRemoveChoice(index, question.id, choice.id, question)} aria-label="Delete Choice">
                                                        <i className="material-icons" style={{fontSize: "26px"}}>remove_circle_outline</i>
                                                    </Button>
                                                )}
                                                </>
                                            ) : (
                                                <Button className="remove-question-btn ml-2" onClick={() => handleRemoveChoice(index, question.id, choice.id, question)} aria-label="Delete Choice">
                                                    <i className="material-icons" style={{fontSize: "26px"}}>remove_circle_outline</i>
                                                </Button>
                                            )} 
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Form.Check 
                                                type="checkbox" 
                                                aria-label="Choice"
                                                checked={question.job_question_choice[index].is_answer}
                                                onChange={() => toggleCorrectChoice(index, question)}
                                                onKeyUp={(event) => event.key === "Enter" ? toggleCorrectChoice(index, question):""}
                                            />
                                            <Form.Control
                                                placeholder="Please type an answer."
                                                onChange={(e) => handleChoice(index, e.target.value, question)}
                                                name="question_choice"
                                                isInvalid={!validChoice && invalidIndex === index}
                                                value={question.job_question_choice[index].choice}
                                                type="text"
                                                as="input"
                                                autoComplete="off"
                                            />  
                                            <Form.Control.Feedback type="invalid" style={{position: 'absolute', top: '85px'}}>
                                                Please provide the answer.
                                            </Form.Control.Feedback>                  
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                        ))}
                            {index + 1 === screeningQuestions.length && screeningQuestions.length < 5 ? (
                                <Button className="add-question-btn py-3" onClick={() => handleAddQuestion(index)} aria-label="Add Question">
                                    <i className="material-icons">add_circle</i> Add Question
                                </Button>
                            ) : ""}
                    </div>
                    ))
            ) : "" )}

        {!hasSelectedChoice && (
            <Form.Label className="invalid-section-feedback">Please select atleast one answer as the correct answer.</Form.Label>
        )}

        {screeningQuestions.length === 5 && (
            <Form.Label className="job-post-info">Screening questions have a maximum limit of 5.</Form.Label>
        )}
    
        </>
    )
};

export default JobScreeningQuestions;