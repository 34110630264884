import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink as Link, useRouteMatch } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ImageUploader from "../../../components/image/ImageUploader";
import NavProfile from "./NavProfile";
import { addToast } from "../../../redux/toast";
import { setSelectedImage } from "../../../redux/photos";
import ReadMoreAndLess from "react-read-more-less";

import { updateProfile } from "../../../api";

const Bio = ({
  isOwner,
  profile,
  refreshProfile,
  addToast,
}) => {
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [photoUploading, setPhotoUploading] = useState(false);
  const [show, setShow] = useState(false);
  const { url } = useRouteMatch();

  const onPhotoChanged = (image) => {
    setPhotoUploading(true);

    const formData = new FormData();
    formData.append("person_display_picture", image, image.name);

    updateProfile(profile.id, formData)
      .then(() => {
        setPhotoUploading(false);
        refreshProfile();

        addToast({
          title: "Success",
          text: "Your photo has been uploaded.",
          type: "success",
        });

        setShowImageDialog(false);
      })
      .catch(() => {
        setPhotoUploading(false);

        addToast({
          title: "Error",
          text: "An error occurred.",
          type: "error",
        });
      });
  };

  return (
    <section className="dashboard-left-sidebar">
      <div className="fixedsidebar">
        <aside className="bioAside">
          <div className="left-sidebar">
            <div className="left-sidebar--wrapper">
              <div className="container-fluid">
                {/* <!-- Start user details. --> */}
                <div className="user-picture">
                  {isOwner ? (
                    <ImageUploader
                      isUploading={photoUploading}
                      onPhotoChanged={onPhotoChanged}
                      onClose={() => setShowImageDialog(false)}
                      show={showImageDialog}
                    />
                  ) : null}
                  <div className="user-picture--wrapper d-flex flex-column align-items-center">
                    {isOwner ? (
                      <React.Fragment>
                        <a
                          onClick={() => setShow(true)}
                          className="user-picture-btn"
                          role="button"
                          style={{ position: 'relative' }}
                        >
                          <picture>
                            <img
                              src={profile.person_display_picture}
                              alt={`${profile.person_first_name}'s profile picture`}
                              title={`${profile.person_first_name}'s profile picture`}
                            />
                          </picture>
                        </a>
  
                        <Button
                          onClick={() => setShowImageDialog(true)}
                          className="d-flex"
                          style={{ backgroundColor: '#fff', color: '#5F5F5F', border: '2px solid #5F5F5F', padding: '0.25rem', borderRadius: '50%', position: 'absolute', right: '24%', bottom: '12px' }}
                        >
                          <i className="material-icons" style={{ color: '#5F5F5F' }}>edit</i>
                        </Button>

                      </React.Fragment>
                    ) : (
                      <a
                        onClick={() => setShow(true)}
                        className="user-picture-btn"
                        role="button"
                      >
                        <picture>
                          <img
                            src={profile.person_display_picture}
                            alt={`${profile.person_first_name}'s profile picture`}
                            title={`${profile.person_first_name}'s profile picture`}
                          />
                        </picture>
                      </a>
                    )}
                  </div>
                </div>

                <div
                  className={
                    "row user-details-container d-flex flex-column justify-content-center text-center pb-0 mb-3"
                  }
                >
                  <div className="user-name">
                    <div className="user-name--wrapper">
                      <span className={"user-name-btn"}>
                        {profile.person_first_name.charAt(0).toUpperCase() +
                          profile.person_first_name.slice(1)}
                        &nbsp;
                        {profile.person_last_name.charAt(0).toUpperCase() +
                          profile.person_last_name.slice(1)}
                      </span>
                    </div>
                  </div>
                  <div className="user-app-type">
                    <div className={"user-app-type--wrapper"}>
                      <span>{profile.person_headline}</span>
                    </div>
                  </div>
                  {profile.person_city && (
                    <div className="user-app-loc">
                      <div className={"user-app-loc--wrapper "}>
                        <span className="locText">
                          {profile.person_city && profile.person_city.city_name}
                          ,{" "}
                          {profile.person_city &&
                            profile.person_city.city_country.country_name}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="user-app-activity">
                    <div className="user-app-activity--wrapper">
                      <div className="userPost">
                        <span className="user-activity-number" style={{ paddingBottom: '5px' }}>
                          {profile.post_count}
                        </span>
                        <span className="user-activity-text">Posts</span>
                      </div>
                      <div className="userPost userSupporter">
                        <Link
                          to={`${url}/followers`}
                          className="profile-nav-link"
                          href="followers.html"
                        >
                          <span
                            className="user-activity-number"
                            style={{ display: "block" }}
                          >
                            {profile.follower_count}
                          </span>
                          <span className="user-activity-text"> Followers</span>
                        </Link>
                      </div>
                      <div className="userPost">
                        <Link
                          to={`${url}/following`}
                          className="profile-nav-link"
                          href="followers.html"
                        >
                          <span
                            className="user-activity-number"
                            style={{ display: "block" }}
                          >
                            {profile.following_count}
                          </span>
                          <span className="user-activity-text">Following</span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="user-app-tagline">
                    <div className="user-app-tagline--wrapper">
                      {profile && (
                        <ReadMoreAndLess
                          className="read-more-content my-story-link"
                          charLimit={250}
                          readMoreText="Read more"
                          readLessText="Read less"
                        >
                          {profile && profile.person_summary}
                        </ReadMoreAndLess>
                      )}
                    </div>
                  </div>
                  <NavProfile
                    isOwner={isOwner}
                    name={profile.person_first_name}
                  />
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>

      <Modal
        show={show}
        centered
        size="md"
        onHide={() => setShow(false)}
        dialogClassName="modelImage"
      >
        <Modal.Body className="modalBodyImg pt-0 pl-0 pr-0 pb-0">
          <img
            style={{ width: "100%" }}
            src={profile.person_display_picture}
            alt="User avatar"
            title="User Avatar"
          />
          <span className="closeBtn" onClick={() => setShow(false)}>
            &#9447;
          </span>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default connect(
  (state) => ({
    attendingEvents: state.user.attendingEvents,
  }),
  {
    setSelectedImage,
    addToast,
  }
)(Bio);
