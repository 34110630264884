import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import "emoji-mart/css/emoji-mart.css";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Modal from "react-bootstrap/Modal";
import { getTimeAgo, getTimeAgoArabicShort } from "../../common/utils";
import { setSelectedImage } from "../../redux/photos";
import { addToast } from "../../redux/toast";
import Images from "../image/Images";
import ImageCarousal from "./../image/ImageCarousal";
import PostDetail from "./PostDetail";
import PostedByOn from "./PostedByOn";
import PostShareOptions from "./PostShareOptions";
import Reactions from './PostCardReactions';
import ReactionsBar from './PostReactionsBar';
import Comments from "./PostCardComments";
import RichLinkPreview from "./../RichLinkPreview";
import ReportContent from "../ReportContentModal";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
  deletefeedPost,
  getFollowPost,
  followPost,
  unFollowPost,
} from "../../api";

const PostCard = ({
  posterName,
  activityId,
  postedBy,
  postedOn,
  postedTo,
  picture,
  content,
  comments,
  reactions,
  activeProfile,
  setSelectedImage,
  isFeed,
  addToast,
  onDelete,
  photos,
  onDeletePic,
  postDetail,
  fullpost,
  imgIndex,
  isfull,
}) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [selectedImgsIndex, setSelectedImgIndex] = useState(0);
  const [follow, setFollow] = useState(false);
  const [bodyWithMention, setBodyWithMention] = useState(content);
  const [isMore, setIsMore] = useState(false);
  const [firstHalf, setFirstHalf] = useState("");
  const [secondHalf, setSecondHalf] = useState("");
  const textRef = useRef(null);
  const [commentNumber, setCommentNumber] = useState(comments.length);
  const { t } = useTranslation();
  const direction = i18n.dir();
  const language = i18n.language;
  const altTextTitle = t("Image description of photo in link:");
  const [reactionCount, setReactionCount] = useState(reactions.count);
  const [reactionList, setReactionList] = useState(reactions.reaction_list);
  const [showReactions, setShowReactions] = useState(false);
  const [showPostReactionDropdown, setShowPostReactionDropdown] = useState(false);
  const [ownPost, setOwnPost] = useState(false);
  const verifiedPost = fullpost.verified;
  const [showReportModal, setShowReportModal] = useState(false);

  //Replace "Image description of photo in link:" in the text with the translated version.
  content = content.replace("Image description of photo in link:", altTextTitle).replace(":وصف الصورة للصورة في الرابط", altTextTitle);
 
  //Handling post with mention
  let bodyWithMentionw = content;
  useEffect(() => {
    if (fullpost) {
      let index;
      //if there is a mention, replace it with a link to Org or User profile.
      if (fullpost.mentions.length) {
        fullpost.mentions.forEach((element) => {
          if (element.mention.organisation) {
            //by using 'g' i.e. global, we are looking for all occurences of element.mention_title, not just the first one
            bodyWithMentionw = bodyWithMentionw.replace(
              new RegExp(element.mention_title, "g"),
              `<a href="/o/${element.mention.organisation.slug}">${element.mention_title}</a>`
            );
          } else {
            bodyWithMentionw = bodyWithMentionw.replace(
              new RegExp(element.mention_title, "g"),
              `<a href="/p/${element.mention.person.slug}">${element.mention_title}</a>`
            );
          }
        });
      }

      //Handling 280 char limit.
      if (content.length > 280) {
        //Dividing the text into two spans and switching between them using handleShowMore and handleShowLess functions.
        const text = bodyWithMentionw;
        const firsthalf = bodyWithMentionw
          .substring(0, 280)
          .concat(
            `...  <span className='readMoreText' id='showmore'>${t("Show More")}</span>`
          );
        const seconfhalf = bodyWithMentionw.concat(
          ` <span className='readMoreText' id='showless'>${t("Show Less")}</span>`
        );

        setBodyWithMention(firsthalf);
        setFirstHalf(firsthalf);
        setSecondHalf(seconfhalf);

        setIsMore(true);
      } else setBodyWithMention(bodyWithMentionw);
    }
  }, []);

  const handleShowMore = () => {
    setBodyWithMention(secondHalf);
  };
  const handleShowLess = () => {
    setBodyWithMention(firstHalf);
  };

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest("a");
    const spanshow = e.target.closest("span");
    if (spanshow) {
      if (spanshow.id === "showmore") {
        handleShowMore();
      } else if (spanshow.id === "showless") {
        handleShowLess();
      }
    }

    if (!targetLink) return;
    //Adding check to ensure link is treated as external link if it doesn't contain inclusive/localhost/p//o/.
    if (
      (!targetLink.href.includes("/p/") && !targetLink.href.includes("/o/")) ||
      (!targetLink.href.includes("inclusive") &&
        !targetLink.href.includes("localhost"))
    )
      return;
    e.preventDefault();

    const sp = targetLink.href.includes("/p/") ? "/p/" : "/o/";
    const link = targetLink.href.split(sp)[1];
    history.push(sp + link);
  };

  //Follow/UnFollow post
  useEffect(() => {
    let unmounted = false;
    if (postedBy.id !== activeProfile.owner_id)
      getFollowPost(activityId, activeProfile.owner_id)
        .then((res) => {
          if (!unmounted){
            if (res.status === 204) setFollow(false);
            else setFollow(true);
          }
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: "Error",
              text: "An error occurred, please try again.",
            });
          }
        });
    if (postedBy.id === activeProfile.owner_id) {
      setOwnPost(true);
    }
    return () => { unmounted = true };
  }, [activityId]);

  const followUnfollowPost = () => {
    setIsLoading(true);
    if (!follow)
      followPost(activityId, activeProfile.owner_id)
        .then((res) => {
          setFollow(true);
          addToast({
            title: t("Success"),
            text: t(`Post Followed Successfully`),
            type: "success",
          });
          setIsLoading(false);
        })
        .catch((error) => {
          if (Object.values(error)[2].status === 405) {
            addToast({
              type: "error",
              title: "Error",
              text: "Sorry, you're not authorized to perform this operation.",
            });
          }
          else {
            addToast({
              type: "error",
              title: t("Error"),
              text: t("An error occurred, please try again."),
            });
          }
          setIsLoading(false);
        });
    else
      unFollowPost(activityId, activeProfile.owner_id)
        .then((resp) => {
          setFollow(false);
          addToast({
            title: t("Success"),
            text: t(`Post Unfollowed Successfully`),
            type: "success",
          });
          setIsLoading(false);
        })
        .catch((error) => {
          addToast({
            type: "error",
            title: t("Error"),
            text: t("An error occurred, please try again."),
          });
          setIsLoading(false);
        });
  };

  let images = [];
  if (photos) {
    images = photos.map((item) => {
      return item.photo;
    });
  }


  const onDeletePost = (postId) => {
    setIsLoading(true);

    deletefeedPost(postId)
      .then(() => {
        setIsLoading(false);
        onDelete();
      })
      .catch(() => {
        setIsLoading(false);

        addToast({
          type: "error",
          title: "Error",
          text: "An error occurred, please try again.",
        });
      });
  };

  const handleOpenPostDetail = (imgs, index) => {
    setShowImg(true);
    setSelectedImgIndex(index);
  };

  String.prototype.splice = function (idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
  };

  //Handling Links
  const addLinks = (text) => {
    const urlMatches = text.match(/\b(http|https)?:\/\/\S+/gi) || [];

    urlMatches.forEach((link) => {
      const startIndex = text.indexOf(link);
      const endIndex = startIndex + link.length;
      let link_string = `<a href="${link}" target="_blank" rel="noopener noreferrer" className="embedded-link">`;
      text = text
        .splice(endIndex, 0, "</a>")
        .splice(startIndex, 0, link_string);
    });  
    return text;
  };

  const LinkPreview = (text) => {
    const urlMatches = text.match(/\b(http|https)?:\/\/\S+/gi) || [];
    return <RichLinkPreview key={urlMatches[0]} link={urlMatches[0]} />;
  };
  // const shareUrl = "http://app.inclusive.ae/activity/" + postedBy.id + "/" + content.id;
  const shareUrl = `http://app.inclusive.ae/activity/${activityId}`;

  let shareBody = content;

  return (
    <>
      <StyledPostCard className="ic-app-feeds">

        {isFeed && postedBy.owner_id !== postedTo.owner_id ? (
          <PostedByOn postedBy={postedBy} postedTo={postedTo} />
        ) : null}

        {/* Display the pending verification tag for user's own not-verified post.  */}
          {ownPost && !verifiedPost && 
            <div className="post-verification-tag">
              <div className="post-verification-text">
                Your post is pending verification. You can check the status under{" "}
                <Link style={{color: '#fff'}} to={"/profile/conversations"}>
                  My Conversations
                </Link>.
              </div>         
            </div>
          }
        <div className="container-fluid">
          <div className="row">
            <div className="ic-feed-profile--wrapper">
              <div className="ic-feed-profile d-flex flex-row align-items-center">
                <div className="ic-feed-picture">
                  <a
                    onClick={() => setShow(true)}
                    role="button"
                    aria-label="View Enlarged User Photo"
                  >
                    <img
                      src={
                        postedBy.organisation
                          ? postedBy.organisation.organization_logo
                          : postedBy.person.person_display_picture
                      }
                      alt=""
                      title=""
                      style={{cursor: 'pointer'}}
                    />
                  </a>
                </div>
                <div className={`ic-feed-name-logs d-flex flex-column ${direction === "rtl" ? "margin-right" : "margin-left"}`}>
                  <div className="ic-feed-name">
                    <Link
                      to={
                        postedBy.person
                          ? `/p/${postedBy.person.slug}`
                          : `/o/${postedBy.organisation.slug}`
                      }
                    >
                      {posterName}
                    </Link>{" "}
                    <span className="userIdentityWeb ic-feed-headline" style={{fontFamily: `${direction === "rtl" ? "Almarai" : ""}`}}>
                      (
                      {postedBy.organisation
                        ? "Organization"
                        : t(postedBy.person.identifies_as)}
                      )
                    </span>
                    <span className="userIdentityMob ic-feed-headline" style={{fontFamily: `${direction === "rtl" ? "Almarai" : ""}`}}>
                      {postedBy.organisation
                        ? "Organization"
                        : t(postedBy.person.identifies_as)}
                    </span>
                  </div>
                  <div className="ic-feed-name">
                    <span className="ic-feed-headline">
                      {postedBy.organisation
                        ? postedBy.organisation.organization_tagline.substring(0, 60)
                        : postedBy.person.person_headline.substring(0, 60)}
                    </span>
                  </div>
                  <div className="ic-feed-logs">
                    <span className="log-time" style={{fontFamily: `${language === "ar" ? "Almarai" : ""}`}}>
                      {/* Displaying the postedOn time in shortform. */}
                      {language !== "ar" ? (
                        getTimeAgo(postedOn)
                        .replace("about ", "")
                        .replace(" hour", "h")
                        .replace("s", "")
                        .replace(" ago", "")
                        .replace(" day", "d")
                        .replace(" minute", "m")
                        .replace("les than am", "just now")) 
                        :
                        (
                        getTimeAgoArabicShort(postedOn)
                        .replace("تقريباً","")
                        )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Dropdown className={`ib-post-dropdown ${direction === "rtl" ? "display-rtl" : "display-ltr"}`} alignRight={direction === "rtl" ? false : true}>
              <Dropdown.Toggle
                variant="light"
                bg="white"
                id="dropdown-basic"
                aria-label="Post options"
                onKeyUp={(event) => event.key === "Tab" ? setShowPostReactionDropdown(true):""}
              >
                <i className="fas fa-ellipsis-v" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="p-0 m-0">
                {postedBy.id === activeProfile.owner_id ||
                  postedTo.id === activeProfile.owner_id ? (
                    <Dropdown.Item
                      onClick={() => onDeletePost(activityId)}
                      aria-label="Delete Post"
                    >
                      {t("Delete Post")}
                    </Dropdown.Item>
                  ) : (
                    <>
                    <Dropdown.Item
                      onClick={followUnfollowPost}
                      aria-label={follow ? "Unfollow Post" : "Follow Post"}
                    >
                      {follow ? t("Unfollow Post") : t("Follow Post")}
                    </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setShowReportModal(true)}
                        aria-label="Report Post"
                      >
                        Report Post
                      </Dropdown.Item>
                    </>
                  )}

                {!isfull && (
                  <Dropdown.Item
                    as={Link}
                    to={`/activity/${activityId}`}
                    aria-label="Full Page View"
                  >
                    {t("Full Page View")}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <ReportContent modalVisible={showReportModal} setModalVisible={setShowReportModal} contentType="P" contentId={activityId} />

          <div className="row">
            <div className={`ic-feed-content imageLayout ${postDetail ? "p-0" : ""}`}>
              <div className="ic-feed-content--wrapper">
                {!photos ? (
                  <div className="ic-feed-content-type-text">
                    <div className="feed-type-text">
                      <p
                        className="pre_line mb-0"
                        style={{fontFamily: `${language === "ar" ? "Almarai" : ""}`}}
                        dangerouslySetInnerHTML={{
                          __html: addLinks(content),
                        }}
                      />

                      {LinkPreview(content)}
                    </div>
                  </div>
                ) : (
                    <div className="ic-feed-content-type-image">
                      <div className="feed-type-text">
                        {bodyWithMention !== "" ? (
                          <>
                            <p
                              className="pre_line fdsfds mb-0"
                              style={{fontFamily: `${language === "ar" ? "Almarai" : ""}`}}
                              onClick={contentClickHandler}
                              dangerouslySetInnerHTML={{
                                __html: addLinks(bodyWithMention),
                              }}
                            />
                          </>
                        ) : (
                            ""
                          )}

                        {LinkPreview(content)}
                      </div>

                      {!postDetail ? (
                        <>
                          <Images
                            images={photos}
                            hideOverlay={true}
                            onClickEach={({ imgs, index }) =>
                              handleOpenPostDetail(imgs, index)
                            }
                          />
                          {fullpost && (
                            <PostDetail
                              onDeletePic={onDeletePic}
                              imgIndex={selectedImgsIndex}
                              show={showImg}
                              post={fullpost}
                              onClose={() => setShowImg(false)}
                            />
                          )}
                        </>
                      ) : (
                          <ImageCarousal
                            imgIndex={imgIndex}
                            activityId={activityId}
                            images={photos}
                            editable={
                              postedBy.id === activeProfile.owner_id ? true : false
                            }
                            deletePic={onDeletePic}
                          />
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <ReactionsBar reactionList={reactionList} postId={activityId} reactionCount={reactionCount}/>
        </div>

        <div style={{ borderBottom: "0.5px solid #DCDCDC" }}></div>

        <Row className="post-actions-container">
          <Col xs={4} className="d-flex justify-content-center">
            <Reactions reactions={reactions} postId={activityId} ownerId={activeProfile.owner_id} setReactionCount={setReactionCount} reactionList={reactionList} setReactionList={setReactionList} showReactions={showReactions} setShowReactions={setShowReactions} showDropdown={showPostReactionDropdown} setShowDropdown={setShowPostReactionDropdown}/>                
          </Col>
          <Col xs={4} className="d-flex justify-content-center">
            <Button
              className="post-action d-flex align-items-center"
              aria-label="Comment on post"
              onClick={() => setOpen(!open)}
            >
              <i className={`material-icons ${direction === "rtl" ? "ml-1" : "mr-1"}`}>comment</i>
              <span className={direction === "rtl" ? "ml-1" : "mr-1"}>{commentNumber <= 1 ? t("Comment") : t("Comments")}</span>
              {commentNumber > 0 ? `(${commentNumber})` : ``}
            </Button>
          </Col>
          <Col xs={4} className="d-flex justify-content-center">
            <PostShareOptions url={shareUrl} body={shareBody} />
          </Col>
        </Row>

        <Collapse in={open}>
          <div id="comments-collapse-button">
            <Comments
              activityId={activityId}
              comments={comments}
              setCommentNumber={setCommentNumber}
              refs={textRef}
            />
          </div>
        </Collapse>

        <Modal
          show={show}
          centered
          size="md"
          onHide={() => setShow(false)}
          dialogClassName="modelImage"
          aria-label="User Image Modal"
        >
          <Modal.Body
            className="modalBodyImg pt-0 pl-0 pr-0 pb-0"
            aria-label="User Image Modal"
          >
            <img
              style={{ width: "100%" }}
              src={
                postedBy.organisation
                  ? postedBy.organisation.organization_logo
                  : postedBy.person.person_display_picture
              }
              alt="User avatar"
              title="User Avatar"
            />
            <span className="closeBtn" onClick={() => setShow(false)}>
              &#9447;
            </span>
          </Modal.Body>
        </Modal>
      </StyledPostCard>
    </>
  );
};

PostCard.propTypes = {
  commentsOpened: PropTypes.bool,
};

PostCard.defaultProps = {
  commentsOpened: false,
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
  {
    setSelectedImage,
    addToast,
  }
)(PostCard);

const StyledPostCard = styled.div`
  background-color: #ffffff;
  margin-bottom: 30px;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.05);
  border-radius: 0.4rem;
  border: 0px !important;

  .card {
    border: 0.5px solid rgb(220, 220, 220);
    border-radius: 11px;
}

  .container-fluid {
    position: relative;
  }
  .closePicbtn{
    position:absolute;
    top:-10px;
    color: gray;
    cursor:pointer
  }

  .post-verification-tag {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(205,74,4);
    color: rgb(255,255,255);
    border-radius: 14px 14px 0 0;
  }

  .ib-post-dropdown {
    position: absolute;
    top: 18px;
    color: #B11030;
  }

  .ib-post-dropdown.display-rtl .dropdown-menu.show {
    text-align: right;
  }
  
  .ib-post-dropdown.display-rtl {
    left: 10px;
  }
  
  .ib-post-dropdown.display-ltr {
    right: 10px;
  }
  
  .share-dropdown.display-rtl .dropdown-menu.show {
    text-align: right;
  }

  .dropdown-toggle::after {
    content: none;
  }

  a.dropdown-item {
    padding: 0.8rem;
}

a.dropdown-item:active {
  background: white;
  color: #212529;
}

  .post-delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #B11030;
  }

  button#dropdown-basic {
    background: transparent;
    border: none;
}

i.fas.fa-ellipsis-v {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.5);
    }

.comments-toggle {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    background-color: #ffffff;
    border-color: #ffffff;
}

.comments-toggle:active {
    font-weight: 600;
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}
.comments-toggle:hover {
    font-weight: 600;
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}

.comment-input {
  padding-left: 0;
  @media(max-width: 576px) {
    padding-right: 15px !important;
    padding-left: 15px;
  }
}

.ic-primary-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.065em; 
  color: #FFFFFF;
  background: #3F0E40;
  border-radius: 6px;
  width: 45px;
  height: 40px !important;
  display: flex;
  justify-content: center;
}

.imgDP {
  border-radius: 50%;
}

.comment-wrapper-container {
    padding: 20px 24px;
    border-bottom: 0.5px solid #DCDCDC;
    @media (max-width: 576px) {
      padding: 20px;
    }
}

.ic-show-all-comments {
    padding: 16px 24px;
    color: #515151;
    font-weight: 500;
    @media (max-width: 576px) {
      padding: 16px 20px;
    }
}

.post-comment-container.display-ltr {
    padding: 20px 20px 20px 0;
    @media (max-width: 576px) {
      padding: 20px 15px;
    }
}
.post-comment-container.display-rtl {
  padding: 20px 0 20px 20px;
  @media (max-width: 576px) {
    padding: 20px 15px;
  }
}

@media (max-width: 576px) {
  .post-comment-container.display-ltr .col-2 {
    padding-right: 20px !important;
  }
  .post-comment-container.display-ltr .col-10 {
    padding-right: 0;
  }
  .post-comment-container.display-rtl .col-2 {
    padding-left: 20px !important;
  }
  .post-comment-container.display-rtl .col-10 {
    padding-left: 0;
  }
  h4.card-title.preview_link_title {
    padding-top: 1rem;
  }
  .card-block.px-3 {
    padding: 0 !important;
  }
}

.comment-picture img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid #80808038 !important;
    object-fit: cover;
}

.conter p {
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    margin-bottom: 5px;
}

.post-actions-container.row {
    padding: 5px 24px;
    align-items: center;
    @media (max-width: 576px) {
      padding: 0;
    }
}

button.post-action {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #8C8C8C;
    border: 0;
    background-color: #ffffff;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  @media (max-width: 576px) {
    i.material-icons.mr-1 {
      font-size: 22px;
      margin-top: 4px;
  }
  }
}
  button.post-action:hover {
      color: #343a40 !important;
      background: #ffffff !important;
      border-color: #ffffff !important;
  }
  button.post-action:active {
    color: #343a40 !important;
    background: #ffffff !important;
    border-color: #ffffff !important;
}

  button.post-action:focus-visible {
    box-shadow: 0 0 0 0.13rem #000 !important;
    color: #343a40 !important;
}
  button#dropdown-basic:focus-visible {
    box-shadow: 0 0 0 0.13rem #000 !important;
  }
  
  .ib-comment-dropdown {
    position: absolute;
    top: 0px;
}

  .ib-comment-dropdown.display-rtl .dropdown-menu.show {
    text-align: right;
  }
  
  .ib-comment-dropdown.display-rtl {
    left: -14px;
  }
  
  .ib-comment-dropdown.display-ltr {
    right: -14px;
  }


  .comment-delete-button {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(0,0,0,0.5);
    background-color: #ffffff;
    border: 0;
  }

  .comment-delete-button:active {
    color: rgba(0,0,0,0.5) !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
  }

  .comment-delete-button:hover {
    color: rgba(0,0,0,0.7) !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
  }

  .ic-feed-profile {
    padding: 24px 24px 0;
    @media (max-width: 576px) {
      padding: 20px;
      border-bottom: 0.5px solid rgb(220, 220, 220);
    }
  }

  .ic-feed-profile--wrapper {
    width: 100%;
  }

  .ic-feed-profile--wrapper .ic-feed-picture a img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    border: 1px solid #80808038 !important;

    object-fit: cover;
  }
  @media (max-width: 576px) {
    .ic-feed-profile--wrapper .ic-feed-picture a img {
      width: 70px;
      height: 70px;
    }
  }

  .ic-feed-profile--wrapper .ic-feed-name-logs.margin-left {
    margin-left: 15px;
  }

  .ic-feed-profile--wrapper .ic-feed-name-logs.margin-right {
    margin-right: 15px;
  }

  .ic-feed-profile--wrapper .ic-feed-name-logs .ic-feed-name a {
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    @media (max-width: 576px) {
      font-size: 19px; 
    }
  }

  .ic-feed-profile--wrapper .ic-feed-name-logs .ic-feed-logs p {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    color: #a6a7ab;
    margin: 0;
  }

  // .ic-feed-profile--wrapper .ic-feed-name-logs .ic-feed-logs  {
  //   font-size: 18px;
  //   font-family: 'Inter', sans-serif;
  //   font-style: italic;
  //   color: #a6a7ab;
  //   margin: 0;
  // }
  .log-time{
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #8C8C8C;
    margin: 0;
    height: 22px;
  }

  .ic-feed-headline {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #000000;
  }

  .ic-feed-content { 
    padding: 24px 24px 4px 24px;
    @media (max-width: 576px) {
      padding: 20px 20px 0 20px;
    }
  }

  .ic-feed-content--wrapper .ic-feed-content-type-text .feed-type-text p {
    margin-bottom: 0;
  }

  p.pre_line.fdsfds {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #515151;
}


  .ic-feed-content--wrapper .ic-feed-content-type-image {
    position: relative;
  }

  .ic-feed-content--wrapper
    .ic-feed-content-type-image
    .feed-type-image
    .image-wrap
    ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
  }

  .ic-feed-content--wrapper
    .ic-feed-content-type-image
    .feed-type-image
    .image-wrap
    ul
    li:first-child {
    margin-left: 0;
  }

  .ic-feed-content--wrapper
    .ic-feed-content-type-image
    .feed-type-image
    .image-wrap
    ul
    li {
    width: 50%;
    margin-left: 20px;
  }

  @media (max-width: 576px) {
    .ic-feed-content--wrapper
      .ic-feed-content-type-image
      .feed-type-image
      .image-wrap
      ul
      li {
      width: 100%;
      margin-left: 0;
    }
  }
 
  .ic-feed-content--wrapper
    .ic-feed-content-type-image
    .feed-type-image
    .image-wrap
    ul
    li
    picture
    img:not(:first-child) {
    margin-top: 8px;
  }

  .ic-feed-content--wrapper
    .ic-feed-content-type-image
    .feed-type-image
    .image-wrap
    ul
    li
    picture
    img {
    max-width: 100%;
  }

  .ic-feed-user-comment-wrapper {
    border-top: 0.5px solid #DCDCDC;
  }

  .ic-feed-user-comment-wrapper .ic-feed-user-comment-body {
    width: 100%;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-comment-user-details {
    position: relative;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-comment-user-details
    .ic-comment-user-name
    a {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    color: #000000 !important;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-comment-user-details
    .ic-comment-user-logs {
    // margin-left: 12px;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-comment-user-details
    .ic-comment-user-logs{
    font-family: 'Inter', sans-serif;
    font-style: italic;
    color: #a6a7ab;
    @media(max-width: 576px) {
        margin-left: 0 !important;
    }
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-comment-user-content {
    margin-top: 5px;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-view-all-comments
    a {
    font-size: 17px;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-post-comment-wrapper {
    margin-top: 12px;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-post-comment-wrapper
    form {
    max-width: 100%;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-feed-post-comment-wrapper
    form
    input {
    max-width: 100%;
  }

  input.form-control {
    background: #F8F8F8;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 32px !important;
    border: 0 !important;
  }
  input.form-control:focus {
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  }

  .commetTextArea .form-control {
    //background-color: #F8F8F8 !important;
    background-color: #F8F8F8;

    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 32px !important;
    border: 0 !important;
    max-height:unset !important;
    //padding: 1rem 2.4rem 1rem 1rem;

  }
  .commetTextArea .form-control:focus {
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  }

  .commetTextArea__highlighter, .comment{
    padding: 0.8rem;
    min-height: 40px;
  }

  .long-comment .commetTextArea__highlighter {
    padding: 0.8rem 0.8rem 2.5rem 0.8rem !important;
    min-height: 40px;
  }

  .commetTextArea__suggestions__list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }
  .commetTextArea__suggestions__item{
    padding:0.8rem;
    width:100%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
    &:hover {
      background-color: rgba(0,0,0,0.05);
    }
  }
  .commetTextArea__suggestions__item:last-child:after
  {
border:0px !important
  }
  .commetTextArea__suggestions{
  //  top: 41px !important;
    z-index: 1000000000000000 !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: .25rem;
    //top: 16px !important;
  }

  .commetInput{
    background-color: #C5DAF4 !important;
  }

  .ic-feed-user-comment-wrapper
    .ic-feed-user-comment-body
    .ic-show-all-comments
    a {
    cursor: pointer;
    color: #263238 !important;
  }

  .imageLayout{
    width:100%;
  }

  .grid-container {
    text-align: center;
    margin: auto;
    width: 100%;
    padding: 10px;
  }
  
  .container {
    padding-left: 0;
    padding-right: 0;
    width: 100% !important;
  }
  
  .container *{
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  
  .col-md-4 {
    width: 33.33333333%;
  }
  
  .border {
    border: 2px solid white;
    border-radius: 6px;
  }
  
  .background {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  
  .height-one {
    width: 100%;
    padding-top: 100%;
  }
  
  .height-two {
    width: 50%;
    padding-top: 50%;
  }
  
  .height-three {
    width: 33.3333%;
    padding-top: 33.3333%;
  }
  
  .cover {
    background-color: #222;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 6px;
  }
  
  .cover-text {
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    font-size: 7%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    text-align: center;
  }
  
  .cover-text > p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  // .slide {
  //   height: 0;
  //   bottom: 100%;
  //   transition: .5s ease;
  //   overflow: hidden;
  //   font-size: 3%;
  // }
  
  // .border:hover .slide  {
  //   bottom: 0;
  //   height: auto;
  // }
  
  // .border:hover .animate-text  {
  //   top: 62%
  // }

  .carousel-control-next, .carousel-control-prev {
    top:30% !important;
    bottom:50% !important
  }

  .reddots{
    font-size: 30px;
    color: #B11030;
  }
  .imageDropDown .dropdown-menu{
        right: 0px;
        left: unset !important;
  }
  #dropdownImgMenu{
        margin: 0px !important;
  }
  .imageDropDown{
        position: absolute;
    right: 18px;
        top: 12px;
  }
 .dropdown-toggle::after{
   display:none !important
 }
 .carousel-indicators{
  bottom: -30px !important;
 }
 .carousel-indicators li{
  background-color: #B11030 !important;
  height:8px !important
 }
 .border{
   cursor:pointer
 }

 span#showmore, span#showless  {
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  font-weight:700;
  cursor:pointer
}
.firstpic{
  background-size: contain !important;
  background-position: bottom !important;
  max-height: 100%;
  padding: 0 !important;
}


.add-emoji-button {
  width: 28px;
  height: 29px;
  background: #000000;
  //box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #FFFFFF;
  border-color: #000000;
  display: flex !important;
  //align-items: center !important;
  justify-content: center !important;
  margin-left: 6px;
  border-radius: 50%;
  &:hover {
    color: #343a40 !important;
  }
  &:active {
    color: #343a40 !important;
  }
}

.emoji-trigger{
  // position: absolute;
  // top: 94px;
  // left: 88px;
  padding-bottom: 5px;
  cursor:pointer
   // z-index:100000
}
.emoji-triggersss{
  // position: absolute;
  // top: 0;
  // left: -37px;
   cursor:pointer
  // z-index:1
  padding: 0px 6px;
}
// .emoji-triggersss:hover{
//  color:yellow
// }

.emoji-mart-bar {
    display: none;
}

.emoji-mart-category-label span {
    font-family: 'Inter', sans-serif;
}

.emoji-mart-emoji span {
  cursor: pointer !important;
}

.emoji-mart-search {
    font-family: 'Inter', sans-serif;
}

.emoji-mart-search input {
    border: 1px solid #E8E8E8;
    border-radius: 7px;
}

button.emoji-mart-search-icon {
    display: flex;
    align-self: center;
}

.displayRtl .emoji-mart-category-label span {
  font-family: 'Almarai', sans-serif;
}

.displayRtl .emoji-mart-search {
  font-family: 'Almarai', sans-serif;
}

.displayRtl .emoji-mart-no-results-label {
  font-family: 'Almarai', sans-serif;
}

.displayRtl .emoji-mart-light {
  left: 0px;
  right: auto;
}

.emoji-mart-light{
  position:absolute;
  z-index:100000;
  right: 0px;
  top: 35px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  overflow: hidden;
}

.emojiWrapper.display-ltr {
  padding-top: 1px;
  position: absolute;
  right: 24px;
  bottom: 10.7px;
  display: flex;
}
.emojiWrapper.display-rtl {
  padding-top: 1px;
  position: absolute;
  left: 24px;
  bottom: 10.7px;
  display: flex;
}

@media (max-width: 480px) {
 
  .displayRtl .emoji-mart-light {
    right: auto !important;
  }
 
  .emoji-mart-light {
    right: 0px !important;
    max-width: 320px !important;
  }

}

`;
