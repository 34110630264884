import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import AppContentContainer from "../../../common/AppContentContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { getCourseDetail } from "../../../api";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import ReadMoreAndLess from 'react-read-more-less';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CertificateImg from '../../../assets/images/Certificate.png';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import CertificatePdf from "./CertificatePdf";

import i18n from "i18next";


const CourseDetail = ({ activeProfile}) => {
  const direction = i18n.dir();
  const { courseId } = useParams();

  const [course, setCourse] = useState();
  const [modules, setModules] = useState([]);
  const [completedModules, setCompletedModules] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    let unmounted = false;
    getCourseDetail(courseId)
      .then(({ data }) => {
        if (!unmounted) {
          setCourse(data.course);
          setModules(data.modules);
          setCompletedModules(data.completed_modules);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (modules.length === completedModules.length) {
      setIsComplete(true);
    }
    else {
      setIsComplete(false);
    }
  },[modules, completedModules]);

  const moduleJsx = modules.map((course_lesson) => {
    return (
      <Link 
        to={`/learn/${courseId}/lesson/${course_lesson.id}`}
        className="course-module"
        key={course_lesson.id}
      >
        <li className="list-group-item">
          <Row className="d-flex align-items-center">
            <i className="material-icons md-24 mx-3 show-on-regular">
              {course_lesson.module_type === 'text' && 'import_contacts'}
              {course_lesson.module_type === 'video' && 'play_circle'}
              {course_lesson.module_type === 'pdf' && 'description'}
              {course_lesson.module_type === 'quiz' && 'quiz'}
            </i>
            <div>
              <h3 className="module-title">{course_lesson.title}</h3>
              <div className="d-flex align-items-center">
                <i className="material-icons md-24 mx-2 show-on-mobile">
                  {course_lesson.module_type === 'text' && 'import_contacts'}
                  {course_lesson.module_type === 'video' && 'play_circle'}
                  {course_lesson.module_type === 'pdf' && 'description'}
                  {course_lesson.module_type === 'quiz' && 'quiz'}
                </i>
                <h5 className="module-type">{course_lesson.module_type === 'text' ? 'Reading' : course_lesson.module_type === 'pdf' ? 'Additional Resources' : course_lesson.module_type === 'video' ? 'Video' : 'Practice Quiz'}</h5>
                <span className="px-1">•</span>
                <h5 className="module-duration">{course_lesson.duration.replace('Mins', 'min').replace('mins', ' min')}</h5>
              </div>
            </div>
          </Row>
      </li>
      </Link>
    );
  });
  let courseJsx;
  if (course) {

    courseJsx = (
      <div className="job-card" aria-label="Job card">
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={'div'}>
              <Link to="/learn" className="breadcrumb-link">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{course.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row className="d-flex job-content align-items-center pb-0 pt-3">
          <Col md={12} className="job-title">
            <h1 className="course-title">{course.title}</h1>
          </Col>
        </Row>
        <Row className="d-flex align-items-center job-content pt-0">
          <Col xs={6}>
            <span className="info-button">{course.category.category}</span>
          </Col>
          <Col xs={6} className="enroll-container">
            <Link 
              to={`/learn/${courseId}/lesson/${modules[0]?.id}`}
              id="enroll-course"
              aria-label="Enroll Course"
            >
              <i className={`material-icons ${direction === "rtl" ? "ml-2" : "mr-2"}`} style={{ fontSize: "20px" }}>
                launch
              </i>
              Start Course
            </Link>
          </Col>
        </Row>
        <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
        <Row className="job-content">
          <Col md={12}>
          <h2 className="course-heading">About this course</h2>
            <div className="read-more-less">
              <ReadMoreAndLess
                className="read-more-content"
                charLimit={480}
                readMoreText="Read more"
                readLessText="Read less"
              >
                {course.description}
              </ReadMoreAndLess>
            </div>
          </Col>
        </Row>
        <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
        <Row className="job-content">
          <Col md={12}>
          <h2 className="course-heading">Instructors</h2>
            {course.course_instructors.map((instructor, index) => 
              <div className="instructor-item" key={instructor.instructor.id} style={{ marginTop: index === 0 ? 0 : '1rem' }}>
                <div className="instructor-header d-flex align-items-center mb-3">
                  <img src={instructor.instructor.photo} className="instructor-img" alt="Instructor logo"/>
                  <div className="instructor-info ml-2">
                    <h3 className="instructor-title">{instructor.instructor.name}</h3>
                    <h4 className="instructor-desig">{instructor.instructor.designation}</h4>
                  </div>
                </div>
                <div className="read-more-less">
                  <ReadMoreAndLess
                    className="read-more-content"
                    charLimit={240}
                    readMoreText="Read more"
                    readLessText="Read less"
                  >
                    {instructor.instructor.bio}
                  </ReadMoreAndLess>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
        <Row className="job-content">
          <Col md={12}>
          <h2 className="course-heading">In partnership with</h2>
            {course.course_knowledge_partner.map((partner) => 
              <div className="partner-item" key={partner.knowledge_partner.id}>
                <div className="instructor-header d-flex align-items-center mb-3">
                  <img src={partner.knowledge_partner.logo} className="partner-img" alt="Partner logo"/>
                  <div className="partner-info ml-2">
                    <h3 className="partner-title">{partner.knowledge_partner.title}</h3>
                  </div>
                </div>
                <div className="read-more-less">
                  <ReadMoreAndLess
                    className="read-more-content"
                    charLimit={240}
                    readMoreText="Read more"
                    readLessText="Read less"
                  >
                    {partner.knowledge_partner.bio}
                  </ReadMoreAndLess>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
        <Row className="job-content">
          <Col md={12}>
            <h2 className="course-heading">Modules</h2>
            <ul className="list-group">
            {moduleJsx}

            </ul>
          </Col>
        </Row>
        <div style={{ borderBottom: "1px solid #e9e9e9" }}></div>
        <Row className="job-content d-flex align-items-center justify-content-between">
          <Col md={8}>
            <h2 className="course-heading">Create your Future, Earn a Skill Certificate</h2>
            <h3 className="module-title mb-4 certificate-subheading" style={{fontSize: '1rem'}}>
              An inclusive future is everyone’s responsibility, your choice to build your skills today gives you a stronger resume, a more desirable profile for employment, and a push forward towards your dream career. Upskill yourself, take lead in building on your own future today. Remember to mention your certificate on your CV!
            </h3>
            {isComplete ? (
              <PDFDownloadLink document={<CertificatePdf title={course.title} userName={activeProfile?.full_name} partnerLogo={course.course_knowledge_partner[0].knowledge_partner.logo} partnerName={course.course_knowledge_partner[0].knowledge_partner.title}/>} fileName={`${course.title} Certificate.pdf`} className="download-certificate-btn mb-2">
                <i className="material-icons mr-2">download</i>
                Download Certificate
              </PDFDownloadLink>
            ) : (
              <Button
                className="download-certificate-btn mb-2"
                id="download-certificate"
                aria-label="Download Certificate"
                style={{ cursor: 'default', opacity: '0.65' }}
                onClick={() => { setShowInfo(true) }}
              >
                <i className="material-icons mr-2">download</i>
                Download Certificate
              </Button>
            )}
            {showInfo && (
              <span className="module-type certificate-info">Complete all lessons to unlock the certificate.</span>
            )}
          </Col>
          <Col md={4} className="d-flex certificate-img-container">
            <img
              src={CertificateImg}
              className="certificate-img"
              alt="certificate-img"
            />
          </Col>
        </Row>
      </div>
    );
  }
  
  return (
    <>
    <AppContentContainer classes="container d-flex flex-column flex-md-row">
      <StyledCourseCard>{courseJsx}</StyledCourseCard>
    </AppContentContainer>
    {/* <PDFViewer width={'1500'} height='1000'>
      <CertificatePdf title={course?.title} userName={activeProfile?.full_name} partnerLogo={course?.course_knowledge_partner[0].knowledge_partner.logo} partnerName={course?.course_knowledge_partner[0].knowledge_partner.title}/>
    </PDFViewer> */}
    </>
  );
};

export default connect(
  (state) => ({
    activeProfile: state.user.activeProfile,
  }),
)(CourseDetail);

const StyledCourseCard = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  width: 100%;

  @media(max-width: 440px) {
    margin-top: 2.5rem;
    .course-title {
      font-size: 1.5rem;
    }
    .enroll-container {
      padding-left: 0;
    }
    .course-heading {
      font-size: 1.2rem;
    }
    .module-title {
      margin-left: 0.5rem;
    }
    .certificate-subheading {
      margin-left: 0;
    }
    .download-certificate-btn {
      margin-bottom: 1rem !important;
    }
    .certificate-img-container {
      justify-content: start !important;
      margin-top: 1rem;
    }
  }

  .course-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .course-heading {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .breadcrumb {
    background-color: #fff;
    margin: 1rem 0 0 2rem;
    flex-wrap: nowrap;
    @media(max-width: 440px) {
      margin-left: 1.5rem;
    }
  }

  .breadcrumb-item {
    white-space: nowrap;
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .breadcrumb-link {
    color: #000;
  }

  .read-more-less .readMoreText {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: black !important;
    padding-left: 0.3rem;
  }

  .job-header {
    padding: 20px 24px;
    color: #ffffff;
    background-color: #000000;
    font-size: 1.1rem;
    opacity: 0.9;
  }

  .job-content {
    padding: 1.8rem 2rem;
  }

  .job-footer {
    padding: 20px 24px;
    font-size: 1.1rem;
  }

  .job-close-date {
    color: #000000;
  }

  .job-title-a {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000000;
    @media (max-width: 560px) {
      font-size: 1.2rem;
    }
  }

  .job-organization {
    font-size: 1.1rem;
    font-weight: 500;
    color: #000;
    @media (max-width: 560px) {
      font-size: 1rem;
    }
  }

  .job-location {
    font-size: 1rem;
    font-weight: 500;
    color: #5f5f5f;
  }

  .job-badge-icon {
    display: flex;
    justify-content: flex-end;
  }

  .job-logo-container {
    width: 84px;
    height: 84px;
  }

  .job-logo-container img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    object-fit: contain;
  }

  .instructor-img {
    width: 150px !important;
    height: 150px !important;
    object-fit: cover !important;
  }

  .instructor-img, .partner-img {
    width: 75px;
    height: 75px;
    border-radius: 10px;
    border: 1px solid #e5e7e8;
    padding: 4px;
    object-fit: contain;
  }

  .instructor-title, .partner-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
  }

  .instructor-desig {
    font-size: 1rem;
    font-weight: 500;
    color: #5f5f5f;
    margin-bottom: 0;
    margin-top: 0.25rem;
  }

  .job-apply-button {
    display: flex;
    justify-content: flex-end;
  }

  #enroll-course, #download-certificate, .download-certificate-btn {
    background-color: #3f0e40;
    border-color: #3f0e40;
    color: white;
    border-radius: 7px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0.438rem 0.75rem;
    &:hover {
      text-decoration: none;
    }
  }

  .download-certificate-btn {
    width: fit-content;
    cursor: pointer;
  }

  .enroll-container {
    justify-content: end;
    display: flex;
  }

  .certificate-img-container {
    justify-content: flex-end;
  }

  .certificate-img {
    height: 180px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .course-module:hover {
    text-decoration: none;
  }

  .course-module li {
    color: #5F5F5F;
    padding: 16px 20px;
    &:hover {
      background-color: #f5f7f8;
    }
  }

  .module-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
  }

  .module-type, .module-duration {
    font-size: 1rem;
    font-weight: 500;
    color: #5F5F5F;
    margin-bottom: 0;
  }

  .info-button {
    font-size: 1rem;
    color: #3f0e40;
    background-color: #ededed;
    border-radius: 7px;
    font-weight: 500;
    display: inline-block;
    padding: 0.438rem 0.75rem;
  }

  .btn.disabled,
  .btn:disabled {
    cursor: default;
  }

  .show-on-mobile {
    display: none;
  }

  @media (max-width: 440px) {
    .show-on-regular {
      display: none !important;
    }
    .show-on-mobile {
      display: flex !important;
    }
    .job-content {
      padding: 1.5rem;
    }
    .job-location {
      justify-content: flex-start;
    }
    .job-title.title-ltr {
      padding-left: 0;
    }
    .job-title.title-rtl {
      padding-right: 0;
    }
    .job-apply-button.apply-ltr {
      //justify-content: flex-start;
      //padding-top: 1rem;
      padding-left: 0;
    }
    .job-apply-button.apply-rtl {
      padding-right: 0;
    }
    .job-close-date.date-ltr {
      padding-right: 0;
    }
    .job-close-date.date-rtl {
      padding-left: 0;
    }
  }
`;
