import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LoaderWithText } from "../../../components/Loader";
import Row from "react-bootstrap/Row";
import { capitalizeFirstLetter } from "../../../common/utils";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Search = ({ user, index }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="list-user"
        style={{ borderTop: `${index !== 0 ? "1px solid #CECECE" : ""}` }}
      >
        <div className="user-info d-flex flex-row flex-wrap align-items-center">
          <div className="picture">
            <Link to={`/o/${user.slug}`} role="button">
              <img
                src={user.organization_logo}
                alt="profile picture"
                title="profile picture"
              />
            </Link>
          </div>
          <div className="user-name-location d-flex flex-column col">
            <div className="name">
              <Link
                to={`/o/${user.slug}`}
                role="button"
              >{`${capitalizeFirstLetter(user.organization_title)}`}</Link>
            </div>
            <div className="user-type">
              <span className="type-of-user">{t("Organization")}</span>
            </div>
            <div className="ic-feed-name">
              {user.organization_tagline.substring(0, 45)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserList = ({ profile, isSearching }) => {
  const { t } = useTranslation();
  const direction = i18n.dir();

  if (isSearching) {
    return <LoaderWithText padding="30px 0" text={t("Please wait...")} />;
  }

  return (
    <StyledUserList className="ic-dashview d-flex flex-row flex-wrap justify-content-between">
      <div className="dashview-followers">
        <div className="follower-list">
          <Row className="search-header">
            <h2
              className="m-0 pl-2"
              style={{
                fontWeight: "700",
                fontSize: "1.2rem",
                color: "#000000",
              }}
            >
              {t("Search Results")}
            </h2>
          </Row>
          {profile && profile.length ? (
            profile.map((profile, index) => (
              <Search index={index} key={profile.id} user={profile} />
            ))
          ) : (
            <>
              <h3 className={`py-4 text-red ${direction === "rtl" ? "pr-4" : "pl-4"}`}>{t("No user found.")}</h3>
            </>
          )}
        </div>
      </div>
    </StyledUserList>
  );
};

export default UserList;

const StyledUserList = styled.div`
  .dashview-followers {
    //border: solid 2px #dadbe2;
    background-color: #ffffff;
    width: 100%;
  }

  .dashview-followers .follower-list .list-user:not(:first-child) {
    //  border-top: 1px solid #CECECE;
  }

  //   .dashview-followers .follower-list .list-user:first-child {
  //     padding-top: 15px;
  //   }

  .dashview-followers .follower-list .list-user {
    padding: 25px 35px;
    @media (max-width: 576px) {
      padding: 20px;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .picture {
    width: 15%;
  }

  @media (min-width: 993px) and (max-width: 1340px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 15%;
    }
  }

  @media (max-width: 992px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 20%;
    }
  }

  @media (max-width: 576px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 25%;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .picture a img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    @media (max-width: 576px) {
      width: 70px;
      height: 70px;
    }
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .name
    a {
    color: #000000;
    font-size: 19px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    line-height: 27px;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .user-type {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }

  .ic-feed-name {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .location {
    color: #a6a7ab;
    font-size: 18px;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .location
    .log-time {
    font-family: "Inter", sans-serif;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option {
    width: 3%;
    position: relative;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    a {
    font-size: 2em;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    .dropdown-menu {
    -webkit-transform: translate3d(-156px, 45px, 0px) !important;
    transform: translate3d(-156px, 45px, 0px) !important;
    border-color: rgba(237, 234, 246, 0.5);
    box-shadow: 2px 3px 0px 0px rgba(43, 43, 43, 0.2);
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    .dropdown-menu
    .dropdown-item {
    font-size: 0.8em;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    .dropdown-menu:after {
    width: 0;
    height: 0;
    position: absolute;
    top: 0%;
    content: "";
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(43, 43, 43, 0.5);
    right: 12px;
  }

  .search-header {
    padding: 20px 30px;
    border-bottom: 2px solid #dadbe2;
  }
`;
