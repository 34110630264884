import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { LoaderWithText } from "../../../components/Loader";
import { addToast } from "../../../redux/toast";

import {
  getFollowing,
  unfollow,
  getUserFollowing,
  unFollowPerson,
} from "../../../api";

const Following = ({ following, owns, onUnFollow }) => {
  if (following.following.organisation) {
    return (
      <div className="list-user">
        <div className="user-info d-flex flex-row flex-wrap align-items-center">
          <div className="picture">
            <Link
              to={`/o/${following.following.organisation.slug}`}
              role="button"
            >
              <img
                src={following.following.organisation.organization_logo}
                alt="profile picture"
                title="profile picture"
              />
            </Link>
          </div>
          <div className="user-name-location d-flex flex-column col">
            <div className="name">
              <Link
                to={`/o/${following.following.organisation.slug}`}
                role="button"
              >{`${following.following.organisation.organization_title}`}</Link>
            </div>
            <div className="user-type">
              <span className="type-of-user">Organization</span>
            </div>
            <div className="ic-feed-name">
              {following.following.organisation.organization_tagline.substring(
                0,
                45
              )}
            </div>
          </div>
          {owns ? (
            <button
              type="button"
              onClick={onUnFollow.bind(this, following)}
              className="modal-submit-button"
            >
              Unfollow
            </button>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div className="list-user">
      <div className="user-info d-flex flex-row flex-wrap align-items-center">
        <div className="picture">
          <Link to={`/p/${following.following.person.slug}`} role="button">
            <img
              src={following.following.person.person_display_picture}
              alt="profile picture"
              title="profile picture"
            />
          </Link>
        </div>
        <div className="user-name-location d-flex flex-column col">
          <div className="name">
            <Link
              to={`/p/${following.following.person.slug}`}
              role="button"
            >{`${following.following.person.person_first_name} ${following.following.person.person_last_name}`}</Link>
          </div>
          <div className="user-type">
            <span className="type-of-user">
              {following.following.person.identifies_as}
            </span>
          </div>
          <div className="ic-feed-name">
            {following.following.person.person_headline.substring(0, 45)}
          </div>
        </div>
        {owns ? (
          <button
            type="button"
            onClick={onUnFollow.bind(this, following)}
            className="modal-submit-button"
          >
            Unfollow
          </button>
        ) : null}
      </div>
    </div>
  );
};

const Followings = ({ profile, isOwner, addToast }) => {
  const [followings, setFollowings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (profile) {
      setLoading(true);

      getUserFollowing(profile.owner_id)
        .then(({ data }) => {
          if (!unmounted) {
            setLoading(false);
            setFollowings(data);
          }
        })
        .catch(() => {
          setLoading(false);
        });

      // getFollowing(profile.id)
      //   .then(({ data }) => {
      //     setLoading(false)
      //     setFollowings(data);
      //   })
      //   .catch(() => {
      //     setLoading(false)
      //   });
    }
    return () => { unmounted = true }; 
  }, [profile]);


  const unfollowuser = (follow_item) => {
    setLoading(true);

    unFollowPerson(follow_item.follower.id, follow_item.following.id)
      .then(() => {
        let list = followings.filter((x) => {
          return x.id != follow_item.id;
        });
        addToast({
          title: "Success",
          text: `Unfollowed successfully.`,
          type: "success",
        });
        setFollowings(list);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        addToast({
          type: "error",
          title: "Error",
          text: "An error occurred, please try again.",
        });
      });
    // unfollow(follow_item.person.id, follow_item.follower_person.id)
    //   .then(() => {
    //     let list = followings.filter(x => {
    //       return x.id != follow_item.id;
    //     })
    //     setFollowings(list);
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
  };

  if (loading)
    return (
      <StyledFollowings className="ic-dashview d-flex flex-row flex-wrap justify-content-between">
        <div className="dashview-followers">
          <div className="follower-list">
            <LoaderWithText text="Please wait..." />
          </div>
        </div>
      </StyledFollowings>
    );

  return (
    <StyledFollowings className="ic-dashview d-flex flex-row flex-wrap justify-content-between">
      <div className="dashview-followers">
        <div className="follower-list">
          <div className="d-flex align-items-center user-story-header">
            <h2 className="pl-1">Following</h2>
          </div>
          {followings.length ? (
            followings.map((following) => (
              <Following
                key={following.id}
                onUnFollow={unfollowuser}
                owns={isOwner}
                following={following}
              />
            ))
          ) : isOwner ? (
            <h3 className="text-red m-0 following-text">
              You are not following any person.
            </h3>
          ) : (
            <h3 className="text-red m-0 following-text">
              This user is not following any person.
            </h3>
          )}
        </div>
      </div>
    </StyledFollowings>
  );
};

export default connect(() => ({}), {
  addToast,
})(Followings);
const StyledFollowings = styled.div`
  //margin-top: 5.6rem !important;
  margin-top: 0px !important; 
  
  .dashview-followers {
    margin-top: 1rem;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    border: 0px;
  }

  .dashview-followers .follower-list {
    padding: 20px;
  }

  .dashview-followers .follower-list .list-user:not(:first-child) {
    border-top: solid 2px #dadbe2;
  }

  .dashview-followers .follower-list .list-user:first-child {
    padding-top: 15px;
  }

  .dashview-followers .follower-list .list-user {
    padding: 25px 0;
    @media(max-width: 576px) {
      padding: 16px 0;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .picture {
    width: 15%;

    margin-right: 15px;
  }

  @media (min-width: 993px) and (max-width: 1340px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 15%;
    }
  }

  @media (max-width: 992px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 20%;
    }
  }

  @media (max-width: 576px) {
    .dashview-followers .follower-list .list-user .user-info .picture {
      width: 25%;
    }
  }

  .dashview-followers .follower-list .list-user .user-info .picture a img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .name
    a {
    color: #263238;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    font-weight: bold;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .user-type {
    color: #515151;
    font-size: 16px;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .location {
    color: #515151;
    font-size: 16px;
  }

  .ic-feed-name {
    color: #515151;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-name-location
    .location
    .log-time {
    font-family: "Inter", sans-serif;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option {
    width: 3%;
    position: relative;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    a {
    font-size: 2em;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    .dropdown-menu {
    -webkit-transform: translate3d(-156px, 45px, 0px) !important;
    transform: translate3d(-156px, 45px, 0px) !important;
    border-color: rgba(237, 234, 246, 0.5);
    box-shadow: 2px 3px 0px 0px rgba(43, 43, 43, 0.2);
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    .dropdown-menu
    .dropdown-item {
    font-size: 0.8em;
  }

  .dashview-followers
    .follower-list
    .list-user
    .user-info
    .user-settings-option
    .dropdown-menu:after {
    width: 0;
    height: 0;
    position: absolute;
    top: 0%;
    content: "";
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(43, 43, 43, 0.5);
    right: 12px;
  }
`;
