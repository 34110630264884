import React, { useMemo, useEffect, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { CSVLink } from "react-csv";
import { processDate } from "../../../../../common/utils";
import { getPersonContact, getProfileById } from '../../../../../api';

const prepareFilter = (queryParams, values) => {
  
  const { status, type, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status !== "" ? +status : undefined;
  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  filter.lastName = searchText;
  if (searchText) {
    filter.firstName = searchText;
    filter.email = searchText;
    filter.ipAddress = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ handleStatusChange, applicants }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    
  const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const [applicantData, setApplicantData] = useState([]);

  useEffect(() => {
    setApplicantData([["Name", "Identification", "Location", "Status", "Applied Date", "Email", "Phone Number", "Link to Profile"]]);
    if (applicants.length > 0) {
      for (let i = 0; i < applicants.length; i++) {
        getPersonContact(applicants[i]?.applicant?.id)
          .then(({ data }) => {
            getProfileById(applicants[i]?.applicant?.id)
              .then(({ data: response }) => {
                setApplicantData((appData) => [...appData, 
                  [ 
                    `${applicants[i]?.applicant?.full_name}`, 
                    `${applicants[i]?.applicant?.identifies_as}`, 
                    `${applicants[i]?.applicant?.person_city?.city_name}`,
                    `${applicants[i]?.applicant_status === "R" ? "Reject" : applicants[i]?.applicant_status === "S" ? "Shortlist" : "Applied" }`,
                    `${processDate(applicants[i]?.created_at, "dd-MM-yyyy")}`,
                    `${data?.person_email}`,
                    `${data?.person_phone_number}`,
                    `https://app.iminclusive.com/p/${response?.slug}/`
                  ]]);
              })
              .catch((error) => { });
          })
          .catch(() => {});
      }
    }
  },[applicants]);

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row justify-content-between">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Status"
                  // TODO: Change this code
                  onChange={(e) => {
                    handleStatusChange(e);
                    setFieldValue("status", e.target.value);
                    handleSubmit();

                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="All">All</option>
                  {/* <option value="A">Applied</option> */}
                  <option value="S">Shortlisted</option>
                  <option value="R">Rejected</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>
              {applicants.length > 0 && (
              <div className="px-3">
                <CSVLink data={applicantData} filename="applicant_list" className="btn btn-outline-primary d-flex align-items-center">
                  <i className="material-icons" style={{ fontSize: "20px" }}>file_download</i>
                  Applicants
                </CSVLink>
              </div>
              )}
            {/* <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            */}
           
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
